import {Consumer} from '@possible/cassandra'

import {LoanAcceptanceModuleDefinitions, LoanApplicationModuleDefinitions} from 'src/flows/loans'
import {ModuleStackFactory} from 'src/flows/modules/factory'
import {OnboardingModuleDefinitions, OnboardingParamList} from 'src/flows/types'
import {SignUpModuleDefinitions} from 'src/flows/signup'
import {CardApplicationModuleDefinitions, CardActivationModuleDefinitions} from 'src/flows/cards'

export const allModuleDefinitions = {
  ...SignUpModuleDefinitions,
  ...LoanApplicationModuleDefinitions,
  ...LoanAcceptanceModuleDefinitions,
  ...CardApplicationModuleDefinitions,
  ...CardActivationModuleDefinitions,
} as OnboardingModuleDefinitions

const OnboardingModuleStack = ModuleStackFactory<OnboardingParamList>(
  Consumer.methods.GetOnboardingCurrentModule,
  Consumer.methods.OnboardingMoveToNextModule,
  Consumer.methods.OnboardingMoveToPreviousModule,
  allModuleDefinitions,
)

export const OnboardingLinkingOptions = OnboardingModuleStack.linkingOptions
export const OnboardingStackNavigator = OnboardingModuleStack.stackNavigator

const Onboarding = OnboardingModuleStack.component
export default Onboarding
