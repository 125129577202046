import {gql} from '@apollo/client'

import {
  CardAccountFeesFragment,
  CardAccountMiscDataFragment,
  CardAccountTypeFragment,
  CardAgreementsFragment,
  CardBalanceFragment,
  CardInstallmentPlansFragment,
  CardPaymentMethodsFragment,
  CardPaymentsFragmentV2,
  CardsFragment,
  CardStatementsFragmentV2,
  CardStatusFragment,
  CardTransactionsFragmentV2,
} from '../fragments/cards'
import {PaymentMethodTypeFragment} from '../fragments/paymentMethods'

export const CardsQuery = gql`
  ${CardBalanceFragment}
  ${CardsFragment}
  ${CardStatementsFragmentV2}
  ${CardAgreementsFragment}
  ${CardPaymentMethodsFragment}
  ${CardInstallmentPlansFragment}
  ${CardAccountTypeFragment}
  ${CardAccountFeesFragment}
  ${CardAccountMiscDataFragment}
  query CardsQuery($startDate: LocalDate!) {
    me {
      id
      cardAccounts {
        active {
          id
          createdAt
          updatedAt
          creditLimit
          cardNetwork
          balance {
            ...CardBalanceFragment
          }
          cards {
            ...CardsFragment
          }
          statements {
            ...CardStatementsFragmentV2
          }
          agreements {
            ...CardAgreementsFragment
          }
          paymentMethods {
            ...CardPaymentMethodsFragment
          }
          installmentPlans {
            ...CardInstallmentPlansFragment
          }
          cardAccountType {
            ...CardAccountTypeFragment
          }
          fees {
            ...CardAccountFeesFragment
          }
          miscData {
            ...CardAccountMiscDataFragment
          }
          autopayEnabled
        }
      }
    }
  }
`

export const CardsQueryPolled = gql`
  ${CardTransactionsFragmentV2}
  ${CardPaymentsFragmentV2}
  ${CardStatusFragment}
  query CardsQueryPolled($startDate: LocalDate!, $endDate: LocalDate!) {
    me {
      id
      cardAccounts {
        active {
          id
          transactions {
            ...CardTransactionsFragmentV2
          }
          payments {
            ...CardPaymentsFragmentV2
          }
          status {
            ...CardStatusFragment
          }
        }
      }
    }
  }
`
export const ApplyForCardAccountMutation = gql`
  mutation cardAccountSubmitApplicationV2($cardOfferId: ID!, $miscData: CardAccountMiscDataInput) {
    cardAccountSubmitApplicationV2(cardOfferId: $cardOfferId, miscData: $miscData) {
      id
    }
  }
`

export const AcceptCardAccountMutation = gql`
  mutation cardAccountAccept($cardAccountId: ID!) {
    cardAccountAccept(cardAccountId: $cardAccountId) {
      id
    }
  }
`

export const ActivateCardMutation = gql`
  mutation cardActivate($cardAccountId: ID!, $cardId: ID!) {
    cardActivate(cardAccountId: $cardAccountId, cardId: $cardId)
  }
`

export const AcceptCardAccountAgreements = gql`
  mutation cardAccountAgreementsAccept($cardAccountId: ID!, $cardAccountAgreementId: [ID]!) {
    cardAccountAgreementsAccept(
      cardAccountId: $cardAccountId
      cardAccountAgreementId: $cardAccountAgreementId
    ) {
      id
    }
  }
`

export const DeclineCardAccountAgreements = gql`
  mutation cardAccountDecline($cardAccountId: ID!) {
    cardAccountDecline(cardAccountId: $cardAccountId) {
      id
    }
  }
`

export const RejectCardAccountAgreements = gql`
  mutation RejectCardAgreements($cardAccountId: ID!, $cardAccountAgreementId: [ID]!) {
    cardAccountAgreementsReject(
      cardAccountId: $cardAccountId
      cardAccountAgreementId: $cardAccountAgreementId
    ) {
      id
    }
  }
`

export const CardAccountAgreementsDownload = gql`
  mutation CardAccountAgreementsDownload($cardAccountId: ID!, $cardAccountAgreementId: [ID]!) {
    cardAccountAgreementsDownload(
      cardAccountId: $cardAccountId
      cardAccountAgreementId: $cardAccountAgreementId
    ) {
      agreement {
        id
        type
        status
        createdAt
        updatedAt
      }
      download {
        url
        expiresAt
      }
    }
  }
`

export const CardAccountSchedulePayment = gql`
  mutation CardAccountSchedulePayment(
    $cardAccountId: ID!
    $cardPaymentMethodId: ID!
    $payment: PaymentSpecificationInput!
  ) {
    cardAccountSchedulePayment(
      cardAccountId: $cardAccountId
      cardPaymentMethodId: $cardPaymentMethodId
      payment: $payment
    ) {
      id
      executeAt
    }
  }
`
export const CardAccountLinkPaymentMethod = gql`
  ${PaymentMethodTypeFragment}
  mutation CardAccountLinkPaymentMethod(
    $cardAccountId: ID!
    $userPaymentMethodID: ID!
    $makePrimary: Boolean
  ) {
    cardAccountLinkPaymentMethod(
      cardAccountId: $cardAccountId
      userPaymentMethodID: $userPaymentMethodID
      makePrimary: $makePrimary
    ) {
      ... on AchPaymentMethod {
        ...AchPaymentMethodFragment
      }
      ... on DebitCardPaymentMethod {
        ...DebitCardPaymentMethodFragment
      }
    }
  }
`

export const CardAccountMarkPaymentMethodPrimary = gql`
  mutation CardAccountMarkPaymentMethodPrimary($cardAccountId: ID!, $cardPaymentMethodID: ID!) {
    cardAccountMarkPaymentMethodPrimary(
      cardAccountId: $cardAccountId
      cardPaymentMethodID: $cardPaymentMethodID
    ) {
      __typename
    }
  }
`

export const CardAccountEnableAutomaticPaymentsMutation = gql`
  mutation cardAccountEnableAutomaticPayments($cardAccountId: ID!, $cardPaymentMethodId: ID!) {
    cardAccountEnableAutomaticPayments(
      cardAccountId: $cardAccountId
      cardPaymentMethodId: $cardPaymentMethodId
    ) {
      id
      paymentDate
    }
  }
`

export const GetUserNextAvailablePaymentDate = gql`
  query getUserNextAvailablePaymentDate(
    $network: ProcessorNetwork!
    $direction: FundingDirection!
    $desiredSettlementTime: DateTime!
  ) {
    getUserNextAvailablePaymentDate(
      network: $network
      direction: $direction
      desiredSettlementTime: $desiredSettlementTime
    ) {
      settlementDelayDays
      settlesOnHolidaysAndWeekends
      desiredSettlementDatetime
      adjustedSettlementDatetime
      submitForProcessingBy
      timeNow
    }
  }
`

export const MeActiveCardIdQuery = gql`
  query MeActiveCardId {
    me {
      id
      cardAccounts {
        active {
          id
          status {
            __typename
          }
        }
      }
    }
  }
`

export const CardAccountActivateInstallmentPlanMutation = gql`
  mutation CardAccountActivateInstallmentPlan(
    $cardAccountId: ID!
    $cardAccountInstallmentPlanId: ID!
  ) {
    cardAccountActivateInstallmentPlan(
      cardAccountId: $cardAccountId
      cardAccountInstallmentPlanId: $cardAccountInstallmentPlanId
    ) {
      id
    }
  }
`

export const CardActivatePhysicalMutation = gql`
  mutation CardActivatePhysical(
    $cardAccountId: ID!
    $cardId: ID!
    $cvv: String!
    $ssnMask: String!
  ) {
    cardActivatePhysical(
      cardAccountId: $cardAccountId
      cardId: $cardId
      cvv: $cvv
      ssnMask: $ssnMask
    )
  }
`

export const GetStaticCardAgreementsQuery = gql`
  query GetStaticCardAgreements($agreementTypes: [CardStaticAgreementType!]!) {
    getStaticCardAgreements(agreementTypes: $agreementTypes) {
      agreementType
      presignedUrl
    }
  }
`

export const CardAccountStatementGenerateDownloadLinkMutation = gql`
  mutation CardAccountStatementGenerateDownloadLink($cardAccountId: ID!, $statementId: ID!) {
    cardAccountStatementGenerateDownloadLink(
      cardAccountId: $cardAccountId
      statementId: $statementId
    ) {
      expiresAt
      url
    }
  }
`

export const RequestMfaMutation = gql`
  mutation RequestMfa(
    $source: String
    $messageType: UserVerificationMessageType!
    $verificationType: UserVerificationType!
  ) {
    requestMfa(source: $source, messageType: $messageType, verificationType: $verificationType)
  }
`
export const ValidateMfaMutation = gql`
  mutation ValidateMfa(
    $source: String!
    $verificationType: UserVerificationType!
    $verificationCode: String!
    $consumeVerificationCode: Boolean
  ) {
    validateMfa(
      source: $source
      verificationType: $verificationType
      verificationCode: $verificationCode
      consumeVerificationCode: $consumeVerificationCode
    )
  }
`
export const CancelCardApplicationMutation = gql`
  mutation onboardingCancelApplication {
    onboardingCancelApplication {
      currentModule
      onboardingFinished
    }
  }
`

export const CardAccountToggleApplicationPropertiesRequest = gql`
  mutation cardAccountToggleApplicationPropertiesRequest(
    $applicationDataInput: ToggleCardAccountApplicationPropertiesInput!
  ) {
    cardAccountToggleApplicationPropertiesRequest(applicationDataInput: $applicationDataInput)
  }
`

export const CardAdhocPayment = gql`
  ${CardBalanceFragment}
  query CardAdhocPayment {
    me {
      id
      cardAccounts {
        active {
          id
          __typename
          status {
            ... on ActiveCardAccountStatus {
              autopayFrequency
              overdueInstallmentInfo {
                amount
                paymentDueAt
              }
              overduePaymentInfo {
                amount
              }
              restriction {
                ... on CardAccountSuspendedRestriction {
                  reason
                }
              }
              balance {
                ...CardBalanceFragment
              }
              chargedOffStatus
            }
            ... on DeactivatedCardAccountStatus {
              autopayFrequency
              code
              occurredAt
              subStatus
              reasonCode
              balance {
                ...CardBalanceFragment
              }
              chargedOffStatus
            }
          }
          installmentPlans {
            current {
              id
              numberOfOverdueInstallments
              payments {
                id
                amount
                dueAt
                executeAt
                ordinal
                remainingDue
              }
              totalOverdueAmount
            }
          }
        }
      }
    }
  }
`

export const CardsAccountType = gql`
  query CardsAccountType {
    me {
      id
      cardAccounts {
        active {
          cardAccountType {
            name
            maxBalanceLimit
          }
        }
      }
    }
  }
`

export const CardActivationApproved = gql`
  ${CardAccountTypeFragment}
  query CardActivationApproved {
    me {
      id
      bankAccounts {
        all(accountFilter: {onlyAchEligible: true}) {
          id
        }
      }
      cardAccounts {
        active {
          id
          creditLimit
          cardAccountType {
            id
            monthlyMembershipFees
          }
        }
      }
    }
  }
`
