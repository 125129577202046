import {ServiceErrorErrorCode} from '../../api/proto/common_const'
import {common} from '../../generated/protomonoTypes'
import {apiUriDev} from './ApiUris'

export type PhoneNumberVerificationMethod = 'VOICE' | 'SMS'

const connectionTimeout = 40000

export const maintenanceErrorMessage = 'Maintenance in progress, please try again later.'

//ApiBaseUriOverride allows us to override the base uri for specific calls. Such as the BranchUI call
const ApiV2BaseUriOverride = {}
ApiV2BaseUriOverride['Donkey'] = {UiBranchesGet: apiUriDev.apiV2Uri}

export type ApiV2PutParamsType = {
  request?: object
  method: string //Friendly name of API
  uri: string //This is actually the uri path, not the uri
  //uriBaseOverride used to override the configued URI, the only known one is the BranchUI API
  baseUriOverride?: string
}

export function NewErrorResponse(errorStr) {
  const typed_response = {
    error: {
      error_code: ServiceErrorErrorCode.SendFailure,
      error_msg: errorStr,
    },
  }
  return Object.assign(new APIv2ResponseBase(), typed_response)
}

export class APIv2ResponseBase {
  error?: common.IServiceError = undefined

  throwIfError() {
    const errStr = this.getErrorStr()

    if (errStr) {
      throw errStr
    }
  }

  hasError() {
    return (
      this.error &&
      this.error.errorMsg &&
      this.error.errorMsg !== 'Ok' &&
      this.error.errorMsg !== 'Success'
    )
  }

  getErrorStr() {
    if (!this.error) {
      return undefined
    }

    let errorStr: string | undefined = 'Error with response'
    try {
      if (this.error.errorCode === 'Ok' || this.error.errorMsg === 'Success') {
        return undefined
      }

      errorStr = this.error.errorMsg
    } catch (e) {
      // Log.error(e)
    }

    return errorStr
  }

  getErrorCode() {
    return this.error && this.error.errorCode
  }
}

export type APIv2Response<T> = T & APIv2ResponseBase
export type APIV2ReturnType<T> = Promise<APIv2Response<T>>

const forceError = false

export type APIClientOptions = {
  shouldLog?: boolean

  log?: (...args) => void
  warn?: (...args) => void
  error?: (...args) => void

  devMode: boolean

  apiV1Uri: string
  apiV2Uri: string
  badConnection: boolean
  maintainenceOn: boolean

  clientInfo: ClientInfo
  sessionId?: string
  instanceId?: string

  username?: string
  password?: string
  userId: string
  userToken: string

  onLogout: () => void
  onLoggedIn: () => Promise<boolean>
  onNotLoggedIn: () => void

  onConnectionError: () => void
  onConnectionSuccess: () => void
  checkIsMaintenance: (response: Response, prefix: string) => Promise<boolean>
  onStateChange: (state: any) => void

  platform: string
}

export interface ClientInfo {
  clientType?: string
  deviceOsName?: string
  deviceId?: string
  deviceCarrier?: string
  deviceMfg?: string
  appUniqueId?: string
}

class APIClient {
  nextReqID = 0
  instanceId?: string
  fcmToken?: string
  sessionId?: string

  options?: APIClientOptions

  getNextReqID() {
    return this.nextReqID++
  }

  userId() {
    return this.options?.userId
  }

  userToken() {
    return this.options?.userToken
  }

  api_v2_uri() {
    return this.options?.apiV2Uri
  }

  devMode() {
    return this.options?.devMode
  }

  setDevMode(isDev: boolean) {
    if (this.options) {
      this.options.devMode = isDev
    }
  }

  log(...args) {
    if (this.options?.shouldLog) {
      this.options?.log?.('APIClient -- ', ...args)
    }
  }

  warn(...args) {
    if (this.options?.shouldLog) {
      this.options?.warn?.('APIClient -- ', ...args)
    }
  }

  updateOptions = (pOptions: Partial<APIClientOptions>) => {
    if (this.options) {
      this.options = {
        ...this.options,
        ...pOptions,
      }
    }
  }

  async fetchWithTimeout(uri, request) {
    return Promise.race([
      fetch(uri, request),
      new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error('Connection Timeout')), connectionTimeout),
      ),
    ])
  }

  checkForNetworkFailure = (error) => {
    if (error?.message === 'Network request failed' || error?.message === 'Timeout') {
      this.log('Connection ', error?.message)
      this.options?.onConnectionError?.()
    }
  }

  networkSuccess = () => {
    this.options?.onConnectionSuccess?.()
  }

  bearer_header() {
    const token = this.userToken()
    if (!token) {
      return null
    }

    return {Authorization: `Bearer ${token}`}
  }

  async api_get_json_response(request_name, response) {
    const body = await response.text()
    this.log(`Request [${response.reqID}] : ${request_name}`)
    this.log(`Response Uri  : ${response.url}`)
    this.log(`Response Status  : ${response.status}`)
    this.log(`Response Headers  : ${JSON.stringify(response.headers.map)}`)
    this.log(`Response Body  : ${body}`)

    return JSON.parse(body)
  }

  apiV2Headers() {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...this.bearer_header(),
    }
  }

  async apiV2Put(params: ApiV2PutParamsType) {
    const reqID = this.getNextReqID()

    const retVal = new APIv2ResponseBase()
    retVal.error = {
      errorCode: common.ServiceError.ErrorCode.SendFailure,
      errorMsg: 'Client send error',
    }

    try {
      this.log(`Request Body Parsed [${reqID}] :`)
      this.log('params apiV2Put', params)

      const ACTION = 'PUT'

      if (!params.request) {
        params.request = {}
      }

      const body = JSON.stringify(params.request)

      if (forceError) {
        throw new Error('Testing failure fetch')
      }

      const headers = this.apiV2Headers()

      this.log('ApiV2 headers: ', headers)

      const rootApi = params.baseUriOverride ?? this.api_v2_uri()

      const response = await this.fetchWithTimeout(rootApi + params.uri, {
        method: ACTION,
        headers,
        body: body,
      })

      if (await this.options?.checkIsMaintenance(response, 'V2')) {
        retVal.error.errorMsg = maintenanceErrorMessage
        return retVal
      }

      if (!response?.ok) {
        this.warn('possible_request_v2 response not ok, status : ' + response.status)
        return retVal
      }

      const typed_response = await this.api_get_json_response(params.method, response)

      this.log('Response Body Parsed :', typed_response)

      if (typed_response.error && typed_response.error?.errorCode !== 'Ok') {
        if (typed_response.error.errorCode === 'InvalidAuth') {
          this.log('Server calls returning InvalidAuth, removing local info ')
          this.options?.onLogout?.()
        }
        this.warn(
          `possible_request_v2 error code : ${typed_response.error.errorCode} msg : ${typed_response.error.errorMsg}`,
        )
      }
      this.networkSuccess()
      return Object.assign(new APIv2ResponseBase(), typed_response)
    } catch (error: any) {
      this.log(` Possible Request ${params.method} threw error : ${error}`)
      if (error?.message) {
        retVal.error.errorMsg = error?.message
      }
      this.checkForNetworkFailure(error)
      return retVal
    }
  }

  get_authenticated_headers() {
    return {...this.bearer_header(), Accept: 'application/json'}
  }

  async init(options: APIClientOptions) {
    this.options = options
    this.log({options})

    this.options?.onStateChange({
      dev_mode: this.devMode(),
      api_v1_uri: options.apiV1Uri,
      api_v2_uri: options.apiV2Uri,
      user_id: options.userId,
      user_token: options.userToken,
      instanceId: options.instanceId,
    })

    if (!options.username || !options.password || options.password?.length <= 1) {
      options.onStateChange({init: true})
      return
    }

    this.options?.onStateChange({
      user_id: options.userId || options.username,
      user_token: options.userToken || options.password,
    })

    this.updateOptions({
      userId: options.username,
      userToken: options.password,
    })

    const success = await options.onLoggedIn()
    if (options.maintainenceOn) {
      this.log('init stopped because of maintenance')
      options.onNotLoggedIn()
      return
    }

    if (!success) {
      if (options.badConnection) {
        this.log('Init stopped because of bad connection')
        return
      }

      this.log(`Credentials are not valid for user ${options.username}`)

      options.onStateChange({
        init: true,
        user_id: undefined,
        user_token: undefined,
      })
      options.onNotLoggedIn()

      // This means token is invalid and we don't want to make anymore api calls.
      if (success === false) {
        return success
      }
    } else {
      options.onStateChange({
        init: true,
      })
    }
  }

  async user_misc_props_new(request) {
    const params = {
      method: 'Set user_misc_props_new',
      uri: '/api/v2/ura/UserMiscPropNew',
      request: request,
    }

    const resp = await this.apiV2Put(params)
    if (resp && resp.property && resp.property.value) {
      resp.property.valueParsed = JSON.parse(resp.property.value)
    }
    return resp
  }

  async ura_for_user_get(userId) {
    const params = {
      method: 'Get URA for user',
      request: {
        type: 'ByUserInPending',
        userId: userId,
      },
      uri: '/api/v2/ura/URAQuery',
    }
    return this.apiV2Put(params)
  }

  async ura_set_status(request) {
    //( {id: actionId, newStatus: "completed", resourceId: resourceId} : SetStatusRequest)
    const params = {
      method: 'Set URA',
      request,
      uri: '/api/v2/ura/URASetStatus',
    }

    return this.apiV2Put(params)
  }

  getServicePathName(serviceName) {
    const coreServices = ['ab', 'awards']
    serviceName = serviceName.toLowerCase()

    if (coreServices.includes(serviceName)) {
      return `core_${serviceName}`
    }

    return serviceName
  }

  //Compatability for Auto Generated API calls
  async apiV2SvcPut(serviceName, methodName, request) {
    const params: ApiV2PutParamsType = {
      method: methodName,
      request,
      uri: `/api/v2/${this.getServicePathName(serviceName)}/${methodName}`,
      baseUriOverride: ApiV2BaseUriOverride[serviceName]?.[methodName],
    }
    return this.apiV2Put(params)
  }
}

export default new APIClient()
