import * as user_actions from 'src/lib/user/actions'
import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import {UserReduxTypesV1} from 'src/lib/user/types'

const UsersStateDefault: UserReduxTypesV1 = {
  //$FlowFixMe
  profile: {},
  linked_accounts: undefined,
  prequalificationStatus: undefined,
}

export default function reducer(state: UserReduxTypesV1 | undefined, action): UserReduxTypesV1 {
  state = state ?? UsersStateDefault

  switch (action.type) {
    case CLEAR_USER_ACTION:
      return UsersStateDefault
    case user_actions.USERS_STATE_CHANGE_EVENT:
      return {...state, ...action.state}
    case user_actions.USERS_FINS_UPDATE:
      return {...state, linked_accounts: action.accounts}
    case user_actions.USERS_PREQUALIFICATION_STATUS_UPDATE:
      return {...state, prequalificationStatus: action.prequalificationStatus}
    default:
      return state
  }
}
