import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {UpdateLastLoan} from 'src/api/actions/loans/loanActions'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {ShowException} from 'src/lib/errors'
import {latestLoanSelector} from 'src/lib/loans/selector'
import Log from 'src/lib/loggingUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanApproved, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import NavPageState from 'src/navigation/NavPageState'
import {CancelSurveyTemplate} from 'src/products/loans/LoanApprovedActivation/CancelSurvey/CancelSurveyTemplate'

type Props = StackScreenProps<MainStackParamList, 'CancelSurvey'>

export const CancelSurveyNavPageStateContainer: React.FC<Props> = (props) => {
  const {navigation} = props

  const {t} = useTranslation('CancelLoan')
  const loan = useSelector(latestLoanSelector)
  const dispatch = usePfDispatch()
  const shouldRedirectLoanApplyAcceptToWeb = getIsFeatureFlagEnabled(
    'loan-application-handoff-to-web-on-android',
  )
  const [isOnContinueLoading, setIsOnContinueLoading] = React.useState(false)
  const [loanCancel] = Consumer.hooks.useLoanCancelMutation({
    onError: (e) => {
      Log.error(e, 'Failed to cancel loan')
    },
  })

  useEffect(() => {
    void dispatch(UserStateRefresh())
  }, [dispatch])

  const handleOnContinue = async (value: string, other?: string): Promise<void> => {
    setIsOnContinueLoading(true)
    try {
      if (loan?.id) {
        await loanCancel({
          variables: {
            loanId: loan.id,
            reason: other && other !== '' ? other : value,
            reasonCodes: [],
          },
        })
      } else {
        Log.warn(`Trying to cancel loan, but couldn't find it.`)
      }

      const didRefresh = await dispatch(UserStateRefresh())
      if (!didRefresh) {
        throw t('FailedToLoadError')
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const result = await dispatch(UpdateLastLoan())
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      result.throwIfError()
      navigation.pop()
    } catch (e) {
      Log.log(e)
      ShowException(e)
    } finally {
      setIsOnContinueLoading(false)
    }
  }

  const handleRedirectAcceptLoan = (): void => {
    navigation.pop()

    LoanApproved()

    TrackAppEvent(AppEvents.Name.loan_approved_completed, AppEvents.Category.Checkout)

    if (shouldRedirectLoanApplyAcceptToWeb) {
      EmitRedirectionEvent('acceptance')
    } else {
      const approvalStep = NavPageState.GetNextApprovalState('Dashboard')
      void NavPageState.GoToScreen(navigation, approvalStep)
    }
  }

  return (
    <CancelSurveyTemplate
      onContinue={handleOnContinue}
      onRedirectAcceptLoan={handleRedirectAcceptLoan}
      isOnContinueLoading={isOnContinueLoading}
    />
  )
}
