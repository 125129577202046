import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import PersonalInformationTemplate from 'src/products/loans/PersonalInformation/PersonalInformationTemplate'
import {SignupWorkflowStackParams, WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<SignupWorkflowStackParams, 'PersonalInformation'>

const PersonalInformationWorkflowContainer: React.FC<Props> = (props) => {
  const {onPreReqFulfilled} = props

  const handleComplete = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return <PersonalInformationTemplate {...props} onComplete={handleComplete} />
}

export default PersonalInformationWorkflowContainer
