import React, {FC, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useSelector} from 'react-redux'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {PopPage, PushPage} from 'src/navigation/NavHelper'
import {useUser} from 'src/cassandra'
import {getAllPlaidLinkedBankAccounts, getDebitPaymentMethods} from 'src/lib/card/selectors'
import {PaymentMethodList} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodList'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {PaymentAccount, PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {PfReduxState} from 'src/reducers/types'
import {
  getPayNowPaymentMethod,
  hasPaymentInstrument,
} from 'src/products/card/PaymentMethods/selectors'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {useBankAggregator} from 'src/products/card/PaymentMethods/useBankAggregator'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {logErrorAndShowException} from 'src/lib/errors'
import {AccountNotUsableInfoTile} from 'src/products/MCU/AccountManagementV2/AccountNotUsableInfoTile'

type Props = StackScreenProps<MainStackParamList, 'CardViewPaymentMethods'> & {
  flow: PaymentFlow
}

const CardViewPaymentMethods: FC<Props> = (props) => {
  const {navigation, flow} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_view_payment_method_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardViewPaymentMethods', 'Common'])
  const [busy, setBusy] = useState<boolean>(false)
  useUser(false)
  const allBankAccounts = useSelector(getAllPlaidLinkedBankAccounts)
  const allDebitPaymentMethods = useSelector(getDebitPaymentMethods)
  const defaultPayNowPaymentMethod = useSelector(getPayNowPaymentMethod)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentAccount>(
    defaultPayNowPaymentMethod,
  )
  const doesSelectedBankAccountHavePaymentInstrument = useSelector((state: PfReduxState) =>
    hasPaymentInstrument(state, selectedPaymentMethod?.id),
  )
  const linkPaymentMethod = useLinkPaymentMethod()
  const callBankAggregator = useBankAggregator(navigation)
  const showRadioButtons =
    allBankAccounts &&
    allDebitPaymentMethods &&
    allBankAccounts.length + allDebitPaymentMethods.length > 0

  const handleOnBankSelect = (bankAccount: PaymentAccount): void => {
    TrackAppEvent(CardEvents.card_view_payment_method_page_bank_select_cta, AppEvents.Category.Card)
    setSelectedPaymentMethod(bankAccount)
  }

  const handleOnSavePaymentMethod = async (): Promise<void> => {
    try {
      setBusy(true)

      TrackAppEvent(
        CardEvents.card_view_payment_method_page_save_payment_cta,
        AppEvents.Category.Card,
      )
      if (doesSelectedBankAccountHavePaymentInstrument) {
        await linkPaymentMethod(selectedPaymentMethod?.id, true)
        PopPage(navigation)
      } else {
        PushPage(navigation, 'CardVerifyBankDetails', {
          bankIdToFilter: getPaymentMethodAccount(selectedPaymentMethod)?.id,
          flow,
        })
      }
    } catch (e) {
      void logErrorAndShowException(e as Error, 'onSavePaymentMethod')
    } finally {
      setBusy(false)
    }
  }

  const handleOnBankComplete = (bankName: string): void => {
    PushPage(navigation, 'CardVerifyBankDetails', {
      bankNameToFilter: bankName,
      flow,
    })
  }

  const handleOnAddBank = (): void => {
    TrackAppEvent(CardEvents.card_view_payment_method_page_add_bank_cta, AppEvents.Category.Card)
    callBankAggregator(handleOnBankComplete)
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Save'),
          onPress: handleOnSavePaymentMethod,
          loading: busy,
          disabled: busy,
        },
      }}
      variant={'generic'}
      title={t('YourPaymentMethods')}
      banner={(): null => null}
      smallTopGap
      noHeaderSpacer
    >
      <Box>
        <PFText variant={'h3'}>{t('BankAccounts')}</PFText>
        <Box marginTop={'small'}>
          <PaymentMethodList
            paymentMethods={allBankAccounts ?? []}
            navigation={navigation}
            selectedPaymentAccount={selectedPaymentMethod}
            onPaymentAccountSelect={handleOnBankSelect}
            showRadio={showRadioButtons}
          />
        </Box>

        <Box marginTop={'medium'} marginBottom={'small'}>
          <SvgLink
            linkType={'inline'}
            onPress={handleOnAddBank}
            linkText={`+ ${t('AddNewBank')}`}
            textVariant={'p_semibold'}
          />
        </Box>
      </Box>

      <Box>
        <PFText variant={'h3'}>{t('DebitCards')}</PFText>
        <Box marginTop={'small'}>
          <PaymentMethodList
            paymentMethods={allDebitPaymentMethods ?? []}
            navigation={navigation}
            selectedPaymentAccount={selectedPaymentMethod}
            onPaymentAccountSelect={handleOnBankSelect}
            showRadio={showRadioButtons}
          />

          <Box marginTop={'medium'} marginBottom={'small'}>
            <SvgLink
              linkType={'inline'}
              onPress={(): void => {
                PushPage(navigation, 'CollectDebitCardNumbersForCardProduct')
              }}
              textVariant={'p_semibold'}
              linkText={`+ ${t('AddDebitCard')}`}
            />
          </Box>
        </Box>
      </Box>
      <AccountNotUsableInfoTile paymentMethodScreen />
    </Page>
  )
}

export default withForwardedNavigationParams<Props>()(CardViewPaymentMethods)
