import {ApolloQueryResult} from '@apollo/client'

import {ProductsQuery} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCallback, useState} from 'react'

import {MoveToNextPreReqRoute} from 'src/workflows/navigation'
import {MoveToNextPreReqRouteArgsType, PreReqType} from 'src/workflows/types'

const useMoveToNextPreReqRoute = ({
  unmet: initialUnmet,
  met: initialMet,
}: {
  met: PreReqType[]
  unmet: PreReqType[]
}): typeof MoveToNextPreReqRoute => {
  const [previousPreReqs, setPreviousPreReqs] = useState<
    MoveToNextPreReqRouteArgsType['previousPreReqs']
  >({met: initialMet, unmet: initialUnmet})

  return useCallback(
    async <T extends ApolloQueryResult<unknown> = ApolloQueryResult<ProductsQuery>>(
      args: MoveToNextPreReqRouteArgsType<T>,
    ): Promise<NonNullable<MoveToNextPreReqRouteArgsType['previousPreReqs']>> => {
      const newPreReqs = await MoveToNextPreReqRoute<T>({
        ...args,
        previousPreReqs,
      })
      setPreviousPreReqs(newPreReqs)
      return newPreReqs
    },
    [previousPreReqs],
  )
}

export {useMoveToNextPreReqRoute}
