import React from 'react'

import i18n from 'src/lib/localization/i18n'
import {getRemoteValueJson} from 'src/lib/RemoteConfig'
import {HOW_DID_YOU_HEAR_ABOUT_US} from 'src/lib/RemoteConfig/parameterkeys'
import {
  MarketingSurveyBaseProps,
  OnContinueAfterMarketingSurveyOptionSelected,
} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'
import {MarketingSurveyTemplate} from 'src/products/general/MarketingSurvey/MarketingSurveyTemplate'
import {setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import Log from 'src/lib/loggingUtil'

/**
 * Container to store necessary marketing survey answers with redux.
 */
const MarketingSurveyGQLContainer: React.FC<MarketingSurveyBaseProps> = (
  props: MarketingSurveyBaseProps,
) => {
  const {onContinue} = props

  /**
   * Gets the survey options list version.
   */
  const getListVersion = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const remoteConfig = getRemoteValueJson(HOW_DID_YOU_HEAR_ABOUT_US)
    if (!remoteConfig) {
      return 'v8'
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const version = remoteConfig['version']
    if (!version) {
      return 'unknown'
    }
    return typeof version === 'string' ? version : String(version)
  }

  /**
   * Gets the survey options from remote config.
   */
  const options = React.useMemo((): string[] | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const remoteConfig = getRemoteValueJson(HOW_DID_YOU_HEAR_ABOUT_US)
    if (remoteConfig) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const optionList = remoteConfig[i18n.language]
      const optionsListStrings: string[] = []
      if (optionList && Array.isArray(optionList)) {
        optionList.forEach((thisOption) => {
          if (typeof thisOption === 'string') {
            optionsListStrings.push(thisOption)
          }
        })
        return optionsListStrings
      }
    }
    return undefined
  }, [])

  const handleOnContinue: OnContinueAfterMarketingSurveyOptionSelected = async ({
    selectedSourceOption,
    otherValue,
  }) => {
    const listVersion: string = getListVersion()
    const event = {
      source: selectedSourceOption,
      version: listVersion,
    }

    // this records the user's answer on the back end
    setUserProperty('mkt_chl_src', event).catch(() => {
      Log.warn('Failed to set mkt_chl_src user property')
    })

    await onContinue({
      selectedSourceOption,
      otherValue,
    })
  }

  return <MarketingSurveyTemplate onContinue={handleOnContinue} marketingSourceOptions={options} />
}

export {MarketingSurveyGQLContainer}
