import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type ApplyInFewStepsTemplateProps = {
  handleStartThreeStepsApplication: () => Promise<void>
}

const ApplyInFewStepsTemplate: React.FC<ApplyInFewStepsTemplateProps> = (props) => {
  const {handleStartThreeStepsApplication} = props
  const {t} = useTranslation(['ApplyInFewSteps', 'Common'])

  const title = (
    <Box paddingHorizontal={'small'}>
      <PFText color="white" variant={'h1'} textAlign="center" testID="Apply-In-Three-Steps-Title">
        {t('ApplyInThreeStepsTitle')}
      </PFText>
    </Box>
  )

  const applySteps = [
    {label: t('Step1Label'), testID: 'Verify-Identity-and-Link-Account-Tile'},
    {label: t('Step2Label'), testID: 'Check-Your-Elibibility-Tile'},
  ]

  const renderApplySteps = (
    <Box direction="column" gap="enormous" fill="horizontal">
      {applySteps.map((step, index) => (
        <Box direction="column" align="center" key={step.label} gap="enormous">
          <Box
            width={40}
            height={40}
            border={{color: NamedColors.LIME, width: 2}}
            justify="center"
            align="center"
            radius={'enormous'}
          >
            <PFText variant={'p_semibold'} color={NamedColors.LIME}>
              {String(index + 1)}
            </PFText>
          </Box>
          <Box direction="row" width={'100%'} justify="center">
            <PFText color="white" variant={'h2'} textAlign="center" testID={step.testID}>
              {step.label}
            </PFText>
          </Box>
        </Box>
      ))}
    </Box>
  )

  const content = (
    <Box paddingHorizontal={'large'} marginTop={'enormous'}>
      <Box>{renderApplySteps}</Box>
    </Box>
  )

  const buttonProps: ButtonLockupPropsPrimary = {
    text: t('Common:Continue'),
    onPress: handleStartThreeStepsApplication,
    testID: 'Apply-In-Three-Steps-Continue-Button',
    mode: 'secondary',
  }

  return (
    <Page
      title={title}
      variant={'generic'}
      buttonProps={buttonLockupProperties(buttonProps)}
      backgroundColor={'black'}
      safeAreaBackground={'black'}
      noHorizontalPadding
      smallTopGap
      contentBackgroundColor={'black'}
    >
      {content}
    </Page>
  )
}

export {ApplyInFewStepsTemplate}
