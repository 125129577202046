export * as types from './types/consumer'
export * as schemas from './schemas/consumer'
export * as formatting from './utils/formatting'
export * as adapters from './utils/adapters'
export * as errors from './utils/errors'
export * as factories from './utils/factories'
export * as selectors from './utils/selectors'

import {actions as userActions} from './user'

import * as userMethods from './user/methods'
import * as onboardingMethods from './onboarding/methods'
import * as consumerHooks from './hooks/consumer'
import * as cassandraHooks from './utils/hooks'

const actions = {
  ...userActions,
}

const methods = {
  ...userMethods,
  ...onboardingMethods,
}

const hooks = {
  ...consumerHooks,
  ...cassandraHooks,
}

export {actions, methods, hooks}
