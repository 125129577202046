import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import PersonalInformationTemplate from 'src/products/loans/PersonalInformation/PersonalInformationTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowPersonalInformation} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'

const PersonalInformationLegacyContainer: React.FC<
  StackScreenProps<MainStackParamList, 'PersonalInformation'>
> = (props) => {
  const {navigation} = props

  const handleComplete = useCallback(async () => {
    NavPageState.NavToNextPage(LoanFlowPersonalInformation, navigation)
  }, [navigation])

  return <PersonalInformationTemplate {...props} onComplete={handleComplete} />
}

export default PersonalInformationLegacyContainer
