import React, {FC} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ReasonSurveyRESTContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyRESTContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'

const ReasonSurveyNavPageStateContainer: FC = () => {
  const navigation: StackScreenProps<MainStackParamList, 'ReasonSurvey'> = useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.purpose_for_loan_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    const approvalStep = NavPageState.GetNextApprovalState(LoanApprovedFlow.ReasonSurvey)
    await NavPageState.GoToScreen(navigation, approvalStep)
  }

  return <ReasonSurveyRESTContainer onContinue={handleOnContinue} />
}

export {ReasonSurveyNavPageStateContainer}
