import React, {FC, ReactNode} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'

export type NonACHProps = {
  title?: string
  body?: ReactNode
  onNonAch: () => void
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  secondaryButtonText?: string
}

const NonACH: FC<NonACHProps> = (props) => {
  const {t} = useTranslation(['LoanApproved', 'Common'])

  usePageViewedAnalytics({
    eventName: AppEvents.Name.non_ach_option_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const onNonAch = (): void => {
    props.onNonAch()
    props.navigation.pop()
  }

  const defaultBody = (
    <PFText variant={'p'}>
      <Trans i18nKey={'NoAutoPayDefaultBody'} t={t}>
        Not accepting the Electronic Payment Authorization means that
        <PFText variant={'p'} color={'error'}>
          you will not receive funds for at least 15 to 20 business days
        </PFText>
        . If you choose to opt out, you must contact us so we can verify your mailing address and
        send you a check for your funds via standard mail (15–20 business days).
      </Trans>
    </PFText>
  )

  const {title = t('PayByMoneyOrder'), body = defaultBody, navigation, secondaryButtonText} = props

  const primary = {
    text: t('Common:NeverMind'),
    onPress: (): void => {
      TrackAppEvent(AppEvents.Name.non_ach_option_nevermind_selected, AppEvents.Category.Checkout)
      navigation.pop()
    },
  }

  const secondary = {
    text: secondaryButtonText ? secondaryButtonText : t('AgreeToDelayOfFunds'),
    onPress: (): void => {
      TrackAppEvent(
        AppEvents.Name.non_ach_option_agree_to_delay_of_funds_selected,
        AppEvents.Category.Checkout,
      )
      onNonAch()
    },
  }

  return (
    <LightboxNotice title={title} primary_action={primary} secondary_action={secondary}>
      <PFText variant={'p'}>{body}</PFText>
    </LightboxNotice>
  )
}

export default NonACH
