import * as React from 'react'
import {NavigationContainerRef} from '@react-navigation/core'
import {NavigationAction} from '@react-navigation/routers'
import {DrawerParamList} from 'src/nav/MainStackParamsList'

export const rootNavigationRef = React.createRef<NavigationContainerRef<DrawerParamList>>()

export function rootDispatch(action: NavigationAction): void {
  rootNavigationRef.current?.dispatch(action)
}

export function rootNavigate(action): void {
  rootNavigationRef.current?.navigate(action)
}

global.rootNavigationRef = rootNavigationRef
