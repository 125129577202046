import {snakeCase} from 'lodash'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PaymentAction} from 'src/products/general/components/molecules/PaymentInput/PaymentInput'
import {getNextPaymentAmount} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCardAdhocPayment} from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment'
import {
  GetIsButtonSelectedProps,
  getIsButtonSelected,
} from 'src/products/general/components/molecules/PaymentInput/PaymentInput.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type UseCardAdhocPaymentInput = {
  buttonActions: PaymentAction[] | undefined
  getIsButtonSelected: (props: GetIsButtonSelectedProps) => boolean
  selectedButton: PaymentAction | undefined
}

// Storing translation keys as constants to make Sonar happy 🙃
const CURRENT_BALANCE_TRANSLATION_KEY = 'ButtonActions.CurrentBalance'
const FALLBACK_ACTION_TRANSLATION_KEY = 'ButtonActions.FallbackAction'
const MISSED_INSTALLMENT_TRANSLATION_KEY = 'ButtonActions.MissedInstallment'
const UPCOMING_INSTALLMENT_TRANSLATION_KEY = 'ButtonActions.UpcomingInstallment'
const MINIMUM_PAYMENT_TRANSLATION_KEY = 'ButtonActions.MinimumPayment'
const STATEMENT_BALANCE_TRANSLATION_KEY = 'ButtonActions.StatementBalance'

const useCardAdhocPaymentInput = (): UseCardAdhocPaymentInput => {
  const {currentBalance, data, isLoading, paymentState} = useCardAdhocPayment()
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay
  const minimumBalance = cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments
  const statementBalance = cardMinPayResponse?.balance?.statementAfterPayments
  const {t} = useTranslation('CardAdhocPaymentAmountEntry')

  const activeAccount = data?.me.cardAccounts.active

  const getButtonActions = useMemo((): PaymentAction[] => {
    const upcomingInstallmentAmount = getNextPaymentAmount(activeAccount, true)

    const buttonActions: PaymentAction[] = [
      {
        amount: currentBalance,
        isFallback: false,
        label: t(CURRENT_BALANCE_TRANSLATION_KEY, {keySeparator: '.'}),
        trackingId: snakeCase(t(CURRENT_BALANCE_TRANSLATION_KEY, {keySeparator: '.'})),
      },
      {
        amount: '0',
        isFallback: true,
        label: t(FALLBACK_ACTION_TRANSLATION_KEY, {keySeparator: '.'}),
        trackingId: snakeCase(t(FALLBACK_ACTION_TRANSLATION_KEY, {keySeparator: '.'})),
      },
    ]

    if (
      activeAccount?.installmentPlans.current?.totalOverdueAmount &&
      activeAccount?.installmentPlans.current?.numberOfOverdueInstallments
    ) {
      buttonActions.unshift({
        amount: activeAccount?.installmentPlans.current?.totalOverdueAmount,
        isFallback: false,
        label: t(MISSED_INSTALLMENT_TRANSLATION_KEY, {
          count: activeAccount?.installmentPlans.current?.numberOfOverdueInstallments,
          keySeparator: '.',
        }),
        trackingId: snakeCase(
          t(MISSED_INSTALLMENT_TRANSLATION_KEY, {
            count: activeAccount?.installmentPlans.current?.numberOfOverdueInstallments,
            keySeparator: '.',
          }),
        ),
      })
    } else if (upcomingInstallmentAmount) {
      buttonActions.unshift({
        amount: upcomingInstallmentAmount,
        isFallback: false,
        label: t(UPCOMING_INSTALLMENT_TRANSLATION_KEY, {keySeparator: '.'}),
        trackingId: snakeCase(t(UPCOMING_INSTALLMENT_TRANSLATION_KEY, {keySeparator: '.'})),
      })
    }
    if (isUserMinPayProgram && minimumBalance !== '0.00') {
      buttonActions.splice(
        1,
        0,
        {
          amount: statementBalance ?? '',
          isFallback: false,
          label: 'Statement Balance',
          trackingId: snakeCase(t(STATEMENT_BALANCE_TRANSLATION_KEY, {keySeparator: '.'})),
        },
        {
          amount: minimumBalance ?? '',
          isFallback: false,
          label: 'Minimum Payment',
          trackingId: snakeCase(t(MINIMUM_PAYMENT_TRANSLATION_KEY, {keySeparator: '.'})),
        },
      )
    }

    return buttonActions
  }, [activeAccount, currentBalance, isUserMinPayProgram, cardMinPayResponse, minimumBalance, t])

  const selectedButton = getButtonActions.find((action) =>
    getIsButtonSelected({
      buttonActions: getButtonActions,
      currentAction: action,
      inputValue: paymentState.payment.amount,
      trackingId: action.trackingId,
    }),
  )

  return {
    buttonActions: !isLoading ? getButtonActions : undefined,
    getIsButtonSelected,
    selectedButton,
  }
}

export {useCardAdhocPaymentInput}
