import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import PersonalInformationTemplate from 'src/products/loans/PersonalInformation/PersonalInformationTemplate'
import {ModuleControlProps} from 'src/flows/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

const PersonalInformationMPOContainer: React.FC<
  StackScreenProps<MainStackParamList, 'PersonalInformation'> & ModuleControlProps
> = (props) => {
  const {onModuleComplete} = props

  const handleComplete = useCallback(async () => {
    onModuleComplete?.()
  }, [onModuleComplete])

  return <PersonalInformationTemplate {...props} onComplete={handleComplete} />
}

export default PersonalInformationMPOContainer
