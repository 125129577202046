export enum aggregatorsId {
  plaid = 'plaid',
  yodlee = 'yodlee',
  mocked = 'mocked',
}

export enum bankLinkStatus {
  linked = 'linked_in_use',
  unlinked = 'unlinked',
}

export enum paymentInstrumentStatus {
  removed = 'removed',
  invalid = 'invalid',
  pending = 'pending',
  suspended = 'suspended',
  verified = 'verified',
}

export enum AccountType {
  CD = 'cd',
  CHECKING = 'checking',
  CREDIT_CARD = 'credit card',
  DEBIT_CARD = 'debit card',
  DEPOSITORY = 'depository',
  FOUR01K = '401k',
  IRA = 'ira',
  MONEY_MARKET = 'money market',
  SAVINGS = 'savings',
  STUDENT = 'student',
}

export const DL_TYPE_ID = 'dl'
