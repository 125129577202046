import React, {useCallback, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {ampli} from 'src/lib/Analytics/ampli'
import {Consumer} from '@possible/cassandra'
import * as Public from '@possible/cassandra/src/types/types.public.generated'
import {
  userForgotPasswordSendCode,
  userValidateVerificationCode,
} from '@possible/cassandra/src/user/authPublicMethods'
import {PhoneNumberVerificationMethod} from '@possible/generated/APIClient'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {userIdSelector} from 'src/api/selectors/selectors'
import NotificationsPermissionsModal from 'src/products/general/NotificationsPermissions/NotificationsPermissionsModal'
import {GenericVerification} from 'src/products/loans/PhoneConfirmation/GenericVerification'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent, VerifyPhoneEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import Log from 'src/lib/loggingUtil'

type Props = StackScreenProps<MainStackParamList, 'PhoneVerification'> & {
  showNotificationsModal?: boolean
  onVerifiedSuccessfully: () => void
  onPushPermissionsGranted?: () => Promise<void>
  onPushPermissionsModalDismissed?: () => Promise<void>
}

const PhoneVerificationTemplate: React.FC<Props> = ({
  navigation,
  route,
  showNotificationsModal = false,
  onVerifiedSuccessfully,
  onPushPermissionsGranted,
  onPushPermissionsModalDismissed,
}: Props) => {
  const {phoneNumber, phoneNumberFormatted, forgotPassword} = route.params

  const [busy, setBusy] = useState(false)

  const [sendVerificationCode] = Consumer.hooks.usePhoneVerificationCodeSendMutation()
  const [validateCode] = Consumer.hooks.usePhoneVerificationCodeValidationMutation()

  const userId = usePfSelector(userIdSelector) as string | undefined
  const dispatch = usePfDispatch()

  const isCreatePasswordFlow = forgotPassword && !userId

  const eventCategory = isCreatePasswordFlow
    ? AppEvents.Category.Admin
    : AppEvents.Category.Onboarding

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_verification_code_screen_viewed,
    eventCategory,
  })

  const handleResend = useCallback(
    async (method: PhoneNumberVerificationMethod) => {
      let successful = true
      TrackAppEvent(
        method === 'SMS'
          ? AppEvents.Name.enter_verification_code_resend_selected
          : AppEvents.Name.enter_verification_code_phone_call_selected,
        AppEvents.Category.Checkout,
      )

      try {
        if (forgotPassword) {
          const input: Public.UserForgotPasswordSendCodeInput = {
            usernameType: Public.AccountUsernameType.Phone,
            username: phoneNumberFormatted,
            deliveryMedium:
              method.toUpperCase() === Public.AccountDeliveryMedium.Sms
                ? Public.AccountDeliveryMedium.Sms
                : Public.AccountDeliveryMedium.Voice,
          }
          const success: boolean | undefined = await userForgotPasswordSendCode(input)
          if (!success) {
            throw Error('Failed to send forgot password code')
          }
        } else {
          const response = await sendVerificationCode({
            variables: {
              authorizationCodeInput: {
                phoneNumber,
                method,
              },
            },
          })

          if (response.errors) {
            throw response.errors[0]
          }
        }
      } catch (e) {
        ShowException(e)
        successful = false
      }
      return successful
    },
    [forgotPassword, phoneNumber, phoneNumberFormatted, sendVerificationCode],
  )

  const handleSubmit = useCallback(
    async (code: string) => {
      const onPressCreatePassword = async (): Promise<void> => {
        try {
          TrackAppEvent(AppEvents.Name.enter_verification_code_submitted, eventCategory)

          try {
            ampli.registrationVerificationCompleted()
          } catch (e) {
            Log.warn('Failed to send registration verification completed event', e)
          }
          const input: Public.UserValidateVerificationCodeInput = {
            usernameType: Public.AccountUsernameType.Phone,
            username: phoneNumberFormatted,
            verificationCode: code,
          }
          const resp: Public.UserValidateVerificationCodeResponse | undefined =
            await userValidateVerificationCode(input)
          if (!resp?.obfuscatedEmail) {
            throw Error('Failed to validate verification code')
          }
          const obfuscatedEmail: string | undefined = resp.obfuscatedEmail

          PushPage(navigation, 'CreatePasswordLoggedOut', {
            code,
            username: phoneNumberFormatted,
            obfuscatedEmail: obfuscatedEmail,
          })
        } catch (e) {
          ShowException(e)
        }
      }

      const onPressLoanFlow = async (): Promise<void> => {
        try {
          TrackAppEvent(AppEvents.Name.enter_verification_code_submitted, eventCategory)

          try {
            ampli.registrationVerificationCompleted()
          } catch (e) {
            Log.warn('Failed to send registration verification completed event', e)
          }

          const res = await validateCode({
            variables: {
              phoneNumberVerificationInput: {
                phoneNumber,
                code,
              },
            },
          })
          if (res.errors) {
            throw res.errors[0]
          }

          VerifyPhoneEvent()
          await dispatch(UserStateRefresh())

          setBusy(true)
          await onVerifiedSuccessfully()
        } catch (e) {
          ShowException(e)
        } finally {
          setBusy(false)
        }
      }

      const onPress = isCreatePasswordFlow ? onPressCreatePassword : onPressLoanFlow
      await onPress()
    },
    [
      dispatch,
      eventCategory,
      isCreatePasswordFlow,
      navigation,
      onVerifiedSuccessfully,
      phoneNumber,
      phoneNumberFormatted,
      validateCode,
    ],
  )

  const handlePushPermissionsGranted = useCallback(() => {
    onPushPermissionsGranted?.().finally(() => {
      setBusy(false)
    })
  }, [onPushPermissionsGranted])

  const handlePushPermissionsModalDismissed = useCallback(() => {
    onPushPermissionsModalDismissed?.().finally(() => {
      setBusy(false)
    })
  }, [onPushPermissionsModalDismissed])

  return (
    <>
      <GenericVerification
        resendAction={handleResend}
        submitAction={handleSubmit}
        formattedPhoneNumber={phoneNumberFormatted}
        verificationMethod={'PHONE'}
        parentBusy={busy}
      />
      <NotificationsPermissionsModal
        visible={showNotificationsModal}
        onPermissionsGranted={handlePushPermissionsGranted}
        onDismiss={handlePushPermissionsModalDismissed}
      />
    </>
  )
}

export default PhoneVerificationTemplate
