import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type StateSelectedQueryVariables = Types.Exact<{[key: string]: never}>

export type StateSelectedQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        address?: {__typename?: 'Address'; state?: string | null} | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'OnboardingInfo'
      loan?: {__typename?: 'LoanOnboardingInfo'; stateSelected?: string | null} | null
    } | null
  }
}

export type LoanAmountSelectionMutationVariables = Types.Exact<{
  amount: Types.Scalars['Decimal']['input']
}>

export type LoanAmountSelectionMutation = {
  __typename?: 'Mutation'
  onboardingSelectLoanAmount: boolean
}

export const StateSelectedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'StateSelected'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'state'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'onboarding'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'stateSelected'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StateSelectedQuery, StateSelectedQueryVariables>
export const LoanAmountSelectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'LoanAmountSelection'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Decimal'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'onboardingSelectLoanAmount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanAmountSelectionMutation, LoanAmountSelectionMutationVariables>
