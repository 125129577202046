import React, {FC, useEffect, useState} from 'react'
import {StyleSheet} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {Consumer} from '@possible/cassandra'
import UserInfoField, {UserInfoFieldProps} from 'src/products/MCU/Profile/UserInfoField'
import {userProfileSelector, isEmailVerifiedSelector} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {BusyHook} from 'src/products/MCU/EmailConfirmation/EmailConfirmation.utils'
import {usePfSelector} from 'src/store/utils'
import {
  formatAddressMultilines,
  openEditEmail,
  openEditPassword,
  verifyEmail,
} from 'src/products/MCU/Profile/Profile.utils'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {getLoginMethodUserProperty} from 'src/api/lib/UserProperties/LoginMethodUserPropertyUtils'

type Props = StackScreenProps<MainStackParamList, 'Profile'>
type Navigation = Props['navigation']

const passwordDisplay = '********'

const info = (
  user: Consumer.types.UserProfile,
  navigation: Navigation,
  showEditPassword: boolean,
  t: TFunction,
  emailVerified: boolean,
  busy: boolean,
  setBusy: BusyHook,
): UserInfoFieldProps[] => {
  const editPasswordField = showEditPassword
    ? [
        {
          label: t('Password'),
          value: passwordDisplay,
          isEditable: true,
          onPress: (): void => {
            openEditPassword(navigation)
          },
        },
      ]
    : []

  const emailIcon = emailVerified ? (
    <Box paddingLeft={6} paddingTop={4}>
      <SvgIcon name={'checkWithCircle'} colorVariant={'success'} />
    </Box>
  ) : undefined

  const verifyEmailAction = emailVerified
    ? undefined
    : {
        text: t('VerifyEmail'),
        onPress: () => verifyEmail(navigation, setBusy),
        disabled: busy,
        testID: 'VerifyEmail',
      }

  return [
    {label: t('Name'), value: user?.name?.firstName ?? ''},
    {label: t('PhoneNumber'), value: user?.phone?.number ?? ''},
    {
      label: t('Email'),
      value: user?.email?.address ?? '',
      isEditable: true,
      onPress: () => openEditEmail(navigation),
      valueExtra: emailIcon,
      action: verifyEmailAction,
    },
    ...editPasswordField,
    {
      label: t('Address'),
      value: formatAddressMultilines(user?.home?.address as Consumer.types.Address),
    },
  ]
}

const Profile: FC<Props> = ({navigation}) => {
  const userInfo = usePfSelector(userProfileSelector)
  const emailVerified = usePfSelector(isEmailVerifiedSelector) ?? false
  const [showEditPassword, setShowEditPassword] = useState(false)
  const [busy, setBusy] = useState(false)
  const {t} = useTranslation('Profile')

  useEffect(() => {
    const getLoginMethod = async (): Promise<void> => {
      const loginMethod = await getLoginMethodUserProperty()
      // If a user uses oauth we don't want to let them edit password as they likely don't have one set yet
      setShowEditPassword(loginMethod !== 'oauth')
    }
    void getLoginMethod()
  }, [])

  const infoList = info(
    userInfo,
    navigation,
    showEditPassword,
    t,
    emailVerified,
    busy,
    setBusy,
  ).map((item) => (
    <Box marginTop={'large'} key={item.label}>
      <UserInfoField
        label={item.label}
        value={item.value}
        isEditable={item.isEditable}
        onPress={item.onPress}
        valueExtra={item.valueExtra}
        action={item.action}
      />
    </Box>
  ))

  return (
    <GenericNonModalTemplate
      title={t('Profile')}
      justifyContent={styles.content}
      testID="ProfilePage"
    >
      {infoList}
    </GenericNonModalTemplate>
  )
}

export default Profile

const styles = StyleSheet.create({
  content: {
    justifyContent: 'flex-start',
  },
})
