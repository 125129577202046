import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useCards} from 'src/products/card/hooks/useCards'
import {userEmailSelector} from 'src/lib/user/selector'
import {isUserRejectedDueToQuota} from 'src/products/card/LoanDash/utils'

export const useGetRejectionText = () => {
  const {t} = useTranslation('CardApplicationRejected')
  const emailAddress = useSelector(userEmailSelector)
  const {activeCardStatus, loading} = useCards()

  let titleKey = 'CardRejectedTitle'
  let bodyKey = 'CardRejectedBody'

  if (isUserRejectedDueToQuota(activeCardStatus)) {
    titleKey = 'CardRejectedForQuotaTitle'
    bodyKey = 'CardRejectedForQuotaBody'
  }

  return {
    loading,
    title: t(titleKey),
    body: t(bodyKey, {emailAddress}),
  }
}
