import {Consumer} from '@possible/cassandra'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import Money from 'src/assets/illustrations/Money.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ModuleControlProps} from 'src/flows/types'
import {ShowException} from 'src/lib/errors'
import {latestLoanSelector} from 'src/lib/loans/selector'
import Log from 'src/lib/loggingUtil'
import {usePfSelector} from 'src/store/utils'

const PROCESSING_ACCEPT = 'accept'
const PROCESSING_CANCEL = 'cancel'

const LoanProcessingNotNow: React.FC<ModuleControlProps> = (props) => {
  const {onModuleComplete} = props

  const {t} = useTranslation('Onboarding')

  const [processingAction, setProcessingAction] = useState<string | undefined>()

  const loan = usePfSelector(latestLoanSelector)
  const [loanCancel] = Consumer.hooks.useLoanCancelMutation()

  const amount = loan?.amountBorrowed

  const handleAccept = async () => {
    setProcessingAction(PROCESSING_ACCEPT)
    await onModuleComplete?.('LOAN_ACCEPTANCE')
    setProcessingAction(undefined)
  }

  const handleCancel = async () => {
    setProcessingAction(PROCESSING_CANCEL)

    try {
      if (loan && loan.id) {
        const variables = {
          loanId: loan.id,
          reason: 'MPO Cancel -- No Survey',
          reasonCodes: [],
        }

        const result = await loanCancel({
          variables,
        })

        if (result.errors) {
          throw result.errors[0]
        }

        await onModuleComplete?.('LOAN_END')
      } else {
        throw new Error("Tried to cancel loan but couldn't find it. Please restart the app.")
      }
    } catch (e) {
      Log.error(e, 'Error while canceling loan from MPO: ')
      ShowException(e)
    } finally {
      setProcessingAction(undefined)
    }
  }

  return (
    <Page variant="generic" smallTopGap>
      <Box fill marginTop="small">
        <BasicTile padding="medium" gap="medium">
          <Box paddingHorizontal="enormous">
            <PFSvgContain svg={Money} width="100%" />
          </Box>

          <PFText variant="h3">{t('LoanProcessingNotNowTitle', {amount})}</PFText>

          <PFText variant="p_lg">{t('LoanProcessingNotNowBody')}</PFText>

          <Box gap="small">
            <Button
              mode="primary"
              size="large"
              onPress={handleAccept}
              disabled={!!processingAction}
              loading={processingAction === PROCESSING_ACCEPT}
            >
              {t('LoanProcessingNotNowPrimaryCTA')}
            </Button>
            <Button
              mode="secondary"
              size="large"
              onPress={handleCancel}
              disabled={!!processingAction}
              loading={processingAction === PROCESSING_CANCEL}
            >
              {t('LoanProcessingNotNowSecondaryCTA')}
            </Button>
          </Box>
        </BasicTile>
      </Box>
    </Page>
  )
}

export default LoanProcessingNotNow
