import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {ModuleControlProps, OnboardingParamList} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCards} from 'src/products/card/hooks/useCards'
import {CardAutopayOverviewTemplate} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewTemplate/CardAutopayOverviewTemplate'

type CardAutopayOverviewMPOContainerProps = StackScreenProps<
  OnboardingParamList,
  'CARD_AUTOPAY_OVERVIEW'
> &
  ModuleControlProps

const CardAutopayOverviewMPOContainer = ({
  onStepComplete,
}: CardAutopayOverviewMPOContainerProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_join_autopay_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {hasPendingPhysicalCard} = useCards()

  const handleOnPress = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.card_autopay_overview_page_cta, AppEvents.Category.Card)

    await onStepComplete?.()
  }

  return (
    <CardAutopayOverviewTemplate
      onPress={handleOnPress}
      hasPendingPhysicalCard={hasPendingPhysicalCard}
    />
  )
}

export {CardAutopayOverviewMPOContainer}
