import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {ModuleControlProps} from 'src/flows/modules/types'
import {OnboardingParamList} from 'src/flows/types'
import {CardPaymentAgreementGQLContainer} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementGQLContainer/CardPaymentAgreementGQLContainer'

type CardPaymentAgreementMPOContainerProps = StackScreenProps<
  OnboardingParamList,
  'CARD_PAYMENT_AGREEMENT'
> &
  ModuleControlProps

const CardPaymentAgreementMPOContainer = ({
  onStepComplete,
}: CardPaymentAgreementMPOContainerProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_finish_virtual_activation_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })

  const handleOnComplete = (): void => {
    void onStepComplete?.()
  }

  return (
    <CardPaymentAgreementGQLContainer
      // In MPO, the backend drives both of these navigation actions.
      onComplete={handleOnComplete}
      onPressSecondary={handleOnComplete}
    />
  )
}

export {CardPaymentAgreementMPOContainer}
