import React, {FC, useCallback, useEffect, useState} from 'react'
import {useStore} from 'react-redux'

import {
  completeUra,
  GetPendingUserRequestedActions,
  UserRequestedActionStatusCode,
} from 'src/cassandra'
import {maintenanceOn} from 'src/api/selectors/selectors'
import URAVerifyNameMatchesID from 'src/products/loans/UserRequestedAction/URAVerifyNameMatchesID'
import URAUpdateSSN from 'src/products/loans/UserRequestedAction/URAUpdateSSN'
import URAUpdateAddress from 'src/products/loans/UserRequestedAction/URAUpdateAddress'
import {RelinkAccount} from 'src/products/loans/UserRequestedAction/RelinkAccount'
import RetakeLightBox from 'src/products/loans/UserRequestedAction/RetakeLightBox'
import {AchAccountNumber} from 'src/products/loans/UserRequestedAction/AchAccountNumber/AchAccountNumber'
import {InterchangeAccountNumberLightBox} from 'src/products/loans/UserRequestedAction/InterchangeAccountNumberLightBox'
import URAOther from 'src/products/loans/UserRequestedAction/URAOther'
import LinkAnotherAccount from 'src/products/loans/UserRequestedAction/LinkAnotherAccount'
import ProofOfResidenceLightBox from 'src/products/loans/UserRequestedAction/ProofOfResidenceLightBox'
import {get_latest_loan} from 'src/lib/loans/utils'
import {getActiveAccount} from 'src/lib/card/selectors'
import OverduePayment from 'src/products/loans/UserRequestedAction/OverduePayment'
import Log from 'src/lib/loggingUtil'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {get_ura_ui_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {
  URAUIUpdateAction,
  URAUpdateAction,
  URAUpdateActionProps,
} from 'src/lib/ura/actions/uraActions'
import {UserLoginStates} from 'src/api/reducers/types'
import {ShowException} from 'src/lib/errors'
import {PfReduxState} from 'src/reducers/types'
import {PendingURAType} from 'src/lib/ura/types'
import LinkUsableAccount, {
  LinkUsableAccountUraType,
} from 'src/products/loans/UserRequestedAction/LinkUsableAccount'
import BankAddressMismatch from 'src/products/loans/UserRequestedAction/BankAddressMismatch'
import {UnfreezeBureauFrozenFile} from 'src/products/loans/UserRequestedAction/UnfreezeBureauFrozenFile/UnfreezeBureauFrozenFile'
import {usePfDispatch} from 'src/store/utils'
import {UploadDocumentsModal} from 'src/products/loans/UserRequestedAction/UploadDocumentsModal'
import AppEvents from 'src/lib/Analytics/app_events'
import {ChangeDefaultPaymentMethod} from 'src/products/loans/UserRequestedAction/ChangeDefaultPaymentMethod'

const genericURAAction = (ura_id: string): React.ReactElement => <URAOther ura_id={ura_id} />

const linkAnotherAccountAction = (ura_id: string): React.ReactElement => (
  <LinkAnotherAccount ura_id={ura_id} />
)
const retakeDLPictureAction = (ura_id: string): React.ReactElement => (
  <RetakeLightBox ura_id={ura_id} routeName={'RetakeFrontDL'} />
)
const proofOfResidenceAction = (ura_id: string): React.ReactElement => (
  <ProofOfResidenceLightBox ura_id={ura_id} />
)
const ssnCardAction = (ura_id: string): React.ReactElement => (
  <RetakeLightBox ura_id={ura_id} routeName={'SSNCard'} />
)
const paymentsOverdue = (ura_id: string): React.ReactElement => <OverduePayment uraId={ura_id} />

const URALookUp = new Map([
  ['other', genericURAAction],
  ['unrecognized', genericURAAction],
  [
    'verify_name_matches_id',
    (ura_id: string): React.ReactElement => {
      return <URAVerifyNameMatchesID ura_id={ura_id} />
    },
  ],
  ['payment_reschedule_request', genericURAAction],
  [
    'update_pii_ssn',
    (ura_id: string): React.ReactElement => {
      return <URAUpdateSSN ura_id={ura_id} />
    },
  ],
  ['ssn_card', ssnCardAction],
  [
    'update_pii_address',
    (ura_id: string): React.ReactElement => {
      return <URAUpdateAddress ura_id={ura_id} />
    },
  ],
  ['link_account_with_more_data', linkAnotherAccountAction],
  ['link_supported_institution', linkAnotherAccountAction],
  ['link_supported_accounts', linkAnotherAccountAction],
  [
    'payment_instrument_update_required',
    (ura_id: string): React.ReactElement => {
      return <LinkAnotherAccount hideSupportedBanks ura_id={ura_id} />
    },
  ],
  [
    'payments_reauth_required',
    (ura_id: string): React.ReactElement => {
      return <RelinkAccount uraId={ura_id} />
    },
  ],
  [
    'relink_account',
    (ura_id: string): React.ReactElement => {
      return <RelinkAccount uraId={ura_id} />
    },
  ],
  ['retake_id_corners', retakeDLPictureAction],
  ['retake_id_expired', retakeDLPictureAction],
  ['retake_id_fingers', retakeDLPictureAction],
  ['retake_id_form', retakeDLPictureAction],
  ['retake_id_front', retakeDLPictureAction],
  ['retake_id_lighting', retakeDLPictureAction],
  ['identity_docs_required', retakeDLPictureAction],
  [
    'retake_liveness',
    (ura_id: string): React.ReactElement => {
      return <RetakeLightBox ura_id={ura_id} routeName={'RetakeLiveness'} />
    },
  ],
  ['out_of_state', proofOfResidenceAction],
  [
    'por_bank_address_mismatch',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <BankAddressMismatch ura_id={ura_id} />
    },
  ],
  ['por_date', proofOfResidenceAction],
  ['por_ln_mismatch', proofOfResidenceAction],

  [
    'account_number_required_ach',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <AchAccountNumber ura_id={ura_id} />
    },
  ],
  [
    'account_number_required_interchange',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <InterchangeAccountNumberLightBox ura_id={ura_id} />
    },
  ],
  ['contact_us', genericURAAction],
  ['payments_overdue', paymentsOverdue],
  [
    'link_personal_account',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount uraId={ura_id} type={LinkUsableAccountUraType.link_personal_account} />
      )
    },
  ],
  [
    'link_account_with_new_name',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount
          uraId={ura_id}
          type={LinkUsableAccountUraType.link_account_with_new_name}
        />
      )
    },
  ],
  [
    'relink_account_set_primary',
    (ura_id: string): React.ReactElement => {
      return (
        <LinkUsableAccount
          uraId={ura_id}
          type={LinkUsableAccountUraType.relink_account_set_primary}
        />
      )
    },
  ],
  [
    'unfreeze_bureau_frozen_file',
    (ura_id: string): React.ReactElement => {
      // @ts-expect-error requires props added by caller
      return <UnfreezeBureauFrozenFile ura_id={ura_id} />
    },
  ],
  [
    'joint_account_verification',
    (ura_id: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <UploadDocumentsModal
          ura_id={ura_id}
          uraViewedEvent={{
            eventName: AppEvents.Name.joint_account_verifcation_URA_viewed,
            eventCategory: AppEvents.Category.InitialApplication,
          }}
          uploadDocumentsRoute={'JointAccountVerification'}
        />
      )
    },
  ],
  [
    'docs_proof_of_income',
    (ura_id: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <UploadDocumentsModal
          ura_id={ura_id}
          uraViewedEvent={{
            eventName: AppEvents.Name.proof_of_income_URA_viewed,
            eventCategory: AppEvents.Category.InitialApplication,
          }}
          uploadDocumentsRoute={'ProofOfIncome'}
        />
      )
    },
  ],
  [
    'change_default_payment_method_request',
    (uraId: string): React.ReactElement => {
      return (
        // @ts-expect-error requires props added by caller
        <ChangeDefaultPaymentMethod uraId={uraId} />
      )
    },
  ],
])

const URA_INTERVAL = Math.round(5000 + Math.random() * 2500)
const MAINTENANCE_INTERVAL = Math.round(5000 * 60 + Math.random() * 2500)

export const UseUra: FC<React.PropsWithChildren> = (props) => {
  const {children} = props
  const dispatch = usePfDispatch()
  const store = useStore<PfReduxState>()
  const state = store.getState()
  const inMaintenance = maintenanceOn(state)
  const isLogged = state?.api?.user_logged_state === UserLoginStates.logged_in
  const [liveURA, setLiveURA] = useState<PendingURAType | null>(null)

  const _closeURA = useCallback(
    (uraUi: URAUpdateActionProps[number], uraId: string) => {
      const close = uraUi.close
      dispatch(
        URAUIUpdateAction(
          [{id: uraId, componentId: null, close: null, prompted: false}],
          'MergeRecord',
        ),
      )
      close?.()
    },
    [dispatch],
  )

  const onDismissUra = async (uraId: string): Promise<void> => {
    try {
      if (uraId) {
        await completeUra(uraId)
      }
    } catch (e) {
      ShowException(e)
    }
  }

  const showLightboxForURA = useCallback(
    (action: PendingURAType, urasLive: Map<string, boolean>): void => {
      try {
        urasLive.set(action.id, true)
        const uraUi = get_ura_ui_by_id_from_redux(action.id, state)

        if (action.status === UserRequestedActionStatusCode.Pending && !uraUi?.prompted) {
          if (uraUi) {
            uraUi.prompted = true
          }

          dispatch(URAUIUpdateAction([uraUi ?? {id: action.id, prompted: true}], 'MergeRecord'))
          setLiveURA(action)
        } else if (
          (action.status === UserRequestedActionStatusCode.Completed ||
            action.status === UserRequestedActionStatusCode.Expired) &&
          uraUi?.close
        ) {
          _closeURA(uraUi, action.id)
        }
      } catch (e) {
        if (e instanceof Error) {
          Log.warn(`URA Handler, e : ${e.toString()}`)
        }
      }
    },
    [state, dispatch, _closeURA],
  )

  const executePendingUras = useCallback(async () => {
    try {
      const pendingUras: (PendingURAType | null)[] = (await GetPendingUserRequestedActions()) ?? []

      if (!pendingUras || pendingUras.length == 0) {
        return
      }

      const urasLive = new Map<string, boolean>()

      const loan = get_latest_loan(state?.lib?.loans?.loans)
      const hasCardAccount = !!getActiveAccount(state)

      if (!loan && !hasCardAccount) {
        return
      }

      if (pendingUras) {
        dispatch(URAUpdateAction(pendingUras))
      }

      for (const action of pendingUras) {
        if (action) {
          showLightboxForURA(action, urasLive)
        }
      }

      if (state.lib.ura) {
        for (const uraId of state.lib.ura.ura_ui.keys()) {
          // URA no longer in the pending list ...
          if (!urasLive.get(uraId)) {
            Log.log(`${uraId} close ....`)
            const uraUi = get_ura_ui_by_id_from_redux(uraId, state)

            if (uraUi?.close && uraUi.prompted) {
              _closeURA(uraUi, uraId)
            }

            urasLive.delete(uraId)
          }
        }
      }
    } catch (e) {
      Log.warn(e)
    }
  }, [state, showLightboxForURA, dispatch, _closeURA])

  useEffect(() => {
    if (isLogged) {
      const interval = setInterval(
        () => {
          void executePendingUras()
        },
        inMaintenance ? MAINTENANCE_INTERVAL : URA_INTERVAL,
      )
      return () => clearInterval(interval)
    }
  }, [isLogged, inMaintenance, executePendingUras])

  useEffect(() => {
    if (liveURA !== null) {
      const canClear = liveURA.optional
      const handler = URALookUp.get(String(liveURA.actionType).toLowerCase())

      if (handler) {
        const ura_id = liveURA.id
        dispatch(UserStateRefresh())
        ShowLightbox(handler(ura_id), !canClear, false, `URAModal${ura_id}`, {
          onBackdropClose: () => {
            void onDismissUra(ura_id)
          },
        })
      } else {
        Log.log(`No handler for action : ${liveURA.actionType}`)
      }
    }
  }, [dispatch, liveURA])

  return <>{children}</>
}
