import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {PaymentReviewTilaAndLoanAgreementCARESTContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCARESTContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ModuleControlProps} from 'src/flows/types'
import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {CALoanFinalActivationNavigationScreenAnalytics} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCA.utils'

type PaymentReviewTilaAndLoanAgreementCAMPOContainerProps = ModuleControlProps &
  Pick<StackScreenProps<MainStackParamList, 'PaymentReviewTilaAndLoanAgreementCA'>, 'navigation'>

const PaymentReviewTilaAndLoanAgreementCAMPOContainer: FC<
  PaymentReviewTilaAndLoanAgreementCAMPOContainerProps
> = (props) => {
  const {onStepComplete, moduleName} = props

  CALoanFinalActivationNavigationScreenAnalytics('MPO')

  useMPOAndroidToWeb(moduleName)

  const handleOnContinue = async (): Promise<void> => {
    if (onStepComplete) {
      await onStepComplete()
    }
  }

  return <PaymentReviewTilaAndLoanAgreementCARESTContainer onContinue={handleOnContinue} />
}

export {PaymentReviewTilaAndLoanAgreementCAMPOContainer}
