import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import ImagePicker from 'src/products/general/ImagePicker'
import {getImagePickerDefaults} from 'src/lib/utils/formData'
import Log from 'src/lib/loggingUtil'
import {ImageUploadList} from 'src/products/general/UploadDocuments/ImageUploadList'
import {uploadAndCreateDocument} from 'src/lib/utils/files'
import {
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {usePfSelector} from 'src/store/utils'
import {userIdSelector} from 'src/api/selectors/selectors'
import Snackbar from 'src/lib/Snackbar'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'

type File = {
  uri: string
  type: string
  fileName: string
  isUploading: boolean
  hasError: boolean
  key: number
}

type UploadDocumentProps = {
  onContinue: () => Promise<void>
  documentSubType: UserDocumentSubType
  title: string
  body: string | JSX.Element
}

export const UploadDocuments: React.FC<UploadDocumentProps> = (props) => {
  const {onContinue, title, body, documentSubType} = props
  const userId = usePfSelector(userIdSelector)
  const {t} = useTranslation(['UserRequestedAction'])

  const [files, setFiles] = useState<File[]>([])
  const [isOnContinueLoading, setIsOnContinueLoading] = useState(false)

  async function handleOnCaptureWithImagePicker(): Promise<void> {
    // Get image from image picker
    let file: File
    try {
      const response = await ImagePicker.launchImageLibrary({
        mediaType: 'photo',
        selectionLimit: 1,
      })

      if (response.didCancel) {
        Log.log('User cancelled image picker')
        return
      }

      if (response.errorCode || response.errorMessage) {
        Log.error(`ImagePicker Error: ${response.errorCode} ${response.errorMessage}`)
        Snackbar.error({
          title: t('UnableToOpenImagePickerError', {errorMessage: response.errorMessage}),
          duration: Snackbar.LENGTH_LONG,
        })

        return
      }

      if (!response.assets?.length || !response.assets[0].uri) {
        Log.log('ImagePicker: No assets returned')
        return
      }

      const {fileName, type} = getImagePickerDefaults(response.assets[0].uri)
      file = {
        uri: response.assets[0].uri,
        type: response.assets[0].type ?? type,
        fileName: response.assets[0].fileName ?? fileName,
        isUploading: true,
        hasError: false,
        key: Date.now(),
      }
    } catch (e) {
      Log.error(e, 'UploadDocuments failed to open image picker.')
      Snackbar.error({
        title: t('UnableToOpenImagePickerError'),
        duration: Snackbar.LENGTH_LONG,
      })
      return
    }

    // Upload selected image
    try {
      setFiles((files) => files.concat([file]))

      await uploadAndCreateDocument(
        {
          uri: file.uri,
          fileName: file.fileName,
          type: file.type,
        },
        UserDocumentType.Identity,
        documentSubType,
        userId,
      )
      setFiles((files) => files.slice(0, -1).concat([{...file, isUploading: false}]))
    } catch (e) {
      setFiles((files) =>
        files.slice(0, -1).concat([{...file, isUploading: false, hasError: true}]),
      )
      Log.error(e, 'UploadDocuments failed to upload image.')
      Snackbar.error({
        title: t('UnableToUploadImage'),
        duration: Snackbar.LENGTH_LONG,
      })
    }
  }

  async function handleOnContinue(): Promise<void> {
    try {
      setIsOnContinueLoading(true)
      await onContinue()
    } finally {
      setIsOnContinueLoading(false)
    }
  }

  return (
    <Page
      noHeaderSpacer
      smallTopGap
      variant="generic"
      title={
        <>
          {/*
            the default page header spacer doesnt look right when this page is embedded in a ShowLightbox modal
            for URAs so we add it manually here instead and set noHeaderSpacer={true}. see ENG-17521
          */}
          <HeaderSpacer />
          <PFText>{title}</PFText>
        </>
      }
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: 'Continue',
          onPress: handleOnContinue,
          loading: isOnContinueLoading,
          disabled:
            files.length < 1 ||
            files.some((file) => file.isUploading) ||
            files.every((file) => file.hasError) ||
            isOnContinueLoading,
          testID: 'UploadDocuments-ContinueBtn',
        },
      }}
    >
      <PFText variant="p">{body}</PFText>
      <ImageUploadList
        items={files.map((file) => ({
          image: file.uri,
          name: t('Document'),
          isLoading: file.isUploading,
          hasError: file.hasError,
          key: file.key,
        }))}
        onPress={handleOnCaptureWithImagePicker}
      />
    </Page>
  )
}
