import React from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type Props = {
  onContinue: () => void
}

const LivenessComplete: React.FC<Props> = (props) => {
  const {t} = useTranslation(['Liveness', 'Common'])
  const action = {
    text: t('Common:Continue'),
    onPress: props.onContinue,
    disabled: false,
  }

  return (
    <Page
      variant={'generic'}
      buttonProps={buttonLockupProperties(action)}
      title={t('LivenessCheckComplete')}
      smallTopGap
    />
  )
}

export default LivenessComplete
