import React, {ReactNode, useState} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'

export type ProductIntroductionStep = {
  title: string
  subtitle?: string
  illustration: ReactNode
  testID?: string
}

type ProductIntroductionProps = {
  title: string
  subtitle?: string
  actionText?: string
  level?: string
  cost?: string
  steps: ProductIntroductionStep[]
  primaryButton: ButtonLockupPropsPrimary
  testIDValues?: {
    page?: string
    title?: string
    subTitle?: string
    actionText?: string
    level?: string
    cost?: string
    primaryButton?: string
  }
}

/**
 * Introduction to a single feature of a product with an image, title and description.
 */
const ProductIntroductionStepComponent: React.FC<ProductIntroductionStep> = (props) => {
  const {title, subtitle, illustration, testID} = props

  return (
    <Box gap="medium" testID={testID}>
      <Box fill="horizontal" align="center">
        {illustration}
      </Box>
      <Box gap="little">
        <PFText variant="h3">{title}</PFText>
        <PFText variant="p">{subtitle}</PFText>
      </Box>
    </Box>
  )
}

/**
 * Provides a visual introduction to the features of a product. Intro is split into different "steps" each with
 * an image, title and description.
 */
const ProductIntroduction: React.FC<ProductIntroductionProps> = (props) => {
  const {title, subtitle, level, actionText, cost, steps, primaryButton, testIDValues} = props

  const [busy, setBusy] = useState(false)

  const handlePrimaryPress = async (): Promise<void> => {
    setBusy(true)
    await primaryButton.onPress?.()
    setBusy(false)
  }

  return (
    <Page variant="generic" smallTopGap title={title} testID={testIDValues?.page}>
      <Box gap="enormous" paddingBottom="medium">
        <Box>
          <Box gap="medium">
            {level ? (
              <PFText variant="p" testID={testIDValues?.level}>
                {level}{' '}
                <PFText variant="p_semibold" testID={testIDValues?.cost}>
                  {cost ?? ''}
                </PFText>
              </PFText>
            ) : null}
            {subtitle ? (
              <PFText variant="p_semibold" testID={testIDValues?.subTitle}>
                {subtitle}
              </PFText>
            ) : null}
            {actionText ? (
              <PFText variant="h3" testID={testIDValues?.actionText}>
                {actionText}
              </PFText>
            ) : null}
          </Box>

          <Box gap="enormous" paddingVertical="enormous">
            {steps.map((step) => (
              <ProductIntroductionStepComponent key={step.title} {...step} testID={step.testID} />
            ))}
          </Box>
        </Box>

        <Button
          mode="primary"
          size="large"
          disabled={busy}
          loading={busy}
          onPress={handlePrimaryPress}
          width="100%"
          testID={testIDValues?.primaryButton}
        >
          {primaryButton.text}
        </Button>
      </Box>
    </Page>
  )
}

export default ProductIntroduction
