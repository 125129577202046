import React, {FC} from 'react'

import {ModuleControlProps} from 'src/flows/modules/types'
import NavPageState from 'src/navigation/NavPageState'
import {LoanProcessingCounterOfferGQLContainer} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferGQLContainer'
import {LoanCounterOfferNavigationProps} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferTemplate'

export const LoanProcessingCounterOfferMPOContainer: FC<
  ModuleControlProps & LoanCounterOfferNavigationProps
> = (props) => {
  const {onModuleComplete, navigation} = props

  const handleOnContinue = async (): Promise<void> => {
    if (onModuleComplete) {
      await onModuleComplete('LOAN_ACCEPTANCE')
    } else {
      const nextPage = NavPageState.GetNextApprovalState('LoanCounterOffer')
      await NavPageState.GoToScreen(navigation, nextPage)
    }
  }

  const handleOnCancel = async (): Promise<void> => {
    if (onModuleComplete) {
      await onModuleComplete('LOAN_END')
    } else {
      await NavPageState.GoToScreen(navigation, {screen: 'Dashboard'})
    }
  }

  return (
    <LoanProcessingCounterOfferGQLContainer
      onContinue={handleOnContinue}
      onCancel={handleOnCancel}
    />
  )
}
