import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {gutterSize} from 'src/designSystem/layout'
import {ExpandableSection} from 'src/products/loans/LoanAmountSelectionV2/ExpandableSection'

type LoanAmountSelectionV2TemplateProps = {
  estimatedTotal: string
  installmentAmount: string
  totalInterestAndFees: string
  APRPercent: string
  isLoadingEstimatedCosts: boolean
}

export const EstimatedTotal: React.FC<LoanAmountSelectionV2TemplateProps> = (props) => {
  const {
    estimatedTotal,
    installmentAmount,
    totalInterestAndFees,
    APRPercent,
    isLoadingEstimatedCosts,
  } = props

  const [showAPRInfo, setShowAPRInfo] = React.useState<boolean>(false)

  const {t} = useTranslation('LoanAmountSelectionV2')

  return (
    <ExpandableSection
      showLoading={isLoadingEstimatedCosts}
      title={t('EstimatedTotal', {estimatedTotal})}
    >
      <Box>
        <PFText variant="p_tiny_semibold">{t('PaymentExplanation', {installmentAmount})}</PFText>
      </Box>
      <Box direction="row" justify="between" marginTop={gutterSize} align="center">
        <Box>
          <PFText variant="p" textProps={{style: styles.smallLineHeight}}>
            {t('InstallmentsOf')}
          </PFText>
          <PFText variant="p_tiny">{t('EveryTwoWeeks')}</PFText>
        </Box>
        <Box boxStyle={styles.amountBox}>
          <PFText variant="p_semibold" color="primary">{`$${installmentAmount} x 4`}</PFText>
        </Box>
      </Box>

      <Box direction="row" justify="between" marginTop={gutterSize} align="center">
        <Box>
          <PFText variant="p" textProps={{style: styles.smallLineHeight}}>
            {t('TotalInterest')}
          </PFText>
          <PFText variant="p_tiny">
            <Trans
              t={t}
              i18nKey="TranslatesToAPR"
              values={{APRPercent}}
              components={{
                underline: (
                  <PFText
                    variant="p_tiny"
                    onPress={(): void => setShowAPRInfo(true)}
                    textProps={{style: {textDecorationLine: 'underline'}}}
                  />
                ),
              }}
            />
          </PFText>
        </Box>

        <Box boxStyle={styles.amountBox}>
          <PFText variant="p_semibold" color="primary">{`$${totalInterestAndFees}`}</PFText>
        </Box>
      </Box>

      <Box
        direction="row"
        justify="between"
        marginTop={gutterSize}
        align="center"
        boxStyle={styles.amountBox}
      >
        <PFText variant="p_semibold">{t('Total')}</PFText>
        <PFText variant="p_semibold">{`$${estimatedTotal}`}</PFText>
      </Box>
      <BottomSheet
        visible={showAPRInfo}
        title={t('HowAPRCalculated')}
        titleAlign="left"
        titleVariant="h3"
        onDismiss={(): void => setShowAPRInfo(false)}
        showDots={false}
        showCloseButton
        dismissButton={{text: t('DismissButtonLabel')}}
        fullWidthTitle={false}
      >
        <PFText variant="p">{t('APRExplanation')}</PFText>
      </BottomSheet>
    </ExpandableSection>
  )
}

const styles = StyleSheet.create({
  smallLineHeight: {
    lineHeight: 18,
  },
  amountBox: {
    backgroundColor: NamedColors.WHITE,
    borderRadius: 8,
    paddingLeft: gutterSize,
    paddingRight: gutterSize,
    paddingTop: gutterSize / 2,
    paddingBottom: gutterSize / 2,

    marginRight: -gutterSize,
    marginLeft: -gutterSize,
  },
})
