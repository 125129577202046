import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  RefObject,
} from 'react'
import {Animated, TextInputProps, TouchableWithoutFeedback, View} from 'react-native'

import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {animationEasing, animationDuration} from 'src/designSystem/animations'
import {formFieldProps} from 'src/designSystem/components/atoms/HookForm/HookForm'
import Input from 'src/designSystem/components/atoms/PFTextInput/Input'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import PFFieldBase from 'src/designSystem/components/atoms/PFFieldBase/PFFieldBase'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
  textButtonProps,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import styles from 'src/designSystem/components/atoms/PFTextInput/styles'
import {getErrorState, getBaseFieldState} from 'src/designSystem/lib/fieldUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'

const getFieldActive = (isValueEmpty: boolean, hasFocus: boolean): boolean => {
  return !isValueEmpty || hasFocus
}

export type autoCapitalizeProps = 'none' | 'sentences' | 'words' | 'characters'

export type PFTextInputProps = TextInputProps & {
  label: string
  value?: string
  onPress?: () => void
  onPressIcon?: () => void
  onFocus?: () => void
  onBlur?: () => void
  changeFilter?: any
  onChangeText?: (text: string) => void
  error?: string
  infoMessage?: string
  icon?: SvgIconProps
  errorOnEmpty?: boolean
  editable?: boolean
  autoCorrect?: boolean
  autoCapitalize?: autoCapitalizeProps
  multiline?: boolean
  numberOfLines?: number
  formProps?: formFieldProps
  onSubmitEditing?: () => void
  returnKeyType?: string
  keyboardType?: string
  maxLength?: number
  secureTextEntry?: boolean
  autoCompleteType?: string
  textContentType?: string
  testID?: string
  textButton?: textButtonProps
  ref?: React.Ref<any>
  editableStyle?: boolean
  iconTestID?: string
}

const PFTextInput: FC<PFTextInputProps> = forwardRef(
  (props: PFTextInputProps, ref: React.Ref<any>) => {
    const {
      label,
      value,
      multiline,
      numberOfLines,
      changeFilter,
      onPress,
      onFocus,
      onBlur,
      onPressIcon,
      error,
      errorOnEmpty,
      infoMessage,
      editable,
      icon,
      onChangeText,
      textButton,
      testID,
      editableStyle,
      iconTestID,
      ...otherProps
    } = props

    const inputEl: RefObject<any> = useRef(null)
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputEl?.current?.focus()
      },
      blur: () => {
        inputEl?.current?.blur()
      },
      clear: () => {
        inputEl?.current?.clear()
      },
    }))

    const [isFocused, setIsFocused] = useState(false)
    const [isFirstAttempt, setIsFirstAttempt] = useState(true)
    const [textInputState, setTextInputState] = useState<PFFieldBaseStateTypes>(
      PFFieldBaseStates.EMPTY,
    )
    const isValueEmpty: boolean = isStringValueEmpty(value)
    const hasIcon = !!icon
    const animationControl: Animated.Value = useMemo(
      () => new Animated.Value(isValueEmpty ? 0 : 1),
      [isValueEmpty],
    )

    useEffect(() => {
      if (isDeviceWeb()) {
        const timer = setTimeout(() => {
          if (inputEl?.current?.matches(':-internal-autofill-selected')) {
            runAnimations(true)
            setTextInputState(PFFieldBaseStates.DEFAULT)
          }
        }, 600)
        return () => clearTimeout(timer)
      }
    }, [])

    useEffect(() => {
      const errorState = getErrorState(errorOnEmpty, isValueEmpty, isFirstAttempt, error)
      const newState = getBaseFieldState(errorState, isValueEmpty, isFocused)
      setTextInputState(newState)
    }, [value, isFocused, isValueEmpty, errorOnEmpty, error, isFirstAttempt])

    const runAnimations: (hasFocus: boolean) => void = useCallback(
      (hasFocus: boolean) => {
        Animated.timing(animationControl, {
          toValue: hasFocus ? 1 : 0,
          duration: animationDuration,
          useNativeDriver: false,
          easing: animationEasing,
        }).start()
      },
      [animationControl],
    )

    useEffect(() => {
      runAnimations(getFieldActive(isValueEmpty, isFocused))
    }, [isValueEmpty, isFocused, runAnimations])

    const renderOverrideView = (onPressOverride) => {
      if (isDeviceWeb() && multiline) {
        //disabling to allow textarea resize on web
        return
      }

      const onPressFieldWrapper = () => {
        inputEl?.current?.focus()
        onPressOverride()
      }

      return (
        <TouchableWithoutFeedback onPress={onPressFieldWrapper}>
          <View style={styles.overrideView} />
        </TouchableWithoutFeedback>
      )
    }

    const onBlurFn = () => {
      setIsFocused(false)
      setIsFirstAttempt(false)

      onBlur?.()
    }

    const onFocusFn = () => {
      setIsFocused(true)
      onFocus?.()
    }

    const onChangeTextFn = (newValue) => {
      if (changeFilter) {
        newValue = changeFilter(newValue)
      }
      onChangeText?.(newValue)
    }

    return (
      <PFFieldBase
        label={label}
        animationControl={animationControl}
        infoMessage={infoMessage}
        error={error}
        editable={editable || editableStyle}
        fieldState={textInputState}
        icon={icon}
        onPressIcon={onPressIcon}
        textButton={textButton}
        placeholder={otherProps.placeholder}
        testID={iconTestID}
      >
        <Input
          ref={inputEl}
          value={value}
          multiline={multiline}
          hasIcon={hasIcon}
          editable={editable}
          numberOfLines={numberOfLines}
          onFocus={onFocusFn}
          onBlur={onBlurFn}
          onChangeText={onChangeTextFn}
          accessible={true}
          testID={testID}
          {...otherProps}
        />
        {onPress ? renderOverrideView(onPress) : null}
      </PFFieldBase>
    )
  },
)

PFTextInput.displayName = 'PFTextInput'

PFTextInput.defaultProps = {
  editable: true,
  multiline: false,
  numberOfLines: 1,
}

export default PFTextInput
