import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation, WithTranslation, Trans} from 'react-i18next'

import {PushPage} from 'src/navigation/NavHelper'
import APIClient from '@possible/generated/APIClient'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {allowedFullStatesList} from 'src/lib/us_states'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PfReduxState} from 'src/reducers/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {userIdSelector} from 'src/api/selectors/selectors'

type Props = PropsFromRedux &
  WithTranslation &
  StackScreenProps<MainStackParamList, 'OutOfState'> & {
    state: string
    userId: string
  }

const OutOfState: FC<Props> = (props) => {
  const {t} = useTranslation('PersonalInformation')
  const {state, navigation, userId, abEnabledStates} = props

  const outOfState = (): void => {
    APIClient.user_misc_props_new({
      property: 'out_of_state',
      value: JSON.stringify({state}),
      version: 1,
      user_id: userId,
    })

    PushPage(navigation, 'UnsupportedStateWaitList', {
      state,
    })
  }

  const description = (list): JSX.Element => (
    <PFText variant={'p'}>
      <Trans i18nKey={'CurrentlyWeAreOnlyAvailableIn'} t={t}>
        Currently, we are only available in <PFText variant={'p_semibold'}>{{list}}</PFText>.
      </Trans>
      {'\n\n'}
      {t('BasedOnAddressProvidedYouDoNotLiveInOneStates')}
    </PFText>
  )

  const onLiveHere = (): void => {
    navigation?.goBack()
  }

  const action = {
    onPress: () => onLiveHere(),
    text: t('ILiveInAServicedState'),
  }

  const secondary = {
    onPress: () => outOfState(),
    buttonText: t('IDoNotLiveInAServicedState'),
  }

  return (
    <GenericNonModalTemplate
      title={t('OutOfStateResidency')}
      actionBlock={buttonLockupProperties(action, secondary)}
      justifyContent={styles.pageBodyStyle}
    >
      {description(allowedFullStatesList(abEnabledStates))}
      <View style={styles.question}>
        <PFText variant={'p'}>{t('DoYouLiveInAServicedState')}</PFText>
      </View>
    </GenericNonModalTemplate>
  )
}

const mapStateToProps = (state: PfReduxState) => {
  return {
    userId: userIdSelector(state),
    abEnabledStates: abFlaggedStatesEnabled(state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default withForwardedNavigationParams<Props>()(connector(OutOfState))

const styles = StyleSheet.create({
  pageBodyStyle: {
    justifyContent: 'flex-start',
  },
  question: {
    marginTop: 24,
  },
})
