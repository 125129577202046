import React, {FC, useEffect} from 'react'
import {StyleSheet, View} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect} from '@react-navigation/native'

import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ZendeskSsoForm} from 'src/products/general/ZendeskHelpCenter/ZendeskSsoForm'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {UserGenerateZendeskTokenDocument} from 'src/products/general/ZendeskHelpCenter/queries/UserGenerateZendeskToken.gqls'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'

type Props = StackScreenProps<MainStackParamList, 'ZendeskHelpCenter'>

const ZendeskHelpCenter: FC<Props> = (props) => {
  const {route} = props
  const [generateZdToken, {loading: isLoadingZdToken, data: zdTokenData}] = useCassandraMutation(
    UserGenerateZendeskTokenDocument,
  )

  useFocusEffect(() => {
    sendAnalyticEvent(ampli.helpCenterRedirectViewed.bind(ampli))
  })

  const isSecureMessagingFlagEnabled = useIsFeatureFlagEnabled('secure-message-center')

  const webviewSource = isSecureMessagingFlagEnabled ? undefined : route.params?.source

  useEffect(() => {
    if (!webviewSource) {
      void generateZdToken()
    }
  }, [generateZdToken, webviewSource])

  return (
    <View style={styles.container}>
      <View style={styles.headerHeight} />
      {isLoadingZdToken ? (
        <Spinner />
      ) : (
        <ZendeskSsoForm
          source={webviewSource}
          token={zdTokenData?.userGenerateZendeskToken.token}
        />
      )}
    </View>
  )
}

export {ZendeskHelpCenter}

const styles = StyleSheet.create({
  container: {
    backgroundColor: genericPageBackground,
    flex: 1,
  },
  headerHeight: {
    height: pfHeaderHeight,
  },
})
