import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanExpiredTileProps = {
  onApplyForNewLoan: ButtonAction
  wasApproved: boolean
  testID?: string
}
/**
 * A tile to inform the user that their loan has expired.
 */
const LoanExpiredTile: React.FC<LoanExpiredTileProps> = ({
  onApplyForNewLoan,
  testID,
  wasApproved,
}) => {
  const {t} = useTranslation('DashboardLoanExpiredTile')
  return (
    <BasicTile padding="small" align="center" testID={testID}>
      <PFText variant="h3" textAlign="center">
        {t('LoanHasExpired')}
      </PFText>
      <Box marginTop="small" gap="small">
        <PFText variant="p" textAlign="center">
          {wasApproved ? t('WeApprovedButExpired') : t('PleaseReapply')}
        </PFText>
        {wasApproved ? (
          <PFText variant="p" textAlign="center">
            {t('ApprovalAmountMayVary')}
          </PFText>
        ) : null}
      </Box>
      <Box width="100%" align="center" marginTop="medium">
        <Button
          mode="primary"
          size="medium"
          width="100%"
          onPress={onApplyForNewLoan}
          testID="LoanExpiredApplyNowBtn"
        >
          {t('ApplyNow')}
        </Button>
      </Box>
    </BasicTile>
  )
}

export default LoanExpiredTile
