import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {AdverseReasonCode} from '@possible/cassandra/src/types/types.iam.generated'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {InfoTile} from 'src/designSystem/components/molecules/InfoTile/InfoTile'
import {basicTileElevation} from 'src/designSystem/guide'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {ConfirmDeclineCounterOfferOverlay} from 'src/products/loans/Dashboard/LoanCounterOfferTile/ConfirmDeclineCounterOfferOverlay/ConfirmDeclineCounterOfferOverlay'
import {getAdverseReasonDescription} from 'src/products/loans/LoanProcessing/LoanProcessing.utils'
import {LoanProcessingCounterOfferTemplateInner} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferTemplate'
import {useLoansActivationWorkflowEnabled} from 'src/workflows/featureFlags'

export type LoanCounterOfferTileProps = {
  onViewCounterOffer: () => void
  onDeclineCounterOffer: () => void
  onContinueWithCounterOffer: () => void
  amountApproved: number
  amountRequested: number
  testID?: string
  firstName: string
  counterOfferCodes: (AdverseReasonCode | null)[]
  expirationDate: Date
}

const LoanCounterOfferTile: React.FC<LoanCounterOfferTileProps> = (
  props: LoanCounterOfferTileProps,
) => {
  const {t} = useTranslation('DashboardLoanCounterOffer')
  const {
    firstName,
    counterOfferCodes,
    onViewCounterOffer: handleOnViewCounterOffer,
    onDeclineCounterOffer,
    onContinueWithCounterOffer,
    amountApproved,
    amountRequested,
    testID = 'LoanCounterOfferTile',
    expirationDate,
  } = props
  const [showConfirmDeclineCounterOfferOverlay, setShowConfirmDeclineCounterOfferOverlay] =
    useState(false)
  const isAtLeastHalfApproved = amountApproved >= amountRequested / 2
  const expirationDateString = `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}`

  const isLoansActivationWorkflowEnabled = useLoansActivationWorkflowEnabled()

  const handleOnDeclineCounterOffer = (): void => {
    TrackAppEvent(
      LoanDashboardEvents.approved_decline_counter_offer_selected,
      AppEvents.Category.LoanDashboard,
    )
    setShowConfirmDeclineCounterOfferOverlay(true)
  }

  const handleOnContinueWithCounterOffer = (): void => {
    onContinueWithCounterOffer()
    setShowConfirmDeclineCounterOfferOverlay(false)
  }

  const handleOnConfirmCancel = (): void => {
    onDeclineCounterOffer()
    setShowConfirmDeclineCounterOfferOverlay(false)
  }

  const reason = getAdverseReasonDescription(counterOfferCodes[0] ?? undefined)

  if (!isLoansActivationWorkflowEnabled) {
    return (
      <Box elevation={basicTileElevation} testID={testID}>
        <InfoTile
          style={styles.infoTileContainer}
          headerProps={{
            subTitle: (
              <Box>
                <Box marginBottom={'little'}>
                  <PFStatusPill
                    text={t('LimitedTimeOffer')}
                    color={'disabled'}
                    fontColor={'black'}
                  />
                </Box>
                <PFText variant={'h2'} textAlign={'left'}>
                  {t(
                    isAtLeastHalfApproved
                      ? 'CounterOfferSubtitleOverFifty'
                      : 'CounterOfferSubtitle',
                    {amountApproved: convertToDollarAmt(amountApproved, false)},
                  )}
                </PFText>
              </Box>
            ),
            body: [
              <PFText variant={'p'} textAlign={'left'} key={1}>
                {t('CounterOfferBody', {expirationDate: expirationDateString})}
              </PFText>,
            ],
          }}
          primary={{
            text: t('CounterOfferConfirm'),
            onPress: handleOnViewCounterOffer,
            testID: 'ViewMyOffer-Btn',
          }}
          secondary={[
            {
              text: t('CounterOfferDecline'),
              onPress: handleOnDeclineCounterOffer,
              testID: 'DeclineOffer-Btn',
            },
          ]}
        />
        <ConfirmDeclineCounterOfferOverlay
          onConfirmCancel={handleOnConfirmCancel}
          onContinueWithLoan={handleOnContinueWithCounterOffer}
          showCancelLoanConfirmation={showConfirmDeclineCounterOfferOverlay}
        />
      </Box>
    )
  }

  return (
    <BasicTile padding="medium" testID={testID}>
      <LoanProcessingCounterOfferTemplateInner
        firstName={firstName}
        isOnContinueLoading={false}
        onCancel={handleOnDeclineCounterOffer}
        onContinue={handleOnViewCounterOffer}
        amountBorrowed={amountApproved.toString()}
        amountRequested={amountRequested.toString()}
        isAmountApprovedOverOrEqualFiftyPercent={isAtLeastHalfApproved}
        reason={reason}
      />

      <ConfirmDeclineCounterOfferOverlay
        onConfirmCancel={handleOnConfirmCancel}
        onContinueWithLoan={handleOnContinueWithCounterOffer}
        showCancelLoanConfirmation={showConfirmDeclineCounterOfferOverlay}
      />
    </BasicTile>
  )
}

export {LoanCounterOfferTile}

const styles = StyleSheet.create({
  infoTileContainer: {
    borderWidth: 0,
    marginHorizontal: 0,
  },
})
