import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {LoanGetTerms} from 'src/api/actions/loans/loanActions'
import {
  AddressCollectionTemplate,
  AddressCompletionMethod,
} from 'src/products/MCU/Address/AddressCollectionTemplate'
import {ModuleControlProps, OnboardingParamList} from 'src/flows/types'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {supportedState, useUpdateUserAddress} from 'src/products/MCU/Address/address.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StateSelectedDocument} from 'src/products/MCU/Address/AddressCollection.gqls'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {usePfDispatch, usePfSelector} from 'src/store/utils'

type Props = StackScreenProps<OnboardingParamList, 'HOME_ADDRESS'> &
  Required<Pick<ModuleControlProps, 'onModuleComplete' | 'onStepComplete'>>

const AddressMPOContainer: React.FC<Props> = (props) => {
  const {onModuleComplete} = props

  const [updateUserAddress] = useUpdateUserAddress(Consumer.types.AddressType.Home)

  const abEnabledStates = usePfSelector(abFlaggedStatesEnabled)

  const dispatch = usePfDispatch()

  const handleComplete: AddressCompletionMethod = useCallback(
    async (address, userState) => {
      if (supportedState(userState, abEnabledStates)) {
        const response = await dispatch(LoanGetTerms(userState))
        response.throwIfError()
      }

      await onModuleComplete()
    },
    [abEnabledStates, dispatch, onModuleComplete],
  )

  const {selectedData: selectedState, loading: isLoadingSelectedState} = useCassandraQuery(
    StateSelectedDocument,
    {
      fetchPolicy: 'network-only',
    },
    (data) => data.me.onboarding?.loan?.stateSelected,
  )

  return isLoadingSelectedState ? (
    <Loading type={'loader0'} size={'large'} />
  ) : (
    <AddressCollectionTemplate
      updateAddress={updateUserAddress}
      onComplete={handleComplete}
      selectedState={selectedState}
    />
  )
}

export {AddressMPOContainer}
