import React, {FC, ReactNode} from 'react'
import {View, TouchableOpacity, ViewStyle, StyleSheet} from 'react-native'
import Collapsible from 'react-native-collapsible'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgIconNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

export type CollapsibleCardProps = React.PropsWithChildren & {
  openTitle: string
  hideTitle: string
  open: boolean
  onChangeOpen?: (value) => void
  content: ReactNode
  contentStyle?: ViewStyle
}

const CollapsibleCard: FC<CollapsibleCardProps> = (props) => {
  const {open, onChangeOpen, children, openTitle, hideTitle, content, contentStyle} = props

  const svgIconName: SvgIconNames = open ? 'chevronUp' : 'chevronDown'
  const title = open ? hideTitle : openTitle

  return (
    <BasicTile>
      {children}

      <TouchableOpacity onPress={(): void => onChangeOpen?.(!open)}>
        <Box direction={'row'} align={'center'} padding={'small'}>
          <View style={styles.titleContainer}>
            <PFText variant={'p_semibold'}>{title}</PFText>
          </View>
          <SvgIcon name={svgIconName} colorVariant={'active'} />
        </Box>
      </TouchableOpacity>

      <Collapsible collapsed={!open}>
        <Box padding={'small'} boxStyle={contentStyle}>
          {content}
        </Box>
      </Collapsible>
    </BasicTile>
  )
}

export default CollapsibleCard

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
  },
})
