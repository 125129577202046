import {
  GetOnboardingCurrentModuleDocument,
  OnboardingModule,
  OnboardingMoveToNextModuleDocument,
  OnboardingMoveToPreviousModuleDocument,
  OnboardingInitializeDocument,
} from '../types/types.mobile.generated'
import {ApplyMutation, ApplyQuery} from '../utils/operations'

export const GetOnboardingCurrentModule = async () => {
  return (await ApplyQuery(GetOnboardingCurrentModuleDocument, {})).data.getOnboardingCurrentModule
}

export const OnboardingMoveToNextModule = async (nextModule?: `${OnboardingModule}`) => {
  return (
    (
      await ApplyMutation(OnboardingMoveToNextModuleDocument, {
        nextModule: nextModule as OnboardingModule,
      })
    )?.onboardingMoveToNextModule ?? false
  )
}

export const OnboardingMoveToPreviousModule = async () => {
  return (
    (await ApplyMutation(OnboardingMoveToPreviousModuleDocument, {}))
      ?.onboardingMoveToPreviousModule ?? false
  )
}

export const OnboardingInitialize = async (initialModule?: `${OnboardingModule}`) => {
  return ApplyMutation(OnboardingInitializeDocument, {
    initialModule: initialModule as OnboardingModule,
  })
}
