import {PaymentFrequency} from 'src/cassandra'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'
import {useSelector} from 'react-redux'

import {getPaymentFrequency} from 'src/lib/card/selectors'

/** Returns the localized, human readable payment frequency (ex. every week, bi-weekly, monthly) */
export function getFrequencyText(
  frequency: PaymentFrequency | null | undefined,
  t: TFunction,
): string {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return t('Common:Weekly')
    case PaymentFrequency.Biweekly:
      return t('Common:Biweekly')
    case PaymentFrequency.Monthly:
      return t('Common:Monthly')
    default:
      return t('Common:Biweekly')
  }
}

function getWeeksFrequencyText(
  frequency: PaymentFrequency | null | undefined,
  t: TFunction,
): string {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return t('OneWeek')
    case PaymentFrequency.Biweekly:
      return t('TwoWeeks')
    case PaymentFrequency.Monthly:
      return t('Month')
    default:
      return t('TwoWeeks')
  }
}

/**
 * @deprecated query for PaymentFrequency from GraphQL and pass it to getFrequencyText directly
 *
 * Returns the localized, human readable payment frequency (ex. every week, bi-weekly, monthly)
 */
export const useGetPaymentFrequency = (): string => {
  // eslint-disable-next-line no-console
  console.warn(
    'useGetPaymentFrequency is deprecated. Query for PaymentFrequency from GraphQL and pass it to getFrequencyText directly',
  )

  const {t} = useTranslation('CardSetupAutopayments')
  const frequency = useSelector(getPaymentFrequency)
  return getFrequencyText(frequency, t)
}

/** Returns the localized, human readable payment weeks frequency (ex. One week, Two Weeks, Month) */
export const useGetPaymentWeeksFrequency = (): string => {
  const {t} = useTranslation('CardSetupAutopayments')
  const frequency = useSelector(getPaymentFrequency)
  return getWeeksFrequencyText(frequency, t)
}
