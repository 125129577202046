import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseLoanSubmissionLoanTypeQueryVariables = Types.Exact<{
  stateAbv: Types.Scalars['String']['input']
}>

export type UseLoanSubmissionLoanTypeQuery = {
  __typename?: 'Query'
  getPrimaryLoanTypeByState?: {__typename?: 'LoanType'; id: string} | null
}

export type UseLoanSubmissionStateLoanCountQueryVariables = Types.Exact<{[key: string]: never}>

export type UseLoanSubmissionStateLoanCountQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        aggregateStatus:
          | {__typename?: 'ActiveLoanAggregateStatus'}
          | {__typename?: 'ApprovedLoanAggregateStatus'}
          | {__typename: 'ClosedLoanAggregateStatus'; loanCount: number}
          | {__typename?: 'DecisioningLoanAggregateStatus'}
          | {__typename?: 'UndefinedLoanAggregateStatus'}
      } | null
    }
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        address?: {__typename?: 'Address'; state?: string | null} | null
      } | null
    } | null
  }
}

export const UseLoanSubmissionLoanTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseLoanSubmissionLoanType'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'stateAbv'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPrimaryLoanTypeByState'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'state'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'stateAbv'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseLoanSubmissionLoanTypeQuery,
  UseLoanSubmissionLoanTypeQueryVariables
>
export const UseLoanSubmissionStateLoanCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseLoanSubmissionStateLoanCount'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aggregateStatus'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ClosedLoanAggregateStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'loanCount'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'state'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseLoanSubmissionStateLoanCountQuery,
  UseLoanSubmissionStateLoanCountQueryVariables
>
