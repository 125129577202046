import React, {PureComponent} from 'react'
import {StyleSheet, View} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'
import {withTranslation, WithTranslation} from 'react-i18next'

import ImagePicker from 'src/products/general/ImagePicker'
import {PushPage} from 'src/navigation/NavHelper'
import {ShowException} from 'src/lib/errors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import Log from 'src/lib/loggingUtil'
import ValuePropList from 'src/products/loans/components/molecules/ValuePropList/ValuePropList'
import {smallGap} from 'src/designSystem/layout'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {stateFull} from 'src/products/MCU/Address/address.utils'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {userAddressState} from 'src/lib/user/selector'
import {latestLoanSelector, latestLoanTypeSelector} from 'src/lib/loans/selector'
import {PfReduxState} from 'src/reducers/types'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {ImageCapture, getImagePickerDefaults} from 'src/lib/utils/formData'

type Props = PropsFromRedux &
  WithTranslation &
  StackScreenProps<MainStackParamList, 'ProofOfResidence'> & {
    noAddressFill?: boolean
    uraActionType?: string
  }
type State = {
  busy: boolean
}

class ProofOfResidence extends PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      busy: false,
    }
  }

  onComplete = (address): void => {
    this.props.navigation.goBack()
    this.props.route?.params?.onProofComplete?.(address)
  }

  confirmImage(imageInfo: ImageCapture): void {
    // TODO: We should not be passing functions and large objects through navigation params
    PushPage(this.props.navigation, 'ProofOfResidenceConfirm', {
      imageInfo,
      noAddressFill: this.props.noAddressFill,
      onComplete: (address) => this.onComplete(address),
      onRetake: this.onCaptureWithImagePicker,
      selectedState: this.props.route.params.selectedState,
    })
  }

  onCaptureWithImagePicker = async () => {
    this.setState({busy: true})

    const options: ImagePicker.ImageLibraryOptions = {
      maxHeight: 2048,
      quality: 0.8,
      mediaType: 'photo',
      selectionLimit: 1,
    }

    try {
      ImagePicker.launchImageLibrary(options, (response) => {
        if (response.didCancel) {
          Log.log('User cancelled image picker')
        } else if (response.errorCode || response.errorMessage) {
          Log.log('ImagePicker Error: ', response.errorCode, response.errorMessage)
        } else if (response?.assets?.length && response.assets[0].uri) {
          const {fileName, type} = getImagePickerDefaults(response.assets[0].uri)
          this.confirmImage({
            uri: response.assets[0].uri,
            fileName: response.assets[0].fileName ?? fileName,
            type: response.assets[0].type ?? type,
          })
        }
      })
    } catch (e) {
      ShowException(e)
    } finally {
      this.setState({busy: false})
    }
  }

  render(): JSX.Element {
    const {t} = this.props

    const action = {
      onPress: () => this.onCaptureWithImagePicker(),
      text: t('Common:Continue'),
      disabled: this.state.busy,
    }

    const loanState = this.props.loanType?.regionCode
      ? stateFull(this.props.loanType?.regionCode)
      : t('Unknown')
    const state = this.props.userState ? stateFull(this.props.userState) : t('Unknown')

    let bodyText = ''
    if (this.props.uraActionType === 'por_date') {
      bodyText = t('OldProofOfResidency')
    } else {
      bodyText = t('SelectedLoanStateDifferentThanAddress', {loanState, state})
    }

    const main_body = (
      <PFText variant={'p'}>
        {bodyText}
        {'\n\n'}
        {t('SomeExamplesOfThisAre')}
        {'\n\n'}• {t('ARecentCellPhoneBill')}
        {'\n'}• {t('ARecentUtilityBill')}
        {'\n'}• {t('ARecentPayStub')}
        {'\n\n'}
      </PFText>
    )

    const requirements = [
      t('YourFirstAndLastName'),
      t('YourCurrentInStateAddress'),
      t('ADateWithinTheLast30Days'),
    ]

    const icon = <SvgIcon name={'checkWithCircle'} colorVariant={'success'} />

    return (
      <GenericNonModalTemplate
        title={t('ProvideProofOfResidency')}
        actionBlock={buttonLockupProperties(action)}
        justifyContent={styles.pageBodyStyle}
      >
        <View style={styles.must_show}>
          {main_body}
          <PFText variant={'p_semibold'}>
            {t('YourProofOfResidencyMustShow')}:{'\n\n'}
          </PFText>
          <ValuePropList
            icon={icon}
            items={requirements}
            boldItems={false}
            style={styles.boxView}
            textStyle={styles.listTextStyle}
          />
        </View>
      </GenericNonModalTemplate>
    )
  }
}

const mapStateToProps = (state: PfReduxState) => {
  const loan = latestLoanSelector(state)
  const loanType = latestLoanTypeSelector(state)
  return {
    loan,
    loanType,
    userState: userAddressState(state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connect(mapStateToProps)(
  withTranslation(['PersonalInformation', 'Common'])(
    withForwardedNavigationParams<Props>()(ProofOfResidence),
  ),
)
const styles = StyleSheet.create({
  boxView: {
    justifyContent: 'space-around',
  },
  listTextStyle: {
    paddingLeft: smallGap,
  },
  must_show: {
    justifyContent: 'center',
  },
  pageBodyStyle: {
    justifyContent: 'flex-start',
  },
})
