import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  ConfirmBankAccountGQLContainer,
  ConfirmBankAccountGQLContainerProps,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountGQLContainer'

export type ConfirmBankAccountMPOContainerProps = StackScreenProps<
  MainStackParamList,
  'ConfirmBankAccount'
> &
  ModuleControlProps

/**
 * Container to integrate the ConfirmBankAccount into an MPO flow.
 */
export const ConfirmBankAccountMPOContainer: React.FC<ConfirmBankAccountMPOContainerProps> = (
  props,
) => {
  const {onStepComplete, navigation, route} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_bank_account_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnSubmit: ConfirmBankAccountGQLContainerProps['onSubmit'] = async () => {
    TrackAppEvent(AppEvents.Name.confirm_bank_account_completed, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })

    // if the user was sent here because of an ACH error (probably from LoanFinalAccept) we will
    // go back to the previous screen after entering account details
    if (route.params.shouldNavBackAfterSubmit) {
      navigation.goBack()
    } else {
      await onStepComplete?.()
    }
  }

  return <ConfirmBankAccountGQLContainer onSubmit={handleOnSubmit} />
}
