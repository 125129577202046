import React, {FC, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {PushPage} from 'src/navigation/NavHelper'
import CardPhysical from 'src/assets/illustrations/cardPhysical.svg'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export const ActivatePhysicalCardTile: FC<BaseCardProps> = ({
  navigation,
  loading,
  tileMarginHorizontal,
  tileRadius,
}) => {
  const {t} = useTranslation('CardDashboard')

  usePageViewedAnalytics({
    eventName: CardEvents.card_activate_physical_dashboard_tile_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const onActivatePhysicalCard = useCallback(() => {
    TrackAppEvent(
      CardEvents.card_activate_physical_dashboard_tile_activate_cta,
      AppEvents.Category.Card,
    )
    PushPage(navigation, 'CardActivatePhysicalCard')
  }, [navigation])
  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText={t('GotYourCard')}
      buttonText={t('ActivateNow')}
      secondaryAction={[
        {
          onPress: (): void => {
            TrackAppEvent(
              CardEvents.card_activate_physical_dashboard_tile_skip_cta,
              AppEvents.Category.Card,
            )
            PushPage(navigation, 'CardDashboard')
          },
          text: t('ActivateLater'),
        },
      ]}
      content=""
      img={
        <PFSvg
          SvgUri={CardPhysical}
          svgProps={{
            height: 48 * 1.5,
            width: 32 * 1.5,
          }}
        />
      }
      onPress={onActivatePhysicalCard}
      loading={loading}
    />
  )
}
