import {ApolloError} from '@apollo/client'
import React, {ReactElement} from 'react'

import {DefaultPaymentMethodCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import Snackbar from 'src/lib/Snackbar'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {AcceptAgreementsContinueResult} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {AcceptLoanAndAutoPayAgreementsDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreements.gqls'
import {AcceptLoanAndAutoPayAgreementsBaseTemplateProps} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsBaseTemplate'
import {useGetAgreementDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument'
import {useSaveAcceptAgreementsResult} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useSaveAcceptAgreementsResult/useSaveAcceptAgreementsResult'

export type AcceptLoanAndAutoPayAgreementsGQLContainerProps = Pick<
  AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
  'onAcceptAndContinue'
> & {
  testID: string
  /**
   * Allow the GQL container to fetch the necessary data and pass it to a child using a render() prop.
   * That way we can use one data container for multiple child templates since there are a number of variations of
   * this screen that will all need the same data.
   */
  render: (
    args: Omit<
      AcceptLoanAndAutoPayAgreementsBaseTemplateProps,
      // this wll be set by the child screen template that uses AcceptLoanAndAutoPayAgreementsBaseTemplate
      'showLoanAgreementCheckbox'
    >,
  ) => ReactElement
}

/**
 * Data container responsible for fetching and storying any data necessary for accepting agreements.
 * Accepts a render prop so any child template using the base template AcceptLoanAndAutoPayAgreementsBaseTemplate
 * can be used with this data.
 */
export const AcceptLoanAndAutoPayAgreementsGQLContainer: React.FC<
  AcceptLoanAndAutoPayAgreementsGQLContainerProps
> = (props) => {
  const {render, onAcceptAndContinue} = props
  const {
    selectedData,
    error: queryError,
    loading: isLoading,
  } = useCassandraQuery(
    AcceptLoanAndAutoPayAgreementsDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationErrorAndShowException(err, 'AcceptLoanAndAutoPayAgreements query failed')
      },
    },
    (data) => {
      const defaultPaymentMethod = data.me.paymentMethods.loanEligible?.filter((paymentMethod) =>
        paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.PaymentDefault),
      )[0]
      let defaultPaymentMethodMask = ''
      switch (defaultPaymentMethod?.__typename) {
        case 'AchPaymentMethod':
          defaultPaymentMethodMask = defaultPaymentMethod.bankAccount.mask
          break
        case 'DebitCardPaymentMethod':
          defaultPaymentMethodMask = defaultPaymentMethod.card.mask ?? ''
          break
        default:
          break
      }
      return {
        loanId: data.me.loans.latestActionableLoan?.id,
        defaultPaymentMethod: data.me.paymentMethods.loanEligible?.filter((paymentMethod) =>
          paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.PaymentDefault),
        )[0],
        defaultPaymentMethodMask,
      }
    },
  )

  const {onSaveAcceptAgreementsResult} = useSaveAcceptAgreementsResult()
  const {getLoanAgreementDocument, getLoanAutoPayAgreementDocument} = useGetAgreementDocument()

  const defaultPaymentMethod = selectedData?.defaultPaymentMethod
  const defaultPaymentMethodMask = selectedData?.defaultPaymentMethodMask
  const loanId = selectedData?.loanId

  const navigation = useNavigation()
  const {t} = useTranslation('LoanApproved')

  /**
   * Get and open the AutoPay Agreement document.
   */
  const handleOnOpenAutoPayAgreement = async (): Promise<void> => {
    if (!loanId) {
      logOfferActivationErrorAndShowException(
        new Error('No loanId found when retrieving autopay agreement'),
      )
      return
    }
    try {
      const {documentUrl} = await getLoanAutoPayAgreementDocument({loanId})
      displayPdf(documentUrl, navigation)
    } catch (e) {
      logOfferActivationError(e, 'Failed to get autopay agreement document')
      Snackbar.error({
        title: t('ErrorLoadingAutopayAgreement'),
        duration: Snackbar.LENGTH_LONG,
      })
    }
  }

  /**
   * Get and open the Loan Agreement document.
   */
  const handleOnOpenLoanAgreement = async (): Promise<void> => {
    if (!loanId) {
      logOfferActivationErrorAndShowException(
        new Error('No loanId found when retrieving loan agreement'),
      )
      return
    }
    try {
      const {documentUrl} = await getLoanAgreementDocument({loanId})
      displayPdf(documentUrl, navigation)
    } catch (e) {
      logOfferActivationErrorAndShowException(
        e,
        'Failed to get loan agreement document, using default fallback',
      )
    }
  }

  /**
   * Record this user's choices when they continue.
   */
  const [handleOnAcceptAndContinue, {isLoading: isHandleOnAcceptAndContinueExecuting}] = usePromise(
    async (agreementsResult: AcceptAgreementsContinueResult): Promise<void> => {
      if (!loanId) {
        logOfferActivationErrorAndShowException(
          new Error(
            'AcceptAgreementsGQLContainer No loanId found when accepting agreements and continuing',
          ),
        )
        return
      }

      try {
        await onSaveAcceptAgreementsResult({loanId, agreementsResult})
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptAgreementsGQLContainer handleOnAcceptAndContinue() failed to submit',
        )
        return
      }
      await onAcceptAndContinue(agreementsResult)
    },
  )

  return render({
    ...props,
    isLoading: isLoading,
    isError: !!queryError,
    isSubmitting: isHandleOnAcceptAndContinueExecuting,
    defaultPaymentMethod: {
      // default to ACH until data is loaded because it's a required prop
      __typename: defaultPaymentMethod?.__typename ?? 'AchPaymentMethod',
      mask: defaultPaymentMethodMask ?? undefined,
    },
    onOpenAutoPayAgreement: (): void => {
      handleOnOpenAutoPayAgreement().catch((e) => {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptLoanAndAutoPayAgreementsGQLContainer failed to open autopay agreement',
        )
      })
    },
    onOpenLoanAgreement: (): void => {
      handleOnOpenLoanAgreement().catch((e) => {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptLoanAndAutoPayAgreementsGQLContainer failed to open loan agreement',
        )
      })
    },
    onAcceptAndContinue: (agreementsResult): void => {
      handleOnAcceptAndContinue(agreementsResult).catch((e) => {
        logOfferActivationErrorAndShowException(
          e,
          'AcceptLoanAndAutoPayAgreementsGQLContainer failed to acccept and continue',
        )
      })
    },
  })
}
