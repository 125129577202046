/* eslint-disable complexity */
import {AutopayModel} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {View} from 'react-native'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {MinPayTable} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/MinPayTable'
import {ShowDetailsOverlay} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/ShowDetailsOverlay'
import {mediumGap} from 'src/designSystem/layout'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {useGetPaymentWeeksFrequency} from 'src/products/card/Autopay/useGetPaymentFrequency'
import {
  CardDashboardPaymentFooter,
  CardDashboardPaymentFooterPropsFromUseCards,
} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentFooter'
import {renderCircularDateComponents} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'

export type UpcomingPaymentsCardPropsFromUseCards = Pick<
  useCardsType,
  'nextPaymentDates' | 'nextPaymentDueDateFiltered' | 'autopayEnabled' | 'nextPaymentAmountFiltered'
>

export type UpcomingPaymentsCardProps = UpcomingPaymentsCardPropsFromUseCards &
  CardDashboardPaymentFooterPropsFromUseCards & {
    navigation: StackNavigationProp<MainStackNavigationProp>
    footer: boolean
    accountStatus: CardAccountDashboardStatus | undefined
    accountPOTStatus: CardAccountPOTStatus
  }

const UpcomingPaymentsCard = ({
  footer,
  accountStatus,
  nextPaymentAmountFiltered,
  autopayEnabled,
  nextPaymentDueDateFiltered,
  nextPaymentDates,
  spentAmount,
  eligibleForInstallments,
  cardAccount,
  ledgerSpentAfterPayments,
  navigation,
}: UpcomingPaymentsCardProps): JSX.Element => {
  const {t} = useTranslation(['UpcomingPaymentsCard', 'Common'])
  const frequency = useGetPaymentWeeksFrequency()
  const [showDetailsOverlay, setShowDetailsOverlay] = useState<boolean>(false)

  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )

  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay

  const getAutoPayText = useCallback(() => {
    if (accountStatus === CardAccountDashboardStatus.SuspendedPayOverTime) {
      return t('PayOverTimeBalanceAutoPay', {
        ns: 'UpcomingPaymentsCard',
        amount: convertToDollarAmt(nextPaymentAmountFiltered),
      })
    }
    if (isUserMinPayProgram) {
      const minimumPaymentDueAfterPayments =
        cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments
      if (!autopayEnabled) {
        return `$${minimumPaymentDueAfterPayments} ${t('isDue')}`
      }

      if (autopayEnabled) {
        return `$${minimumPaymentDueAfterPayments} ${t('willAutoPay')}`
      }
    }

    if (!autopayEnabled) {
      return t('YourFullBalanceIsDue', {ns: 'UpcomingPaymentsCard'})
    }
    if (autopayEnabled) {
      return t('YourFullBalanceWillAutoPay', {ns: 'UpcomingPaymentsCard'})
    }
  }, [
    accountStatus,
    autopayEnabled,
    nextPaymentAmountFiltered,
    isUserMinPayProgram,
    cardMinPayResponse,
    t,
  ])

  const sanitizeAutopayText = (autopayModel: AutopayModel): string => {
    switch (autopayModel) {
      case AutopayModel.MinimumPayment:
        return t('MinimumDue')
      case AutopayModel.StatementBalance:
        return t('StatementBalance')
      case AutopayModel.FullBalance:
        return t('FullBalance')
      default:
        return ''
    }
  }

  const autopayStatus =
    autopayEnabled &&
    (cardMinPayResponse?.status?.__typename === 'ActiveCardAccountStatus' ||
      cardMinPayResponse?.status?.__typename === 'DeactivatedCardAccountStatus')
      ? sanitizeAutopayText(cardMinPayResponse?.status?.autopayModel)
      : 'OFF'

  return (
    <View style={{marginHorizontal: mediumGap}}>
      {isUserMinPayProgram ? (
        <MinPayTable
          setShowDetailsOverlay={setShowDetailsOverlay}
          cardMinPayResponse={cardMinPayResponse}
          autopayStatus={
            autopayEnabled &&
            (cardMinPayResponse?.status?.__typename === 'ActiveCardAccountStatus' ||
              cardMinPayResponse?.status?.__typename === 'DeactivatedCardAccountStatus')
              ? sanitizeAutopayText(cardMinPayResponse?.status?.autopayModel)
              : 'Off'
          }
          autopayEnabled={autopayEnabled}
          nextPaymentDueDateFiltered={nextPaymentDueDateFiltered}
          accountStatus={accountStatus}
          ledgerSpentAfterPayments={ledgerSpentAfterPayments}
          cardAccount={cardAccount}
          navigation={navigation}
          spentAmount={spentAmount}
          eligibleForInstallments={eligibleForInstallments}
        />
      ) : (
        <Box {...boxProps} testID="Upcoming-Payments-Tile-Id">
          <PFText variant={'label_lg'} textAlign={'center'}>
            {t('UpcomingPayments', {ns: 'UpcomingPaymentsCard'})}
          </PFText>

          <Box direction={'row'} align={'center'} justify={'center'}>
            <SvgIcon name={'calendar'} colorVariant={'inactive'} />
            <Box marginLeft={'little'}>
              {isUserMinPayProgram ? (
                <PFText variant={'p'} textAlign={'center'} color={'textDisabled'}>
                  {t('Month')}
                </PFText>
              ) : (
                <PFText variant={'p'} textAlign={'center'} color={'textDisabled'}>
                  {accountStatus === CardAccountDashboardStatus.SuspendedPayOverTime
                    ? t('PayOverTime')
                    : `${t('Every', {ns: 'UpcomingPaymentsCard'})} ${frequency}`}
                </PFText>
              )}
            </Box>
          </Box>
          <Box marginTop={'small'} marginBottom={'little'} direction={'row'} gap={'medium'}>
            {renderCircularDateComponents(nextPaymentDates)}
          </Box>
          <Box direction={'row'} paddingHorizontal={'medium'} justify={'center'}>
            {isUserMinPayProgram &&
            cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments === '0.00' ? null : (
              <PFText variant={'p'} textAlign={'center'}>
                {getAutoPayText()}
                <PFText variant={'p_semibold'}>
                  {formatDate(nextPaymentDueDateFiltered, humanReadableDateDayMonth2)}
                </PFText>
              </PFText>
            )}
          </Box>
          {accountStatus !== CardAccountDashboardStatus.SuspendedPayOverTime && autopayEnabled ? (
            <SvgLink
              onPress={(): void => {
                navigation.navigate('CardRescheduleUpcomingPayment')
              }}
              linkText={t('Reschedule')}
              linkType={'inline'}
              linkIcon={'internal'}
            />
          ) : null}
          {footer ? (
            <CardDashboardPaymentFooter
              accountStatus={accountStatus}
              ledgerSpentAfterPayments={ledgerSpentAfterPayments}
              cardAccount={cardAccount}
              navigation={navigation}
              spentAmount={spentAmount}
              eligibleForInstallments={eligibleForInstallments}
            />
          ) : null}
        </Box>
      )}
      <ShowDetailsOverlay
        visible={showDetailsOverlay}
        onDismiss={(): void => {
          TrackAppEvent(CardEvents.card_key_info_details_exited, AppEvents.Category.Card)
          setShowDetailsOverlay(false)
        }}
        nextPaymentDueDateFiltered={nextPaymentDueDateFiltered}
        autopayEnabled={autopayEnabled}
        autopayStatus={autopayStatus}
        cardMinPayResponse={cardMinPayResponse}
      />
    </View>
  )
}

export {UpcomingPaymentsCard}

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  boxStyle: {overflow: 'hidden'},
  gap: 'little',
  align: 'center',
  width: '100%',
}
