import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {
  possibleElectronicCommunicationURI,
  possiblePrivacyURI,
  possibleTOSURI,
  possibleStateLicensesURI,
  coastalPrivacyPolicyURI,
  possibleCreditApprovalDisclosureURI,
} from 'src/navigation/WebLinks'
import {testProps} from 'src/lib/utils/tests.utils'

const handleOnPossibleTOS = (): void => {
  TrackAppEvent(AppEvents.Name.accept_agreements_view_tos_selected, AppEvents.Category.Login)
  OpenWebPage({uri: possibleTOSURI()})
}

const handleOnPossibleStateLicenses = (): void => {
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_state_licenses_selected,
    AppEvents.Category.Login,
  )
  OpenWebPage({uri: possibleStateLicensesURI()})
}

const handleOnPossiblePP = (): void => {
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_privacy_policy_selected,
    AppEvents.Category.Login,
  )
  OpenWebPage({uri: possiblePrivacyURI()})
}

const handleOnCoastalPP = (): void => {
  OpenWebPage({uri: coastalPrivacyPolicyURI})
}

const handleOnPressCreditApprovalDisclosure = (): void => {
  TrackAppEvent(
    AppEvents.Name.conditions_for_approval_disclosure_selected,
    AppEvents.Category.Login,
  )

  OpenWebPage({uri: possibleCreditApprovalDisclosureURI})
}

const handleOnPossibleECC = (): void => {
  TrackAppEvent(AppEvents.Name.accept_agreements_view_ecc_selected, AppEvents.Category.Login)
  OpenWebPage({uri: possibleElectronicCommunicationURI()})
}

type Props = {
  disabled: boolean
}

const LoginRegistrationLegal: FC<Props> = (props) => {
  const {t} = useTranslation(['RegistrationOrLogin'])
  const coastalPrivacyPolicy = (
    <SvgLink
      linkType={'inline'}
      onPress={handleOnCoastalPP}
      textVariant="p_sm"
      testID={'link_coastal_privacy_policy'}
      linkText={t('CoastalPrivacyPolicy')}
      {...props}
    />
  )

  const stateLicenses = (
    <SvgLink
      linkType={'inline'}
      onPress={handleOnPossibleStateLicenses}
      textVariant="p_sm"
      // eslint-disable-next-line testing-library/consistent-data-testid
      testID="link_state_licenses"
      {...props}
      linkText={t('StateLicenses')}
    />
  )

  return (
    <View>
      <View style={styles.disclaimer} {...testProps('label_footer')} accessible={false}>
        <PFText variant={'p_sm'} textAlign={'center'} testID={'label_login_or_register_consent'}>
          {t('LoginOrRegisterConsent')}
        </PFText>
        <SvgLink
          linkType={'inline'}
          onPress={handleOnPossibleTOS}
          textVariant="p_sm"
          testID={'link_terms_of_service'}
          {...props}
          linkText={t('TermsOfUse')}
        />

        <PFText variant={'p_sm'}>{', '} </PFText>
        <SvgLink
          linkType={'inline'}
          onPress={handleOnPossiblePP}
          textVariant="p_sm"
          testID={'link_privacy_policy'}
          {...props}
          linkText={t('PrivacyPolicy')}
        />

        <PFText variant={'p_sm'}>{', '}</PFText>
        <SvgLink
          linkType={'inline'}
          onPress={handleOnPressCreditApprovalDisclosure}
          textVariant="p_sm"
          testID={'link_credit_approval_disclosure'}
          {...props}
          linkText={t('CreditApprovalDisclosure')}
        />

        <SvgLink
          linkType={'inline'}
          onPress={handleOnPossibleECC}
          textVariant="p_sm"
          testID={'link_electronic_communications_consent'}
          {...props}
          linkText={t('ElectronicCommunicationsConsent')}
        />

        <PFText variant={'p_sm'}>{` ${t('Common:and')} `}</PFText>
        {coastalPrivacyPolicy}
      </View>
      <View style={styles.viewOurStateLicenses}>
        <PFText variant="p_sm">{t('ViewOur')}</PFText>
        {stateLicenses}
      </View>
    </View>
  )
}
export default LoginRegistrationLegal

const styles = StyleSheet.create({
  disclaimer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  viewOurStateLicenses: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginVertical: 16,
  },
})
