import {useSelector} from 'react-redux'
import {getActiveAccountId, getPotentialInstallmentPlan} from 'src/lib/card/selectors'
import {cardAccountActivateInstallmentPlan} from 'src/cassandra'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const useEnablePayOverTime = () => {
  const accountId = useSelector(getActiveAccountId)
  const plan = useSelector(getPotentialInstallmentPlan)
  const schedule = plan?.payments

  return async () => {
    if (!accountId || !plan?.id) {
      throw new Error('Invalid accountId or plan.id')
    }

    TrackAppEvent(CardEvents.enable_pay_over_time_action, AppEvents.Category.Card)
    await cardAccountActivateInstallmentPlan(accountId, plan?.id)

    return {
      amount: Number(schedule?.[0]?.amount ?? '0'),
      date: formatDate(schedule?.[0]?.executeAt, friendlyDateWithDOW, utcDate),
    }
  }
}
