export type Events = 'Redirection' | 'RedirectionComplete' | 'Loading' | 'LoadingComplete'

type EventCallback = (data: any) => void
type ListenerMap = {[x in Events]: {[k: string]: EventCallback}}

export const DestinationValues = [
  'apply',
  'onboarding',
  'application',
  'reapplication',
  'acceptance',
  'mpo',
  'card',
  'verify-email',
] as const
export type Destinations = (typeof DestinationValues)[number]

const listeners: ListenerMap = {
  Redirection: {},
  RedirectionComplete: {},
  Loading: {},
  LoadingComplete: {},
}
let listenerId = 0

export const EventEmitter: {
  dispatch: (event: Events, data?: any) => void
  subscribe: (event: Events, callback: EventCallback) => string
  unsubscribe: (event: Events, listenerId: string) => void
} = {
  dispatch(event: Events, data?: any) {
    if (!listeners[event]) {
      return
    }

    Object.keys(listeners[event]).forEach((lId) => {
      listeners[event][lId](data)
    })
  },

  subscribe(event: Events, callback: (data: any) => any) {
    listenerId += 1
    listeners[event][listenerId.toString()] = callback

    return listenerId.toString()
  },

  unsubscribe(event: Events, lId: string) {
    if (!listeners?.[event]?.[lId]) {
      return
    }

    delete listeners[event][lId]
  },
}

export const EmitRedirectionEvent = (destination: Destinations, needsAuth?: boolean) => {
  EventEmitter.dispatch('Redirection', {destination, needsAuth})
}

export const EmitRedirectionCompleteEvent = (destination: Destinations) => {
  EventEmitter.dispatch('RedirectionComplete', {destination})
}

export const EmitLoadingEvent = (destination: Destinations) => {
  EventEmitter.dispatch('Loading', {destination})
}

export const EmitLoadingCompleteEvent = (destination: Destinations) => {
  EventEmitter.dispatch('LoadingComplete', {destination})
}
