import React, {FC} from 'react'

import {PaymentReviewTilaAndLoanAgreementCARESTContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCARESTContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {CALoanFinalActivationNavigationScreenAnalytics} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCA.utils'

export type PaymentReviewTilaAndLoanAgreementCAWorkflowContainerProps =
  WorkflowPreReqFulfillScreenProps

const PaymentReviewTilaAndLoanAgreementCAWorkflowContainer: FC<
  PaymentReviewTilaAndLoanAgreementCAWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props

  CALoanFinalActivationNavigationScreenAnalytics('Workflows')

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }

  return <PaymentReviewTilaAndLoanAgreementCARESTContainer onContinue={handleOnContinue} />
}

export {PaymentReviewTilaAndLoanAgreementCAWorkflowContainer}
