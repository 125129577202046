import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ModuleControlProps} from 'src/flows/types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {UpgradeToInstallmentPlanGQLContainer} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlanGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

type UpgradeToInstallmentPlanMPOContainerProps = Pick<ModuleControlProps, 'onStepComplete'> &
  StackScreenProps<MainStackParamList, 'UpgradeToInstallment'>

export const UpgradeToInstallmentPlanMPOContainer: React.FC<
  UpgradeToInstallmentPlanMPOContainerProps
> = (props) => {
  const {onStepComplete, navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.installment_upgrade_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnConvertLoanSuccess = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.installment_upgrade_accepted, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })
    await onStepComplete?.()
    navigation.navigate('MoneyOnTheWay')
  }
  const handleOnDeclineConversion = async (): Promise<void> => {
    await onStepComplete?.()
    navigation.navigate('MoneyOnTheWay')
  }
  const handleOnOpenDeclineModal = (): void => {
    TrackAppEvent(AppEvents.Name.installment_upgrade_declined, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })
  }

  const handleOnDismissDeclineModal = (): void => {
    TrackAppEvent(
      AppEvents.Name.installment_upgrade_declined_modal_back,
      AppEvents.Category.Checkout,
      {
        navContainerType: 'MPO',
      },
    )
  }

  return (
    <UpgradeToInstallmentPlanGQLContainer
      onConvertLoanSuccess={handleOnConvertLoanSuccess}
      onOpenDeclineModal={handleOnOpenDeclineModal}
      onDismissDeclineModal={handleOnDismissDeclineModal}
      onDecline={handleOnDeclineConversion}
    />
  )
}
