export const regionCodes = {
  California: 'USA-CA',
  Florida: 'USA-FL',
  Idaho: 'USA-ID',
  Illinois: 'USA-IL',
  Louisiana: 'USA-LA',
  Ohio: 'USA-OH',
  Texas: 'USA-TX',
  Utah: 'USA-UT',
  Washington: 'USA-WA',
}

export type transferMethodsType = 'interchange' | 'ach' | 'check' | 'none'

export const transferMethods = {
  interchange: 'interchange' as transferMethodsType,
  ach: 'ach' as transferMethodsType,
  check: 'check' as transferMethodsType,
  none: 'none' as transferMethodsType,
}

export const fundingAuthDirection = {
  from: 'from', // Payment
  to: 'to', //Disbursement
}

export const fundingStatusList = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  NONE: 'NONE',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  HUMAN_REVIEW: 'HUMAN_REVIEW_REQUIRED',
}

export enum statusList {
  ACTIVE = 'active',
  ACTIVE_DEFAULT = 'active_default',
  ACTIVE_LATE = 'active_late',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
  CANCELLED_ACTIVE = 'cancelled_active',
  CHARGED_OFF = 'chargedoff',
  CHARGED_OFF_COLLECTED = 'chargedoff_collected_full',
  CHARGED_OFF_SETTLED = 'chargedoff_settled',
  COMPLETED = 'completed',
  DEFAULT = 'default',
  EXPIRED = 'expired',
  EXPIRED_APPROVED = 'expired_approved',
  EXPIRED_PENDING = 'expired_pending',
  FAILED = 'failed',
  LATE = 'late',
  NONE = 'none',
  PAIDOFF = 'paidoff',
  PENDING = 'pending',
  PROCESSING = 'in_progress',
  REJECTED = 'rejected',
  USER_ACCEPTED_LOAN = 'Loan accepted by user',
  SUSPENDED = 'suspended',
  REPLACED = 'replaced',
}

export type StatusListKeys = keyof typeof statusList

export type FundingStatusListKeys = keyof typeof fundingStatusList
export type FundingStatusValues = (typeof fundingStatusList)[FundingStatusListKeys]

export const UIState = {
  APPROVED: 'APPROVED',
  APPLICATION_EXPIRED: 'APPLICATION_EXPIRED',
  APPLY_NOW: 'APPLY_NOW',
  CANCELLED: 'CANCELLED',
  CHARGED_OFF: 'CHARGED_OFF',
  CHARGED_OFF_SETTLED: 'CHARGED_OFF_SETTLED',
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR',
  FUNDING_COMPLETE: 'FUNDING_COMPLETE',
  FUNDING_DELAYED: 'FUNDING_DELAYED',
  INCOMPLETE_APPLICATION: 'INCOMPLETE_APPLICATION',
  LATE_PAYMENT: 'LATE_PAYMENT',
  LOAN_EXPIRED: 'LOAN_EXPIRED',
  MONEY_ALMOST_ON_THE_WAY: 'MONEY_ALMOST_ON_THE_WAY',
  MONEY_ON_THE_WAY: 'MONEY_ON_THE_WAY',
  MONEY_ON_THE_WAY_INTERCHANGE: 'MONEY_ON_THE_WAY_INTERCHANGE',
  NO_LOAN: 'NO_LOAN',
  NO_LOAN_USE_MPO: 'NO_LOAN_USE_MPO',
  NO_CURRENT_LOAN_REAPPLY: 'NO_CURRENT_LOAN_REAPPLY',
  PAIDOFF: 'PAIDOFF',
  PAIDOFF_CHARGED_OFF: 'PAIDOFF_CHARGED_OFF',
  PAYMENT_METHOD_ISSUE: 'PAYMENT_METHOD_ISSUE',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PENDING: 'PENDING',
  PENDING_DELAYED: 'PENDING_DELAYED',
  REJECTED: 'REJECTED',
  REJECTED_CAN_REAPPLY: 'REJECTED_CAN_REAPPLY',
  UPCOMING_PAYMENT: 'UPCOMING_PAYMENT',
  LOADING: 'LOADING',
  WA_CONVERSION_PENDING: 'WA_CONVERSION_PENDING',
  WA_CONVERSION_APPROVED: 'WA_CONVERSION_APPROVED',
  WA_CONVERSION_ACTIVE: 'WA_CONVERSION_ACTIVE',
  STATE_SUPPORT_DISABLED: 'STATE_SUPPORT_DISABLED',
  REJECTED_SDB: 'REJECTED_SDB',
  COUNTER_OFFER: 'COUNTER_OFFER',
}

export type UIStateKeys = keyof typeof UIState
export type UIStateValues = (typeof UIState)[UIStateKeys]
