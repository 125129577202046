import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowLiveness} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'
import {LivenessReduxContainer} from 'src/products/general/Liveness/LivenessReduxContainer'

type Props = StackScreenProps<MainStackParamList, 'Liveness'>

export const LivenessRouteContainer: FC<Props> = ({navigation}) => {
  const onComplete = (): void => {
    NavPageState.NavToNextPage(LoanFlowLiveness, navigation)
  }

  return <LivenessReduxContainer onComplete={onComplete} />
}
