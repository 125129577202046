import React, {FC} from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {CardActivatedGQLContainer} from 'src/products/card/Activation/CardActivated/CardActivatedGQLContainer/CardActivatedGQLContainer'

export const CardActivatedMPOContainer: FC<ModuleControlProps> = ({onModuleComplete}) => {
  const handleOnComplete = async (): Promise<void> => {
    await onModuleComplete?.()
  }

  return <CardActivatedGQLContainer onComplete={handleOnComplete} />
}
