import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import NonACH, {
  NonACHProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/NonAch'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export const NonACHWAInstallment: FunctionComponent<NonACHProps> = (props) => {
  const {t} = useTranslation('LoanApprovedExtendedPlan')

  const body = <PFText variant={'p'}>{t('NoAutoPayBody')}</PFText>

  return <NonACH {...props} body={body} secondaryButtonText={t('AgreeToPayByMoneyOrder')} />
}
