import React, {FC, ReactElement} from 'react'
import {Pressable, StyleSheet} from 'react-native'

import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {NamedColors} from 'src/designSystem/colors'
import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {
  accountHasError,
  accountIsJointType,
  hasValidOwnershipStatus,
} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {
  BankAccountTileAlertIcon,
  BankAccountTileEditIcon,
  BankAccountTileText,
} from 'src/products/MCU/AccountManagementV2/BankAccountTile/BankAccountTileElements'
import Box from 'src/designSystem/components/atoms/Box/Box'

type Props = {
  account: BankAccountInfo
  onEditPress: () => void
  onAlertPress: () => void
  onOwnershipStatusAlertPress: () => void
  isOwnerShipValidationEnabled: boolean
  isEditAccountButtonVisible: boolean
}

export const BankAccountTileView: FC<Props> = ({
  account,
  onEditPress: handleOnEditPress,
  onAlertPress: handleOnAlertPress,
  onOwnershipStatusAlertPress: handleOnOwnershipStatusAlertPress,
  isOwnerShipValidationEnabled,
  isEditAccountButtonVisible,
}) => {
  const accountNumber = '\u2022\u2022\u2022\u2022' + account.mask

  const hasAccountingAndRoutingNumber = account.isRoutingAndAccountNumbersAvailable

  const handleGetAlertIconAction = (): (() => void) => {
    if (!hasAccountingAndRoutingNumber) {
      return handleOnAlertPress
    }

    if (isOwnerShipValidationEnabled) {
      return hasValidOwnershipStatus(account) && !accountIsJointType(account)
        ? handleOnAlertPress
        : handleOnOwnershipStatusAlertPress
    }

    return handleOnAlertPress
  }

  const textAndIconView: ReactElement = accountHasError(account, isOwnerShipValidationEnabled) ? (
    <>
      <BankAccountTileText account={account} accountNumber={accountNumber} />
      <BankAccountTileAlertIcon onPress={handleGetAlertIconAction()} />
      {!isEditAccountButtonVisible ? null : <BankAccountTileEditIcon onPress={handleOnEditPress} />}
    </>
  ) : (
    <>
      <BankAccountTileText account={account} accountNumber={accountNumber} />

      {!isEditAccountButtonVisible ? null : <BankAccountTileEditIcon onPress={handleOnEditPress} />}
    </>
  )
  const needsAttentionTile: ReactElement = (
    <Box fill={'horizontal'} paddingRight={'large'}>
      <Pressable
        onPress={handleOnEditPress}
        style={styles.touchableWrapper}
        testID={'needsAttentionTile_touchable_wrapper'}
      >
        <BankAccountTileText account={account} accountNumber={accountNumber} />
        <BankAccountTileAlertIcon onPress={handleOnEditPress} />
      </Pressable>
    </Box>
  )

  return (
    <Box
      align={'center'}
      direction={'row'}
      padding={'small'}
      border={{
        color: accountHasError(account, isOwnerShipValidationEnabled)
          ? NamedColors.PRODUCT_GOLD
          : NamedColors.ASH,
        width: 2,
      }}
      radius={4}
      testID="Bank-Account-Tile-View"
    >
      <Box marginRight={'small'}>
        <SvgIcon name={'bank'} colorVariant={'default'} />
      </Box>
      {hasAccountingAndRoutingNumber ? textAndIconView : needsAttentionTile}
    </Box>
  )
}

const styles = StyleSheet.create({
  touchableWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
})
