import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {GetPaymentInstrumentsAndAccounts} from 'src/api/actions/user/userActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage} from 'src/navigation/NavHelper'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {useExecuteOnce} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/useExecuteOnce'
import {userIdSelector} from 'src/lib/user/selector'
import {ContextualizedLogException} from 'src/lib/errors'

import {
  AggregatorPlaidProps,
  onExitType,
  onInstitutionSelectedType,
  onSuccessType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'
import AggregatorPlaidGQLContainer from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidGQLContainer'
import {CALLBACK_STATUS} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

type Props = AggregatorPlaidProps & StackScreenProps<MainStackParamList, 'AggregatorPlaid'>

const AggregatorPlaid: React.FC<Props> = (props) => {
  const {accountId, onComplete, onSwitch, navigation} = props

  const dispatch = usePfDispatch()
  const userId = usePfSelector(userIdSelector)

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.PlaidSDK,
    eventName: AppEvents.Name.plaid_refactor_activated,
  })

  const pop = useExecuteOnce(() => {
    PopPage(navigation)
  })

  // NOTE:
  // this callback will only be called when linking
  // completes IN THE APP. If the user is redirected
  // to the app from the browser, this callback will
  // not be called
  const handleSuccess = useCallback<onSuccessType>(
    async (data) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await GetPaymentInstrumentsAndAccounts(dispatch, userId)
      } catch (e) {
        ContextualizedLogException('Failed to get payment instruments and accounts')(e)
      } finally {
        onComplete(CALLBACK_STATUS.SUCCESS, data.institution?.name)

        // Ideally this pop would actually be called by the
        // screen that instantiated linking -- it understands
        // context. In the short term however this is how it
        // has been done, so we do it.
        pop()
      }
    },
    [dispatch, onComplete, pop, userId],
  )

  // NOTE:
  // this callback will only be called when linking
  // is exited IN THE APP. If the user is redirected
  // to an OAuth flow and exits, this callback will
  // not be called
  const handleExit = useCallback<onExitType>(() => {
    // for now, all of the things we do on exit are handled
    // by the validation container
  }, [])

  // NOTE:
  // institution selection happens before OAuth flows
  // so this callback should always be called
  const handleInstitutionSelected = useCallback<onInstitutionSelectedType>((_id, name) => {
    TrackAppEvent(
      AppEvents.Name.link_bank_account_bank_selected,
      AppEvents.Category.InitialApplication,
      {value: name},
    )
  }, [])

  return (
    <AggregatorPlaidGQLContainer
      accountId={accountId}
      onSuccess={handleSuccess}
      onExit={handleExit}
      onInstitutionSelected={handleInstitutionSelected}
      onEarlyExit={pop}
      onSwitch={onSwitch}
    />
  )
}

export default withForwardedNavigationParams<Props>()(AggregatorPlaid)
