import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Clock from 'src/assets/icons/Clock.svg'

import Card from 'src/assets/icons/Card.svg'
import Check from 'src/assets/icons/Check.svg'
import Graph from 'src/assets/icons/Graph.svg'
import CalendarCash from 'src/assets/illustrations/CalendarCash.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import IconFeatureList from 'src/designSystem/components/molecules/IconFeatureList/IconFeatureList'
import {ButtonAction} from 'src/designSystem/types'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type CardAutopayOverviewTemplateProps = {
  hasPendingPhysicalCard?: boolean
  onPress: ButtonAction
}

const CardAutopayOverviewTemplate = ({
  hasPendingPhysicalCard,
  onPress,
}: CardAutopayOverviewTemplateProps): JSX.Element => {
  const {t} = useTranslation(['CardAutopayOverviewTemplate', 'Common'])
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay

  const listItems = isUserMinPayProgram
    ? [
        {
          text: t('MinPayBenefit1'),
          icon: Card,
          iconWidth: 35,
        },
        {
          text: t('MinPayBenefit2'),
          icon: Graph,
          iconWidth: 35,
        },
      ]
    : [
        {
          text: t('Benefit2'),
          icon: Graph,
          iconWidth: 35,
        },
        {
          text: t('Benefit3'),
          icon: Clock,
          iconWidth: 30,
        },
        {
          text: t('Benefit4'),
          icon: Check,
          iconWidth: 30,
        },
      ]

  if (hasPendingPhysicalCard && !isUserMinPayProgram)
    listItems.unshift({
      text: t('Benefit1'),
      icon: Card,
      iconWidth: 34,
    })

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress,
        },
      }}
      smallTopGap
      testID={'CardAutopayOverviewTemplate'}
      title={t('JoinAutoPay')}
      variant={'generic'}
    >
      <Box align={'center'} margin={'small'}>
        <PFSvgContain svg={CalendarCash} width={195} />
      </Box>

      <Box marginTop={'medium'} marginBottom={'large'}>
        <PFText variant="p_lg_semibold">{t('SubTitle')}</PFText>
      </Box>

      <Box marginBottom={'medium'}>
        <IconFeatureList items={listItems} />
      </Box>
    </Page>
  )
}

export {CardAutopayOverviewTemplate}
