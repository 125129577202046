import {FieldErrors} from 'react-hook-form'
import moment from 'moment'

import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {PfReduxState} from 'src/reducers/types'
import {userProfileSelector} from 'src/lib/user/selector'
import {Maybe, Address} from 'src/cassandra'
import {DeepNonNullable} from 'utility-types'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const validEmail = (email) => {
  return emailRegex.test(email)
}

const whitespaceRegex = /^\s*$/
export const startsOrEndsWithWhitespace = (password: string) => {
  return whitespaceRegex.test(password[0]) || whitespaceRegex.test(password[password.length - 1])
}

export const getHasErrors = (errors: FieldErrors) => {
  return Object.keys(errors)?.length > 0
}

//this function is meant to handle any variation of allowable parameters passed to react-hook-form's watch()
//NOTE: this doesn't check whether the field is required so if you want to omit optional
//fields you should pass an array of the required fields
export const getHasMissingValues = (
  watch: (names?: string | string[]) => any,
  names?: string | string[],
) => {
  const lookForMissingValues = (valueMap) => {
    const keys = Object.keys(valueMap)
    if (keys.length === 0) {
      //if the watch object is empty there are no values set
      return true
    } else {
      //otherwise check if any of the properties have empty values
      return keys.some((key) => !valueMap[key])
    }
  }

  const values = watch(names)
  if (typeof names === 'string') {
    return !values
  } else {
    return lookForMissingValues(values)
  }
}

export const getHasErrorsOrMissingValues = (
  errors: FieldErrors,
  watch: (names?: string | string[]) => any,
  names?: string | string[],
) => {
  return getHasErrors(errors) || getHasMissingValues(watch, names)
}

export const getHasUpdatedValues = (
  defaultValues: object,
  watch: (names?: string | string[]) => any,
): boolean => {
  return !!Object.keys(defaultValues).find((key) => defaultValues[key] !== watch(key))
}

export const validLengthNumeral = (length: number, value: any) => {
  const regex = new RegExp('^\\d{' + length + '}$')
  return regex.test(value)
}

const regexNumber = /^\+?\d+$/
export function isValueDefinedAndNotANumber(value) {
  //this will sometimes return undefined if we don't explicitly return true/false
  return value && !regexNumber.test(value) ? true : false
}

export function isWhitespace(value) {
  return whitespaceRegex.test(value)
}

export const routingNumberLength = 9
export const isValidRoutingNumber = (routingNumber: string): boolean => {
  return isRoutingNumberLengthValid(routingNumber) && isRoutingNumberDigitValid(routingNumber)
}

export const minimumAccountNumberLength = 4
export const isValidAccountNumber = (accountNumber: string): boolean => {
  return !!accountNumber && accountNumber.trim().length >= minimumAccountNumberLength
}

export const accountNumberMatchesMask = (accountNumber: string, mask: string): boolean => {
  return accountNumber.substring(accountNumber.length - mask.length, accountNumber.length) === mask
}

export const shortZipCodeLength = 5
export const validShortZipCode = (value) => {
  return validLengthNumeral(shortZipCodeLength, value)
}

export const isInputValueString = (inputValue: any) => {
  return /^\s*$/.test(inputValue ?? '')
}

export const hasFirstAndLastNamesFilled = (state: PfReduxState): boolean => {
  const {name} = userProfileSelector(state)
  return !isStringValueEmpty(name?.firstName) && !isStringValueEmpty(name?.lastName)
}

type ValidStreetAddress = DeepNonNullable<
  Pick<Address, 'street1' | 'city' | 'state' | 'postalCode'>
>
export type ValidAddress = ValidStreetAddress & Exclude<Partial<Address>, ValidStreetAddress>

export const isValidAddress = (address?: Maybe<Partial<Address>>): address is ValidAddress => {
  return (
    !isStringValueEmpty(address?.street1) &&
    !isStringValueEmpty(address?.city) &&
    !isStringValueEmpty(address?.state) &&
    !isStringValueEmpty(address?.postalCode)
  )
}

//routing numbers has a check digit which validates the numbers and verify if it is valid
//It follows the format: XXXXYYYYC
// Where XXXX is Federal Reserve Routing Symbol, YYYY is the Financial Institution Identifier, and C is the Check Digit.
export const calculateRoutingNumberCheckDigit = (routingNumber: string): number => {
  if (
    routingNumber?.length !== routingNumberLength - routingNumberCheckDigitLength ||
    isNaN(+routingNumber) //cast and check non numeric strings
  ) {
    return -1
  }

  const routeIndex: number[] = Array.from({length: 8}, (item, index) => {
    return Number(routingNumber[index])
  })
  let n: number = routeIndex[0]
  let sum: number = n * 3
  n = routeIndex[1]
  sum += n * 7
  n = routeIndex[2]
  sum += n
  n = routeIndex[3]
  sum += n * 3
  n = routeIndex[4]
  sum += n * 7
  n = routeIndex[5]
  sum += n
  n = routeIndex[6]
  sum += n * 3
  n = routeIndex[7]
  sum += n * 7

  return roundUp10(sum) - sum
}

const roundUp10 = (n: number): number => {
  return Math.ceil(n / 10) * 10
}

export const routingNumberCheckDigitLength = 1
export const isRoutingNumberDigitValid = (rdfi: string) => {
  const normalizedRdfi = rdfi.trim().substring(0, routingNumberLength)
  const rdfiIdentification = normalizedRdfi.substring(
    0,
    routingNumberLength - routingNumberCheckDigitLength,
  )
  const checkDigitGiven = normalizedRdfi.substring(
    routingNumberLength - routingNumberCheckDigitLength,
    routingNumberLength,
  )
  const checkDigitCalculated: number = calculateRoutingNumberCheckDigit(rdfiIdentification)
  return checkDigitGiven === String(checkDigitCalculated)
}

export const isRoutingNumberLengthValid = (routingNumber: string) => {
  return !!routingNumber && routingNumber.trim().length === routingNumberLength
}

export const ssnLength = 9
export const validSsnField = (value: string): boolean => {
  const plainValue = value.replace(/-/g, '')

  if (!plainValue || !/^\d+$/.test(plainValue) || plainValue.length !== ssnLength) {
    return false
  } else {
    return true
  }
}

export const hasDuplicates = (valuesArray: any[]) => {
  const valuesSet = new Set(valuesArray)
  return valuesSet.size !== valuesArray.length
}

export const isBelowEighteenYearsOld = (date: Date) => {
  return moment().diff(moment(date), 'years') < 18
}
