import React, {FC} from 'react'

import {LoanPaymentReviewGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import AppEvents from 'src/lib/Analytics/app_events'

type LoanPaymentReviewWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

const LoanPaymentReviewWorkflowContainer: FC<LoanPaymentReviewWorkflowContainerProps> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.review_payment_schedule_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }

  return <LoanPaymentReviewGQLContainer onContinue={handleOnContinue} />
}

export {LoanPaymentReviewWorkflowContainer}
