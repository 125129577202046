export const isStringValueEmpty = (value?: string | null): boolean =>
  !value || value.trim?.().length === 0
export const isNumericValueValid = (value?: number): boolean => typeof value === 'number'
export const getStringValue = (value?: string): string => {
  return value ?? ''
}
export const isValidAmount = (amount: string, fullBalance: number): boolean => {
  const value = Number(amount.replace('$', ''))
  return !!value && value !== 0 && value <= fullBalance
}
