import React, {FC, useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ViewPaymentMethodsTemplate} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethodsTemplate'
import {
  ViewPaymentMethodsSubsets,
  ViewPaymentMethodsAchSubset,
  ViewPaymentMethodsDebitCardSubset,
} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethods.types'
import {AppEvent} from 'src/lib/Analytics/app_events'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {PaymentMethodTypesEnum} from 'src/products/general/GeneralPaymentMethods/types'

export type ViewPaymentMethodsAnalyticsType = {
  changePaymentMethodAppEvent: AppEvent
  changePaymentMethodCompletedAppEvent: AppEvent
}

export type ViewPaymentMethodsContainerProps = {
  viewPaymentMethodsAnalytics: ViewPaymentMethodsAnalyticsType
  paymentMethods: ViewPaymentMethodsSubsets[]
  selectedPaymentMethod: ViewPaymentMethodsSubsets | null
  bankingAggregatorId?: aggregatorsId
  addPaymentMethodSuccessRouteName: keyof LoanPayNavigatorStack
  onContinue: () => void
  onSelectPaymentMethodId: (paymentMethodId?: string) => void
}

const ViewPaymentMethodsContainer: FC<ViewPaymentMethodsContainerProps> = (props) => {
  const {
    viewPaymentMethodsAnalytics,
    paymentMethods,
    selectedPaymentMethod,
    bankingAggregatorId,
    addPaymentMethodSuccessRouteName,
    onContinue,
    onSelectPaymentMethodId,
  } = props

  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList & LoanPayNavigatorStack>>()
  const mainStackNavigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const hasPaymentsPhase2 = useIsFeatureFlagEnabled('payments-phase-2')
  const isYodleeAggregator = bankingAggregatorId === aggregatorsId.yodlee
  const [addAccountType, setAddAccountType] = useState<PaymentMethodTypesEnum | null>(null)

  const {openBankAggregator} = useBankAggregator(mainStackNavigation)

  const [initialSelectedPaymentMethod] = useState<ViewPaymentMethodsSubsets | null>(
    selectedPaymentMethod,
  )

  const handleOnBankSelect = (bankAccount: ViewPaymentMethodsSubsets): void => {
    onSelectPaymentMethodId(bankAccount.bankingPaymentInstrumentId)
    setAddAccountType(null)

    TrackAppEvent(
      viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.name,
      viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.category,
      {
        type: 'existing_account',
      },
    )
    TrackAppEvent(
      viewPaymentMethodsAnalytics.changePaymentMethodCompletedAppEvent.name,
      viewPaymentMethodsAnalytics.changePaymentMethodCompletedAppEvent.category,
      {
        type: 'existing_account',
      },
    )
  }

  const handleOnContinue = (): void => {
    if (addAccountType) {
      handleOnAddNewAccount(addAccountType)
    } else if (selectedPaymentMethod?.bankingPaymentInstrumentId) {
      onContinue()
    }
  }

  const handleOnAddNewAccount = (type: PaymentMethodTypesEnum): void => {
    switch (type) {
      case PaymentMethodTypesEnum.DebitCard:
        TrackAppEvent(
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.name,
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.category,
          {
            type: 'add_debit_card',
          },
        )
        navigation.navigate('CollectDebitCardNumberForAdhocPayment', {
          onSuccessRouteDestination: addPaymentMethodSuccessRouteName,
        })
        break
      case PaymentMethodTypesEnum.AccountRouting:
        TrackAppEvent(
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.name,
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.category,
          {
            type: 'add_routing_account',
          },
        )
        navigation.navigate('BankAddRoutingAndAccount', {
          onSuccessRouteDestination: addPaymentMethodSuccessRouteName,
        })
        break
      case PaymentMethodTypesEnum.Plaid:
        TrackAppEvent(
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.name,
          viewPaymentMethodsAnalytics.changePaymentMethodAppEvent.category,
          {
            type: 'add_link_with_plaid',
          },
        )
        openBankAggregator({
          selectAndVerifyAccountMode: 'ForAdhocPayment',
          onBankLinkComplete: ({newAchPaymentMethod}) => {
            if (!newAchPaymentMethod) {
              logAddPaymentMethodError(
                new Error(
                  `ViewPaymentMethodContainer for routeName="${addPaymentMethodSuccessRouteName}" finished bank linking with aggregator but did not receive an achPaymentMethod`,
                ),
              )
            }

            // when done linking account w/plaid we'll go back to the original screen
            // and select the new payment method
            // @ts-expect-error UPDATE NOTE: we need to reduce the number of potential routes this
            // type is capable of representing
            navigation.navigate(addPaymentMethodSuccessRouteName, {
              newSelectedPaymentInstrumentId: newAchPaymentMethod?.id,
            })
          },
        })
        break
      default:
        break
    }
  }

  const handleOnManagePaymentMethods = (): void => {
    navigation.navigate('AccountManagementV2')
  }

  const isAch = (method: ViewPaymentMethodsSubsets): method is ViewPaymentMethodsAchSubset =>
    method.__typename === 'AchPaymentMethod'
  const isDebit = (
    method: ViewPaymentMethodsSubsets,
  ): method is ViewPaymentMethodsDebitCardSubset => method.__typename === 'DebitCardPaymentMethod'

  const allBankAccounts: ViewPaymentMethodsAchSubset[] = paymentMethods.filter(isAch)
  const allDebitPaymentMethods: ViewPaymentMethodsDebitCardSubset[] = paymentMethods.filter(isDebit)

  const handleOnSelectAccountType = (accountType: PaymentMethodTypesEnum): void => {
    setAddAccountType(accountType)

    // Reset to original selected payment method for now, in case user presses back to cancel
    onSelectPaymentMethodId(initialSelectedPaymentMethod?.bankingPaymentInstrumentId)
  }

  return (
    <ViewPaymentMethodsTemplate
      allBankAccounts={allBankAccounts}
      allDebitPaymentMethods={allDebitPaymentMethods}
      onBankSelect={handleOnBankSelect}
      onContinue={handleOnContinue}
      selectedPaymentMethod={addAccountType ? null : selectedPaymentMethod}
      onManagePaymentMethods={handleOnManagePaymentMethods}
      hasPaymentsPhase2={hasPaymentsPhase2}
      addAccountType={addAccountType}
      onSelectAddAccountType={handleOnSelectAccountType}
      hideAggregatorButton={isYodleeAggregator}
    />
  )
}

export {ViewPaymentMethodsContainer}
