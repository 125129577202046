import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {userIdSelector} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowLoanSelection} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'
import {LoanAmountSelectionRESTContainer} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionRESTContainer'
import {usePfSelector} from 'src/store/utils'

export type LoanAmountSelectionLegacyContainerProps = StackScreenProps<
  MainStackParamList,
  'LoanAmountSelection'
>

/**
 * Legacy container for the LoanAmountSelection screen. This is ƒor when LoanAmountSelection
 * is used outside of MPO or Workflows. Sends user to the market survey when complete.
 */
const LoanAmountSelectionLegacyContainer: React.FC<LoanAmountSelectionLegacyContainerProps> = (
  props: LoanAmountSelectionLegacyContainerProps,
) => {
  const {navigation} = props

  const marketingChannelSurveySrcQuery = useUserProperty('mkt_chl_src')
  const wasLoanMarketingSurveyViewed = !!marketingChannelSurveySrcQuery.value

  const userId: string | undefined = usePfSelector(userIdSelector)

  /**
   * Once user has been sent to the marketing suryvey and completed it
   * they nav to the next page with NavToNextPage.
   */
  const onSurveyComplete = useCallback((): void => {
    NavPageState.NavToNextPage(LoanFlowLoanSelection, navigation)
  }, [navigation])

  /**
   * When user finishes selecting their loan amount we send them to the
   * marketing survey screen.
   */
  const handleComplete = useCallback((): void => {
    NavPageState.surveyViewedNavigation({
      userId,
      surveyViewed: wasLoanMarketingSurveyViewed,
      navigation,
      onComplete: onSurveyComplete,
    })
  }, [navigation, userId, wasLoanMarketingSurveyViewed, onSurveyComplete])

  return <LoanAmountSelectionRESTContainer navigation={navigation} onComplete={handleComplete} />
}

export {LoanAmountSelectionLegacyContainer}
