import {AddressMPOContainer} from 'src/products/MCU/Address/AddressMPOContainer'
import PersonalInformationMPOContainer from 'src/products/loans/PersonalInformation/PersonalInformationMPOContainer'
import PhoneConfirmationMPOContainer from 'src/products/loans/PhoneConfirmation/PhoneConfirmationMPOContainer'
import PhoneVerificationMPOContainer from 'src/products/loans/PhoneConfirmation/PhoneVerificationMPOContainer'
import {OnboardingModuleDefinitions} from 'src/flows/types'
import {ProductChoiceMPOContainer} from 'src/products/loans/ProductChoice/ProductChoiceMPOContainer'

export const SignUpModuleDefinitions: Partial<OnboardingModuleDefinitions> = {
  VERIFY_PHONE_NUMBER: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'VERIFY_PHONE_NUMBER',
        screen: {
          path: 'phone/enter',
        },
        Component: PhoneConfirmationMPOContainer,
        headerLeftButton: 'Logout',
      },
      {
        name: 'CONFIRM_PHONE_NUMBER',
        screen: {
          path: 'phone/verify',
        },
        Component: PhoneVerificationMPOContainer,
      },
    ],
  },
  BASIC_PII: {
    screen: {
      path: 'info',
    },
    Component: PersonalInformationMPOContainer,
  },
  HOME_ADDRESS: {
    screen: {
      path: 'address',
    },
    Component: AddressMPOContainer,
  },
  PRODUCT_SELECTION: {
    screen: {
      path: 'products',
    },
    options: {cardStyle: {flex: 1}},
    Component: ProductChoiceMPOContainer,
  },
  WAITLIST: {
    type: 'End',
    navigateTo: 'UnsupportedStateWaitList',
  },
}
