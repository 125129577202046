import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowPhoneConfirmation} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'
import PhoneVerificationTemplate from 'src/products/loans/PhoneConfirmation/PhoneVerificationTemplate'

type Props = StackScreenProps<MainStackParamList, 'PhoneVerification'> & {
  onVerifiedSuccessfully: () => Promise<void>
}

const PhoneVerificationLegacyContainer: React.FC<Props> = (props) => {
  const {navigation} = props

  const handleOnVerifiedSuccessfully = (): void => {
    navigation.pop(1)
    // Using navigation.pop here to prevent the user from seeing the
    // verification code input if they navigate back after they have verified
    NavPageState.NavToNextPage(LoanFlowPhoneConfirmation, navigation)
  }

  return (
    <PhoneVerificationTemplate {...props} onVerifiedSuccessfully={handleOnVerifiedSuccessfully} />
  )
}

export default withForwardedNavigationParams<Props>()(PhoneVerificationLegacyContainer)
