import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanCancelledTileProps = {
  onApplyForNewLoan: ButtonAction
  testID?: string
}
/**
 * A tile to inform the user that their loan has been cancelled.
 */
export const LoanCancelledTile: React.FC<LoanCancelledTileProps> = ({
  onApplyForNewLoan: handleOnApplyForNewLoan,
  testID,
}) => {
  const {t} = useTranslation('DashboardLoanCancelledTile')
  return (
    <BasicTile padding="small" align="center" testID={testID}>
      <PFText variant="p_lg_semibold" textAlign="center">
        {t('LoanHasBeenCancelled')}
      </PFText>
      <Box marginTop="small">
        <PFText variant="p" textAlign="center">
          {t('PleaseApplyAgain')}
        </PFText>
      </Box>
      <Box fill="horizontal" align="center" marginTop="small">
        <Button
          mode="primary"
          size="medium"
          width="100%"
          onPress={handleOnApplyForNewLoan}
          testID="LoanCancelledTile-ApplyNowBtn"
        >
          {t('ApplyNow')}
        </Button>
      </Box>
    </BasicTile>
  )
}
