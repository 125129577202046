import React, {FC} from 'react'
import {useNavigation} from '@react-navigation/native'

import {LoanFinalAcceptGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  LoanFinalAcceptNavProps,
  handleOnConfirmBankDetails,
  onContinueToDashboardNavPageState,
} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'

const LoanFinalAcceptNavPageStateContainer: FC = () => {
  const navigation: LoanFinalAcceptNavProps = useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.final_loan_acceptance_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  return (
    <LoanFinalAcceptGQLContainer
      onContinue={(): Promise<void> =>
        onContinueToDashboardNavPageState(navigation, LoanApprovedFlow.LoanFinalAccept)
      }
      onConfirmBankDetails={(): void => handleOnConfirmBankDetails(navigation)}
    />
  )
}

export {LoanFinalAcceptNavPageStateContainer}
