import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {StackNavigationProp} from '@react-navigation/stack'
import {ReactNode} from 'react'

import {
  CheckPlaidProfileEligibilityQuery,
  CheckPlaidProfileEligibilityQueryVariables,
} from 'src/products/MCU/PlaidLayers/queries/CheckPlaidProfileEligibility.gqls'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'

export const eligibilitySelector = (
  data: CheckPlaidProfileEligibilityQuery,
): boolean | null | undefined => data.checkPlaidProfileEligibility.isEligible

type CheckIfEligibleForPlaidLayersType = ReturnType<
  typeof useCassandraLazyQuery<
    CheckPlaidProfileEligibilityQuery,
    CheckPlaidProfileEligibilityQueryVariables,
    ReturnType<typeof eligibilitySelector>
  >
>[0]

export type UsePlaidLayersReturnType = {
  isLoadingPlaidData: boolean
  linkSessionId: string | null
  createLayerSession: () => void | boolean
  openPlaidLayers: (
    onSuccess: () => Promise<void>,
    onFailProcessProfile: () => Promise<void>,
    onExit: () => void,
  ) => Promise<void> | void
  checkIfEligibleForPlaidLayers: CheckIfEligibleForPlaidLayersType
  restartPlaidLayers: () => Promise<void>
}

export type UsePlaidLayerProps = {
  onPreReqFulfilled: WorkflowPreReqFulfillScreenProps['onPreReqFulfilled']
  navigation: StackNavigationProp<WorkflowsStackParams> | undefined
}

export type PlaidLayersProviderProps = {
  children: ReactNode
  onPreReqFulfilled: WorkflowPreReqFulfillScreenProps['onPreReqFulfilled']
  navigation: StackNavigationProp<WorkflowsStackParams> | undefined
}
