import {
  AchPaymentMethod,
  CheckPaymentMethod,
  DebitCardPaymentMethod,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'

/**
 * Given the typename from a PaymentMethod (ex: AchPaymentMethod) get the corresponding LoanPaymentMethod enum
 * value (ex: LoanPaymentMethod.Ach).
 */
export const getPaymentMethodEnumFromTypename = (
  paymentMethodTypename:
    | AchPaymentMethod['__typename']
    | DebitCardPaymentMethod['__typename']
    | CheckPaymentMethod['__typename'],
): LoanPaymentMethod | undefined => {
  let loanDisbursementMethod: LoanPaymentMethod | undefined = undefined
  switch (paymentMethodTypename) {
    case 'AchPaymentMethod':
      loanDisbursementMethod = LoanPaymentMethod.Ach
      break
    case 'DebitCardPaymentMethod':
      loanDisbursementMethod = LoanPaymentMethod.DebitCard
      break
    case 'CheckPaymentMethod':
      loanDisbursementMethod = LoanPaymentMethod.Check
      break
    case undefined:
      loanDisbursementMethod = undefined
  }
  return loanDisbursementMethod
}
