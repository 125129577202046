import React, {ReactElement} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

import {AcceptLoanAndAutoPayAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsGQLContainer'
import {AcceptLoanAndAutoPayAgreementsWATemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndAutoPayAgreementsWA/AcceptLoanAndAutoPayAgreementsWATemplate'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type AcceptLoanAndAutoPayAgreementsWAWorkflowContainerProps =
  WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptLoandAndAutoPayAgreements WA screen within Workflows.
 */
const AcceptLoanAndAutoPayAgreementsWAWorkflowContainer: React.FC<
  AcceptLoanAndAutoPayAgreementsWAWorkflowContainerProps
> = (props: AcceptLoanAndAutoPayAgreementsWAWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'loan_and_autopay_wa',
    },
  })
  return (
    <AcceptLoanAndAutoPayAgreementsGQLContainer
      testID="AcceptLoanAndAutoPayAgreementsWAWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => (
        <AcceptLoanAndAutoPayAgreementsWATemplate {...innerProps} />
      )}
    />
  )
}

export {AcceptLoanAndAutoPayAgreementsWAWorkflowContainer}
