import {StackScreenProps} from '@react-navigation/stack'
import {useEffect, useRef} from 'react'

import {LoanOfferAvailabilityStatus} from '@possible/cassandra/src/types/types.mobile.generated'

import {getLoanTransfers} from 'src/api/actions/loans/loanActions'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {logErrorAndShowException} from 'src/lib/errors'
import {LoansStateChange} from 'src/lib/loans/actions'
import {ILoanRedux, Transfer} from 'src/lib/loans/reducers/types'
import {
  getUserStateAbv,
  getUserUSStateFromLoan,
  latestLoanSelector,
  loanSelector,
} from 'src/lib/loans/selector'
import {isPendingInstallmentLoan, isUpgradeAvailable} from 'src/lib/loans/utils'
import Log from 'src/lib/loggingUtil'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import NavPageState from 'src/navigation/NavPageState'
import {LoansDashboardReduxData} from 'src/products/loans/Dashboard/DashboardLoan.types'
import {DashboardLoanAggregateStatusQueryLoanOfferInfo} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {
  WhyChargedOffModalProps,
  showWhyChargedOffModal,
} from 'src/products/loans/Dashboard/WhyChargedOffModal/WhyChargedOffModal'
import {showWhyDefaultModal} from 'src/products/loans/Dashboard/WhyDefaultModal/WhyDefaultModal'
import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {getIsLoansRepeatLoanWorkflowEnabled} from 'src/workflows/featureFlags'
import {wfError} from 'src/workflows/logging'
import {SetWorkflowStateAction} from 'src/workflows/slice'

export type DashboardNavigation = StackScreenProps<
  MainStackParamList,
  'Dashboard' | 'ProductHub'
>['navigation']

/**
 * Gather all necessary data from Redux for the loans dashboard V2.
 */
export const useLoansDashboardReduxData = (params: {
  dispatch: ReturnType<typeof usePfDispatch>
}): LoansDashboardReduxData => {
  // refactored from PaymentDetailsTile mapStateToProps()
  const {dispatch} = params
  useEffect(() => {
    // on mount get latest loan data into redux
    void dispatch(UserStateRefresh())
  }, [dispatch])
  const loan: ILoanRedux | null = usePfSelector(latestLoanSelector)
  const hasRetrievedTransfers = useRef(false)
  useEffect(() => {
    // first time loan changes we will load transfers
    if (loan?.id && !hasRetrievedTransfers.current) {
      hasRetrievedTransfers.current = true
      void getLoanTransfers(loan.id, dispatch)
    }
  }, [loan, dispatch, hasRetrievedTransfers])

  // the loan to use for transfers. if latest_loan is an installment loan with status=pending,
  // show the transfer payments from the original loan
  let transferLoan: ILoanRedux | null
  const originalLoan: ILoanRedux | undefined = usePfSelector((state: PfReduxState) => {
    if (loan?.originalLoanId) {
      return loanSelector(state, {loanId: loan.originalLoanId})
    }
    return undefined
  })
  if (loan && isPendingInstallmentLoan(loan) && loan.originalLoanId && originalLoan) {
    transferLoan = originalLoan
  } else {
    transferLoan = loan
  }
  // get transfers (payments) for this loan
  const transfers =
    usePfSelector((state: PfReduxState): Transfer[] | null => {
      if (
        state.lib?.loans?.transfers &&
        transferLoan?.id &&
        state.lib.loans.transfers[transferLoan.id]
      ) {
        return state.lib.loans.transfers[transferLoan.id]
      }
      return null
    }) ?? []

  // determine user's US state abbreviation
  const userUSStateFromLoan = usePfSelector((state: PfReduxState) => {
    return getUserUSStateFromLoan(state, loan)
  })
  const userUSStateFromLoanTerms = usePfSelector((state: PfReduxState) => {
    return getUserStateAbv(state)
  })
  const userStateAbv = userUSStateFromLoan ?? userUSStateFromLoanTerms

  return {
    onUpdateLoanPaymentDatesReduxData: {
      transfers,
      loan,
      userStateAbv,
    },
  }
}

export const onShowDefaultExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(LoanDashboardEvents.default_explanation_selected, AppEvents.Category.LoanDashboard)
  showWhyDefaultModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

export const onShowChargedOffExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(
    LoanDashboardEvents.charged_off_explanation_selected,
    AppEvents.Category.LoanDashboard,
  )
  showWhyChargedOffModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

const SelectLoanOffer = (
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[],
): string | null => {
  // get the first available loan offer we see
  for (const offer of loanOffers) {
    if (
      offer.loanOfferAvailabilityStatus === LoanOfferAvailabilityStatus.OfferAvailable &&
      offer.offers.length > 0
    ) {
      return offer.offers[0].id
    }
  }
  return null
}

/**
 * Handle when a user begins to reapply for a new loan AFTER they've already had their first loan.
 */
export const onReapplyForNewLoan = (params: {
  navigation: DashboardNavigation
  dispatch: ReturnType<typeof usePfDispatch>
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
  reapplyEventArgs?: object
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[]
}): void => {
  try {
    const {
      navigation,
      dispatch,
      shouldRedirectLoanApplyAndAcceptToWeb,
      reapplyEventArgs = {},
    } = params
    // reapply_for_loan_selected event is specific to re-applying
    TrackAppEvent(
      LoanDashboardEvents.reapply_for_loan_selected,
      AppEvents.Category.LoanDashboard,
      reapplyEventArgs,
    )
    // apply_for_loan_selected is not technically re-apply only
    TrackAppEvent(
      ManageActiveLoanEvents.apply_for_loan_selected,
      AppEvents.Category.ManageActiveLoan,
    )
    if (shouldRedirectLoanApplyAndAcceptToWeb) {
      EmitRedirectionEvent('reapplication')
    } else {
      dispatch(
        LoansStateChange({
          reapplying: true,
          user_selected_loan_amount: false,
        }),
      )

      if (getIsLoansRepeatLoanWorkflowEnabled()) {
        const loanOfferId = SelectLoanOffer(params.loanOffers)
        if (loanOfferId) {
          // would be cool to get a busy state on this
          // OR should `OfferApplicationWorkflow` "select" the offer
          // passed to it?
          // https://possible.atlassian.net/browse/ENG-19702
          dispatch(
            SetWorkflowStateAction({
              // we set these here so that OLD customers aren't forced to go
              // through them (again). OLD customers won't have these set
              // if they used MPO or NavPageState to apply for their
              // initial loan
              // https://possible.atlassian.net/browse/ENG-19701
              metOneTimeFrontEndPreReqs: [
                'LOAN_PRODUCT_INFORMATION',
                'MARKETING_SURVEY',
                'PII_CONFIRM',
              ],
              selectedOffer: {offerId: loanOfferId, metFrontEndPreReqs: []},
            }),
          )
            .unwrap()
            .then(() => {
              navigation.push('OfferApplicationWorkflow', {
                offerId: loanOfferId,
                screen: 'Loading',
              })
            })
            .catch((e) => {
              const error = e instanceof Error ? e : new Error(String(e))
              wfError(error, 'Failed to select loan offer from loan dashboard')
              loanDashboardLogErrorShowException(
                error,
                'Failed to select loan offer from loan dashboard',
              )
              NavPageState.PushNavToReapply(navigation)
            })
        } else {
          // somehow we got here without a loan offer available -- this seems bad
          wfError(
            new Error('No loan offer available'),
            'Failed to select loan offer from loan dashboard',
          )
          NavPageState.PushNavToReapply(navigation)
        }
      } else {
        NavPageState.PushNavToReapply(navigation)
      }
    }
  } catch (e) {
    void loanDashboardLogErrorShowException(
      e instanceof Error ? e : new Error(String(e)),
      'DashboardLoan.utils onReapplyForNewLoan() failed to send user to reapply screen',
    )
  }
}

/**
 * Open the contact us form to let users contact us for any reason.
 */
export const onContactUs = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.contact_us_selected, AppEvents.Category.LoanDashboard)
  openContactUsForm(params.navigation)
}

/**
 * Send user to the account management screen to relink their bank account.
 */
export const onManagePaymentAccounts = (params: {navigation: DashboardNavigation}): void => {
  const {navigation} = params
  navigation.navigate('AccountManagementV2')
}

/**
 * Let the user update their loan payment dates schedule. If an upgrade is available it will send them
 * to that UX, otherwise they're sent to the scheduling screen.
 */
export const onUpdateLoanPaymentDates = (params: {
  navigation: DashboardNavigation
  onUpdateLoanPaymentDatesReduxData: LoansDashboardReduxData['onUpdateLoanPaymentDatesReduxData']
}): void => {
  // refactored from loanCardUtils onReschedule()
  const {onUpdateLoanPaymentDatesReduxData, navigation} = params
  const {transfers, userStateAbv, loan} = onUpdateLoanPaymentDatesReduxData
  TrackAppEvent(LoanDashboardEvents.update_payment_dates_selected, AppEvents.Category.LoanDashboard)
  TrackAppEvent(
    ManageActiveLoanEvents.reschedule_payments_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  if (!transfers) {
    const err = new Error('Transfer data not available, cannot update loan payment dates')
    void loanDashboardLogErrorShowException(
      err,
      'DashboardLoan.utils, onUpdateLoanPaymentDates(), unable to update loan payment dates, missing transfer data',
    )
    return
  } //without the transfers we can't make a decision
  if (isUpgradeAvailable(transfers, userStateAbv)) {
    try {
      navigation.push('UpgradeToInstallment', {isFromDashboard: true})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to UpgradeToInstallment failed',
      )
    }
  } else {
    try {
      if (!loan?.id) {
        throw new Error('loanId not available')
      }
      navigation.push('SetSchedule', {loanId: loan?.id})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to SetSchedule failed',
      )
    }
  }
}

/**
 * Send user to the documents history page to view their loan history.
 */
export const onViewLoanHistory = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.view_history_selected, AppEvents.Category.LoanDashboard)
  const {navigation} = params
  navigation.navigate('DocumentsHistory')
}

const addLoanDashboardIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - Loans Dashboard`)
  modifiedError.stack = e.stack
  return modifiedError
}

/**
 * Log error and show an exception for all dashboard related errors.  Includes standardized log prefix.
 */
export const loanDashboardLogErrorShowException = (e: unknown, context?: string): void => {
  void logErrorAndShowException(
    addLoanDashboardIdentifierToError(e instanceof Error ? e : new Error(String(e))),
    context,
  )
}

/**
 * Log errors related to the dashboard. Includes standardized log prefix.
 */
export const loanDashboardLogError = (e: Error, msg?: string): void => {
  Log.error(addLoanDashboardIdentifierToError(e), msg)
}
