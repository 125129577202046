import {useMemo} from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useLoansActivationWorkflowEnabled} from 'src/workflows/featureFlags'
import {wfError, wfLog} from 'src/workflows/logging'
import {WorkflowsInitialRouteType, WorkflowsType} from 'src/workflows/types'
import {GetNextRouteFromPreReqs, isInActivationStage} from 'src/workflows/utils'

/**
 * A hook to get the initialRoute for AuthenticatedRoutes based on the
 * status of the workflows data.
 * @param workflowsData The workflows data to use.
 * @param fallbackRouteName The route to use if we should not be using workflows.
 * @returns An initial route to use or undefined if the data is still loading.
 */
export const useWorkflowsInitialRoute = (
  workflowsData: WorkflowsType,
  fallbackRouteName: keyof MainStackParamList = 'DashboardLoader',
): WorkflowsInitialRouteType => {
  const {status} = workflowsData
  const hasLoansActivationFF = useLoansActivationWorkflowEnabled()

  // I think we need to generate this JUST ONCE
  // and then return it every time the hook is called
  // it kinda looks like this is spamming datadog
  // with errors when status changes (which can happen
  // when the workflows data is re-queried while stepping
  // through a workflow)
  return useMemo((): WorkflowsInitialRouteType => {
    switch (status) {
      case 'ERROR':
        TrackAppEvent(AppEvents.Name.workflow_initial_route_error, AppEvents.Category.Workflows, {
          error: workflowsData.error,
        })
        wfError(workflowsData.error, 'Error while determining initial route.')
        return {loading: false, name: fallbackRouteName}
      case 'LOADING':
        return {loading: true}
      case 'READY': {
        const {applications, offers, selectedOffer, unmetMinPreReqs} = workflowsData
        const nextMinPreReqRoute = GetNextRouteFromPreReqs(unmetMinPreReqs)

        // Workflows
        if (isInActivationStage(applications, hasLoansActivationFF)) {
          // Activation Flow
          return {loading: false, name: 'ProductHub'}
        } else if (selectedOffer && selectedOffer?.unmetPreReqs.length > 0) {
          // Offer Flow
          wfLog('Going to OfferApplicationWorkflow')
          return {loading: false, name: 'OfferApplicationWorkflow'}
        } else if (offers.length > 0 || applications?.length > 0) {
          // Product Hub
          wfLog('Going to ProductHub')
          return {loading: false, name: 'ProductHub'}
        } else if (!nextMinPreReqRoute && offers.length === 0 && applications?.length === 0) {
          // if we have no offers and no applications there is nothing the user can do;
          // let's send 'em to the waitlist
          wfLog('Going to Waitlist')
          return {loading: false, name: 'UnsupportedStateWaitList'}
        }

        // Signup Flow
        if (nextMinPreReqRoute) {
          return {loading: false, name: 'SignupWorkflow'}
        }

        return {loading: false, name: fallbackRouteName}
      }
    }

    // we do not need to get the initial route every time workflowsData changes
    // and it wouldn't matter anyway, react navigation ignores initialRouteName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fallbackRouteName])
}
