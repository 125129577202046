import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Log from 'src/lib/loggingUtil'
import {ShowException} from 'src/lib/errors'
import {completeUra} from '@possible/cassandra'
import {UserDocumentSubType} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {UploadDocuments} from 'src/products/general/UploadDocuments/UploadDocuments'

type JointAccountVerificationProps = StackScreenProps<
  MainStackParamList,
  'JointAccountVerification'
>

export const JointAccountVerification: React.FC<JointAccountVerificationProps> = (props) => {
  const {navigation, route} = props
  const uraIdToComplete = route?.params?.uraIdToComplete
  const {t} = useTranslation(['UserRequestedAction'])

  async function handleOnContinue(): Promise<void> {
    try {
      await completeUra(uraIdToComplete)
      TrackAppEvent(
        AppEvents.Name.joint_account_verifcation_URA_completed,
        AppEvents.Category.InitialApplication,
      )
      // We must call this twice. pop(2) doesn't work because they're in different stacks
      navigation.goBack() // Remove this screen
      navigation.goBack() // Remove URA lightbox
    } catch (e) {
      Log.error(e, `JointAccountVerification failed to complete URA with id: ${uraIdToComplete}`)
      ShowException(e)
    }
  }

  return (
    <UploadDocuments
      onContinue={handleOnContinue}
      documentSubType={UserDocumentSubType.BankStatement}
      title={t('VerifyYourAccount')}
      body={t('JointAccountVerification')}
    />
  )
}
