import AddBankLink from 'src/products/general/AddBankLink/AddBankLinkMPOContainer'
import IDScanMPOContainer from 'src/products/loans/IDScan/IDScanMPOContainer'
import {OnboardingModuleDefinitions} from 'src/flows/types'
import PersonalInfoConfirmationMPO from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmationMPOContainer'
import {CardAgreementViewerMPOContainer} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerMPOContainer'
import {CardProcessingMPOContainer} from 'src/products/card/Application/CardProcessingMPOContainer'
import {SSNCollectionMPOContainer} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionMPOContainer'
import {LivenessInfoMPOContainer} from 'src/products/general/LivenessInfo/LivenessInfoMPOContainer'
import {LivenessMPOContainer} from 'src/products/general/Liveness/LivenessMPOContainer'
import {CardProductIntroductionMPOContainer} from 'src/products/card/Application/CardProductIntroduction/CardProductIntroductionMPOContainer'
import {EmailVerificationMPOContainer} from 'src/products/loans/EmailVerification/EmailVerificationMPOContainer'

export const CardApplicationModuleDefinitions: Partial<OnboardingModuleDefinitions> = {
  CARD_LV2_SSN: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'CARD_LV2_SSN',
        screen: {
          path: 'card/lvl2-ssn',
        },
        Component: SSNCollectionMPOContainer,
      },
      {
        name: 'CARD_LVL2_REVIEW_APPLICATION',
        screen: {
          path: 'card/lvl2-review-application',
        },
        Component: PersonalInfoConfirmationMPO,
      },
    ],
  },
  CARD_LV3_SSN: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'CARD_LV3_SSN',
        screen: {
          path: 'card/lvl3-ssn',
        },
        Component: SSNCollectionMPOContainer,
      },
      {
        name: 'CARD_LVL3_REVIEW_APPLICATION',
        screen: {
          path: 'card/lvl3-review-application',
        },
        Component: PersonalInfoConfirmationMPO,
      },
    ],
  },
  CARD_LV2_SELECTED: {
    screen: {
      path: 'card/lvl2-selected',
    },
    options: {cardStyle: {flex: 1}},
    Component: CardProductIntroductionMPOContainer,
  },
  CARD_LV3_SELECTED: {
    screen: {
      path: 'card/lvl3-selected',
    },
    options: {cardStyle: {flex: 1}},
    Component: CardProductIntroductionMPOContainer,
  },
  CARD_BANK_LINK: {
    screen: {
      path: 'card/bank-link',
    },
    Component: AddBankLink,
    options: {
      headerBackgroundContainerStyle: {
        backgroundColor: 'transparent',
      },
      headerTintColor: 'white',
    },
  },
  CARD_SELFIE: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'CARD_SELFIE',
        screen: {
          path: 'card/selfie-info',
        },
        Component: LivenessInfoMPOContainer,
        options: {
          headerBackgroundContainerStyle: {
            backgroundColor: 'transparent',
          },
          headerTintColor: 'white',
          cardStyle: {flex: 1},
        },
      },
      {
        name: 'CARD_SELFIE_CAPTURE',
        screen: {
          path: 'card/selfie',
        },
        Component: LivenessMPOContainer,
        options: {cardStyle: {flex: 1}},
      },
    ],
  },
  CARD_PHOTO_ID: {
    screen: {
      path: 'card/photo-id',
    },
    Component: IDScanMPOContainer,
    options: {cardStyle: {flex: 1}},
  },
  CARD_EMAIL_VERIFICATION: {
    screen: {
      path: 'card/email-verification',
    },
    Component: EmailVerificationMPOContainer,
  },
  CARD_SUBMISSION: {
    screen: {
      path: 'card/submission',
    },
    Component: CardAgreementViewerMPOContainer,
  },
  CARD_PROCESSING: {
    screen: {
      path: 'card/processing',
    },
    Component: CardProcessingMPOContainer,
    headerLeftButton: 'Menu',
  },
}
