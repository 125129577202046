import {ApolloError} from '@apollo/client'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {AccountListDocument, AccountListQuery} from 'src/lib/bank/queries/AccountList.gqls'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

type LinkedAccountsSelectedData =
  | undefined
  | {
      linkedAccounts: AccountListQuery['me']['bankAccounts']['all']
      preferredAccount:
        | undefined
        // we know that preferredFundingSource must be true for the preferredAccount
        // so change the type to know it won't ever be false
        | (NonNullable<AccountListQuery['me']['bankAccounts']['all']>[0] & {
            preferredFundingSource: true
          })
    }

export type UseGetLinkedAccountsResult = {
  linkedAccountsData: LinkedAccountsSelectedData
  linkedAccountsError: ApolloError | undefined
  linkedAccountsRefetch: ReturnType<
    typeof useCassandraQuery<AccountListQuery, object, LinkedAccountsSelectedData>
  >['refetch']
  isLoadingLinkedAccounts: boolean
}

/**
 * Hook to get a user's linked accounts and their primary account. Note that you may be looking for
 * PaymentMethods instead of LinkedAccounts since payment methods added without Plaid/Yodlee will
 * not be included in the LinkedAccounts list.
 */
export const useGetLinkedAccounts = (): UseGetLinkedAccountsResult => {
  const {
    selectedData: linkedAccountsData,
    loading: isLoadingLinkedAccounts,
    error: linkedAccountsError,
    refetch: linkedAccountsRefetch,
  } = useCassandraQuery(
    AccountListDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (error) => {
        logAddPaymentMethodErrorAndShowException(error, 'useGetLinkedAccounts failed')
      },
    },
    (data) => {
      let preferredAccount:
        | undefined
        | NonNullable<LinkedAccountsSelectedData>['preferredAccount'] = undefined
      if (data?.me.bankAccounts.all) {
        for (let i = 0; i < data?.me.bankAccounts.all?.length; i++) {
          const thisAcct = data.me.bankAccounts.all[i]
          if (thisAcct.preferredFundingSource === true) {
            // the type definition of LinkedAccount says that preferredFundingSource may be false
            // but at this point we KNOW it's true. force it to true so that typescript knows it
            // can't be false for preferredAccount, it's not able to infer that preferredFundingSource
            // will be true even tho we check with .preferredFundingSource === true
            preferredAccount = {
              ...thisAcct,
              preferredFundingSource: true,
            }
          }
        }
      }
      return {
        linkedAccounts: data?.me?.bankAccounts?.all ?? undefined,
        preferredAccount: preferredAccount,
      }
    },
  )

  return {linkedAccountsData, linkedAccountsError, isLoadingLinkedAccounts, linkedAccountsRefetch}
}
