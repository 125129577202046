import {useContext, useEffect, useState} from 'react'

import {userIsLoggedIn} from 'src/api/selectors/selectors'
import {LoansStateChange} from 'src/lib/loans/actions'
import {addDeepLink} from 'src/lib/singular/utils'
import {Destinations, EmitLoadingCompleteEvent} from 'src/lib/utils/events'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {NavContext} from 'src/nav/NavContext'
import {ApplicationDashboard} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'
import {getPfStore} from 'src/store'
import {usePfSelector} from 'src/store/utils'

const handleLoggedInNavigation = (
  navigation: ReturnType<typeof useMainNavigator>,
  destination: Destinations,
) => {
  switch (destination) {
    case 'mpo':
      navigation.reset({routes: [{name: 'Onboarding'}]})
      break
    case 'acceptance': {
      const next = NavPageState.GetNextApprovalState(ApplicationDashboard)
      if (!next?.screen) {
        navigation.reset({routes: [{name: 'Dashboard'}]})
        break
      }

      navigation.reset({routes: [{name: 'Dashboard'}, {name: next.screen, params: next.props}]})
      break
    }
    case 'application':
      NavPageState.NavToDashboard()
      NavPageState.NavToNextPage(ApplicationDashboard, navigation)
      break
    case 'reapplication':
      getPfStore().dispatch(LoansStateChange({reapplying: true, user_selected_loan_amount: false}))
      navigation.reset({routes: [{name: 'Dashboard'}, {name: 'LoanAmountSelection'}]})
      break
    default:
      break
  }

  EmitLoadingCompleteEvent(destination)
}

export const useURLNavigation = (): boolean | undefined => {
  const isLoggedIn = usePfSelector(userIsLoggedIn)
  const navigation = useMainNavigator()

  const [state, updateState] = useContext(NavContext)

  // we need any components using this to first render
  // any Navigators at least once before we issue a
  // `navigate` call. So let's force a single render cycle.
  const [isNotFirstCycle, setIsNotFirstCycle] = useState(false)

  const {handledDestination: didHandleDestination, destination} = state
  useEffect(() => {
    if (!isNotFirstCycle) {
      setIsNotFirstCycle(true)
      return
    }

    if (!destination || destination === 'none') {
      return
    }

    if (didHandleDestination) {
      return
    }

    if (isLoggedIn) {
      handleLoggedInNavigation(navigation, destination)
      updateState(() => ({handledDestination: true}))
    } else {
      if (destination === 'verify-email') {
        navigation.navigate('VerifyEmailLink')
        updateState(() => ({handledDestination: true}))
      } else if (destination === 'apply') {
        navigation.navigate('Registration')
        updateState(() => ({handledDestination: true}))
      } else if (destination === 'card') {
        addDeepLink({type: 'card_landing', path: 'card_landing'})
        navigation.reset({index: 0, routes: [{name: 'CardLandingWebV2'}]})
        updateState(() => ({handledDestination: true}))
      }
    }
  }, [destination, didHandleDestination, isNotFirstCycle, isLoggedIn, navigation, updateState])

  return (
    !isNotFirstCycle ||
    (destination && destination !== 'none' && destination !== 'apply' && destination !== 'card')
  )
}
