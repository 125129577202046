import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'
import {URAProps} from 'src/products/loans/UserRequestedAction/URAProps'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/loans/UserRequestedAction/URAModalContent'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'

export const UploadDocumentsModal: React.FunctionComponent<
  URAProps & {
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
    uploadDocumentsRoute: keyof MainStackParamList
    uraViewedEvent: {
      eventName: AppEventName
      eventCategory: AppEventCategory
    }
  }
> = (props) => {
  const {componentId, ura_id, close, navigation, uploadDocumentsRoute, uraViewedEvent} = props // eslint-disable-line @typescript-eslint/naming-convention

  const {t} = useTranslation('UserRequestedAction')
  const dispatch = usePfDispatch()

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(ura_id, state))

  useEffect(() => {
    TrackAppEvent(uraViewedEvent.eventName, uraViewedEvent.eventCategory)

    dispatch(URAUIUpdateAction([{id: ura_id, componentId, close}], 'MergeRecord'))
  }, [dispatch, ura_id, componentId, close, uraViewedEvent.eventName, uraViewedEvent.eventCategory])

  const handleNavigateToJoinAccountVerification = (): void => {
    navigation.push(uploadDocumentsRoute, {
      uraIdToComplete: ura_id,
    })
  }

  const primary: LightboxNoticeAction = {
    text: t('UploadMyDocument'),
    onPress: handleNavigateToJoinAccountVerification,
    size: 'large',
  }

  return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
}
