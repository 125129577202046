import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {UpdateLastLoan} from 'src/api/actions/loans/loanActions'
import FastLoans from 'src/assets/illustrations/FastLoans.svg'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ModuleControlProps} from 'src/flows/types'
import {latestLoanSelector} from 'src/lib/loans/selector'
import {
  hasApplicationExpired,
  hasLoanExpired,
  isLoanApproved,
  isLoanRejected,
} from 'src/lib/loans/utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import LoanProcessingResult from 'src/products/loans/LoanProcessing/LoanProcessingResult'
import {LoanProcessingCounterOfferMPOContainer} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferMPOContainer'

type Props = StackScreenProps<MainStackParamList, 'Onboarding'> & ModuleControlProps

// Remove in ENG-18602
const LoanProcessing: React.FC<Props> = (props) => {
  const {navigation, setLeftHeaderButton, onModuleComplete} = props

  const {t} = useTranslation('Onboarding')

  const loan = usePfSelector(latestLoanSelector)

  const dispatch = usePfDispatch()

  const loanStatus = loan?.status?.status
  const loanSubStatus = loan?.status?.subStatus
  const [loanApproved, loanRejected, loanExpired, applicationExpired] = useMemo(() => {
    if (loanStatus && loanSubStatus) {
      return [
        isLoanApproved(loanStatus),
        isLoanRejected(loanStatus),
        hasLoanExpired(loanStatus, loanSubStatus),
        hasApplicationExpired(loanStatus, loanSubStatus),
      ]
    }

    return [false, false, false, false]
  }, [loanStatus, loanSubStatus])

  const isCounterOfferFlow = loan?.status?.counterOfferCodes
    ? loan?.status?.counterOfferCodes?.length > 0
    : false

  useEffect(() => {
    const interval = setInterval(() => dispatch(UpdateLastLoan()), 30000)
    return () => clearInterval(interval)
  }, [dispatch])

  useEffect(() => {
    if (loanApproved || loanRejected) {
      navigation.setOptions({headerTitle: undefined})
      setLeftHeaderButton?.('None')
      return
    }

    navigation.setOptions({
      headerTitle: function headerTitle() {
        return <NameLogo />
      },
    })

    setLeftHeaderButton?.('Menu')
  }, [loanApproved, loanRejected, navigation, setLeftHeaderButton])

  useEffect(() => {
    // For initial MPO launch we are just going to redirect to the
    // dashboard if the user's loan or loan application has expired
    if (loanExpired || applicationExpired) {
      onModuleComplete?.('LOAN_END')
    }
  }, [applicationExpired, loanExpired, onModuleComplete])

  if (isCounterOfferFlow) {
    return <LoanProcessingCounterOfferMPOContainer {...props} />
  }

  if (loanApproved || loanRejected) {
    return (
      <LoanProcessingResult
        {...props}
        approved={loanApproved}
        rejected={loanRejected}
        expired={loanExpired}
        applicationExpired={applicationExpired}
      />
    )
  }

  return (
    <Page variant="generic" smallTopGap>
      <Box fill marginTop="small">
        <BasicTile padding="medium">
          <Box gap="medium">
            <Box fill="horizontal" paddingHorizontal={30}>
              <PFSvgContain svg={FastLoans} width="100%" />
            </Box>

            <Box gap="small">
              <PFText variant="h3">{t('LoanProcessingHeading')}</PFText>
              <PFText variant="p_lg">{t('LoanProcessingBody')}</PFText>
            </Box>

            <Box gap="small">
              <PFText variant="p_lg_semibold">{t('LoanProcessingListHeading')}</PFText>
              <PFText variant="p_lg">{t('LoanProcessingListItem1')}</PFText>
              <PFText variant="p_lg">{t('LoanProcessingListItem2')}</PFText>
              <PFText variant="p_lg">{t('LoanProcessingListItem3')}</PFText>
            </Box>
          </Box>
        </BasicTile>
      </Box>
    </Page>
  )
}

export default LoanProcessing
