import React, {useEffect} from 'react'
import Box from 'src/designSystem/components/atoms/Box/Box'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {useURLNavigation} from 'src/nav/useURLNavigation'

const Init: React.FC = () => {
  const navigation = useMainNavigator()

  const hasUrlNavigation = useURLNavigation()

  useEffect(() => {
    if (hasUrlNavigation) {
      return
    }

    navigation.reset({routes: [{name: 'DashboardLoader'}]})
  }, [hasUrlNavigation, navigation])

  return (
    <Box fill>
      <Loading type="loader0" size="large" />
    </Box>
  )
}

export default Init
