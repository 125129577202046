import React, {FC, forwardRef, useState, useRef, useCallback, useMemo} from 'react'
import {createPortal} from 'react-dom'
import {StyleSheet, View} from 'react-native'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import 'src/designSystem/components/molecules/PFDatePickerField/datepicker-overrides.css'
import {DateToStringFormat} from 'src/api/lib/APIUtils'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import AndroidWebDatePicker from 'src/products/general/components/atoms/AndroidWebDatePickerField/AndroidWebDatePicker'
import {datePickerBackground} from 'src/designSystem/semanticColors'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {isDeviceNotWeb, isDeviceiOSWeb} from 'src/lib/utils/platform'
import {getOpenToDate} from 'src/designSystem/components/molecules/PFDatePickerField/PFDatePickerField.utils'
import {PFDatePickerFieldProps} from 'src/designSystem/components/molecules/PFDatePickerField/PFDatePickerField.types'

const onSelect = (
  dateValue: moment.Moment | undefined,
  onChange: PFDatePickerFieldProps['onChange'],
): void => {
  if (onChange && dateValue) {
    //delay a bit for animation to complete, the animation closes
    //the keyboard causes problems on focus for next field
    setTimeout((): void => onChange(dateValue), 500)
  }
}

const PFDatePickerField: FC<PFDatePickerFieldProps> = (props) => {
  const dateInputRef = useRef<HTMLInputElement>(null)
  const {onChange, label, minimumDate, maximumDate} = props
  const [value, setValue] = useState<moment.Moment | undefined>(props.value)

  const updateValue = useCallback(
    (newDate) => {
      let momentDate: moment.Moment
      if (newDate instanceof Date) {
        momentDate = moment(newDate)
      } else {
        momentDate = moment(newDate.nativeEvent.target.value)
      }

      setValue(momentDate)
      onSelect(momentDate, onChange)
    },
    [onChange],
  )

  const mobilePicker = useMemo(() => {
    const onMobilePicker = () => {
      if (isDeviceiOSWeb()) {
        //for chrome and safari on iOS
        dateInputRef?.current?.focus()
      } else {
        const modalProps = {
          dateValue: getOpenToDate({value, minimumDate, maximumDate}),
          label: label,
          onConfirm: updateValue,
          minimumDate,
          maximumDate,
        }
        ShowLightbox(<AndroidWebDatePicker {...modalProps} />, false)
      }
    }

    const dateString = DateToStringFormat(value)

    return (
      <>
        <View>
          <View style={styles.mobilePickerView}>
            {isDeviceiOSWeb() ? (
              <input
                ref={dateInputRef}
                type="date"
                name={label}
                onChange={updateValue}
                style={styles.input}
              />
            ) : null}
          </View>
          <View style={styles.mobileBackground} />
          <PFTextInput
            label={label}
            errorOnEmpty={true}
            value={dateString}
            onPress={onMobilePicker}
          />
        </View>
      </>
    )
  }, [maximumDate, minimumDate, value, label]) // eslint-disable-line react-hooks/exhaustive-deps

  const desktopPicker = useMemo(() => {
    // https://reactdatepicker.com/#example-custom-input
    type CustomInputProps = {
      onClick?: () => void
    }
    const CustomInput: FC<CustomInputProps> = forwardRef(({onClick = () => null}, ref) => (
      <PFTextInput
        ref={ref}
        label={label}
        errorOnEmpty={true}
        value={DateToStringFormat(value)}
        onPress={(): void => onClick()}
      />
    ))
    CustomInput.displayName = 'CustomInput'

    return (
      <DatePicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={getOpenToDate({value, minimumDate, maximumDate})}
        onChange={updateValue}
        customInput={<CustomInput />}
        minDate={minimumDate}
        maxDate={maximumDate}
        popperContainer={({children}) => createPortal(children, document.body)}
      />
    )
  }, [maximumDate, minimumDate, value, label]) // eslint-disable-line react-hooks/exhaustive-deps

  return isDeviceNotWeb() ? mobilePicker : desktopPicker
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
  },
  mobileBackground: {
    backgroundColor: datePickerBackground,
    ...StyleSheet.absoluteFillObject,
  },
  mobilePickerView: {
    bottom: 5,
    left: 5,
    position: 'absolute',
  },
})

export default PFDatePickerField
