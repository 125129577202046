/* eslint-disable react/jsx-handler-names */
/* eslint-disable @typescript-eslint/naming-convention */
import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {Consumer, GetMeAction, getUserEnvSelector} from '@possible/cassandra'
import {useSelector} from 'react-redux'

import {ModuleControlProps, OnboardingParamList} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {
  CardApproved as CardApprovedMarketingEvent,
  TrackAppEvent,
} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {CardApprovedTemplate} from 'src/products/card/Activation/CardApproved/CardApprovedTemplate/CardApprovedTemplate'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

import {badConnectionSelector} from 'src/api/selectors/selectors'
import {ShowException, logErrorAndShowException} from 'src/lib/errors'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import Log from 'src/lib/loggingUtil'
import {getFilteredLinkedBankAccountsGql} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {
  launchAggregator,
  launchAlternateAggregator,
  onBankLinkingCompleteGql,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {getDefaultPaymentMethod} from 'src/products/card/PaymentMethods/selectors'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {AddBankLinkTemplateProps} from 'src/products/general/AddBankLink/AddBankLinkTemplate'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {CardApprovedMpoContainerDocument} from 'src/products/card/Activation/CardApproved/CardApprovedMPOContainer.gqls'
import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'

type CardApprovedMPOContainerProps = StackScreenProps<OnboardingParamList, 'CARD_APPROVED'> &
  ModuleControlProps

const CardApprovedMPOContainer = ({
  onStepComplete,
  navigation,
}: CardApprovedMPOContainerProps): JSX.Element => {
  const {t} = useTranslation('CardDashboard')
  const {
    data,
    error,
    loading: isLoading,
    refetch,
  } = Consumer.hooks.useCardActivationApprovedQuery({
    fetchPolicy: 'cache-first',
  })

  const [setPrimaryAccount] = useCassandraMutation(CardApprovedMpoContainerDocument)

  const linkPaymentMethod = useLinkPaymentMethod()
  const dispatch = usePfDispatch()
  const badConnection = usePfSelector(badConnectionSelector)
  const env = usePfSelector(getUserEnvSelector)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_approved_page_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  useFocusEffect(
    useCallback(() => {
      CardApprovedMarketingEvent()
    }, []),
  )
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod)
  const onboardingExperimentA3FeatureFlag = useIsFeatureFlagEnabled(
    'card-onboarding-experiment-1-mock-bureau-uw',
  )

  const onAddBank: AddBankLinkTemplateProps['onAddBank'] = (): void => {
    if (badConnection) {
      Log.error(
        new Error('Error while trying to add a bank on BankLinkProps'),
        'Error while trying to add a bank',
      )
      ShowException(t('NetworkRequestFailed'))
    } else {
      launchAggregator(
        env,
        navigation,
        null,
        (success: string, bank: string) => onDoneWithBankingAggregator(success, bank),
        (alternateAccountId, accountId) => {
          launchAlternateAggregator(
            alternateAccountId,
            accountId,
            navigation,
            (success: string, bank: string) => onDoneWithBankingAggregator(success, bank),
          )
        },
      )
    }
  }

  /**
   * Handle when the user is done with the banking aggregator.
   */
  const onDoneWithBankingAggregator = useCallback(
    async (status: string, bankName: string): Promise<void> => {
      try {
        const linkedAccounts = await getFilteredLinkedBankAccountsGql()
        if (onBankLinkingCompleteGql(status, linkedAccounts, bankName)) {
          await dispatch(GetMeAction())
          await refetch()
          const accountId = data?.me.bankAccounts?.all?.[0]?.id
          if (!accountId) {
            throw new Error('No account id found')
          }
          const setPrimaryResponse = await setPrimaryAccount({
            variables: {
              linkedAccountId: accountId,
              paymentMethod: LoanPaymentMethod.Ach,
            },
          })
          if (setPrimaryResponse.errors) {
            throw setPrimaryResponse.errors[0]
          }
          await linkPaymentMethod(accountId)
          Log.info('Successfully set bank account as primary')
          await onStepComplete?.()
        }
      } catch (e) {
        void logErrorAndShowException(
          e,
          'onDoneWithBankingAggregator bankAggregatorCallback failed to confirm bank account linking',
        )
      }
    },
    [
      dispatch,
      linkPaymentMethod,
      onStepComplete,
      setPrimaryAccount,
      data?.me.bankAccounts?.all,
      refetch,
    ],
  )
  const handleOnPress = (): void => {
    TrackAppEvent(AppEvents.Name.card_approved_page_cta, AppEvents.Category.Onboarding)
    if (onboardingExperimentA3FeatureFlag) {
      if (!defaultPaymentMethod) {
        onAddBank()
      } else {
        void onStepComplete?.()
      }
    } else {
      void onStepComplete?.()
    }
  }

  let creditLimit: string = ''
  let monthlyMembershipFees: string = ''

  if (
    data?.me.cardAccounts.active?.creditLimit &&
    data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  ) {
    creditLimit = data?.me.cardAccounts.active?.creditLimit
    monthlyMembershipFees = data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  }

  return (
    <BaseTemplate
      isError={!!error || !creditLimit || !monthlyMembershipFees}
      isLoading={isLoading}
      onRetry={refetch}
      errorTitle={t('ErrorTitle')}
    >
      <CardApprovedTemplate
        creditLimit={creditLimit}
        feeAmount={monthlyMembershipFees}
        onPress={handleOnPress}
      />
    </BaseTemplate>
  )
}

export {CardApprovedMPOContainer}
