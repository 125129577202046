import React from 'react'

import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrySubmitReturnType} from 'src/lib/loans/useLoanSubmission/useLoanSubmission'
import {logOfferApplicationErrorAndShowException} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {LoanApplicationSubmissionGQLContainer} from 'src/products/loans/LoanApplicationSubmission/LoanApplicationSubmissionGQLContainer'

type LoanInitialApplicationSubmissionMPOContainerProps = Required<
  Pick<ModuleControlProps, 'onModuleComplete' | 'moduleName'>
>

/**
 * Container to integrate the LoanInitialApplicationSubmission screen into an MPO flow
 * using onModuleComplete().
 */
const LoanInitialApplicationSubmissionMPOContainer: React.FC<
  LoanInitialApplicationSubmissionMPOContainerProps
> = (props: LoanInitialApplicationSubmissionMPOContainerProps) => {
  const {moduleName, onModuleComplete} = props

  useMPOAndroidToWeb(moduleName)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.completed_application_submission_screen_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleLoanApplicationSubmitted = async (loan: TrySubmitReturnType): Promise<void> => {
    if (loan) {
      try {
        TrackAppEvent(
          AppEvents.Name.completed_application_submitted,
          AppEvents.Category.InitialApplication,
        )
        await onModuleComplete()
      } catch (e) {
        void logOfferApplicationErrorAndShowException(
          e,
          'LoanSubmission - LoanInitialApplicationSubmission failed to submit initial loan application in MPO', // the "LoanSubmission" text is required for a DataDog monitor
        )
      }
    }
  }

  return (
    <LoanApplicationSubmissionGQLContainer
      onSubmitLoanApplication={handleLoanApplicationSubmitted}
    />
  )
}

export {LoanInitialApplicationSubmissionMPOContainer}
