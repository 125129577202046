import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import IDCameraPage from 'src/products/general/components/organisms/IDCameraPage/IDCameraPage'

const phases = ['FrontImageCapture', 'Complete'] as const
type Phases = (typeof phases)[number]

type Props = {
  onContinue: () => Promise<void>
  onFrontConfirm: () => Promise<void>
  busy: boolean
  phase: Phases | undefined
}

const IDScanTemplate: FC<Props> = ({onContinue, onFrontConfirm, busy, phase}) => {
  const {t} = useTranslation(['IDScan', 'Common'])

  switch (phase) {
    case 'FrontImageCapture':
      return <IDCameraPage onComplete={onFrontConfirm} />
    case 'Complete':
      return (
        <Page
          variant="generic"
          buttonProps={{
            type: 'singleButton',
            primary: {
              text: t('Common:Continue'),
              onPress: () => onContinue(),
              loading: busy,
              disabled: busy,
              testID: 'continueTestId',
            },
          }}
          title={t('IDCaptured')}
        />
      )
    default:
      return null
  }
}

export {IDScanTemplate, phases}
export type {Phases}
