import {
  CardAccountStatusCode,
  CardAccountSubStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {Dashboard} from 'src/navigation/LoanApprovedFlow'
import {useCardsType} from 'src/products/card/hooks/useCards'

export type CardAccountType = useCardsType['cardAccountPolled']

/**
 * Given a card account status, determine where this user should be redirected.
 * Eventually this will be replaced by the Product Hub.
 * For now, when the user loads the app, it's up to the `DashboardLoader` to decide which screen to display until this is updated to handle Workflows.
 */

export const getRouteForCardStatus = (
  cardAccount: CardAccountType | undefined,
  isLoanStateUser: boolean,
): keyof MainStackParamList => {
  const cardAccountStatus = cardAccount?.status
  const isActiveCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Active
  const isRejectedCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Rejected
  const isExpiredCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Expired
  const isDeactivatedCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Deactivated
  const isDeactivatedWithOutstandingBalance =
    cardAccountStatus?.subStatus === CardAccountSubStatus.DeactivatedWithBalance

  if (isRejectedCardAccount) {
    // Rejected loan state cards users see the loan screen
    // Rejected non-loan state cards users see the rejection screen
    if (isLoanStateUser) {
      return 'Dashboard'
    } else {
      return 'CardApplicationRejected'
    }
  } else if (isExpiredCardAccount) {
    // Expired loan state cards users see the loan screen
    // Expired non-loan state cards users see the Expiration screen
    if (isLoanStateUser) {
      return 'Dashboard'
    } else {
      return 'CardApplicationExpired'
    }
  } else if (isDeactivatedCardAccount) {
    // If deactivated user has outstanding balance, redirect to card dash
    if (isDeactivatedWithOutstandingBalance) {
      return 'CardDashboard'
    } else {
      return 'Dashboard'
    }
  } else if (isActiveCardAccount) {
    // Active cards users see the card dash
    return 'CardDashboard'
  } else {
    // All other users see loan dash
    return Dashboard
  }
}
