import React, {useState} from 'react'
import {StyleSheet, View} from 'react-native'
import {useSelector} from 'react-redux'
import {StackNavigationProp} from '@react-navigation/stack'
import {Trans, useTranslation} from 'react-i18next'

import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import {Consumer} from '@possible/cassandra'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import NonACH from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/NonAch'
import {
  disbursementMethodSelectedSelector,
  latestLoanSelector,
  latestLoanTypeSelector,
} from 'src/lib/loans/selector'
import {regionCodes, transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import {preferredAccount} from 'src/lib/user/accountsSelector'
import {
  RegionSpecificProps,
  PaymentMethodType,
  RadioOptionIdType,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {RadioButtonList} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import {ILoanRedux} from 'src/lib/loans/reducers/types'
import {
  canContinue,
  NoLoanSpinner,
  onACHAgreementLink,
  updateDisbursementMethod,
  updatePaymentMethod,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.utils'
import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch} from 'src/store/utils'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {PushPage} from 'src/navigation/NavHelper'
import {DidNotAcceptAgreementModal} from 'src/products/MCU/AccountManagementV2/Modals/DidNotAcceptAgreementModal'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'

// When possible this component should be updated to use Page and BaseTemplate to match AcceptACH
type NavigationType =
  | StackNavigationProp<MainStackParamList, 'AcceptAchAndAgreements'>
  | StackNavigationProp<MainStackParamList, 'AcceptAgreements'>

type Props = RegionSpecificProps & {
  onAccept: () => void
  navigation: NavigationType
  isDebitCardAgreement?: boolean
}

const onLAG = async (loan: ILoanRedux | null, navigation: NavigationType): Promise<void> => {
  if (!loan?.id) return

  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_loan_agreement_selected,
    AppEvents.Category.Checkout,
  )

  let response: Consumer.types.LoanGetDocumentMutation | null | undefined
  try {
    response = await Consumer.methods.getLoanDocument(loan.id, LoanDocumentType.LoanAgreement)
  } catch (e) {
    throw new Error('Failed to get document')
  }

  if (!response?.loanGetDocument?.url) {
    throw new Error('Failed to get document')
  }
  displayPdf(response.loanGetDocument.url, navigation)
}

const AcceptAchAndAgreements: React.FC<Props> = (props) => {
  const {navigation, renderRadioText, isDebitCardAgreement} = props
  const dispatch = usePfDispatch()

  const {t} = useTranslation(['LoanApproved', 'Common'])
  const account = useSelector((state: PfReduxState) => preferredAccount(state))
  const loan = useSelector((state: PfReduxState) => latestLoanSelector(state))
  const loanType = useSelector((state: PfReduxState) => latestLoanTypeSelector(state))
  const disbursementMethod =
    useSelector((state: PfReduxState) => disbursementMethodSelectedSelector(state)) ??
    transferMethods.ach
  const [transferMethod, setTransferMethod] = useState<transferMethodsType | undefined>(undefined)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>('UNSET')
  const [acceptChecked, setAcceptChecked] = useState<boolean>(false)
  const [selectedAutoPayOption, setSelectedAutoPayOption] = useState<string>('')
  const [showDidNotAcceptAgreementModal, setShowDidNotAcceptAgreementModal] =
    useState<boolean>(false)

  const mask = account?.account?.mask

  const defaultDescription =
    loanType?.regionCode === regionCodes.Washington ? (
      <PFText variant="p">
        {t('LoanCommonText1')} <PFText variant="p_semibold">{t('LoanCommonText2')}</PFText>
        {t('WASpecificText')}
      </PFText>
    ) : (
      <PFText variant="p">
        {t('LoanCommonText1')} <PFText variant="p_semibold">{t('LoanCommonText2')}</PFText>
      </PFText>
    )

  const {description = defaultDescription} = props

  const handleNoAutoConfirmed = (): void => {
    TrackAppEvent(
      AppEvents.Name.accept_agreements_not_accept_ach_selected,
      AppEvents.Category.Checkout,
    )
    setTransferMethod(transferMethods.check)
    setPaymentMethod('NOAUTO')
    setSelectedAutoPayOption(noAutoPayId)
  }

  const onNoAutoPay = (): void => {
    setSelectedAutoPayOption('')
    const NonACHComponentDef = props.nonAchDialog || NonACH
    ShowLightbox(() => (
      <NonACHComponentDef onNonAch={handleNoAutoConfirmed} navigation={navigation} />
    ))
  }

  const onDidNotAcceptAgreements = (): void => {
    setShowDidNotAcceptAgreementModal(true)
  }

  const didNotAcceptAgreementAction = (): void => {
    setShowDidNotAcceptAgreementModal(false)
    PushPage(navigation, 'AccountManagementV2')
  }

  const onAcceptLoanAgreement = (checked: boolean): void => {
    if (checked) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_loan_agreement_selected,
        AppEvents.Category.Checkout,
      )
    }
    setAcceptChecked(checked)
  }

  const handleACHAgreementPressed = async () => {
    try {
      await onACHAgreementLink(navigation, loan, t)
    } catch (e) {
      Log.error(e)
      ShowException(e)
    }
  }

  const onAccept = (): void => {
    if (transferMethod === transferMethods.check) {
      void dispatch(updateDisbursementMethod(transferMethod, dispatch))
    }
    void dispatch(updatePaymentMethod(transferMethod, dispatch))
    props.onAccept()
  }

  const radioAcceptAch = (
    <PFText>
      <Trans values={{accountMask: mask}} i18nKey={'AcceptLoanRadio'} t={t}>
        I authorize Possible Finance to debit the account ending in {mask} on the dates listed on my
        Payment Schedule. This{' '}
        <SvgLink
          onPress={handleACHAgreementPressed}
          linkText={'Electronic Payment Authorization'}
          linkType={'inline'}
        />{' '}
        is valid and will remain effective unless I cancel this authorization at least 3 business
        days in advance.
      </Trans>
    </PFText>
  )

  const noAccept = <PFText>{t('IDoNotAccept')}</PFText>

  const acceptAchId: RadioOptionIdType = 'accept-ach-radio-button'
  const noAutoPayId: RadioOptionIdType = 'no-auto-pay-radio-button'

  const options = [
    {
      id: acceptAchId,
      testID: 'AcceptAchAgreement_acceptAchId',
      text: renderRadioText ? renderRadioText(handleACHAgreementPressed) : radioAcceptAch,
    },
    {
      id: noAutoPayId,
      testID: 'AcceptAchAgreement_noAutoPayId',
      text: noAccept,
    },
  ]

  const handleRadioButtonPress = (option): void => {
    setSelectedAutoPayOption(option)
    if (option === acceptAchId) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_ach_selected,
        AppEvents.Category.Checkout,
      )
      setTransferMethod(disbursementMethod)
      setPaymentMethod('AUTO')
    } else if (option === noAutoPayId && selectedAutoPayOption !== noAutoPayId) {
      isDebitCardAgreement ? onDidNotAcceptAgreements() : onNoAutoPay()
    }
  }

  const acceptText = (
    <PFText variant={'p'}>
      <Trans i18nKey={'AcceptLoanAgreementText'} t={t}>
        I accept the
        <SvgLink
          onPress={(): Promise<void> => onLAG(loan, navigation)}
          linkText={'Loan Agreement'}
          linkType={'inline'}
        />
      </Trans>
    </PFText>
  )

  const primary = {
    text: t('Common:AgreeAndContinue'),
    testID: 'AcceptAgreement_button',
    onPress: onAccept,
    disabled:
      !selectedAutoPayOption ||
      !canContinue(isDebitCardAgreement ? true : acceptChecked, paymentMethod, transferMethod),
  }

  const checkBox = {
    text: acceptText,
    onCheck: onAcceptLoanAgreement,
    checked: acceptChecked,
    testID: 'AcceptLoanAgreement-checkbox',
  }

  const lockupProps: ButtonLockupProps = isDebitCardAgreement
    ? {
        type: 'singleButton',
        primary: primary,
      }
    : {
        type: 'interstitial',
        primary: primary,
        checkBox: checkBox,
      }

  return (
    <>
      {!loan || !loanType ? (
        <NoLoanSpinner title={t('AcceptAgreements')} />
      ) : (
        <GenericNonModalTemplate
          actionBlock={lockupProps}
          title={t('AcceptAgreements')}
          description={description}
        >
          <View style={styles.view}>
            <Box gap={'large'} marginTop={'small'}>
              <RadioButtonList
                options={options}
                onPress={(option): void => handleRadioButtonPress(option)}
                selectedOption={selectedAutoPayOption}
              />
            </Box>
          </View>
        </GenericNonModalTemplate>
      )}
      {showDidNotAcceptAgreementModal ? (
        <DidNotAcceptAgreementModal
          visible={showDidNotAcceptAgreementModal}
          onCTA={didNotAcceptAgreementAction}
        />
      ) : null}
    </>
  )
}

export default AcceptAchAndAgreements

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
})
