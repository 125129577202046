import React, {useState} from 'react'

import {
  ConfirmBankAccountTemplate,
  ConfirmBankAccountTemplateProps,
  ConfirmBankAccountTitle,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountTemplate'
import {getFormattedAccountType} from 'src/lib/user/utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {BankRoutingAccountFormData} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountForm.types'
import {AccountAndRoutingProvided} from 'src/lib/loans/actions'
import {usePfDispatch} from 'src/store/utils'
import {AccountAndRoutingProvidedValue} from 'src/lib/loans/reducers/types'
import {useBankRoutingAndAccountFormSubmission} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountFormPageContainer.utils'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  ConfirmBankAccountDocument,
  ConfirmBankAccountQuery,
  LoanSetDisbursementMethodConfirmBankAccountDocument,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccount.gqls'
import {
  AddPaymentMethodResultType,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {logOfferActivationError} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {usePromise} from 'src/lib/usePromise/usePromise'

export type ConfirmBankAccountGQLContainerProps = Pick<ConfirmBankAccountTemplateProps, 'onSubmit'>

export const ConfirmBankAccountGQLContainer: React.FC<ConfirmBankAccountGQLContainerProps> = (
  props,
) => {
  const {onSubmit} = props
  const dispatch = usePfDispatch()

  const queryResult = useCassandraQuery(ConfirmBankAccountDocument)

  const preferredAccount:
    | NonNullable<ConfirmBankAccountQuery['me']['bankAccounts']['all']>[number]
    | undefined = queryResult.data?.me.bankAccounts.all?.find(
    (account) => account.preferredFundingSource,
  )

  const [loanSetDisbursementMethod] = useCassandraMutation(
    LoanSetDisbursementMethodConfirmBankAccountDocument,
  )

  const {handleSubmitRoutingAndAccountForm} = useBankRoutingAndAccountFormSubmission()

  const [errorReason, setErrorReason] = useState<PaymentMethodsAlertModalErrorReason | undefined>()

  // If after pressing the back button the user has already set the disbursement method, we don't need to do anything
  const [wasAlreadyConfirmed, setWasAlreadyConfirmed] = useState<boolean>(false)

  const handleOnDismissErrorModal = (): void => {
    setErrorReason(undefined)
  }

  const [handleOnSubmit, {isLoading: isOnSubmitExecuting}] = usePromise(
    async (data: BankRoutingAccountFormData): Promise<void> => {
      if (wasAlreadyConfirmed) {
        // if the user has already set the disbursement method, we don't need to do anything
        await onSubmit(data)
        return
      }

      try {
        if (!preferredAccount?.id) {
          throw new Error('No linked account ID found')
        }

        const {errorReason, achPaymentMethod} = await handleSubmitRoutingAndAccountForm({
          formData: {
            accountNumber: data.accountNumber,
            routingNumber: data.routingNumber,
          },
          linkedAccountIdToAssociateWith: preferredAccount.id,
        })

        // It's fine if it already exists. This can happen if this is a re-submit of the form
        // but loanSetDisbursementMethod failed after the first submission.
        // Or if the user presses the back button and re-submits the form.
        if (errorReason && errorReason !== AddPaymentMethodResultType.AlreadyExists) {
          setErrorReason(errorReason)
          return
        }

        if (!achPaymentMethod?.bankingPaymentInstrumentId) {
          setErrorReason('GENERIC_ERROR')
          throw new Error('bankingPaymentInstrumentId not found')
        }

        if (!queryResult.data?.me.loans.latestActionableLoan?.id) {
          setErrorReason('GENERIC_ERROR')
          throw new Error('latestActionableLoan ID not found')
        }

        const setDisbursementResponse = await loanSetDisbursementMethod({
          variables: {
            input: {
              loanId: queryResult.data.me.loans.latestActionableLoan.id,
              disbursementMethod: LoanPaymentMethod.Ach,
              paymentInstrumentId: achPaymentMethod.bankingPaymentInstrumentId,
            },
          },
        })

        if (setDisbursementResponse.errors) {
          setErrorReason('GENERIC_ERROR')
          throw setDisbursementResponse.errors[0]
        }

        const value: AccountAndRoutingProvidedValue = {
          account_number: data.accountNumber,
          routing_number: data.routingNumber,
        }
        dispatch(AccountAndRoutingProvided(value))

        setWasAlreadyConfirmed(true)
        await onSubmit(data)
      } catch (e) {
        setErrorReason('GENERIC_ERROR')

        logOfferActivationError(e, `ConfirmBankAccountGQLContainer handleOnSubmit() failed.`)
      }
    },
  )

  const institutionName = preferredAccount?.institution?.name ?? 'BANK NAME'
  const accountType = getFormattedAccountType(preferredAccount?.type) ?? 'ACCOUNT TYPE'
  const displayName = `${institutionName} ${accountType}`
  const preferredAccountMask = preferredAccount?.mask ?? 'XXXX'

  return (
    <BaseTemplate
      testID="ConfirmBankAccountGQLContainer"
      /* TODO: ENG-19749 - Remove `!preferredAccount &&` to enable a error state when the query
       * fails. There is a test already written that can be commented out and used afterwards. */
      isLoading={!preferredAccount && queryResult.loading}
      isError={!!queryResult.error}
      pageTitle={<ConfirmBankAccountTitle accountMask={preferredAccountMask} />}
    >
      <ConfirmBankAccountTemplate
        onSubmit={async (data: BankRoutingAccountFormData): Promise<void> => {
          await handleOnSubmit(data)
        }}
        accountDisplayName={displayName}
        accountMask={preferredAccountMask}
        isSubmitting={isOnSubmitExecuting}
        onDismissErrorModal={handleOnDismissErrorModal}
        errorModalReason={errorReason}
        isEditable={!wasAlreadyConfirmed}
      />
    </BaseTemplate>
  )
}
