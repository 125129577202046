import React from 'react'
import {Keyboard} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'
import {LoginTemplate} from 'src/products/MCU/RegistrationOrLogin/Login/LoginTemplate'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {useUserLoginMutation} from 'src/products/MCU/RegistrationOrLogin/Login/useUserLoginMutation'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {FormProvider, useForm} from 'react-hook-form'

const Login: React.FC = (): JSX.Element => {
  const formContext = useForm<UserRegisterInput>({
    mode: 'all',
  })
  const {isSubmittingForm, isSocialBusy, handleSocialLogin, userLogin} =
    useUserLoginMutation(formContext)
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'Login'>>()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.login_screen_viewed,
    eventCategory: AppEvents.Category.Login,
  })

  const handleTapSignUp = (): void => {
    navigation.replace('Registration')
  }

  const handleLogin = (input: UserRegisterInput): void => {
    Keyboard.dismiss()
    void userLogin(input)
  }

  return (
    <FormProvider {...formContext}>
      <LoginTemplate
        onHandleLogin={handleLogin}
        onHandleTapSignUp={handleTapSignUp}
        onHandleSocialLogin={handleSocialLogin}
        isSocialBusy={isSocialBusy}
        isSubmittingForm={isSubmittingForm}
      />
    </FormProvider>
  )
}

export {Login}
