import {OnboardingModule} from '@possible/cassandra/src/types/types.mobile.generated'
import React, {useState} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {Consumer} from '@possible/cassandra'
import {ModuleControlProps} from 'src/flows/types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {ProductChoiceGQLContainer} from 'src/products/loans/ProductChoice/ProductChoiceGQLContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppNav from 'src/nav/AppNavActions'

type ProductChoiceProps = ModuleControlProps

export const ProductChoiceMPOContainer: React.FC<ProductChoiceProps> = (props) => {
  const {onModuleComplete, nextModulesAllowed} = props
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [cancelCardOnboarding] = Consumer.hooks.useCancelCardOnboarding()
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const onPressExitAndCancelCardApplication: () => Promise<void> = async () => {
    const {data, errors = []} = await cancelCardOnboarding()
    if (data?.onboardingCancelApplication?.onboardingFinished) {
      AppNav.replace(navigation, 'Dashboard')
    }
    if (errors?.length) {
      Log.error(errors, `Error cancelling card application`)
    }
  }

  const showLoans = !!(
    nextModulesAllowed?.includes(OnboardingModule.LoanSelected) ||
    nextModulesAllowed?.includes(OnboardingModule.LoanBankLink)
  )

  const onLoanPressed = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.apply_for_loan_selected, AppEvents.Category.ManageActiveLoan)
    await onModuleComplete?.(OnboardingModule.LoanSelected)
  }

  const onCardPressed = async (): Promise<void> => {
    try {
      if (isBusy) {
        return
      }
      setIsBusy(true)

      await onModuleComplete?.(OnboardingModule.CardLv3Ssn)
    } catch (e) {
      Log.error(e, 'Failed to apply for card on MPO')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <ProductChoiceGQLContainer
      showLoans={showLoans}
      handleOnCardPressed={onCardPressed}
      handleOnLoanPressed={onLoanPressed}
      isBusy={isBusy}
      handleOnPressExitAndCancelCardApplication={onPressExitAndCancelCardApplication}
    />
  )
}
