import {TFunction} from 'i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {Consumer} from '@possible/cassandra'
import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'

export const onACHAgreementLink = async (
  navigation: StackNavigationProp<MainStackParamList, 'AcceptACH'>,
  loanId: string | null,
  t: TFunction,
): Promise<void> => {
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_ach_agreement_selected,
    AppEvents.Category.Checkout,
  )

  if (!loanId) {
    throw new Error('Unable to load loan for documents, please try again later')
  }

  let document: Consumer.types.LoanGetDocumentMutation | null | undefined
  try {
    document = await Consumer.methods.getLoanDocument(loanId, LoanDocumentType.AchAgreement)
  } catch (e) {
    //
  }

  if (document?.loanGetDocument?.url) {
    displayPdf(document.loanGetDocument.url, navigation)
  } else {
    throw new Error('Unable to load documents, please try again later.')
  }
}
