import React from 'react'
import moment, {Moment} from 'moment-timezone'
import {TFunction} from 'i18next'

import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {isHoliday, isWeekEnd} from 'src/lib/time_util'
import {Transfer} from 'src/lib/loans/reducers/types'
import RescheduleAlertLightbox from 'src/products/loans/Reschedule/RescheduleAlertLightbox'

export enum FeasibleDateCodes {
  code_ok,
  code_ach_cutoff,
  code_holiday,
  code_weekend,
  code_15_to_29_days_past_orig_date, // warn but continue
  code_30plus_days_past_orig_date, // do not continue
  code_30plus_days_past_orig_date_continue, // warn and continue
}

/* If a user pays within 14 days of the original due date we consider it on time
   If they pay after 14 days but before 29 days of the original due date, it affects uw for the next loan
   If they pay past 29 days of the original due date we report the payment as late to credit bureaus
 */
const thresholdFromOriginalDate = 29
const gracePeriod = 15
const twoWeeksInDays = 14
const fourWeeksInDays = 28

export const isPaymentLate = (selectedDate: Moment, originalDate: Moment): boolean => {
  const durationFromOrigDate = moment.duration(selectedDate.diff(originalDate))
  const daysFromOriginalDate = Math.floor(durationFromOrigDate.asDays())
  return daysFromOriginalDate > thresholdFromOriginalDate
}

const isPaymentFailed = (payment: Transfer): boolean => {
  return payment && payment.status === 'failed'
}

const notFeasibleWarnAndContinue = (code: FeasibleDateCodes): boolean => {
  return (
    code === FeasibleDateCodes.code_15_to_29_days_past_orig_date ||
    code === FeasibleDateCodes.code_30plus_days_past_orig_date_continue
  )
}

export const isSelectedDayFeasible_MarkCalendarDateAsDisabled = (
  selectedDate: Moment,
  settlesOnHolidaysAndWeekends: boolean,
  earliestAvailableDate: Moment,
  originalDate: Moment,
  todayDate: Moment,
  payment: Transfer,
): boolean => {
  const feasibility = isSelectedDayFeasible(
    selectedDate,
    settlesOnHolidaysAndWeekends,
    earliestAvailableDate,
    originalDate,
    todayDate,
    payment,
  )

  return !(
    feasibility === FeasibleDateCodes.code_ok ||
    feasibility === FeasibleDateCodes.code_15_to_29_days_past_orig_date ||
    feasibility === FeasibleDateCodes.code_30plus_days_past_orig_date_continue
  )
}

export const isSelectedDayFeasible = (
  selectedDate: Moment,
  settlesOnHolidaysAndWeekends: boolean,
  earliestAvailableDate: Moment,
  originalDate: Moment,
  todayDate: Moment,
  payment: Transfer,
): FeasibleDateCodes => {
  selectedDate = selectedDate.set({hour: 17, minute: 0, second: 0, millisecond: 0})

  const durationFromOrigDate = moment.duration(selectedDate.diff(originalDate))
  const durationFromNow = moment.duration(selectedDate.diff(todayDate))

  const daysFromOriginalDate = Math.floor(durationFromOrigDate.asDays())
  const daysFromNow = Math.floor(durationFromNow.asDays())

  const isLoanCurrentlyLate = isPaymentLate(todayDate, originalDate)

  if (!settlesOnHolidaysAndWeekends && isWeekEnd(selectedDate)) {
    return FeasibleDateCodes.code_weekend
  }

  if (!settlesOnHolidaysAndWeekends && isHoliday(selectedDate)) {
    return FeasibleDateCodes.code_holiday
  }

  if (selectedDate.isBefore(earliestAvailableDate, 'day')) {
    return FeasibleDateCodes.code_ach_cutoff
  }

  if (daysFromOriginalDate >= gracePeriod && daysFromOriginalDate <= thresholdFromOriginalDate) {
    return FeasibleDateCodes.code_15_to_29_days_past_orig_date
  }

  if (daysFromOriginalDate > thresholdFromOriginalDate) {
    let allowUpToDays = twoWeeksInDays

    if (isLoanCurrentlyLate) {
      allowUpToDays = fourWeeksInDays
    }

    // If the payment is failed, allow user to reschedule within 2 weeks from current date
    // If the payment is late, allow user to reschedule within 4 weeks from current date
    if ((isPaymentFailed(payment) || isLoanCurrentlyLate) && daysFromNow <= allowUpToDays) {
      return FeasibleDateCodes.code_30plus_days_past_orig_date_continue
    } else {
      return FeasibleDateCodes.code_30plus_days_past_orig_date
    }
  }

  return FeasibleDateCodes.code_ok
}

const showDayNotFeasibleModal = (
  reasonCode: FeasibleDateCodes,
  selectedDate: Moment,
  todayDate: Moment,
  earliestAvailableDate: Moment,
  t: TFunction,
  onContinueCallback?: () => unknown,
): void => {
  let text: string
  let title: string
  let actionButton: string
  let secondaryActionText: string | undefined = undefined

  const todayIs = todayDate.format('M/D')
  const earliestAvailDate = earliestAvailableDate.format('dddd, M/D')

  switch (reasonCode) {
    case FeasibleDateCodes.code_ach_cutoff:
      title = t('DateUnavailable')
      text = t('OopsThisDateIsUnavailable', {todayIs, earliestAvailDate})
      actionButton = t('PickANewDate')
      break

    case FeasibleDateCodes.code_weekend:
      title = t('DateUnavailable')
      text = t('YouWillNeedToPickADifferentDayForYourPaymentBanksAreClosedOnTheWeekend')
      actionButton = t('PickANewDate')
      break

    case FeasibleDateCodes.code_holiday:
      title = t('ThatIsABankHoliday')
      text = t('YouWillNeedToPickADifferentDayForYourPayment') // TODO translate 'this day' to a friendly holiday name.
      actionButton = t('PickANewDate')
      break

    case FeasibleDateCodes.code_15_to_29_days_past_orig_date:
      title = t('HmmThatDateIsFarAway')
      text = t('ThisWillNotAffectYourCredit')
      actionButton = t('Common:Continue')
      secondaryActionText = t('Common:NeverMind')
      break

    case FeasibleDateCodes.code_30plus_days_past_orig_date:
      title = t('DateTooFarAway')
      text = t('UhOhThisDateIsOutsideOfThe29DayGraceWindow')
      actionButton = t('Common:GoBack')
      break

    case FeasibleDateCodes.code_30plus_days_past_orig_date_continue:
      title = t('CreditRiskWarning')
      text = t('ThanksForReschedulingYourPaymentIsMoreThan29Days')
      actionButton = t('Common:Continue')
      secondaryActionText = t('PickANewDate')
      break

    default:
      title = t('Whoops')
      text = t('ThisDateIsNotFeasible')
      actionButton = t('PickANewDate')
      break
  }

  const modal = (
    <RescheduleAlertLightbox
      text={text}
      title={title}
      buttonText={actionButton}
      secondaryButtonText={secondaryActionText}
      onContinue={(): void => {
        onContinueCallback?.()
      }}
      close={undefined}
    />
  )
  return ShowLightbox(() => modal, true)
}

export const checkAndSetDate = (
  code: FeasibleDateCodes,
  selectedDate: Moment,
  todayDate: Moment,
  earliestAvailableDate: Moment,
  onContinueCallback: () => void,
  t: TFunction,
): void => {
  if (code !== FeasibleDateCodes.code_ok) {
    if (!notFeasibleWarnAndContinue(code)) {
      // Disallowed ...
      return showDayNotFeasibleModal(code, selectedDate, todayDate, earliestAvailableDate, t)
    } else {
      // Warn and continue
      return showDayNotFeasibleModal(
        code,
        selectedDate,
        todayDate,
        earliestAvailableDate,
        t,
        onContinueCallback,
      )
    }
  }

  return onContinueCallback()
}
