import Log from 'src/lib/loggingUtil'

//TODO SPP-3203
export const init = () => {
  Log.log('RealtimeDatabase. init called on web')
}

export const unsubscribe = () => {
  Log.log('RealtimeDatabase. unsubscribe called on web')
}
