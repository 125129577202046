import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import NavPageState from 'src/navigation/NavPageState'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {HowPaymentsWorkGQLContainer} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkGQLContainer/HowPaymentsWorkGQLContainer'

type Props = Pick<StackScreenProps<MainStackParamList, 'HowPaymentsWork'>, 'navigation'>

export const HowPaymentsWorkNavPageStateContainer: FC<Props> = (props) => {
  const {navigation} = props
  const stepName = LoanApprovedFlow.HowPaymentsWork

  usePageViewedAnalytics({
    eventName: AppEvents.Name.how_payments_work_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  function handleOnContinue(): void {
    TrackAppEvent(AppEvents.Name.how_payments_work_completed, AppEvents.Category.Checkout, {
      navContainerType: 'NavPageState',
    })

    const nextPage = NavPageState.GetNextApprovalState(stepName)
    void NavPageState.GoToScreen(navigation, nextPage)
  }

  return <HowPaymentsWorkGQLContainer onContinue={handleOnContinue} />
}
