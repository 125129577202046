import {cloneDeep} from 'lodash'

import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import * as loanActions from 'src/lib/loans/actions'
import {LoanState} from 'src/lib/loans/reducers/types'
import {find_copy_update_by_id, update_map} from 'src/reducers/utils'

export const LoansStateDefault: LoanState = {
  user_selected_loan_amount: false,
  transfers: {},
  reapplying: false,
  loans: [],
  loan_terms_by_id: new Map(),
  loanHasError: false,
  loanErrorMessage: undefined,
  accountAndRoutingProvided: undefined,
  paymentMethodSelected: undefined,
  disbursementMethodSelected: undefined,
}

function update_transfers(state, action) {
  const all_transfers = cloneDeep(state.transfers)
  let transfers = all_transfers[action.data.loanId]
  if (!transfers) {
    transfers = []
  }
  for (const payment of action.data.payments) {
    transfers[payment.ordinal - 1] = payment
  }

  all_transfers[action.data.loanId] = transfers
  return {...state, transfers: all_transfers}
}

export default function reducer(state: LoanState = LoansStateDefault, action) {
  switch (action.type) {
    case loanActions.DISBURSEMENT_METHOD_SELECTED:
      return {...state, disbursementMethodSelected: action.value}
    case loanActions.PAYMENT_METHOD_SELECTED:
      return {...state, paymentMethodSelected: action.value}
    case loanActions.ACCOUNT_AND_ROUTING_PROVIDED:
      return {...state, accountAndRoutingProvided: action.value}
    case CLEAR_USER_ACTION:
      return LoansStateDefault
    case loanActions.LOANS_STATE_CHANGE_EVENT:
      return {...state, ...action.state}
    case loanActions.LOAN_TRANSFERS_UPDATE:
      return update_transfers(state, action)
    case loanActions.LOAN_NEW:
    case loanActions.LOAN_UPDATE:
      return {...state, loans: find_copy_update_by_id(state.loans, action.loan)}
    case loanActions.LOAN_TYPE_UPDATE:
      return {
        ...state,
        loan_terms_by_id: update_map(state.loan_terms_by_id, action.value.id, action.value),
      }
    case loanActions.LOAN_ERROR:
      return {
        ...state,
        loanHasError: action.loanHasError,
        loanErrorMessage: action.loanErrorMessage,
      }
    default:
      return state
  }
}
