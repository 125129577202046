import moment from 'moment'
import React, {useCallback} from 'react'
import {View} from 'react-native'
import {useSelector} from 'react-redux'

import {useTranslation} from 'react-i18next'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {mediumGap} from 'src/designSystem/layout'
import {getActivePhysicalCard, getAutopayEnabled} from 'src/lib/card/selectors'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {CardDashboardBalanceLedger} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardBalanceLedger/CardDashboardBalanceLedger'
import {
  getCardDashboardHeaderBalanceLabel,
  getCardDashboardHeading,
  isNSF,
  shouldShowVirtualCardButton,
} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCardsType} from 'src/products/card/hooks/useCards'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type CardDashboardHeaderFromUseCards = Pick<
  useCardsType,
  'loading' | 'userActivationDate' | 'ledgerSpent' | 'accountStatus' | 'activeCardStatus'
>
export type CardDashboardHeaderProps = CardDashboardHeaderFromUseCards & {
  onShowCardDetails: () => void
}

const CardDashboardHeader: React.FC<CardDashboardHeaderProps> = (props) => {
  const {
    onShowCardDetails,
    accountStatus,
    ledgerSpent,
    loading,
    userActivationDate,
    activeCardStatus,
  } = props
  const activePhysicalCard = useSelector(getActivePhysicalCard)
  const autopayEnabled = useSelector(getAutopayEnabled)
  const {t} = useTranslation(['CardDashboardHeader', 'Common'])
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )

  const isUserNSF = cardMinPayResponse?.status && isNSF(cardMinPayResponse?.status)
  /**
   * For reference to why/what externalPhaseThreeUser, please see:
   * https://possiblefinance.slack.com/archives/C01USDWNY3C/p1665677326934749
   * TL;DR: We want anyone that already has access to virtual card prior to
   * external phase 3 to maintain access to that feature
   */
  const externalPhaseThreeUser = moment(userActivationDate).isSameOrBefore('2022-10-31')

  const ShowVirtualCardButton = useCallback(
    () =>
      shouldShowVirtualCardButton(
        activePhysicalCard,
        autopayEnabled,
        externalPhaseThreeUser,
        activeCardStatus.code,
      ) ? (
        <Box marginTop="small" align="center" key={4}>
          <Button
            size={'medium'}
            testID="Virtual-Card-Button-Id"
            mode={'secondary'}
            width={310}
            onPress={onShowCardDetails}
          >
            {t('ShowVirtualCard')}
          </Button>
        </Box>
      ) : null,
    [
      activePhysicalCard,
      autopayEnabled,
      externalPhaseThreeUser,
      onShowCardDetails,
      activeCardStatus.code,
      t,
    ],
  )

  const getInnerContent = (): JSX.Element => {
    if (loading) {
      const boxStyle = {minHeight: 200}
      return (
        <Box align={'center'} gap={'little'} boxStyle={boxStyle}>
          <Spinner />
        </Box>
      )
    }
    return (
      <>
        <Box align={'center'} gap={'little'}>
          {getCardDashboardHeading(accountStatus, t, isUserNSF)}
          <Box direction={'row'} paddingTop={'little'}>
            <PFText testID="Ledger-Spent-Id" variant="d3">
              {convertToDollarAmt(ledgerSpent)}
            </PFText>
          </Box>
          <Box align={'center'} direction={'row'}>
            <PFText variant={'label_md'} color={'textDisabled'}>
              {getCardDashboardHeaderBalanceLabel(accountStatus, t)}
            </PFText>
          </Box>
        </Box>
        <CardDashboardBalanceLedger />
        <ShowVirtualCardButton />
      </>
    )
  }

  return (
    <View style={{marginHorizontal: mediumGap}}>
      <Box {...boxProps}>{getInnerContent()}</Box>
    </View>
  )
}

export default CardDashboardHeader

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  boxStyle: {overflow: 'hidden'},
  gap: 'none',
}
