import {Consumer} from '@possible/cassandra'
import {CohortUserTpe} from '@possible/cassandra/src/types/consumer'
import React from 'react'
import {useTranslation} from 'react-i18next'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

import {
  SSNCollectionDataInterface,
  SSNCollectionOnCompleteCallback,
} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollection.types'
import {SSNCollectionTemplate} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionTemplate'

type SSNCollectionGQLContainerProps = SSNCollectionDataInterface

const SSNCollectionGQLContainer: React.FC<SSNCollectionGQLContainerProps> = (props) => {
  const {
    onComplete,
    onMoreInfoPressed: handleOnMoreInfoPressed,
    onContactUs: handleOnContactUs,
    clearDefaultSsnOnInit,
    template: Template = SSNCollectionTemplate,
    isFromUra,
  } = props

  const {t} = useTranslation(['PersonalInformation'])

  const {
    selectedData,
    loading: isLoading,
    error,
  } = Consumer.hooks.useUserSSNQuery({
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      if (isFromUra) {
        Log.error(e, 'SSNCollectionGQLContainer user ssn query failed when arriving from URA')
      } else {
        logOfferApplicationError(e, 'SSNCollectionGQLContainer user ssn query failed')
      }
    },
  })
  const [modifySSN] = Consumer.hooks.useModifySSNMutation()

  const cohortUserType = useGetCohortUserType() // this is for cards to check which cohort the user is in

  const handleComplete: SSNCollectionOnCompleteCallback = async (
    inData,
    changed,
  ): Promise<void> => {
    // we only want to persist if the user has changed their SSN
    if (changed) {
      try {
        await modifySSN({variables: {ssn: {ssn: inData.ssn}}})
      } catch (e) {
        if (isFromUra) {
          void logErrorAndShowException(
            e,
            'SSNCollectionGQLContainer modify ssn mutation failed when arriving from URA',
          )
        } else {
          logOfferApplicationErrorAndShowException(
            e,
            'SSNCollectionGQLContainer modify ssn mutation failed',
          )
        }
      }
    }

    await onComplete(inData, changed)
  }

  if (isLoading) {
    return <Loading size="large" type="loader0" />
  }

  if (error) {
    return (
      <ErrorTemplate errorTitle={t('ErrorLoadingInformation')} onContactUs={handleOnContactUs} />
    )
  }

  const isEditable = cohortUserType !== CohortUserTpe.LoanGrad || isFromUra
  return (
    <Template
      initialData={clearDefaultSsnOnInit || !selectedData ? {} : selectedData}
      onComplete={handleComplete}
      onMoreInfoPressed={handleOnMoreInfoPressed}
      isEditable={isEditable}
    />
  )
}

export default SSNCollectionGQLContainer
