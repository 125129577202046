import {
  LinkedAccount,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {AddBankLinkedAccountSubset} from 'src/products/general/AddBankLink/AddBankLink.types'
import * as AddBankLinkUtils from 'src/products/general/AddBankLink/AddBankLink.utils'
import {
  AddBankLinkGQLContainer,
  AddBankLinkGQLContainerProps,
} from 'src/products/general/AddBankLink/AddBankLinkGQLContainer'
import {AddBankLinkMpoContainerMutationDocument} from 'src/products/general/AddBankLink/AddBankLinkMPOContainer.gqls'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

export type AddBankLinkMPOContainerProps = {
  navigation: AddBankLinkGQLContainerProps['navigation']
} & Required<Pick<ModuleControlProps, 'onModuleComplete' | 'onStepComplete'>>

/**
 * Container to integrate the AddBankLink screen into an MPO flow.
 */
const AddBankLinkMPOContainer: React.FC<AddBankLinkMPOContainerProps> = (
  props: AddBankLinkMPOContainerProps,
) => {
  const {navigation, onStepComplete, onModuleComplete} = props
  const {t} = useTranslation('AddBankLink')

  const [setBankAccountAsPrimary] = useCassandraMutation(AddBankLinkMpoContainerMutationDocument)

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Prequal,
    eventName: AppEvents.Name.prequal_bank_link_shown,
  })

  const handleOnCheckBankSupport: AddBankLinkGQLContainerProps['onCheckBankSupport'] = () => {
    AddBankLinkUtils.onCheckBankSupport({
      navigation,
    })
  }

  /**
   * Update this user's primary account if they selected one account, or if they selected
   * more than one we send them to the next MPO step to select a primary.
   */
  const updatePrimaryAccountForMPO = async <
    LinkedAccountSubset extends Pick<LinkedAccount, 'id'>,
  >(args: {
    bankName: string
    accounts: LinkedAccountSubset[] | undefined
  }): Promise<void> => {
    const {accounts} = args
    try {
      if (!accounts || accounts.length === 0) {
        throw new Error('No accounts found')
      }

      if (accounts.length > 1) {
        // if we have more than one account we need to go to the select primary account screen
        await onStepComplete()
      } else if (accounts.length === 1) {
        // if we only have one account we can set it as primary and go to the next module
        const accountId = accounts[0].id
        if (!accountId) {
          throw new Error('No account id found')
        }

        const response = await setBankAccountAsPrimary({
          variables: {
            linkedAccountId: accountId,
            paymentMethod: LoanPaymentMethod.Ach,
          },
        })
        if (response.errors) {
          throw response.errors[0]
        }
        Log.info('Successfully set bank account as primary')
        await onModuleComplete()
      }
    } catch (e) {
      logOfferApplicationError(e, 'Error while trying to set primary account for MPO')
      ShowException(
        t(
          'ErrorSettingSinglePrimaryAccount',
          'We were unable to set your primary account. Please try again later.',
        ),
      )
    }
  }

  /**
   * When user continues after adding a bank account we update their primary account
   * info and send them to the next MPO step/module accordingly.
   */
  const handleOnContinueAfterBankLinkComplete: AddBankLinkGQLContainerProps['onContinueAfterBankLinkComplete'] =
    async (args: {
      bankName: string
      accounts: AddBankLinkedAccountSubset[] | undefined
    }): Promise<void> => {
      const {bankName, accounts} = args
      await updatePrimaryAccountForMPO({
        bankName,
        accounts,
      })
    }

  return (
    <AddBankLinkGQLContainer
      navigation={navigation}
      onCheckBankSupport={handleOnCheckBankSupport}
      pageTitle={t('LinkYourBankAccountPrequal')}
      onContinueAfterBankLinkComplete={handleOnContinueAfterBankLinkComplete}
    />
  )
}

export default AddBankLinkMPOContainer
