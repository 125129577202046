import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {CardAccountOfferAvailabilityStatus} from 'src/cassandra'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {
  WaitListVariant,
  getWaitListVariant,
} from 'src/products/loans/StateSelector/UnsupportedStateWaitlist.utils'
import {CardOfferAvailabilityStatusQueryDocument} from 'src/products/loans/StateSelector/UseGetWaitlistVariant/CardOfferAvailabilityStatusQuery.gqls'

export const useGetWaitlistVariant = (): WaitListVariant => {
  const {canAccessCards: isCardsUser} = useCanAccessCards()
  const {selectedData: offerAvailabilityStatus} = useCassandraQuery(
    CardOfferAvailabilityStatusQueryDocument,
    {},
    (data) => data?.me?.cardAccounts?.offerInfo?.offerAvailabilityStatus,
  )

  const isUnsupportedCardState =
    offerAvailabilityStatus === CardAccountOfferAvailabilityStatus.IneligibleLocation

  return getWaitListVariant(isUnsupportedCardState, isCardsUser)
}
