import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AddBankLinkMpoContainerMutationMutationVariables = Types.Exact<{
  linkedAccountId: Types.Scalars['ID']['input']
  paymentMethod: Types.LoanPaymentMethod
}>

export type AddBankLinkMpoContainerMutationMutation = {
  __typename?: 'Mutation'
  loanSetBankAccountAsPrimary: {
    __typename?: 'SetBankAccountAsPrimaryResponse'
    primaryAccount?: {
      __typename?: 'LinkedAccount'
      id: string
      preferredFundingSource?: boolean | null
    } | null
  }
}

export const AddBankLinkMpoContainerMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AddBankLinkMPOContainerMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethod'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanPaymentMethod'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetBankAccountAsPrimary'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkedAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkedAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentMethod'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'paymentMethod'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'primaryAccount'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddBankLinkMpoContainerMutationMutation,
  AddBankLinkMpoContainerMutationMutationVariables
>
