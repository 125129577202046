import {
  getIsFeatureFlagEnabled,
  useIsFeatureFlagEnabled,
} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

export const useLoansActivationWorkflowEnabled = (): boolean => {
  return useIsFeatureFlagEnabled('loans-activation-workflow')
}

export const getLoansActivationWorkflowEnabled = (): boolean => {
  return getIsFeatureFlagEnabled('loans-activation-workflow')
}

export const useLoansRepeatLoanWorkflowEnabled = (): boolean => {
  return useIsFeatureFlagEnabled('repeat-loan-workflow')
}

export const getIsLoansRepeatLoanWorkflowEnabled = (): boolean => {
  return getIsFeatureFlagEnabled('repeat-loan-workflow')
}
