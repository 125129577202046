import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import PhoneConfirmationTemplate from 'src/products/loans/PhoneConfirmation/PhoneConfirmationTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowPhoneConfirmation} from 'src/navigation/LoanFlow'
import {PushPage} from 'src/navigation/NavHelper'
import NavPageState from 'src/navigation/NavPageState'

type Props = StackScreenProps<MainStackParamList, 'PhoneConfirmation'>

const PhoneConfirmationLegacyContainer: React.FC<Props> = (props) => {
  const {navigation, route} = props
  const {forgotPassword} = route?.params ?? {}

  const handleComplete = async (phoneNumber: string, phoneNumberFormatted: string) => {
    PushPage(navigation, 'PhoneVerification', {
      phoneNumber,
      phoneNumberFormatted,
      forgotPassword: forgotPassword ?? false,
    })
  }

  const handleAlreadyComplete = async () => {
    NavPageState.NavToNextPage(LoanFlowPhoneConfirmation, navigation)
  }

  return (
    <PhoneConfirmationTemplate
      {...props}
      onComplete={handleComplete}
      onAlreadyComplete={handleAlreadyComplete}
    />
  )
}

export default PhoneConfirmationLegacyContainer
