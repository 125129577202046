import React, {FC} from 'react'
import {useSelector} from 'react-redux'

import {useUser} from 'src/cassandra'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {getSelectedCardOffer, getSelectedCardOfferMonthlyFee} from 'src/lib/card/selectors'
import {
  CardProductIntroductionTemplateProps,
  CardProductIntroductionTemplate,
} from 'src/products/card/Application/CardProductIntroduction/CardProductIntroductionTemplate'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const CardProductIntroductionReduxContainer: FC<
  Pick<CardProductIntroductionTemplateProps, 'onComplete'>
> = (props) => {
  const {onComplete} = props
  useUser(false)
  const monthlyFee = useSelector(getSelectedCardOfferMonthlyFee)
  const selectedOffer = useSelector(getSelectedCardOffer)

  usePageViewedAnalytics({
    eventName: CardEvents.card_application_overview_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })

  return (
    <CardProductIntroductionTemplate
      onComplete={onComplete}
      monthlyFee={monthlyFee}
      balanceLimit={selectedOffer?.balanceLimit}
    />
  )
}
