import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import PhoneConfirmationTemplate from 'src/products/loans/PhoneConfirmation/PhoneConfirmationTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  WorkflowPreReqFulfillScreenProps,
  WorkflowsPreReqFullfilledFunction,
} from 'src/workflows/types'

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'PhoneConfirmation'> & {
    onShouldMoveToVerification: WorkflowsPreReqFullfilledFunction<'PhoneVerification'>
  }

/**
 * A container for the PhoneConfirmationTemplate with logic for workflow navigation.
 */
const PhoneConfirmationWorkflowContainer: React.FC<Props> = (props) => {
  const {route, onShouldMoveToVerification, onPreReqFulfilled} = props
  const {forgotPassword} = route.params ?? {}

  const handleComplete = useCallback(
    async (phoneNumber: string, phoneNumberFormatted: string) => {
      await onShouldMoveToVerification({
        phoneNumber,
        phoneNumberFormatted,
        forgotPassword,
      })
    },
    [forgotPassword, onShouldMoveToVerification],
  )

  const handleAlreadyComplete = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return (
    <PhoneConfirmationTemplate
      {...props}
      onComplete={handleComplete}
      onAlreadyComplete={handleAlreadyComplete}
    />
  )
}

export default PhoneConfirmationWorkflowContainer
