import React, {useCallback, useEffect, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import moment, {Moment} from 'moment-timezone'
import {cloneDeep} from 'lodash'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {loans} from '@possible/generated/proto'
import allSettled from 'promise.allsettled'
import {Consumer} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'
import {
  getNextAvailableSettlementDate,
  UpdateLastLoan,
  getLoan,
} from 'src/api/actions/loans/loanActions'
import {userTimeZoneId} from 'src/lib/loans/payments'
import {loanSelector, loanTypeForLoan} from 'src/lib/loans/selector'
import {PopPage, PushPage} from 'src/navigation/NavHelper'
import {LogException, ShowException} from 'src/lib/errors'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import * as userActions from 'src/api/actions/user/userActions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {regionCodes} from 'src/lib/loans/consts'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {Transfer} from 'src/lib/loans/reducers/types'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {userProfileSelector} from 'src/lib/user/selector'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {smallGap} from 'src/designSystem/layout'
import PayNowConfirmation from 'src/products/loans/Reschedule/PayNowConfirmation'
import PaymentItem from 'src/products/loans/Reschedule/PaymentItem'
import {useChaosModeRenderFailure} from '@possible/chaos'
import {ShowExceptionModal} from 'src/products/loans/Errors/ShowExceptionModal'

const dateChangesWillChangePaymentAmount = [regionCodes.Texas, regionCodes.Ohio]

type Props = StackScreenProps<MainStackParamList, 'SetSchedule'> & MainStackParamList['SetSchedule']

const SetSchedule: React.FC<Props> = (props) => {
  const {navigation, loanId} = props

  const {t} = useTranslation('Reschedule')

  const [busy, setBusy] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [paymentsChanged, setPaymentsChanged] = useState<{[x: string]: boolean}>({})
  const [nextAvailableSettlementDate, setNextAvailableSettlementDate] = useState<
    Consumer.types.NextAvailablePaymentDateQuery['getNextAvailablePaymentDate'] | undefined
  >(undefined)
  const [timeZoneId, setTimeZoneId] = useState<string | null | undefined>(undefined)

  const user = usePfSelector(userProfileSelector)
  const loan = usePfSelector((state) => loanSelector(state, {loanId: loanId}))
  const loanType = usePfSelector((state): loans.Loan.IType | undefined =>
    loanTypeForLoan(state, loan),
  )
  const transfers = usePfSelector<Transfer[]>((state) => state.lib.loans?.transfers[loanId] ?? [])

  const [setPaymentDates] = Consumer.hooks.useLoanSetPaymentDatesMutation()

  const dispatch = usePfDispatch()

  useChaosModeRenderFailure({
    componentName: 'SetSchedule',
    dispatch,
  })

  usePageViewedAnalytics({
    eventName: AppEvents.Name.reschedule_payments_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
  })

  useEffect(() => {
    refreshEnv().catch(LogException)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshEnv = useCallback(() => {
    const callIt = async (): Promise<void> => {
      if (!loanId || !user) {
        return
      }

      setBusy(true)

      try {
        const promise0 = getLoan(loanId)
        const promise1 = dispatch(userActions.UserStateRefresh())
        const promise2 = getNextAvailableSettlementDate(loanId, moment().utc().format(), false)
        const promise3 = dispatch(UpdateLastLoan())

        const [, response1, response2] = await allSettled([promise0, promise1, promise2, promise3])

        if (response1.status === 'rejected') {
          throw new Error(`Failed to load user data ${response1.reason as string}`)
        }
        if (response2.status === 'rejected') {
          throw new Error(
            `Failed to retrieve next available settlement date ${response2.reason as string}`,
          )
        }
        setNextAvailableSettlementDate(response2.value)
        setTimeZoneId(userTimeZoneId(user))
      } catch (e) {
        ShowException(e)
      } finally {
        setBusy(false)
      }
    }

    return callIt()
  }, [dispatch, loanId, user])

  const renderNextAvailableSettlmentDate = (): React.ReactNode => {
    if (!nextAvailableSettlementDate || !timeZoneId) {
      return null
    }

    const desiredSettlementDatetime = moment.tz(
      nextAvailableSettlementDate.adjustedSettlementDatetime,
      timeZoneId,
    )

    return (
      <PFText variant={'p'}>
        {t('SoonestDateAvailable', {date: desiredSettlementDatetime.format('dddd, MMMM Do')})}
      </PFText>
    )
  }

  const confirmSameDayPayments = (amount: number): Promise<boolean> => {
    return new Promise(function (resolve) {
      const onConfirm = (): void => {
        resolve(true)
      }
      const onCancel = (): void => {
        resolve(false)
      }
      const close = (): void => {
        resolve(false)
      }
      ShowLightbox(() => (
        <PayNowConfirmation
          onConfirm={onConfirm}
          onCancel={onCancel}
          close={close}
          amount={`$${amount.toFixed(2)}`}
        />
      ))
    })
  }

  const showRescheduleSaveError = (): void => {
    ShowExceptionModal(t('OopsWeAreUnableToSave'), t('HmmWeAreHavingTroubleSaving'), navigation)
  }

  const onDateChangeConfirm = async (payment: Transfer, newDate: Moment): Promise<boolean> => {
    setBusy(true)

    try {
      const now = moment().local()
      newDate = moment(newDate)
      const previousDate = moment(payment.rescheduledDate)

      //did the payment date actually change?
      if (previousDate.isSame(newDate, 'day')) {
        return false
      }

      //is the payment being set for today?
      if (newDate.isSame(now, 'day')) {
        const amountForPayment = payment.amount
        const agreed = await confirmSameDayPayments(amountForPayment)
        if (!agreed) {
          return false
        }
      }

      const updatedPayment = {
        paymentId: payment.id,
        date: newDate.format(),
        ordinal: payment.ordinal,
      }
      const changedPayment = [updatedPayment]
      const paymentDatesInput: Consumer.types.LoanSetPaymentDatesInput = {
        loanId,
        payments: changedPayment,
        payNow: false,
      }
      const response = await setPaymentDates({variables: {paymentDatesInput}})
      if (response.errors) {
        throw Error(response.errors[0]?.message)
      }

      setPaymentsChanged((prev) => ({...prev, [payment.id]: true}))
      setHasChanges(true)

      await refreshEnv()

      return true
    } catch (e) {
      Log.error(e, 'SetSchedule, onDateChangeConfirm: ')
      showRescheduleSaveError()
      return false
    } finally {
      setBusy(false)
    }
  }

  const onEdit = (payment: Transfer): void => {
    TrackAppEvent(
      AppEvents.Name.reschedule_payments_payment_selected,
      AppEvents.Category.ManageActiveLoan,
      {value: payment.ordinal},
    )

    PushPage(navigation, 'PaymentRescheduleCalendar', {
      loan_id: loanId,
      payment: cloneDeep(payment),
      onSetNewDateAsync: (newPayment: Transfer, newDate: Moment) =>
        onDateChangeConfirm(newPayment, newDate),
    })
  }

  const renderPaymentLines = (): React.ReactNode => {
    if (!transfers || busy) {
      return null
    }

    return transfers.map((payment) => {
      const has_change = paymentsChanged[payment.id]

      return (
        <PaymentItem
          key={payment.id}
          hasChange={has_change}
          payment={payment}
          onEdit={(editingPayment: Transfer): void => onEdit(editingPayment)}
          testID={`payment-item-${payment.ordinal}`}
        />
      )
    })
  }

  const onAllDone = (): void => {
    PopPage(navigation)
  }

  const action = {
    text: t('AllDone'),
    onPress: () => onAllDone(),
    disabled: busy || !hasChanges,
    testID: 'all-done-button',
  }

  let disclaimer: JSX.Element | null = null

  try {
    if (loanType?.regionCode && dateChangesWillChangePaymentAmount.includes(loanType.regionCode)) {
      disclaimer = (
        <View style={{paddingVertical: smallGap}}>
          <PFText variant={'p'}>{t('YouMightSeeYourFinalPaymentAmountChange')}</PFText>
        </View>
      )
    }
  } catch (e) {
    Log.log(e)
  }

  return (
    <GenericNonModalTemplate
      title={t('NeedMoreTime')}
      actionBlock={buttonLockupProperties(action)}
      description={t('WeKnowLifeHappens')}
    >
      <View style={styles.container}>
        {renderNextAvailableSettlmentDate()}
        {renderPaymentLines()}
        {disclaimer}
        {busy ? (
          <ActivityIndicator animating={busy} style={styles.activityIndicator} size="large" />
        ) : null}
      </View>
    </GenericNonModalTemplate>
  )
}

export default withForwardedNavigationParams<Props>()(SetSchedule)

const styles = StyleSheet.create({
  activityIndicator: {
    margin: 20,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
})
