import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  getAllPaymentItems,
  getAllTransactionItems,
  ItemType,
  sortTransactionItems,
} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/utils'
import {getAllPayments, getAllTransactions} from 'src/lib/card/selectors'

/**
 * Returns a sorted list of all transaction and payment items
 */
export const useAllTransactionItems = (): ItemType[] => {
  const allPayments = useSelector(getAllPayments)
  const allTransactions = useSelector(getAllTransactions)
  const {t} = useTranslation(['TransactionsAndPaymentsList', 'Common'])

  return useMemo(() => {
    const items = [
      ...getAllPaymentItems(allPayments, t),
      ...getAllTransactionItems(allTransactions, t),
    ]

    return sortTransactionItems(items)
  }, [allPayments, allTransactions, t])
}
