import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import NavPageState from 'src/navigation/NavPageState'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'

import {
  CollectDebitCardNumbersForLoanGQLContainer,
  CollectDebitCardNumbersForLoanGQLContainerProps,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanGQLContainer'

export type CollectDebitCardNumbersForLoanNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'CollectDebitCardNumbersForLoansProduct'>,
  'navigation' | 'route'
>

/**
 * Container for the CollectDebitCardNumbersForLoan screen to integrate it into
 * the NavPageState navigation system.
 */
const CollectDebitCardNumbersForLoanNavPageStateContainer: React.FC<
  CollectDebitCardNumbersForLoanNavPageStateContainerProps
> = (props) => {
  const {navigation, route} = props
  const {onCompleteFromURA} = route.params

  const handleOnDebitCardSaved = async (): Promise<void> => {
    TrackAppEvent(
      AppEvents.Name.disbursement_method_debit_link_success,
      AppEvents.Category.Checkout,
    )
    if (onCompleteFromURA) {
      // this user was sent here from a URA
      TrackAppEvent(AppEvents.Name.add_debit_card_confirmed, AppEvents.Category.Checkout)
      navigation.pop()
      void onCompleteFromURA()
    } else {
      // if not from a URA this user is accepting a loan that is NOT their first loan.
      // first loan acceptance happens in MPO/Workflows, after that it happens in NavPageState as of 4/2024
      const stepName = LoanApprovedFlow.CollectBankDebitCardNumbers
      const nextStep = NavPageState.GetNextApprovalState(stepName)
      await NavPageState.GoToScreen(navigation, nextStep)
    }
  }

  const handleOnDebitCardSaveFailed: CollectDebitCardNumbersForLoanGQLContainerProps['onDebitCardSaveFailed'] =
    (errorReason) => {
      TrackAppEvent(
        AppEvents.Name.disbursement_method_debit_link_fail,
        AppEvents.Category.Checkout,
        {error: errorReason},
      )
    }
  return (
    <CollectDebitCardNumbersForLoanGQLContainer
      onDebitCardSaved={handleOnDebitCardSaved}
      onDebitCardSaveFailed={handleOnDebitCardSaveFailed}
    />
  )
}

export {CollectDebitCardNumbersForLoanNavPageStateContainer}
