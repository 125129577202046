import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {smallGap} from 'src/designSystem/layout'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  AcceptAgreementsContinueResult,
  AgreementType,
  StateSpecificAgreement,
  StateSpecificAgreementResult,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'

export type AcceptLoanAndStateAgreementsBaseTemplateProps = {
  testID: string
  title?: string
  loanAmountBorrowed: string
  isLoading: boolean
  isError: boolean
  isSubmitting: boolean
  stateSpecificAgreements: StateSpecificAgreement[]
  loanAgreementTestID: string
  onOpenAgreementDocument: (stateSpecificAgreement: StateSpecificAgreement) => void | Promise<void>
  onAcceptAndContinue: (args: AcceptAgreementsContinueResult) => void | Promise<void>
}

/**
 * Base template for AcceptLoanAndStateAgreements screens that have users agree
 * to state-specific agreements (arbitration agreement, etc) as well as their loan agreement.
 */
const AcceptLoanAndStateAgreementsBaseTemplate: React.FC<
  AcceptLoanAndStateAgreementsBaseTemplateProps
> = (props) => {
  const {t} = useTranslation('LoanApproved')

  const {
    testID,
    isLoading,
    isError,
    isSubmitting,
    title = t('AcceptAgreements'),
    stateSpecificAgreements,
    loanAmountBorrowed,
    loanAgreementTestID,
    onOpenAgreementDocument,
    onAcceptAndContinue,
  } = props

  const allAgreements = [...stateSpecificAgreements]
  // add the Loan Agreement to the list of agreements
  allAgreements.push({
    title: t('LoanAgreement'),
    documentTypeToDownload: LoanDocumentType.LoanAgreement,
    agreementType: AgreementType.LoanAgreement,
    id: loanAgreementTestID,
  })

  const [allAgreementsResult, setAllAgreementsResult] = useState<StateSpecificAgreementResult[]>(
    // initial state has all state specific agreements not accepted
    allAgreements.map((agreement) => {
      return {
        ...agreement,
        didUserAccept: false,
      }
    }),
  )

  /**
   * Handle when user checks a state-specific agreement
   */
  const handleOnTapAgreementCheckbox = (
    selectedStateAgreement: StateSpecificAgreementResult,
    isChecked: boolean,
  ): void => {
    setAllAgreementsResult((prevState) => {
      let indexOfSelectedAgreement: number | undefined = undefined
      return prevState.map((currentAgreement, index): StateSpecificAgreementResult => {
        if (currentAgreement.id === selectedStateAgreement.id) {
          indexOfSelectedAgreement = index
          // update this agreement's accepted status
          return {
            ...currentAgreement,
            didUserAccept: isChecked,
          }
        } else if (indexOfSelectedAgreement !== undefined && index > indexOfSelectedAgreement) {
          // we require the user to check each agreement in order. if they uncheck an agreement we automatically
          // uncheck any agreement after it so that they must check them all in order again
          return {
            ...currentAgreement,
            didUserAccept: false,
          }
        }
        return currentAgreement
      })
    })
  }

  const areAllAgreementsAccepted = allAgreementsResult.every((agreement) => agreement.didUserAccept)

  const primaryAction: ButtonLockupPropsPrimary = {
    text: t('AgreementsAgreeAndContinue'),
    onPress: () => {
      if (areAllAgreementsAccepted) {
        const didAcceptLoanAgreement = !!allAgreementsResult.find(
          (agreement) => agreement.id === loanAgreementTestID,
        )?.didUserAccept
        // separate the state-specific agreements since the loan agreement is included
        // separately in the result
        const allStateSpecificAgreements = allAgreementsResult.filter(
          (agreement) => agreement.id !== loanAgreementTestID,
        )
        onAcceptAndContinue({
          didAcceptLoanAgreement,
          stateSpecificAgreements: allStateSpecificAgreements,
        })
      }
    },
    disabled: isLoading || isError || isSubmitting || !areAllAgreementsAccepted,
    loading: isSubmitting,
  }

  return (
    <BaseTemplate
      isLoading={isLoading}
      isError={isError}
      pageTitle={title}
      showTileBorder={false}
      testID={testID}
    >
      <Page
        title={title}
        titleTextAlignment="center"
        description={
          <PFText variant="p" textAlign="center">
            {/* we show the loan amount as an integer without decimals */}
            {t('LoanCommonText', {borrowedAmount: loanAmountBorrowed.split('.')[0]})}
          </PFText>
        }
        buttonProps={buttonLockupProperties(primaryAction)}
        variant={'generic'}
        smallTopGap
      >
        <Box justify="end" flex={1} grow={true}>
          <PFText variant={'h3'}>{t('AgreeToTerms')}:</PFText>
          {allAgreementsResult.map((currentAgreement, index) => {
            const isFirstAgreement = index === 0
            const isPreviousAgreementAccepted = allAgreementsResult[index - 1]?.didUserAccept
            return (
              <View key={`${currentAgreement.id}-${index}`} style={styles.checkboxPadding}>
                <PFCheckbox
                  disabled={!isPreviousAgreementAccepted && !isFirstAgreement}
                  onPress={(checked): void =>
                    handleOnTapAgreementCheckbox(currentAgreement, checked)
                  }
                  checked={currentAgreement.didUserAccept}
                  testID={currentAgreement.id}
                >
                  <SvgLink
                    linkType={'inline'}
                    onPress={(): void => {
                      void onOpenAgreementDocument(currentAgreement)
                    }}
                    linkText={currentAgreement.title}
                  />
                </PFCheckbox>
              </View>
            )
          })}
        </Box>
      </Page>
    </BaseTemplate>
  )
}

export {AcceptLoanAndStateAgreementsBaseTemplate}

const styles = StyleSheet.create({
  checkboxPadding: {
    paddingVertical: smallGap,
  },
})
