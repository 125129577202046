export const Landing = 'Dashboard'
export const CounterOffer = 'LoanCounterOffer'
export const LoanPaymentReview = 'LoanPaymentReview'
export const DisbursementMethodSelection = 'DisbursementMethodSelection'
export const ConfirmDebitCard = 'ConfirmDebitCard'
export const HowPaymentsWork = 'HowPaymentsWork'
export const LoanTilaDisclosure = 'LoanTilaDisclosure'
export const PaymentReviewTilaAndLoanAgreementCA = 'PaymentReviewTilaAndLoanAgreementCA'
export const AcceptAgreements = 'AcceptAgreements'
export const AcceptACH = 'AcceptACH'
export const ConfirmBankAccount = 'ConfirmBankAccount'
export const UpgradeToInstallment = 'UpgradeToInstallment'
export const ReasonSurvey = 'ReasonSurvey'
export const PhoneConfirmation = 'PhoneConfirmationApproval'
export const PhoneVerification = 'PhoneVerification'
export const CollectBankDebitCardNumbers = 'CollectDebitCardNumbersForLoansProduct'
export const LoanFinalAccept = 'LoanFinalAccept'
export const Dashboard = 'Dashboard'
export const StateDisclosure = 'StateDisclosure'
export const AcceptAgreementsWAInstallment = 'AcceptAgreementsWAInstallment'
export const LoanFinalAcceptWAExtendedInstallment = 'LoanFinalAcceptWAExtendedInstallment'
