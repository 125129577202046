import {Consumer, EnvironmentType, GetUserEnv} from '@possible/cassandra'
import APIClient, {APIClientOptions, ClientInfo} from '@possible/generated/APIClient'
import DeviceInfo from 'react-native-device-info'
import {Platform} from 'react-native'

import {apiUriDev, apiUriProd} from '@possible/generated/src/api/lib/ApiUris'

import {
  NETWORK_CONNECTION_FAILURE,
  NETWORK_CONNECTION_SUCCESS,
  STORE_USER_ID_TOKEN_ACTION,
  USER_LOGGED_IN_ACTION,
} from 'src/api/actions/actionsNames'
import {APIStateChange} from 'src/api/actions/index'
import APIClientLoan from 'src/api/lib/APIClientLoan'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {ApiReduxState, UserLoginStates} from 'src/api/reducers/types'
import {maintenanceOn, userIdSelector} from 'src/api/selectors/selectors'
import {initializeCassandra} from 'src/cassandra'
import codePush from 'src/products/general/CodePush'
import {API_CLIENT_LOGGING} from 'src/config'
import {enableMaintenanceScreenIfNecessary} from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress.util'
import {ApiVersion} from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress.types'
import {SetAmplitudeUserProperties, SetUserId} from 'src/lib/Analytics/analytics_compat'
import {readDevMode, readEnv} from 'src/lib/devMode'
import Firebase from 'src/lib/firebase'
import * as Keychain from 'src/lib/keychain'
import {LoansStateChange, LOAN_TYPE_UPDATE} from 'src/lib/loans/actions'
import Log from 'src/lib/loggingUtil'
import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {isJailBroken} from 'src/lib/jailBreak'
import {PfDispatch, PfGetState} from 'src/store/types'
import {setShouldEnrollInCards} from 'src/lib/card/actions'
import singular from 'src/lib/singular'
import {UpdateLoanTerms} from 'src/api/actions/loans/loanActions'

const APP_UI_VERSION_PROPERTY_NAME = 'app_ui_version'

export const DevModeAction = () => APIStateChange({dev_mode: true})
export const EnvOverrideAction = (envOverride: EnvironmentType) => APIStateChange({envOverride})

//// THUNKS

const trackAppUiVersion = async () => {
  const codePushMetadata = await codePush.getUpdateMetadata()

  const uiVersion = codePushMetadata?.label ?? 'undefined'
  SetAmplitudeUserProperties(`[Possible Finance] ${APP_UI_VERSION_PROPERTY_NAME}`, uiVersion)
}

const trackDeviceJailbroken = async (userId?: string) => {
  if (isJailBroken()) {
    Log.warn(`Device is jailbroken! userId: ${userId}`)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function UserLoggedIn(refreshLoginMethod?: boolean) {
  return async (dispatch: PfDispatch, getState: PfGetState): Promise<boolean> => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const userId = getState().api.user_id! //user id must be set by now
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const token = getState().api.user_token!

    SetUserId(userId)
    void trackAppUiVersion()

    if (!(await dispatch(UserLoanStateRefresh()))) {
      return false
    }

    await initializeCassandra(dispatch, token)

    await dispatch(GetUserEnv())

    await dispatch(UpdateLoanTerms())

    dispatch(APIStateChange({initialUserRefresh: true}))
    dispatch(UserLoggedInAction())
    return true
  }
}

export function UserLoanStateRefresh() {
  return async (dispatch, getState) => {
    const userId = userIdSelector(getState())
    const loansResp = await APIClientLoan.GetLoansByUserId(userId)
    if (!loansResp || loansResp.getErrorStr()) return false
    await dispatch(LoansStateChange({loans: loansResp.loans}))

    const loanRecords = loansResp?.loans
    let state = getState()
    for (const loan of loanRecords) {
      //Get the loan type record if we don't already have it
      if (loan?.typeId && !state.lib.loans.loan_terms_by_id.has(loan.typeId)) {
        const loanType = await APIClientLoan.GetLoanTypeById(loan.typeId)
        if (!loanType.hasError() && loanType.loanType) {
          await dispatch({type: LOAN_TYPE_UPDATE, value: loanType.loanType})
          state = getState()
        }
      }
    }

    return true
  }
}

export function GetURIsFromEnv(env: EnvironmentType) {
  switch (env) {
    case EnvironmentType.Local:
    case EnvironmentType.Dev:
      return apiUriDev
    default:
      //default to production
      return apiUriProd
  }
}

export async function GetURIs() {
  const env = await getEnvironment()
  return GetURIsFromEnv(env)
}

export async function GetClientInfo(): Promise<ClientInfo> {
  try {
    const info: ClientInfo = {}

    if (isDeviceWeb()) {
      info.clientType = 'web_app'
      return info
    }

    info.clientType = 'mobile_app'
    info.deviceOsName = Platform.OS
    info.deviceId = DeviceInfo.getUniqueIdSync() // BE uses appUniqueId for device id
    info.deviceCarrier = (await DeviceInfo.getCarrier()).substring(0, 40)
    info.deviceMfg = (await DeviceInfo.getManufacturer()).substring(0, 40)
    info.appUniqueId = info.deviceId
    return info
  } catch (e: any) {
    Log.log(`getClientInfo failed, e : ${e.toString()}`)
    throw e
  }
}

async function getAPIClientOptions(dispatch, getState): Promise<APIClientOptions> {
  try {
    const uris = await GetURIs()

    const handleLoggedIn = async (): Promise<boolean> => {
      const result = await dispatch(UserLoggedIn(true))

      if (result) {
        dispatch(
          APIStateChange({
            user_logged_state: UserLoginStates.logged_in,
          }),
        )
      }
      return result
    }

    const handleNotLoggedIn = () => {
      dispatch(
        APIStateChange({
          user_logged_state: UserLoginStates.not_logged_in,
          user_id: undefined,
          user_token: undefined,
        }),
      )
    }

    const handleLogout = async () => {
      dispatch(Logout(true))
    }

    const handleConnectionError = async () => {
      dispatch(ConnectionError())
    }

    const handleConnectionSuccess = async () => {
      dispatch(ConnectionSuccess())
    }

    const handleCheckIsMaintenance = async (response: Response, prefix: string) => {
      return enableMaintenanceScreenIfNecessary(response, prefix as ApiVersion)
    }
    const user_creds = await read_user_id()
    const userId = getState().api.user_id
    const userToken = getState().api.user_token
    const clientInfo = await GetClientInfo()

    const instanceId = Firebase.instanceId
    await dispatch(APIStateChange({instanceId}))

    const handleStateChange = async (newState: Partial<ApiReduxState>) => {
      await dispatch(APIStateChange(newState))
    }

    const devMode = await readDevMode()

    return {
      shouldLog: API_CLIENT_LOGGING,

      log: Log.log,
      warn: Log.warn,
      error: Log.error,

      username: user_creds && user_creds?.username !== '_pfo' ? user_creds.username : undefined,
      password: user_creds && user_creds?.password !== '1' ? user_creds.password : undefined,
      userId,
      userToken,

      devMode,

      instanceId,

      ...uris,

      badConnection: getState()?.api.badConnection,
      maintainenceOn: maintenanceOn(getState()),

      onLoggedIn: handleLoggedIn,
      onNotLoggedIn: handleNotLoggedIn,
      onLogout: handleLogout,

      onConnectionError: handleConnectionError,
      onConnectionSuccess: handleConnectionSuccess,
      checkIsMaintenance: handleCheckIsMaintenance,
      onStateChange: handleStateChange,

      platform: Platform.OS,

      clientInfo,
    }
  } catch (e) {
    Log.error(e, 'getAPIClientOptions: ')
    throw e
  }
}

export function InitClient() {
  return async (dispatch, getState) => {
    try {
      const userCreds = await read_user_id()
      const userId = userCreds ? userCreds.username : undefined
      Firebase.init()

      const apiClientOptions = await getAPIClientOptions(dispatch, getState)
      const envOverride = await readEnv()
      initializeCassandra(dispatch, userCreds ? userCreds.password : undefined)

      APIClientLoan.init(dispatch, getState)
      // APIClient.init depends on PfOpenApi being initialized
      const response = await APIClient.init(apiClientOptions)
      if (response === false) {
        throw Error('DoNotLog')
      }

      if (userId && userCreds && userCreds.password !== '1') {
        await Firebase.registerNotifications()
        if (Firebase.fcmToken) {
          singular.setPushToken(Firebase.fcmToken)
        }
      }
      dispatch(APIStateChange({init: true, options: apiClientOptions, envOverride}))

      trackDeviceJailbroken(userId)
    } catch (e) {
      if (e instanceof Error && e.message !== 'DoNotLog') {
        Log.error(e, 'Unable to complete InitClient')
      }
      dispatch(Logout())
      dispatch(APIStateChange({init: true}))
    }
  }
}

export function ConnectionError() {
  return async (dispatch, getState) => {
    const state = getState()
    if (!state.api.badConnection) {
      await dispatch({type: NETWORK_CONNECTION_FAILURE})
    }
  }
}

export function ConnectionSuccess() {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.api.badConnection) {
      await dispatch({type: NETWORK_CONNECTION_SUCCESS})
    }
  }
}

function StoreUserIdTokenAction(userId: string, token: string) {
  return async (dispatch) => {
    dispatch({type: STORE_USER_ID_TOKEN_ACTION, user_id: userId, token})
  }
}

function UserLoggedInAction() {
  return {type: USER_LOGGED_IN_ACTION}
}

const handlePostLoginDeeplinks = async (userId: string, dispatch: PfDispatch) => {
  const deeplink = getDeeplink('offer')
  if (deeplink) {
    const {params} = deeplink

    try {
      await Consumer.methods.partnerLinkUserWithOffer(params.offer_id)
      await clearDeeplink(deeplink)
    } catch (e) {
      Log.error(e, `Error linking partner offer. offer_id: "${params.offer_id}"`)
    }
  }

  const cardDeeplink = getDeeplink('card_landing')
  if (cardDeeplink) {
    try {
      dispatch(setShouldEnrollInCards())
      await clearDeeplink(cardDeeplink)
    } catch (e) {
      Log.error(e, 'Error adding user to card group.')
    }
  }
}

export async function initUserAuth(userId: string, accessToken: string, dispatch: PfDispatch) {
  await save_user_id(userId, accessToken)

  APIClient.updateOptions({
    userId,
    userToken: accessToken,
  })
  Firebase.init()

  await handlePostLoginDeeplinks(userId, dispatch)

  await dispatch(StoreUserIdTokenAction(userId, accessToken))
  await Firebase.registerNotifications()
  if (Firebase.fcmToken) {
    singular.setPushToken(Firebase.fcmToken)
  }

  await dispatch(UserLoggedIn(false))
}

export async function save_user_id(username, password) {
  return Keychain.setServicePassword(username, password)
}

export async function read_user_id() {
  //This method supports transitioning from storing our service password in the generic key store, to the specific service key store
  //The Firebase SDK was overwriting the generic key store. https://github.com/oblador/react-native-keychain/issues/363
  try {
    const servicePassword = await Keychain.getServicePassword()
    if (!servicePassword) {
      //check generic key store and attempt a transition
      const user_creds = await Keychain.getGenericPassword()
      if (user_creds && user_creds?.username === '_pfo') {
        //Firebase is using the apps keychain on iOS, this is conflicting with our usage of it.
        Log.info(`firebase has overriden generic keychain`)
        return false
      }

      //update access token
      if (user_creds) {
        await Keychain.setServicePassword(user_creds.username, user_creds.password)
        return {
          username: user_creds.username,
          password: user_creds.password,
        }
      }
    }
    return servicePassword
  } catch (e) {
    return false
  }
}
