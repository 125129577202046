import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'
import {StackNavigationProp} from '@react-navigation/stack'
import {withTranslation, WithTranslation} from 'react-i18next'

import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import AppEvents from 'src/lib/Analytics/app_events'
import {latestLoanSelector, latestLoanTypeSelector} from 'src/lib/loans/selector'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {
  agreeAndContinueText,
  LOAN_AGREEMENT,
  pageTitle,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements.utils'
import {AgreementType} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {smallGap} from 'src/designSystem/layout'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {Consumer} from '@possible/cassandra'
import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'

// When possible this component should be updated to use Page and BaseTemplate to match AcceptACH
type Props = WithTranslation &
  PropsFromRedux & {
    onAccept: () => void
    agreements: AgreementType[]
    navigation:
      | StackNavigationProp<MainStackParamList>
      | StackNavigationProp<MainStackParamList, 'AcceptAgreements'>
  }

type State = {
  loanChecked: boolean
  busy: boolean
  agreementsAcked: Map<string, boolean>
  agreementsAckedOrder: boolean[]
}

class AcceptSelectAgreements extends Component<Props, State> {
  state: State = {
    loanChecked: false,
    busy: false,
    agreementsAcked: new Map<string, boolean>(),
    agreementsAckedOrder: [],
  }

  onContinue = () => {
    this.props.onAccept()
  }

  canContinue() {
    if (!this.props.loan) return false

    for (const agreement of this.props.agreements) {
      if (!this.state.agreementsAcked.get(agreement.agreementName)) {
        return false
      }
    }

    return true
  }

  onAgreementChecked(agreementName: string, checked: boolean, idx: number) {
    const agreementsAcked = new Map(this.state.agreementsAcked)
    const agreementsAckedOrder = [...this.state.agreementsAckedOrder]
    const agreements = this.props.agreements
    agreementsAckedOrder[idx] = checked
    if (!checked && agreements) {
      for (let i = idx + 1; i < agreements.length; i++) {
        agreementsAcked.set(agreements[i].agreementName, false)
        agreementsAckedOrder[i] = false
      }
    }
    agreementsAcked.set(agreementName, checked)
    this.setState({agreementsAcked, agreementsAckedOrder})
  }

  async onPressLink(agreementName: string, agreementType: LoanDocumentType) {
    if (agreementName === LOAN_AGREEMENT) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_view_loan_agreement_selected,
        AppEvents.Category.Checkout,
      )
    }

    const loanId = this.props.loan?.id
    if (!loanId) return

    let document: Consumer.types.LoanGetDocumentMutation | null | undefined
    try {
      document = await Consumer.methods.getLoanDocument(loanId, agreementType)
    } catch (e) {
      //
    }

    if (document?.loanGetDocument?.url) {
      displayPdf(document.loanGetDocument.url, this.props.navigation)
    }
  }

  renderConfirmButtons() {
    return this.props.agreements.map((agreement, idx) => {
      return (
        <View key={idx} style={styles.checkboxPadding}>
          <PFCheckbox
            disabled={idx != 0 && !this.state.agreementsAckedOrder[idx - 1]}
            onPress={(checked) => this.onAgreementChecked(agreement.agreementName, checked, idx)}
            checked={this.state.agreementsAcked.get(agreement.agreementName)}
            testID={agreement.testID}
          >
            <SvgLink
              linkType={'inline'}
              onPress={(): Promise<void> =>
                this.onPressLink(agreement.agreementName, agreement.type)
              }
              linkText={agreement.title}
            />
          </PFCheckbox>
        </View>
      )
    })
  }

  render() {
    const {t, loan} = this.props
    const {busy} = this.state
    if (!loan) return null
    const borrowed_amount = loan.amountBorrowed?.toFixed() ?? ''

    const action = {
      text: agreeAndContinueText,
      onPress: this.onContinue,
      disabled: this.state.busy || !this.canContinue(),
    }

    if (busy) {
      return <Loading type="loader0" size="large" />
    }

    return (
      <GenericNonModalTemplate
        actionBlock={buttonLockupProperties(action)}
        title={pageTitle}
        description={t('LoanCommonText', {borrowedAmount: borrowed_amount})}
      >
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <PFText variant={'h3'}>{t('AgreeToTerms')}:</PFText>
          {this.renderConfirmButtons()}
        </View>
      </GenericNonModalTemplate>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loanType: latestLoanTypeSelector(state),
    loan: latestLoanSelector(state),
  }
}

const mapDispatchToProps = {
  UserStateRefresh,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withTranslation('LoanApproved')(AcceptSelectAgreements))

const styles = StyleSheet.create({
  checkboxPadding: {
    paddingVertical: smallGap,
  },
})
