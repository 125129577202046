import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {EmailVerificationRESTContainer} from 'src/products/loans/EmailVerification/EmailVerificationRESTContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import NavPageState from 'src/navigation/NavPageState'
import {LoanFlowEmailConfirmation} from 'src/navigation/LoanFlow'

export type EmailVerificationRouteContainerProps = StackScreenProps<
  MainStackParamList,
  'EmailVerification'
>

/**
 * Integrates the EmailVerification screen into a typical react-navigation route using navigation.push().
 */
export const EmailVerificationRouteContainer: FC<EmailVerificationRouteContainerProps> = (
  props,
) => {
  const {navigation, route} = props

  const fallbackOnSuccess = (): void => {
    // this is a hack to solve ENG-16967. if this screen ends up in a NavPageState flow
    // due to NavPageState.reapplyFlowStages() then it won't (and can't) have onSuccess passed so we default it here
    NavPageState.NavToNextPage(LoanFlowEmailConfirmation, navigation)
  }
  const onSuccess = route.params?.onSuccess ?? fallbackOnSuccess

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_email_verification_code_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const handleOnResendCodeTracking = (): void => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_resend_selected,
      AppEvents.Category.Checkout,
    )
  }

  const handleOnCodeSubmitted = (): void => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_submitted,
      AppEvents.Category.Checkout,
    )
    onSuccess(navigation)
  }

  return (
    <EmailVerificationRESTContainer
      onResendCodeTracking={handleOnResendCodeTracking}
      onCodeSubmitted={handleOnCodeSubmitted}
      withoutSendCodeOverlay
    />
  )
}
