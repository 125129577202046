import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {OnContinueAfterMarketingSurveyOptionSelected} from 'src/products/general/MarketingSurvey/MarketingSurvey.types'
import {MarketingSurveyGQLContainer} from 'src/products/general/MarketingSurvey/MarketingSurveyGQLContainer'

export type MarketingSurveyMPOContainerProps = ModuleControlProps &
  StackScreenProps<MainStackParamList, 'LoansMarketingSurvey'>

/**
 * Container to integrate the marketing survey in an MPO flow.
 */
const MarketingSurveyMPOContainer: React.FC<MarketingSurveyMPOContainerProps> = (
  props: MarketingSurveyMPOContainerProps,
) => {
  const {onModuleComplete} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.marketing_source_survey_screen_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnContinue: OnContinueAfterMarketingSurveyOptionSelected = async ({
    selectedSourceOption,
    otherValue,
  }) => {
    TrackAppEvent(
      AppEvents.Name.marketing_source_survey_selected,
      AppEvents.Category.InitialApplication,
      {value: selectedSourceOption, other: otherValue, navContainerType: 'MPO'},
    )

    await onModuleComplete?.()
  }

  return <MarketingSurveyGQLContainer onContinue={handleOnContinue} />
}

export default MarketingSurveyMPOContainer
