import React, {CSSProperties, forwardRef, useImperativeHandle, useRef} from 'react'
import {StyleSheet, View} from 'react-native'
import Webcam from 'react-webcam'

import {
  UniversalCameraProps,
  UniversalCameraRef,
} from 'src/products/general/UniversalCamera/UniversalCamera.types'
import {jpegContentType} from 'src/lib/utils/formData'
import {useMeasurement} from 'src/lib/utils/useMeasurement'
import {openImageUriAsFile} from 'src/products/general/UniversalCamera/UniversalCamera.utils'
import AspectView from 'src/products/general/components/atoms/PFCamera/AspectView'

const UniversalCamera = forwardRef<UniversalCameraRef, UniversalCameraProps>(
  (props, ref): JSX.Element => {
    const {
      disabled,
      aspectRatio,
      position = 'front',
      style,
      onCameraReady,
      width: captureWidth = 960,
      height: captureHeight = 540,
    } = props

    const {width, height, handleLayout: setCameraLayout} = useMeasurement()

    const webcamRef = useRef<Webcam>(null)

    useImperativeHandle(
      ref,
      () => ({
        takeImageCapture: async (): ReturnType<UniversalCameraRef['takeImageCapture']> => {
          if (!webcamRef.current) {
            return null
          }

          const dataUri = webcamRef.current.getScreenshot({
            width: captureWidth,
            height: captureHeight,
          })
          if (!dataUri) {
            return null
          }

          const file = await openImageUriAsFile(dataUri)

          return {
            source: 'web',
            file,
            dataUri,
            width: captureWidth,
            height: captureHeight,
            pictureOrientation: 1,
            uri: dataUri,
          }
        },
      }),
      [captureHeight, captureWidth],
    )

    const onUserMedia = (): void => {
      onCameraReady?.()
    }

    const videoConstraints: MediaTrackConstraints = {
      facingMode: position === 'front' ? 'user' : 'environment',
      width: width,
      height: height,
    }

    const cam =
      !disabled && width && height ? (
        <Webcam
          style={styles.webcam as CSSProperties}
          screenshotQuality={0.98}
          onUserMedia={onUserMedia}
          screenshotFormat={jpegContentType}
          // @ts-expect-error There is an additional string type applied somewhere?
          ref={webcamRef}
          audio={false}
          mirrored={false}
          videoConstraints={videoConstraints}
        />
      ) : null

    return (
      <AspectView aspectRatio={aspectRatio ?? 1} style={style}>
        <View onLayout={setCameraLayout} style={styles.container}>
          {cam}
        </View>
      </AspectView>
    )
  },
)

UniversalCamera.displayName = 'Camera'

export default UniversalCamera
export type {Webcam as CameraRefType}

const styles = StyleSheet.create({
  container: {height: '100%', width: '100%'},
  webcam: {height: '100%', objectFit: 'cover', width: '100%'},
})
