import React, {FC} from 'react'
import {useNavigation} from '@react-navigation/native'

import {LoanFinalAcceptGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptGQLContainer'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  LoanFinalAcceptNavProps,
  handleOnConfirmBankDetails,
} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'

type LoanFinalAcceptMPOContainerProps = ModuleControlProps

const LoanFinalAcceptMPOContainer: FC<LoanFinalAcceptMPOContainerProps> = (props) => {
  const {onStepComplete} = props
  const navigation: LoanFinalAcceptNavProps = useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.final_loan_acceptance_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    if (onStepComplete) {
      await onStepComplete()
    }
  }

  return (
    <LoanFinalAcceptGQLContainer
      onContinue={handleOnContinue}
      onConfirmBankDetails={(): void => handleOnConfirmBankDetails(navigation)}
    />
  )
}

export {LoanFinalAcceptMPOContainer}
