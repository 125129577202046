import React, {useState} from 'react'

import {logErrorAndShowException} from 'src/lib/errors'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {
  CardActivated as CardActivatedMarketingEvent,
  TrackAppEvent,
} from 'src/lib/Analytics/analytics_compat'
import {useAcceptCardAgreements} from 'src/products/card/Activation/CardPaymentAgreement/useAcceptCardAgreements'
import {useAcceptCardAccount} from 'src/products/card/PaymentMethods/useAcceptCardAccount'
import {CardPaymentAgreementTemplate} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementTemplate/CardPaymentAgreementTemplate'
import {usePfDispatch} from 'src/store/utils'
import {onboardingSetCardPaymentMethod} from 'src/lib/onboarding/slice'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardPaymentAgreementDocument} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementGQLContainer/CardPaymentAgreementQuery.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type CardPaymentAgreementGQLContainerProps = {
  onComplete: () => void
  onPressSecondary: () => void
}

const CardPaymentAgreementGQLContainer = ({
  onComplete,
  onPressSecondary,
}: CardPaymentAgreementGQLContainerProps): JSX.Element => {
  const dispatch = usePfDispatch()
  const [isBusy, setIsBusy] = useState(false)

  const {
    data,
    loading: isLoadingQuery,
    error: hasQueryError,
  } = useCassandraQuery(CardPaymentAgreementDocument, {fetchPolicy: 'no-cache'})

  const [acceptCardAgreements, {error: hasAcceptCardAgreementsError}] = useAcceptCardAgreements()
  const acceptCardAccount = useAcceptCardAccount()
  const isAutopayEnabled = data?.me.cardAccounts.active?.autopayEnabled ?? false

  const handleOnPressPrimary = async (): Promise<void> => {
    setIsBusy(true)
    const activeAccountId = data?.me.cardAccounts.active?.id
    if (!activeAccountId) {
      throw new Error('Common:UnableToLocateAccount')
    }

    try {
      TrackAppEvent(CardEvents.card_finish_virtual_activation_page_cta, AppEvents.Category.Card, {
        // This is preserving old tracking event behavior. In the future we may change this to just rack `isAutopayEnabled: boolean`
        paymentMethod: isAutopayEnabled ? 'autopay' : 'manual',
      })

      if (!isAutopayEnabled) {
        TrackAppEvent(CardEvents.enable_manual_pay_action, AppEvents.Category.Card)
      }

      CardActivatedMarketingEvent()
      await acceptCardAgreements({
        cardAccountId: activeAccountId,
        isAutopay: isAutopayEnabled,
        pendingAgreements: data?.me.cardAccounts.active?.agreements?.all ?? [],
      })

      await acceptCardAccount(activeAccountId)
      onComplete()
    } catch (e) {
      void logErrorAndShowException(e, 'card CardPaymentAgreement error')
    } finally {
      setIsBusy(false)
    }
  }

  const handleOnPressStep = (): void => {
    // This dispatch can be removed once MPO is deleted
    dispatch(onboardingSetCardPaymentMethod('reset'))
    onPressSecondary()
  }
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  return (
    <BaseTemplate
      isError={!!hasQueryError || !!hasAcceptCardAgreementsError}
      isLoading={isLoadingQuery}
    >
      <CardPaymentAgreementTemplate
        feeAmount={data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees ?? ''}
        isAutopay={isAutopayEnabled}
        isBusy={isBusy}
        onPressPrimary={handleOnPressPrimary}
        onPressStep={handleOnPressStep}
        minPayData={cardMinPayResponse}
      />
    </BaseTemplate>
  )
}

export {CardPaymentAgreementGQLContainer}
