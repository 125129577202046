import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import PhoneConfirmationTemplate from 'src/products/loans/PhoneConfirmation/PhoneConfirmationTemplate'
import {ModuleControlProps} from 'src/flows/types'
import {ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type Props = StackScreenProps<MainStackParamList, 'PhoneConfirmation'> & ModuleControlProps

const PhoneConfirmationMPOContainer: React.FC<Props> = (props) => {
  const {onStepComplete, onModuleComplete} = props

  const handleComplete = async (phoneNumber: string, phoneNumberFormatted: string) => {
    await onStepComplete?.({phoneNumber, phoneNumberFormatted})
  }

  const handleAlreadyComplete = async () => {
    try {
      await onModuleComplete?.()
    } catch (e) {
      ShowException(e)
    }
  }

  return (
    <PhoneConfirmationTemplate
      {...props}
      onComplete={handleComplete}
      onAlreadyComplete={handleAlreadyComplete}
    />
  )
}

export default PhoneConfirmationMPOContainer
