import {StackNavigationProp} from '@react-navigation/stack'
import React, {FC} from 'react'

import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {ModuleControlProps} from 'src/flows/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {IDScanReduxContainer} from 'src/products/loans/IDScan/IDScanReduxContainer'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, 'IDScan'>
} & ModuleControlProps

const IDScanMPOContainer: FC<Props> = (props) => {
  const {onModuleComplete, moduleName} = props

  useMPOAndroidToWeb(moduleName)

  const onComplete = async (): Promise<void> => {
    await onModuleComplete?.()
  }

  return <IDScanReduxContainer onComplete={onComplete} />
}

export default IDScanMPOContainer
