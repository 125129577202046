import React, {FC} from 'react'

import {LoanPaymentReviewGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewGQLContainer'
import {ModuleControlProps} from 'src/flows/types'
import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

type LoanPaymentReviewMPOContainerProps = ModuleControlProps

const LoanPaymentReviewMPOContainer: FC<LoanPaymentReviewMPOContainerProps> = (props) => {
  const {onStepComplete, moduleName} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.review_payment_schedule_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  useMPOAndroidToWeb(moduleName)

  const handleOnContinue = async (): Promise<void> => {
    if (onStepComplete) {
      await onStepComplete()
    }
  }

  return <LoanPaymentReviewGQLContainer onContinue={handleOnContinue} />
}

export {LoanPaymentReviewMPOContainer}
