import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import FastLoans from 'src/assets/illustrations/FastLoans.svg'

/**
 * A tile to inform the user that their loan has been submitted.
 * @example <LoanDecisioningTile />
 */

export const LoanDecisioningTile: React.FC = () => {
  const {t} = useTranslation('DashboardLoanDecisioningTile')
  return (
    <BasicTile padding="medium">
      <Box>
        <Box fill="horizontal" align="center" marginBottom="large">
          <PFSvgContain svg={FastLoans} width={230} />
        </Box>

        <Box gap="small" marginBottom="medium">
          <PFText variant="h3">{t('ApplicationSubmitted')}</PFText>
          <PFText variant="p">{t('ThisMayTakeUpToACoupleHours')}</PFText>
        </Box>

        <Box gap="small">
          <PFText variant="p_semibold">{t('SyncingYouAccountIncludes')}</PFText>
          <PFText variant="p">1. {t('LinkingYourBankAccount')}</PFText>
          <PFText variant="p">2. {t('VerifyingYourAccountDetails')}</PFText>
          <PFText variant="p">3. {t('ReviewingHistoricalTransactions')}</PFText>
        </Box>
      </Box>
    </BasicTile>
  )
}
