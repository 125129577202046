import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import {GraphQLError} from 'graphql'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import * as Public from '@possible/cassandra/src/types/types.public.generated'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {isPhoneVerified} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {ShowException} from 'src/lib/errors'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {usePfSelector} from 'src/store/utils'
import {userForgotPasswordSendCode} from '@possible/cassandra/src/user/authPublicMethods'
import AppEvents from 'src/lib/Analytics/app_events'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PhoneNumberEntryTemplate from 'src/products/general/components/templates/PhoneNumberEntryTemplate/PhoneNumberEntryTemplate'

type Props = StackScreenProps<MainStackParamList, 'PhoneConfirmation'> & {
  onComplete: (phoneNumber: string, phoneNumberFormatted: string) => Promise<void>
  onAlreadyComplete: () => Promise<void>
}

const PhoneConfirmationTemplate: React.FC<Props> = ({
  navigation,
  route,
  onComplete,
  onAlreadyComplete,
}: Props) => {
  const {forgotPassword} = route?.params ?? {}

  const {t} = useTranslation(['PhoneConfirmation', 'Common'])

  const [busy, setBusy] = useState(false)
  const [duplicate, setDuplicate] = useState('')
  const phoneVerified = usePfSelector(isPhoneVerified)

  const [sendVerificationCode] = Consumer.hooks.usePhoneVerificationCodeSendMutation()

  const eventCategory = forgotPassword ? AppEvents.Category.Admin : AppEvents.Category.Checkout
  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_phone_number_screen_viewed,
    eventCategory,
  })

  const onDuplicatePhoneNumber = (phoneNumberFormatted: string): void => {
    PushPage(navigation, 'DuplicatePhoneNumber', {
      phoneNumber: phoneNumberFormatted,
    })
  }

  const navToVerification = async (
    phoneNumber: string,
    phoneNumberFormatted: string,
  ): Promise<void> => {
    setBusy(true)
    await onComplete(phoneNumber, phoneNumberFormatted)
    setBusy(false)
  }

  const onCellPhoneNumberReady = async (
    phoneNumber: string,
    phoneNumberFormatted: string,
    phoneNumberDisplayed: string,
  ): Promise<void> => {
    setBusy(true)

    /* this is a server error used to check the call status, it's not displayed to the user
     *  most likely this will always be in English*/
    const duplicateNumberError = 'Phone number already exists'

    try {
      const response = await sendVerificationCode({
        variables: {authorizationCodeInput: {phoneNumber: phoneNumberFormatted, method: 'SMS'}},
      })
      if (response.errors) {
        throw response.errors[0]
      }

      navToVerification(phoneNumber, phoneNumberFormatted)
    } catch (e) {
      const error = e as GraphQLError
      if (error.message === duplicateNumberError) {
        setDuplicate(phoneNumberDisplayed)
        onDuplicatePhoneNumber(phoneNumberFormatted)
      } else {
        ShowException(e)
      }
    } finally {
      setBusy(false)
    }
  }

  const sendForgotPasswordCode = async (
    phoneNumber: string,
    phoneNumberFormatted: string,
  ): Promise<void> => {
    setBusy(true)
    try {
      const input: Public.UserForgotPasswordSendCodeInput = {
        usernameType: Public.AccountUsernameType.Phone,
        username: phoneNumberFormatted,
        deliveryMedium: Public.AccountDeliveryMedium.Sms,
      }
      const success: boolean | undefined = await userForgotPasswordSendCode(input)
      if (!success) {
        throw Error('Failed to send forgot password code')
      }

      navToVerification(phoneNumber, phoneNumberFormatted)
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  const onNext = async (): Promise<void> => {
    setBusy(true)
    await onAlreadyComplete()
    setBusy(false)
  }

  const onNoPhone = (): void => {
    PushPage(navigation, 'TemporaryPassword')
  }

  const renderComplete = (): JSX.Element => {
    const action = {
      onPress: onNext,
      text: t('Common:Continue'),
      disabled: busy,
    }

    return (
      <Page
        variant={'generic'}
        smallTopGap={true}
        testID={'PhoneConfirmationPage'}
        buttonProps={buttonLockupProperties(action)}
        title={t('YourPhoneNumberHasBeenVerified')}
      />
    )
  }

  if (phoneVerified) {
    return renderComplete()
  }
  let title = t('WhatsYourPhoneNumber')
  if (forgotPassword) {
    title = t('Common:ForgotYourPassword')
  }
  const buttonLabel = forgotPassword ? t('SendMyCode') : t('SubmitPhoneNumber')
  const buttonAction = forgotPassword ? sendForgotPasswordCode : onCellPhoneNumberReady
  //we only want to show this link if they get to the screen from the "Forgot Password" link on the Login page
  const showNoPhoneLink = forgotPassword ? (): void => onNoPhone() : undefined
  return (
    <PhoneNumberEntryTemplate
      testID={'PhoneConfirmationPage'}
      onButtonPress={buttonAction}
      mainTitle={title}
      mainBody={t('CellPhoneWillBeUsedForAccountVerification')}
      buttonLabel={buttonLabel}
      appEventCompleted={AppEvents.Name.enter_phone_number_completed}
      appEventCategory={eventCategory}
      duplicateNumber={duplicate}
      actionDisabled={busy}
      showNoPhoneLinkAction={showNoPhoneLink}
    />
  )
}

export default PhoneConfirmationTemplate
