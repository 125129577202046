import {useFocusEffect} from '@react-navigation/native'
import React, {FC, useCallback, useState} from 'react'

import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {logErrorAndShowException} from 'src/lib/errors'
import NavPageState from 'src/navigation/NavPageState'
import {IDScanTemplate, Phases} from 'src/products/loans/IDScan/IDScanTemplate'
import {usePfDispatch} from 'src/store/utils'

type Props = {
  onComplete: () => void | Promise<void>
}

export const IDScanReduxContainer: FC<Props> = (props) => {
  const {onComplete} = props
  const dispatch = usePfDispatch()
  const [busy, setBusy] = useState<boolean>(false)
  const [phase, setPhase] = useState<Phases | undefined>(undefined)

  useFocusEffect(
    useCallback(() => {
      if (NavPageState.IsDLScanned()) {
        setPhase('Complete')
      } else {
        setPhase('FrontImageCapture')
      }
    }, []),
  )

  const onContinue = useCallback(async (): Promise<void> => {
    try {
      await onComplete()
    } catch (e) {
      if (e instanceof Error) {
        void logErrorAndShowException(e, 'Error while trying to continue IDScan ')
      }
    }
  }, [onComplete])

  const onFrontConfirm = async (): Promise<void> => {
    try {
      setBusy(true)
      setPhase('Complete')
      await onComplete()
      await dispatch(UserStateRefresh())
    } catch (e) {
      if (e instanceof Error) {
        void logErrorAndShowException(e, 'Error while trying to confirm IDScan')
      }
    } finally {
      setBusy(false)
    }
  }

  return (
    <IDScanTemplate
      onContinue={onContinue}
      onFrontConfirm={onFrontConfirm}
      busy={busy}
      phase={phase}
    />
  )
}
