import {useFocusEffect} from '@react-navigation/native'
import {useCallback} from 'react'

import {EmitRedirectionEvent} from 'src/lib/utils/events'
import useRedirectToWeb from 'src/nav/useRedirectToWeb'

const isLoanModule = (moduleName?: string) => {
  return moduleName?.startsWith('LOAN') ?? false
}

/**
 * For MPO we will need certain screens to navigate
 * to web (on the phone's browser) to comply with
 * Google's play store TOS (or something). Using this
 * hook will handle that redirection.
 *
 * Normally we would specify a destination but with MPO
 * the backend already knows and specifies our destination
 * module, so this isn't necessary.
 */
export const useMPOAndroidToWeb = (moduleName: string | undefined) => {
  const redirectToWeb = useRedirectToWeb()
  const focusEffect = useCallback(() => {
    MPOAndroidToWeb(moduleName, redirectToWeb)
  }, [moduleName, redirectToWeb])
  useFocusEffect(focusEffect)
}

export const MPOAndroidToWeb = (moduleName: string | undefined, redirectToWeb: boolean) => {
  if (isLoanModule(moduleName)) {
    if (redirectToWeb) {
      EmitRedirectionEvent('mpo', true)
    }
  }
}
