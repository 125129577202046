export const DELETE_MAP_ACTION_TYPE = Symbol('DELETE_MAP_ACTION_TYPE')
export type DeleteMapActionType = {
  type: symbol
  entityType: string
  keys: string[]
}

export const UPSERT_MAP_ACTION_TYPE = Symbol('UPSERT_MAP_ACTION_TYPE')
export type UpsertMapActionType<T> = {
  type: symbol
  entityType: string
  values: Record<string, T>
}

export type entityActionTypes = DeleteMapActionType | UpsertMapActionType<any>

export function isDeleteMapAction(action: entityActionTypes): action is DeleteMapActionType {
  return action.type === DELETE_MAP_ACTION_TYPE
}

export function isUpsertMapAction(action: entityActionTypes): action is UpsertMapActionType<any> {
  return action.type === UPSERT_MAP_ACTION_TYPE
}

export type keyAccessorType<T> = (value: T) => any

export class PfEntityAction<T> {
  entityType: string
  keyAccessor: keyAccessorType<T>

  constructor(entityType: string, keyAccessor: keyAccessorType<T>) {
    this.entityType = entityType
    this.keyAccessor = keyAccessor
  }

  deleteMapAction(keys: string[]): DeleteMapActionType {
    return {
      type: DELETE_MAP_ACTION_TYPE,
      entityType: this.entityType,
      keys,
    }
  }

  upsertRecordsMapAction(values: Record<string, T>): UpsertMapActionType<T> {
    return {
      type: UPSERT_MAP_ACTION_TYPE,
      entityType: this.entityType,
      values,
    }
  }

  upsertRecordMapAction(value: T): UpsertMapActionType<T> {
    const values = {[this.keyAccessor(value)]: value}
    return {
      type: UPSERT_MAP_ACTION_TYPE,
      entityType: this.entityType,
      values,
    }
  }
}
