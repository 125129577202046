import Log from 'src/lib/loggingUtil'

import {getPfStore} from 'src/store'
import {ApiVersion} from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress.types'
import {MaintenanceDone, MaintenanceInProgress} from 'src/api/actions/maintenance/actions'

export const ENABLE_MAINTENANCE_MODE_WHEN_REQUESTS_FAIL = false
export const SERVER_MAINTENANCE_HTTP_STATUS = 503

/**
 * Check server response for http 503 Service Unavailable,
 * and display maintenance screen if necessary.
 */
type enableMaintenanceScreenIfNecessaryType = (response: Response, prefix: ApiVersion) => boolean
export const enableMaintenanceScreenIfNecessary: enableMaintenanceScreenIfNecessaryType = (
  response,
  prefix,
) => {
  if (!response?.status || !ENABLE_MAINTENANCE_MODE_WHEN_REQUESTS_FAIL) {
    return false
  }

  const dispatch = getPfStore()?.dispatch

  if (response.status === SERVER_MAINTENANCE_HTTP_STATUS) {
    Log.log('Getting 503, maintenance in progress?', response)
    dispatch?.(MaintenanceInProgress(prefix))
    return true
  } else {
    dispatch?.(MaintenanceDone(prefix))
    return false
  }
}
