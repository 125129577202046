import {CardPaymentAgreementMPOContainer} from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementMPOContainer'
import {CardAutopayOverviewMPOContainer} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewMPOContainer'
import {CardManualPayOverviewMPOContainer} from 'src/products/card/Activation/CardManualPayOverview/CardManualPayOverviewMPOContainer'
import {OnboardingModuleDefinitions} from 'src/flows/types'
import {CardApprovedMPOContainer} from 'src/products/card/Activation/CardApproved/CardApprovedMPOContainer'
import {onboardingCardPaymentMethodSelector} from 'src/lib/onboarding/selectors'
import {getPfStore} from 'src/store'
import {CardManualPayScheduleMPOContainer} from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleMPOContainer'
import {CardActivationAutopayScheduleMPOContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleMPOContainer'
import {CardActivatedMPOContainer} from 'src/products/card/Activation/CardActivated/CardActivatedMPOContainer'

export const CardActivationModuleDefinitions: Partial<OnboardingModuleDefinitions> = {
  CARD_ACTIVATION: {
    type: 'MultiDirected',
    init: () => 'CARD_APPROVED',
    director: (from) => {
      const paymentMethod = onboardingCardPaymentMethodSelector(getPfStore().getState())

      switch (from) {
        // Autopay info screens - all users
        case 'CARD_APPROVED':
          return 'CARD_AUTOPAY_OVERVIEW'
        case 'CARD_AUTOPAY_OVERVIEW':
          return 'CARD_AUTOPAY_SCHEDULE'

        // User decides on autopay or manual pay
        case 'CARD_AUTOPAY_SCHEDULE': {
          if (paymentMethod === 'autopay') {
            return 'CARD_PAYMENT_AGREEMENT'
          } else {
            return 'CARD_MANUAL_PAY_OVERVIEW'
          }
        }

        // Additional screens for manual pay only
        case 'CARD_MANUAL_PAY_OVERVIEW': {
          // User has the option to switch to autopay on this screen
          if (paymentMethod === 'reset') {
            return 'CARD_AUTOPAY_SCHEDULE'
          } else {
            return 'CARD_MANUAL_PAY_SCHEDULE'
          }
        }
        case 'CARD_MANUAL_PAY_SCHEDULE':
          return 'CARD_PAYMENT_AGREEMENT'

        // Concluding screens - both autopay and manual pay
        case 'CARD_PAYMENT_AGREEMENT': {
          // User has the option to switch to autopay on this screen
          if (paymentMethod === 'reset') {
            return 'CARD_AUTOPAY_SCHEDULE'
          } else {
            return 'CARD_ACTIVATED'
          }
        }
        case 'CARD_ACTIVATED':
          return undefined
      }
    },
    steps: {
      CARD_APPROVED: {
        screen: {
          path: 'card/approved',
        },
        Component: CardApprovedMPOContainer,
        headerLeftButton: 'Menu',
      },
      CARD_AUTOPAY_OVERVIEW: {
        screen: {
          path: 'card/autopay-overview',
        },
        Component: CardAutopayOverviewMPOContainer,
      },
      CARD_AUTOPAY_SCHEDULE: {
        screen: {
          path: 'card/autopay-schedule',
        },
        Component: CardActivationAutopayScheduleMPOContainer,
      },
      CARD_MANUAL_PAY_OVERVIEW: {
        screen: {
          path: 'card/manual-overview',
        },
        Component: CardManualPayOverviewMPOContainer,
      },
      CARD_MANUAL_PAY_SCHEDULE: {
        screen: {
          path: 'card/manual-schedule',
        },
        Component: CardManualPayScheduleMPOContainer,
      },
      CARD_PAYMENT_AGREEMENT: {
        screen: {
          path: 'card/payment-agreement',
        },
        Component: CardPaymentAgreementMPOContainer,
      },
      CARD_ACTIVATED: {
        screen: {
          path: 'card/activated',
        },
        Component: CardActivatedMPOContainer,
        // 'NoneNoBreak' is a hack to get around an issue in MPO
        // For details see https://gitlab.com/possiblefinance/client_mono/-/merge_requests/2619
        headerLeftButton: 'NoneNoBreak',
      },
    },
  },
  CARD_END: {
    type: 'End',
    navigateTo: 'DashboardLoader',
  },
}
