/* eslint-disable */

import React, {useCallback, FC, useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useNavigation} from '@react-navigation/native'

import {Consumer} from '@possible/cassandra'
import {allModuleDefinitions} from 'src/flows/onboarding'
import {isDeviceIOS} from 'src/lib/utils/platform'
import {MainStackNavigationProp, MainStackParamList} from 'src/nav/MainStackParamsList'
import {ModuleStackFactory} from 'src/flows/modules/factory'
import {OnboardingModule} from '@possible/cassandra/src/types/types.mobile.generated'
import {OnboardingParamList} from 'src/flows/types'
import {userIsLoggedIn} from 'src/api/selectors/selectors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ScrollView, StyleSheet, TextInput, TouchableOpacity} from 'react-native'
import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {screenToParamsMap} from 'src/products/general/VersionFooter/AppScreenSelector/AppScreenParamsRequired'

const mpoModuleKey = ' (MPO module)'

const getOptions = (navigation: MainStackNavigationProp): string[] => {
  const routeNames = navigation.getState()?.routes?.[0]?.state?.routes?.[0]?.state?.routeNames ?? []
  const mpoModuleNames = Object.keys(OnboardingModule).map((s) => `${s}${mpoModuleKey}`)
  const allOptions = [...routeNames, ...mpoModuleNames]
  allOptions.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })

  return allOptions.map((routeName) => {
    return routeName
  })
}

const navigateAway = (routeTo: string, navigation: MainStackNavigationProp): void => {
  if (!routeTo) return

  const isMpoModule = routeTo.includes(mpoModuleKey)

  if (isMpoModule) {
    const moduleName = routeTo.replace(mpoModuleKey, '')
    const module = OnboardingModule[moduleName]

    const OnboardingModuleStack = ModuleStackFactory<OnboardingParamList>(
      async () => {
        return {
          currentModule: module,
          onboardingFinished: false,
          nextModulesAllowed: {
            nextModulesAllowed: [],
          },
          previousModules: {
            previousModules: [module],
          },
        }
      },
      Consumer.methods.OnboardingMoveToNextModule,
      Consumer.methods.OnboardingMoveToPreviousModule,
      allModuleDefinitions,
    )

    navigation.navigate('ScreenSelectorModuleContainer', {
      component: OnboardingModuleStack.component as React.FC,
    })
  } else {
    const routeKey = routeTo as keyof MainStackParamList
    navigation.navigate(routeKey, screenToParamsMap[routeKey])
  }
}

export const AppScreenSelector: FC = () => {
  const navigation: MainStackNavigationProp = useNavigation()
  const [options, setOptions] = useState<string[]>([])
  const [selectedRoute, setSelectedRoute] = useState<string>('')
  const [screenSearchTerm, setScreenSearchTerm] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const isUserLoggedIn = useSelector(userIsLoggedIn)

  // Uncomment for testing
  // useEffect(() => {
  //   setTimeout(() => {
  //     navigateAway("CardActivation" + mpoModuleKey, navigation)
  //   }, timeoutAmount)
  // }, [navigation])

  // The component mounts before the nav state is composed
  // This is a really hacky fix, but it works.
  // If the onOpen prop of RNPickerSelect ever starts working in android, use that instead

  const timeoutAmount = 5000

  useEffect(() => {
    if (isUserLoggedIn) {
      const to = setTimeout(() => {
        setOptions(getOptions(navigation))
      }, timeoutAmount)

      return () => clearTimeout(to)
    }
  }, [navigation, isUserLoggedIn])

  const navigateTo = useCallback(
    (routeTo?: string) => {
      navigateAway(routeTo ?? selectedRoute, navigation)
    },
    [navigation, selectedRoute],
  )

  const onSelection = useCallback(
    (routeName: string) => {
      setSelectedRoute(() => {
        if (!isDeviceIOS()) {
          setScreenSearchTerm('')
          setIsModalVisible(false)
          navigateTo(routeName)
          return ''
        } else {
          return routeName
        }
      })
    },
    [setSelectedRoute, navigateTo],
  )

  if (!isUserLoggedIn) return null

  const toggleModal = (): void => {
    if (!isModalVisible) {
      setSelectedRoute('')
      setIsModalVisible(true)
    } else {
      setIsModalVisible(true)
    }
  }
  const onDone = (): void => {
    setScreenSearchTerm('')
    setIsModalVisible(false)
    navigateTo(selectedRoute)
  }
  const onNevermind = (): void => {
    setScreenSearchTerm('')
    setSelectedRoute('')
    setIsModalVisible(false)
  }

  return (
    <Box marginVertical="little">
      <Button size="medium" width={'100%'} mode="primary" onPress={toggleModal}>
        Jump to a screen
      </Button>
      <OverlaySimple
        visible={isModalVisible}
        testID="AppScreenSelectorModal"
        okayButtonText="Navigate to selected screen"
        okayButtonDisabled={!selectedRoute}
        onOkay={onDone}
        dismissButtonText="Nevermind"
        onDismiss={onNevermind}
        title={`Select a screen`}
        // add render key so that it re-renders when these values change
        renderKey={`${screenSearchTerm} ${selectedRoute}`}
      >
        <TextInput
          placeholder={'Search screens'}
          onChangeText={(value: string): void => {
            setScreenSearchTerm(value)
          }}
          style={styles.searchBox}
        />
        <ScrollView style={styles.screensList}>
          {options
            .filter((thisOption) => {
              if (!screenSearchTerm) {
                return true
              } else {
                return thisOption.toLowerCase().indexOf(screenSearchTerm.toLowerCase()) !== -1
              }
            })
            .map((thisOption) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    onSelection(thisOption)
                  }}
                  key={thisOption}
                >
                  <PFText
                    variant={'p_sm'}
                    key={thisOption}
                    color={
                      selectedRoute === thisOption ? NamedColors.PRODUCT_BLUE : NamedColors.BLACK
                    }
                  >
                    {thisOption}
                  </PFText>
                </TouchableOpacity>
              )
            })}
        </ScrollView>
      </OverlaySimple>
    </Box>
  )
}

const styles = StyleSheet.create({
  searchBox: {
    borderColor: NamedColors.BLACK,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  },
  screensList: {
    height: 300,
    overflow: 'scroll',
  },
})
