import React from 'react'
import {ImageRequireSource, StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import PossibleCardImage from 'src/assets/images/PossibleCard.png'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Image from 'src/products/general/Image/Image'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {
  ProgressTracker,
  ProgressTrackerProps,
} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTracker'
import {ButtonAction} from 'src/designSystem/types'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

type CardApprovedTemplateProps = {
  creditLimit: string
  feeAmount: string
  onPress: ButtonAction
}

const CardApprovedTemplate = ({
  creditLimit,
  feeAmount,
  onPress,
}: CardApprovedTemplateProps): JSX.Element => {
  const {t} = useTranslation('CardApprovedTemplate')
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const onboardingExperimentA3FeatureFlag = useIsFeatureFlagEnabled(
    'card-onboarding-experiment-1-mock-bureau-uw',
  )
  const progressTrackerSteps: ProgressTrackerProps['steps'] = onboardingExperimentA3FeatureFlag
    ? [
        {
          title: t('bullet1TitleOnboardingExp'),
          subtitle: t('bullet1DescriptionOnboardingExp'),
          label: '1',
          lineStyle: 'dashed',
          isComplete: true,
        },
        {
          title: t('bullet1Title'),
          label: '2',
          lineStyle: 'dashed',
          isComplete: true,
        },
        {
          title: t('bullet2Title'),
          subtitle: t('bullet2Description'),
          label: '3',
          isComplete: true,
        },
      ]
    : [
        {
          title: t('bullet1Title'),
          label: '1',
          lineStyle: 'dashed',
          isComplete: true,
        },
        {
          title: t('bullet2Title'),
          subtitle: t('bullet2Description'),
          label: '2',
          lineStyle: 'dashed',
          isComplete: true,
        },
        {
          title: t('bullet3Title'),
          label: '3',
          isComplete: true,
        },
      ]

  return (
    <Page
      variant={'generic'}
      smallTopGap
      noHorizontalPadding
      title={t('title')}
      testID={'CardApprovedTemplate'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: onboardingExperimentA3FeatureFlag ? t('activateMyAccount') : t('primaryCTA'),
          onPress: onPress,
        },
      }}
    >
      <Image
        source={PossibleCardImage as ImageRequireSource}
        resizeMode={'cover'}
        style={styles.image}
      />

      <Box paddingHorizontal={'medium'} paddingTop={'medium'} paddingBottom={'small'}>
        <PFText testID={'card_approved_title'} variant={'h3'}>
          {t('header', {
            amountBorrowed: convertToDollarAmt(creditLimit, false),
          })}
        </PFText>
      </Box>

      <Box paddingHorizontal={'medium'} paddingBottom={'large'}>
        <PFText variant={'p'}>
          {t('body', {
            monthlyFee: convertToDollarAmt(feeAmount, false),
          })}
        </PFText>
      </Box>

      <Box paddingBottom={'large'} paddingHorizontal={'medium'}>
        <ProgressTracker steps={progressTrackerSteps} />
      </Box>
    </Page>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
  },
})

export {CardApprovedTemplate}
