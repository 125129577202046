import React, {useState, FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'

import Log from 'src/lib/loggingUtil'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {URAModalContentProps} from 'src/products/loans/UserRequestedAction/URAProps'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {expireUra} from '@possible/cassandra/src/user/methods'
import {ShowException} from 'src/lib/errors'

const URAModalContent: FC<URAModalContentProps> = ({
  uraId,
  ura,
  extra,
  primary,
  secondary: secondaryAction,
  close,
  legalText,
}) => {
  const [busy, setBusy] = useState(false)
  const {t} = useTranslation('Common')

  if (!ura) {
    return null
  }

  const onDismiss = async (): Promise<void> => {
    try {
      setBusy(true)

      await expireUra(uraId)

      close()
    } catch (e) {
      if (e instanceof Error) {
        Log.warn(e.message)
      }

      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  //LightboxNotice is putting the secondary_action in an array
  const secondary = ura.optional
    ? {
        text: t('Dismiss'),
        disabled: primary.disabled || busy,
        onPress: () => onDismiss(),
      }
    : undefined

  const body = (
    <View>
      {ura.actionBody ? (
        <View>
          <PFText variant={'p'}>{ura.actionBody}</PFText>
        </View>
      ) : null}
      {extra ? <View style={styles.extra}>{extra}</View> : null}
    </View>
  )
  return (
    <LightboxNotice
      titleTextAlign="left"
      title={ura.actionTitle}
      body={body}
      primary_action={primary}
      secondary_action={secondaryAction ?? secondary}
      legalText={legalText}
    />
  )
}
export default URAModalContent

const styles = StyleSheet.create({
  extra: {
    marginTop: 8,
  },
})
