import React, {FC} from 'react'

import AppEvents from 'src/lib/Analytics/app_events'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {ReasonSurveyRESTContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyRESTContainer'

type ReasonSurveyMPOContainerProps = ModuleControlProps

export const ReasonSurveyMPOContainer: FC<ReasonSurveyMPOContainerProps> = (props) => {
  const {onStepComplete} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.purpose_for_loan_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    await onStepComplete?.()
  }

  return <ReasonSurveyRESTContainer onContinue={handleOnContinue} />
}
