import React, {FC} from 'react'

import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {HowPaymentsWorkGQLContainer} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkGQLContainer/HowPaymentsWorkGQLContainer'

type Props = ModuleControlProps

export const HowPaymentsWorkMPOContainer: FC<Props> = (props) => {
  const {onStepComplete} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.how_payments_work_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  async function handleOnContinue(): Promise<void> {
    TrackAppEvent(AppEvents.Name.how_payments_work_completed, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })
    await onStepComplete?.()
  }

  return <HowPaymentsWorkGQLContainer onContinue={handleOnContinue} />
}
