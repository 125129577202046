import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {CardMinPaymentsQuery} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {getTestID} from 'src/lib/utils/tests.utils'

interface OverlayDetailsProps {
  visible: boolean
  onDismiss: () => void
  nextPaymentDueDateFiltered: string | undefined
  autopayEnabled: boolean | undefined
  autopayStatus: string
  cardMinPayResponse: CardMinPaymentsQuery['me']['cardAccounts']['active']
}

const ShowDetailsOverlay = ({
  visible,
  onDismiss: handleOnDismiss,
  nextPaymentDueDateFiltered,
  autopayEnabled,
  autopayStatus,
  cardMinPayResponse,
}: OverlayDetailsProps): JSX.Element => {
  const {t} = useTranslation(['UpcomingPaymentsCard'])

  const renderOverlayDetailRow = (
    label: string,
    value: string | number,
    description?: string,
  ): JSX.Element => (
    <Box paddingVertical="tiny">
      <Box testID={getTestID(label, '-Id')} direction="row">
        <PFText variant="p_semibold">{label}: </PFText>
        <PFText variant="p">{value}</PFText>
      </Box>
      {description ? (
        <PFText variant="p_sm" color={NamedColors.SILVER}>
          {description}
        </PFText>
      ) : null}
    </Box>
  )

  return (
    <OverlaySimple
      testID="ShowDetailsOverlay"
      visible={visible}
      dismissButtonText={t('Close')}
      dismissButtonSize="medium"
      onDismiss={handleOnDismiss}
      padding="medium"
      content={
        <Box>
          {autopayEnabled && (
            <>
              {renderOverlayDetailRow(
                t('AutopayScheduled'),
                formatDate(nextPaymentDueDateFiltered, humanReadableDateDayMonth2),
                t('AutopayDateDescription'),
              )}
              {renderOverlayDetailRow(
                t('AutopayAmount'),
                autopayStatus === 'Statement Balance'
                  ? `$${cardMinPayResponse?.balance?.statementAfterPayments}`
                  : `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`,
              )}
              {renderOverlayDetailRow(t('AutopayFrequency'), t('Monthly'))}
              {renderOverlayDetailRow(t('AutopayType'), autopayStatus)}
              <Box paddingVertical="medium">
                <Separator />
              </Box>
            </>
          )}
          {renderOverlayDetailRow(
            t('MinimumDue'),
            `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`,
          )}
          {renderOverlayDetailRow(
            t('StatementBalance'),
            `$${cardMinPayResponse?.balance?.statementAfterPayments}`,
            t('StatementBalanceDescription'),
          )}
          {renderOverlayDetailRow(
            t('PaymentDueDate'),
            formatDate(
              cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
              humanReadableDateDayMonth2,
            ),
            t('StatementDueDateDescription'),
          )}
        </Box>
      }
    />
  )
}

export {ShowDetailsOverlay}
