import {MutationResult} from '@apollo/client'
import moment from 'moment'
import {useCallback, useState} from 'react'

import {Consumer} from '@possible/cassandra'
import {getNextAvailableDisbursementDate} from 'src/api/actions/loans/loanActions'
import APIClientLoan from 'src/api/lib/APIClientLoan'
import {LoanAccepted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {LOAN_UPDATE} from 'src/lib/loans/actions'
import {latestLoanSelector, OALoanCount} from 'src/lib/loans/selector'
import Log from 'src/lib/loggingUtil'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {GraphQLFormattedError} from 'graphql'

export type UseAcceptLoanArguments = Consumer.types.LoanAcceptInput

const trackError = (
  exception: Error | GraphQLFormattedError,
  service: 'submitLoan' | 'retrieveLoan' | 'exception' | 'getNextAvailableDisbursementDate',
  overrideMessage?: string,
): void => {
  const message = exception.message
  Log.error(exception, `${service} error`)
  TrackAppEvent(AppEvents.Name.final_loan_acceptance_failed, AppEvents.Category.Checkout, {
    service: service,
    error: message,
  })
  if (overrideMessage) {
    ShowException(overrideMessage ?? message)
  }
}

export const useAcceptLoan = (): [
  (input: UseAcceptLoanArguments) => Promise<void | boolean>,
  number,
  MutationResult<Consumer.types.LoanAcceptMutation>,
  boolean,
] => {
  const dispatch = usePfDispatch()

  const loan = usePfSelector(latestLoanSelector)
  const oaCount = usePfSelector(OALoanCount)

  const [acceptLoan, requestInfo] = Consumer.hooks.useLoanAcceptMutation()
  const [retry, setRetry] = useState(0)
  const [hasAchMaskAccountError, setHasAchMaskAccountError] = useState(false)

  const handleAcceptLoan = useCallback(
    async (input: UseAcceptLoanArguments) => {
      const result = await acceptLoan({variables: {acceptInput: input}})
      if (result.errors) {
        if (
          Consumer.errors.containsError(result.errors, Consumer.errors.ErrorCodes.AchMaskMatchFail)
        ) {
          setHasAchMaskAccountError(true)
        }
        trackError(result.errors[0], 'submitLoan', 'Unable to submit loan')
        setRetry((current) => current + 1)
        return false
      }

      Log.info('acceptLoan results', result.data)

      try {
        // This will be able to be removed when we remove MPO and can stop using the current GetLoan call
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const {loan} = await APIClientLoan.GetLoan(input.loanId)

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dispatch({type: LOAN_UPDATE, loan})
      } catch (e) {
        const err: Error = e instanceof Error ? e : new Error(String(e))
        trackError(err, 'retrieveLoan', 'Unable to retrieve updated loan')
        setRetry((current) => current + 1)
        // this is an error but we don't want to halt acceptance
        // as the polling will pick this up eventually
      }

      const disbursementDateResponse = await getNextAvailableDisbursementDate(
        input.loanId,
        moment().utc(),
      )

      if (!disbursementDateResponse) {
        const errMessage = `Failed to retrieve next available disbursement date for loanId "${input.loanId}"`
        trackError(
          new Error(errMessage),
          'getNextAvailableDisbursementDate',
          'Unable to retrieve disbursement date',
        )
        // this is an error but we don't want to halt acceptance
        // as by this point they have already accepted, however
        // we may not be able to show their disbursement date
      }

      let hasSentPurchaseEvent = false
      //Fire Branch/FB/Firebase purchase event only for first loan OA1
      if (loan && oaCount === 1) {
        LoanAccepted(loan)
        hasSentPurchaseEvent = true
      }
      TrackAppEvent(AppEvents.Name.final_loan_acceptance_completed, AppEvents.Category.Checkout, {
        oaCount,
        purchaseEventCreated: hasSentPurchaseEvent,
      })

      return true
    },
    [acceptLoan, dispatch, loan, oaCount],
  )

  return [handleAcceptLoan, retry, requestInfo, hasAchMaskAccountError]
}
