import {UserLoginStates, UserLoginStatesTypes} from 'src/api/reducers/types'
import {MinimumBinaryVersion} from 'src/lib/MinimumBinaryVersion'
import {ApiVersion} from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress.types'
import {PfReduxState} from 'src/reducers/types'

export const badConnectionSelector = (state: PfReduxState): boolean => state.api.badConnection
export const maintenanceOn = (state: PfReduxState): boolean =>
  state.api?.maintenanceV1 || state.api?.maintenanceV2
export const maintenanceOnApi = (state: PfReduxState, apiVersion: ApiVersion): boolean =>
  state.api?.[`maintenance${apiVersion}`]
export const maintenanceOnFirebase = (state: PfReduxState): boolean =>
  state.api?.maintenanceFirebase
export const minimumBinaryVersionOnFirebase = (
  state: PfReduxState,
): MinimumBinaryVersion | undefined => state.api?.minimumBinaryVersion
export const appInit = (state: PfReduxState): boolean => state.api?.init
export const initialUserRefreshSelector = (state: PfReduxState): boolean =>
  state.api?.initialUserRefresh
export const userLoggedState = (state: PfReduxState): UserLoginStatesTypes =>
  state.api?.user_logged_state
export const userIsLoggedIn = (state: PfReduxState): boolean =>
  state.api?.user_logged_state === UserLoginStates.logged_in
// LOTS of code expects this to be a string no matter what. it should really be string | undefined, but to avoid breaking typing on a ton of screens this is cast as string for now
export const userIdSelector = (state: PfReduxState): string => state.api?.user_id as string
export const devMode = (state: PfReduxState): boolean => state.api?.dev_mode
