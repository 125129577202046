import React, {FC} from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {CardProductIntroductionReduxContainer} from 'src/products/card/Application/CardProductIntroduction/CardProductIntroductionReduxContainer'

/**
 * ENG-18373 CardProductIntroduction can be deleted when MPO is deleted
 */
export const CardProductIntroductionMPOContainer: FC<ModuleControlProps> = ({onModuleComplete}) => {
  const handleOnComplete = async (): Promise<void> => {
    if (onModuleComplete) {
      return onModuleComplete()
    }
    return Promise.resolve()
  }

  return <CardProductIntroductionReduxContainer onComplete={handleOnComplete} />
}
