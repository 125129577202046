/* eslint-disable @typescript-eslint/no-var-requires */
import React, {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Image, ImageSourcePropType, StyleSheet} from 'react-native'

import AccountSyncing from 'src/assets/images/account-syncing.svg'
import LinkBankAccount from 'src/assets/images/link-bank-account.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import ProductIntroduction, {
  ProductIntroductionStep,
} from 'src/products/general/ProductIntroduction/ProductIntroduction'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ShowException} from 'src/lib/errors'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

export type CardProductIntroductionTemplateProps = {
  onComplete: () => Promise<void>
  monthlyFee?: string
  balanceLimit?: string
}

export const CardProductIntroductionTemplate: FC<CardProductIntroductionTemplateProps> = (
  props,
) => {
  const {onComplete, monthlyFee, balanceLimit} = props
  const {t} = useTranslation('CardProductIntroduction')
  const [busy, setBusy] = useState<boolean>(false)
  const steps: ProductIntroductionStep[] = useMemo(
    () => [
      {
        title: t('ApplyInAFewSteps'),
        illustration: null,
        testID: 'CardProductIntroduction_apply_in_a_few_steps',
      },
      {
        title: t('LinkYourBankAccount'),
        subtitle: t('ConnectYourBankAccount'),
        illustration: <PFSvgContain svg={LinkBankAccount} height={218} />,
        testID: 'CardProductIntroduction_link_your_bank_account',
      },
      {
        title: t('WaitForAccountSyncing'),
        subtitle: t('ItCanTakeUpToAFew'),
        illustration: <PFSvgContain svg={AccountSyncing} height={218} />,
        testID: 'CardProductIntroduction_wait_for_account_syncing',
      },
      {
        title: t('ActivateYourCard'),
        subtitle: t('ActivateYourCardByConfirming'),
        illustration: (
          <Box alignSelf="center">
            <Image
              style={styles.image}
              source={require('src/assets/images/activate-card.png') as ImageSourcePropType}
              resizeMode={'contain'}
            />
          </Box>
        ),
        testID: 'CardProductIntroduction_activate_your_card',
      },
    ],
    [t],
  )

  const onPress = async (): Promise<void> => {
    try {
      setBusy(true)
      await onComplete()
    } catch (e) {
      logOfferApplicationError(e, 'Error while completing card product introduction')
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <ProductIntroduction
      title={t('GetTheCreditLimit', {
        creditLimit: convertToDollarAmt(balanceLimit, false),
      })}
      subtitle={t('monthlyFee', {
        monthlyFee: convertToDollarAmt(monthlyFee, false),
      })}
      steps={steps}
      primaryButton={{
        text: t('StartByLinking'),
        onPress,
        disabled: busy,
      }}
      testIDValues={{
        page: 'CardProductIntroduction_page',
        title: 'CardProductIntroduction_title',
        subTitle: 'CardProductIntroduction_subtitle',
        primaryButton: 'CardProductIntroduction_primary_button',
      }}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    height: 218,
    width: 218,
  },
})
