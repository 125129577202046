import AddBankLink from 'src/products/general/AddBankLink/AddBankLinkMPOContainer'
import IDScanMPOContainer from 'src/products/loans/IDScan/IDScanMPOContainer'
import {LivenessMPOContainer} from 'src/products/general/Liveness/LivenessMPOContainer'
import LoanProcessing from 'src/products/loans/LoanProcessing/LoanProcessing'
import LoanProcessingNotNow from 'src/products/loans/LoanProcessing/LoanProcessingNotNow'
import {LoanAmountSelectionMPOContainer} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionMPOContainer'
import {LoanInitialApplicationSubmissionMPOContainer} from 'src/products/loans/LoanApplicationSubmission/LoanInitialApplicationSubmission/LoanInitialApplicationSubmissionMPOContainer'
import MarketingSurvey from 'src/products/general/MarketingSurvey/MarketingSurveyMPOContainer'
import {LoanProductIntroductionMPOContainer as LoanProductIntroduction} from 'src/products/loans/LoanProductIntroduction/LoanProductIntroductionMPOContainer'
import {OnboardingModuleDefinitions} from 'src/flows/types'
import {PrimaryAccountSelectionMPOContainer as PrimaryAccountSelection} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionMPOContainer'
import {SSNCollectionMPOContainer} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionMPOContainer'
import {EmailVerificationMPOContainer} from 'src/products/loans/EmailVerification/EmailVerificationMPOContainer'

export const LoanApplicationModuleDefinitions: Partial<OnboardingModuleDefinitions> = {
  LOAN_SSN: {
    screen: {
      path: 'ssn',
    },
    Component: SSNCollectionMPOContainer,
  },
  LOAN_SELECTED: {
    screen: {
      path: 'loan/info',
    },
    Component: LoanProductIntroduction,
  },
  LOAN_BANK_LINK: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'LOAN_BANK_LINK',
        screen: {
          path: 'banking',
        },
        Component: AddBankLink,
        options: {
          headerBackgroundContainerStyle: {
            backgroundColor: 'transparent',
          },
        },
      },
      {
        name: 'LOAN_PRIMARY_ACCOUNT_SELECTION',
        screen: {
          path: 'banking/primary-account',
        },
        Component: PrimaryAccountSelection,
      },
    ],
  },
  LOAN_HOW_MUCH: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'LOAN_HOW_MUCH',
        screen: {
          path: 'loan/amount',
        },
        Component: LoanAmountSelectionMPOContainer,
      },
      {
        name: 'LOAN_MARKETING_SURVEY',
        screen: {
          path: 'loan/how-did-you-hear-about-us',
        },
        Component: MarketingSurvey,
      },
    ],
  },
  LOAN_SELFIE: {
    screen: {
      path: 'selfie',
    },
    Component: LivenessMPOContainer,
  },
  LOAN_PHOTO_ID: {
    screen: {
      path: 'id',
    },
    Component: IDScanMPOContainer,
  },
  LOAN_EMAIL_VERIFICATION: {
    screen: {
      path: 'email/verify',
    },
    Component: EmailVerificationMPOContainer,
  },
  LOAN_SUBMISSION: {
    screen: {
      path: 'loan/submit',
    },
    Component: LoanInitialApplicationSubmissionMPOContainer,
  },
  LOAN_PROCESSING: {
    type: 'MultiLinear',
    steps: [
      {
        name: 'LOAN_PROCESSING',
        screen: {
          path: 'loan/syncing',
        },
        Component: LoanProcessing,
        headerLeftButton: 'None',
      },
      {
        name: 'LOAN_APPROVED_NOT_NOW',
        screen: {
          path: 'loan/cancel',
        },
        Component: LoanProcessingNotNow,
        headerLeftButton: 'Menu',
      },
    ],
  },
}
