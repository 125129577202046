import React, {FC, useCallback, useState} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'
import {logErrorAndShowException} from 'src/lib/errors'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {usePfDispatch} from 'src/store/utils'
import {LivenessTemplate, Phase} from 'src/products/general/Liveness/LivenessTemplate'

type Props = {
  onComplete: () => Promise<void> | void
}

export const LivenessReduxContainer: FC<Props> = ({onComplete}) => {
  const [phase, setPhase] = useState<Phase>(Phase.Camera)
  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.take_a_selfie_screen_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  useFocusEffect(
    useCallback(() => {
      if (NavPageState.IsLivenessCaptured()) {
        if (phase !== Phase.Complete) {
          setPhase(Phase.Complete)
        }
      } else {
        if (phase !== Phase.Camera) {
          setPhase(Phase.Camera)
        }
      }
    }, [phase]),
  )

  const updateUserSelfie = async (): Promise<void> => {
    try {
      await dispatch(UserStateRefresh())
    } catch (e) {
      if (e instanceof Error) {
        void logErrorAndShowException(e, 'Error while trying to update user on Liveness screen')
      }
    }
  }

  const onLivenessConfirm = async (): Promise<void> => {
    await updateUserSelfie()
    await onComplete()
  }

  return (
    <LivenessTemplate onLivenessConfirm={onLivenessConfirm} onComplete={onComplete} phase={phase} />
  )
}
