import {
  CardAccountOfferAvailabilityStatus,
  CardAccountOfferInfo,
  CardAccountStatusCode,
  CardAccountStatuses,
  CardAccountSubStatus,
  ProductOffer,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  ProductsQueryAllEligibleOffersType,
  ProductsQueryAllIneligibleOffersType,
  ProductsQueryEligibleLoanOfferInfo,
} from 'src/workflows/types'

/**
 * Active cards users and deactivated cards users with an outstanding balance can view the card dashboard.
 */
export const canViewCardDashboard = (cardAccountStatus?: Partial<CardAccountStatuses>): boolean => {
  const isActiveCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Active
  const isDeactivatedCardAccount = cardAccountStatus?.code === CardAccountStatusCode.Deactivated
  const isDeactivatedWithOutstandingBalance =
    cardAccountStatus?.subStatus === CardAccountSubStatus.DeactivatedWithBalance

  return isActiveCardAccount || (isDeactivatedCardAccount && isDeactivatedWithOutstandingBalance)
}

export const getLoanOfferInfo = (
  allOffers: ProductsQueryAllEligibleOffersType,
): ProductsQueryEligibleLoanOfferInfo | undefined => {
  return allOffers.find(
    (offer): offer is ProductsQueryEligibleLoanOfferInfo => offer.__typename === 'LoanOfferInfo',
  )
}

export const getCardOfferInfo = (
  allOffers: Partial<ProductOffer>[],
): CardAccountOfferInfo | undefined => {
  return allOffers.find(
    (offer): offer is CardAccountOfferInfo => offer.__typename === 'CardAccountOfferInfo',
  )
}

export const isInCardsWaitlist = (
  ineligibleOfferInfos?: ProductsQueryAllIneligibleOffersType,
): boolean => {
  return (ineligibleOfferInfos || []).some(
    (offer) =>
      offer.__typename === 'CardAccountOfferInfo' &&
      offer?.cardOfferAvailabilityStatus === CardAccountOfferAvailabilityStatus.IneligibleLocation,
  )
}
