import React, {FC, useMemo} from 'react'
import WebView from 'react-native-webview'
import {WebViewSourceUri} from 'react-native-webview/lib/WebViewTypes'
import {StyleSheet, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'

import {isDeviceWeb} from 'src/lib/utils/platform'
import {ampli} from 'src/lib/Analytics/ampli'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'

type Props = {
  source?: WebViewSourceUri
  token?: string
}

const zendeskAuthenticationUrl = `https://possiblefinance1722620762.zendesk.com/access/jwt?return_to=https://possiblefinance1722620762.zendesk.com/hc/en-us/`

const continueLabel = 'Continue to Zendesk'

const ZendeskSsoForm: FC<Props> = (props) => {
  const {token, source} = props

  const handleOnClickCta = (): void => {
    sendAnalyticEvent(ampli.helpCenterRedirectSelected.bind(ampli))
  }

  const renderForm = useMemo(() => {
    if (isDeviceWeb()) {
      return (
        <View testID="Help-Center-Form-Web">
          <form method="post" action={zendeskAuthenticationUrl}>
            <input type="hidden" name="jwt" value={token} />
            <button type="submit" style={styles.submitButton} onClick={handleOnClickCta}>
              {continueLabel}
            </button>
          </form>
        </View>
      )
    }

    return (
      <WebView
        testID="Pf-Webview-Id"
        originWhitelist={['*']}
        javaScriptEnabled
        onNavigationStateChange={(e): void => {
          if (e.url === zendeskAuthenticationUrl) {
            handleOnClickCta()
          }
        }}
        source={
          source ?? {
            html: `
          <form method="post" action="${zendeskAuthenticationUrl}">
            <input type="hidden" name="jwt" value="${token}" />
            <button type="submit" style="font-size:40px; margin: 40px">${continueLabel}</button>
          </form>
          `,
          }
        }
      />
    )
  }, [source, token])

  return <SafeAreaView style={styles.webView}>{renderForm}</SafeAreaView>
}

export {ZendeskSsoForm}

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    width: '100%',
  },
  submitButton: {
    margin: 40,
  },
})
