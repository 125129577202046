import React, {FC} from 'react'

import {CardActivatedGQLContainer} from 'src/products/card/Activation/CardActivated/CardActivatedGQLContainer/CardActivatedGQLContainer'
import {WorkflowScreenProps} from 'src/workflows/types'

export const CardActivatedWorkflowContainer: FC<WorkflowScreenProps> = ({
  onScreenComplete: handleOnScreenComplete,
}) => {
  return <CardActivatedGQLContainer onComplete={handleOnScreenComplete} />
}
