import React, {FC, ReactElement} from 'react'
import {View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type SummaryLineItemProps = {
  label: string | ReactElement
  value: string
}

const SummaryLineItem: FC<SummaryLineItemProps> = (props) => {
  const {label, value} = props

  return (
    <View>
      <PFText color={NamedColors.SILVER} variant={'label_sm'}>
        {label}
      </PFText>
      <PFText variant={'p'}>{value}</PFText>
    </View>
  )
}

export {SummaryLineItem}
