import React, {FC, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber'
import {useTranslation} from 'react-i18next'

import {ShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {PFPhoneNumberInput} from 'src/designSystem/components/molecules/PFPhoneNumberInput/PFPhoneNumberInput'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {AppEventCategory, AppEventName} from 'src/lib/Analytics/app_events'
import {testProps} from 'src/lib/utils/tests.utils'

const phoneUtil = PhoneNumberUtil.getInstance()

type Props = {
  appEventCompleted: AppEventName
  appEventCategory: AppEventCategory
  onButtonPress: (
    phoneNumber: string,
    phoneNumberFormatted: string,
    formattedNew: string,
  ) => Promise<void>
  duplicateNumber?: string
  actionDisabled: boolean
  buttonLabel: string
  mainBody: string
  mainTitle: string
  testID?: string
  showNoPhoneLinkAction?: () => void
}

const PhoneNumberEntryTemplate: FC<Props> = (props) => {
  const {
    onButtonPress,
    appEventCompleted,
    appEventCategory,
    duplicateNumber,
    actionDisabled,
    buttonLabel,
    showNoPhoneLinkAction: handleShowNoPhoneLinkAction,
    testID,
    mainTitle,
    mainBody,
  } = props
  const {t} = useTranslation('PhoneNumberEntry')
  const [phoneNumberText, setPhoneNumberText] = useState<string>('')
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false)
  let phoneNumber = ''
  let phoneNumberDisplayFormatted = ''

  const verifyPhoneNumberIsValid = (): boolean => {
    let phoneNumberValid = false

    if (phoneNumberText === '') {
      throw new Error(t('PhoneNumberFormatError'))
    }
    try {
      const phone_number_parsed = phoneUtil.parse(phoneNumberText, 'US')
      if (phoneUtil.isValidNumber(phone_number_parsed)) {
        /*keeping some consistency on passing the argument to the APIs with E164*/
        phoneNumber = phoneUtil.format(phone_number_parsed, PhoneNumberFormat.E164)
        phoneNumberDisplayFormatted = phoneUtil.format(
          phone_number_parsed,
          PhoneNumberFormat.INTERNATIONAL,
        )
        phoneNumberValid = true
      }
    } catch (e) {
      throw new Error(t('PhoneNumberNotValidError', {phoneNumberText}))
    }
    return phoneNumberValid
  }

  const onCellPhoneNumberReady = (): void => {
    try {
      if (verifyPhoneNumberIsValid()) {
        onButtonPress(phoneNumber, phoneNumberDisplayFormatted, phoneNumberText)
      }
    } catch (e) {
      ShowException(e)
    }
  }

  const onPress = (): void => {
    TrackAppEvent(appEventCompleted, appEventCategory)
    onCellPhoneNumberReady()
  }

  const getActionDisabled = (): boolean => {
    return !phoneIsValid || duplicateNumber === phoneNumberText || actionDisabled
  }

  const action = {
    onPress: () => onPress(),
    text: buttonLabel,
    disabled: getActionDisabled(),
    loading: actionDisabled,
    ...testProps('PhoneNumberEntrySubmitButton'),
  }

  const getNoPhoneCopy = (): JSX.Element | undefined => {
    return handleShowNoPhoneLinkAction ? (
      <View style={styles.noPhone} accessible={false}>
        <PFText variant={'p'} textAlign={'center'}>
          {t('NoPhone')}
        </PFText>
        <SvgLink
          onPress={handleShowNoPhoneLinkAction}
          linkType={'inline'}
          disabled={actionDisabled}
          testID={'Enter_Temp_Password_Link'}
          linkText={t('EnterTempPassword')}
        />
      </View>
    ) : undefined
  }

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      testID={testID}
      buttonProps={buttonLockupProperties(action)}
      title={mainTitle}
      description={mainBody}
      enabledKeyboardAvoidingView
    >
      <View style={styles.phoneEntry}>
        <PFPhoneNumberInput
          testID={'PhoneNumberEntryInput'}
          value={phoneNumberText}
          onGetFormattedPhoneNumber={(formattedNumber): void => setPhoneNumberText(formattedNumber)}
          onPhoneNumberIsValid={(isValid): void => setPhoneIsValid(isValid)}
        />
        {getNoPhoneCopy()}
      </View>
    </Page>
  )
}

export default PhoneNumberEntryTemplate

const styles = StyleSheet.create({
  noPhone: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
  },
  phoneEntry: {
    flex: 1,
    width: '100%',
  },
})
