import {HeaderBackButtonProps} from '@react-navigation/elements'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {ReactNode} from 'react'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {Color} from 'src/designSystem/types'

import {ModuleNodeHeaderButton} from 'src/flows/types'
import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import HeaderLogoutButton from 'src/nav/Header/HeaderLogoutButton/HeaderLogoutButton'
import {HeaderMenuButton} from 'src/nav/Header/HeaderMenuButton/HeaderMenuButton'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export const headerButtonOptionFactory = (
  headerButton: ModuleNodeHeaderButton | undefined,
  navigation: StackNavigationProp<MainStackParamList, any>,
) => {
  if (!headerButton) {
    return undefined
  }

  const components: Record<ModuleNodeHeaderButton, (props: HeaderBackButtonProps) => ReactNode> = {
    Logout: function LogoutButton(props) {
      return (
        <Box paddingHorizontal="medium" paddingVertical="tiny">
          <HeaderLogoutButton color={props.tintColor as Color} />
        </Box>
      )
    },

    Menu: function MenuButton() {
      return (
        <Box paddingHorizontal="medium" paddingVertical="small">
          <HeaderMenuButton navigation={navigation} size="medium" />
        </Box>
      )
    },

    Back: function BackButton(props) {
      return (
        <Box paddingHorizontal="medium" paddingVertical="small">
          <HeaderBackButton
            navigation={navigation}
            size="medium"
            color={props.tintColor as Color}
          />
        </Box>
      )
    },

    None: function NoneButton() {
      return null
    },

    NoneNoBreak: function NoneButton() {
      return null
    },
  }

  return components[headerButton]
}
