import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'

import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ErrorAlertLightbox} from 'src/products/loans/Errors/ErrorAlertLightbox'

export function ShowExceptionModal(
  title: string,
  body: string,
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>,
): void {
  const modal = (
    <ErrorAlertLightbox
      text={body}
      title={title}
      close={(): void => navigation?.pop()}
      navigation={navigation}
    />
  )
  ShowLightbox(() => modal, false)
}
