import React, {FC, useCallback} from 'react'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {PushPage} from 'src/navigation/NavHelper'
import Card from 'src/assets/illustrations/Card_Character 1_Arrows.svg'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export const CardActivatedTile: FC<BaseCardProps> = ({
  navigation,
  loading,
  tileMarginHorizontal,
  tileRadius,
}) => {
  usePageViewedAnalytics({
    eventName: CardEvents.activate_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const onPress = useCallback(() => {
    TrackAppEvent(CardEvents.activate_dashboard_card_cta, AppEvents.Category.Card)
    PushPage(navigation, 'CardDashboard')
  }, [navigation])
  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText="YourCardIsActive"
      buttonText="MyCard"
      content=""
      img={
        <PFSvg
          SvgUri={Card}
          svgProps={{
            height: 79,
            width: 100,
          }}
        />
      }
      onPress={onPress}
      loading={loading}
    />
  )
}
