import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'

type Props = {
  onConfirm: () => void
  close: () => void
  onCancel: () => void
  amount: string
}

const PayNowConfirmation: FC<Props> = (props) => {
  const {onConfirm, close, onCancel, amount} = props
  const {t} = useTranslation(['Reschedule', 'Common'])

  const confirmPressed = () => {
    onConfirm()
    close()
  }

  const cancelPressed = () => {
    onCancel()
    close()
  }

  return (
    <Box padding={'small'} gap={'small'}>
      <PFText variant={'h3'}>{t('PayNow?')}</PFText>
      <PFText variant={'p'}>{t('YouHaveSelectedToMakeASameDayPayment')}</PFText>
      <PFText variant={'h3'}>{amount}</PFText>
      <Box gap={'little'} paddingBottom={'small'}>
        <Button mode="primary" size="medium" onPress={confirmPressed} width={'100%'}>
          {t('PayAmountNow', {amount: amount})}
        </Button>
        <Button mode="secondary" size="medium" onPress={cancelPressed} width={'100%'}>
          {t('Common:NeverMind')}
        </Button>
      </Box>
    </Box>
  )
}

export default PayNowConfirmation
