import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {LoanTilaDisclosureGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureGQLContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'

type LoanTilaDisclosureNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'LoanTilaDisclosure'>,
  'navigation'
>

const LoanTilaDisclosureNavPageStateContainer: FC<LoanTilaDisclosureNavPageStateContainerProps> = (
  props,
) => {
  const {navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.tila_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    const nextPage = NavPageState.GetNextApprovalState(LoanApprovedFlow.LoanTilaDisclosure)
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  return <LoanTilaDisclosureGQLContainer onContinue={handleOnContinue} />
}

export {LoanTilaDisclosureNavPageStateContainer}
