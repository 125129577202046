import {GraphQLFormattedError} from 'graphql'

import {
  LoginResult,
  UserIdentityProviderCallbackProvider,
  UserIdentityProviderCallbackSource,
  UserMfaDeliveryMedium,
  UserRegisterResponse,
} from '@possible/cassandra/src/types/types.public.generated'
import {
  userIdentityProviderCallback,
  userLogin,
  userLoginWithMfa,
  userRegister,
  userResetTemporaryPassword,
} from '@possible/cassandra/src/user/authPublicMethods'

import {
  ErrorResponseCode,
  LoginOrRegisterResult,
  LoginResponse,
  MobileGatewayApiResponse,
} from 'src/api/MobileGatewayAPI/MobileGatewayApi.types'
import {initUserAuth} from 'src/api/actions/client'
import {initializeCassandra} from 'src/cassandra'
import PosAnalytics from 'src/lib/Analytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {getRemoteValueString} from 'src/lib/RemoteConfig/methods'
import {GOOGLE_OAUTH} from 'src/lib/RemoteConfig/parameterkeys'
import {GoogleSignin} from 'src/lib/googleSignin'
import Log from 'src/lib/loggingUtil'
import {PfDispatch} from 'src/store/types'
import {setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {LoginMethodType} from 'src/api/lib/UserProperties/LoginMethodUserProperty.types'
import i18n from 'src/lib/localization/i18n'
import {getDeviceDetails} from 'src/lib/getDeviceDetails'

const getFirstErrorSubCode = (
  errors: readonly GraphQLFormattedError[] | undefined,
): string | undefined => {
  const extensions = errors?.[0]?.extensions
  if (extensions) {
    if ('errorSubCode' in extensions) {
      return extensions.errorSubCode as string
    }
  }
  return undefined
}

function SuccessfulLoginAnalytics(data: string, method?: string) {
  PosAnalytics.Login()
  TrackAppEvent(AppEvents.Name.login_succeeded, AppEvents.Category.Login, {
    value: data,
    method,
    [GOOGLE_OAUTH]: getRemoteValueString(GOOGLE_OAUTH),
  })
}

function FailedLoginAnalytics(
  data: string,
  response: MobileGatewayApiResponse,
  method?: string,
): void {
  TrackAppEvent(AppEvents.Name.login_failed, AppEvents.Category.Login, {
    value: data,
    method,
    errorCode: response.getErrorCode(),
  })
}

export const verifyAuthentication = async (args: {
  response: MobileGatewayApiResponse | LoginResult | UserRegisterResponse
  dispatch: PfDispatch
  analyticsData: string
  method: LoginMethodType
  analyticsMethod?: string
}): Promise<true | undefined> => {
  const {response, dispatch, analyticsData, method, analyticsMethod} = args
  if ('userId' in response && response.userId && response.token) {
    SuccessfulLoginAnalytics(analyticsData, analyticsMethod)

    await initializeCassandra(dispatch, response.token)

    await initUserAuth(response.userId, response.token, dispatch)
    await setUserProperty('login_method', {method})

    return true
  }

  if (
    'getErrorCode' in response &&
    response.getErrorCode() !== ErrorResponseCode.ConflictingAccount
  ) {
    FailedLoginAnalytics(analyticsData, response, analyticsMethod)
  }
}

export async function registerUser(
  email: string,
  password: string,
  stateSelected?: string,
): Promise<LoginResponse> {
  try {
    const deviceDetails = await getDeviceDetails()
    const resp = await userRegister({email, password, stateSelected, deviceDetails})
    return buildLoginResponse(
      resp.data?.userRegister,
      resp.errors?.[0]?.message,
      getFirstErrorSubCode(resp.errors),
    )
  } catch (e) {
    return buildLoginResponse(undefined, `${e}`)
  }
}

export function EmailPasswordSignup(email: string, password: string, stateSelected?: string) {
  return async (dispatch: PfDispatch): Promise<LoginResponse> => {
    const analyticsData = 'signup'
    const response = await registerUser(email, password, stateSelected)

    await verifyAuthentication({
      response,
      dispatch,
      analyticsData,
      method: 'emailpwd',
    })

    return response
  }
}

function buildLoginResponse(
  loginResult?: LoginOrRegisterResult,
  errorStr?: string,
  errorSubCode?: string,
): LoginResponse {
  if (loginResult) {
    return {
      throwIfError: () => undefined,
      hasError: () => false,
      getErrorStr: () => undefined,
      getErrorCode: () => undefined,
      userId: loginResult.userId,
      token: loginResult.token,
      mfaDeliveryMedium: loginResult.mfaDeliveryMedium,
    }
  } else {
    const errorCode =
      errorSubCode === 'register_email_exists' ? ErrorResponseCode.ConflictingAccount : errorSubCode
    return {
      throwIfError: () => {
        throw Error(errorStr || 'Unknown error')
      },
      hasError: () => true,
      getErrorStr: () => errorStr || 'Unknown error',
      getErrorCode: () => errorCode || 'unknown',
    }
  }
}

export async function loginUser(email: string, password: string): Promise<LoginResponse> {
  try {
    const deviceDetails = await getDeviceDetails()
    const resp = await userLogin({email, password, deviceDetails})
    return buildLoginResponse(
      resp.data?.userLogin,
      resp.errors?.[0]?.message,
      getFirstErrorSubCode(resp.errors),
    )
  } catch (e) {
    return buildLoginResponse(undefined, i18n.t('Common:GenericError'))
  }
}

export function EmailPasswordLogin(email: string, password: string) {
  return async (dispatch: PfDispatch): Promise<LoginResponse> => {
    const analyticsData = 'login'

    const response = await loginUser(email, password)

    if (!response.mfaDeliveryMedium) {
      await verifyAuthentication({
        response,
        dispatch,
        analyticsData,
        method: 'emailpwd',
      })
    }

    return response
  }
}

export async function loginUserWithMfa(
  email: string,
  password: string,
  otp: string,
  mfaDeliveryMedium: UserMfaDeliveryMedium,
): Promise<LoginResponse> {
  try {
    const deviceDetails = await getDeviceDetails()
    const resp = await userLoginWithMfa({
      email,
      password,
      otp,
      mfaDeliveryMedium,
      deviceDetails,
    })
    return buildLoginResponse(
      resp.data?.userLoginWithMfa,
      resp.errors?.[0]?.message,
      getFirstErrorSubCode(resp.errors),
    )
  } catch (e) {
    return buildLoginResponse(undefined, `${e}`)
  }
}

export function MfaLogin(
  email: string,
  password: string,
  otp: string,
  deliveryMedium: UserMfaDeliveryMedium,
) {
  return async (dispatch: PfDispatch): Promise<LoginResponse> => {
    const analyticsData = 'login'
    const response = await loginUserWithMfa(
      email,
      password,
      otp,
      deliveryMedium.toUpperCase() as UserMfaDeliveryMedium,
    )

    await verifyAuthentication({
      response,
      dispatch,
      analyticsData,
      method: 'other',
    })

    return response
  }
}

export async function resetUserTemporaryPassword(
  email: string,
  temporaryPassword: string,
  proposedPassword: string,
): Promise<LoginResponse> {
  try {
    const deviceDetails = await getDeviceDetails()
    const resp = await userResetTemporaryPassword({
      email,
      temporaryPassword,
      proposedPassword,
      deviceDetails,
    })
    return buildLoginResponse(
      resp.data?.userResetTemporaryPassword,
      resp.errors?.[0]?.message,
      getFirstErrorSubCode(resp.errors),
    )
  } catch (e) {
    return buildLoginResponse(undefined, `${e}`)
  }
}

export function ResetPasswordLogin(
  email: string,
  temporaryPassword: string,
  proposedPassword: string,
) {
  return async (dispatch: PfDispatch): Promise<LoginResponse> => {
    const analyticsData = 'reset'
    const response = await resetUserTemporaryPassword(email, temporaryPassword, proposedPassword)

    await verifyAuthentication({
      response,
      dispatch,
      analyticsData,
      method: 'other',
    })

    return response
  }
}

export async function identityProviderCallback(
  code: string,
  source: UserIdentityProviderCallbackSource,
  provider: UserIdentityProviderCallbackProvider,
): Promise<LoginResponse> {
  try {
    const deviceDetails = await getDeviceDetails()
    const resp = await userIdentityProviderCallback({code, source, provider, deviceDetails})
    return buildLoginResponse(
      resp.data?.userIdentityProviderCallback,
      resp.errors?.[0]?.message,
      getFirstErrorSubCode(resp.errors),
    )
  } catch (e) {
    return buildLoginResponse(undefined, `${e}`)
  }
}

const googleSigninAction = async (): Promise<LoginResponse> => {
  const webClientId = '1049650429468-e7p00u8o3jvbjhsp01r38do4egb94j43.apps.googleusercontent.com'

  GoogleSignin.configure({
    webClientId: webClientId,
    offlineAccess: true,
  })

  const hasPlayServices = await GoogleSignin.hasPlayServices()

  const isSignedIn = await GoogleSignin.isSignedIn()
  Log.log({'googleauth isSignedIn:': isSignedIn})

  // Log the user out in case they need to select a different account. Otherwise they get
  // a modal that doesn't let them pick a new account since they're already logged in.
  if (isSignedIn) {
    await GoogleSignin.signOut()
  }

  if (hasPlayServices) {
    let userInfo
    try {
      userInfo = await GoogleSignin.signIn()
    } catch (e) {
      const error = `User backed out of login process: ${e}`
      Log.log(error)
      return buildLoginResponse(undefined, error)
    }

    Log.log({'googleauth response:': userInfo})

    return await identityProviderCallback(
      userInfo.serverAuthCode,
      UserIdentityProviderCallbackSource.App,
      UserIdentityProviderCallbackProvider.Google,
    )
  } else {
    const error = 'Play services missing!'
    Log.log(error)
    return buildLoginResponse(undefined, error)
  }
}

export type SocialProvidersType = 'google'

export function socialSignin(signUp: boolean, provider: SocialProvidersType) {
  return async (dispatch: PfDispatch): Promise<LoginResponse> => {
    let response: LoginResponse

    let eventName
    const providerSigninAction: () => Promise<LoginResponse> = googleSigninAction
    const method = 'Google'
    if (provider === 'google') {
      eventName = AppEvents.Name.google_oauth_selected
    }

    TrackAppEvent(eventName, AppEvents.Category.Login)

    try {
      response = await providerSigninAction()
    } catch (e) {
      const error = `${method} sign in Error: ${e}`
      Log.warn(error)
      response = buildLoginResponse(undefined, error)
    }

    const analyticsData = signUp ? 'signup' : 'login'

    await verifyAuthentication({
      response,
      dispatch,
      analyticsData,
      method: 'oauth',
      analyticsMethod: method,
    })

    return response
  }
}
