import React, {FC} from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import moment from 'moment'
import {cloneDeep} from 'lodash'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {paymentRescheduleListingBackground} from 'src/designSystem/semanticColors'
import {Transfer} from 'src/lib/loans/reducers/types'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type PaymentItemProps = {
  hasChange: boolean
  payment: Transfer
  onEdit: (payment: Transfer) => void
  testID?: string
}

const PaymentItem: FC<PaymentItemProps> = (props) => {
  const {hasChange, payment, onEdit, testID} = props
  const {t} = useTranslation('Reschedule')

  const renderStatus = (): JSX.Element => {
    const status = paymentStatus()
    let c: React.ReactNode | undefined = undefined

    const color = hasChange ? 'textPrimary' : 'link'

    switch (status) {
      case 'completed':
        c = (
          <View>
            <PFText variant={'p'} color={color}>
              {t('Paid')}
            </PFText>
          </View>
        )
        break
      case 'pending':
        c = <SvgIcon name={'edit'} colorVariant={'default'} size={'medium'} />
        break
      case 'suspended':
      case 'failed':
        c = <SvgIcon name={'edit'} colorVariant={'error'} size={'medium'} />

        break
      case 'in_progress':
        c = <SvgIcon name={'edit'} colorVariant={'warning'} size={'medium'} />

        break
      default:
      /* no-op */
    }

    return <View style={styles.statusView}>{c}</View>
  }

  const canEdit = (): boolean => {
    switch (payment.status) {
      case 'pending':
      case 'failed':
      case 'suspended':
        return true
      default:
        return false
    }
  }

  const paymentStatus = (): string => {
    if (!payment) return ''
    return payment.status
  }

  const amount = (): string => {
    try {
      return payment.amount.toFixed(2)
    } catch (e) {
      return '0.00'
    }
  }

  const date = moment(payment.rescheduledDate)

  const originalDate = moment(payment.originalDate)

  const dateString = date.format('MMM Do')
  const secondRow = !originalDate.isSame(date) ? (
    <View style={[styles.lineView, styles.secondRow]}>
      <PFText variant={'p'}>
        {t('InitiallyScheduledForDate', {date: originalDate.format('MMM Do')})}
      </PFText>
    </View>
  ) : null

  return (
    <TouchableWithoutFeedback
      disabled={!canEdit()}
      onPress={(): void => onEdit(cloneDeep(payment))}
    >
      <View style={styles.rowView}>
        <View style={styles.lineView}>
          <View style={styles.itemView}>
            <PFText variant={'p_semibold'}>{dateString}</PFText>
          </View>
          <View style={styles.amountView}>
            <PFText variant={'p_semibold'}>${amount()}</PFText>
          </View>
          <View style={styles.itemView} testID={testID}>
            {renderStatus()}
          </View>
        </View>
        {secondRow}
      </View>
    </TouchableWithoutFeedback>
  )
}

export default PaymentItem

const styles = StyleSheet.create({
  amountView: {
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  itemView: {
    alignSelf: 'stretch',
    flex: 1,
    justifyContent: 'center',
  },
  lineView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  rowView: {
    backgroundColor: paymentRescheduleListingBackground,
    borderRadius: 4,
    marginVertical: 8,
    padding: 16,
  },
  secondRow: {
    alignSelf: 'flex-start',
    flexBasis: '100%',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'flex-start',
    paddingTop: 8,
    width: '100%',
  },
  statusView: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 8,
  },
})
