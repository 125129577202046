import {StackNavigationProp} from '@react-navigation/stack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {completeUra} from 'src/cassandra'
import {ShowException} from 'src/lib/errors'
import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import URAModalContent from 'src/products/loans/UserRequestedAction/URAModalContent'
import {URAProps} from 'src/products/loans/UserRequestedAction/URAProps'
import {usePfDispatch, usePfSelector} from 'src/store/utils'

type Props = URAProps & {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const InterchangeAccountNumberLightBox: React.FC<Props> = (props) => {
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = usePfDispatch()
  const {t} = useTranslation('Common')

  const {componentId, ura_id, close, navigation} = props

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(ura_id, state))

  useEffect(() => {
    dispatch(
      URAUIUpdateAction(
        [{id: ura_id, componentId: componentId, close: () => close()}],
        'MergeRecord',
      ),
    )
  }, [dispatch, ura_id, componentId, close])

  const onComplete = async (): Promise<void> => {
    try {
      setIsBusy(true)

      await completeUra(ura_id)

      close()
    } catch (e) {
      ShowException(e)
    } finally {
      setIsBusy(false)
    }
  }

  const onCollectBankInfo = (): void => {
    navigation.push('CollectDebitCardNumbersForLoansProduct', {
      associatedLinkedAccountId: ura?.actionData.accountId ? ura?.actionData.accountId : undefined,
      onCompleteFromURA: () => void onComplete(),
    })
  }

  const primary = {
    text: t('Continue'),
    disabled: isBusy,
    onPress: () => {
      onCollectBankInfo()
    },
  }

  return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
}

export {InterchangeAccountNumberLightBox}
