import {initializeChaos} from '@possible/chaos'
import {InitClient} from 'src/api/actions/client'
import {EnvironmentType, initializeCassandra} from 'src/cassandra'
import * as Notifications from 'src/initNotifications'
import Analytics from 'src/lib/Analytics'
import Log from 'src/lib/loggingUtil'
import {init as RealtimeDatabaseInit} from 'src/lib/RealtimeDatabase'
import {init as RemoteConfigInit} from 'src/lib/RemoteConfig'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import NavPageState from 'src/navigation/NavPageState'
import {getPfStore} from 'src/store'

export default async function init(): Promise<void> {
  const pfStore = getPfStore()

  if (pfStore) {
    /* Used to grab the Redux store state
  if (__DEV__) {

    // @ts-ignore
    window.pfStore = pfStore
    // @ts-ignore
    window.dumpState = () => {
      const state = util.inspect(pfStore!.getState(), false, null, true)//last param enable colors
      console.log(state)
    }
  }*/

    Notifications.init()
    NavPageState.init(pfStore)
    await Analytics.init(pfStore)
    RemoteConfigInit() //this calls a method from Analytics so the order is important
    RealtimeDatabaseInit()

    await initializeCassandra(pfStore.dispatch)
    await pfStore.dispatch(InitClient())

    try {
      const environment: EnvironmentType = await getEnvironment()
      if (environment !== EnvironmentType.Prod) {
        Log.info('Initializing @possible/chaos')
        // initialize @possible/chaos package for simulating failures in preprod environments
        initializeChaos({
          store: pfStore,
          chaosSliceKey: 'chaos',
        })
      }
    } catch (e) {
      Log.error(
        e,
        'Failed to get environment in app init. Ignoring error since its only used for Chaos Mode',
      )
    }
  } else {
    Log.error('Somehow the store is not available in AppInit. This should not happen.')
  }
}
