import React, {ReactNode} from 'react'
import {DeepMap, FieldError, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import HookForm, {
  FIELD_VARIANTS,
  formFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {accountNumberFilter, routingNumberFilter} from 'src/lib/bank_account_utils'
import {isValidAccountNumber, isValidRoutingNumber} from 'src/lib/utils/formValidationUtil'
import CheckGraphic from 'src/assets/images/check-graphic-2.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {BankRoutingAccountFormData} from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/BankRoutingAndAccountForm.types'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {PaymentMethodsAlertModals} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals'

export type BankRoutingAndAccountFormPageTemplateProps = {
  /**
   * Can be a custom react element. If title is a string it will be in <PFText variant="h1">.
   */
  title: ReactNode
  /**
   * Can be a custom react element. If description is a string it will be in <PFText variant="p">.
   */
  description?: ReactNode
  onSubmit: (data: BankRoutingAccountFormData) => void | Promise<void>
  onSubmitError?: (errors: DeepMap<BankRoutingAccountFormData, FieldError>) => void
  errorModalReason?: PaymentMethodsAlertModalErrorReason
  onDismissErrorModal: () => void
  // called when rendering throws and is caught by ErrorBoundary
  onErrorBoundary?: (error: Error) => void
  isSubmitting: boolean
  isLoading?: boolean
  showErrorPage?: boolean
  /**
   * If provided the account number entered must end in these four numbers.
   */
  accountNumberMask?: string
  isEditable?: boolean
}

/**
 * Page with a form to allow the user to enter routing and account numbers for a bank account.
 */
const BankRoutingAndAccountFormPageTemplate: React.FC<
  BankRoutingAndAccountFormPageTemplateProps
> = (props: BankRoutingAndAccountFormPageTemplateProps) => {
  const {
    title,
    description,
    onSubmit,
    onSubmitError,
    onErrorBoundary: handleOnErrorBoundary,
    onDismissErrorModal: handleOnDismissErrorModal,
    isSubmitting,
    isLoading,
    showErrorPage,
    errorModalReason,
    accountNumberMask,
    isEditable,
  } = props
  const {t} = useTranslation(['Bank', 'Common'])

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {control, errors, handleSubmit, watch} = useForm<BankRoutingAccountFormData>({
    mode: 'onChange',
    defaultValues: {
      accountNumber: '',
      routingNumber: '',
    },
  })
  const routingNumber = watch('routingNumber')
  const accountNumber = watch('accountNumber')
  const areFieldValuesValid =
    isValidRoutingNumber(routingNumber) && isValidAccountNumber(accountNumber)

  const handleOnSubmit = handleSubmit(
    (data: BankRoutingAccountFormData) => {
      // react-hook-form passes additional event arg that we don't care about so we only provide data to parent
      void onSubmit(data)
    },
    (errors) => {
      onSubmitError?.(errors)
    },
  )

  const routingNumberFormProps: formFieldProps = {
    field: FIELD_VARIANTS.TEXT_FIELD,
    name: 'routingNumber',
    rules: {
      required: t('Common:LabelIsRequired', {
        replace: {
          label: t('RoutingNumber'),
        },
      }),

      validate: (value: string) => {
        return !isValidRoutingNumber(value) ? t('PleaseEnterValidRoutingNumber') : undefined
      },
    },
  }
  const accountNumberFormProps: formFieldProps = {
    field: FIELD_VARIANTS.TEXT_FIELD,
    name: 'accountNumber',
    rules: {
      validate: (value: string) => {
        if (!isValidAccountNumber(value)) {
          return t('PleaseEnterValidAccountNumber', {replace: {label: t('AccountNumber')}})
        }
      },
    },
  }
  const continueBtn: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:Continue'),
      onPress: () => {
        void handleOnSubmit()
      },
      testID: 'BankRoutingAndAccountForm-Continue-Button',
      loading: isSubmitting,
      disabled: isSubmitting || !areFieldValuesValid,
    },
  }

  const titleToUse = typeof title === 'string' ? <PFText variant="h1">{title}</PFText> : title

  const descriptionToUse =
    typeof description === 'string' ? <PFText variant="p">{description}</PFText> : description

  return (
    <BaseTemplate
      onErrorBoundary={handleOnErrorBoundary}
      isLoading={isLoading}
      isError={showErrorPage}
      testID="BankRoutingAndAccountForm"
    >
      <Page variant="generic" buttonProps={continueBtn} smallTopGap titleTextAlignment="center">
        <Box gap={'small'} marginBottom={'small'}>
          <PaymentMethodsAlertModals
            paymentType="ACH"
            reason={errorModalReason}
            onOkay={handleOnDismissErrorModal}
          />

          {titleToUse}

          {descriptionToUse ?? null}

          <HookForm control={control} errors={errors}>
            <PFTextInput
              formProps={routingNumberFormProps}
              keyboardType="numeric"
              label={t('RoutingNumber')}
              changeFilter={routingNumberFilter}
              testID="BankRoutingAndAccountForm-RoutingNumber-Input"
              editable={isEditable}
              editableStyle={isEditable}
            />
            <PFTextInput
              formProps={accountNumberFormProps}
              keyboardType="numeric"
              label={
                accountNumberMask
                  ? t('AccountNumberEndingIn', {mask: accountNumberMask})
                  : t('AccountNumber')
              }
              changeFilter={accountNumberFilter}
              testID="BankRoutingAndAccountForm-AccountNumber-Input"
              editable={isEditable}
              editableStyle={isEditable}
            />
          </HookForm>

          <PFSvgContain svg={CheckGraphic} width={'100%'} />
        </Box>
      </Page>
    </BaseTemplate>
  )
}

export {BankRoutingAndAccountFormPageTemplate}
