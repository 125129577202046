import React, {FC, useCallback, useState} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {EmailVerificationTemplate} from 'src/products/loans/EmailVerification/EmailVerificationTemplate'
import {usePfSelector, usePfDispatch} from 'src/store/utils'
import {isEmailVerifiedSelector, userEmailSelector} from 'src/lib/user/selector'
import {onboardingEmailCodeSentSelector} from 'src/lib/onboarding/selectors'
import {onboardingEmailCodeSent} from 'src/lib/onboarding/slice'
import {UserVerifyEmailSendCodeMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {ModifyEmailSendCodeMedium} from '@possible/cassandra/src/types/types.mobile.generated'
import {ShowException} from 'src/lib/errors'
import {userVerifyEmailConfirm} from '@possible/cassandra/src/user/authPublicMethods'
import {EmailVerificationIsVerifiedTemplate} from 'src/products/loans/EmailVerification/EmailVerificationIsVerifiedTemplate'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

export type EmailVerificationRESTContainerProps = {
  onEditEmail?: () => void
  onResendCodeTracking: () => void
  onCodeSubmitted: () => Promise<void> | void
  onContinueAfterIsVerified?: () => Promise<void>
  withoutSendCodeOverlay?: boolean
}

const EmailVerificationRESTContainer: FC<EmailVerificationRESTContainerProps> = ({
  onEditEmail,
  onResendCodeTracking,
  onCodeSubmitted,
  onContinueAfterIsVerified,
  withoutSendCodeOverlay = false,
}) => {
  const email = usePfSelector(userEmailSelector) ?? ''
  const isCodeSent = usePfSelector(onboardingEmailCodeSentSelector)
  const isEmailVerified = usePfSelector(isEmailVerifiedSelector) ?? false
  const dispatch = usePfDispatch()

  const [isContinueBusy, setIsContinueBusy] = useState<boolean>(false)

  /**
   * Send Code To Email overlay.
   */

  const [shouldShowSendCodeOverlay, setShouldShowSendCodeOverlay] = useState<boolean>(false)

  // Supposedly this will prevent the overlay showing up when React Navigation has
  // this component mounted due to back-button history but not displayed.
  // Probably, we shouldn't be using an overlay at all here.
  useFocusEffect(
    useCallback(() => {
      // Show overlay to send a code if code has not been sent and email not verified
      if (!isCodeSent && !isEmailVerified && !withoutSendCodeOverlay) {
        setShouldShowSendCodeOverlay(true)
      }
    }, [isCodeSent, isEmailVerified, withoutSendCodeOverlay]),
  )

  // Runs when user chooses to send a code to the email shown in the overlay
  const handleOnSendCode = async (): Promise<boolean> => {
    setShouldShowSendCodeOverlay(false)
    dispatch(onboardingEmailCodeSent())

    try {
      const isSuccess: boolean | undefined = await UserVerifyEmailSendCodeMutation({
        verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
      })

      if (!isSuccess) {
        throw Error('Could not send email code verification')
      }

      return true
    } catch (e) {
      logOfferApplicationError(e, 'EmailVerificationRESTContainer send email code error')
      ShowException(e)
      return false
    }
  }

  // Runs when user presses edit email button in overlay
  const handleOnCloseOverlayAndEditEmail = (): void => {
    setShouldShowSendCodeOverlay(false)
    onEditEmail?.()
  }

  /**
   * Submit code screen.
   */

  const handleOnResendCode = async (): Promise<boolean> => {
    onResendCodeTracking()
    return handleOnSendCode()
  }

  const handleOnSubmitCode = async (code: string): Promise<void> => {
    try {
      setIsContinueBusy(true)

      if (!email) {
        throw new Error('Email undefined for email verification')
      }

      const isSuccess: boolean | undefined = await userVerifyEmailConfirm({
        email,
        verificationCode: code,
      })

      if (!isSuccess) {
        throw Error('Failed to verifiy email')
      }

      await dispatch(UserStateRefresh())
      await onCodeSubmitted()
    } catch (e) {
      logOfferApplicationError(e, 'EmailVerificationRESTContainer verifyEmailCode error')
      ShowException(e)
    } finally {
      setIsContinueBusy(false)
    }
  }

  if (isEmailVerified && onContinueAfterIsVerified) {
    const handleOnContinuePressed = async (): Promise<void> => {
      setIsContinueBusy(true)
      await onContinueAfterIsVerified()
      setIsContinueBusy(false)
    }
    return (
      <EmailVerificationIsVerifiedTemplate
        onContinuePressed={handleOnContinuePressed}
        isContinueBusy={isContinueBusy}
      />
    )
  }

  return (
    <EmailVerificationTemplate
      email={email}
      onSendCode={handleOnSendCode}
      onEditEmail={handleOnCloseOverlayAndEditEmail}
      onResendCode={handleOnResendCode}
      onSubmitCode={handleOnSubmitCode}
      showSendCodeOverlay={shouldShowSendCodeOverlay}
    />
  )
}

export {EmailVerificationRESTContainer}
