import React, {FC} from 'react'

import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {LoanSelectionCompleted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {LoansStateChange} from 'src/lib/loans/actions'
import {repeatLoanSelector} from 'src/lib/loans/selector'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {possibleStateLicensesURI} from 'src/navigation/WebLinks'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {LoanAmountSelectionDocument} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelection.gqls'
import {LoanAmountSelectionBaseProps} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelection.types'
import {
  LoanAmountSelectionTemplate,
  LoanAmountSelectionTemplateProps,
} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionTemplate'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {useLoanAmountSelectionData} from 'src/products/loans/LoanSelection/LoanAmountSelection/useLoanAmountSelectionData'

export type LoanAmountSelectionRESTContainerProps = Pick<
  LoanAmountSelectionTemplateProps,
  'navigation'
> &
  LoanAmountSelectionBaseProps

/**
 * LoanAmountSelection screen REST container that gets all data necessary
 * from redux selectors which get their data from REST API.
 */
const LoanAmountSelectionRESTContainer: FC<LoanAmountSelectionRESTContainerProps> = (props) => {
  const {navigation, onComplete} = props
  const dispatch = usePfDispatch()

  const [submitLoanAmountMutation] = useCassandraMutation(LoanAmountSelectionDocument)

  const {
    isLoading: isLoadingLoanAmountSelectionData,
    error: loanAmountSelectionDataError,
    handleRetry: handleLoanAmountSelectionDataRetry,
    prequalificationAmount,
    loanUsStateAbv,
    loanTerms,
  } = useLoanAmountSelectionData()

  const abFlaggedStatesThatCanApply: string[] = usePfSelector(abFlaggedStatesEnabled)
  const isRepeatLoan: boolean = usePfSelector(repeatLoanSelector)

  if (loanAmountSelectionDataError) {
    return (
      <ErrorTemplate
        onRetry={handleLoanAmountSelectionDataRetry}
        error={loanAmountSelectionDataError}
      />
    )
  }

  const handleOnViewStateFeeSchedules = (): void => {
    OpenWebPage({
      uri: possibleStateLicensesURI(),
    })
  }

  /**
   * After us selects amount and presses "Continue" we save it to me.onboarding.loans.amountSelected and move on with onComplete().
   */
  const handleOnContinueAfterAmountSelected = async (args: {
    selectedLoanAmount: number
  }): Promise<void> => {
    try {
      const {selectedLoanAmount} = args
      TrackAppEvent(
        AppEvents.Name.loan_amount_selected,
        isRepeatLoan ? AppEvents.Category.Reapplication : AppEvents.Category.InitialApplication,
        {
          value: selectedLoanAmount,
          loanTermsMinAmount: loanTerms?.minimumAmount,
          loanTermsMaxAmount: loanTerms?.maximumAmount,
        },
      )
      LoanSelectionCompleted()
      dispatch(LoansStateChange({user_selected_loan_amount: true}))

      const loanAmountSubmissionResponse = await submitLoanAmountMutation({
        variables: {amount: selectedLoanAmount.toString()},
      })

      if (loanAmountSubmissionResponse?.errors?.length) {
        throw loanAmountSubmissionResponse.errors[0]
      }

      await onComplete()
    } catch (e) {
      logOfferApplicationError(e, 'LoanAmountSelection onContinueAfterAmountSelected error')
      throw e
    }
  }

  if (isLoadingLoanAmountSelectionData || !loanTerms || !loanUsStateAbv) {
    // typescript couldn't infer that loanTermsFields and loanUsStateAbv is always defined when isLoadingLoanAmountSelectionData === false
    // so I had to do this check first to make the LoanSelectionTemplate props happy
    return <Loading type={'loader0'} size={'large'} />
  }

  return (
    <LoanAmountSelectionTemplate
      navigation={navigation}
      loanUsStateAbv={loanUsStateAbv}
      abFlaggedStatesThatCanApply={abFlaggedStatesThatCanApply}
      loanTerms={loanTerms}
      prequalificationAmount={prequalificationAmount}
      isRepeatLoan={isRepeatLoan}
      onViewStateFeeSchedules={handleOnViewStateFeeSchedules}
      onContinueAfterAmountSelected={handleOnContinueAfterAmountSelected}
    />
  )
}

export {LoanAmountSelectionRESTContainer}
