import moment, {Moment, MomentInput} from 'moment'

const Sunday = 0
const Saturday = 6
export function isWeekEnd(m: Moment): boolean {
  const d = m.day()
  return d === Sunday || d === Saturday
}

const federalHolidays = [
  moment('2024-01-01'), // New Year’s Day
  moment('2024-01-15'), // Birthday of Martin Luther King, Jr.
  moment('2024-02-19'), // Washington’s Birthday
  moment('2024-05-27'), // Memorial Day
  moment('2024-06-19'), // Juneteenth National Independence Day
  moment('2024-07-04'), // Independence Day
  moment('2024-09-02'), // Labor Day
  moment('2024-10-14'), // Columbus Day
  moment('2024-11-11'), // Veterans Day
  moment('2024-11-28'), // Thanksgiving Day
  moment('2024-12-25'), // Christmas Day

  moment('2025-01-01'), // New Year’s Day
  moment('2025-01-20'), // Birthday of Martin Luther King, Jr. & Inauguration Day
  moment('2025-02-17'), // Washington’s Birthday
  moment('2025-05-26'), // Memorial Day
  moment('2025-06-19'), // Juneteenth National Independence Day
  moment('2025-07-04'), // Independence Day
  moment('2025-09-01'), // Labor Day
  moment('2025-10-13'), // Columbus Day
  moment('2025-11-11'), // Veterans Day
  moment('2025-11-27'), // Thanksgiving Day
  moment('2025-12-25'), // Christmas Day

  moment('2026-01-01'), // New Year’s Day
  moment('2026-01-19'), // Birthday of Martin Luther King, Jr.
  moment('2026-02-16'), // Washington’s Birthday
  moment('2026-05-25'), // Memorial Day
  moment('2026-06-19'), // Juneteenth National Independence Day
  moment('2026-07-03'), // Independence Day observed
  moment('2026-09-07'), // Labor Day
  moment('2026-10-12'), // Columbus Day
  moment('2026-11-11'), // Veterans Day
  moment('2026-11-26'), // Thanksgiving Day
  moment('2026-12-25'), // Christmas Day

  moment('2027-01-01'), // New Year’s Day
  moment('2027-01-18'), // Birthday of Martin Luther King, Jr.
  moment('2027-02-15'), // Washington’s Birthday
  moment('2027-05-31'), // Memorial Day
  moment('2027-06-18'), // Juneteenth National Independence Day observed
  moment('2027-07-05'), // Independence Day observed
  moment('2027-09-06'), // Labor Day
  moment('2027-10-11'), // Columbus Day
  moment('2027-11-11'), // Veterans Day
  moment('2027-11-25'), // Thanksgiving Day
  moment('2027-12-24'), // Christmas Day observed
  moment('2027-12-31'), // New Year’s Day observed

  moment('2028-01-17'), // Birthday of Martin Luther King, Jr.
  moment('2028-02-21'), // Washington’s Birthday
  moment('2028-05-29'), // Memorial Day
  moment('2028-06-19'), // Juneteenth National Independence Day
  moment('2028-07-04'), // Independence Day
  moment('2028-09-04'), // Labor Day
  moment('2028-10-09'), // Columbus Day
  moment('2028-11-10'), // Veterans Day observed
  moment('2028-11-23'), // Thanksgiving Day
  moment('2028-12-25'), // Christmas Day

  moment('2029-01-01'), // New Year’s Day
  moment('2029-01-15'), // Birthday of Martin Luther King, Jr.
  moment('2029-02-19'), // Washington’s Birthday
  moment('2029-05-28'), // Memorial Day
  moment('2029-06-19'), // Juneteenth National Independence Day
  moment('2029-07-04'), // Independence Day
  moment('2029-09-03'), // Labor Day
  moment('2029-10-08'), // Columbus Day
  moment('2029-11-12'), // Veterans Day observed
  moment('2029-11-22'), // Thanksgiving Day
  moment('2029-12-25'), // Christmas Day

  moment('2030-01-01'), // New Year’s Day
  moment('2030-01-21'), // Birthday of Martin Luther King, Jr.
  moment('2030-02-18'), // Washington’s Birthday
  moment('2030-05-27'), // Memorial Day
  moment('2030-06-19'), // Juneteenth National Independence Day
  moment('2030-07-04'), // Independence Day
  moment('2030-09-02'), // Labor Day
  moment('2030-10-14'), // Columbus Day
  moment('2030-11-11'), // Veterans Day
  moment('2030-11-28'), // Thanksgiving Day
  moment('2030-12-25'), // Christmas Day
]

export function isHoliday(m: Moment): boolean {
  return !!federalHolidays.find((holiday) => holiday.isSame(m, 'day'))
}

export function formatShortNumericDate(date: MomentInput): string {
  return moment(date).format('l')
}

export function formatYearMonthString(date: MomentInput): string {
  return moment(date, 'YYYYMM').format('MM/YY')
}

// Represents a date in the format Dayname, Monthname Day.
// e.g. "Monday, January 1st"
export const momentDateFormat = 'dddd, MMMM Do'
// Represents a date in the format Dayname, Monthname Day
// after Time. e.g. "Monday, January 1st after 12:00 PM"
export const momentDateAfterTimeFormat = 'dddd, MMMM Do [after] h:mm a'

export const validBeforeDate = moment('01/01/1900', 'M/D/YYYY')
export const APIDateFormat = 'MMM D, YYYY'
export const apiParseDateFormat = 'YYYY-MM-DD'
export const legalMinAgeForLoan = moment().subtract(18, 'years')
export const legalMaxAgeForLoan = moment().subtract(100, 'years')

export const stringDateOrEmpty = (dateValue: MomentInput, format?: string): string => {
  return dateValue ? moment(dateValue).format(format ?? APIDateFormat) : ''
}
