import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardAccountStatusCode} from 'src/cassandra'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {isPayOverTime} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCards} from 'src/products/card/hooks/useCards'

export const useShouldDisplayCardDashboardPayOverTimeTile = (): boolean => {
  const {spentAmount, eligibleForInstallments, activeCardStatus} = useCards()
  const {selectedData: doesSupportMinPay} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active?.supportsMinPay,
  )

  // ENG-14442 Hide entry tile for deactivated users
  if (activeCardStatus?.code === CardAccountStatusCode.Deactivated) {
    return false
  }
  if (doesSupportMinPay) {
    return false
  }

  return isPayOverTime(spentAmount, eligibleForInstallments)
}
