import React, {useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {ApplyInOneClickTemplate} from 'src/products/general/ApplyInOneClick/ApplyInOneClickTemplate'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import {usePfDispatch} from 'src/store/utils'
import {CompleteFrontEndPreReqAction} from 'src/workflows/slice'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {usePlaidLayersContext} from 'src/products/MCU/PlaidLayers/PlaidLayersContext'

type ApplyInOneClickContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<WorkflowsStackParams, 'ApplyInOneClick'>

const ApplyInOneClickContainer: React.FC<ApplyInOneClickContainerProps> = (props): JSX.Element => {
  const {navigation, onPreReqFulfilled} = props
  const dispatch = usePfDispatch()
  const [isCompletingFrontEndPreReq, setIsCompletingFrontEndPreReq] = useState<boolean>(false)
  const [hasPushPermissions] = usePushPermissions()

  sendAnalyticEvent(ampli.plaidLayerViewLayerPrompt.bind(ampli))

  navigation.setOptions({
    headerShown: false,
  })

  const onExit = (): void => {
    navigation.navigate('PhoneConfirmation', {
      optedOutOfPlaidLayer: true,
    })
  }

  const onSuccess = async (): Promise<void> => {
    setIsCompletingFrontEndPreReq(true)
    await dispatch(CompleteFrontEndPreReqAction('PII_CONFIRM'))
    await dispatch(CompleteFrontEndPreReqAction('LOAN_PRODUCT_INFORMATION'))
    if (!hasPushPermissions) {
      navigation.navigate('NotificationsPermissions')
    } else {
      await onPreReqFulfilled()
    }
    setIsCompletingFrontEndPreReq(false)
  }

  const onFailProcessProfile = async (): Promise<void> => {
    await onPreReqFulfilled()
  }

  const {isLoadingPlaidData, openPlaidLayers} = usePlaidLayersContext()

  const onHandleStartOneClickApplication = (): void => {
    sendAnalyticEvent(ampli.plaidLayerLayerPromptComplete.bind(ampli))
    setIsCompletingFrontEndPreReq(true)
    void openPlaidLayers(onSuccess, onFailProcessProfile, onExit)
  }

  return (
    <ApplyInOneClickTemplate
      handleStartOneClickApplication={onHandleStartOneClickApplication}
      isContinueButtonLoading={isLoadingPlaidData || isCompletingFrontEndPreReq}
    />
  )
}

export {ApplyInOneClickContainer}
