import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'
import {FetchResult} from '@apollo/client'

import {VerifyYourIdentity} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {
  UserModifyProfileDocument,
  UserModifyProfileMutation,
  UserModifyProfileMutationVariables,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.gqls'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'PersonalInformation'>

const VerifyYourIdentityWorkflowContainer: React.FC<Props> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_screen_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  const handleComplete = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  const [submitUserPersonalInfo, {loading: isSubmittingPersonalInfo}] = useCassandraMutation<
    UserModifyProfileMutation,
    UserModifyProfileMutationVariables
  >(UserModifyProfileDocument, {
    onError: (error) => {
      Log.error(`${error.message} - VerifyYourIdentity Screen - userModifyProfile mutation`)
      throw new Error('Unable to submit personal info.')
    },
    onCompleted: () => {
      TrackAppEvent(
        AppEvents.Name.personal_details_completed,
        AppEvents.Category.InitialApplication,
      )
      TrackAppEvent(
        AppEvents.Name.personal_details_ssn_completed,
        AppEvents.Category.InitialApplication,
      )
    },
  })

  const handleSubmitUserPersonalInfo = async (options: {
    variables: {input: UserModifyProfileMutationVariables}
  }): Promise<FetchResult<UserModifyProfileMutation>> => {
    return submitUserPersonalInfo({
      variables: options.variables.input,
    })
  }

  return (
    <VerifyYourIdentity
      {...props}
      onComplete={handleComplete}
      onSubmitUserPersonalInfo={handleSubmitUserPersonalInfo}
      isSubmittingPersonalInfo={isSubmittingPersonalInfo}
    />
  )
}

export {VerifyYourIdentityWorkflowContainer}
