/**
 * testProps function should only be used in native RN components, when we have a custom compoment we should pass
 * testID which is then passed to testProps inside the appropriate native component inside the custom component.
 */

export function testProps(id) {
  return {testID: id, accessibilityLabel: id}
}

export function getTestID(title: string, postfix: string): string {
  try {
    return title.replace(/\s/g, '-') + postfix
  } catch (e) {
    return 'testId'
  }
}
