import moment from 'moment'
import {createSelector} from 'reselect'

import {lag, loans} from '@possible/generated/proto'
import {allowedStates, CoastalStatesAbv, regionCodeToAbv} from 'src/lib/us_states'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {PfReduxState} from 'src/reducers/types'
import {
  get_latest_loan,
  getLoanStatus,
  getLoanSubStatus,
  isDefaultOrChargedOff,
  isLoanLate,
  isPendingInstallmentLoan,
} from 'src/lib/loans/utils'
import {statusList, transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import {LoanErrorSelection} from 'src/lib/loans/types'
import {
  AccountAndRoutingProvidedValue,
  ILoanRedux,
  LoanState,
  Transfer,
} from 'src/lib/loans/reducers/types'

export const loansStateSelector = (state: PfReduxState): LoanState | undefined => state.lib.loans

export const getLoanTransfers = (state: PfReduxState, loan: ILoanRedux): Transfer[] | undefined =>
  loan?.id ? state.lib?.loans?.transfers?.[loan.id] : undefined

/**
 * Get loan transfers.  If latest loan is an installment loan with status=pending, return the transfer
 * payments from the original loan.
 */
export const loansTransfersWithInstallmentUpgradeSupport = (
  state: PfReduxState,
): Transfer[] | undefined => {
  const loan = get_latest_loan(state?.lib?.loans?.loans)
  let transferLoan: ILoanRedux | undefined
  if (loan && isPendingInstallmentLoan(loan) && loan?.originalLoanId) {
    transferLoan = loanSelector(state, {loanId: loan.originalLoanId})
  } else {
    if (loan) {
      transferLoan = loan
    }
  }
  if (transferLoan) {
    return getLoanTransfers(state, transferLoan)
  }
  return undefined
}

export const getLoanTerms = (state: PfReduxState): lag.ILoanTerms | undefined =>
  state.lib?.loans?.loan_terms?.terms

export const getLoanTitle = (state: PfReduxState): string | undefined =>
  state.lib?.loans?.loan_terms?.title

export const getLoanTermsRedux = (state: PfReduxState): loans.Loan.IType | undefined =>
  state.lib?.loans?.loan_terms

export const getUserStateAbv = (state: PfReduxState): string | undefined =>
  state.cassandra?.user?.me?.profile?.home?.address?.state ?? undefined

export const loansCountSelector = createSelector(
  loansStateSelector,
  (loans) => loans?.loans?.length ?? 0,
)

export const isCoastalState = createSelector(getLoanTitle, getUserStateAbv, (title, stateAbv) => {
  if (title === 'TX_Primary') return false
  return stateAbv ? CoastalStatesAbv.has(stateAbv) : false
})

export const loansSelector = createSelector(loansStateSelector, (loans) => loans?.loans)

export const latestLoanSelector = createSelector(loansSelector, get_latest_loan)

export const loansErrorSelector = createSelector(
  loansStateSelector,
  (loans): LoanErrorSelection => ({
    hasError: loans ? loans.loanHasError : false,
    errorMessage: loans?.loanErrorMessage,
  }),
)

// Check if this is the first loan that user will be accepting
export const OALoanCount = createSelector(loansSelector, (loans) => {
  let count = 0
  loans?.forEach?.((loan) => {
    //loan has been cancelled after being accepted/active
    const cancelledActive =
      getLoanStatus(loan) === statusList.CANCELLED &&
      getLoanSubStatus(loan) === statusList.CANCELLED_ACTIVE
    if (
      cancelledActive ||
      (getLoanStatus(loan) !== statusList.REJECTED &&
        getLoanStatus(loan) !== statusList.EXPIRED &&
        getLoanStatus(loan) !== statusList.CANCELLED)
    ) {
      count++
    }
  })
  return count
})

export const loanTypeForLoan = (
  state: PfReduxState,
  loan: ILoanRedux | null | undefined,
): loans.Loan.IType | undefined => {
  if (!loan?.typeId) {
    return undefined
  }
  return loanTypeForLoanTypeId(state, loan?.typeId)
}

export const getUserUSStateFromLoan = createSelector(
  loanTypeForLoan,
  (loanType): string | undefined => {
    if (!loanType?.regionCode) {
      return undefined
    }
    regionCodeToAbv.get(loanType?.regionCode)
  },
)

export const loanTypeForLoanTypeId = (
  state: PfReduxState,
  loanTypeId: string | undefined,
): loans.Loan.IType | undefined => {
  if (!state || !loanTypeId) {
    return undefined
  }

  return state.lib?.loans?.loan_terms_by_id?.get(loanTypeId)
}

export const latestLoanTypeSelector = createSelector(
  (state: PfReduxState) => state,
  latestLoanSelector,
  loanTypeForLoan,
)

export const paymentMethodSelectedSelector = (state) =>
  state.lib.loans?.paymentMethodSelected as transferMethodsType

export const hasDisbursementOptions = (
  state: PfReduxState,
): loans.GetLoanPaymentMethodsResponse.ILoanPaymentMethods | undefined => {
  const loan = latestLoanSelector(state)
  const paymentMethods = loan?.methods ?? []
  return paymentMethods.find((method) => {
    return method.direction === 'to' && method.network === transferMethods.interchange
  })
}

export const disbursementMethodSelectedSelector = (
  state: PfReduxState,
): Extract<transferMethodsType, 'ach' | 'interchange'> | undefined =>
  state.lib.loans?.disbursementMethodSelected

export const accountAndRoutingProvidedSelector = (
  state: PfReduxState,
): AccountAndRoutingProvidedValue | undefined => state.lib.loans?.accountAndRoutingProvided

export const allLoansSelector = (state: PfReduxState): ILoanRedux[] => state.lib.loans?.loans ?? []

export const repeatLoanSelector = createSelector(
  allLoansSelector,
  (loans) =>
    !!loans?.find(
      (loan) =>
        getLoanStatus(loan) === statusList.PAIDOFF ||
        getLoanStatus(loan) === statusList.CHARGED_OFF ||
        (getLoanStatus(loan) === statusList.CANCELLED &&
          getLoanSubStatus(loan) === statusList.CANCELLED_ACTIVE),
    ),
)

export const canReapply = createSelector(latestLoanSelector, (loan) => {
  const coolOffPeriodDays = 3
  const now = moment().utc()
  const eligibleOn = loan?.rejectedAtDatetime
    ? moment(loan?.rejectedAtDatetime).utc().add(coolOffPeriodDays, 'days')
    : null

  return eligibleOn?.isBefore(now) || eligibleOn?.isSame(now, 'day')
})

export const inLoanReapplyingPhase = (state: PfReduxState): boolean | undefined =>
  state.lib?.loans?.reapplying

type loanIdSelectorProps = {
  loanId: string
}
const loanIdSelector = (_state: PfReduxState, props: loanIdSelectorProps): string => props.loanId
export const loanSelector = createSelector(
  allLoansSelector,
  loanIdSelector,
  (loans, loanId: string) => loans?.find((loan) => loan.id === loanId),
)

export const userIsInAllowedUsStateSelector = (state: PfReduxState): boolean => {
  const userUsState = getUserStateAbv(state)
  if (!userUsState) {
    return false
  }
  return !!allowedStates(abFlaggedStatesEnabled(state))?.includes(userUsState)
}

export const userIsInGoodStandingSelector = createSelector(
  allLoansSelector,
  (loans) =>
    !loans.find((loan) => {
      const loanStatus = getLoanStatus(loan)
      const loanSubStatus = getLoanSubStatus(loan)
      return (
        isLoanLate(loanStatus, loanSubStatus) || isDefaultOrChargedOff(loanStatus, loanSubStatus)
      )
    }),
)
