import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getChaosFailedComponentRenders, getChaosRegisteredComponents} from '../state/selectors'
import {
  degregisterComponentForChaos,
  disableChaosFailureForComponentRender,
  registerComponentForChaos,
} from '../state/actions'
import {chaosReduxStoreCache} from '../../root-redux-store-cache'

/**
 * Create a hash unique to a set of components config (either registered components or failure enabled components).
 * This can be used to determine if a list of registered or enabled components has changed or not.
 */
const hashChaosComponentsConfig = (args: {
  components: {
    [queryName: string]: boolean
  }
}) => {
  const {components} = args
  let hash = ''
  Object.keys(components).forEach((thisComponentName) => {
    hash += thisComponentName
  })
  return hash
}

/**
 * Hook that a component can use to enable chaos mode which can trigger simulated
 * render failures intentinoally.
 */
export const useChaosModeRenderFailure = (params: {
  componentName: string
  dispatch: ReturnType<typeof useDispatch>
}): void => {
  const {componentName, dispatch} = params

  const [chaosRegisteredComponents, setChaosRegisteredComponents] = useState<{
    [key: string]: boolean
  }>({})
  const [chaosFailedComponentRenders, setChaosFailedComponentRenders] = useState<{
    [key: string]: boolean
  }>({})

  const rootStore = chaosReduxStoreCache.getStore()
  const unsubscribeFromReduxActions = rootStore
    ? rootStore.subscribe(() => {
        const chaosState = chaosReduxStoreCache.getChaosStateFromStore()
        // listen for redux store state changes
        if (chaosState) {
          const updatedChaosRegisteredComponents = getChaosRegisteredComponents(chaosState)
          const currentRegisteredComponentsHash = hashChaosComponentsConfig({
            components: updatedChaosRegisteredComponents,
          })
          const previousRegisteredComponentsHash = hashChaosComponentsConfig({
            components: chaosRegisteredComponents,
          })
          // if the list of registered components has changed, update local state for this hook
          if (currentRegisteredComponentsHash !== previousRegisteredComponentsHash) {
            setChaosRegisteredComponents({...updatedChaosRegisteredComponents})
          }
          const updatedChaosFailureComponentRenders = getChaosFailedComponentRenders(chaosState)
          const currentFailureComponentsHash = hashChaosComponentsConfig({
            components: updatedChaosFailureComponentRenders,
          })
          const previousFailureComponentsHash = hashChaosComponentsConfig({
            components: chaosFailedComponentRenders,
          })
          // if the list of components with chaos render failures enabled has changed, update local state for this hook
          if (currentFailureComponentsHash !== previousFailureComponentsHash) {
            setChaosFailedComponentRenders({...updatedChaosFailureComponentRenders})
          }
        }
      })
    : () => {}
  useEffect(() => {
    // cleanup on component unmount
    return () => {
      unsubscribeFromReduxActions()
    }
  }, [])
  useEffect(() => {
    const isChaosModeEnabledForThisComponent = chaosFailedComponentRenders[componentName] === true
    if (isChaosModeEnabledForThisComponent) {
      dispatch(disableChaosFailureForComponentRender({componentName}))
      throw new Error(`Chaos Mode simulated component failure for: ${componentName}`)
    }
  }, [chaosFailedComponentRenders])
  if (!rootStore) {
    // we can't simulate errors without access to store state since it contains
    // config settings for chaos. this should not be set in prod env
    return
  }
  useEffect(() => {
    const isComponentRegisteredForChaos = chaosRegisteredComponents[componentName]
    if (!isComponentRegisteredForChaos) {
      // when this hook is first called register this component as capable of chaos
      // if it's not already registered
      dispatch(registerComponentForChaos({componentName}))
    }
  }, [chaosRegisteredComponents])
}
