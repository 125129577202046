import React, {FC} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'
import {LoanAcceptedPixel} from 'src/products/loans/LoanApprovedActivation/components/LoanAcceptedPixel/LoanAcceptedPixel'
import {transferMethods} from 'src/lib/loans/consts'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type MoneyOnTheWayContentProps = {
  amountApproved: string
  disbursementSettlementDate: DisbursementSettlementDateType
  loanId: string
  paymentMethod: string
}

const MoneyOnTheWayContent: FC<MoneyOnTheWayContentProps> = (props) => {
  const {amountApproved, disbursementSettlementDate, loanId, paymentMethod} = props

  const {t} = useTranslation('LoanApproved')
  const defaultLoanId = '00000000-0000-0000-0000-000000000000'
  const defaultLoanAmount = '0'
  const activeDate = disbursementSettlementDate?.adjustedSettlementDatetime
  const settleDate = moment.utc(activeDate).local()
  const settleDateStr = moment(settleDate).format('dddd, MMMM Do')

  const text =
    paymentMethod === transferMethods.interchange
      ? t('MoneyOnTheWayInterchangeDate')
      : t('MoneyOnTheWayDate', {settleDateStr})

  const loanAmountForPixel = amountApproved === '' ? defaultLoanAmount : amountApproved

  return (
    <Box>
      <LoanAcceptedPixel loanId={loanId ?? defaultLoanId} loanAmount={loanAmountForPixel} />
      <PFText variant="p">{text}</PFText>
    </Box>
  )
}

export {MoneyOnTheWayContent}
