import React, {FC, useEffect} from 'react'

import {ApplicationProcessingTemplate} from 'src/products/general/ApplicationProcessing/ApplicationProcessingTemplate'
import {ApplicationProcessingProps} from 'src/products/general/ApplicationProcessing/types'
import {useCardApplicationProcessingStatus} from 'src/products/general/ApplicationProcessing/useCardApplicationProcessingStatus'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const CardApplicationProcessing: FC<ApplicationProcessingProps> = ({
  onUnderwritingComplete: onProcessingComplete,
}) => {
  const {isApplicationUWComplete, isAccountStatusLoading} = useCardApplicationProcessingStatus()

  usePageViewedAnalytics({
    eventName: CardEvents.card_application_processing_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  useEffect(() => {
    if (!isAccountStatusLoading && isApplicationUWComplete) {
      void onProcessingComplete()
    }
  }, [isApplicationUWComplete, isAccountStatusLoading, onProcessingComplete])

  return <ApplicationProcessingTemplate />
}
