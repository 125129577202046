import React, {FC} from 'react'

import SelfieCameraPage from 'src/products/general/components/organisms/SelfieCameraPage/SelfieCameraPage'
import LivenessComplete from 'src/products/general/Liveness/LivenessComplete'

type Props = {
  onComplete: () => Promise<void> | void
  onLivenessConfirm: () => Promise<void>
  phase: Phase
}

export enum Phase {
  Camera = 'LivenessCamera',
  Complete = 'LivenessCaptureComplete',
}

export const LivenessTemplate: FC<Props> = (props) => {
  const {onComplete, onLivenessConfirm, phase} = props

  const onCompleteSync = (): void => void onComplete()

  switch (phase) {
    case Phase.Complete:
      return <LivenessComplete onContinue={onCompleteSync} />

    case Phase.Camera:
      return <SelfieCameraPage onComplete={onLivenessConfirm} />

    default:
      return null
  }
}
