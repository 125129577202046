import {Consumer} from '@possible/cassandra'
import moment from 'moment'
import {createSelector} from 'reselect'

import {PrequalificationStatus} from 'src/lib/user/types'
import {DL_TYPE_ID} from 'src/lib/user/userEnvConsts'
import {PfReduxState} from 'src/reducers/types'

/*
There are two places accounts are stored in the redux store. The legacy V1 location, V1 is in
snakecase.
V1 location : state.lib.users.profile.accounts

The new V2 location is in camelCase
V2 location : state.lib.users.linked_accounts.linked_accounts.accounts

T
 */

export const sortAccountOnCreatedDate = (account1, account2) => {
  return moment(account1?.createdAt).isBefore(moment(account2?.createdAt), 'minute') ? 1 : -1
}

export const userIdSelector = (state: PfReduxState): string | undefined =>
  state.cassandra.user.me?.id

export const stateProfile = (state: PfReduxState) => state.lib?.users?.profile

export const userMeSelector = (state: PfReduxState) => state.cassandra?.user?.me

export const userProfileSelector = (state): Consumer.types.UserProfile =>
  state.cassandra?.user?.me?.profile

export const userIdentificationSelector = (state: PfReduxState) =>
  state.cassandra?.user?.me?.identification

export const userBankAccountsSelector = (state: PfReduxState) =>
  state.cassandra?.user?.me?.bankAccounts

export const isPhoneVerified = createSelector(userProfileSelector, (user) => user?.phone?.verified)

export const isEmailVerifiedSelector = createSelector(
  userProfileSelector,
  (user): boolean => !!user?.email?.verified,
)

export const userEmailSelector = createSelector(
  userProfileSelector,
  (user) => user?.email?.address ?? undefined,
)

export const usernameSelector = createSelector(userProfileSelector, (user) => user?.name)

export const userFullAddress = createSelector(
  userProfileSelector,
  (profile) => profile?.home?.address,
)

export const isSSNInfoComplete = createSelector(
  userIdentificationSelector,
  (identification): string | undefined => identification?.ssn?.mask,
)

export const formattedPI = createSelector(userMeSelector, isSSNInfoComplete, (me, ssn) => {
  return {
    first_name: me?.profile?.name?.firstName,
    last_name: me?.profile?.name?.lastName,
    middle_name: me?.profile?.name?.middleName,
    name_suffix: me?.profile?.name?.suffix,
    id_number: me?.identification?.identificationDocument?.number,
    id_expiration_date: me?.identification?.identificationDocument?.expirationDate,
    birth_date: me?.profile?.birthDate,
    address: me?.profile?.home?.address,
    ssn,
  }
})
export const userAddressState = createSelector(
  userProfileSelector,
  (profile) => profile?.home?.address?.state,
)

export const isDLScanned = createSelector(stateProfile, (profile) => {
  const dl = profile?.identity?.dl ?? []
  return dl.find((dl_entry) => {
    return dl_entry.type_id === DL_TYPE_ID
  })
})

export const isLivenessCaptured = createSelector(stateProfile, (profile) =>
  profile?.identity?.liveness?.length ? profile.identity.liveness.length > 0 : false,
)

export const isBankAccountSet = createSelector(
  userBankAccountsSelector,
  (accounts) => !!accounts?.all?.length,
)

export const isPIComplete = createSelector(
  userProfileSelector,
  userIdentificationSelector,
  (profile, identification) => {
    return (
      profile?.name?.lastName &&
      profile?.name?.firstName &&
      profile?.birthDate &&
      identification?.identificationDocument?.number &&
      identification?.identificationDocument?.number?.trim().length > 0
    )
  },
)

export const isAddressVerified = createSelector(
  userProfileSelector,
  (profile) => profile?.home?.address?.verified,
)

export const isAddressComplete = createSelector(userProfileSelector, (profile) => {
  const address = profile?.home?.address
  return address && address.street1 && address.postalCode && address.city && address.state
})

export const userInfoChanges = (state) => state.lib?.ui?.userInfoChanges

export const getPrequalificationStatus = (state): PrequalificationStatus | undefined =>
  state.lib?.users?.prequalificationStatus
