import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Calendar from 'src/assets/icons/Calendar1.svg'
import Warning from 'src/assets/icons/WarningGreen.svg'
import IconFeatureList from 'src/designSystem/components/molecules/IconFeatureList/IconFeatureList'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {ButtonLinkProps, ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import {ButtonAction} from 'src/designSystem/types'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type CardManualPayOverviewTemplateProps = {
  onPressPrimary: ButtonAction
  onPressSecondary: ButtonLinkProps['onPress']
}

const CardManualPayOverviewTemplate = (props: CardManualPayOverviewTemplateProps): JSX.Element => {
  const {onPressPrimary, onPressSecondary: handleOnPressSecondary} = props

  const {t} = useTranslation(['CardManualPayOverviewTemplate', 'Common'])
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )

  const isUserMinPayProgram = cardMinPayResponse?.supportsMinPay
  const listItems = isUserMinPayProgram
    ? [
        {
          text: t('MinPayBenefit1'),
          icon: Warning,
          iconWidth: 35,
        },
      ]
    : [
        {
          text: t('Benefit1'),
          icon: Calendar,
          iconWidth: 34,
        },
        {
          text: t('Benefit2'),
          icon: Warning,
          iconWidth: 35,
        },
      ]

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: onPressPrimary,
        },
      }}
      smallTopGap
      testID={'CardManualPayOverviewTemplate'}
      title={t('Title')}
      variant={'generic'}
    >
      <Box gap={'large'} paddingBottom={'large'}>
        <IconFeatureList items={listItems} />

        <BasicTile align={'center'} gap={'small'} padding={'small'}>
          <PFText variant={'h3'}>{t('SecondaryTitle')}</PFText>

          <PFText variant={'p'}>
            {isUserMinPayProgram ? t('SecondaryMinPayDesc') : t('SecondaryDesc')}
          </PFText>

          <ButtonLink icon={'arrowRight'} onPress={handleOnPressSecondary}>
            {t('SecondaryCTA')}
          </ButtonLink>
        </BasicTile>
      </Box>
    </Page>
  )
}

export {CardManualPayOverviewTemplate}
