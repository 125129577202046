import React, {FC} from 'react'

import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {logErrorAndShowException} from 'src/lib/errors'
import {ModuleControlProps} from 'src/flows/types'
import {LivenessInfoTemplate} from 'src/products/general/LivenessInfo/LivenessInfoTemplate'

export const LivenessInfoMPOContainer: FC<ModuleControlProps> = (props) => {
  const {onModuleComplete, onStepComplete} = props

  usePageViewedAnalytics({
    eventName: CardEvents.card_selfie_id_info_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const onComplete = async (): Promise<void> => {
    try {
      if (onStepComplete) {
        await onStepComplete()
      } else if (onModuleComplete) {
        await onModuleComplete()
      }
    } catch (e) {
      void logErrorAndShowException(
        e,
        'Error while trying move to the next module on page LivenessInfo',
      )
    }
  }

  return <LivenessInfoTemplate onComplete={onComplete} />
}
