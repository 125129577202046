import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type LoanFeeRowProps = {
  title: string
  value: string
}

const LoanFeeRow: FC<LoanFeeRowProps> = ({title, value}) => {
  return (
    <View style={styles.row}>
      <View>
        <PFText variant={'p_sm_semibold'}>{title}</PFText>
      </View>
      <View>
        <PFText variant={'p_sm'}>{value}</PFText>
      </View>
    </View>
  )
}

type StateLoanInfoProps = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  paymentInfo: PaymentInfoType
  description?: string
  feesTitle: string
  alternateInterestTitle?: string
  serviceChargeValue?: number
  hideInterest?: boolean
}

const StateLoanInfo: FC<StateLoanInfoProps> = ({
  navigation,
  paymentInfo,
  description,
  feesTitle,
  alternateInterestTitle,
  serviceChargeValue,
  hideInterest,
}) => {
  const {t} = useTranslation(['LoanSelection', 'Common'])
  const onOk = () => {
    navigation?.pop()
  }
  const primary = {
    text: t('Common:Dismiss'),
    onPress: () => {
      onOk()
    },
  }

  return (
    <LightboxNotice title={t('MainTitle')} primary_action={primary}>
      {description ? (
        <View style={{width: '100%'}}>
          <PFText variant={'p'}>{description}</PFText>
        </View>
      ) : null}
      <View style={styles.box_view}>
        {!hideInterest ? (
          <LoanFeeRow
            title={alternateInterestTitle ?? t('Common:Interest')}
            value={`$${paymentInfo?.interest?.toFixed(2)}`}
          />
        ) : null}
        <LoanFeeRow title={feesTitle} value={`$${paymentInfo?.fees?.toFixed(2)}`} />
        {serviceChargeValue ? (
          <LoanFeeRow title={t('ServiceCharge')} value={`$${serviceChargeValue?.toFixed(2)}`} />
        ) : null}
        <LoanFeeRow
          title={t('TotalFees')}
          value={`$${paymentInfo?.interest_and_fees?.toFixed(2)}`}
        />
      </View>
    </LightboxNotice>
  )
}
export default StateLoanInfo
const styles = StyleSheet.create({
  box_view: {
    alignSelf: 'center',
    marginTop: smallGap / 2,
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
