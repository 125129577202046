import React, {useCallback} from 'react'

import {
  LoanAmountSelectionRESTContainer,
  LoanAmountSelectionRESTContainerProps,
} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionRESTContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type LoanAmountSelectionWorkflowContainerProps = Pick<
  LoanAmountSelectionRESTContainerProps,
  'navigation'
> &
  WorkflowPreReqFulfillScreenProps

/**
 * Container to use the LoanAmountSelection screen within Workflows.
 * @param props
 * @returns
 */
const LoanAmountSelectionWorkflowContainer: React.FC<LoanAmountSelectionWorkflowContainerProps> = (
  props: LoanAmountSelectionWorkflowContainerProps,
) => {
  const {navigation, onPreReqFulfilled} = props
  /**
   * When user finishes selecting their loan amount we inform Workflows
   * that the prereq has been fulfilled.
   */
  const handleComplete = useCallback(async (): Promise<void> => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  return <LoanAmountSelectionRESTContainer navigation={navigation} onComplete={handleComplete} />
}

export {LoanAmountSelectionWorkflowContainer}
