import React from 'react'
import {StackNavigationProp} from '@react-navigation/stack'

import {
  CardAccountOfferInfo,
  CardAccountStatusCode,
  CardAccountStatuses,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardApprovedWorkflowContainer} from 'src/products/card/Activation/CardApproved/CardApprovedWorkflowContainer'
import {CardApplicationExpired} from 'src/products/card/Application/CardApplicationExpired/CardApplicationExpired'
import {CardApplicationRejected} from 'src/products/card/Application/CardApplicationRejected'
import {CardDeactivatedTile} from 'src/products/card/LoanDash/CardDeactivatedTile'
import {ProductChoiceWorkflowContainer} from 'src/products/loans/ProductChoice/ProductChoiceWorkflowContainer'
import {ApplicationProcessingProps} from 'src/products/general/ApplicationProcessing/types'
import {CardApplicationProcessing} from 'src/products/general/ApplicationProcessing/CardApplicationProcessing'
import {ProductsQueryAllIneligibleOffersType} from 'src/workflows/types'
import {isInCardsWaitlist as getIsInCardsWaitlist} from 'src/products/general/ProductHub/ProductHub.utils'
import {CardWaitListTile} from 'src/products/card/LoanDash/CardWaitListTile'
import {ProductHubTileWrapper} from 'src/products/general/ProductHub/ProductHubTileWrapper'
import {RouteProp} from '@react-navigation/native'

type Props = ApplicationProcessingProps & {
  cardAccountStatus?: Partial<CardAccountStatuses>
  navigation: StackNavigationProp<MainStackParamList, 'ProductHub'>
  route: RouteProp<MainStackParamList, 'ProductHub'>
  cardOfferInfo?: CardAccountOfferInfo
  ineligibleOffers?: ProductsQueryAllIneligibleOffersType
}

export const CardProductHub: React.FC<Props> = (props) => {
  const {
    cardAccountStatus,
    navigation,
    route,
    cardOfferInfo,
    onUnderwritingComplete: handleOnUnderwritingComplete,
    ineligibleOffers,
  } = props
  const isInCardsWaitlist = getIsInCardsWaitlist(ineligibleOffers)

  // Application pending
  if (cardAccountStatus?.code === CardAccountStatusCode.Pending) {
    return <CardApplicationProcessing onUnderwritingComplete={handleOnUnderwritingComplete} />
  }

  // If repeat card flow in progress, show the product choice workflow
  if (route?.params?.isInNonInitialOnboardingFlow === true) {
    return <ProductChoiceWorkflowContainer />
  }

  // Application rejected
  if (cardAccountStatus?.code === CardAccountStatusCode.Rejected) {
    return (
      <ProductHubTileWrapper tileKey="card-rejected">
        <CardApplicationRejected />
      </ProductHubTileWrapper>
    )
  }

  // Application expired
  if (cardAccountStatus?.code === CardAccountStatusCode.Expired) {
    return (
      <ProductHubTileWrapper tileKey="card-expired">
        <CardApplicationExpired />
      </ProductHubTileWrapper>
    )
  }

  // Application deactivated
  if (cardAccountStatus?.code === CardAccountStatusCode.Deactivated) {
    return (
      <ProductHubTileWrapper tileKey="card-deactivated">
        <CardDeactivatedTile
          loading={false}
          navigation={navigation}
          tileMarginHorizontal={0}
          testID="CardApplicationDeactivated"
        />
      </ProductHubTileWrapper>
    )
  }

  // If an application has finished UW, start activation flow
  if (cardAccountStatus?.code === CardAccountStatusCode.Approved) {
    return (
      <CardApprovedWorkflowContainer
        onScreenComplete={(): void => {
          navigation.reset({index: 0, routes: [{name: 'ApplicationActivationWorkflow'}]})
        }}
      />
    )
  }

  // If an application is in the waitlist, display the waitlist tile
  if (isInCardsWaitlist) {
    return (
      <ProductHubTileWrapper tileKey="card-waitlist">
        <CardWaitListTile tileMarginHorizontal={0} testID="CardApplicationWaitlist" />
      </ProductHubTileWrapper>
    )
  }

  // If there is an offer available, display it
  if (cardOfferInfo && cardOfferInfo.offers.length > 0) {
    return <ProductChoiceWorkflowContainer />
  }

  return null
}
