import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {ModuleControlProps, OnboardingParamList} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {usePfDispatch} from 'src/store/utils'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {onboardingSetCardPaymentMethod} from 'src/lib/onboarding/slice'
import {CardManualPayOverviewTemplate} from 'src/products/card/Activation/CardManualPayOverview/CardManualPayOverviewTemplate/CardManualPayOverviewTemplate'

type CardManualPayOverviewMPOContainerProps = StackScreenProps<
  OnboardingParamList,
  'CARD_MANUAL_PAY_OVERVIEW'
> &
  ModuleControlProps

const CardManualPayOverviewMPOContainer = (
  props: CardManualPayOverviewMPOContainerProps,
): JSX.Element => {
  const {onStepComplete} = props

  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_how_manual_pay_works_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnPressPrimary = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.card_how_manual_pay_works_page_cta, AppEvents.Category.Card)
    await onStepComplete?.()
  }

  const handleOnPressSecondary = async (): Promise<void> => {
    dispatch(onboardingSetCardPaymentMethod('reset'))
    await onStepComplete?.()
  }

  return (
    <CardManualPayOverviewTemplate
      onPressPrimary={handleOnPressPrimary}
      onPressSecondary={handleOnPressSecondary}
    />
  )
}

export {CardManualPayOverviewMPOContainer}
