import React from 'react'
import {useTranslation} from 'react-i18next'
import {CompositeScreenProps} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {UsePageViewedAnalyticsProps} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {LoansAdhocPaymentEvents} from 'src/lib/Analytics/app_events'
import {AdhocPaymentSuccessContainer} from 'src/products/general/AdhocPayment/AdhocPaymentSuccess/AdhocPaymentSuccessContainer'
import {trackLoansAdhocScreenRenderFailure} from 'src/products/loans/AdhocPayment/LoanPay.utils'
import {utcDate} from 'src/lib/utils/date'

type LoanPaySuccessProps = Pick<
  CompositeScreenProps<
    StackScreenProps<LoanPayNavigatorStack, 'LoanPaySuccess'>,
    StackScreenProps<MainStackParamList>
  >,
  'navigation' | 'route'
>

/**
 * Displays a success message after an adhoc loan payment has been submitted.
 * The payment amount and date displayed are passed as route params.
 */
const LoanPaySuccess = ({navigation, route}: LoanPaySuccessProps): JSX.Element => {
  const {t} = useTranslation('AdhocPayment')

  const pageViewedAnalytics: UsePageViewedAnalyticsProps = {
    eventName: LoansAdhocPaymentEvents.loans_adhoc_success_screen_viewed,
    eventCategory: AppEvents.Category.LoansAdhocPayment,
    eventArgs: {
      payment_method_type: route.params.paymentMethodTypeForAnalytics,
    },
  }

  const paymentAmount = route.params.paymentAmount
  const executeDate = route.params.initiationDate ?? ''

  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansAdhocScreenRenderFailure(e, 'LoanPaySuccess')
  }
  return (
    <AdhocPaymentSuccessContainer
      pageViewedAnalytics={pageViewedAnalytics}
      onOkay={(): void => navigation.navigate('Dashboard')}
      paymentAmount={paymentAmount}
      executeDate={executeDate ?? ''}
      executeDateFormat={utcDate}
      overrideTitle={t('YourPaymentIsProcessing')}
      testID="LoanPaySuccess"
      onErrorBoundary={handleOnErrorBoundary}
    />
  )
}

export {LoanPaySuccess}
