import React, {FC, useCallback} from 'react'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import Card from 'src/assets/illustrations/Card_Character 1_Arrows.svg'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export const CardApprovedTile: FC<BaseCardProps> = ({
  navigation,
  loading,
  tileMarginHorizontal,
  tileRadius,
}) => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_approved_dashboard_tile_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const handleOnPress = useCallback(() => {
    TrackAppEvent(CardEvents.card_approved_dashboard_tile_cta, AppEvents.Category.Card)
    navigation.navigate('ProductHub', {isInNonInitialOnboardingFlow: true})
  }, [navigation])
  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText="YouveBeenApproved"
      buttonText="ActivateMyCard"
      content="ApplicationApproved"
      img={
        <PFSvg
          SvgUri={Card}
          svgProps={{
            height: 79,
            width: 100,
          }}
        />
      }
      onPress={handleOnPress}
      loading={loading}
    />
  )
}
