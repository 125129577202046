import React from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {logOfferApplicationErrorAndShowException} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import PersonalInfoConfirmationGQLContainer from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmationGQLContainer'

type PersonalInfoConfirmationMPOContainerProps = Required<
  Pick<ModuleControlProps, 'onModuleComplete'>
>

/**
 * Container to integrate the PersonalInfoConfirmation screen into an MPO flow.
 */
const PersonalInfoConfirmationMPOContainer: React.FC<PersonalInfoConfirmationMPOContainerProps> = (
  props: PersonalInfoConfirmationMPOContainerProps,
) => {
  const {onModuleComplete} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_confirm_page_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  const onConfirm = async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.personal_details_confirm_page_completed,
        AppEvents.Category.InitialApplication,
      )
      await onModuleComplete()
    } catch (e) {
      logOfferApplicationErrorAndShowException(
        e,
        'Error while reviewing application and confirming personal information in MPO',
      )
    }
  }
  return <PersonalInfoConfirmationGQLContainer onConfirm={onConfirm} />
}

export default PersonalInfoConfirmationMPOContainer
