import React, {Component} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {StyleSheet} from 'react-native'
import {Calendar, DateData as rnCalendarsDateData} from 'react-native-calendars'
import moment, {Moment} from 'moment-timezone'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {withTranslation, WithTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'
import {MarkedDates} from 'react-native-calendars/src/types'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {allowedEndDay, userTimeZoneId} from 'src/lib/loans/payments'
import {selectedDateColor, disabledText} from 'src/designSystem/semanticColors'
import {PopPage} from 'src/navigation/NavHelper'
import {logErrorAndShowException, ShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  isSelectedDayFeasible_MarkCalendarDateAsDisabled,
  isSelectedDayFeasible,
  checkAndSetDate,
} from 'src/products/loans/Reschedule/RescheduleFeasibleHelper'
import {getNextAvailableSettlementDate} from 'src/api/actions/loans/loanActions'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import Log from 'src/lib/loggingUtil'
import {loanSelector} from 'src/lib/loans/selector'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import {userProfileSelector} from 'src/lib/user/selector'
import {PageViewedAnalyticsProvider} from 'src/lib/Analytics/AnalyticsHelper'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  NextAvailablePaymentDateQuery,
  UserProfile,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {PfReduxState} from 'src/reducers/types'
import {ILoanRedux} from 'src/lib/loans/reducers/types'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

const CAL_DATE_FORMAT = 'YYYY-MM-DD'

type Props = StackScreenProps<MainStackParamList, 'PaymentRescheduleCalendar'> &
  WithTranslation &
  MainStackParamList['PaymentRescheduleCalendar']

type PropsWithRedux = Props & ConnectedProps<typeof connector>

type State = {
  busy: boolean
  date_changed: boolean
  selected_date: Moment
  nextAvailableSettlmentDate: NextAvailablePaymentDateQuery['getNextAvailablePaymentDate']
  datesMarkedSet: boolean
}

class PaymentRescheduleCalendar extends Component<PropsWithRedux, State> {
  originalDate: Moment
  previousSelected: string | undefined
  markDates: MarkedDates = {}

  constructor(props: PropsWithRedux) {
    super(props)
    this.state = {
      busy: false,
      date_changed: false,
      selected_date: moment(this.props.payment.rescheduledDate),
      nextAvailableSettlmentDate: {},
      datesMarkedSet: false,
    }
    this.originalDate = moment(this.props.payment.originalDate)
  }

  componentDidMount(): void {
    void this.refreshEnv()
  }

  shouldComponentUpdate(_nextProps: Props, nextState: State): boolean {
    /* We need this because otherwise the component re-renders every time the URA check returns
    and it re-renders on the current date
     */
    return (
      nextState.busy !== this.state.busy ||
      !nextState.selected_date.isSame(this.state.selected_date) ||
      (nextState.nextAvailableSettlmentDate &&
        nextState.nextAvailableSettlmentDate.timeNow !==
          this.state.nextAvailableSettlmentDate.timeNow)
    )
  }

  async refreshEnv(): Promise<void> {
    this.setState({busy: true})

    try {
      const response = await getNextAvailableSettlementDate(
        this.props.loan_id,
        moment().utc().format(),
        false,
      )

      if (!response) {
        throw new Error('Failed to retrieve next available settlement date')
      }

      this.markDates = this.getMarkedDays()
      this.setState({
        nextAvailableSettlmentDate: response,
        datesMarkedSet: true,
      })
    } catch (e) {
      ShowException(e)
    } finally {
      this.setState({busy: false})
    }
  }

  async onConfirm(): Promise<void> {
    try {
      this.setState({busy: true})
      const {payment} = this.props
      const newScheduledDate = this.state.selected_date.clone()
      if (this.props.onSetNewDateAsync) {
        const success = await this.props.onSetNewDateAsync(payment, newScheduledDate)
        this.setState({busy: false})
        if (success) {
          TrackAppEvent(
            AppEvents.Name.reschedule_payments_calendar_completed,
            AppEvents.Category.ManageActiveLoan,
            {
              id: payment?.id,
              original: payment?.originalDate,
              new: newScheduledDate?.format(CAL_DATE_FORMAT),
            },
          )
          PopPage(this.props.navigation)
        } else {
          TrackAppEvent(
            AppEvents.Name.reschedule_payments_calendar_confirm_failed,
            AppEvents.Category.ManageActiveLoan,
            {
              id: payment?.id,
              original: payment?.originalDate,
            },
          )
        }
      }
    } catch (e) {
      this.setState({busy: false})
      void logErrorAndShowException(e, 'Failed to confirm rescheduled loan payment dates')
    }
  }

  amount(): string {
    try {
      //$FlowFixMe
      return this.props.payment.amount.toFixed(2)
    } catch (e) {
      return '0.00'
    }
  }

  today(): moment.Moment {
    const timeZoneId = userTimeZoneId(this.props.user)

    if (!timeZoneId) {
      return moment()
    }

    return moment().tz(timeZoneId)
  }

  allowedStartDay(): moment.Moment {
    const timeZoneId = userTimeZoneId(this.props.user)
    if (!timeZoneId) {
      return moment(this.state.nextAvailableSettlmentDate.adjustedSettlementDatetime)
    }
    return moment(this.state.nextAvailableSettlmentDate.adjustedSettlementDatetime).tz(timeZoneId)
  }

  settlesOnHolidaysAndWeekends(): boolean {
    return !!this.state.nextAvailableSettlmentDate.settlesOnHolidaysAndWeekends
  }

  allowedEndDay(): moment.Moment {
    return allowedEndDay(this.originalDate, this.props.payment?.status)
  }

  getMarkedDays(): MarkedDates {
    const startDay = this.allowedStartDay()
    const endDay = this.allowedEndDay()

    const settlesOnHolidaysAndWeekends = !!this.settlesOnHolidaysAndWeekends()
    const markDates: MarkedDates = {}

    let iterDay = this.allowedStartDay()

    while (iterDay.isSameOrBefore(endDay, 'day')) {
      if (
        isSelectedDayFeasible_MarkCalendarDateAsDisabled(
          iterDay,
          settlesOnHolidaysAndWeekends,
          startDay,
          this.originalDate,
          this.today(),
          this.props.payment,
        )
      ) {
        markDates[iterDay.format(CAL_DATE_FORMAT)] = {disabled: true, disableTouchEvent: false}
      }
      iterDay = iterDay.add(1, 'days')
    }
    return markDates
  }

  markSelectedDate = (start: moment.Moment, end: moment.Moment): MarkedDates => {
    const markedDates = {}
    if (
      this.state.selected_date.isSameOrAfter(start, 'day') &&
      this.state.selected_date.isSameOrBefore(end)
    ) {
      if (this.previousSelected && this.markDates[this.previousSelected]) {
        this.markDates[this.previousSelected] = {disabled: true, disableTouchEvent: false}
      }
      const selected_date = this.state.selected_date.format(CAL_DATE_FORMAT)
      const alreadyMarked = this.markDates[selected_date]
      if (alreadyMarked) {
        alreadyMarked.selected = true
      } else {
        markedDates[selected_date] = {selected: true}
      }
      this.previousSelected = selected_date
    }
    return markedDates
  }

  setDatePicked(timeNow: moment.Moment, selectedDate: moment.Moment): void {
    if (timeNow.isSame(selectedDate, 'day')) {
      // set the time component to time now
      selectedDate.set({
        hour: timeNow.get('hour'),
        minute: timeNow.get('minute'),
        second: timeNow.get('second'),
      })
    } else {
      // set the time component to 10am local time
      selectedDate.set({
        hour: 10,
        minute: 0,
        second: 0,
      })
    }

    this.setState({
      selected_date: selectedDate,
      date_changed: true,
    })
  }

  onDayPress(day: rnCalendarsDateData): void {
    const {t, payment} = this.props
    const timeZoneId = userTimeZoneId(this.props.user)
    const selected_date = timeZoneId
      ? moment(day.dateString, CAL_DATE_FORMAT).tz(timeZoneId)
      : moment(day.dateString, CAL_DATE_FORMAT)
    const timeNow = this.today()

    if (selected_date.format(CAL_DATE_FORMAT) === this.previousSelected) {
      return
    }
    TrackAppEvent(
      AppEvents.Name.reschedule_payments_calendar_selected,
      AppEvents.Category.ManageActiveLoan,
      day.dateString,
    )

    const feasibleReasonCode = isSelectedDayFeasible(
      selected_date,
      this.settlesOnHolidaysAndWeekends(),
      this.allowedStartDay(),
      this.originalDate,
      timeNow,
      payment,
    )

    Log.log('=========== onDayPress')
    Log.log(`Time now = ${timeNow.format()}`)
    Log.log(`Selected date = ${selected_date.format()}`)
    Log.log(`User timezone = ${timeZoneId}`)
    Log.log(`feasibleReasonCode = ${feasibleReasonCode}`)
    return checkAndSetDate(
      feasibleReasonCode,
      selected_date,
      timeNow,
      this.allowedStartDay(),
      () => {
        this.setDatePicked(timeNow, selected_date)
      },
      t,
    )
  }

  isNextAvailDatesLoaded(): boolean {
    return !!(this.state.nextAvailableSettlmentDate.timeNow && this.state.datesMarkedSet)
  }

  render(): JSX.Element {
    const {t} = this.props
    const action = {
      text: t('SaveDate'),
      onPress: (): void => {
        void this.onConfirm()
      },
      disabled: this.state.busy || !this.isNextAvailDatesLoaded() || !this.state.date_changed,
      testID: 'Save-Date-Button',
    }
    const startDay = this.today()
    const endDay = this.allowedEndDay()

    const current = this.state.selected_date.format(CAL_DATE_FORMAT)

    const markDates = Object.assign(
      this.markSelectedDate(this.allowedStartDay(), endDay),
      this.markDates,
    )

    const rescheduledDate = moment(this.props.payment.rescheduledDate)
    const mainBodyMessage = this.originalDate.isSame(rescheduledDate)
      ? t('ChooseWhenYouWantToMakeYourPayment', {amount: this.amount()})
      : t('InitiallyScheduledForDate', {date: this.originalDate.format('MMM Do')})

    return (
      <GenericNonModalTemplate
        title={t('NeedMoreTime')}
        actionBlock={buttonLockupProperties(action)}
        description={mainBodyMessage}
      >
        <PageViewedAnalyticsProvider
          eventName={AppEvents.Name.reschedule_payments_calendar_screen_viewed}
          eventCategory={AppEvents.Category.ManageActiveLoan}
          eventArgs={{value: this.props.payment.ordinal}}
        />

        {!this.isNextAvailDatesLoaded() ? (
          <ActivityIndicator style={styles.activityIndicator} size="large" />
        ) : null}
        {this.isNextAvailDatesLoaded() ? (
          <Calendar
            style={styles.cal}
            theme={{
              selectedDayBackgroundColor: selectedDateColor,
              textDisabledColor: disabledText,
              textMonthFontWeight: 'bold',
            }}
            calendarWidth={'100%'}
            current={current}
            renderArrow={(direction): React.ReactNode => {
              return (
                <SvgIcon
                  name={direction === 'left' ? 'chevronLeft' : 'chevronRight'}
                  colorVariant={'info'}
                />
              )
            }}
            minDate={startDay.format(CAL_DATE_FORMAT)}
            maxDate={endDay.format(CAL_DATE_FORMAT)}
            markedDates={markDates}
            onDayPress={(day: rnCalendarsDateData): void => this.onDayPress(day)}
          />
        ) : null}
        <Box marginTop={'large'}>
          <Alert
            title={'Rescheduling Note'}
            description={
              <PFText variant={'p_sm'} testID="End-Payment-Date">
                {t('YourPaymentMustBeScheduledBefore')}
                <PFText variant={'p_sm_semibold'}>{endDay.format('MMM Do')}</PFText>
              </PFText>
            }
            level={'info'}
          />
        </Box>
      </GenericNonModalTemplate>
    )
  }
}

const mapStateToProps = (
  state: PfReduxState,
  props: Props,
): {
  user: UserProfile
  loan: ILoanRedux | undefined
} => {
  return {
    user: userProfileSelector(state),
    loan: loanSelector(state, {loanId: props.loan_id}),
  }
}

const connector = connect(mapStateToProps)

export default withForwardedNavigationParams<Props>()(
  connector(withTranslation('Reschedule')(PaymentRescheduleCalendar)),
)

const styles = StyleSheet.create({
  activityIndicator: {
    margin: 20,
  },
  cal: {
    height: 300,
  },
})
