import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import NotificationsPermissions from 'src/assets/illustrations/NotificationsPermissions.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {isDeviceWeb} from 'src/lib/utils/platform'

type NotificationsPermissionsModalProps = {
  longTitle?: boolean
  visible: boolean
  onPermissionsGranted: () => void
  onDismiss: () => void
}

const NotificationsPermissionsModal: FC<NotificationsPermissionsModalProps> = (props) => {
  const {t} = useTranslation('NotificationsPermissions')

  const {
    longTitle: hasLongTitle = false,
    visible: isVisible,
    onPermissionsGranted,
    onDismiss: handleOnDismiss,
  } = props

  const [hasPushPermissions, requestPushPermissions] = usePushPermissions()

  useEffect(() => {
    if (isVisible && hasPushPermissions) {
      onPermissionsGranted()
    }
    // Adding onPermissionsGranted to the dependencies array will cause an infinite loop in dev + web
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPushPermissions, isVisible])

  const handleOkay = (): void => {
    void requestPushPermissions().finally(() => {
      handleOnDismiss()
    })
  }

  const webImage = <PFSvgContain svg={NotificationsPermissions} width={287} height={92} />
  const mobileImage = (
    <Box elevation={2} marginTop={-3} marginLeft={-6} marginRight={-6} radius="medium">
      <PFSvgContain svg={NotificationsPermissions} width={'100%'} />
    </Box>
  )

  return (
    <Overlay
      visible={isVisible}
      title={
        hasLongTitle ? t('NotificationsPermissionsLongTitle') : t('NotificationsPermissionsTitle')
      }
      image={<Box>{isDeviceWeb() ? webImage : mobileImage}</Box>}
      text={<PFText variant={'p'}>{t('NotificationsPermissionsBody')}</PFText>}
      okayButtonText={t('NotificationsPermissionsPrimaryAction')}
      onOkay={handleOkay}
      dismissButtonText={t('NotificationsPermissionsSecondaryAction')}
      onDismiss={handleOnDismiss}
      testID="NotificationsPermissionsModal"
    />
  )
}

export default NotificationsPermissionsModal
