import React, {ReactElement} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

import {AcceptLoanAndStateAgreementsFLTemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsFL/AcceptLoanAndStateAgreementsFLTemplate'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {AcceptLoanAndStateAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsGQLContainer'

export type AcceptLoanAndStateAgreementsFLWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptLoanAndStateAgreementsFL screen within Workflows.
 */
const AcceptLoanAndStateAgreementsFLWorkflowContainer: React.FC<
  AcceptLoanAndStateAgreementsFLWorkflowContainerProps
> = (props: AcceptLoanAndStateAgreementsFLWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'state_specific_fl',
    },
  })
  return (
    <AcceptLoanAndStateAgreementsGQLContainer
      testID="AcceptLoanAndStateAgreementsFLWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => (
        <AcceptLoanAndStateAgreementsFLTemplate {...innerProps} />
      )}
    />
  )
}

export {AcceptLoanAndStateAgreementsFLWorkflowContainer}
