import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'
import {MoneyOnTheWayContent} from 'src/products/loans/LoanApprovedActivation/components/MoneyOnTheWayOverlay/MoneyOnTheWayContent'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import AppEvents from 'src/lib/Analytics/app_events'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

export type MoneyOnTheWayOverlayProps = {
  isVisible: boolean
  onOverlayContinue: () => Promise<void> | void
  loanId: string
  paymentMethod: transferMethodsType
  amountApproved: string
  disbursementSettlementDate: DisbursementSettlementDateType
}

const MoneyOnTheWayOverlay: FC<MoneyOnTheWayOverlayProps> = (props) => {
  const {
    isVisible,
    onOverlayContinue,
    loanId,
    paymentMethod,
    amountApproved,
    disbursementSettlementDate,
  } = props
  const {t} = useTranslation('LoanApproved')

  const [isOnContinueProcessing, setIsOnContinueProcessing] = useState(false)

  const handleOnContinue = async (): Promise<void> => {
    setIsOnContinueProcessing(true)
    TrackAppEvent(AppEvents.Name.your_money_is_on_the_way_completed, AppEvents.Category.Checkout)
    await onOverlayContinue()
    setIsOnContinueProcessing(false)
  }

  const getTitle = (): string => {
    const loanPaymentMethod = paymentMethod
    if (loanPaymentMethod === transferMethods.interchange) {
      return t('CheckYourBankAccount')
    } else {
      return t('MoneyOnTheWay')
    }
  }

  return (
    <OverlaySimple
      visible={isVisible}
      title={getTitle()}
      testID={'Money-On-The-Way-Overlay'}
      okayButtonText={t('ContinueToDashboard')}
      onOkay={handleOnContinue}
      okayButtonDisabled={isOnContinueProcessing}
      content={
        <MoneyOnTheWayContent
          amountApproved={amountApproved}
          disbursementSettlementDate={disbursementSettlementDate}
          loanId={loanId}
          paymentMethod={paymentMethod}
        />
      }
    />
  )
}

export {MoneyOnTheWayOverlay}
