import React, {Component} from 'react'
import {View} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {completeUra, getUserEnvSelector} from '@possible/cassandra'
import {BankSupportDisclaimerInline} from 'src/products/loans/components/molecules/BankSupportDisclaimerInline/BankSupportDisclaimerInline'
import {URASet, UserStateRefresh} from 'src/api/actions/user/userActions'
import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux, get_ura_ui_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {ShowException} from 'src/lib/errors'
import URAModalContent from 'src/products/loans/UserRequestedAction/URAModalContent'
import Log from 'src/lib/loggingUtil'
import {
  launchAlternateAggregator,
  launchAggregator,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {URAProps} from 'src/products/loans/UserRequestedAction/URAProps'

type State = {
  busy: boolean
}

type Props = PropsFromRedux &
  URAProps &
  WithTranslation & {
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
    hideSupportedBanks?: boolean
  }

class LinkAnotherAccount extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      busy: false,
    }

    this.props.URAUIUpdateAction(
      [{id: props.ura_id, componentId: props.componentId, close: () => this.props.close()}],
      'MergeRecord',
    )
  }

  componentDidMount() {
    this.getUserEnv()
  }

  async onBankComplete(success) {
    try {
      if (success) {
        await completeUra(this.props.ura_id)

        this.props.close()
      }
    } catch (e) {
      ShowException(e)
    }
  }

  async getUserEnv() {
    const refresh_success = await this.props.UserStateRefresh()
    if (!refresh_success) {
      Log.warn('failed user profile refresh')
    }
  }

  bankAggregatorSwitchCallback(alternateId, accountId) {
    launchAlternateAggregator(alternateId, accountId, this.props.navigation, (success) =>
      this.onBankComplete(success),
    )
  }

  async onLinkAccount() {
    this.setState({busy: true})

    await this.getUserEnv()

    this.setState({busy: false})

    const {env, navigation} = this.props
    launchAggregator(
      env,
      navigation,
      null,
      (success) => this.onBankComplete(success),
      (alternateId, accountId) => this.bankAggregatorSwitchCallback(alternateId, accountId),
    )
  }

  render() {
    const primary = {
      text: this.props.t('LinkAnotherAccount'),
      disabled: this.state.busy,
      onPress: () => {
        this.onLinkAccount()
      },
    }
    const bodyExtra = (
      <View>
        {this.props.hideSupportedBanks ? null : (
          <BankSupportDisclaimerInline navigation={this.props.navigation} />
        )}
      </View>
    )
    return (
      <URAModalContent
        uraId={this.props.ura_id}
        ura={this.props.ura}
        extra={bodyExtra}
        primary={primary}
        close={this.props.close}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const env = getUserEnvSelector(state)

  return {
    env,
    ura: get_ura_by_id_from_redux(ownProps.ura_id, state),
    ura_ui: get_ura_ui_by_id_from_redux(ownProps.ura_id, state),
  }
}

const mapDispatchToProps = {
  URASet,
  URAUIUpdateAction,
  UserStateRefresh,
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withTranslation('UserRequestedAction')(LinkAnotherAccount))
