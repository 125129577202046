import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import LinkBankAccount from 'src/assets/illustrations/LinkYourBank.svg'
import Avatar from 'src/assets/icons/Avatar.svg'
import Card from 'src/assets/icons/Card2.svg'
import {
  InterstitialList,
  InterstitialListItem,
} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'

export type Props = {
  onComplete: () => Promise<void>
}

export const LivenessInfoTemplate: FC<Props> = (props) => {
  const {onComplete} = props
  const {t} = useTranslation(['LivenessInfo', 'Common'])

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:OkGotIt'),
      onPress: onComplete,
      testID: 'continue_button',
    },
  }

  const ValueProps: InterstitialListItem[] = [
    {
      id: 1,
      title: t('SelfiePhoto'),
      subtext: t('ClearPhoto'),
      illustration: Avatar,
      illustrationSize: 35,
    },
    {
      id: 2,
      title: t('StateIssuedID'),
      subtext: t('DriversLicenses'),
      illustration: Card,
      illustrationSize: 29,
    },
  ]

  return (
    <Page
      variant={'interstitial'}
      buttonProps={buttonProps}
      banner={LinkBankAccount}
      title={t('MoreInformation')}
    >
      <InterstitialList items={ValueProps} />
    </Page>
  )
}
