import React, {ReactNode} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'

import {largeGap, smallGap} from 'src/designSystem/layout'
import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {Color} from 'src/designSystem/types'

type ProgressTrackerStepLayoutProps = {
  isComplete: boolean
  lineStyle?: ViewStyle['borderStyle']
  lineColor?: Color
  color?: Color
  label?: ReactNode
  labelColor?: Color
  children: ReactNode
}

const ProgressTrackerStepLayout = (props: ProgressTrackerStepLayoutProps): JSX.Element => {
  const {
    children,
    isComplete,
    color = NamedColors.WHITESMOKE,
    label,
    labelColor = NamedColors.PRODUCT_BLUE,
    lineStyle,
    lineColor = NamedColors.WHITESMOKE,
  } = props

  const progressLineStyle: ViewStyle[] = [
    styles.progressLine,
    {
      borderColor: !lineStyle ? 'transparent' : lineColor, // allow for no border
      borderStyle: lineStyle,
      borderWidth: 3,
    },
  ]

  const iconStyle: ViewStyle[] = [
    styles.icon,
    isComplete
      ? {
          backgroundColor: color,
        }
      : {
          ...styles.iconOutline,
          borderColor: color,
        },
  ]

  return (
    <View style={styles.step}>
      <View style={styles.iconWrapper}>
        <View style={iconStyle}>
          <>
            {label && typeof label === 'string' ? (
              <PFText color={labelColor} variant="p_sm_semibold">
                {label}
              </PFText>
            ) : (
              label
            )}
          </>
        </View>

        <View style={styles.iOSBorderFix}>
          <View style={progressLineStyle} />
        </View>
      </View>

      <View style={styles.content}>{children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flexShrink: 1,
    paddingBottom: largeGap,
  },
  iOSBorderFix: {
    flex: 1,
    overflow: 'hidden', // 🧙 Hack to get dashed borders working in ios: https://github.com/facebook/react-native/issues/24224
  },
  icon: {
    alignItems: 'center',
    borderRadius: largeGap / 2,
    height: largeGap,
    justifyContent: 'center',
    width: largeGap,
  },
  iconOutline: {
    backgroundColor: NamedColors.WHITE,
    borderWidth: 3,
  },
  iconWrapper: {
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: smallGap,
  },
  progressLine: {
    flex: 1,
    // 🧙 Hack to get dashed borders working in ios: https://github.com/facebook/react-native/issues/24224
    marginBottom: -3,
    marginRight: -3,
    marginTop: -3,
    // 🧙 End hack.
    paddingBottom: largeGap, // This is effectively our `minHeight`
  },
  step: {
    flexDirection: 'row',
  },
})

export {ProgressTrackerStepLayout}
export type {ProgressTrackerStepLayoutProps}
