import React, {FC} from 'react'

import AppEvents from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {trackSubmitCode} from 'src/products/loans/EmailVerification/EmailVerification.utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {EmailConfirmationV2} from 'src/products/MCU/EmailConfirmationV2/EmailConfirmationV2'

export const EmailVerificationWorkflowContainer: FC<WorkflowPreReqFulfillScreenProps> = ({
  onPreReqFulfilled,
}) => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_email_verification_code_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const handleOnCodeSubmitted = async (): Promise<void> => {
    trackSubmitCode()
    await onPreReqFulfilled()
  }

  return <EmailConfirmationV2 onCodeSubmitted={handleOnCodeSubmitted} />
}
