import {
  LinkedAccount,
  LinkedAccountStatusCode,
  UserProfile,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {banking} from '@possible/generated/proto'

import {getUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {Address} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'
import {bankLinkStatus} from 'src/lib/user/userEnvConsts'

export const maskPrefix = '\u0020\u0020\u2022\u2006\u2022\u2006\u2022\u2006\u2022\u2006'
export const maskPrefixSmall = '\u2E31\u2E31\u2E31\u2E31'
// the dots •••• with a non-breaking space at end so it doesn't wrap
export const maskPrefixNonBreaking = '\u2022\u2022\u2022\u2022\u00A0'
// the dots spaced out • • • • with a non-breaking space so it doesn't wrap
export const maskPrefixNonBreakingSpacedOut = '\u2022\u00A0\u2022\u00A0\u2022\u00A0\u2022\u00A0'

// Confirmed new UI for formatted mask with Design
// formatMask function is being depreciated in favor of formatMaskNonBreaking
// ticket: https://possible.atlassian.net/browse/ENG-16516
export const formatMask = (mask: string | undefined, small: boolean): string => {
  if (mask) {
    return `${small ? maskPrefixSmall : maskPrefix}${mask}`
  } else {
    return ''
  }
}

export const formatMaskNonBreaking = (
  mask: string | null,
  withSpaceBetweenDots: boolean,
): string => {
  if (!mask) {
    return ''
  }

  if (withSpaceBetweenDots) {
    return `${maskPrefixNonBreakingSpacedOut}${mask}`
  }

  return `${maskPrefixNonBreaking}${mask}`
}

export const hasValidFundableAccountV2 = (
  accounts: banking.ILinkedAccount[] | undefined,
): boolean => {
  return hasValidAccountV2(accounts) && linkedFundingAccountFoundV2(accounts)
}

export function hasValidFundableAccountGql<
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(accounts: LinkedAccountSubset[] | undefined | null): boolean {
  if (!accounts || accounts.length === 0) {
    return false
  }
  const filterredAccounts = accounts.filter(
    (account) => account.status === LinkedAccountStatusCode.LinkedInUse && !account.isLoginRequired,
  )
  return filterredAccounts.length > 0
}

export const hasValidAccountV2 = (accounts: banking.ILinkedAccount[] | undefined): boolean => {
  if (!accounts) {
    return false
  }

  return accounts.length > 0
}

export const linkedFundingAccountFoundV2 = (
  accounts: banking.ILinkedAccount[] | undefined,
): boolean => {
  if (!accounts) {
    return false
  }
  return accounts.filter((account) => isAccountLinked(account)).length > 0
}

export const isAccountLinked = (account: banking.ILinkedAccount | undefined): boolean => {
  return (
    account?.status?.toLowerCase() === bankLinkStatus.linked && account?.isLoginRequired === false
  )
}

export const isAccountLinkedGql = <
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(
  account: LinkedAccountSubset,
): boolean => {
  return account.status === LinkedAccountStatusCode.LinkedInUse && account.isLoginRequired === false
}

export function getAccountMaskForLinkedAccount(
  account: banking.ILinkedAccount | undefined,
): string {
  return account?.account?.maskCorrected || account?.account?.mask || 'XXXX'
}

export function getFriendlyAccountNameFromLinkedAccount(
  account: banking.ILinkedAccount | undefined,
): string {
  try {
    if (!account) {
      return ''
    }
    const institutionName = account?.institution?.name ?? 'BANK NAME'
    const accountName = account?.account?.name ?? 'ACCOUNT NAME'
    const mask = getAccountMaskForLinkedAccount(account)

    return `${institutionName} ${accountName} ending in ${mask}`
  } catch (e) {
    Log.log(e)
  }
  return ''
}

export async function userHasOnlineBankEnabled(): Promise<boolean> {
  try {
    const result = await getUserProperty('onlineBank')
    return result?.value?.enabled ?? false
  } catch (e) {
    Log.info('onlineBank get error:', e)
  }
  return false
}

function add_address_line(oldAddStr: string, newAddrLine: string | null | undefined): string {
  if (!newAddrLine) {
    return oldAddStr
  }
  if (oldAddStr) {
    oldAddStr = `${oldAddStr}\n`
  }
  oldAddStr = oldAddStr + newAddrLine
  return oldAddStr
}

export function get_formated_address(address: Address | null | undefined): string {
  if (!address) return ''
  let adddressStr = ''
  adddressStr = add_address_line(adddressStr, address?.street1)
  adddressStr = add_address_line(adddressStr, address?.street2)
  adddressStr = add_address_line(
    adddressStr,
    `${address?.city}, ${address?.state} ${address?.postalCode}`,
  )
  return adddressStr
}

export function getFormattedAccountType(subType: string | undefined): string {
  if (!subType) {
    return ''
  }
  return subType
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function capitalizeFirstLetter(string: string | undefined): string {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getUserFullName = (data: NonNullable<UserProfile['name']>): string =>
  // Object order is not guaranteed
  [data.firstName, data?.middleName, data?.lastName, data?.suffix]
    .filter((nameDatum) => !!nameDatum)
    .join(' ')
