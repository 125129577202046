import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/loans/UserRequestedAction/URAModalContent'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LightboxNoticeAction} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import AppEvents from 'src/lib/Analytics/app_events'
import {completeUra} from '@possible/cassandra'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import Snackbar from 'src/lib/Snackbar'

type Props = {
  uraId: string
  close: () => void
  componentId?: string | null
}

export const ChangeDefaultPaymentMethod: React.FunctionComponent<
  Props & {
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  }
> = (props) => {
  const {componentId, uraId, close, navigation} = props

  const {t} = useTranslation('UserRequestedAction')
  const dispatch = usePfDispatch()

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(uraId, state))
  const modifiedUra = ura
    ? {
        ...ura,
        actionBody: '', // Removes body text, which is not needed for this URA
      }
    : undefined

  useEffect(() => {
    TrackAppEvent(
      AppEvents.Name.change_default_payment_method_URA_viewed,
      AppEvents.Category.AccountManagement,
    )

    dispatch(URAUIUpdateAction([{id: uraId, componentId, close}], 'MergeRecord'))
  }, [dispatch, uraId, componentId, close])

  const handleNavigateToAcceptAgreements = (): void => {
    if (
      !ura?.actionData?.paymentInstrumentId ||
      ura?.requestContext?.type !== 'loan' ||
      !ura?.requestContext?.id
    ) {
      logAddPaymentMethodError(new Error('No paymentInstrumentId or loanId in URA actionData'))
      Snackbar.error({
        title: t('Common:GenericError'),
        duration: Snackbar.LENGTH_LONG,
      })
      return
    }

    navigation.push('AcceptLoanAutoPayAgreement', {
      newDefaultPaymentMethodId: ura.actionData.paymentInstrumentId,
      loanId: ura.requestContext.id,
      uraId,
    })
  }

  const primary: LightboxNoticeAction = {
    text: t('YesUpdateMyAutoPay'),
    onPress: handleNavigateToAcceptAgreements,
    size: 'large',
  }

  const secondary: LightboxNoticeAction = {
    text: t('DoNotUpdate'),
    onPress: async () => {
      await completeUra(uraId)
      TrackAppEvent(
        AppEvents.Name.change_default_payment_method_URA_completed,
        AppEvents.Category.AccountManagement,
        {
          did_update_payment_method: false,
        },
      )
      close()
    },
    size: 'large',
  }

  return (
    <URAModalContent
      uraId={uraId}
      ura={modifiedUra}
      primary={primary}
      secondary={secondary}
      close={close}
    />
  )
}
