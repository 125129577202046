import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {MoneyOnTheWayGQLContainer} from 'src/products/loans/MoneyOnTheWay/MoneyOnTheWayGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

export type MoneyOnTheWayNavigationContainerProps = StackScreenProps<
  MainStackParamList,
  'MoneyOnTheWay'
>

const MoneyOnTheWayNavigationContainer: FC<MoneyOnTheWayNavigationContainerProps> = (props) => {
  const {navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.your_money_is_on_the_way_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const handleOnContinue = (): void => {
    navigation.navigate('Dashboard')
  }
  return <MoneyOnTheWayGQLContainer onContinue={handleOnContinue} />
}

export {MoneyOnTheWayNavigationContainer}
