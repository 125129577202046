import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import LightboxNotice, {
  LightboxNoticeAction,
} from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'

type Props = {
  title: string
  text: string
  buttonText: string
  secondaryButtonText?: string
  close?: () => void
  onContinue: () => void
}

const RescheduleAlertLightbox: FC<Props> = (props) => {
  const {title, text, buttonText, secondaryButtonText, close, onContinue} = props
  const {t} = useTranslation(['Reschedule', 'Common'])

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.reschedule_alert_viewed, AppEvents.Category.ManageActiveLoan, {
      title,
    })
  }, [title])

  const onComplete = () => {
    TrackAppEvent(
      AppEvents.Name.reschedule_alert_continue_selected,
      AppEvents.Category.ManageActiveLoan,
    )
    close?.()
    onContinue?.()
  }

  const onClose = () => {
    close?.()
  }

  const primaryText = buttonText || t('Common:Continue')

  //LightboxNotice puts the secondary_action in an array
  const secondary: LightboxNoticeAction | undefined = secondaryButtonText
    ? {
        text: secondaryButtonText,
        onPress: () => {
          TrackAppEvent(
            AppEvents.Name.reschedule_alert_nevermind_selected,
            AppEvents.Category.ManageActiveLoan,
          )
          onClose()
        },
      }
    : undefined

  const primary: LightboxNoticeAction = {
    text: primaryText,
    onPress: () => {
      onComplete()
    },
  }

  return (
    <LightboxNotice
      title={title}
      body_text={text}
      primary_action={primary}
      secondary_action={secondary}
    />
  )
}

export default RescheduleAlertLightbox
