import {useNavigation} from '@react-navigation/native'
import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ActivityIndicator} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'

import {StackNavigationProp} from '@react-navigation/stack'

import {CohortUserTpe} from '@possible/cassandra/src/types/types.mobile.generated'
import ProfileSVG from 'src/assets/icons/Profile.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {prefixSsn} from 'src/lib/utils/ssnUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType'
import {openContactUsForm} from 'src/lib/contactUs'

type PersonalInfoFieldToConfirm = {
  label: string
  value: string
}
export type PersonalInfoConfirmationTemplateProps = {
  onConfirm: () => Promise<void> | void
  ssnLastFour: string
  name: string
  birthDate: string
  email: string
  address: string
  isConfirmBtnDisabled?: boolean
  isConfirmBtnLoading?: boolean
  isLoading: boolean
}
const ContactUsBox = (): React.ReactElement => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const handleOnPressContactUsForm = (): void => {
    openContactUsForm(navigation)
  }
  const {t} = useTranslation(['PersonalInfoConfirmation', 'Common'])
  return (
    <Box
      elevation={2}
      radius={4}
      paddingHorizontal={'tiny'}
      paddingVertical={'small'}
      justify={'start'}
      direction="row"
      testID="ContactUsBox"
    >
      <Box paddingHorizontal="tiny" justify="center" align="center">
        <PFSvgContain svg={ProfileSVG} width={30} />
      </Box>
      <Box justify="center" align="center">
        <PFText variant="p" color={NamedColors.SILVER} textAlign="center">
          <SvgLink
            onPress={handleOnPressContactUsForm}
            linkText={t('ContactUs')}
            linkType={'inline'}
          />
          {t('UpdateYourInformation')}
        </PFText>
      </Box>
    </Box>
  )
}

export const PersonalInfoConfirmationTemplate: FC<PersonalInfoConfirmationTemplateProps> = (
  props: PersonalInfoConfirmationTemplateProps,
) => {
  const {
    onConfirm,
    name,
    address,
    ssnLastFour,
    birthDate,
    email,
    isConfirmBtnDisabled,
    isConfirmBtnLoading,
    isLoading,
  } = props

  const {t} = useTranslation(['PersonalInfoConfirmation', 'Common'])
  const cohortUserType = useGetCohortUserType()

  const config: PersonalInfoFieldToConfirm[] = [
    {
      label: t('Common:Name'),
      value: name,
    },
    {
      label: t('Common:Email'),
      value: email,
    },
    {
      label: t('Common:BirthDate'),
      value: birthDate,
    },
    {
      label: t('Common:Address'),
      value: address,
    },
    {
      label: t('Common:SSN'),
      value: prefixSsn(ssnLastFour),
    },
  ]

  const personalInfoFieldRows = config.map((item: PersonalInfoFieldToConfirm) => {
    return (
      <Box key={item.label} direction={'row'}>
        <Box flex={1} marginRight={'medium'}>
          <PFText variant={'p_semibold'}>{item.label}</PFText>
          <PFText variant={'p'} color={'textDisabled'}>
            {item.value}
          </PFText>
        </Box>
      </Box>
    )
  })

  const onPress = async (): Promise<void> => {
    await onConfirm()
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:ThisIsCorrect'),
          onPress,
          disabled: isConfirmBtnDisabled,
          loading: isConfirmBtnLoading,
          testID: 'This-Is-Correct-Button',
        },
      }}
      variant={'generic'}
      title={t('PersonalInfoConfirmation:IsThisInfoCorrect')}
      smallTopGap={true}
    >
      <Box marginBottom={'medium'}>
        <Box elevation={2} radius={4} padding={'medium'}>
          {isLoading ? <ActivityIndicator /> : <Box gap={'medium'}>{personalInfoFieldRows}</Box>}
        </Box>
      </Box>
      {cohortUserType === CohortUserTpe.LoanGrad ? (
        <Box paddingVertical={'tiny'}>
          <ContactUsBox />
        </Box>
      ) : null}
    </Page>
  )
}
