import React, {FC, useCallback} from 'react'
import {useFocusEffect} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  TrackAppEvent,
  CardApproved as CardApprovedMarketingEvent,
} from 'src/lib/Analytics/analytics_compat'
import {CardApprovedTemplate} from 'src/products/card/Activation/CardApproved/CardApprovedTemplate/CardApprovedTemplate'
import {Consumer} from '@possible/cassandra'
import {WorkflowScreenProps} from 'src/workflows/types'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

const CardApprovedWorkflowContainer: FC<WorkflowScreenProps> = ({onScreenComplete}) => {
  const {t} = useTranslation('CardDashboard')
  const {
    data,
    error,
    loading: isLoading,
    refetch: handleOnRetry,
  } = Consumer.hooks.useCardActivationApprovedQuery({
    fetchPolicy: 'cache-first',
  })

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_approved_page_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  useFocusEffect(
    useCallback(() => {
      CardApprovedMarketingEvent()
    }, []),
  )

  const handleOnPress = (): void => {
    TrackAppEvent(AppEvents.Name.card_approved_page_cta, AppEvents.Category.Onboarding)
    onScreenComplete()
  }

  let creditLimit: string = ''
  let monthlyMembershipFees: string = ''

  if (
    data?.me.cardAccounts.active?.creditLimit &&
    data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  ) {
    creditLimit = data?.me.cardAccounts.active?.creditLimit
    monthlyMembershipFees = data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  }

  return (
    <BaseTemplate
      isError={!!error || !creditLimit || !monthlyMembershipFees}
      isLoading={isLoading}
      onRetry={handleOnRetry}
      errorTitle={t('ErrorTitle')}
    >
      <CardApprovedTemplate
        creditLimit={creditLimit}
        feeAmount={monthlyMembershipFees}
        onPress={handleOnPress}
      />
    </BaseTemplate>
  )
}

export {CardApprovedWorkflowContainer}
