import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import LightboxView from 'src/designSystem/components/organisms/Lightbox/LightboxView'
import {openContactUsForm} from 'src/lib/contactUs'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Box from 'src/designSystem/components/atoms/Box/Box'

type Props = {
  title: string
  text: string
  close: () => void
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}
const ErrorAlertLightbox: FC<Props> = ({title, text, close, navigation}) => {
  const {t} = useTranslation(['ErrorAlertLightbox', 'Common'])
  const onFeedback = (): void => {
    onComplete()
    openContactUsForm(navigation)
  }

  const onComplete = (): void => {
    close?.()
  }

  const primary = {
    text: t('Common:Continue'),
    onPress: (): void => {
      onComplete()
    },
  }

  const body = (
    <Box gap={'small'} marginBottom={'little'}>
      <PFText variant={'p'}>{text}</PFText>
      <PFText variant={'p'}>
        <Trans i18nKey="ForAssistance" t={t}>
          Please
          <SvgLink
            onPress={(): void => onFeedback()}
            linkType={'inline'}
            linkText={t('Common:ContactUsLowercase')}
          />
          for further assistance!
        </Trans>
      </PFText>
    </Box>
  )

  return <LightboxView title={title} body={body} primary_action={primary} />
}

export {ErrorAlertLightbox}
