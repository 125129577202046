import React, {
  FC,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import {Animated, View} from 'react-native'

import {formFieldProps} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFFieldBase from 'src/designSystem/components/atoms/PFFieldBase/PFFieldBase'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Dropdown from 'src/designSystem/components/atoms/PFDropdown/Dropdown/Dropdown'
import styles from 'src/designSystem/components/atoms/PFDropdown/styles'
import {alert, black, textDisabled} from 'src/designSystem/colors'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {animationDuration, animationEasing} from 'src/designSystem/animations'
import {isDeviceIOS, isDeviceWeb} from 'src/lib/utils/platform'
import {textInputEmptyColor} from 'src/designSystem/semanticColors'
import {Color} from 'src/designSystem/types'
import {getErrorState, getBaseFieldState} from 'src/designSystem/lib/fieldUtil'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type PFDropdownOptionProps = {
  label: string
  value: any
  color?: any
}

export type PFDropdownProps = {
  label: string
  options: PFDropdownOptionProps[]
  useValueForLabel?: boolean
  value?: string
  onSelection?: (value: any) => void
  placeholder: string
  error?: string
  infoMessage?: string
  errorOnEmpty?: boolean
  onFocus?: () => void
  onBlur?: () => void
  editable?: boolean
  formProps?: formFieldProps
  testID?: string
  ref?: React.Ref<any>
}

const getTextColor = (isValueEmpty: boolean): Color => {
  return isValueEmpty ? textInputEmptyColor : black
}

const getIconColor = (dropdownState: PFFieldBaseStateTypes): Color => {
  return dropdownState === PFFieldBaseStates.ACTIVE ? alert : black
}

const getFieldActive = (isValueEmpty: boolean, hasFocus: boolean): boolean => {
  return !isValueEmpty || hasFocus
}

//useValueForLabel will display the value instead of the label when the dropdown is closed
const getLabelValue = (
  options: PFDropdownOptionProps[],
  value: string | undefined,
  isValueEmpty: boolean,
  useValueForLabel: PFDropdownProps['useValueForLabel'],
  hasFocus: boolean,
  placeholder: string,
): string => {
  const selectedOption = options.find((item) => item.value === value)
  if (selectedOption) {
    return useValueForLabel ? selectedOption.value : selectedOption.label
  } else if (isValueEmpty && hasFocus) {
    return placeholder
  } else {
    return ' ' //using spacing here to force text render content, otherwise it will cause a flick when set value
  }
}

const PFDropdown: FC<PFDropdownProps> = forwardRef(
  (props: PFDropdownProps, ref: React.Ref<any>) => {
    const {
      value,
      label,
      editable,
      options,
      useValueForLabel,
      onFocus,
      onBlur,
      onSelection,
      placeholder,
      testID,
      infoMessage,
      error,
      errorOnEmpty,
    } = props

    const dropdownRef: RefObject<any> = useRef(null)
    useImperativeHandle(ref, () => ({
      toggle: (focus) => {
        if (isDeviceWeb()) {
          setHasFocus(!!focus)
        } else if (isDeviceIOS()) {
          dropdownRef?.current?.togglePicker(true)
        } else {
          /* android not supported */
        }
      },
    }))

    const placeholderObj: PFDropdownOptionProps = {
      label: placeholder,
      value: undefined,
      color: textDisabled,
    }
    const [dropdownState, setDropdownState] = useState<PFFieldBaseStateTypes>(
      PFFieldBaseStates.DEFAULT,
    )
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const [hasBlur, setHasBlur] = useState<boolean>(false)
    const [isFirstAttempt, setIsFirstAttempt] = useState<boolean>(true)
    const isValueEmpty: boolean = isStringValueEmpty(value)
    const animationControl: Animated.Value = useMemo(
      () => new Animated.Value(isValueEmpty ? 0 : 1),
      [isValueEmpty],
    )

    useEffect(() => {
      const errorState = getErrorState(errorOnEmpty, isValueEmpty, isFirstAttempt, error)
      setDropdownState(getBaseFieldState(errorState, isValueEmpty, hasFocus))
    }, [value, hasFocus, isValueEmpty, isFirstAttempt, errorOnEmpty, error])

    const runAnimations: (activeState: boolean) => void = useCallback(
      (activeState: boolean) => {
        Animated.timing(animationControl, {
          toValue: activeState ? 1 : 0,
          duration: animationDuration,
          useNativeDriver: false,
          easing: animationEasing,
        }).start()
      },
      [animationControl],
    )

    useEffect(() => {
      runAnimations(getFieldActive(isValueEmpty, hasFocus))
    }, [isValueEmpty, hasFocus, runAnimations])

    useEffect(() => {
      if (hasFocus) {
        onFocus?.()
      }
      // onFocus dep causes this to hook to execute far too often
      // disabling until we agree on a better solution for parent function deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFocus])

    useEffect(() => {
      if (hasBlur) {
        setHasBlur(false)
        onBlur?.()
        if (isFirstAttempt) {
          setIsFirstAttempt(false)
        }
      }
    }, [hasBlur, onBlur, isFirstAttempt])

    const iconColor = useMemo(() => getIconColor(dropdownState), [dropdownState])
    const textColor = useMemo(() => getTextColor(isValueEmpty), [isValueEmpty])

    const onValueChange = (newValue) => {
      onSelection?.(newValue)
      setIsFirstAttempt(false) // android support (this should be removed when picker supports onClose event)
    }

    const dropdownIcon: () => SvgIconProps = () => ({
      name: 'chevronDown',
      colorVariant: 'custom',
      color: iconColor,
    })

    return (
      <Dropdown
        testID={testID}
        ref={dropdownRef}
        editable={editable}
        animationControl={animationControl}
        hasFocus={hasFocus}
        value={value}
        placeholderObj={placeholderObj}
        options={options}
        setHasFocus={setHasFocus}
        setHasBlur={setHasBlur}
        onValueChange={onValueChange}
      >
        <PFFieldBase
          label={label}
          animationControl={animationControl}
          infoMessage={infoMessage}
          error={error}
          editable={editable}
          icon={dropdownIcon()}
          onPressIcon={() => setHasFocus(!hasFocus)}
          fieldState={dropdownState}
        >
          <View style={styles.textBox}>
            <PFText variant={'p'} color={textColor}>
              {getLabelValue(options, value, isValueEmpty, useValueForLabel, hasFocus, placeholder)}
            </PFText>
          </View>
        </PFFieldBase>
      </Dropdown>
    )
  },
)

PFDropdown.displayName = 'PFDropdown'

PFDropdown.defaultProps = {
  editable: true,
}

export default PFDropdown
