import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCards} from 'src/products/card/hooks/useCards'
import {CardAutopayOverviewTemplate} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewTemplate/CardAutopayOverviewTemplate'
import {WorkflowScreenProps} from 'src/workflows/types'

const CardAutopayOverviewWorkflowContainer = ({
  onScreenComplete,
}: WorkflowScreenProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_join_autopay_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {hasPendingPhysicalCard} = useCards()

  const handleOnPress = (): void => {
    TrackAppEvent(AppEvents.Name.card_autopay_overview_page_cta, AppEvents.Category.Card)

    onScreenComplete()
  }

  return (
    <CardAutopayOverviewTemplate
      onPress={handleOnPress}
      hasPendingPhysicalCard={hasPendingPhysicalCard}
    />
  )
}

export {CardAutopayOverviewWorkflowContainer}
