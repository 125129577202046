import {Applications, CreateApolloClient, LogCallback, UpdateEnvironment} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {usePfDispatch} from 'src/store/utils'

export * from '@possible/cassandra'
export * from '@possible/cassandra/src/types/consumer'
export * from './src'

export const initializeCassandra = async (
  dispatch: ReturnType<typeof usePfDispatch>,
  token?: string,
): Promise<void> => {
  const cassandraLogger: LogCallback = {
    log: Log.log,
    warn: Log.warn,
    error: Log.error,
  }

  try {
    const env = await getEnvironment()
    dispatch(UpdateEnvironment({environment: env, app: Applications.MOBILE, token}))
    CreateApolloClient(Applications.MOBILE, env, token, cassandraLogger)
  } catch (e) {
    Log.error(e, 'Failed to initialize Cassandra')
  }
}
