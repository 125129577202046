import React from 'react'
import {Pressable, StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {gutterSize, tinyGap} from 'src/designSystem/layout'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'

type ExpandableSectionProps = {
  title: string
  children: React.ReactNode
  showLoading?: boolean
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = (props) => {
  const {title, children, showLoading} = props

  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleOnPress = (): void => setIsExpanded((isExpanded) => !isExpanded)

  return (
    <Box boxStyle={styles.expandableBox}>
      {showLoading ? (
        <View style={styles.loading}>{<Loading type="loader0" size="large" />}</View>
      ) : null}
      <View style={showLoading ? styles.hiddenContent : null}>
        <Pressable onPress={handleOnPress}>
          <Box direction="row" justify="between" align="center" marginRight={-1 * gutterSize}>
            <PFText variant="p_semibold">{title}</PFText>
            <SvgIcon name={isExpanded ? 'chevronUp' : 'chevronDown'} colorVariant="default" />
          </Box>
        </Pressable>
        {isExpanded ? <Box marginTop={tinyGap}>{children}</Box> : null}
      </View>
    </Box>
  )
}

const styles = StyleSheet.create({
  expandableBox: {
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: 10,
    paddingTop: gutterSize,
    paddingBottom: gutterSize,
    paddingLeft: gutterSize * 2,
    paddingRight: gutterSize * 2,
  },
  hiddenContent: {
    opacity: 0,
  },
  loading: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
