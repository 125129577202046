/* eslint-disable */
/* This is an autogenerated file from client_mono/packages/generated/proto_ts_gen.sh */

import APIClient, {APIV2ReturnType} from '../api/lib/APIClient'
import * as protomonoTypes from './protomonoTypes'

export class AwardsApi {
  static userAwardCreate = function (
    req: protomonoTypes.awards.IUserAwardCreateRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IUserAwardCreateResponse> {
    return APIClient.apiV2SvcPut('Awards', 'UserAwardCreate', req)
  }
  static userAwardRead = function (
    req: protomonoTypes.awards.IUserAwardReadRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IUserAwardReadResponse> {
    return APIClient.apiV2SvcPut('Awards', 'UserAwardRead', req)
  }
  static userAwardReadDefault = function (
    req: protomonoTypes.awards.IUserAwardReadDefaultRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IUserAwardReadDefaultResponse> {
    return APIClient.apiV2SvcPut('Awards', 'UserAwardReadDefault', req)
  }
  static userAwardUpdate = function (
    req: protomonoTypes.awards.IUserAwardUpdateRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IUserAwardUpdateResponse> {
    return APIClient.apiV2SvcPut('Awards', 'UserAwardUpdate', req)
  }
  static userAwardRedeem = function (
    req: protomonoTypes.awards.IUserAwardRedeemRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IUserAwardRedeemResponse> {
    return APIClient.apiV2SvcPut('Awards', 'UserAwardRedeem', req)
  }
  static getReferralsByUser = function (
    req: protomonoTypes.awards.IGetReferralsByUserRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IGetReferralsByUserResponse> {
    return APIClient.apiV2SvcPut('Awards', 'GetReferralsByUser', req)
  }
  static getReferralLinkForUser = function (
    req: protomonoTypes.awards.IGetReferralLinkForUserRequest,
  ): APIV2ReturnType<protomonoTypes.awards.IGetReferralLinkForUserResponse> {
    return APIClient.apiV2SvcPut('Awards', 'GetReferralLinkForUser', req)
  }
}
export class UhuraApi {
  static sendMessage = function (
    req: protomonoTypes.uhura.IMessageRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IMessageResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'SendMessage', req)
  }
  static getTemplateList = function (
    req: protomonoTypes.uhura.IGetTemplateListRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IGetTemplateListResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'GetTemplateList', req)
  }
  static getTemplate = function (
    req: protomonoTypes.uhura.IGetTemplateRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IGetTemplateResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'GetTemplate', req)
  }
  static renderTemplate = function (
    req: protomonoTypes.uhura.IMessageRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IRenderTemplateResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'RenderTemplate', req)
  }
  static getCommunicationsForUser = function (
    req: protomonoTypes.uhura.IGetCommunicationsForUserRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IGetCommunicationsForUserResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'GetCommunicationsForUser', req)
  }
  static getContactUsForm = function (
    req: protomonoTypes.uhura.IGetContactUsFormRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IGetContactUsFormResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'GetContactUsForm', req)
  }
  static submitContactUsForm = function (
    req: protomonoTypes.uhura.ISubmitContactUsFormRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.ISubmitContactUsFormResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'SubmitContactUsForm', req)
  }
  static getUsersCommunicationPreferences = function (
    req: protomonoTypes.uhura.IUsersCommunicationPreferencesRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IUsersCommunicationPreferencesResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'GetUsersCommunicationPreferences', req)
  }
  static updateUsersCommunicationPreferences = function (
    req: protomonoTypes.uhura.IUpdateUsersCommunicationPreferencesRequest,
  ): APIV2ReturnType<protomonoTypes.uhura.IUsersCommunicationPreferencesResponse> {
    return APIClient.apiV2SvcPut('Uhura', 'UpdateUsersCommunicationPreferences', req)
  }
  static hasNewCommunications = function (
    req: protomonoTypes.uhura.IHasNewCommunicationsRequest,
  ): APIV2ReturnType<protomonoTypes.protobuf.IBoolValue> {
    return APIClient.apiV2SvcPut('Uhura', 'HasNewCommunications', req)
  }
}
export class CardsApi {
  static getCardAccountOffers = function (
    req: protomonoTypes.cards.IGetCardAccountOffersRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountOffersResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountOffers', req)
  }
  static getApplicationPreRequisites = function (
    req: protomonoTypes.cards.IGetApplicationPreRequisitesRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetApplicationPreRequisitesResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetApplicationPreRequisites', req)
  }
  static applyForCardAccount = function (
    req: protomonoTypes.cards.IApplyForCardAccountRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IApplyForCardAccountResponse> {
    return APIClient.apiV2SvcPut('Cards', 'ApplyForCardAccount', req)
  }
  static acceptApprovedCardAccount = function (
    req: protomonoTypes.cards.IAcceptApprovedCardAccountRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IAcceptApprovedCardAccountResponse> {
    return APIClient.apiV2SvcPut('Cards', 'AcceptApprovedCardAccount', req)
  }
  static declineApprovedCardAccount = function (
    req: protomonoTypes.cards.IDeclineApprovedCardAccountRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IDeclineApprovedCardAccountResponse> {
    return APIClient.apiV2SvcPut('Cards', 'DeclineApprovedCardAccount', req)
  }
  static activateCard = function (
    req: protomonoTypes.cards.IActivateCardRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IActivateCardResponse> {
    return APIClient.apiV2SvcPut('Cards', 'ActivateCard', req)
  }
  static lockActiveCardAccount = function (
    req: protomonoTypes.cards.ILockActiveCardAccountRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ILockActiveCardAccountResponse> {
    return APIClient.apiV2SvcPut('Cards', 'LockActiveCardAccount', req)
  }
  static getCardAccountBalance = function (
    req: protomonoTypes.cards.IGetCardAccountBalanceRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountBalanceResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountBalance', req)
  }
  static getCardAccountBalanceEntriesOfType = function (
    req: protomonoTypes.cards.IGetCardAccountBalanceEntriesOfTypeRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountBalanceEntriesOfTypeResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountBalanceEntriesOfType', req)
  }
  static getBalanceAtChargedOff = function (
    req: protomonoTypes.cards.IGetBalanceAtChargedOffRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetBalanceAtChargedOffResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetBalanceAtChargedOff', req)
  }
  static getCardAccountById = function (
    req: protomonoTypes.cards.IGetCardAccountByIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountByIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountById', req)
  }
  static getCardPanDecodingDataById = function (
    req: protomonoTypes.cards.IGetCardPanDecodingDataByIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardPanDecodingDataByIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardPanDecodingDataById', req)
  }
  static getCardPanDecodedDataById = function (
    req: protomonoTypes.cards.IGetCardPanDecodedDataByIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardPanDecodedDataByIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardPanDecodedDataById', req)
  }
  static getCardAccountsByUserId = function (
    req: protomonoTypes.cards.IGetCardAccountsByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountsByUserIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountsByUserId', req)
  }
  static getCardAccountsSimplifiedVersionByUserId = function (
    req: protomonoTypes.cards.IGetCardAccountsSimplifiedVersionByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountsSimplifiedVersionByUserIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountsSimplifiedVersionByUserId', req)
  }
  static setupAutoPaymentSchedule = function (
    req: protomonoTypes.cards.ISetupAutoPaymentScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ISetupAutoPaymentScheduleResponse> {
    return APIClient.apiV2SvcPut('Cards', 'SetupAutoPaymentSchedule', req)
  }
  static getAutoPaymentSchedule = function (
    req: protomonoTypes.cards.IGetAutoPaymentScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetAutoPaymentScheduleResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetAutoPaymentSchedule', req)
  }
  static getUpcomingScheduledPayments = function (
    req: protomonoTypes.cards.IGetUpcomingScheduledPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetUpcomingScheduledPaymentsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetUpcomingScheduledPayments', req)
  }
  static getPaymentHistory = function (
    req: protomonoTypes.cards.IGetPaymentHistoryRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPaymentHistoryResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPaymentHistory', req)
  }
  static getCardAccountAgreements = function (
    req: protomonoTypes.cards.IGetCardAccountAgreementsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountAgreementsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountAgreements', req)
  }
  static cardAccountAgreementDownload = function (
    req: protomonoTypes.cards.ICardAccountAgreementDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICardAccountAgreementDownloadResponse> {
    return APIClient.apiV2SvcPut('Cards', 'CardAccountAgreementDownload', req)
  }
  static acceptCardAccountAgreements = function (
    req: protomonoTypes.cards.IAcceptCardAccountAgreementsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IAcceptCardAccountAgreementsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'AcceptCardAccountAgreements', req)
  }
  static getSupportedIssuerProcessors = function (
    req: protomonoTypes.cards.ICardsServiceGetIssuerProcessorsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICardsServiceGetIssuerProcessorsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetSupportedIssuerProcessors', req)
  }
  static getTransactionHistory = function (
    req: protomonoTypes.cards.IGetTransactionHistoryRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetTransactionHistoryResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetTransactionHistory', req)
  }
  static getCardAccountDelinquencySnapshot = function (
    req: protomonoTypes.cards.IGetCardAccountDelinquencySnapshotRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountDelinquencySnapshotResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountDelinquencySnapshot', req)
  }
  static getCardAccountCreditReportingDelinquencyHistory = function (
    req: protomonoTypes.cards.IGetCardAccountCreditReportingDelinquencyHistoryRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountCreditReportingDelinquencyHistoryResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountCreditReportingDelinquencyHistory', req)
  }
  static getCardBillPaymentMethods = function (
    req: protomonoTypes.cards.IGetCardBillPaymentMethodsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardBillPaymentMethodsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardBillPaymentMethods', req)
  }
  static addCardBillPaymentMethod = function (
    req: protomonoTypes.cards.IAddCardBillPaymentMethodRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IAddCardBillPaymentMethodResponse> {
    return APIClient.apiV2SvcPut('Cards', 'AddCardBillPaymentMethod', req)
  }
  static markCardBillPaymentMethodPrimary = function (
    req: protomonoTypes.cards.IMarkCardBillPaymentMethodPrimaryRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IMarkCardBillPaymentMethodPrimaryResponse> {
    return APIClient.apiV2SvcPut('Cards', 'MarkCardBillPaymentMethodPrimary', req)
  }
  static scheduleCardBillPayment = function (
    req: protomonoTypes.cards.IScheduleCardAccountPaymentRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IScheduleCardAccountPaymentResponse> {
    return APIClient.apiV2SvcPut('Cards', 'ScheduleCardBillPayment', req)
  }
  static assignApprovalManualReviewToAssignee = function (
    req: protomonoTypes.cards.IAssignApprovalManualReviewToAssigneeRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IAssignApprovalManualReviewToAssigneeResponse> {
    return APIClient.apiV2SvcPut('Cards', 'AssignApprovalManualReviewToAssignee', req)
  }
  static getApprovalManualReviewsByCardAccountId = function (
    req: protomonoTypes.cards.IGetApprovalManualReviewsByCardAccountIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetApprovalManualReviewsByCardAccountIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetApprovalManualReviewsByCardAccountId', req)
  }
  static getNextApprovalManualReviewByAssignee = function (
    req: protomonoTypes.cards.IGetNextApprovalManualReviewByAssigneeRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetNextApprovalManualReviewByAssigneeResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetNextApprovalManualReviewByAssignee', req)
  }
  static approvalManualReviewAction = function (
    req: protomonoTypes.cards.IApprovalManualReviewActionRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IApprovalManualReviewActionResponse> {
    return APIClient.apiV2SvcPut('Cards', 'ApprovalManualReviewAction', req)
  }
  static getCardAccountPaymentByPaymentId = function (
    req: protomonoTypes.cards.IGetCardAccountPaymentByPaymentIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountPaymentByPaymentIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountPaymentByPaymentId', req)
  }
  static setCardAccountPaymentStatusByPaymentId = function (
    req: protomonoTypes.cards.ISetCardAccountPaymentStatusByPaymentIdRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ISetCardAccountPaymentStatusByPaymentIdResponse> {
    return APIClient.apiV2SvcPut('Cards', 'SetCardAccountPaymentStatusByPaymentId', req)
  }
  static getCardAccountPayments = function (
    req: protomonoTypes.cards.IGetCardAccountPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountPaymentsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountPayments', req)
  }
  static getStatements = function (
    req: protomonoTypes.cards.ICardServiceGetStatementsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICardServiceGetStatementsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetStatements', req)
  }
  static downloadStatement = function (
    req: protomonoTypes.cards.ICardServiceDownloadStatementRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICardServiceDownloadStatementResponse> {
    return APIClient.apiV2SvcPut('Cards', 'DownloadStatement', req)
  }
  static calculateInstallmentSchedule = function (
    req: protomonoTypes.cards.ICalculateInstallmentScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICalculateInstallmentScheduleResponse> {
    return APIClient.apiV2SvcPut('Cards', 'CalculateInstallmentSchedule', req)
  }
  static getCurrentInstallmentPayments = function (
    req: protomonoTypes.cards.IGetCurrentInstallmentPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCurrentInstallmentPaymentsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCurrentInstallmentPayments', req)
  }
  static getInstallmentsEligibility = function (
    req: protomonoTypes.cards.IGetInstallmentsEligibilityRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetInstallmentsEligibilityResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetInstallmentsEligibility', req)
  }
  static getPayOverTimeDetails = function (
    req: protomonoTypes.cards.IGetPayOverTimeDetailsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPayOverTimeDetailsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPayOverTimeDetails', req)
  }
  static activatePhysicalCard = function (
    req: protomonoTypes.cards.IActivatePhysicalCardRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IActivatePhysicalCardResponse> {
    return APIClient.apiV2SvcPut('Cards', 'ActivatePhysicalCard', req)
  }
  static getPotentialAutoPaymentSchedule = function (
    req: protomonoTypes.cards.IGetPotentialAutoPaymentScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPotentialAutoPaymentScheduleResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPotentialAutoPaymentSchedule', req)
  }
  static buybackUploadReport = function (
    req: protomonoTypes.cards.ICardBuybackUploadReportRequest,
  ): APIV2ReturnType<protomonoTypes.cards.ICardBuybackUploadReportResponse> {
    return APIClient.apiV2SvcPut('Cards', 'BuybackUploadReport', req)
  }
  static getPresignedAgreementUrl = function (
    req: protomonoTypes.cards.IGetPresignedAgreementUrlRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPresignedAgreementUrlResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPresignedAgreementUrl', req)
  }
  static getActiveCardPaymentPeriods = function (
    req: protomonoTypes.cards.IGetActiveCardPaymentPeriodsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetActiveCardPaymentPeriodsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetActiveCardPaymentPeriods', req)
  }
  static getCurrentCardPaymentPeriod = function (
    req: protomonoTypes.cards.IGetCurrentCardPaymentPeriodRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCurrentCardPaymentPeriodResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCurrentCardPaymentPeriod', req)
  }
  static getPreviousCardPaymentPeriods = function (
    req: protomonoTypes.cards.IGetPreviousCardPaymentPeriodsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPreviousCardPaymentPeriodsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPreviousCardPaymentPeriods', req)
  }
  static getAllCardAccountIdsPaginated = function (
    req: protomonoTypes.cards.IGetAllCardAccountIdsPaginatedRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetAllCardAccountIdsPaginatedResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetAllCardAccountIdsPaginated', req)
  }
  static getOverduePaymentInfo = function (
    req: protomonoTypes.cards.IGetOverduePaymentInfoRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetOverduePaymentInfoResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetOverduePaymentInfo', req)
  }
  static getOverdueStatementInfo = function (
    req: protomonoTypes.cards.IGetOverdueStatementInfoRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetOverdueStatementInfoResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetOverdueStatementInfo', req)
  }
  static getPotentialFeeSchedule = function (
    req: protomonoTypes.cards.IGetPotentialFeeScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPotentialFeeScheduleResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPotentialFeeSchedule', req)
  }
  static getCardAccountAuditEntries = function (
    req: protomonoTypes.cards.IGetCardAccountAuditEntriesRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountAuditEntriesResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountAuditEntries', req)
  }
  static getPayrollDatesForReset = function (
    req: protomonoTypes.cards.IGetPayrollDatesForResetRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetPayrollDatesForResetResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetPayrollDatesForReset', req)
  }
  static getCardAccountRefunds = function (
    req: protomonoTypes.cards.IGetCardAccountRefundsRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IGetCardAccountRefundsResponse> {
    return APIClient.apiV2SvcPut('Cards', 'GetCardAccountRefunds', req)
  }
  static isPaymentInstrumentInUse = function (
    req: protomonoTypes.cards.IIsPaymentInstrumentInUseRequest,
  ): APIV2ReturnType<protomonoTypes.cards.IIsPaymentInstrumentInUseResponse> {
    return APIClient.apiV2SvcPut('Cards', 'IsPaymentInstrumentInUse', req)
  }
}
export class PartnersServiceApi {
  static requestOffer = function (
    req: protomonoTypes.partners.IRequestOfferRequest,
  ): APIV2ReturnType<protomonoTypes.partners.IRequestOfferResponse> {
    return APIClient.apiV2SvcPut('PartnersService', 'RequestOffer', req)
  }
  static selectOffer = function (
    req: protomonoTypes.partners.ISelectOfferRequest,
  ): APIV2ReturnType<protomonoTypes.partners.ISelectOfferResponse> {
    return APIClient.apiV2SvcPut('PartnersService', 'SelectOffer', req)
  }
  static getCurrentOffer = function (
    req: protomonoTypes.partners.IGetCurrentOfferRequest,
  ): APIV2ReturnType<protomonoTypes.partners.IGetCurrentOfferResponse> {
    return APIClient.apiV2SvcPut('PartnersService', 'GetCurrentOffer', req)
  }
}
export class UsersApi {
  static createAuthorizationToken = function (
    req: protomonoTypes.users.ICreateAuthorizationTokenRequest,
  ): APIV2ReturnType<protomonoTypes.users.ICreateAuthorizationTokenResponse> {
    return APIClient.apiV2SvcPut('Users', 'CreateAuthorizationToken', req)
  }
  static getAuthorizationTokenByToken = function (
    req: protomonoTypes.users.IGetAuthorizationTokenRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetAuthorizationTokenByUserIdResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetAuthorizationTokenByToken', req)
  }
  static getMilitaryActiveDutyCheckByUserId = function (
    req: protomonoTypes.users.IMilitaryActiveDutyCheckByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.users.IMilitaryActiveDutyCheckByUserIdResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetMilitaryActiveDutyCheckByUserId', req)
  }
  static verifyPiiForUser = function (
    req: protomonoTypes.users.IVerifyPiiForUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IVerifyPiiForUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'VerifyPiiForUser', req)
  }
  static verifyAddressForUser = function (
    req: protomonoTypes.users.IVerifyPiiForUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IVerifyPiiForUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'VerifyAddressForUser', req)
  }
  static getLastKnownIpAddress = function (
    req: protomonoTypes.users.IGetLastKnownIpAddressByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetLastKnownIpAddressResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetLastKnownIpAddress', req)
  }
  static getUserEnvEx = function (
    req: protomonoTypes.users.IGetUserEnvExRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserEnvExResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserEnvEx', req)
  }
  static setUserEnvEx = function (
    req: protomonoTypes.users.ISetUserEnvExRequest,
  ): APIV2ReturnType<protomonoTypes.users.ISetUserEnvExResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserEnvEx', req)
  }
  static createUser = function (
    req: protomonoTypes.users.ICreateUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.ICreateUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'CreateUser', req)
  }
  static updateUser = function (
    req: protomonoTypes.users.IUpdateUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUpdateUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'UpdateUser', req)
  }
  static setUserStatus = function (
    req: protomonoTypes.users.ISetUserStatusRequest,
  ): APIV2ReturnType<protomonoTypes.users.ISetUserStatusResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserStatus', req)
  }
  static setUserSubStatus = function (
    req: protomonoTypes.users.ISetUserSubStatusRequest,
  ): APIV2ReturnType<protomonoTypes.users.ISetUserSubStatusResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserSubStatus', req)
  }
  static updateUserEligibility = function (
    req: protomonoTypes.users.IUpdateUserEligibilityRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUpdateUserEligibilityResponse> {
    return APIClient.apiV2SvcPut('Users', 'UpdateUserEligibility', req)
  }
  static getUser = function (
    req: protomonoTypes.users.IGetUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUsersResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUser', req)
  }
  static getUserById = function (
    req: protomonoTypes.users.IGetUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserById', req)
  }
  static getUserByIdentityProviderId = function (
    req: protomonoTypes.users.IGetUserByIdentityProviderIdRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserByIdentityProviderId', req)
  }
  static linkIdentityProviderToUser = function (
    req: protomonoTypes.users.ILinkIdentityProviderToUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.ILinkIdentityProviderToUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'LinkIdentityProviderToUser', req)
  }
  static getUsersById = function (
    req: protomonoTypes.users.IGetUsersRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUsersResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUsersById', req)
  }
  static getUserByPhone = function (
    req: protomonoTypes.users.IGetUserByPhoneRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserByPhone', req)
  }
  static getMatchingUsers = function (
    req: protomonoTypes.users.IGetMatchingUsersRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetMatchingUsersResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetMatchingUsers', req)
  }
  static accountRecovery = function (
    req: protomonoTypes.users.IAccountRecoveryRequest,
  ): APIV2ReturnType<protomonoTypes.users.IAccountRecoveryResponse> {
    return APIClient.apiV2SvcPut('Users', 'AccountRecovery', req)
  }
  static deleteUser = function (
    req: protomonoTypes.users.IDeleteUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IDeleteUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'DeleteUser', req)
  }
  static consumerInquiry = function (
    req: protomonoTypes.users.IConsumerInquiryRequest,
  ): APIV2ReturnType<protomonoTypes.users.IConsumerInquiryResponse> {
    return APIClient.apiV2SvcPut('Users', 'ConsumerInquiry', req)
  }
  static getUsersWithMatchingIdNumbers = function (
    req: protomonoTypes.users.IGetUsersWithMatchingIdNumbersRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUsersWithMatchingIdNumbersResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUsersWithMatchingIdNumbers', req)
  }
  static getUserFraud = function (
    req: protomonoTypes.users.IGetUserFraudRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserFraudResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserFraud', req)
  }
  static startSession = function (
    req: protomonoTypes.common.IStartSessionRequest,
  ): APIV2ReturnType<protomonoTypes.common.IStartSessionResponse> {
    return APIClient.apiV2SvcPut('Users', 'StartSession', req)
  }
  static getDuplicateUserIdsForUserByDevice = function (
    req: protomonoTypes.users.IGetDuplicateUserIdsForUserByDeviceRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetDuplicateUserIdsForUserByDeviceResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetDuplicateUserIdsForUserByDevice', req)
  }
  static getMostRecentUserSession = function (
    req: protomonoTypes.users.IGetMostRecentUserSessionRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetMostRecentUserSessionRespone> {
    return APIClient.apiV2SvcPut('Users', 'GetMostRecentUserSession', req)
  }
  static getSignedResourceKey = function (
    req: protomonoTypes.users.IGetSignedResourceKeyRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetSignedResourceKeyResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetSignedResourceKey', req)
  }
  static userMiscPropNew = function (
    req: protomonoTypes.users.IUserMiscPropertyNewRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUserMiscPropertyNewResponse> {
    return APIClient.apiV2SvcPut('Users', 'UserMiscPropNew', req)
  }
  static userMiscPropQuery = function (
    req: protomonoTypes.users.IUserMiscPropertyQueryRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUserMiscPropertyQueryResponse> {
    return APIClient.apiV2SvcPut('Users', 'UserMiscPropQuery', req)
  }
  static userMiscPropUpdate = function (
    req: protomonoTypes.users.IUserMiscPropertyUpdateRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUserMiscPropertyUpdateResponse> {
    return APIClient.apiV2SvcPut('Users', 'UserMiscPropUpdate', req)
  }
  static getUserProperty = function (
    req: protomonoTypes.users.IGetUserPropertyRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserPropertyResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserProperty', req)
  }
  static getUserProperties = function (
    req: protomonoTypes.users.IGetUserPropertiesRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserPropertiesResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserProperties', req)
  }
  static setUserAddressBookEntry = function (
    req: protomonoTypes.users.ISetUserAddressBookEntryRequest,
  ): APIV2ReturnType<protomonoTypes.users.ISetUserAddressBookEntryResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserAddressBookEntry', req)
  }
  static getUserAddress = function (
    req: protomonoTypes.users.IGetUserAddressRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserAddressResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserAddress', req)
  }
  static getUserAddressBook = function (
    req: protomonoTypes.users.IGetUserAddressBookRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserAddressBookResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserAddressBook', req)
  }
  static getUserVerifications = function (
    req: protomonoTypes.users.IGetUserVerificationsRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserVerificationsResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserVerifications', req)
  }
  static authenticateUser = function (
    req: protomonoTypes.users.IAuthenticateUserRequest,
  ): APIV2ReturnType<protomonoTypes.users.IAuthenticateUserResponse> {
    return APIClient.apiV2SvcPut('Users', 'AuthenticateUser', req)
  }
  static setUserCreditReportingDisabled = function (
    req: protomonoTypes.users.ISetUserCreditReportingDisabledRequest,
  ): APIV2ReturnType<protomonoTypes.users.ISetUserCreditReportingDisabledResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserCreditReportingDisabled', req)
  }
  static getUserNotificationTokens = function (
    req: protomonoTypes.users.IGetUserNotificationTokenRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserNotificationTokenResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserNotificationTokens', req)
  }
  static setUserPreferredLanguage = function (
    req: protomonoTypes.users.IUserSetPreferredLanguageRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUserSetPreferredLanguageResponse> {
    return APIClient.apiV2SvcPut('Users', 'SetUserPreferredLanguage', req)
  }
  static getUserNotes = function (
    req: protomonoTypes.users.IGetUserNotesRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserNotesResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserNotes', req)
  }
  static createUserNote = function (
    req: protomonoTypes.users.ICreateUserNoteRequest,
  ): APIV2ReturnType<protomonoTypes.users.ICreateUserNoteResponse> {
    return APIClient.apiV2SvcPut('Users', 'CreateUserNote', req)
  }
  static updateUserNote = function (
    req: protomonoTypes.users.IUpdateUserNoteRequest,
  ): APIV2ReturnType<protomonoTypes.users.IUpdateUserNoteResponse> {
    return APIClient.apiV2SvcPut('Users', 'UpdateUserNote', req)
  }
  static requestMfa = function (
    req: protomonoTypes.users.IRequestMfaRequest,
  ): APIV2ReturnType<protomonoTypes.users.IRequestMfaResponse> {
    return APIClient.apiV2SvcPut('Users', 'RequestMfa', req)
  }
  static validateMfa = function (
    req: protomonoTypes.users.IValidateMfaRequest,
  ): APIV2ReturnType<protomonoTypes.users.IValidateMfaResponse> {
    return APIClient.apiV2SvcPut('Users', 'ValidateMfa', req)
  }
  static shouldRefreshMfa = function (
    req: protomonoTypes.users.IShouldRefreshMfaRequest,
  ): APIV2ReturnType<protomonoTypes.users.IShouldRefreshMfaResponse> {
    return APIClient.apiV2SvcPut('Users', 'ShouldRefreshMfa', req)
  }
  static getUserLockout = function (
    req: protomonoTypes.users.IGetUserLockoutRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetUserLockoutResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetUserLockout', req)
  }
  static getCurrentUserBankruptcyRecord = function (
    req: protomonoTypes.users.IGetCurrentUserBankruptcyRecordRequest,
  ): APIV2ReturnType<protomonoTypes.users.IGetCurrentUserBankruptcyRecordResponse> {
    return APIClient.apiV2SvcPut('Users', 'GetCurrentUserBankruptcyRecord', req)
  }
}
export class UnderwritingServiceApi {
  static getUnderwriting = function (
    req: protomonoTypes.underwriting.IUnderwritingRequest,
  ): APIV2ReturnType<protomonoTypes.underwriting.IUnderwritingResponse> {
    return APIClient.apiV2SvcPut('UnderwritingService', 'GetUnderwriting', req)
  }
  static getPaymentSchedule = function (
    req: protomonoTypes.underwriting.IPaymentScheduleRequest,
  ): APIV2ReturnType<protomonoTypes.underwriting.IPaymentScheduleResponse> {
    return APIClient.apiV2SvcPut('UnderwritingService', 'GetPaymentSchedule', req)
  }
  static getPartnerUnderwritingOffer = function (
    req: protomonoTypes.underwriting.IPartnerUnderwritingRequest,
  ): APIV2ReturnType<protomonoTypes.underwriting.IPartnerUnderwritingResponse> {
    return APIClient.apiV2SvcPut('UnderwritingService', 'GetPartnerUnderwritingOffer', req)
  }
}
export class LoansApi {
  static getLoanOffers = function (
    req: protomonoTypes.loans.IGetLoanOffersRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanOffersResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanOffers', req)
  }
  static suspendLoan = function (
    req: protomonoTypes.loans.ISuspendLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISuspendLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SuspendLoan', req)
  }
  static listPayments = function (
    req: protomonoTypes.loans.IListPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IListPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ListPayments', req)
  }
  static failPayment = function (
    req: protomonoTypes.loans.IFailPaymentRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IFailPaymentResponse> {
    return APIClient.apiV2SvcPut('Loans', 'FailPayment', req)
  }
  static getLoanPaymentMethods = function (
    req: protomonoTypes.loans.IGetLoanPaymentMethodsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanPaymentMethodsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanPaymentMethods', req)
  }
  static validatePaymentInstruments = function (
    req: protomonoTypes.loans.IValidatePaymentInstrumentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IValidatePaymentInstrumentsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ValidatePaymentInstruments', req)
  }
  static getLoanFundingStatus = function (
    req: protomonoTypes.loans.IGetLoanFundingStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanFundingStatusResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanFundingStatus', req)
  }
  static getNextAvailablePaymentDate = function (
    req: protomonoTypes.loans.IGetNextAvailablePaymentDateRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetNextAvailablePaymentDateResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetNextAvailablePaymentDate', req)
  }
  static getLoanStatusHistoryByLoanId = function (
    req: protomonoTypes.loans.IGetLoanStatusHistoryByLoanIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanStatusHistoryByLoanIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanStatusHistoryByLoanId', req)
  }
  static getLoansByStatus = function (
    req: protomonoTypes.loans.IGetLoansByStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansByStatusResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoansByStatus', req)
  }
  static getPrimaryLoanTypeByRegion = function (
    req: protomonoTypes.loans.IGetPrimaryLoanTypeByRegionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrimaryLoanTypeByRegionResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetPrimaryLoanTypeByRegion', req)
  }
  static getPrimaryLoanTypeByState = function (
    req: protomonoTypes.loans.IGetPrimaryLoanTypeByStateRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrimaryLoanTypeByStateResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetPrimaryLoanTypeByState', req)
  }
  static getLoanTypesByStatus = function (
    req: protomonoTypes.loans.IGetLoanTypesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTypesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanTypesByStatus', req)
  }
  static getLoanTypeById = function (
    req: protomonoTypes.loans.IGetLoanTypeByIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTypeByIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanTypeById', req)
  }
  static getActiveLoanTypesByRegion = function (
    req: protomonoTypes.loans.IGetActiveLoanTypesByRegionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetActiveLoanTypesByRegionResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetActiveLoanTypesByRegion', req)
  }
  static getLoanNotesByLoanId = function (
    req: protomonoTypes.loans.IGetLoanNotesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNotesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanNotesByLoanId', req)
  }
  static createLoanNote = function (
    req: protomonoTypes.loans.ICreateLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CreateLoanNote', req)
  }
  static updateLoanNote = function (
    req: protomonoTypes.loans.IUpdateLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans', 'UpdateLoanNote', req)
  }
  static deleteLoanNote = function (
    req: protomonoTypes.loans.IDeleteLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans', 'DeleteLoanNote', req)
  }
  static generateUnderwritingDataByLoanId = function (
    req: protomonoTypes.loans.IGenerateUnderwritingDataRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUnderwritingDataResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GenerateUnderwritingDataByLoanId', req)
  }
  static setLoanUnderwritingByLoanId = function (
    req: protomonoTypes.loans.ISetLoanUnderwritingByIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanUnderwritingByIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SetLoanUnderwritingByLoanId', req)
  }
  static getLoanRoles = function (
    req: protomonoTypes.loans.IGetLoanRolesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanRolesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanRoles', req)
  }
  static getLoansByUserId = function (
    req: protomonoTypes.loans.IGetLoansByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoansByUserId', req)
  }
  static getLoansSimplifiedVersionByUserId = function (
    req: protomonoTypes.loans.IGetLoansSimplifiedVersionByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansSimplifiedVersionByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoansSimplifiedVersionByUserId', req)
  }
  static getLoanCount = function (
    req: protomonoTypes.loans.IGetLoanCountRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanCountResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanCount', req)
  }
  static getLoan = function (
    req: protomonoTypes.loans.IGetLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoan', req)
  }
  static createLoan = function (
    req: protomonoTypes.loans.ICreateLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICreateLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CreateLoan', req)
  }
  static setPaymentDates = function (
    req: protomonoTypes.loans.ISetPaymentDatesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetPaymentDatesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SetPaymentDates', req)
  }
  static payNow = function (
    req: protomonoTypes.loans.IPayNowRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IPayNowResponse> {
    return APIClient.apiV2SvcPut('Loans', 'PayNow', req)
  }
  static getCustomPaymentBalances = function (
    req: protomonoTypes.loans.IGetCustomPaymentBalancesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetCustomPaymentBalancesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetCustomPaymentBalances', req)
  }
  static submitCustomPayment = function (
    req: protomonoTypes.loans.ISubmitCustomPaymentRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISubmitCustomPaymentResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SubmitCustomPayment', req)
  }
  static getDisbursement = function (
    req: protomonoTypes.loans.IGetDisbursementRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetDisbursementResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetDisbursement', req)
  }
  static getLastTransaction = function (
    req: protomonoTypes.loans.IGetLastTransactionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLastTransactionResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLastTransaction', req)
  }
  static getChargeOffInformation = function (
    req: protomonoTypes.loans.IGetChargeOffInformationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetChargeOffInformationResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetChargeOffInformation', req)
  }
  static changePaymentDates = function (
    req: protomonoTypes.loans.IChangePaymentDatesRequest,
  ): APIV2ReturnType<protomonoTypes.lag.IChangePaymentDatesResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ChangePaymentDates', req)
  }
  static setStatus = function (
    req: protomonoTypes.loans.ISetLoanStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanStatusResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SetStatus', req)
  }
  static cancelLoan = function (
    req: protomonoTypes.loans.ICancelLoanByLoanIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CancelLoan', req)
  }
  static cancelActiveLoansByUserId = function (
    req: protomonoTypes.loans.ICancelActiveLoansByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICancelActiveLoansByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CancelActiveLoansByUserId', req)
  }
  static getConversionOptions = function (
    req: protomonoTypes.loans.IGetConversionOptionsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetConversionOptionsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetConversionOptions', req)
  }
  static convertLoan = function (
    req: protomonoTypes.loans.IConvertLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IConvertLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ConvertLoan', req)
  }
  static readUnderwritingStages = function (
    req: protomonoTypes.loans.IUnderwritingStepsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUnderwritingStepsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ReadUnderwritingStages', req)
  }
  static updateLoanPaymentStatus = function (
    req: protomonoTypes.loans.IUpdateLoanPaymentStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUpdateLoanPaymentStatusResponse> {
    return APIClient.apiV2SvcPut('Loans', 'UpdateLoanPaymentStatus', req)
  }
  static cancelPayments = function (
    req: protomonoTypes.loans.ICancelPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICancelPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CancelPayments', req)
  }
  static failPayments = function (
    req: protomonoTypes.loans.IFailPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IFailPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'FailPayments', req)
  }
  static listPaymentDateAvailability = function (
    req: protomonoTypes.loans.IListPaymentDateAvailabilityRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IListPaymentDateAvailabilityResponse> {
    return APIClient.apiV2SvcPut('Loans', 'ListPaymentDateAvailability', req)
  }
  static setLinkedAccountAsPrimaryFundingSource = function (
    req: protomonoTypes.loans.ISetPrimaryFundingSourceRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetPrimaryFundingSourceResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SetLinkedAccountAsPrimaryFundingSource', req)
  }
  static updateLoanPaymentMethods = function (
    req: protomonoTypes.loans.IUpdateLoanPaymentMethodsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUpdateLoanPaymentMethodsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'UpdateLoanPaymentMethods', req)
  }
  static getLoansForWorkflowSummary = function (
    req: protomonoTypes.loans.IGetLoansForWorkflowSummaryRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansForWorkflowSummaryResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoansForWorkflowSummary', req)
  }
  static setLoanGrossMonthlyIncome = function (
    req: protomonoTypes.loans.ISetLoanGrossMonthlyIncomeRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanGrossMonthlyIncomeResponse> {
    return APIClient.apiV2SvcPut('Loans', 'SetLoanGrossMonthlyIncome', req)
  }
  static acceptLoan = function (
    req: protomonoTypes.loans.IAcceptLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IAcceptLoanResponse> {
    return APIClient.apiV2SvcPut('Loans', 'AcceptLoan', req)
  }
  static getPrequalification = function (
    req: protomonoTypes.loans.IGetPrequalificationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrequalificationResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetPrequalification', req)
  }
  static getCurrentSettlementOffer = function (
    req: protomonoTypes.loans.IGetCurrentSettlementOfferRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetCurrentSettlementOfferResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetCurrentSettlementOffer', req)
  }
  static getLoanDocumentForDownload = function (
    req: protomonoTypes.loans.IGetLoanDocumentForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanDocumentForDownload', req)
  }
  static getLoanDocumentsForDownload = function (
    req: protomonoTypes.loans.IGetLoanDocumentsForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanDocumentsForDownload', req)
  }
  static getLoanDocuments = function (
    req: protomonoTypes.loans.IGetLoanDocumentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanDocuments', req)
  }
  static getLoanDocumentsHistory = function (
    req: protomonoTypes.loans.IGetLoanDocumentsHistoryRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsHistoryResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanDocumentsHistory', req)
  }
  static transformLoanDocumentKeyForDownload = function (
    req: protomonoTypes.loans.ITransformLoanDocumentKeyForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ITransformLoanDocumentKeyForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans', 'TransformLoanDocumentKeyForDownload', req)
  }
  static calculateAmortization = function (
    req: protomonoTypes.loans.ICalculateAmortizationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICalculateAmortizationResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CalculateAmortization', req)
  }
  static generateLoanBuybackUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GenerateLoanBuybackUploadUrl', req)
  }
  static generateLoanPlacementPossibleDataUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GenerateLoanPlacementPossibleDataUploadUrl', req)
  }
  static generateLoanPlacementPartnerDataUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GenerateLoanPlacementPartnerDataUploadUrl', req)
  }
  static checkPayNowEligibility = function (
    req: protomonoTypes.loans.ICheckPayNowEligibilityRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICheckPayNowEligibilityResponse> {
    return APIClient.apiV2SvcPut('Loans', 'CheckPayNowEligibility', req)
  }
  static getLoanTradeLine = function (
    req: protomonoTypes.loans.IGetLoanTradeLineRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTradeLineResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetLoanTradeLine', req)
  }
  static getActiveLoanAlerts = function (
    req: protomonoTypes.loans.IGetActiveLoanAlertsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetActiveLoanAlertsResponse> {
    return APIClient.apiV2SvcPut('Loans', 'GetActiveLoanAlerts', req)
  }
}
export class Loans2Api {
  static getLoanOffers = function (
    req: protomonoTypes.loans.IGetLoanOffersRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanOffersResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanOffers', req)
  }
  static suspendLoan = function (
    req: protomonoTypes.loans.ISuspendLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISuspendLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SuspendLoan', req)
  }
  static listPayments = function (
    req: protomonoTypes.loans.IListPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IListPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ListPayments', req)
  }
  static failPayment = function (
    req: protomonoTypes.loans.IFailPaymentRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IFailPaymentResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'FailPayment', req)
  }
  static getLoanPaymentMethods = function (
    req: protomonoTypes.loans.IGetLoanPaymentMethodsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanPaymentMethodsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanPaymentMethods', req)
  }
  static validatePaymentInstruments = function (
    req: protomonoTypes.loans.IValidatePaymentInstrumentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IValidatePaymentInstrumentsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ValidatePaymentInstruments', req)
  }
  static getLoanFundingStatus = function (
    req: protomonoTypes.loans.IGetLoanFundingStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanFundingStatusResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanFundingStatus', req)
  }
  static getNextAvailablePaymentDate = function (
    req: protomonoTypes.loans.IGetNextAvailablePaymentDateRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetNextAvailablePaymentDateResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetNextAvailablePaymentDate', req)
  }
  static getLoanStatusHistoryByLoanId = function (
    req: protomonoTypes.loans.IGetLoanStatusHistoryByLoanIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanStatusHistoryByLoanIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanStatusHistoryByLoanId', req)
  }
  static getLoansByStatus = function (
    req: protomonoTypes.loans.IGetLoansByStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansByStatusResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoansByStatus', req)
  }
  static getPrimaryLoanTypeByRegion = function (
    req: protomonoTypes.loans.IGetPrimaryLoanTypeByRegionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrimaryLoanTypeByRegionResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetPrimaryLoanTypeByRegion', req)
  }
  static getPrimaryLoanTypeByState = function (
    req: protomonoTypes.loans.IGetPrimaryLoanTypeByStateRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrimaryLoanTypeByStateResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetPrimaryLoanTypeByState', req)
  }
  static getLoanTypesByStatus = function (
    req: protomonoTypes.loans.IGetLoanTypesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTypesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanTypesByStatus', req)
  }
  static getLoanTypeById = function (
    req: protomonoTypes.loans.IGetLoanTypeByIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTypeByIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanTypeById', req)
  }
  static getActiveLoanTypesByRegion = function (
    req: protomonoTypes.loans.IGetActiveLoanTypesByRegionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetActiveLoanTypesByRegionResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetActiveLoanTypesByRegion', req)
  }
  static getLoanNotesByLoanId = function (
    req: protomonoTypes.loans.IGetLoanNotesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNotesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanNotesByLoanId', req)
  }
  static createLoanNote = function (
    req: protomonoTypes.loans.ICreateLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CreateLoanNote', req)
  }
  static updateLoanNote = function (
    req: protomonoTypes.loans.IUpdateLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'UpdateLoanNote', req)
  }
  static deleteLoanNote = function (
    req: protomonoTypes.loans.IDeleteLoanNoteRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanNoteResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'DeleteLoanNote', req)
  }
  static generateUnderwritingDataByLoanId = function (
    req: protomonoTypes.loans.IGenerateUnderwritingDataRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUnderwritingDataResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GenerateUnderwritingDataByLoanId', req)
  }
  static setLoanUnderwritingByLoanId = function (
    req: protomonoTypes.loans.ISetLoanUnderwritingByIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanUnderwritingByIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SetLoanUnderwritingByLoanId', req)
  }
  static getLoanRoles = function (
    req: protomonoTypes.loans.IGetLoanRolesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanRolesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanRoles', req)
  }
  static getLoansByUserId = function (
    req: protomonoTypes.loans.IGetLoansByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoansByUserId', req)
  }
  static getLoansSimplifiedVersionByUserId = function (
    req: protomonoTypes.loans.IGetLoansSimplifiedVersionByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansSimplifiedVersionByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoansSimplifiedVersionByUserId', req)
  }
  static getLoanCount = function (
    req: protomonoTypes.loans.IGetLoanCountRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanCountResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanCount', req)
  }
  static getLoan = function (
    req: protomonoTypes.loans.IGetLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoan', req)
  }
  static createLoan = function (
    req: protomonoTypes.loans.ICreateLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICreateLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CreateLoan', req)
  }
  static setPaymentDates = function (
    req: protomonoTypes.loans.ISetPaymentDatesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetPaymentDatesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SetPaymentDates', req)
  }
  static payNow = function (
    req: protomonoTypes.loans.IPayNowRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IPayNowResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'PayNow', req)
  }
  static getCustomPaymentBalances = function (
    req: protomonoTypes.loans.IGetCustomPaymentBalancesRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetCustomPaymentBalancesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetCustomPaymentBalances', req)
  }
  static submitCustomPayment = function (
    req: protomonoTypes.loans.ISubmitCustomPaymentRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISubmitCustomPaymentResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SubmitCustomPayment', req)
  }
  static getDisbursement = function (
    req: protomonoTypes.loans.IGetDisbursementRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetDisbursementResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetDisbursement', req)
  }
  static getLastTransaction = function (
    req: protomonoTypes.loans.IGetLastTransactionRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLastTransactionResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLastTransaction', req)
  }
  static getChargeOffInformation = function (
    req: protomonoTypes.loans.IGetChargeOffInformationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetChargeOffInformationResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetChargeOffInformation', req)
  }
  static changePaymentDates = function (
    req: protomonoTypes.loans.IChangePaymentDatesRequest,
  ): APIV2ReturnType<protomonoTypes.lag.IChangePaymentDatesResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ChangePaymentDates', req)
  }
  static setStatus = function (
    req: protomonoTypes.loans.ISetLoanStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanStatusResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SetStatus', req)
  }
  static cancelLoan = function (
    req: protomonoTypes.loans.ICancelLoanByLoanIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CancelLoan', req)
  }
  static cancelActiveLoansByUserId = function (
    req: protomonoTypes.loans.ICancelActiveLoansByUserIdRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICancelActiveLoansByUserIdResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CancelActiveLoansByUserId', req)
  }
  static getConversionOptions = function (
    req: protomonoTypes.loans.IGetConversionOptionsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetConversionOptionsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetConversionOptions', req)
  }
  static convertLoan = function (
    req: protomonoTypes.loans.IConvertLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IConvertLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ConvertLoan', req)
  }
  static readUnderwritingStages = function (
    req: protomonoTypes.loans.IUnderwritingStepsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUnderwritingStepsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ReadUnderwritingStages', req)
  }
  static updateLoanPaymentStatus = function (
    req: protomonoTypes.loans.IUpdateLoanPaymentStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUpdateLoanPaymentStatusResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'UpdateLoanPaymentStatus', req)
  }
  static cancelPayments = function (
    req: protomonoTypes.loans.ICancelPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICancelPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CancelPayments', req)
  }
  static failPayments = function (
    req: protomonoTypes.loans.IFailPaymentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IFailPaymentsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'FailPayments', req)
  }
  static listPaymentDateAvailability = function (
    req: protomonoTypes.loans.IListPaymentDateAvailabilityRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IListPaymentDateAvailabilityResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'ListPaymentDateAvailability', req)
  }
  static setLinkedAccountAsPrimaryFundingSource = function (
    req: protomonoTypes.loans.ISetPrimaryFundingSourceRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetPrimaryFundingSourceResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SetLinkedAccountAsPrimaryFundingSource', req)
  }
  static updateLoanPaymentMethods = function (
    req: protomonoTypes.loans.IUpdateLoanPaymentMethodsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IUpdateLoanPaymentMethodsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'UpdateLoanPaymentMethods', req)
  }
  static getLoansForWorkflowSummary = function (
    req: protomonoTypes.loans.IGetLoansForWorkflowSummaryRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoansForWorkflowSummaryResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoansForWorkflowSummary', req)
  }
  static setLoanGrossMonthlyIncome = function (
    req: protomonoTypes.loans.ISetLoanGrossMonthlyIncomeRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ISetLoanGrossMonthlyIncomeResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'SetLoanGrossMonthlyIncome', req)
  }
  static acceptLoan = function (
    req: protomonoTypes.loans.IAcceptLoanRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IAcceptLoanResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'AcceptLoan', req)
  }
  static getPrequalification = function (
    req: protomonoTypes.loans.IGetPrequalificationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetPrequalificationResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetPrequalification', req)
  }
  static getCurrentSettlementOffer = function (
    req: protomonoTypes.loans.IGetCurrentSettlementOfferRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetCurrentSettlementOfferResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetCurrentSettlementOffer', req)
  }
  static getLoanDocumentForDownload = function (
    req: protomonoTypes.loans.IGetLoanDocumentForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanDocumentForDownload', req)
  }
  static getLoanDocumentsForDownload = function (
    req: protomonoTypes.loans.IGetLoanDocumentsForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanDocumentsForDownload', req)
  }
  static getLoanDocuments = function (
    req: protomonoTypes.loans.IGetLoanDocumentsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanDocuments', req)
  }
  static getLoanDocumentsHistory = function (
    req: protomonoTypes.loans.IGetLoanDocumentsHistoryRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanDocumentsHistoryResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanDocumentsHistory', req)
  }
  static transformLoanDocumentKeyForDownload = function (
    req: protomonoTypes.loans.ITransformLoanDocumentKeyForDownloadRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ITransformLoanDocumentKeyForDownloadResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'TransformLoanDocumentKeyForDownload', req)
  }
  static calculateAmortization = function (
    req: protomonoTypes.loans.ICalculateAmortizationRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICalculateAmortizationResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CalculateAmortization', req)
  }
  static generateLoanBuybackUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GenerateLoanBuybackUploadUrl', req)
  }
  static generateLoanPlacementPossibleDataUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GenerateLoanPlacementPossibleDataUploadUrl', req)
  }
  static generateLoanPlacementPartnerDataUploadUrl = function (
    req: protomonoTypes.loans.IGenerateUploadUrlRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGenerateUploadUrlResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GenerateLoanPlacementPartnerDataUploadUrl', req)
  }
  static checkPayNowEligibility = function (
    req: protomonoTypes.loans.ICheckPayNowEligibilityRequest,
  ): APIV2ReturnType<protomonoTypes.loans.ICheckPayNowEligibilityResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'CheckPayNowEligibility', req)
  }
  static getLoanTradeLine = function (
    req: protomonoTypes.loans.IGetLoanTradeLineRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetLoanTradeLineResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetLoanTradeLine', req)
  }
  static getActiveLoanAlerts = function (
    req: protomonoTypes.loans.IGetActiveLoanAlertsRequest,
  ): APIV2ReturnType<protomonoTypes.loans.IGetActiveLoanAlertsResponse> {
    return APIClient.apiV2SvcPut('Loans2', 'GetActiveLoanAlerts', req)
  }
}
export class DonkeyApi {
  static applyStep = function (
    req: protomonoTypes.donkey.IApplyStepRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IApplyStepResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'ApplyStep', req)
  }
  static usersDelete = function (
    req: protomonoTypes.donkey.IUsersDeleteRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUsersDeleteResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UsersDelete', req)
  }
  static usersRead = function (
    req: protomonoTypes.donkey.IUsersReadRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUsersReadResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UsersRead', req)
  }
  static usersUpdateFlag = function (
    req: protomonoTypes.donkey.IUsersUpdateFlagsRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUsersUpdateFlagsResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UsersUpdateFlag', req)
  }
  static usersCreate = function (
    req: protomonoTypes.donkey.IUsersCreateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUsersCreateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UsersCreate', req)
  }
  static templatesCreate = function (
    req: protomonoTypes.donkey.ITemplatesCreateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITemplatesCreateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TemplatesCreate', req)
  }
  static templatesDelete = function (
    req: protomonoTypes.donkey.ITemplatesDeleteRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITemplatesDeleteResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TemplatesDelete', req)
  }
  static templatesRead = function (
    req: protomonoTypes.donkey.ITemplatesReadRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITemplatesReadResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TemplatesRead', req)
  }
  static templatesUpdate = function (
    req: protomonoTypes.donkey.ITemplatesUpdateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITemplatesUpdateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TemplatesUpdate', req)
  }
  static transactionDataRead = function (
    req: protomonoTypes.donkey.ITransactionDataReadRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITransactionDataReadResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TransactionDataRead', req)
  }
  static transactionDataCreate = function (
    req: protomonoTypes.donkey.ITransactionDataCreateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITransactionDataCreateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TransactionDataCreate', req)
  }
  static transactionDataDelete = function (
    req: protomonoTypes.donkey.ITransactionDataDeleteRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITransactionDataDeleteResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TransactionDataDelete', req)
  }
  static transactionDataRetrieve = function (
    req: protomonoTypes.donkey.ITransactionDataRetrieveRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ITransactionDataRetrieveResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'TransactionDataRetrieve', req)
  }
  static getBankBalance = function (
    req: protomonoTypes.donkey.IGetBankBalanceRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IGetBankBalanceResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'GetBankBalance', req)
  }
  static uiBranchesGet = function (
    req: protomonoTypes.donkey.IUiBranchesGetRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUiBranchesGetResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UiBranchesGet', req)
  }
  static userGetField = function (
    req: protomonoTypes.donkey.IUserGetFieldRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IUserGetFieldResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'UserGetField', req)
  }
  static getUserFlags = function (
    req: protomonoTypes.donkey.IGetUserFlagsRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IGetUserFlagsResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'GetUserFlags', req)
  }
  static checkUserFlag = function (
    req: protomonoTypes.donkey.ICheckUserFlagRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.ICheckUserFlagResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'CheckUserFlag', req)
  }
  static isLoanSetToBeAutoAccepted = function (
    req: protomonoTypes.donkey.IIsLoanSetToBeAutoAcceptedRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IIsLoanSetToBeAutoAcceptedResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'IsLoanSetToBeAutoAccepted', req)
  }
  static getLoanOriginationDate = function (
    req: protomonoTypes.donkey.IGetLoanOriginationDateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IGetLoanOriginationDateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'GetLoanOriginationDate', req)
  }
  static getLoanDecisionDate = function (
    req: protomonoTypes.donkey.IGetLoanDecisionDateRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IGetLoanDecisionDateResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'GetLoanDecisionDate', req)
  }
  static getLoanPaymentStatus = function (
    req: protomonoTypes.donkey.IGetLoanPaymentStatusRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IGetLoanPaymentStatusResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'GetLoanPaymentStatus', req)
  }
  static shouldExecuteFundingAuthorization = function (
    req: protomonoTypes.donkey.IShouldExecuteFundingAuthorizationRequest,
  ): APIV2ReturnType<protomonoTypes.donkey.IShouldExecuteFundingAuthorizationResponse> {
    return APIClient.apiV2SvcPut('Donkey', 'ShouldExecuteFundingAuthorization', req)
  }
}
export class LoanWorkflowApi {
  static auditPendingLoans = function (
    req: protomonoTypes.loanWorkflow.IAuditPendingLoansRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.IAuditPendingLoansResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'AuditPendingLoans', req)
  }
  static loanWorkflowTerminateByLoanId = function (
    req: protomonoTypes.loanWorkflow.ILoanWorkflowTerminateRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.ILoanWorkflowTerminateResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'LoanWorkflowTerminateByLoanId', req)
  }
  static loanWorkflowResumeByLoanId = function (
    req: protomonoTypes.loanWorkflow.ILoanWorkflowResumeRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.ILoanWorkflowResumeResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'LoanWorkflowResumeByLoanId', req)
  }
  static loanWorkflowCurrentReviewAssignments = function (
    req: protomonoTypes.loanWorkflow.ILoanWorkflowCurrentReviewAssignmentsRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.ILoanWorkflowCurrentReviewAssignmentsResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'LoanWorkflowCurrentReviewAssignments', req)
  }
  static loanWorkflowGetStatusByLoanId = function (
    req: protomonoTypes.loanWorkflow.ILoanWorkflowStatusRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.ILoanWorkflowStatusResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'LoanWorkflowGetStatusByLoanId', req)
  }
  static loanWorkflowGetNextManualReview = function (
    req: protomonoTypes.loanWorkflow.ILoanWorkflowManualRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.ILoanWorkflowManualResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'LoanWorkflowGetNextManualReview', req)
  }
  static underwritingDataGet = function (
    req: protomonoTypes.loanWorkflow.IUnderwritingDataRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.IUnderwritingDataResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'UnderwritingDataGet', req)
  }
  static getWorkflowLoanSummary = function (
    req: protomonoTypes.loanWorkflow.IGetWorkflowLoanSummaryRequest,
  ): APIV2ReturnType<protomonoTypes.loanWorkflow.IGetWorkflowLoanSummaryResponse> {
    return APIClient.apiV2SvcPut('LoanWorkflow', 'GetWorkflowLoanSummary', req)
  }
}
export class SDBApi {
  static newLoan = function (
    req: protomonoTypes.sdb.INewLoanRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.INewLoanResponse> {
    return APIClient.apiV2SvcPut('SDB', 'NewLoan', req)
  }
  static closeLoan = function (
    req: protomonoTypes.sdb.ICloseLoanRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.ICloseLoanResponse> {
    return APIClient.apiV2SvcPut('SDB', 'CloseLoan', req)
  }
  static defaultLoan = function (
    req: protomonoTypes.sdb.IDefaultLoanRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.IDefaultLoanResponse> {
    return APIClient.apiV2SvcPut('SDB', 'DefaultLoan', req)
  }
  static reopenLoan = function (
    req: protomonoTypes.sdb.IReopenLoanRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.IReopenLoanResponse> {
    return APIClient.apiV2SvcPut('SDB', 'ReopenLoan', req)
  }
  static queryRecords = function (
    req: protomonoTypes.sdb.IQueryRecordsRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.IQueryRecordsResponse> {
    return APIClient.apiV2SvcPut('SDB', 'QueryRecords', req)
  }
  static getEligibility = function (
    req: protomonoTypes.sdb.IGetEligibilityRequest,
  ): APIV2ReturnType<protomonoTypes.sdb.IGetEligibilityResponse> {
    return APIClient.apiV2SvcPut('SDB', 'GetEligibility', req)
  }
}
export class SherlockApi {
  static userSearch = function (
    req: protomonoTypes.sherlock.IUserSearchRequest,
  ): APIV2ReturnType<protomonoTypes.sherlock.IUserSearchResponse> {
    return APIClient.apiV2SvcPut('Sherlock', 'UserSearch', req)
  }
  static loanSearch = function (
    req: protomonoTypes.sherlock.ILoanSearchRequest,
  ): APIV2ReturnType<protomonoTypes.sherlock.ILoanSearchResponse> {
    return APIClient.apiV2SvcPut('Sherlock', 'LoanSearch', req)
  }
}
export class URAApi {
  static uraQuery = function (
    req: protomonoTypes.ura.IQueryRequest,
  ): APIV2ReturnType<protomonoTypes.ura.IQueryResponse> {
    return APIClient.apiV2SvcPut('URA', 'URAQuery', req)
  }
  static uraSetStatus = function (
    req: protomonoTypes.ura.ISetStatusRequest,
  ): APIV2ReturnType<protomonoTypes.ura.ISetStatusResponse> {
    return APIClient.apiV2SvcPut('URA', 'URASetStatus', req)
  }
}
