import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useSelector} from 'react-redux'

import {CardAutopayOverviewMPOContainer} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewMPOContainer'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {OnboardingParamList, ModuleControlProps} from 'src/flows/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {getDefaultPaymentMethod} from 'src/products/card/PaymentMethods/selectors'

type Props = StackScreenProps<MainStackParamList, 'HowDoesAutopayWork'> & {
  flow?: PaymentFlow
} & StackScreenProps<OnboardingParamList, 'CARD_AUTOPAY_OVERVIEW'> &
  ModuleControlProps

const HowDoesAutopayWork: React.FC<Props> = (props) => {
  const {navigation, route} = props

  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod)

  const pushNextPage = (): void => {
    if (!defaultPaymentMethod) {
      PushPage(navigation, 'AddBankLink', {flow: PaymentFlow.SwitchToAutopay})
      return
    }
    if (defaultPaymentMethod.__typename === 'LinkedAccount') {
      PushPage(navigation, 'CardVerifyBankDetails', {
        bankIdToFilter: getPaymentMethodAccount(defaultPaymentMethod)?.id,
        flow: PaymentFlow.SwitchToAutopay,
      })
    } else {
      if (props.flow === PaymentFlow.PayOverTime) {
        PushPage(navigation, 'CardPayOverTimeDetails')
      } else {
        PushPage(navigation, 'CardReviewAutopay')
      }
    }
  }

  return (
    <CardAutopayOverviewMPOContainer
      onStepComplete={async (): Promise<void> => {
        pushNextPage()
      }}
      navigation={navigation}
      route={route}
    />
  )
}

export default withForwardedNavigationParams<Props>()(HowDoesAutopayWork)
