import {StackScreenProps} from '@react-navigation/stack'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import moment from 'moment'

import Money from 'src/assets/illustrations/Money.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ModuleControlProps} from 'src/flows/types'
import {latestLoanSelector} from 'src/lib/loans/selector'
import {userEmailSelector} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleWhyRejected} from 'src/navigation/WebLinks'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {useLoanSubmission} from 'src/lib/loans/useLoanSubmission/useLoanSubmission'
import {ShowException} from 'src/lib/errors'
import {LoansStateChange} from 'src/lib/loans/actions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {getLoanExpirationDate} from 'src/lib/loans/utils'
import {monthDay} from 'src/lib/utils/date'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {LoanAmountDocument} from 'src/products/loans/LoanProcessing/queries/LoanAmount.gqls'

// Remove in ENG-18602
type Props = StackScreenProps<MainStackParamList, 'Onboarding'> &
  ModuleControlProps & {
    approved: boolean
    rejected: boolean
    expired: boolean
    applicationExpired: boolean
  }

const LoanProcessingResultApproved: React.FC<Props> = (props) => {
  const {onModuleComplete} = props

  const {t} = useTranslation(['Onboarding', 'Common'])

  const [busy, setBusy] = useState(false)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_approved_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const dispatch = usePfDispatch()

  const loan = usePfSelector(latestLoanSelector)
  const amount = loan?.amountBorrowed

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:AcceptThisOffer'),
      onPress: async () => {
        setBusy(true)
        TrackAppEvent(AppEvents.Name.loan_approved_completed, AppEvents.Category.Checkout)
        await onModuleComplete?.('LOAN_ACCEPTANCE')
        await dispatch(UserStateRefresh())
        setBusy(false)
      },
      loading: busy,
      disabled: busy,
    },
  }

  return (
    <Page
      variant="generic"
      title={t('OnboardingLoanProcessingApprovedTitle', {amountBorrowed: `$${amount}`})}
      smallTopGap
      buttonProps={buttonProps}
    >
      <Box fill gap="medium">
        <PFText variant="p_lg">
          {t('OnboardingLoanProcessingApprovedBody', {
            expirationDate: getLoanExpirationDate(loan, monthDay),
          })}
        </PFText>
        <Box padding="enormous">
          <PFSvgContain svg={Money} width="100%" />
        </Box>
      </Box>
    </Page>
  )
}

const LoanProcessingResultRejected: React.FC<Props> = (props) => {
  const {navigation, onModuleComplete} = props

  const {t} = useTranslation(['Onboarding', 'Common'])

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_not_approved_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const email = usePfSelector(userEmailSelector)
  const loan = usePfSelector(latestLoanSelector)
  const reapplyDatetime = moment(loan?.reapplyDatetime)

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:Continue'),
      onPress: () => {
        onModuleComplete?.('LOAN_END')
      },
    },
  }

  const handleOnReadMore = (): void => {
    PushWebPageOnStack(navigation, {uri: possibleWhyRejected})
  }

  return (
    <Page
      variant="generic"
      title={t('OnboardingLoanProcessingRejectedTitle')}
      smallTopGap
      buttonProps={buttonProps}
    >
      <Box>
        <PFText variant="p_lg">
          <Trans
            t={t}
            i18nKey="OnboardingLoanProcessingRejectedBody"
            values={{emailAddress: email, reapplyDate: reapplyDatetime.format('dddd, MMMM Do')}}
            components={{
              svgLink: (
                <SvgLink
                  onPress={handleOnReadMore}
                  linkText={'Learn more about this result.'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </Box>
    </Page>
  )
}

type ExpiredProps = Props & {type: 'Loan' | 'Application'}

const LoanProcessingResultExpired: React.FC<ExpiredProps> = (props) => {
  const {type} = props

  const {t} = useTranslation(['Onboarding', 'Common'])

  const [isReSubmitting, setIsReSubmitting] = useState(false)

  const dispatch = usePfDispatch()

  const {selectedData: amountRequested, loading: isLoadingApplicationData} = useCassandraQuery(
    LoanAmountDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => {
      if (typeof data.me.onboarding?.loan?.amountSelected === 'string')
        return data.me.onboarding?.loan?.amountSelected
    },
  )

  const [trySubmit, {loading: isSubmissionLoading}] = useLoanSubmission()

  const handleReSubmit = async () => {
    setIsReSubmitting(true)

    try {
      dispatch(LoansStateChange({reapplying: true, user_selected_loan_amount: true}))

      void trySubmit(amountRequested)
    } catch (e) {
      ShowException(e)
      return
    } finally {
      setIsReSubmitting(false)
    }
  }

  const handleReapply = async () => {
    // TODO: go directly to some other previous module.
  }

  return (
    <Page variant="generic" smallTopGap>
      <Box marginTop="small">
        <BasicTile padding="medium">
          <Box gap="medium">
            <Box gap="small">
              <PFText variant="h3">{t(`LoanProcessingExpiredHeading${type}`)}</PFText>
              <PFText variant="p_lg">
                <Trans
                  t={t}
                  i18nKey="LoanProcessingExpiredBody"
                  values={{amount: amountRequested}}
                  components={{bold: <PFText variant="p_lg_semibold" />}}
                />
              </PFText>
            </Box>

            <Box gap="small">
              <Button
                mode="primary"
                size="large"
                width="100%"
                onPress={handleReSubmit}
                loading={isSubmissionLoading || isReSubmitting || isLoadingApplicationData}
                disabled={isSubmissionLoading || isReSubmitting || isLoadingApplicationData}
              >
                {t('LoanProcessingExpiredCTA1')}
              </Button>

              <Button mode="secondary" size="large" width="100%" onPress={handleReapply}>
                {t('LoanProcessingExpiredCTA2')}
              </Button>
            </Box>
          </Box>
        </BasicTile>
      </Box>
    </Page>
  )
}

const LoanProcessingResult: React.FC<Props> = (props) => {
  const {approved, rejected, expired, applicationExpired} = props

  if (expired || applicationExpired) {
    return <LoanProcessingResultExpired {...props} type={expired ? 'Loan' : 'Application'} />
  } else if (approved) {
    return <LoanProcessingResultApproved {...props} />
  } else if (rejected) {
    return <LoanProcessingResultRejected {...props} />
  }

  return null
}

export default LoanProcessingResult
