import React, {FC} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'

import {tileDividerBackground} from 'src/designSystem/semanticColors'

type TileDividerProps = {
  style?: ViewStyle
}

const TileDivider: FC<TileDividerProps> = (props) => {
  const {style, ...rest} = props
  return <View {...rest} style={[styles.container_view, style]} />
}
export default TileDivider

const styles = StyleSheet.create({
  container_view: {
    backgroundColor: tileDividerBackground,
    height: 0.5,
    width: '100%',
  },
})
