import {ColorVariants, DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import {convertHexToRgbA} from 'src/designSystem/lib/colorUtil'
import {AnimatableNumericValue, Platform, ViewStyle} from 'react-native'
import {elevationInsetColor, elevationShadowColor} from 'src/designSystem/semanticColors'

export type LevelVariants = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6

type MapOfElevationStyles = {[key in LevelVariants]: () => ViewStyle}

export const LevelsStyle: MapOfElevationStyles = {
  '-1': () => ({
    backgroundColor: elevationInsetColor,
  }),
  '0': () => ({
    /* no-op */
  }),
  '1': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 0.5,
        },
        shadowOpacity: 0.16,
        shadowRadius: 2,
      },
      android: {
        elevation: 1,
      },
      web: {
        boxShadow: `0px 0px 1px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.25,
        )}, 0px 0.5px 2px ${convertHexToRgbA(elevationShadowColor, 0.16)}`,
      },
    }),
  }),
  '2': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.16,
        shadowRadius: 4,
      },
      android: {
        elevation: 2,
      },
      web: {
        boxShadow: `0px 0px 1px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.04,
        )}, 0px 2px 4px ${convertHexToRgbA(elevationShadowColor, 0.16)}`,
      },
    }),
  }),
  '3': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowOpacity: 0.16,
        shadowRadius: 8,
      },
      android: {
        elevation: 3,
      },
      web: {
        boxShadow: `0px 0px 2px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.04,
        )}, 0px 4px 8px ${convertHexToRgbA(elevationShadowColor, 0.16)}`,
      },
    }),
  }),
  '4': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 8,
        },
        shadowOpacity: 0.16,
        shadowRadius: 16,
      },
      android: {
        elevation: 4,
      },
      web: {
        boxShadow: `0px 2px 4px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.04,
        )}, 0px 8px 16px ${convertHexToRgbA(elevationShadowColor, 0.16)}`,
      },
    }),
  }),
  '5': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 16,
        },
        shadowOpacity: 0.16,
        shadowRadius: 24,
      },
      android: {
        elevation: 5,
      },
      web: {
        boxShadow: `0px 2px 8px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.04,
        )}, 0px 16px 24px ${convertHexToRgbA(elevationShadowColor, 0.16)}`,
      },
    }),
  }),
  '6': () => ({
    ...Platform.select({
      ios: {
        shadowColor: elevationShadowColor,
        shadowOffset: {
          width: 0,
          height: 20,
        },
        shadowOpacity: 0.24,
        shadowRadius: 32,
      },
      android: {
        elevation: 6,
      },
      web: {
        boxShadow: `0px 2px 8px ${convertHexToRgbA(
          NamedColors.BLACK,
          0.08,
        )}, 0px 20px 32px ${convertHexToRgbA(elevationShadowColor, 0.24)}`,
      },
    }),
  }),
}

export const ShadowViewStyle = (
  level: LevelVariants,
  backgroundColor: ColorVariants | NamedColors,
  borderRadius?: AnimatableNumericValue,
): ViewStyle => ({
  backgroundColor:
    level === -1 ? NamedColors.ASH : DefaultVariantsColor[backgroundColor] ?? backgroundColor,
  ...BorderRadiusStyle(borderRadius),
  ...LevelsStyle[level](),
})

export const BorderRadiusStyle = (borderRadius?: AnimatableNumericValue): ViewStyle => ({
  borderRadius: borderRadius ?? 0,
})
