import {gql} from '@apollo/client'

export const GetOnboardingCurrentModule = gql`
  query GetOnboardingCurrentModule {
    getOnboardingCurrentModule {
      currentModule
      nextModulesAllowed {
        nextModulesAllowed
      }
      previousModules {
        previousModules
      }
      onboardingFinished
    }
  }
`

export const OnboardingMoveToPreviousModule = gql`
  mutation OnboardingMoveToPreviousModule {
    onboardingMoveToPreviousModule
  }
`

export const OnboardingMoveToNextModule = gql`
  mutation OnboardingMoveToNextModule($nextModule: OnboardingModule) {
    onboardingMoveToNextModule(nextModule: $nextModule)
  }
`

export const OnboardingInitialize = gql`
  mutation OnboardingInitialize($initialModule: OnboardingModule) {
    onboardingInitialize(initialModule: $initialModule)
  }
`
