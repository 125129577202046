import React, {Component} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'

import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux, get_ura_ui_by_id_from_redux} from 'src/lib/ura/ura.utils'
import URAModalContent from 'src/products/loans/UserRequestedAction/URAModalContent'
import {URAProps, RetakePhotoProps} from 'src/products/loans/UserRequestedAction/URAProps'
import {PushPage} from 'src/navigation/NavHelper'
import withNav, {InjectedNavProps} from 'src/nav/withNav'

type State = {
  busy: boolean
}

const mapStateToProps = (state, ownProps) => {
  return {
    ura: get_ura_by_id_from_redux(ownProps.ura_id, state),
    ura_ui: get_ura_ui_by_id_from_redux(ownProps.ura_id, state),
  }
}

const mapDispatchToProps = {
  URAUIUpdateAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & URAProps & RetakePhotoProps & InjectedNavProps & WithTranslation

class RetakeLightBox extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      busy: false,
    }

    this.props.URAUIUpdateAction(
      [{id: props.ura_id, componentId: props.componentId, close: () => this.props.close()}],
      'MergeRecord',
    )
  }

  onRetake(uraId, routeName, onComplete) {
    PushPage(this.props.navigation, routeName, {onComplete: onComplete, ura_id: uraId})
  }

  render() {
    const {ura_id, ura, routeName, close, t} = this.props
    const primary = {
      text: t('TakePhoto'),
      disabled: this.state.busy,
      onPress: () => {
        this.onRetake(ura_id, routeName, () => close())
      },
    }
    return <URAModalContent uraId={ura_id} ura={ura} primary={primary} close={close} />
  }
}

export default connector(withTranslation('UserRequestedAction')(withNav(RetakeLightBox)))
