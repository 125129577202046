import allSettled from 'promise.allsettled'

import {GetUserEnv, GetClient, GetMeAction} from '@possible/cassandra'
import {
  AggregatorType,
  BankAccountUnlinkDocument,
  UserModifyProfileDocument,
  UserModifyProfileMutationVariables,
} from '@possible/cassandra/src/types/types.mobile.generated'
import APIClient from '@possible/generated/APIClient'
import {banking} from '@possible/generated/src/generated/protomonoTypes'
import {ApplyMutation} from '@possible/cassandra/src/utils/operations'

import APIClientBanking from 'src/api/lib/APIClientBanking'
import * as user_actions from 'src/lib/user/actions'
import {URA_UPDATE, URAUpdateAction} from 'src/lib/ura/actions/uraActions'
import {userIdSelector} from 'src/api/selectors/selectors'
import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'
import Log from 'src/lib/loggingUtil'
import {PfDispatch, PfGetState} from 'src/store/types'
import {getLoanStatus} from 'src/lib/loans/utils'
import {latestLoanSelector} from 'src/lib/loans/selector'
import {UpdateLoanTerms} from 'src/api/actions/loans/loanActions'
import {statusList} from 'src/lib/loans/consts'

//////////// User Actions

const checkNoError = (response) => {
  return response && !response.getErrorStr()
}

interface UserInfoUpdate {
  firstName: string
  lastName: string
  middleName: string
  nameSuffix: string
  birthDate: string
  ssn: string
}

const _transformUserInfo = (
  userInfo: Partial<UserInfoUpdate>,
): UserModifyProfileMutationVariables => {
  const info: UserModifyProfileMutationVariables = {}

  if (userInfo.firstName && userInfo.lastName) {
    info.name = {
      first: userInfo.firstName,
      last: userInfo.lastName,
      middle: userInfo.middleName,
      suffix: userInfo.nameSuffix,
    }
  }

  if (userInfo.birthDate) {
    info.dob = {dob: formatDate(userInfo.birthDate, graphqlDateFormat)}
  }

  if (userInfo.ssn) {
    info.ssn = {ssn: userInfo.ssn}
  }

  return info
}

export function userInfoUpdate(userInfo: Partial<UserInfoUpdate>) {
  return async (dispatch) => {
    try {
      const response = await ApplyMutation(UserModifyProfileDocument, _transformUserInfo(userInfo))

      if (!response) {
        throw new Error('Failed to update user info')
      }
      await dispatch(GetMeAction())

      return response['userModifyProfile']
    } catch (e) {
      Log.error(e, 'userInfoUpdate error:')
      throw e
    }
  }
}

export function GetURAByUserId(userId) {
  return async (dispatch) => {
    const response = await APIClient.ura_for_user_get(userId)
    if (checkNoError(response)) {
      if (response.actions) {
        await dispatch(URAUpdateAction(response.actions))
      }
    }

    return response
  }
}

export function URASet(req) {
  return async (dispatch) => {
    const response = await APIClient.ura_set_status(req)
    if (checkNoError(response)) {
      await dispatch({type: URA_UPDATE, ura: [response.action]})
    }

    return response
  }
}

export function UserStateRefresh() {
  return async (dispatch: PfDispatch, getState: PfGetState): Promise<boolean> => {
    try {
      const state = getState()
      const userId = userIdSelector(state)
      /* we will have to get a better solution to stop at the api call level
         but this call is used in the loan state polling so stopping it earlier
       */
      if (!userId) {
        return false
      }

      if (state?.api?.sessionExpired) {
        return false
      }

      await allSettled([
        dispatch(GetMeAction()),
        GetPaymentInstrumentsAndAccounts(dispatch, userId),
        dispatch(GetUserEnv()),
      ])

      const loanStatus = getLoanStatus(latestLoanSelector(state))
      /* Users can call CS agent to change their address any time.
         We need to update the loan terms for the new address before the user reapplies.
         When there is already a loan pending or active the user can't reapply.
       */
      if (loanStatus !== statusList.ACTIVE && loanStatus !== statusList.PENDING) {
        await dispatch(UpdateLoanTerms())
      }

      return true
    } catch (e) {
      Log.error(e, 'Error refreshing user state')
      return false
    }
  }
}

export async function GetPaymentInstrumentsAndAccounts(dispatch, userId) {
  const paymentInstruments = await APIClientBanking.GetPaymentInstruments(userId, true)
  //GetLinkedAccounts has access tokens for plaid relinking
  const accountsResp = await APIClientBanking.GetLinkedAccounts(userId)
  if (paymentInstruments && checkNoError(paymentInstruments)) {
    await dispatch(
      user_actions.UsersLinkedAccountsUpdate({
        accounts: accountsResp?.accounts ?? paymentInstruments?.linkedAccounts,
        institutions: paymentInstruments?.institutions,
        numbers: paymentInstruments?.numbers,
      }),
    )
  }
}

function convertAggregatorIdToType(aggregatorId: banking.LinkedAccount.Aggregator): AggregatorType {
  switch (aggregatorId) {
    case banking.LinkedAccount.Aggregator.plaid:
      return AggregatorType.Plaid
    case banking.LinkedAccount.Aggregator.yodlee:
      return AggregatorType.Yodlee
    case banking.LinkedAccount.Aggregator.finicity:
      return AggregatorType.Finicity
    case banking.LinkedAccount.Aggregator.possible:
      return AggregatorType.Possible
    case banking.LinkedAccount.Aggregator.mocked:
      return AggregatorType.Mocked
  }
  return AggregatorType.Unrecognized
}

export function UnlinkBankAccount(account) {
  return async (dispatch): Promise<boolean> => {
    const client = GetClient()
    let success = false
    try {
      const resp = await client.mutate({
        mutation: BankAccountUnlinkDocument,
        variables: {
          aggregatorType: convertAggregatorIdToType(account.aggregatorId),
          aggregatorRef: account.aggregatorRef,
        },
      })
      success = resp.data?.bankAccountUnlink === true
      if (!success) {
        Log.error('Failed to unlink bank account: ' + JSON.stringify(resp))
      }
    } catch (e) {
      Log.error(e, 'Failed to unlink bank account')
    }
    if (success) {
      await dispatch(UserStateRefresh())
    }
    return success
  }
}
export type BasicPaymentScheduleData = {
  paymentSchedule: 'weekly' | 'bi-weekly' | 'monthly' | 'irregularly'
}
