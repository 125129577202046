import {StackNavigationProp} from '@react-navigation/stack'
import React, {FC} from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {IDScanReduxContainer} from 'src/products/loans/IDScan/IDScanReduxContainer'
import {LoanFlowIDScan} from 'src/navigation/LoanFlow'
import NavPageState from 'src/navigation/NavPageState'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, 'IDScan'>
}

export const IDScanRouteContainer: FC<Props> = (props) => {
  const {navigation} = props

  const onComplete = (): void => {
    NavPageState.NavToNextPage(LoanFlowIDScan, navigation)
  }

  return <IDScanReduxContainer onComplete={onComplete} />
}
