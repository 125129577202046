import React, {FunctionComponent} from 'react'

import {PfReduxState} from 'src/reducers/types'
import MaintenanceInProgress from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress'
import {maintenanceOn} from 'src/api/selectors/selectors'
import {ENABLE_MAINTENANCE_MODE_WHEN_REQUESTS_FAIL} from 'src/products/loans/MaintenanceInProgress/MaintenanceInProgress.util'
import {usePfSelector} from 'src/store/utils'

export const MaintenanceInProgressNotifier: FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const maintenanceFirebase = usePfSelector((state: PfReduxState) => state.api?.maintenanceFirebase)
  const maintenanceEnabledByFailingRequests = usePfSelector((state: PfReduxState) =>
    maintenanceOn(state),
  )
  const displayMaintenancePage =
    maintenanceFirebase ||
    (maintenanceEnabledByFailingRequests && ENABLE_MAINTENANCE_MODE_WHEN_REQUESTS_FAIL)

  return <>{displayMaintenancePage ? <MaintenanceInProgress /> : children}</>
}

export default MaintenanceInProgressNotifier
