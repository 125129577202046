import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {useState} from 'react'

import Log from 'src/lib/loggingUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useSelectCardOffer} from 'src/products/card/LoanDash/useSelectCardOffer'

import {ProductChoiceGQLContainer} from 'src/products/loans/ProductChoice/ProductChoiceGQLContainer'

export const ProductChoiceWorkflowContainer: React.FC = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const selectCardOffer = useSelectCardOffer()
  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'ProductHub'>>()

  const clearIsInOnboarding = (): void => {
    navigation.setParams({isInNonInitialOnboardingFlow: undefined})
  }

  // Loans are not yet supported in this flow
  const onLoanPressed = async (): Promise<void> => {}

  // Exit out of the cards application flow and go back to Loans dash
  const onHandleOnPressExitAndCancelCardApplication: () => Promise<void> = async () => {
    clearIsInOnboarding()
    return Promise.resolve()
  }

  const onCardPressed = async (): Promise<void> => {
    try {
      if (isBusy) {
        return
      }
      setIsBusy(true)
      await selectCardOffer()
      clearIsInOnboarding()
    } catch (e) {
      Log.error(e, 'Failed to apply for card in ProductChoiceWorkflowContainer')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <ProductChoiceGQLContainer
      showLoans={false}
      handleOnCardPressed={onCardPressed}
      handleOnLoanPressed={onLoanPressed}
      isBusy={isBusy}
      handleOnPressExitAndCancelCardApplication={onHandleOnPressExitAndCancelCardApplication}
    />
  )
}
