import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ConfirmDebitCardGQLContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardGQLContainer'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import NavPageState from 'src/navigation/NavPageState'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'

export type ConfirmDebitCardNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'ConfirmDebitCard'>,
  'navigation'
>

/**
 * Container to integrate the ConfirmDebitCard screen into the NavPageState navigation system.
 */
export const ConfirmDebitCardNavPageStateContainer: React.FC<
  ConfirmDebitCardNavPageStateContainerProps
> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_debit_card_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const handleOnAddNewDebitCard = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.suggested_debit_card_confirmed, AppEvents.Category.Checkout, {
      confirmed: false,
    })
    // when they want to add a new card we force them to go to the
    // CollectBankDebitCardNumbers screen within this flow
    const nextPage = {
      screen: LoanApprovedFlow.CollectBankDebitCardNumbers,
    }
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  const handleOnConfirm = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.suggested_debit_card_confirmed, AppEvents.Category.Checkout, {
      confirmed: true,
    })
    // when they confirm we nav to the next screen in the approval flow
    const stepName = LoanApprovedFlow.ConfirmDebitCard
    const nextPage = NavPageState.GetNextApprovalState(stepName)
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  return (
    <ConfirmDebitCardGQLContainer
      onAddNewDebitCard={handleOnAddNewDebitCard}
      onConfirm={handleOnConfirm}
    />
  )
}
