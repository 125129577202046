import React, {FC, useEffect} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {CardAccountStatusCode} from 'src/cassandra'
import {ModuleControlProps} from 'src/flows/types'
import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ApplicationProcessingTemplate} from 'src/products/general/ApplicationProcessing/ApplicationProcessingTemplate'
import {useCardApplicationProcessingStatus} from 'src/products/general/ApplicationProcessing/useCardApplicationProcessingStatus'

type Props = StackScreenProps<MainStackParamList, 'CardProcessing'> & ModuleControlProps

export const CardProcessingMPOContainer: FC<Props> = (props) => {
  const {onModuleComplete} = props
  const {isApplicationUWComplete, cardAccountStatus} = useCardApplicationProcessingStatus()

  useEffect(() => {
    const checkForApplicationCompletion = async (): Promise<void> => {
      try {
        if (isApplicationUWComplete) {
          if (cardAccountStatus?.code === CardAccountStatusCode.Approved) {
            await onModuleComplete?.()
          }

          if (cardAccountStatus?.code === CardAccountStatusCode.Rejected) {
            // When a card application is rejected, the BE will also set MPO to 'CARD_END'.
            // It is set here in the FE to direct user to the next screen
            // Rejected loan state users are sent to the loans dash
            // Rejected non-loan state users are sent to the Application Rejected screen
            await onModuleComplete?.('CARD_END', true)
          }
        }
      } catch (e) {
        await logErrorAndShowException(e, 'card CardProcessing error')
      }
    }

    void checkForApplicationCompletion()
  }, [cardAccountStatus?.code, onModuleComplete, isApplicationUWComplete])

  return <ApplicationProcessingTemplate />
}
