import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import AcceptAgreements, {
  Props as AcceptAgreementsProps,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements'
import {RenderRadioTextType} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {
  onPossibleTOS,
  onPossiblePP,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements.utils'
import {NonACHWAInstallment} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/WAInstallment/NonACHWAInstallment'

export const AcceptAgreementsWAInstallment: FC<AcceptAgreementsProps> = (props) => {
  const {t} = useTranslation(['LoanApprovedExtendedPlan', 'Common'])

  const description: JSX.Element = (
    <PFText variant={'p_sm'}>
      <Trans i18nKey={'ExtendedInstallmentAgreementDescription'} t={t}>
        We will update your scheduled payments as soon as you accept your extended installment plan
        agreement. At the time you repay this loan, you should have sufficient funds to meet your
        other financial obligations. If you cannot pay other bills because you are paying off this
        debt, you should reschedule your payment or contact us to discuss other installment plan
        options. Please accept our
        <SvgLink
          linkType={'inline'}
          // eslint-disable-next-line react/jsx-handler-names
          onPress={onPossibleTOS}
          linkText={'Terms of Service'}
          textVariant={'p_sm_semibold'}
        />
        and
        <SvgLink
          linkType={'inline'}
          // eslint-disable-next-line react/jsx-handler-names
          onPress={onPossiblePP}
          linkText={'Privacy policy'}
          textVariant={'p_sm_semibold'}
        />
        .
      </Trans>
    </PFText>
  )

  const renderRadioText: RenderRadioTextType = (onACHAgreementLink) => {
    return (
      <PFText>
        <Trans i18nKey={'Common:AcceptEPA'} t={t}>
          I accept the
          <SvgLink
            // eslint-disable-next-line react/jsx-handler-names
            onPress={onACHAgreementLink}
            linkText={'Electronic Payment Authorization'}
            linkType={'inline'}
          />
        </Trans>
      </PFText>
    )
  }

  return (
    <AcceptAgreements
      description={description}
      renderRadioText={renderRadioText}
      nonAchDialog={NonACHWAInstallment}
      {...props}
    />
  )
}
