import React, {FC, useEffect, useMemo, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {useTranslation, WithTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {getRemoteValueJson} from 'src/lib/RemoteConfig'
import {
  UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS,
  UNSUPPORTED_INSTITUTIONS_POSSIBLE,
} from 'src/lib/RemoteConfig/parameterkeys'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppEvents from 'src/lib/Analytics/app_events'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {userHasOnlineBankEnabled} from 'src/lib/user/utils'

type Props = WithTranslation & StackScreenProps<MainStackParamList, 'UnsupportedBanks'>

const UnsupportedBanks: FC<Props> = () => {
  const [unsupportedInstitutionsPossible] = useState(
    getRemoteValueJson<Record<string, string>[]>(UNSUPPORTED_INSTITUTIONS_POSSIBLE),
  )
  const [unsupportedInstitutionsOnlineBanks] = useState(
    getRemoteValueJson<Record<string, string>[]>(UNSUPPORTED_INSTITUTIONS_ONLINE_BANKS),
  )
  const {t} = useTranslation(['UnsupportedBanks', 'Common'])
  const [isOnlineBankSupportEnabled, setIsOnlineBankSupportEnabled] = useState(false)

  const getFeatureFlag = async () => {
    const isEnabled = await userHasOnlineBankEnabled()
    setIsOnlineBankSupportEnabled(isEnabled)
  }

  useEffect(() => {
    getFeatureFlag()
  })
  usePageViewedAnalytics({
    eventName: AppEvents.Name.unsupported_institutions_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  const institutionsList = useMemo(
    () => [
      ...(unsupportedInstitutionsPossible ? unsupportedInstitutionsPossible : []),
      ...(!isOnlineBankSupportEnabled ? unsupportedInstitutionsOnlineBanks ?? [] : []),
    ],
    [
      isOnlineBankSupportEnabled,
      unsupportedInstitutionsPossible,
      unsupportedInstitutionsOnlineBanks,
    ],
  )

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      testID={'UnsupportedBanksPage'}
      title={t('UnsupportedBanks')}
    >
      <View style={styles.subtitle}>
        <PFText variant={'h3'}>{t('WeCurrentlyDoNotSupportMessage')}</PFText>
      </View>
      <View>
        {institutionsList?.map((item) => (
          <PFText key={item.id} variant={'p'}>
            {item.name}
          </PFText>
        ))}
      </View>
    </Page>
  )
}

export default UnsupportedBanks

const styles = StyleSheet.create({
  subtitle: {
    marginBottom: 30,
  },
})
