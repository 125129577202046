import {initializeApp, FirebaseApp} from 'firebase/app'
import {
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId as setFirebaseUserId,
  Analytics,
} from 'firebase/analytics'
import {getMessaging, Messaging} from 'firebase/messaging'
import {getRemoteConfig, RemoteConfig} from 'firebase/remote-config'

import WebAppConfig from 'src/WebAppConfig'
import {EnvironmentType} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'

const getOptions = () => {
  if (WebAppConfig.REACT_APP_PFENV === EnvironmentType.Dev) {
    return {
      apiKey: 'AIzaSyBTaA20jpV6dpVbqgdJmasoxh1KTJLyBtg',
      authDomain: 'possible-test.firebaseapp.com',
      databaseURL: 'https://possible-test.firebaseio.com',
      projectId: 'possible-test',
      storageBucket: 'possible-test.appspot.com',
      messagingSenderId: '648409842150',
      appId: '1:648409842150:web:dc9e2cff3e72341df79627',
      measurementId: 'G-DYR03D8PRZ',
    }
  } else {
    return {
      apiKey: 'AIzaSyClvHM1-zSEMKAF4Gq4sf6_QLYWzxK52cI',
      authDomain: 'possible-1515456929227.firebaseapp.com',
      databaseURL: 'https://possible-1515456929227.firebaseio.com',
      projectId: 'possible-1515456929227',
      storageBucket: 'possible-1515456929227.appspot.com',
      messagingSenderId: '1049650429468',
      appId: '1:1049650429468:web:d6b680ecd0448f3f12e9d9',
      measurementId: 'G-T46WLQWT0V',
    }
  }
}

class Firebase {
  app: FirebaseApp | undefined = undefined

  messaging = (): Messaging => {
    if (!this.app) {
      this.init()
    }
    return getMessaging(this.app)
  }

  analytics = (): Analytics => {
    if (!this.app) {
      this.init()
    }
    return getAnalytics(this.app)
  }

  remoteConfig = (): RemoteConfig => {
    if (!this.app) {
      this.init()
    }
    return getRemoteConfig(this.app)
  }

  instanceId: string | undefined = undefined
  fcmToken: string | undefined = undefined

  init(): void {
    this.app = initializeApp(getOptions())
    Log.log('Firebase initializing app')
  }

  async registerNotifications(): Promise<void> {
    Log.log('NoOp Firebase registerNotifications')
    return new Promise((resolve) => resolve())
  }

  logEvent(name: string, params?: {[key: string]: any}): void {
    logFirebaseEvent(this.analytics(), name, params)
  }

  setUserId(userId: string): void {
    setFirebaseUserId(this.analytics(), userId)
  }
}

export default new Firebase()
