import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useState} from 'react'
import {isEmulatorSync} from 'react-native-device-info'

import PhoneVerificationTemplate from 'src/products/loans/PhoneConfirmation/PhoneVerificationTemplate'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

const isEmulator = isEmulatorSync()

type Props = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'PhoneVerification'>

/**
 * A container for the PhoneVerificationTemplate with logic for workflow navigation.
 */
const PhoneVerificationContainer: React.FC<Props> = (props) => {
  const {onPreReqFulfilled} = props

  const [showNotificationsModal, setShowNotificationsModal] = useState(false)

  const [hasPushPermissions] = usePushPermissions()

  const handleVerifiedSuccessfully = useCallback(async () => {
    //the __DEV_ condition is to allow detox to bypass the push permission
    if (__DEV__ || isEmulator) {
      setShowNotificationsModal(true)
      await onPreReqFulfilled()
      return
    }
    if (isDeviceWeb()) {
      await onPreReqFulfilled()
    } else {
      if (!hasPushPermissions) {
        setShowNotificationsModal(true)
      } else {
        await onPreReqFulfilled()
      }
    }
  }, [hasPushPermissions, onPreReqFulfilled])

  const handlePushPermissionsGranted = useCallback(async () => {
    await onPreReqFulfilled()
  }, [onPreReqFulfilled])

  const handlePushPermissionsModalDismissed = useCallback(async () => {
    setShowNotificationsModal(false)
    await Promise.resolve()
  }, [])

  return (
    <PhoneVerificationTemplate
      {...props}
      showNotificationsModal={showNotificationsModal}
      onVerifiedSuccessfully={handleVerifiedSuccessfully}
      onPushPermissionsGranted={handlePushPermissionsGranted}
      onPushPermissionsModalDismissed={handlePushPermissionsModalDismissed}
    />
  )
}

export default PhoneVerificationContainer
