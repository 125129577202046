import {AccountAndRoutingProvidedValue, LoanState} from 'src/lib/loans/reducers/types'

export const DISBURSEMENT_METHOD_SELECTED = 'DISBURSEMENT_METHOD_SELECTED'
export const PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED'
export const ACCOUNT_AND_ROUTING_PROVIDED = 'ACCOUNT_AND_ROUTING_PROVIDED'
export const LOANS_STATE_CHANGE_EVENT = 'loans_state_change'
export const LOAN_NEW = 'loan_new'
export const LOAN_UPDATE = 'loan_update'
export const LOAN_TRANSFERS_UPDATE = 'LOAN_TRANSFERS_UPDATE'
export const LOAN_TYPE_UPDATE = 'LOAN_TYPE_UPDATE'
export const LOANS_NEXT_AVAILABLE_SETTLEMENT_DATE_CHANGE_EVENT =
  'LOANS_NEXT_AVAILABLE_SETTLEMENT_DATE_CHANGE_EVENT'
export const LOAN_ERROR = 'LOAN_ERROR'

export function AccountAndRoutingProvided(value: AccountAndRoutingProvidedValue): {
  type: typeof ACCOUNT_AND_ROUTING_PROVIDED
  value: AccountAndRoutingProvidedValue
} {
  return {
    type: ACCOUNT_AND_ROUTING_PROVIDED,
    value,
  }
}

export function LoansStateChange(state: Partial<LoanState>): {
  type: typeof LOANS_STATE_CHANGE_EVENT
  state: Partial<LoanState>
} {
  return {type: LOANS_STATE_CHANGE_EVENT, state}
}

export function NextAvailableSettlementDateChange(state) {
  return {type: LOANS_NEXT_AVAILABLE_SETTLEMENT_DATE_CHANGE_EVENT, state}
}
