export const LoanFlowLoanSelection = 'LoanAmountSelection'
export const LoanFlowBankLinkProps = 'AddBankLink'
export const LoanFlowIDScan = 'IDScan'
export const LoanFlowPersonalInformation = 'PersonalInformation'
export const LoanFlowAddress = 'AddressCollection'
export const LoanFlowSSNConfirm = 'SsnConfirm'
export const LoanFlowLiveness = 'Liveness'
export const LoanFlowBank = 'ReapplicationBank'
export const LoanFlowPhoneConfirmation = 'PhoneConfirmation'
export const LoanFlowLoanSubmission = 'LoanSubmission'
export const LoanFlowEmailConfirmation = 'EmailVerification'
export const ApplicationDashboard = 'Dashboard'

export type LoanFlowType =
  | typeof LoanFlowLoanSelection
  | typeof LoanFlowBankLinkProps
  | typeof LoanFlowIDScan
  | typeof LoanFlowPersonalInformation
  | typeof LoanFlowAddress
  | typeof LoanFlowSSNConfirm
  | typeof LoanFlowLiveness
  | typeof LoanFlowBank
  | typeof LoanFlowPhoneConfirmation
  | typeof LoanFlowLoanSubmission
  | typeof LoanFlowEmailConfirmation
  | typeof ApplicationDashboard
