import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {ReasonSurveyTemplate} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {UserPropertyKey} from 'src/cassandra'
import {userSetProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'

export type ReasonSurveyRESTContainerProps = {
  onContinue: () => Promise<void>
}

const ReasonSurveyRESTContainer: React.FC<ReasonSurveyRESTContainerProps> = (props) => {
  const {t} = useTranslation('ReasonSurvey')
  const {onContinue} = props
  const [isContinueLoading, setIsContinueLoading] = useState(false)

  const onSubmitSurvey = (value: string, other?: string): void => {
    TrackAppEvent(AppEvents.Name.purpose_for_loan, AppEvents.Category.Checkout, {
      value,
      other,
    })

    void userSetProperty(UserPropertyKey.PurposeForLoan, {
      value,
      other,
    }).catch(() => Log.error('Failed to set purpose_for_loan user property'))
  }

  const handleOnSubmitSurvey = async (value: string, other?: string): Promise<void> => {
    setIsContinueLoading(true)
    onSubmitSurvey(value, other)
    await onContinue()
    setIsContinueLoading(false)
  }

  return (
    <BaseTemplate
      testID={'ReasonSurvey'}
      pageTitle={t('HowIsThisLoanHelpingYou')}
      showTileBorder={false}
    >
      <ReasonSurveyTemplate
        isContinueLoading={isContinueLoading}
        onSubmitSurvey={handleOnSubmitSurvey}
      />
    </BaseTemplate>
  )
}

export {ReasonSurveyRESTContainer}
