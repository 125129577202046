import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {TFunction} from 'i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import {ILoanRedux} from 'src/lib/loans/reducers/types'
import {PaymentMethodType} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {DISBURSEMENT_METHOD_SELECTED, PAYMENT_METHOD_SELECTED} from 'src/lib/loans/actions'
import {Consumer} from '@possible/cassandra'
import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {PfDispatch} from 'src/store/types'

export const canContinue = (
  acceptChecked: boolean,
  paymentMethod: PaymentMethodType,
  transferMethod?: transferMethodsType,
): boolean => {
  return (
    acceptChecked &&
    ((paymentMethod === 'NOAUTO' && transferMethod === transferMethods.check) ||
      (paymentMethod === 'AUTO' &&
        (transferMethod === transferMethods.ach || transferMethod === transferMethods.interchange)))
  )
}

/**
 * Moves the page to a PDF Viewer for specified ach agreement
 * @param navigation navigation stack
 * @param loan the loan to retrieve ach agreement for
 * @param t
 * @throws Error if the loan is missing an id
 */
export const onACHAgreementLink = async (
  navigation,
  loan: ILoanRedux | null,
  t: TFunction,
): Promise<void | never> => {
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_ach_agreement_selected,
    AppEvents.Category.Checkout,
  )

  if (!loan?.id) {
    throw new Error('Unable to load loan for documents, please try again later')
  }

  let document: Consumer.types.LoanGetDocumentMutation | null | undefined
  try {
    document = await Consumer.methods.getLoanDocument(loan.id, LoanDocumentType.AchAgreement)
  } catch (e) {
    //
  }

  if (document?.loanGetDocument?.url) {
    displayPdf(document.loanGetDocument.url, navigation)
  } else {
    throw new Error('Unable to load documents, please try again later.')
  }
}

export const renderDebugInfo = (
  text: string,
  paymentMethod: transferMethodsType | undefined,
): React.ReactNode => {
  if (__DEV__) {
    return (
      <PFText textAlign={'center'} variant={'p_sm'}>
        {text} {paymentMethod}
      </PFText>
    )
  } else {
    return null
  }
}

export function updateDisbursementMethod(value, dispatch) {
  return async () => dispatch({type: DISBURSEMENT_METHOD_SELECTED, value})
}

export function updatePaymentMethod(value: transferMethodsType | undefined, dispatch: PfDispatch) {
  return async (): Promise<{
    type: string
    value: transferMethodsType | undefined
    // eslint-disable-next-line @typescript-eslint/require-await
  }> => dispatch({type: PAYMENT_METHOD_SELECTED, value})
}

export const NoLoanSpinner: FC<{title: string}> = ({title}) => {
  return (
    <GenericNonModalTemplate title={title}>
      <View style={styles.view}>
        <ActivityIndicator animating={true} style={styles.activityIndicator} size="large" />
      </View>
    </GenericNonModalTemplate>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    margin: 20,
  },
  view: {
    flex: 1,
  },
})
