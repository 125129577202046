import {datadogLogs} from '@datadog/browser-logs'
import {Context} from '@datadog/browser-core'
import {datadogRum} from '@datadog/browser-rum'

import {
  DATADOG_KEY,
  DATADOG_SERVICE_NAME,
  DATADOG_APPLICATION_ID,
} from 'src/lib/Analytics/dataDogConfig'

import {Logger} from 'src/lib/Analytics/Logger'
import {NavigationContainerRef} from '@react-navigation/core'
class Datadog implements Logger {
  init(env: string, version: string): void {
    datadogLogs.init({
      clientToken: DATADOG_KEY,
      service: DATADOG_SERVICE_NAME,
      env,
      version,
      forwardErrorsToLogs: false, // This option makes the app malfunction so we want to set it to false
      sessionSampleRate: 100,
    })

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_KEY,
      service: DATADOG_SERVICE_NAME,
      env,
      version,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 25, // This is a percentage of sessionSampleRate
      traceSampleRate: env === 'prod' ? 10 : 100, // NOSONAR These are not magic.
      trackUserInteractions: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        'https://api.possiblefinance.com',
        'https://api.possiblesasquatch.com',
        'https://gateway.possiblefinance.com',
        'https://gateway.possiblesasquatch.com',
      ],
    })
  }

  setUserId(userId: string | undefined): void {
    datadogLogs.setUser({id: userId})
    datadogLogs.logger.setContextProperty('userId', userId)
  }

  info(data: string, context: object = {}): void {
    datadogLogs.logger.info(data, context as Context)
  }

  warn(data: string, context: object = {}): void {
    datadogLogs.logger.warn(data, context as Context)
  }

  error(data: string, context: object = {}): void {
    datadogLogs.logger.error(data, context as Context)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  trackViews(navRef: NavigationContainerRef<any> | null): void {
    /*
     Nothing to do here
    */
  }

  manuallyTrackViews(routeName: string): void {
    if (datadogRum.getInternalContext() !== undefined) {
      datadogRum.startView({
        name: routeName,
      })
    }
  }
}

export default new Datadog()
