import {createSelector} from 'reselect'
import {chain, orderBy} from 'lodash'
import moment from 'moment'

import {banking} from '@possible/generated/proto'
import {transferMethods} from 'src/lib/loans/consts'
import {bankLinkStatus, paymentInstrumentStatus, AccountType} from 'src/lib/user/userEnvConsts'
import {getPaymentInstrumentFromLoan} from 'src/lib/loans/utils'
import {latestLoanSelector} from 'src/lib/loans/selector'
import {PfReduxState} from 'src/reducers/types'
import {PaymentInstrumentType} from 'src/lib/user/types'

// Get payment instrument of current active loan

export const linkedAccounts = (state: PfReduxState) =>
  state.lib?.users?.linked_accounts?.accounts || []

export const linkedNumbers = (state) => state.lib?.users?.linked_accounts?.numbers || []

export const preferredAccount = createSelector(
  [linkedAccounts],
  (linkedAccountsArr): banking.ILinkedAccount | undefined =>
    linkedAccountsArr.filter((account) => account.isPreferredFundingSource)[0],
)

export const getLinkedAccounts = createSelector(linkedAccounts, (accounts) =>
  accounts.filter((account) => account.status?.toLowerCase() === bankLinkStatus.linked),
)

export const linkedAndActiveAccountsSelector = createSelector(getLinkedAccounts, (accounts) =>
  accounts.filter((account) => !account.isLoginRequired),
)

//Checking or Savings accounts
const fundableAccounts = (linkedAccountsArr: banking.ILinkedAccount[]) =>
  linkedAccountsArr.filter((account) => account.isDepositoryChecking || account.isDepositorySavings)

export const fundableAccountsSelector = createSelector(linkedAccounts, fundableAccounts)

export const preferredPaymentInstrument = createSelector(
  latestLoanSelector,
  (loan): PaymentInstrumentType | undefined => {
    if (loan?.paymentMethod === banking.LinkedAccount.ProcessorNetwork.check) {
      return {
        network: banking.LinkedAccount.ProcessorNetwork.check,
        paymentInstrumentStatus: banking.LinkedAccount.Number.NumberStatus.pending,
        isValid: true,
      }
    }

    return getPaymentInstrumentFromLoan(loan)
  },
)
//Get interchange payment instruments linked to preferred account
export const preferredAccountInterchangeSelector = createSelector(
  linkedNumbers,
  preferredAccount,
  (linkedNumbersArr, preferredAccountObj) => {
    //list of all debit cards linked to preferred account in ascending order by created date
    //return just the most recently created instrument if there are multiple
    return chain(linkedNumbersArr)
      ?.filter(
        (instrument) =>
          instrument.linkedAccountId === preferredAccountObj?.id &&
          instrument.network === transferMethods.interchange &&
          (instrument.status === paymentInstrumentStatus.pending ||
            instrument.status === paymentInstrumentStatus.verified),
      )
      ?.sortBy((instrument) => instrument.createdAt)
      .value()
      .pop()
  },
)

export const sortedFundingAccount = createSelector(fundableAccountsSelector, (accounts) =>
  orderBy(accounts, [(o) => moment(o.createdAt)?.unix()], ['desc']),
)
export const latestFundingAccount = createSelector(
  sortedFundingAccount,
  (accounts) => accounts?.[0] ?? undefined,
)

export const preferredPaymentAccount = createSelector(
  preferredPaymentInstrument,
  preferredAccount,
  linkedAccounts,
  (preferredInstrument, preferredAcct, accounts) => {
    if (preferredInstrument?.network === banking.LinkedAccount.ProcessorNetwork.check) {
      return {
        network: banking.LinkedAccount.ProcessorNetwork.check,
        paymentInstrumentStatus: banking.LinkedAccount.Number.NumberStatus.pending,
        isValid: true,
      }
    }
    /* If neither preferredAccount nor payment instrument exists clearing the payment method.
       We test on the network value to hide the component in the UI.
     */
    if (!preferredAcct && !preferredInstrument?.number?.status) {
      return {
        network: undefined,
        expDate: undefined,
        institutionName: undefined,
        paymentInstrumentId: undefined,
        accountId: undefined,
      }
    }
    // If preferred payment instrument does not exist return preferred account
    if (!preferredInstrument?.number?.status) {
      return {
        accountType: preferredAcct?.account?.subType,
        institutionName: preferredAcct?.institution?.name,
        accountId: preferredAcct?.id,
        id: preferredAcct?.id,
        network: banking.LinkedAccount.ProcessorNetwork.ach,
        mask: getNormalizedAccountMask(preferredAcct?.account),
        paymentInstrumentStatus: preferredAcct?.isLoginRequired
          ? banking.LinkedAccount.Number.NumberStatus.invalid
          : banking.LinkedAccount.Number.NumberStatus.pending,
        isValid: true,
      }
    }

    const account = accounts.find((obj) => obj.id === preferredInstrument?.number?.linkedAccountId)
    const instrument: PaymentInstrumentType = {
      network: preferredInstrument?.number?.network,
      accountType:
        preferredInstrument?.number?.network === banking.LinkedAccount.ProcessorNetwork.interchange
          ? AccountType.DEBIT_CARD
          : account?.account?.subType,
      expDate: preferredInstrument?.number?.expDate,
      paymentInstrumentId: preferredInstrument?.number?.id,
      paymentInstrumentStatus: preferredInstrument?.number?.status,
      mask: preferredInstrument?.number?.mask,
      isValid: preferredInstrument?.isValid,
    }

    // If payment instrument doesn't have linked account return just payment instrument data
    if (!account) {
      return instrument
    }

    instrument.id = account?.id ?? '' + preferredInstrument?.number?.id
    instrument.institutionName = account?.institution?.name
    instrument.accountId = account?.id

    return instrument
  },
)

export const getNormalizedAccountMask = (account?: banking.LinkedAccount.IAccount): string => {
  return account?.maskCorrected ?? account?.mask ?? ''
}
