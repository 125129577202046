import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AcceptAchQueryVariables = Types.Exact<{[key: string]: never}>

export type AcceptAchQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename?: 'LinkedAccount'
        id: string
        preferredFundingSource?: boolean | null
        mask: string
      }> | null
    }
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {__typename?: 'Loan'; id: string} | null
    }
    paymentMethods: {
      __typename?: 'PaymentMethodCollection'
      loanEligible?: Array<
        | {
            __typename: 'AchPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
          }
        | {
            __typename: 'CheckPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
          }
        | {
            __typename: 'DebitCardPaymentMethod'
            id: string
            defaultStatus: Array<Types.DefaultPaymentMethodCode>
          }
      > | null
    }
  }
}

export const AcceptAchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AcceptACH'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'paymentMethods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loanEligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AchPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'CheckPaymentMethod'},
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'defaultStatus'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptAchQuery, AcceptAchQueryVariables>
