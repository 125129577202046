import moment from 'moment'

import {
  CardAccountStatusCode,
  DeactivatedCardAccountReasonCode,
  CardAccountStatuses,
  Maybe,
} from 'src/cassandra'

export enum CardDashTileStatus {
  Error = -1,
  None,
  Apply,
  Pending,
  Approved,
  Rejected,
  Active,
  WaitList,
  ActivatePhysicalCard,
  CardAccount,
  Reapply,
  Expired,
  Deactivated,
}

export const isCardAccountFraudulent = (
  cardAccountStatus?: CardAccountStatuses | null,
): boolean => {
  return !!(
    cardAccountStatus?.code === CardAccountStatusCode.Deactivated &&
    cardAccountStatus?.__typename === 'DeactivatedCardAccountStatus' &&
    cardAccountStatus?.reasonCode === DeactivatedCardAccountReasonCode.Fraud
  )
}

/**
 * A user is eligible to reapply if all conditions are met:
 * * They have a card account status of rejected or expired
 * * The eligibleToReapplyAfter date is before the current date
 */
export const isUserEligibleToReapply = (cardAccountStatus?: CardAccountStatuses): boolean => {
  let eligibleToReapplyAfter = ''

  if (
    cardAccountStatus?.__typename === 'RejectedCardAccountStatus' ||
    cardAccountStatus?.__typename === 'ExpiredCardAccountStatus'
  ) {
    eligibleToReapplyAfter = cardAccountStatus.eligibleToReapplyAfter
    return moment(eligibleToReapplyAfter).isBefore(moment())
  } else {
    return false
  }
}

/**
 * A user is eligible to apply for cards if:
 * They don't have a user account (this is a new user)
 * or
 * they are a rejected user who is eligible to reapply
 */
export const isUserEligibleToApply = (cardAccountStatus?: CardAccountStatuses): boolean => {
  return !cardAccountStatus || isUserEligibleToReapply(cardAccountStatus)
}

export const isUserRejectedDueToQuota = (cardAccountStatus?: CardAccountStatuses): boolean => {
  if (
    cardAccountStatus?.__typename === 'RejectedCardAccountStatus' &&
    cardAccountStatus.rejectionReasonCodes?.length
  ) {
    const rejectedForQuota: Maybe<string>[] = (cardAccountStatus.rejectionReasonCodes ?? []).filter(
      (code) => code === 'user.reject.quota_reached',
    )
    return rejectedForQuota.length > 0
  }
  return false
}

type GetCardOfferStatusType = {
  canAccessCards: boolean
  isWaitListAllowed: boolean
  hasError: boolean
  pendingPhysicalCard: boolean
  cardAccountStatus?: CardAccountStatuses
  hasOffers: boolean
}

// eslint-disable-next-line complexity
export const getCardOfferStatus = ({
  canAccessCards,
  isWaitListAllowed,
  hasError,
  pendingPhysicalCard,
  cardAccountStatus,
  hasOffers,
}: GetCardOfferStatusType): CardDashTileStatus => {
  let tile = CardDashTileStatus.None
  switch (true) {
    case hasError:
      tile = CardDashTileStatus.Error
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Active && pendingPhysicalCard:
      tile = CardDashTileStatus.ActivatePhysicalCard
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Active:
      tile = CardDashTileStatus.Active
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Approved:
      tile = CardDashTileStatus.Approved
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Pending:
      tile = CardDashTileStatus.Pending
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Rejected:
      if (isUserEligibleToReapply(cardAccountStatus) && hasOffers) {
        tile = CardDashTileStatus.Reapply
      } else {
        tile = CardDashTileStatus.Rejected
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Expired:
      if (isUserEligibleToReapply(cardAccountStatus) && hasOffers) {
        tile = CardDashTileStatus.Reapply
      } else {
        tile = CardDashTileStatus.Expired
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Deactivated:
      if (isCardAccountFraudulent(cardAccountStatus)) {
        tile = CardDashTileStatus.None
      } else {
        tile = CardDashTileStatus.Deactivated
      }
      break
    case cardAccountStatus?.code === CardAccountStatusCode.Cancelled:
    case !cardAccountStatus:
      if (hasOffers) {
        tile = CardDashTileStatus.Apply
      } else {
        tile = CardDashTileStatus.None
      }
      break
    default:
      tile = CardDashTileStatus.None
      break
  }

  if (hasPermissionToViewTile(canAccessCards, tile)) {
    return tile
  }

  if (tile === CardDashTileStatus.Apply && isWaitListAllowed) {
    return CardDashTileStatus.WaitList
  }

  return CardDashTileStatus.None
}

const hasPermissionToViewTile = (canAccessCards: boolean, tile: CardDashTileStatus): boolean => {
  // Card access required for all tiles except waitlist, rejection, expiration, deactivation and reapply.
  return (
    canAccessCards ||
    [
      CardDashTileStatus.Rejected,
      CardDashTileStatus.Expired,
      CardDashTileStatus.Reapply,
      CardDashTileStatus.Deactivated,
    ].includes(tile)
  )
}

export enum TilesToDisplayOnce {
  CardRejectedTile = 'hasDisplayedCardRejectedTile',
  CardExpiredTile = 'hasDisplayedCardExpiredTile',
}
