import {useCallback, useEffect, useState} from 'react'

import {getUserPrequalificationStatus} from '@possible/cassandra/src/user/methods'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {APIv2ResponseBase} from '@possible/generated/APIClient'
import {lag} from '@possible/generated/proto'
import {LoanGetTerms} from 'src/api/actions/loans/loanActions'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {getLoanTerms} from 'src/lib/loans/selector'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {StateSelectedDocument} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelection.gqls'
import {LoanAmountSelectionContentLoanTermsFields} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent.types'
import {usePfDispatch, usePfSelector} from 'src/store/utils'

const isUndefined = (val: unknown): boolean => typeof val === 'undefined'

const LoanTermsRequiredTypeGuard = (
  loanTerms: lag.ILoanTerms | undefined,
): loanTerms is LoanAmountSelectionContentLoanTermsFields => {
  return !(
    isUndefined(loanTerms?.rate) ||
    isUndefined(loanTerms?.loanDurationWeeks) ||
    isUndefined(loanTerms?.collectionWeekInterval) ||
    isUndefined(loanTerms?.estimatedAPR) ||
    isUndefined(loanTerms?.maximumAmountRepeatLoan) ||
    isUndefined(loanTerms?.maximumAmount) ||
    isUndefined(loanTerms?.minimumAmount) ||
    isUndefined(loanTerms?.defaultAmount) ||
    isUndefined(loanTerms?.step) ||
    isUndefined(loanTerms?.title)
  )
}

type ErrorRetryPair = {
  error: Error
  handleRetry: () => Promise<void>
}

type UseLoanAmountSelectionDataResult = {
  isLoading: boolean
  error?: Error
  handleRetry?: () => Promise<void>
  prequalificationAmount?: number
  loanUsStateAbv?: string | null
  loanTerms?: LoanAmountSelectionContentLoanTermsFields
}

/**
 * Custom hook that loads necessary data into redux and from graphql for LoanAmountSelection screen.
 */
export const useLoanAmountSelectionData = (): UseLoanAmountSelectionDataResult => {
  const [prequalificationAmountString, setPrequalificationAmountString] = useState<string>('')
  const [isLoadingDataIntoRedux, setIsLoadingDataIntoRedux] = useState<boolean>(false)
  const [loadReduxDataError, setLoadReduxDataError] = useState<Error>()
  const dispatch = usePfDispatch()

  const loanTermsRaw: lag.ILoanTerms | undefined = usePfSelector(getLoanTerms)

  const {
    selectedData: loanUsStateAbv,
    loading: isLoadingStateSelected,
    error: stateSelectedError,
    refetch: handleRetry,
  } = useCassandraQuery(
    StateSelectedDocument,
    {
      fetchPolicy: 'network-only',
      onError: (e) => {
        logOfferApplicationError(e, 'Failed to get stateSelected')
      },
    },
    // Must check profile value first, and use || instead of ?? since the state could be an empty string
    (data) => data.me.profile?.home?.address?.state || data.me.onboarding?.loan?.stateSelected,
  )

  // load necessary data into redux
  // or local state (prequalificationAmountString)
  const handleLoadV2Data = useCallback(async () => {
    if (!loanUsStateAbv) {
      return
    }

    const getUserPrequalificationAmount = async (): Promise<string | undefined> => {
      try {
        const value = await getUserPrequalificationStatus(new Date().toISOString()).then((res) => {
          return res.amount
        })
        setPrequalificationAmountString(value)
        return value
      } catch (e) {
        logOfferApplicationError(e, 'LoanAmountSelection failed to get userPrequalificationStatus')
        throw e
      }
    }

    const getLoanTerms = async (): Promise<void> => {
      // UserStateRefresh is required to get loan terms data into the store
      const [wasRefreshSuccessful, loanTermsResponse] = await Promise.all<
        [Promise<boolean>, Promise<APIv2ResponseBase>]
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      >([dispatch(UserStateRefresh()), dispatch(LoanGetTerms(loanUsStateAbv))])
      if (wasRefreshSuccessful === false) {
        throw new Error('Failed to refresh user state')
      }
      if (loanTermsResponse.error) {
        throw new Error(loanTermsResponse.error.errorMsg)
      }
    }

    try {
      setIsLoadingDataIntoRedux(true)
      await Promise.all([getUserPrequalificationAmount(), getLoanTerms()])
      setLoadReduxDataError(undefined)
    } catch (e) {
      logOfferApplicationError(e, 'LoanAmountSelection failed to load necessary data')
      if (e instanceof Error) {
        setLoadReduxDataError(e)
      }
    } finally {
      setIsLoadingDataIntoRedux(false)
    }
  }, [dispatch, loanUsStateAbv])

  // load necessary data into redux
  useEffect(() => {
    if (!loanUsStateAbv) {
      return
    }

    // it handles the catch block itself
    void handleLoadV2Data()
  }, [handleLoadV2Data, loanUsStateAbv])

  let errorRetryPair: ErrorRetryPair | undefined
  const loanTerms = LoanTermsRequiredTypeGuard(loanTermsRaw) ? loanTermsRaw : undefined

  if (!loanTerms) {
    errorRetryPair = {
      error: new Error('Loan terms data is missing required fields'),
      handleRetry: handleLoadV2Data,
    }
  }

  if (loadReduxDataError) {
    errorRetryPair = {
      error: loadReduxDataError,
      handleRetry: handleLoadV2Data,
    }
  }

  if (stateSelectedError) {
    errorRetryPair = {
      error: stateSelectedError,
      handleRetry: async (): Promise<void> => {
        await handleRetry()
      },
    }
  }

  return {
    isLoading: isLoadingDataIntoRedux || isLoadingStateSelected,
    ...errorRetryPair,
    prequalificationAmount: parseFloat(prequalificationAmountString) || undefined,
    loanUsStateAbv,
    loanTerms: LoanTermsRequiredTypeGuard(loanTerms) ? loanTerms : undefined,
  }
}
