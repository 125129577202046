import React, {ReactElement} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

import {AcceptLoanAndStateAgreementsTXTemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsTX/AcceptLoanAndStateAgreementsTXTemplate'
import {AcceptLoanAndStateAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type AcceptLoanAndStateAgreementsTXWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptLoanAndStateAgreementsTX screen within Workflows.
 */
const AcceptLoanAndStateAgreementsTXWorkflowContainer: React.FC<
  AcceptLoanAndStateAgreementsTXWorkflowContainerProps
> = (props: AcceptLoanAndStateAgreementsTXWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'state_specific_tx',
    },
  })
  return (
    <AcceptLoanAndStateAgreementsGQLContainer
      testID="AcceptLoanAndStateAgreementsTXWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => (
        <AcceptLoanAndStateAgreementsTXTemplate {...innerProps} />
      )}
    />
  )
}

export {AcceptLoanAndStateAgreementsTXWorkflowContainer}
