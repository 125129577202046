import {useSelector} from 'react-redux'

import {CohortUserTpe} from 'src/cassandra'
import {userIsInAllowedUsStateSelector} from 'src/lib/loans/selector'
import {hasCompletedLoan} from 'src/lib/loans/utils'

export const useGetCohortUserType = (): CohortUserTpe => {
  const userIsInLoanState = useSelector(userIsInAllowedUsStateSelector)
  const userHasCompletedLoan = useSelector(hasCompletedLoan)
  if (userHasCompletedLoan) {
    return CohortUserTpe.LoanGrad
  } else if (userIsInLoanState) {
    return CohortUserTpe.LoanState
  } else {
    return CohortUserTpe.NonLoanState
  }
}
