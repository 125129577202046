import React, {FC} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import StateLoanInfo from 'src/products/loans/LoanSelection/StateLoanInfo'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  paymentInfo: PaymentInfoType
  fee: number
}

const AlaskaLoanInfo: FC<Props> = (props) => {
  const {navigation, paymentInfo, fee} = props
  const {t} = useTranslation('LoanSelection')
  return (
    <StateLoanInfo
      navigation={navigation}
      paymentInfo={paymentInfo}
      description={t('AKMainDescription', {fee})}
      feesTitle={t('OriginationFee')}
      alternateInterestTitle={t('FinanceCharge')}
    />
  )
}

export default AlaskaLoanInfo
