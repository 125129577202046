import React, {FC} from 'react'

import NavPageState from 'src/navigation/NavPageState'
import {LoanCounterOfferNavigationProps} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferTemplate'
import {LoanProcessingCounterOfferGQLContainer} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferGQLContainer'

export const LoanProcessingCounterOfferNavPageStateContainer: FC<
  LoanCounterOfferNavigationProps
> = (props) => {
  const {navigation} = props

  const handleOnComplete = async (): Promise<void> => {
    const nextPage = NavPageState.GetNextApprovalState('LoanCounterOffer')
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  const handleOnCancel = async (): Promise<void> => {
    await NavPageState.GoToScreen(navigation, {screen: 'Dashboard'})
  }

  return (
    <LoanProcessingCounterOfferGQLContainer
      onContinue={handleOnComplete}
      onCancel={handleOnCancel}
    />
  )
}
