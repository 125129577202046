import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useEffect} from 'react'

import {Consumer} from 'src/cassandra'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {latestLoanSelector, userIsInAllowedUsStateSelector} from 'src/lib/loans/selector'
import {getLoanStatus, isLoanActive as getIsLoanActive} from 'src/lib/loans/utils'
import Log from 'src/lib/loggingUtil'
import {navigateToDeeplinkIfNecessary} from 'src/lib/singular/utils'
import AppNavActions from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {useCards} from 'src/products/card/hooks/useCards'
import {getRouteForCardStatus} from 'src/products/loans/DashboardLoader/DashboardLoader.util'
import {usePfSelector} from 'src/store/utils'

type Props = StackScreenProps<MainStackParamList, 'DashboardLoader'>

/**
 * This component determines where the user should be
 * directed on app load.
 *
 * If `onboardingFinished` is false go to MPO.
 * If `onboardingFinished` is true:
 *  Go to loan dashboard if `currentModule` is `LOAN_END`
 *  Go to card dashboard if `currentModule` is `CARD_END`
 *  Go to waitlist if `currentModule` is `WAITLIST`
 */
export const DashboardLoader: FC<Props> = ({navigation}) => {
  const loan = usePfSelector(latestLoanSelector)
  const loanStatus = getLoanStatus(loan)
  const isLoanActive = getIsLoanActive(loanStatus)
  const {loading: isCardsLoading, cardAccountPolled: cardAccount} = useCards()
  const isLoanStateUser = usePfSelector(userIsInAllowedUsStateSelector)
  const {data, loading: isLoading, error} = Consumer.hooks.useGetOnboardingCurrentModuleQuery()

  useEffect(() => {
    if (isLoading || isCardsLoading) {
      return
    }

    if (error) {
      Log.error(error, `[DashboardLoader Error] - ${error.message}`)
      return
    }

    if (!data?.getOnboardingCurrentModule) {
      Log.error('DashboardLoader - Somehow we finished loading with no errors but do not have data')
      return
    }

    if (navigateToDeeplinkIfNecessary()) {
      return
    }

    // WARNING: this bypasses MPO and redirects users with an active loan to the Loans dashboard
    if (isLoanActive) {
      AppNavActions.replace(navigation, 'Dashboard')
      return
    }

    // ENG-16916 Remove this and replace with redirect to Product Hub
    // A user in workflows will have data.getOnboardingCurrentModule.onboardingFinished = false, even if they have completed onboarding
    if (cardAccount?.status) {
      AppNavActions.replace(navigation, getRouteForCardStatus(cardAccount, isLoanStateUser))
      return
    }

    if (data.getOnboardingCurrentModule.onboardingFinished) {
      const {currentModule} = data.getOnboardingCurrentModule
      switch (currentModule) {
        case Consumer.types.OnboardingModule.LoanEnd:
          AppNavActions.replace(navigation, LoanApprovedFlow.Dashboard)
          break
        case Consumer.types.OnboardingModule.CardEnd:
          AppNavActions.replace(navigation, getRouteForCardStatus(cardAccount, isLoanStateUser))
          break
        case Consumer.types.OnboardingModule.Waitlist:
          AppNavActions.replace(navigation, 'UnsupportedStateWaitList')
          break
        default:
          Log.error(
            `DashboardLoader - onboardingFinished with unknown final module: ${currentModule}`,
          )
          break
      }
    } else {
      AppNavActions.replace(navigation, 'Onboarding')
    }
  }, [
    data,
    error,
    isLoading,
    navigation,
    isCardsLoading,
    loan,
    isLoanActive,
    isLoanStateUser,
    cardAccount,
  ])

  return <Loading type="loader0" size="large" />
}
