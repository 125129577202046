import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'
import {
  ConfirmBankAccountGQLContainer,
  ConfirmBankAccountGQLContainerProps,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountGQLContainer'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'

export type ConfirmBankAccountNavPageStateContainerProps = StackScreenProps<
  MainStackParamList,
  'ConfirmBankAccount'
>

/**
 * Container to integrate the ConfirmBankAccount into the NavPageState navigation system.
 */
export const ConfirmBankAccountNavPageStateContainer: React.FC<
  ConfirmBankAccountNavPageStateContainerProps
> = (props) => {
  const {navigation, route} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_bank_account_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnSubmit: ConfirmBankAccountGQLContainerProps['onSubmit'] = async () => {
    TrackAppEvent(AppEvents.Name.confirm_bank_account_completed, AppEvents.Category.Checkout, {
      navContainerType: 'NavPageState',
    })

    // if the user was sent here because of an ACH error (probably from LoanFinalAccept) we will
    // go back to the previous screen after entering account details
    if (route.params.shouldNavBackAfterSubmit) {
      navigation.goBack()
    } else {
      const currentStep = LoanApprovedFlow.ConfirmBankAccount
      const nextApprovalStep = NavPageState.GetNextApprovalState(currentStep)
      await NavPageState.GoToScreen(navigation, nextApprovalStep)
    }
  }

  return <ConfirmBankAccountGQLContainer onSubmit={handleOnSubmit} />
}
