import {useMemo, useState} from 'react'
import {getSelectedCardOfferId as getSelectedCardOfferIdSelector} from 'src/lib/card/selectors'
import {getPfStore} from 'src/store'

export const useGetSelectedCardOfferId = (): string => {
  const [offerId, setOfferId] = useState('')

  useMemo(() => {
    const state = getPfStore().getState()
    getSelectedCardOfferIdSelector(state).then((id) => {
      if (id) {
        setOfferId(id)
      }
    })
  }, [])

  return offerId
}
