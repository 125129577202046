import React, {ReactElement, ReactNode} from 'react'
import {StyleSheet, View, ScrollView} from 'react-native'

import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type LightBoxViewProps = {
  busy?: boolean
  primary_action?: StackedButtonsAction | Array<StackedButtonsAction>
  secondary_action?: Array<StackedButtonsAction>
  body?: ReactElement
  body_text?: string
  title?: string | ReactNode
  children?: ReactNode
}

const LightboxView: React.FC<LightBoxViewProps> = (props) => {
  const {body_text, body, children, busy, title} = props
  let {primary_action, secondary_action} = props

  if (primary_action) {
    if (Array.isArray(primary_action)) {
      primary_action = primary_action.map((obj) => ({
        ...obj,
        disabled: busy,
      }))
    } else {
      primary_action.disabled = busy
    }
  }

  if (secondary_action?.length) {
    secondary_action = secondary_action?.map((obj) => ({
      ...obj,
      disabled: busy,
    }))
  }

  let bodyComp
  if (body_text) {
    bodyComp = <PFText variant={'p'}>{body_text}</PFText>
  } else {
    bodyComp = body ?? children
  }

  return (
    <ScrollView style={styles.scrollView} contentContainerStyle={[styles.scrollContainerView]}>
      {title ? (
        <View style={[styles.contentView, styles.contentSpacing]}>
          <PFText variant={'h2'}>{title}</PFText>
        </View>
      ) : null}
      {bodyComp ? (
        <View
          style={[
            styles.contentView,
            (primary_action || secondary_action) && styles.contentSpacing,
          ]}
        >
          {bodyComp}
        </View>
      ) : null}
      <StackedButtons primary={primary_action} secondary={secondary_action} />
    </ScrollView>
  )
}

export default LightboxView

/*
Note : Don't use vertical margins, they mess up with the calculated sizes, causing an expansion after initial rendering
 */
const styles = StyleSheet.create({
  contentSpacing: {
    marginBottom: smallGap,
  },
  contentView: {
    marginHorizontal: smallGap / 2,
  },
  scrollContainerView: {
    backgroundColor: genericPageBackground,
    width: '100%',
  },
  scrollView: {
    backgroundColor: genericPageBackground,
    height: -1,
    width: '100%',
  },
})
