import {findIndex, merge, cloneDeep} from 'lodash'

export function find_copy_update_by_id(inArray, update) {
  if (!update) {
    return inArray
  }

  if (!inArray) {
    return [update]
  }

  if (Array.isArray(update)) {
    const newArray = [...inArray]
    for (const item of update) {
      const index = findIndex(newArray, {id: item.id})
      if (index === -1) {
        newArray.push(item)
      } else {
        newArray.splice(index, 1, item)
      }
    }
    return newArray
  } else {
    const newArray = [...inArray]
    const index = findIndex(newArray, {id: update.id})
    if (index === -1) {
      newArray.push(update)
    } else {
      newArray.splice(index, 1, update)
    }

    return newArray
  }
}

function updateRecord(m, recordUpdate, compareUpdateAt, mode) {
  let exisitingRecord

  let setRecord = false
  if (compareUpdateAt || mode === 'MergeRecord') {
    exisitingRecord = m.get(recordUpdate.id)
  }

  if (!exisitingRecord || !compareUpdateAt || exisitingRecord.updateAt !== recordUpdate.updateAt) {
    setRecord = true
  }

  if (setRecord) {
    recordUpdate = cloneDeep(recordUpdate)

    if (mode === 'MergeRecord' && exisitingRecord) {
      recordUpdate = merge(exisitingRecord, recordUpdate)
    }
    m.set(recordUpdate.id, recordUpdate)
  }
  return m
}

export function update_map_state(
  map,
  record_updates,
  compareUpdateAt = false,
  mode = 'ReplaceRecord',
) {
  //$FlowFixMe
  const m = new Map(map)

  if (Array.isArray(record_updates)) {
    for (const record_update of record_updates) {
      updateRecord(m, record_update, compareUpdateAt, mode)
    }
  } else {
    updateRecord(m, record_updates, compareUpdateAt, mode)
  }

  return m
}

export function update_map(map, key, value) {
  //$FlowFixMe
  const m = new Map(map)
  m.set(key, value)
  return m
}
