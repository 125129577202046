import {UserLogoutMutation} from '@possible/cassandra/src/user/authPrivateMethods'

import {CLEAR_USER_ACTION, USER_SESSION_EXPIRED} from 'src/api/actions/actionsNames'
import {UserLoginStates} from 'src/api/reducers/types'
import {initializeCassandra} from 'src/cassandra'
import {ClearId, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {PfDispatch, PfGetState} from 'src/store/types'
import * as Keychain from 'src/lib/keychain'

function Logout(sessionExpired = false) {
  return async (dispatch: PfDispatch, getState: PfGetState): Promise<void> => {
    const state = getState()
    if (state.api.user_logged_state !== UserLoginStates.not_logged_in) {
      try {
        const wasLogoutSuccessful: boolean | undefined = await UserLogoutMutation()
        if (!wasLogoutSuccessful) {
          throw Error('Failed to logout user')
        }
        TrackAppEvent(AppEvents.Name.logout_succeeded, AppEvents.Category.Login)
      } catch (e) {
        Log.error(e, 'Unable to logout user')
      }
    }
    if (sessionExpired) {
      dispatch({type: USER_SESSION_EXPIRED})
    }
    // The below clears the invalid token from cassandra client
    await initializeCassandra(dispatch)
    await removeUserId()
    dispatch({type: CLEAR_USER_ACTION})
    ClearId()
  }
}

async function removeUserId(): Promise<void> {
  return Keychain.resetServicePassword()
}

export {Logout}
