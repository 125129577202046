import {useMemo, useEffect, useState} from 'react'

import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {UpdateLastLoan} from 'src/api/actions/loans/loanActions'
import {
  hasApplicationExpired as hasApplicationExpiredFn,
  hasLoanExpired as hasLoanExpiredFn,
  isLoanApproved as isLoanApprovedFn,
  isLoanRejected as isLoanRejectedFn,
} from 'src/lib/loans/utils'
import {latestLoanSelector} from 'src/lib/loans/selector'

export type UseLoanApplicationProcessingStatusResult = {
  isLoanApproved: boolean
  isLoanExpired: boolean
  isLoanRejected: boolean
  isCounterOfferFlow: boolean
  hasApplicationExpired: boolean
  isApplicationUWComplete: boolean
  lastPollTime: number
}

/**
 * This logic is copied from LoanProcessing.tsx
 */
export function useLoanApplicationProcessingStatus(
  shouldPoll: boolean,
): UseLoanApplicationProcessingStatusResult {
  const loan = usePfSelector(latestLoanSelector)
  const dispatch = usePfDispatch()
  const [lastPollTime, setLastPollTime] = useState<number>(0)

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => {
        void dispatch(UpdateLastLoan())
        setLastPollTime(new Date().getTime())
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [dispatch, shouldPoll])

  const loanStatus = loan?.status?.status
  const loanSubStatus = loan?.status?.subStatus
  const [isLoanApproved, isLoanRejected, isLoanExpired, hasApplicationExpired] = useMemo(() => {
    if (loanStatus && loanSubStatus) {
      return [
        isLoanApprovedFn(loanStatus),
        isLoanRejectedFn(loanStatus),
        hasLoanExpiredFn(loanStatus, loanSubStatus),
        hasApplicationExpiredFn(loanStatus, loanSubStatus),
      ]
    }

    return [false, false, false, false]
  }, [loanStatus, loanSubStatus])

  const isCounterOfferFlow = loan?.status?.counterOfferCodes
    ? loan?.status?.counterOfferCodes?.length > 0
    : false

  return {
    isLoanApproved,
    isLoanRejected,
    isLoanExpired,
    hasApplicationExpired,
    isCounterOfferFlow,
    isApplicationUWComplete:
      isCounterOfferFlow ||
      isLoanApproved ||
      isLoanRejected ||
      isLoanExpired ||
      hasApplicationExpired,
    lastPollTime: lastPollTime,
  }
}
