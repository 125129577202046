import React, {FC} from 'react'

import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {ModuleControlProps} from 'src/flows/types'
import {logErrorAndShowException} from 'src/lib/errors'
import {LivenessReduxContainer} from 'src/products/general/Liveness/LivenessReduxContainer'

export const LivenessMPOContainer: FC<ModuleControlProps> = ({onModuleComplete, moduleName}) => {
  useMPOAndroidToWeb(moduleName)

  const onComplete = async (): Promise<void> => {
    try {
      if (onModuleComplete) {
        await onModuleComplete()
      }
    } catch (e) {
      if (e instanceof Error) {
        void logErrorAndShowException(e, 'Error while trying capture selfie')
      }
    }
  }

  return <LivenessReduxContainer onComplete={onComplete} />
}
