import React, {FC, useEffect} from 'react'

import {CohortPhase, CohortUserTpe, Consumer} from 'src/cassandra'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {sanitizeCurrencyString} from 'src/products/card/Application/CardApplicationFinances/CardApplicationFinances.util'
import {
  CardApplicationFinanceFormData,
  CardApplicationFinancesTemplate,
} from 'src/products/card/Application/CardApplicationFinances/CardApplicationFinancesTemplate'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType'

export const CardApplicationFinancesGQLContainer: FC<{
  onComplete: () => Promise<void>
}> = (props) => {
  const {onComplete: handleOnComplete} = props
  const cohortUserType = useGetCohortUserType()
  const [cardToggleApplicationProperties] =
    Consumer.hooks.useCardAccountToggleApplicationPropertiesMutation()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  usePageViewedAnalytics({
    eventName: CardEvents.self_reported_income_expenses_screen_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  // Loan grads should never see this screen
  // If a loan grad does somehow end up here, trigger the datadog monitor
  // Delete in ENG-19982
  useEffect(() => {
    if (cohortUserType === CohortUserTpe.LoanGrad) {
      // this string matches the datadog monitor here: https://app.datadoghq.com/monitors/149976905
      Log.error('[card-credit-bureau-error] Loan grad at application finances screen')
    }
  }, [cohortUserType])

  const handleOnSubmitSelfReportedData = async (
    data: CardApplicationFinanceFormData,
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const {annualGrossIncome, monthlyHousingCost} = data
      await cardToggleApplicationProperties({
        variables: {
          applicationDataInput: {
            enabled: true,
            miscData: {
              cohortPhase: CohortPhase.OnboardingExp1,
              cohortUserType,
            },
            extraFlags: [],
            selfReportedData: {
              annualGrossIncome: sanitizeCurrencyString(annualGrossIncome),
              monthlyHousingCost: sanitizeCurrencyString(monthlyHousingCost),
            },
          },
        },
      })
      await handleOnComplete()
      TrackAppEvent(CardEvents.self_reported_income_expenses_submitted, AppEvents.Category.Card)
    } catch (e) {
      Log.error('Did not send Self Reported Data successfully')
      ShowException(e)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <CardApplicationFinancesTemplate
      onSubmitSelfReportedData={handleOnSubmitSelfReportedData}
      isLoading={isLoading}
    />
  )
}
