import React, {FC} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import StateLoanInfo from 'src/products/loans/LoanSelection/StateLoanInfo'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  paymentInfo: PaymentInfoType
}

const AlabamaLoanInfo: FC<Props> = (props) => {
  const {navigation, paymentInfo} = props
  const {t} = useTranslation('LoanSelection')

  return (
    <StateLoanInfo
      navigation={navigation}
      paymentInfo={paymentInfo}
      feesTitle={t('FinanceFeeAndSurcharge')}
      hideInterest={true}
    />
  )
}

export default AlabamaLoanInfo
