import React from 'react'

import {badConnectionSelector, userIsLoggedIn} from 'src/api/selectors/selectors'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {NetworkFailureError} from 'src/products/loans/NetworkFailureError/NetworkFailureError'
import {MainStackBaseOptions, MainStackNav} from 'src/nav/Stacks/consts'
import {AuthenticatedRoutes, UnauthenticatedRoutes} from 'src/nav/Stacks/Routes'
import {usePfSelector} from 'src/store/utils'

export const MainStack = () => {
  const loggedIn = usePfSelector(userIsLoggedIn)
  const badConnection = usePfSelector(badConnectionSelector)

  if (badConnection) {
    return <NetworkFailureError logo={true} backgroundColor={genericPageBackground} />
  }

  if (loggedIn) {
    return <AuthenticatedRoutes StackNav={MainStackNav} baseOptions={MainStackBaseOptions} />
  }

  return <UnauthenticatedRoutes StackNav={MainStackNav} baseOptions={MainStackBaseOptions} />
}
