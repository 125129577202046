import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {ReapplicationBankGQLContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'

export const ReapplicationBankWorkflowContainer: React.FC<WorkflowPreReqFulfillScreenProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.link_bank_account_primer_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }

  const bankLinkAnalyticEvent = (): void =>
    TrackAppEvent(
      AppEvents.Name.link_bank_account_primer_completed,
      AppEvents.Category.InitialApplication,
      {
        navContainerType: 'Workflows',
      },
    )

  return (
    <ReapplicationBankGQLContainer
      onContinue={handleOnContinue}
      bankLinkAnalyticEvent={bankLinkAnalyticEvent}
    />
  )
}
