import React, {FC, ReactElement} from 'react'
import {useSelector} from 'react-redux'
import {useNavigation} from '@react-navigation/native'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import AppNav from 'src/nav/AppNavActions'
import {latestLoanTypeSelector} from 'src/lib/loans/selector'
import {PfReduxState} from 'src/reducers/types'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {LAStateDisclosureTemplate} from 'src/products/loans/StateDisclosure/LAStateDisclosure/LAStateDisclosureTemplate'
import {OHStateDisclosureTemplate} from 'src/products/loans/StateDisclosure/OHStateDisclosure/OHStateDisclosureTemplate'
import {regionCodes} from 'src/lib/loans/consts'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {StateDisclosureGQLContainer} from 'src/products/loans/StateDisclosure/StateDisclosureGQLContainer'

export const StateDisclosureMPOContainer: FC<ModuleControlProps> = (props) => {
  const {onStepComplete} = props
  const navigation = useNavigation()
  const loanType = useSelector((state: PfReduxState) => latestLoanTypeSelector(state))

  usePageViewedAnalytics({
    eventName: AppEvents.Name.state_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnContinue = (): void => {
    TrackAppEvent(AppEvents.Name.state_disclosure_accepted, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })

    void onStepComplete?.()
  }

  const handleOnExit = (): void => {
    TrackAppEvent(AppEvents.Name.state_disclosure_exit, AppEvents.Category.Checkout, {
      navContainerType: 'MPO',
    })
    AppNav.popToTop(navigation)
  }

  if (loanType?.regionCode === regionCodes.Louisiana) {
    return (
      <StateDisclosureGQLContainer
        onExit={handleOnExit}
        onContinue={handleOnContinue}
        render={(props): ReactElement => {
          return <LAStateDisclosureTemplate {...props} />
        }}
      ></StateDisclosureGQLContainer>
    )
  } else if (loanType?.regionCode === regionCodes.Ohio) {
    return (
      <StateDisclosureGQLContainer
        onExit={handleOnExit}
        onContinue={handleOnContinue}
        render={(props): ReactElement => {
          return <OHStateDisclosureTemplate {...props} />
        }}
      ></StateDisclosureGQLContainer>
    )
  } else {
    return <ErrorTemplate error={new Error('StateDisclosureMPOContainer: Unknown region code')} />
  }
}
