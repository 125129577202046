import {UserAccountsReduxTypesV1} from 'src/lib/user/types'

export const USERS_STATE_CHANGE_EVENT = 'UsersStateChange'
export const USERS_FINS_UPDATE = 'UserFinsUpdate'
export const USERS_PREQUALIFICATION_STATUS_UPDATE = 'UserPrequalificationStatusUpdate'

////////////////

export function UsersStateChange(state) {
  return {type: USERS_STATE_CHANGE_EVENT, state}
}

/////

export function UsersLinkedAccountsUpdate(accounts: UserAccountsReduxTypesV1) {
  return {type: USERS_FINS_UPDATE, accounts}
}
