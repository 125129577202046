import React from 'react'
import {Linking} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {callNow} from 'src/products/card/Dashboard/util'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

// TODO: ENG-14055 Delete this component when feature flag is disabled
const CardMakePaymentPOT = (): JSX.Element => {
  const {t} = useTranslation('CardMakePaymentPOT')
  usePageViewedAnalytics({
    eventName: CardEvents.card_pot_pay_now_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('CallNow'),
          onPress: () => {
            TrackAppEvent(CardEvents.card_pot_pay_now_page_call_cta, AppEvents.Category.Card)
            callNow()
          },
        },
      }}
      variant={'generic'}
      title={t('MakeAPayment')}
      smallTopGap={true}
    >
      <Box {...boxProps}>
        <Box gap={'small'}>
          <PFText variant={'p'}>
            <Trans i18nKey={'CardMakePaymentPOT'} t={t}>
              Soon, you’ll be able to make manual payments when you’re in Pay Over Time. Right now,
              that feature isn’t available yet.{`\n`}
              {`\n`}Please call us at{' '}
              <SvgLink
                linkType={'inline'}
                onPress={() => {
                  callNow()
                }}
                textVariant={'p'}
                linkText={'1-855-961-5354'}
              />{' '}
              or email{' '}
              <SvgLink
                linkType={'inline'}
                onPress={() => Linking.openURL('mailto:help@possiblefinance.com')}
                textVariant={'p'}
                linkText={'support@possiblefinance.com'}
              />{' '}
              to make a manual payment toward your balance.
            </Trans>
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}
const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'medium',
  boxStyle: {overflow: 'hidden'},
  gap: 'none',
}

export default CardMakePaymentPOT
