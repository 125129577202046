import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {LoanDisbursementMethodSelectionGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type LoanDisbursementMethodSelectionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Workflow container for the LoanDisbursementMethodSelection screen to integrate it into
 * the Workflows system.
 */
export const LoanDisbursementMethodSelectionWorkflowContainer: React.FC<
  LoanDisbursementMethodSelectionWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Checkout,
    eventName: AppEvents.Name.loan_disbursement_method_selection_screen_viewed,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    try {
      await onPreReqFulfilled()
    } catch (e) {
      void logOfferActivationErrorAndShowException(
        e,
        'LoanDisbursementMethodSelectionWorkflowContainer handleOnContinue() failed',
      )
    }
  }

  return <LoanDisbursementMethodSelectionGQLContainer onContinue={handleOnContinue} />
}
