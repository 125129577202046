import {PRODUCTION_BRANCH, STAGING_BRANCH} from 'src/CodePushDeployments'

export async function getUpdateMetadata() {
  //we can get fancier with this in the future. We should pull the branch out of the path
  const label = __DEV__ ? STAGING_BRANCH : PRODUCTION_BRANCH
  return {label}
}

export default {
  getUpdateMetadata,
}
