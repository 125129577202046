import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

import AggregatorPlaid from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid'
import AggregatorPlaidOld from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaid'
import {AggregatorPlaidProps} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaid.types'

type Props = AggregatorPlaidProps & StackScreenProps<MainStackParamList, 'AggregatorPlaid'>

export const AggregatorPlaidFlagRouter: React.FC<Props> = (props) => {
  const {navigation} = props

  const hasRefactoredPlaidFlag = useIsFeatureFlagEnabled('use-plaid-refactor-and-oauth-redirect')

  const routes = navigation.getState().routes
  let previousRouteName: keyof MainStackParamList | undefined
  if (routes.length > 1) {
    previousRouteName = routes[routes.length - 2].name
  }

  // for the moment we only want to use the new plaid flow
  // if the feature flag is on and we are currently in onboarding
  if (
    hasRefactoredPlaidFlag &&
    (previousRouteName === 'Onboarding' || previousRouteName === 'OfferApplicationWorkflow')
  ) {
    return <AggregatorPlaid {...props} />
  }

  return <AggregatorPlaidOld {...props} />
}
