import React, {useState} from 'react'
import {ApolloError} from '@apollo/client'
import moment from 'moment'

import {
  accountAndRoutingProvidedSelector,
  disbursementMethodSelectedSelector,
  paymentMethodSelectedSelector,
} from 'src/lib/loans/selector'
import {
  UseAcceptLoanArguments,
  useAcceptLoan,
} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan'
import {
  LoanFinalAcceptTemplate,
  LoanFinalAcceptTemplateProps,
  useLoanFinalAcceptTitle,
} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptTemplate'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {DisbursementSettlementDateType} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.types'
import {getLoanDisbursementAvailableDate} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.utils'
import {LoanFinalAcceptDocument} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.gqls'
import {LoanOriginator, LoanRole} from '@possible/cassandra/src/types/types.mobile.generated'
import {transferMethods} from 'src/lib/loans/consts'
import {TransferMethodsToLoanPaymentMethod} from 'src/products/loans/LoanApprovedActivation/LoanApprovedActivation.utils'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {UserStateRefresh} from 'src/api/actions/user/userActions'

export type LoanFinalAcceptGQLContainerProps = {
  isWorkflows?: boolean
  onConfirmBankDetails: () => void
  onContinue: () => Promise<void>
}

const LoanFinalAcceptGQLContainer: React.FC<LoanFinalAcceptGQLContainerProps> = (props) => {
  const {onConfirmBankDetails, onContinue, isWorkflows} = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showMoneyOnTheWayOverlay, setShowMoneyOnTheWayOverlay] = useState(false)
  const [disbursementSettlementDate, setDisbursementSettlementDate] =
    useState<DisbursementSettlementDateType>()

  const {
    selectedData,
    error: queryError,
    loading: isQueryLoading,
  } = useCassandraQuery(
    LoanFinalAcceptDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'LoanFinalAccept query error',
        )
      },
      onCompleted: (data) => {
        const loanId = data.me.loans.latestActionableLoan?.id
        if (!loanId) {
          return
        }
        try {
          void getLoanDisbursementAvailableDate({
            loanId: loanId,
            activeDate: moment().utc(),
          })
            .then((response) => {
              setDisbursementSettlementDate(response)
            })
            .catch((e) => {
              logOfferActivationErrorAndShowException(e)
            })
        } catch (e) {
          logOfferActivationErrorAndShowException(e)
        }
      },
    },
    (data) => {
      let amountApproved: string | undefined = undefined
      let isExtendedInstallmentPlanConvertedLoan = false

      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ApprovedLoanAggregateStatus'
      ) {
        amountApproved = data.me.loans.latestActionableLoan.aggregateStatus.amountApproved
        const conversion = data.me.loans.latestActionableLoan.aggregateStatus.conversion
        isExtendedInstallmentPlanConvertedLoan =
          conversion.__typename === 'LoanConversionConverted' &&
          conversion.role === LoanRole.ConversionReplacement
      }
      return {
        amountApproved: amountApproved,
        loanId: data.me.loans.latestActionableLoan?.id,
        originator: data.me.loans.latestActionableLoan?.originator,
        firstName: data.me.profile?.name?.firstName,
        lastName: data.me.profile?.name?.lastName,
        preferredAccount: data.me.bankAccounts.all?.find(
          (account) => account.preferredFundingSource,
        ),
        isExtendedInstallmentPlanConvertedLoan,
      }
    },
  )

  const loanId = selectedData?.loanId

  const [acceptLoan, , , hasAchMaskAccountError] = useAcceptLoan()

  const accountAndRouting = usePfSelector(accountAndRoutingProvidedSelector)
  const paymentMethod = usePfSelector(paymentMethodSelectedSelector)
  const disbursementMethod =
    usePfSelector(disbursementMethodSelectedSelector) ?? transferMethods.ach

  const onPressAcceptLoan = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      if (!loanId) {
        return
      }

      const acceptLoanProps: UseAcceptLoanArguments = {
        loanId: loanId,
        accountNumber: accountAndRouting?.account_number,
        routingNumber: accountAndRouting?.routing_number,
        disbursementMethod: TransferMethodsToLoanPaymentMethod(disbursementMethod),
        paymentMethod: TransferMethodsToLoanPaymentMethod(paymentMethod),
      }

      const isSuccessful = await acceptLoan({
        ...acceptLoanProps,
      })

      if (isSuccessful) {
        if (!isWorkflows) {
          setShowMoneyOnTheWayOverlay(true)
        } else {
          await onContinue()
        }
      }
    } catch (e) {
      logOfferActivationError(e, 'LoanFinalAccept acceptLoan() failed')
    } finally {
      setIsSubmitting(false)
    }
  }

  const dispatch = usePfDispatch()

  const onConfirmBankDetailsModal = async (): Promise<void> => {
    await dispatch(UserStateRefresh())
    onConfirmBankDetails()
  }

  const loanFinalAcceptTemplateProps: LoanFinalAcceptTemplateProps = {
    accountMask: selectedData?.preferredAccount?.mask ?? '',
    achMaskAccountError: hasAchMaskAccountError,
    amountApproved: selectedData?.amountApproved ?? '0',
    disbursementSettlementDate,
    firstName: selectedData?.firstName ?? '',
    isLoanInCoastalState: selectedData?.originator === LoanOriginator.Coastal,
    isShowMoneyOnTheWayOverlayVisible: showMoneyOnTheWayOverlay,
    isSubmitting,
    isExtendedInstallmentPlanConvertedLoan: selectedData?.isExtendedInstallmentPlanConvertedLoan,
    lastName: selectedData?.lastName ?? '',
    loanId: loanId ?? '',
    onConfirmBankDetailsModal: onConfirmBankDetailsModal,
    onOverlayContinue: onContinue,
    onPressAcceptLoan: onPressAcceptLoan,
    paymentMethod: paymentMethod,
  }

  return (
    <BaseTemplate
      isError={!!queryError}
      isLoading={isQueryLoading}
      pageTitle={useLoanFinalAcceptTitle(selectedData?.isExtendedInstallmentPlanConvertedLoan)}
      showTileBorder={false}
      onErrorBoundary={(err: Error): void => {
        logOfferActivationError(err, 'LoanFinalAccept render failures')
      }}
      testID="LoanFinalAccept"
    >
      <LoanFinalAcceptTemplate {...loanFinalAcceptTemplateProps} />
    </BaseTemplate>
  )
}

export {LoanFinalAcceptGQLContainer}
