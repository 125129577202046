import {ChaosEnabled} from '@possible/chaos'
import React from 'react'

import {
  UsePageViewedAnalyticsProps,
  usePageViewedAnalytics,
} from 'src/lib/Analytics/AnalyticsHelper'
import {
  AdhocPaymentConfirmTemplate,
  AdhocPaymentConfirmTemplateProps,
} from 'src/products/general/AdhocPayment/AdhocPaymentConfirm/AdhocPaymentConfirmTemplate'
import {
  BaseTemplate,
  BaseTemplateProps,
} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {usePfDispatch} from 'src/store/utils'

export type AdhocPaymentConfirmContainerProps = Pick<
  AdhocPaymentConfirmTemplateProps,
  | 'totalOwed'
  | 'isLoading'
  | 'isSubmittingPayment'
  | 'onPressPaymentAmount'
  | 'onPressSubmitPayment'
  | 'onPressViewPaymentMethods'
  | 'overrideTitle'
  | 'paymentAmount'
  | 'selectedButtonLabel'
  | 'selectedPaymentMethod'
  | 'infoCapsuleElement'
> & {
  testID: string
  pageViewedAnalytics: UsePageViewedAnalyticsProps
  paymentMethodLinkAnalyticEvent: () => void
} & Pick<BaseTemplateProps, 'onErrorBoundary'>

const AdhocPaymentConfirmContainer: React.FC<AdhocPaymentConfirmContainerProps> = (props) => {
  const {
    testID,
    totalOwed,
    isLoading,
    isSubmittingPayment,
    onPressPaymentAmount: handleOnPressPaymentAmount,
    onPressSubmitPayment: handleOnPressSubmitPayment,
    onPressViewPaymentMethods: handleOnPressViewPaymentMethods,
    overrideTitle,
    pageViewedAnalytics,
    paymentAmount,
    selectedButtonLabel,
    selectedPaymentMethod,
    infoCapsuleElement,
    onErrorBoundary: handleOnErrorBoundary,
    paymentMethodLinkAnalyticEvent,
  } = props

  usePageViewedAnalytics(pageViewedAnalytics)
  const dispatch = usePfDispatch()

  return (
    <BaseTemplate onErrorBoundary={handleOnErrorBoundary}>
      <ChaosEnabled dispatch={dispatch} componentName={testID}>
        <AdhocPaymentConfirmTemplate
          totalOwed={totalOwed}
          isLoading={isLoading}
          isSubmittingPayment={isSubmittingPayment}
          onPressPaymentAmount={handleOnPressPaymentAmount}
          onPressSubmitPayment={(): Promise<void> => handleOnPressSubmitPayment()}
          onPressViewPaymentMethods={handleOnPressViewPaymentMethods}
          overrideTitle={overrideTitle}
          paymentAmount={paymentAmount}
          selectedButtonLabel={selectedButtonLabel}
          selectedPaymentMethod={selectedPaymentMethod}
          infoCapsuleElement={infoCapsuleElement}
          paymentMethodLinkAnalyticEvent={paymentMethodLinkAnalyticEvent}
        />
      </ChaosEnabled>
    </BaseTemplate>
  )
}

export {AdhocPaymentConfirmContainer}
