import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {connect, ConnectedProps} from 'react-redux'
import {StackScreenProps} from '@react-navigation/stack'
import {WithTranslation, withTranslation} from 'react-i18next'

import {smallGap} from 'src/designSystem/layout'
import {sort_loans_by_created_desc, getLoanStatus, getLoanSubStatus} from 'src/lib/loans/utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import TileListView from 'src/products/loans/components/molecules/TileListView'
import Documents from 'src/products/loans/LoanHistory/Documents'
import {latestLoanSelector} from 'src/lib/loans/selector'
import {statusList} from 'src/lib/loans/consts'
import {formatShortNumericDate} from 'src/lib/time_util'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PageViewedAnalyticsProvider} from 'src/lib/Analytics/AnalyticsHelper'

const VISIBLE_LOANS = [
  statusList.ACTIVE,
  statusList.PENDING,
  statusList.PAIDOFF,
  statusList.CHARGED_OFF,
  statusList.LATE,
  statusList.DEFAULT,
  statusList.APPROVED,
]

const NO_DOC_LOANS = [
  statusList.REJECTED,
  statusList.PENDING,
  statusList.APPROVED,
  statusList.EXPIRED,
  statusList.CANCELLED,
]

const TRANSFER_STATUS_DUE = [statusList.PENDING, statusList.FAILED]
type Props = PropsFromRedux & WithTranslation & StackScreenProps<MainStackParamList, 'LoanHistory'>
type State = {
  busy: boolean
}
class LoanHistory extends Component<Props, State> {
  state = {
    busy: false,
  }

  componentDidMount() {
    TrackAppEvent(
      AppEvents.Name.lefthand_drawer_loan_history_screen_viewed,
      AppEvents.Category.Admin,
    )
  }

  getProps(loan) {
    const {t} = this.props
    const transfers = this.props.transfers?.[loan.id]
    const count =
      transfers?.reduce(
        (sum, transfer) => sum + (TRANSFER_STATUS_DUE.includes(transfer.status) ? 1 : 0),
        0,
      ) ?? 0
    const data = {
      title: t('CurrentLoan'),
      date: t('CurrentLoanDate', {date: formatShortNumericDate(loan?.originationDatetime)}),
      status: t('CurrentLoanStatus', {count: count}),
    }
    const createdAt = formatShortNumericDate(loan.createdAtDatetime)

    switch (getLoanStatus(loan)) {
      case statusList.PENDING:
        {
          data.title = t('LoanSubmittedTitle')
          data.date = t('LoanSubmittedDate', {date: createdAt})
          data.status = t('LoanSubmittedStatus')
        }
        break
      case statusList.APPROVED:
        {
          data.title = t('LoanApprovedTitle')
          data.date = t('LoanApproveDate', {date: createdAt})
          data.status = t('LoanApprovedStatus')
        }
        break
      case statusList.CHARGED_OFF:
        data.title =
          getLoanSubStatus(loan) === statusList.CHARGED_OFF_COLLECTED
            ? t('PaidOffStatus')
            : t('ChargedOffStatus')
        break
      case statusList.PAIDOFF:
        data.title = t('PaidOffStatus')
    }

    return data
  }

  renderLoan(loan, showDocs, showStatus) {
    const amount = loan.amountBorrowed.toFixed(2)
    const {title, date, status} = this.getProps(loan)
    return (
      <View key={loan.id} style={styles.loan_card_view}>
        <View style={[styles.postextPadding, styles.loan_card_header_view]}>
          <View style={styles.loanState}>
            <PFText variant={'p_lg_semibold'}>{title}</PFText>
          </View>
          <View style={styles.amount_view}>
            <PFText variant={'p_lg_semibold'}>${amount}</PFText>
          </View>
        </View>
        <View style={styles.postextPadding}>
          <PFText variant={'p_sm'}>{date}</PFText>
        </View>
        {showStatus ? (
          <View style={styles.postextPadding}>
            <PFText variant={'p_sm'}>{status}</PFText>
          </View>
        ) : null}
        {showDocs ? (
          <Documents navigation={this.props.navigation} route={this.props.route} loan={loan} />
        ) : null}
      </View>
    )
  }

  renderLoans() {
    return this.props.loans.map((loan) => {
      let showDocs = true
      let showStatus = false
      if (!loan) return null
      const loanStatus = getLoanStatus(loan)
      if (!loanStatus) return null
      if (!VISIBLE_LOANS.includes(loanStatus)) return null

      if (NO_DOC_LOANS.includes(loanStatus)) showDocs = false

      if (loan.id === this.props.latestLoan?.id) {
        showStatus = true
      }
      return this.renderLoan(loan, showDocs, showStatus)
    })
  }

  render() {
    const {t} = this.props
    return (
      <GenericNonModalTemplate title={t('LoanHistoryTitle')}>
        <PageViewedAnalyticsProvider
          eventName={AppEvents.Name.lefthand_drawer_loan_history_screen_viewed}
          eventCategory={AppEvents.Category.Admin}
        />
        <View style={styles.tileListView}>
          <TileListView>
            <View />
            {this.renderLoans()}
            <View />
          </TileListView>
        </View>
      </GenericNonModalTemplate>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loans: sort_loans_by_created_desc(state.lib.loans.loans),
    transfers: state.lib.loans.transfers,
    latestLoan: latestLoanSelector(state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(withTranslation('LoanHistory')(LoanHistory))

const styles = StyleSheet.create({
  amount_view: {
    alignSelf: 'flex-end',
  },
  loanState: {
    alignSelf: 'flex-start',
  },
  loan_card_header_view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loan_card_view: {
    marginBottom: smallGap,
    paddingTop: smallGap,
  },
  postextPadding: {
    flex: 1,
    marginBottom: smallGap / 2,
  },
  tileListView: {
    flex: 1,
    marginBottom: smallGap / 2,
    width: '100%',
  },
})
