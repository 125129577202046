import React from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import ProductIntroduction, {
  ProductIntroductionStep,
} from 'src/products/general/ProductIntroduction/ProductIntroduction'
import PlaidLink from 'src/assets/illustrations/plaidLink_phoneMock.svg'
import Syncing from 'src/assets/illustrations/syncing_PhoneMock.svg'
import BorrowSlider from 'src/assets/illustrations/borrowSlider_phoneMock.svg'
import AcceptLoan from 'src/assets/illustrations/acceptLoan_phoneMock.svg'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'

type LoanProductIntroductionTemplateProps = {
  onContinue: (() => void) | (() => Promise<void>)
}

/**
 * Displays intro information about the loans production.
 */
const LoanProductIntroductionTemplate: React.FC<LoanProductIntroductionTemplateProps> = (props) => {
  const {onContinue} = props

  const {t} = useTranslation('Onboarding')

  const steps: ProductIntroductionStep[] = [
    {
      title: t('LoanProductIntroductionStep1Title'),
      subtitle: t('LoanProductIntroductionStep1Subtitle'),
      illustration: <PFSvgContain svg={PlaidLink} height={218} />,
      testID: 'LoanProductIntroduction_link_your_bank_account',
    },
    {
      title: t('LoanProductIntroductionStep2Title'),
      subtitle: t('LoanProductIntroductionStep2Subtitle'),
      illustration: <PFSvgContain svg={Syncing} height={218} />,
      testID: 'LoanProductIntroduction_wait_for_account_syncing',
    },
    {
      title: t('LoanProductIntroductionStep3Title'),
      subtitle: t('LoanProductIntroductionStep3Subtitle'),
      illustration: <PFSvgContain svg={BorrowSlider} height={218} />,
      testID: 'LoanProductIntroduction_select_your_loan_amount',
    },
    {
      title: t('LoanProductIntroductionStep4Title'),
      subtitle: t('LoanProductIntroductionStep4Subtitle'),
      illustration: <PFSvgContain svg={AcceptLoan} height={218} />,
      testID: 'LoanProductIntroduction_accept_your_loan',
    },
  ]

  const primaryButton: ButtonLockupPropsPrimary = {
    text: t('LoanProductIntroductionCTA'),
    onPress: async () => {
      TrackAppEvent(AppEvents.Name.click_start_by_linking, AppEvents.Category.Onboarding)
      await onContinue?.()
    },
  }

  return (
    <ProductIntroduction
      title={t('LoanProductIntroductionTitle')}
      subtitle={t('LoanProductIntroductionSubText')}
      actionText={t('LoanProductIntroductionActionText')}
      steps={steps}
      primaryButton={primaryButton}
      testIDValues={{
        page: 'LoanProductIntroduction_page',
        title: 'LoanProductIntroduction_title',
        subTitle: 'LoanProductIntroduction_subTitle',
        primaryButton: 'LoanProductIntroduction_primaryButton',
      }}
    />
  )
}

export default LoanProductIntroductionTemplate
