import {Consumer} from '@possible/cassandra'
import {StackNavigationProp} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfSelector} from 'src/store/utils'
import {
  hasChargedOffLoan as hasChargedOffLoanSelector,
  hasOpenLoan as hasOpenLoanSelector,
} from 'src/lib/loans/utils'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'
import {useWorkflowsInitialRoute} from 'src/workflows/hooks/useWorkflowsInitialRoute'

import {WorkflowsInitialRouteType} from 'src/workflows/types'
import {getUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'

type UseInitializeCardOnboardingFlowType = () => () => Promise<void>

export const useInitializeCardOnboardingFlow: UseInitializeCardOnboardingFlowType = () => {
  const hasOpenLoan = usePfSelector(hasOpenLoanSelector)
  const hasChargedOffLoan = usePfSelector(hasChargedOffLoanSelector)
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const workflows = useWorkflows()
  const initialRoute: WorkflowsInitialRouteType = useWorkflowsInitialRoute(
    workflows,
    'DashboardLoader',
  )

  return async () => {
    const hasActiveCard = await Consumer.methods.hasActiveCard()
    const {value: cardApplicationGroupValue} = await getUserProperty('cardApplicationGroup')
    const isCardsUser: boolean = cardApplicationGroupValue?.possibleCard ?? false
    const isEligibleToApply = isCardsUser && !hasOpenLoan && !hasChargedOffLoan && !hasActiveCard

    if (initialRoute.loading) {
      return
    }

    // In MPO, Reset onboarding if it has already completed (ex. in cases of rejected or expired users applying again)
    if (isEligibleToApply) {
      const current = await Consumer.methods.GetOnboardingCurrentModule()
      if (current.onboardingFinished) {
        await Consumer.methods.OnboardingInitialize()
        navigation.reset({routes: [{name: 'Onboarding'}]})
        return
      }
    }

    // The authenticated user has attempted to enter the card onboarding flow, but is not eligible for a card.
    // Redirect them to the Dash
    navigation.reset({routes: [{name: initialRoute.name}]})
  }
}
