import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {
  AddressForm,
  RequiredAddressFields,
} from 'src/designSystem/components/organisms/AddressForm/AddressForm'
import Log from 'src/lib/loggingUtil'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PopPage} from 'src/navigation/NavHelper'
import {useUpdateUserAddress} from 'src/products/MCU/Address/address.utils'
import Page from 'src/designSystem/components/organisms/Page/Page'

type Props = StackScreenProps<MainStackParamList, 'AddressUpdate'> & {
  address?: Consumer.types.Address
  onComplete?: () => void
}

const AddressUpdate: FC<Props> = (props) => {
  const {address, onComplete, route, navigation} = props
  const {t} = useTranslation(['PersonalInformation', 'Common'])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const addressFromProps = address ?? route?.params?.address

  const {data: meData, loading: isMeDataLoading} = Consumer.hooks.useMeAddressQuery()
  const existingAddress: Consumer.types.Address = meData?.me.profile?.home?.address ?? {
    __typename: 'Address',
    verified: false,
  }

  const [updateUserAddress, {loading: isUpdateUserAddressLoading}] = useUpdateUserAddress()

  const defaultAddress = addressFromProps ?? existingAddress
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {control, handleSubmit, errors, watch} =
    useForm<Consumer.types.ApplicantPhysicalAddressComponentsInput>({
      mode: 'all',
      defaultValues: Consumer.adapters.AddressToAddressComponents(defaultAddress),
    })

  const complete = (addressToConfirm: Consumer.types.Address): void => {
    PopPage(navigation)
    if (route.params?.onComplete) {
      route.params.onComplete(addressToConfirm)
    } else {
      onComplete?.()
    }
  }

  const onSubmit = async (
    data: Consumer.types.ApplicantPhysicalAddressComponentsInput,
  ): Promise<void> => {
    setIsSubmitting(true)

    try {
      data.country = 'US'

      const resAddress = await updateUserAddress(data)
      complete(resAddress)
    } catch (e) {
      Log.error(e, 'AddressUpdate, onSubmit: ')
    } finally {
      setIsSubmitting(false)
    }
  }

  const isDisabled =
    getHasErrorsOrMissingValues(errors, watch, RequiredAddressFields) ||
    isSubmitting ||
    isUpdateUserAddressLoading

  const isLoading = isSubmitting || isUpdateUserAddressLoading

  const primaryAction = {
    text: t('SaveAddress'),
    onPress: handleSubmit(onSubmit),
    disabled: isDisabled,
    loading: isLoading,
    testID: 'Save-Address-CTA',
  }

  if (isMeDataLoading) {
    return <Loading type="loader0" size="large" />
  }

  return (
    <Page
      title={route.params.title}
      buttonProps={buttonLockupProperties(primaryAction)}
      variant={'generic'}
      smallTopGap={true}
      testID="Address-Update-Screen"
    >
      <AddressForm control={control} errors={errors} />
    </Page>
  )
}

export {AddressUpdate}
