import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import {DocumentNode, GraphQLFormattedError} from 'graphql'
import {useEffect, useState} from 'react'

import {GraphqlURIMap} from './env/ApiUris'
import {Applications, EnvironmentType} from './types'
import {integrateChaosModeForApolloHttpLink} from '@possible/chaos'

export type LogCallback = {
  log: (...args) => void
  warn: (...args) => void
  error: (...args) => void
}

/**
 * Operations to run against birdsong instead of the normal environment GQL server.
 * Use the operation name, not the query/mutation name.
 * @example (operation name) 'GetOnboardingCurrentModule' NOT (query/mutation name) 'getOnboardingCurrentModule'.
 * @ THIS ARRAY SHOULD BE EMPTY ON MERGE.
 */
const birdsongOperations = [
  // Uncomment the following to use birdsong for MPO requests:
  // 'GetOnboardingCurrentModule',
  // 'OnboardingMoveToNextModule',
  // 'OnboardingMoveToPreviousModule',
]

export type ApolloClientType = ApolloClient<any>

let client: ApolloClientType | undefined = undefined
let publicClient: ApolloClientType | undefined = undefined
let publicLocalClient: ApolloClientType | undefined = undefined
let localClient: ApolloClientType | undefined = undefined
let application: Applications | undefined = undefined
let environment: EnvironmentType | undefined = undefined
let logging: LogCallback | undefined = undefined

function logGraphQLErrors(graphQLErrors: readonly GraphQLFormattedError[], operationName: string) {
  const logger = GetLogging()?.warn
  graphQLErrors.forEach((error) => {
    const {message, locations, path} = error
    logger?.(
      `[Cassandra -- ${operationName}]: Message: "${message}"; Path: "${path}"; Locations: [${locations?.map(
        (l) => `Line: ${l.line}, column: ${l.column}, `,
      )}]`,
    )
  })
}

const logNetworkError = (
  networkError: Error | ServerError | ServerParseError,
  operationName: string,
) => {
  // We get here and will get here repeatly if the device loses internet connection.
  // We do not need to log this connection issue
  const noInternetConnection =
    networkError.name === 'TypeError' && networkError.message === 'Network request failed'
  if (!noInternetConnection) {
    const serverError = networkError as ServerError
    // We do not treat 403 Forbidden as an error because it happens in our
    // normal operation when a token is expired
    const is403 = serverError.statusCode === 403
    const log = is403 ? GetLogging()?.log : GetLogging()?.error
    log?.(`[Cassandra -- (${operationName}) Network Error]: ${networkError}`)
  }
}

export const defaultApolloClientOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const fetchMethod: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response> = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  if (GetEnvironment() !== EnvironmentType.Prod && GetApplication() === Applications.MOBILE) {
    // when in preprod we integrate Chaos Mode (the @possible/chaos package) into the link chain so that
    // it can intercept and override http requests/responses if Chaos Mode is configured to simulate
    // failure for this request
    return integrateChaosModeForApolloHttpLink(fetch, input, init)
  } else {
    return fetch(input, init)
  }
}
/**
 * This must be called before any graphql queries or mutations are made.
 * Do not cache the returned object. Use GetClient to get the current client instance.
 * @param app The intended use of the application. Mobile (Consumer) or IAM (Administration)
 * @param env The environment to use. Local/Dev/Staging/Prod
 * @param token The authorization token with which to authenticate
 * @returns A client that can be used to make queries and mutations. Do not cache.
 */
export const CreateApolloClient = (
  app: Applications,
  env: EnvironmentType,
  token?: string,
  logCallback?: LogCallback,
): ApolloClientType => {
  logging = logCallback

  const httpLink = new HttpLink({
    uri: GraphqlURIMap[app][env].graphqlUri,
    fetch: fetchMethod,
  })

  const localLink = new HttpLink({
    uri: GraphqlURIMap[app][EnvironmentType.Local].graphqlUri,
    fetch: fetchMethod,
  })

  const publicHttpLink = new HttpLink({
    uri: GraphqlURIMap[app][env].graphqlPublicUri,
    fetch: fetchMethod,
  })

  const publicLocalLink = new HttpLink({
    uri: GraphqlURIMap[app][EnvironmentType.Local].graphqlPublicUri,
    fetch: fetchMethod,
  })

  const errorLink = onError((errorHandler) => {
    const {graphQLErrors, networkError, operation} = errorHandler
    const operationName = operation.operationName ?? '<Unknown Operation>'

    if (graphQLErrors) {
      logGraphQLErrors(graphQLErrors, operationName)
    }

    if (networkError) {
      logNetworkError(networkError, operationName)
    }
  })

  const authLink = token
    ? setContext((_, {headers}) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        }
      })
    : undefined

  const cache = new InMemoryCache({
    typePolicies: {
      InstallmentPayment: {
        keyFields: ['id', 'ordinal', 'executeAt'],
      },
      AutomaticPaymentSchedule: {
        keyFields: ['id', 'paymentDate'],
      },
    },
    possibleTypes: {
      CardAccountStatuses: [
        'ActiveCardAccountStatus',
        'PendingCardAccountStatus',
        'RejectedCardAccountStatus',
        'ApprovedCardAccountStatus',
        'CancelledCardAccountStatus',
        'ExpiredCardAccountStatus',
        'DeactivatedCardAccountStatus',
      ],
    },
  })

  let splitLink: ApolloLink | undefined

  const links: ApolloLink[] = []
  if (splitLink) {
    links.push(errorLink)
    links.push(splitLink)
  } else {
    if (authLink) {
      links.push(authLink)
    }
    links.push(errorLink)
    links.push(httpLink)
  }

  client = new ApolloClient({
    defaultOptions: defaultApolloClientOptions,
    cache,
    link: ApolloLink.from(links),
    // Adds support for Apollo Client Dev Tools
    devtools: {
      enabled: env !== EnvironmentType.Prod,
    },
  })

  publicClient = new ApolloClient({
    defaultOptions: defaultApolloClientOptions,
    cache,
    link: ApolloLink.from([errorLink, publicHttpLink]),
  })

  //these localClients can be used for testing agains a local client (birdsong)
  publicLocalClient = new ApolloClient({
    defaultOptions: defaultApolloClientOptions,
    cache,
    link: ApolloLink.from([errorLink, publicLocalLink]),
  })

  localClient = new ApolloClient({
    defaultOptions: defaultApolloClientOptions,
    cache,
    link: authLink
      ? ApolloLink.from([authLink, errorLink, localLink])
      : ApolloLink.from([errorLink, localLink]),
  })

  SetClient(client)
  SetLocalClient(localClient)
  application = app
  environment = env

  return client
}

export const DestroyClient = () => {
  if (client) {
    client.stop()
    client = undefined
    SetClient(undefined)
  }

  if (localClient) {
    localClient.stop()
    localClient = undefined
  }
}

/**
 * Get the current apollo client used to make queries and mutations.
 * @param forDocument Pass a DocumentNode here if you would like to configure it to
 * be run locally -- against birdsong instead of the normal graphql environment.
 * See birdsongOperations.
 * @returns The current apollo client. Do not cache.
 */
export const GetClient = (forDocument?: DocumentNode): ApolloClientType => {
  if (!client) {
    throw new Error('No Apollo client has been created!')
  }

  if (birdsongOperations.length > 0 && forDocument) {
    if (forDocument.definitions[0].kind === 'OperationDefinition') {
      if (forDocument.definitions[0].name?.kind === 'Name') {
        const name = forDocument.definitions[0].name?.value
        // @ts-ignore
        if (name && birdsongOperations.includes(name)) {
          console.log(`Using birdsong for operation: ${name}`)
          return GetLocalClient()
        }
      }
    }
  }

  return client
}

export const GetPublicClient = (forDocument?: DocumentNode): ApolloClientType => {
  if (!publicClient) {
    throw new Error('No Apollo client has been created!')
  }

  if (birdsongOperations.length > 0 && forDocument) {
    if (forDocument.definitions[0].kind === 'OperationDefinition') {
      if (forDocument.definitions[0].name?.kind === 'Name') {
        const name = forDocument.definitions[0].name?.value
        // @ts-ignore
        if (name && birdsongOperations.includes(name)) {
          console.log(`Using birdsong for operation: ${name}`)
          return GetPublicLocalClient()
        }
      }
    }
  }

  return publicClient
}

const GetLocalClient = (): ApolloClientType => {
  if (!localClient) {
    throw new Error('No Local client has been created!')
  }

  return localClient
}

const GetPublicLocalClient = (): ApolloClientType => {
  if (!publicLocalClient) {
    throw new Error('No Local client has been created!')
  }

  return publicLocalClient
}

export const GetApplication = (): Applications | undefined => {
  return application
}

export const GetEnvironment = (): EnvironmentType | undefined => {
  return environment
}

export const GetLogging = (): LogCallback | undefined => {
  return logging
}

let observers: React.Dispatch<React.SetStateAction<ApolloClientType | undefined>>[] = []

export const SetClient = (newClient: ApolloClientType | undefined) => {
  if (client && GetApplication() === Applications.MOBILE) {
    client.stop()
    client.resetStore()
    client = undefined
  }

  client = newClient
  observers.forEach((update) => update(client))
}

export const SetPublicClient = (newClient: ApolloClientType | undefined) => {
  publicClient = newClient
}

export const SetLocalClient = (newClient: ApolloClientType | undefined) => {
  localClient = newClient
}

const SubscribeToClient = (subscriber) => {
  observers.push(subscriber)
}

const UnsubscribeToClient = (unsubscriber) => {
  observers = observers.filter((observer) => observer !== unsubscriber)
}

export type CassandraClientOptions = {
  isPublic?: boolean
  withBirdsong?: boolean
}

export const useCassandraClient = (
  options: CassandraClientOptions = {},
): [ApolloClientType | undefined, (newClient: ApolloClientType | undefined) => void] => {
  const {isPublic = false, withBirdsong = false} = options

  const [hookClient, setHookClient] = useState<ApolloClientType | undefined>(() => {
    let initialClient = client
    if (isPublic) {
      initialClient = publicClient
    } else if (withBirdsong) {
      initialClient = GetLocalClient()
    }
    return initialClient
  })

  useEffect(() => {
    if (isPublic || withBirdsong) {
      return
    }

    SubscribeToClient(setHookClient)

    setHookClient(client)

    return () => {
      UnsubscribeToClient(setHookClient)
    }
  }, [isPublic, withBirdsong])

  return [hookClient, SetClient]
}
