import React, {FC, useState, useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'

import {BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {CardApprovedTile} from 'src/products/card/LoanDash/CardApprovedTile'
import {CardActivatedTile} from 'src/products/card/LoanDash/CardActivatedTile'
import {ActivatePhysicalCardTile} from 'src/products/card/LoanDash/ActivatePhysicalCardTile'
import {CardApplyTile} from 'src/products/card/LoanDash/CardApplyTile'
import {CardPendingTile} from 'src/products/card/LoanDash/CardPendingTile'
import {QueryStatus, useUser} from 'src/cassandra'
import {CardRejectedTile} from 'src/products/card/LoanDash/CardRejectedTile'
import {CardErrorTile} from 'src/products/card/LoanDash/CardErrorTile'
import {getSelectedCardOffer} from 'src/lib/card/selectors'
import {CardWaitListTile} from 'src/products/card/LoanDash/CardWaitListTile'
import {CardDashTileStatus} from 'src/products/card/LoanDash/utils'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {userEmailSelector} from 'src/lib/user/selector'
import {CardReapplyTile} from 'src/products/card/LoanDash/CardReapplyTile'
import {CardExpiredTile} from 'src/products/card/LoanDash/CardExpiredTile'
import {useCardLoanDashboardTile} from 'src/products/card/hooks/useCardLoanDashboardTile'
import {CardDeactivatedTile} from 'src/products/card/LoanDash/CardDeactivatedTile'

export const CardLoanDashboardTile: FC<
  Pick<BaseCardProps, 'navigation' | 'tileMarginHorizontal' | 'tileRadius'>
> = ({navigation, tileMarginHorizontal, tileRadius}) => {
  const [loading, setLoading] = useState(true)
  const {canAccessCards} = useCanAccessCards()
  const {queryStatus} = useUser(false, canAccessCards)
  const email = useSelector(userEmailSelector)
  const creditLimit = (useSelector(getSelectedCardOffer)?.balanceLimit as string) + ''

  useEffect(() => {
    if (queryStatus === QueryStatus.Fulfilled || queryStatus === QueryStatus.Rejected) {
      setLoading(false)
    }
  }, [queryStatus])

  const {
    cardOfferStatus,
    shouldShowCardLoanDashboardTile,
    isLoading: isLoadingCardTile,
  } = useCardLoanDashboardTile()

  return useMemo(() => {
    const baseProps: BaseCardProps = {
      navigation,
      loading,
      tileMarginHorizontal,
      tileRadius,
    }
    if (!shouldShowCardLoanDashboardTile || isLoadingCardTile) {
      return null
    } else {
      if (cardOfferStatus === CardDashTileStatus.ActivatePhysicalCard) {
        return <ActivatePhysicalCardTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Active) {
        return <CardActivatedTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Approved) {
        return <CardApprovedTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Pending) {
        return <CardPendingTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Apply) {
        return <CardApplyTile {...baseProps} creditLimit={creditLimit} />
      } else if (cardOfferStatus === CardDashTileStatus.WaitList) {
        return <CardWaitListTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Rejected) {
        return <CardRejectedTile {...baseProps} email={email ?? ''} />
      } else if (cardOfferStatus === CardDashTileStatus.Reapply) {
        return <CardReapplyTile {...baseProps} creditLimit={creditLimit} />
      } else if (cardOfferStatus === CardDashTileStatus.Expired) {
        return <CardExpiredTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Deactivated) {
        return <CardDeactivatedTile {...baseProps} />
      } else if (cardOfferStatus === CardDashTileStatus.Error) {
        return <CardErrorTile {...baseProps} />
      } else {
        // note: this shouldn't happen due to shouldShowCardLoanDashboardTile but
        // typescript compiler wants it to adhere to React.FC type
        return null
      }
    }
  }, [
    isLoadingCardTile,
    navigation,
    loading,
    cardOfferStatus,
    creditLimit,
    email,
    tileMarginHorizontal,
    tileRadius,
    shouldShowCardLoanDashboardTile,
  ])
}
