import {useSelector} from 'react-redux'
import {useNavigation} from '@react-navigation/native'

import {getActiveAccountId} from 'src/lib/card/selectors'
import {CardAgreementType, Consumer, getAgreements, CardAccountAgreement} from 'src/cassandra'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {logErrorAndShowException} from 'src/lib/errors'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'

/**
 * Hook to help with displaying the pdf's of the cards agreement docs
 */
export const useAgreementDocPdf = () => {
  const navigation: MainStackNavigationProp = useNavigation()
  const cardAccountId = useSelector(getActiveAccountId) ?? ''
  const agreements: CardAccountAgreement[] = useSelector(getAgreements)

  const [getAgreementDocDownloadLink] =
    Consumer.hooks.useCardAccountAgreementsDownloadLinkMutation()

  const getAgreementUri = async (type: CardAgreementType): Promise<string | undefined> => {
    if (!cardAccountId) {
      void logErrorAndShowException(new Error('Missing cardAccountId'))
      return undefined
    }

    const cardAccountAgreementId = agreements?.map((agreement) => agreement?.id)

    const accountAgreementsDownload = await getAgreementDocDownloadLink({
      variables: {cardAccountId, cardAccountAgreementId},
    })

    return accountAgreementsDownload?.data?.cardAccountAgreementsDownload?.find(
      (agreementDownload) => agreementDownload?.agreement?.type === type,
    )?.download?.url
  }

  const displayAgreementPdf = async (type: CardAgreementType): Promise<void> => {
    const uri: string | undefined = await getAgreementUri(type)
    if (uri === undefined) {
      void logErrorAndShowException(new Error('Unable to display document'))
      return
    }
    displayPdf(uri, navigation)
  }

  return {
    displayAgreementPdf,
  }
}
