import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {ScrollView, View} from 'react-native'
import {ConnectedProps, connect} from 'react-redux'
import {NavigationState} from '@react-navigation/native'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import Log from 'src/lib/loggingUtil'
import {usernameSelector} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleLegalURI, possibleSupportURI} from 'src/navigation/WebLinks'
import MenuHeader from 'src/products/general/LeftDrawer/MenuHeader'
import {MenuItem} from 'src/products/general/LeftDrawer/MenuItem'
import VersionFooter from 'src/products/general/VersionFooter/VersionFooter'
import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch} from 'src/store/utils'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {ContextualizedLogException} from 'src/lib/errors'
import AppNavActions from 'src/nav/AppNavActions'
import {ampli} from 'src/lib/Analytics/ampli'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'

// NOTE: The ACTUAL type that should be used here is <RootStackParamList, 'LeftDrawer'>,
// but all of our 'helper' functions assume MainStackParamList, this is fine as
// react navigation is smart enough to send pushes and navigates up the tree,
// but it does make UNDERSTANDING and TYPING how all this works so much harder.
type Props = StackScreenProps<MainStackParamList, 'LeftDrawer'> &
  PropsFromRedux & {
    userInfo: {
      firstName: string
    }
    state: NavigationState<MainStackParamList>
  }

type Nav = StackNavigationProp<MainStackParamList, 'LeftDrawer'>

const goToPage = (navigation: Nav, page: keyof MainStackParamList): void => {
  Log.log(`${page} selected`)
  // @ts-expect-error Not sure, but upgrading react-native so I'm going to ignore this for now
  navigation?.navigate(page)
}

const LeftDrawer: FC<Props> = ({userInfo, state: navState, navigation}) => {
  const routes = navState?.routes
  const appRoute = routes?.[routes.length - 1]
  const mainRoute = appRoute?.state?.routes[appRoute?.state?.routes.length - 1]
  let currentScreen: keyof MainStackParamList | undefined = undefined
  if (mainRoute?.state?.index !== undefined) {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    currentScreen = mainRoute?.state?.routes[mainRoute.state.index]
      ?.name as keyof MainStackParamList
  }
  const dispatch = usePfDispatch()

  const isInWaitList = currentScreen === 'UnsupportedStateWaitList'
  const shouldShowDashboardLink = currentScreen === 'AccountManagementV2'

  const handleOnPressMyDashboard = (): void => {
    AppNavActions.closeDrawer()
    AppNavActions.resetMainNavigation([{routeName: 'Init', params: {}}])
  }

  const handleOnPressProfile = (): void => goToPage(navigation, 'Profile')
  const handleOnPressAccountManagement = (): void => {
    goToPage(navigation, 'AccountManagementV2')
  }
  const handleOnPressHelpCenter = (): void => {
    sendAnalyticEvent(ampli.helpCenterSelected.bind(ampli))
    PushWebPageOnStack(navigation, {uri: possibleSupportURI})
  }
  const handleOnPressContactUs = (): void => {
    TrackAppEvent(AppEvents.Name.lefthand_drawer_contact_us_selected, AppEvents.Category.Admin)
    openContactUsForm(navigation)
  }
  const handleOnPressHistory = (): void => {
    TrackAppEvent(AppEvents.Name.lefthand_drawer_loan_history_selected, AppEvents.Category.Admin)
    goToPage(navigation, 'DocumentsHistory')
  }
  const handleOnPressLegal = (): void => {
    TrackAppEvent(AppEvents.Name.lefthand_drawer_legal_selected, AppEvents.Category.Admin)
    PushWebPageOnStack(navigation, {uri: possibleLegalURI()})
  }
  const handleOnPressLogout = (): void => {
    AppNavActions.closeDrawer()
    dispatch(Logout(false)).catch(
      ContextualizedLogException('HeaderLogoutButton: Failed to log out'),
    )
  }

  const {t} = useTranslation(['LeftDrawer', 'Common'])
  return (
    <ScrollView>
      <View>
        <MenuHeader name={userInfo?.firstName} />
        {shouldShowDashboardLink ? (
          <MenuItem
            text={t('MyDashboard')}
            onPress={handleOnPressMyDashboard}
            testID="My-Dashboard-Menu"
          />
        ) : null}
        <MenuItem text={t('Profile')} onPress={handleOnPressProfile} testID="Profile-Menu" />
        {isInWaitList ? null : (
          <MenuItem
            text={t('AccountManagement')}
            onPress={handleOnPressAccountManagement}
            testID="Account-Management-Menu"
          />
        )}
        <MenuItem
          text={t('HelpCenter')}
          onPress={handleOnPressHelpCenter}
          testID="Help-Center-Menu"
        />
        <MenuItem
          text={t('Common:ContactUs')}
          onPress={handleOnPressContactUs}
          testID="Contact-Us-Menu"
        />
        <MenuItem
          text={t('HistoryStatementsAndDocuments')}
          onPress={handleOnPressHistory}
          testID="History-Statements-And-Documents-Menu"
        />
        <MenuItem text={t('Legal')} onPress={handleOnPressLegal} testID="Legal-Menu" />
        <MenuItem text={t('Common:Logout')} onPress={handleOnPressLogout} testID="Logout-Menu" />
      </View>
      <View>
        <VersionFooter />
      </View>
    </ScrollView>
  )
}

const mapStateToProps = (state: PfReduxState): unknown => {
  return {
    userInfo: usernameSelector(state),
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(LeftDrawer)
