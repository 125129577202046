/* eslint-disable @typescript-eslint/unbound-method */
import React, {useState, FC} from 'react'
import {useForm} from 'react-hook-form'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import startsWith from 'lodash/startsWith'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import PFPasswordInput from 'src/designSystem/components/molecules/PFPasswordInput/PFPasswordInput'
import HookForm, {FIELD_VARIANTS} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ShowException} from 'src/lib/errors'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {usePfDispatch} from 'src/store/utils'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {UserModifyPasswordMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {ModifyPasswordInput} from '@possible/cassandra/src/types/types.mobile.generated'
import {GraphQLError} from 'graphql/error/GraphQLError'

type FormData = {
  currentPassword: string
  newPassword: string
}

type Props = StackScreenProps<MainStackParamList, 'EditPassword'>

const EditPassword: FC<Props> = ({navigation}: Props) => {
  const {t} = useTranslation(['LoginTransition', 'Common'])
  const [requirementsMet, setRequirementsMet] = useState(false)
  const [busy, setBusy] = useState(false)
  const {control, handleSubmit, errors, watch, trigger} = useForm<FormData>({mode: 'onChange'})
  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.edit_password_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const onSubmit = async (data: FormData): Promise<void> => {
    setBusy(true)
    try {
      const input: ModifyPasswordInput = {
        previousPassword: data?.currentPassword,
        proposedPassword: data?.newPassword,
      }
      const res: boolean | undefined = await UserModifyPasswordMutation(input)

      if (res) {
        TrackAppEvent(AppEvents.Name.edit_password_submitted, AppEvents.Category.Admin)
        navigation?.pop?.()
      }
    } catch (e: any) {
      if (
        typeof e?.message === 'string' &&
        startsWith((e as GraphQLError).message, t('TooManyAttempts'))
      ) {
        await dispatch(Logout(false))
      }
      ShowException(e)
    }
    setBusy(false)
  }

  const getDisabledAction = (): boolean => {
    return !requirementsMet || busy || getHasErrorsOrMissingValues(errors, watch, 'currentPassword')
  }

  const action = {
    text: t('Common:Submit'),
    onPress: handleSubmit(onSubmit),
    disabled: getDisabledAction(),
  }

  const formProps = {
    currentPassword: {
      name: 'currentPassword',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t('CurrentPasswordRequired'),
        minLength: {value: 8, message: t('CurrentPasswordMinimum')},
      },
      onChange: trigger('newPassword'),
    },
    newPassword: {
      name: 'newPassword',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        validate: (value) =>
          value === watch('currentPassword') ? t('PleaseUseNewPassword') : undefined,
      },
    },
  }

  const getContent = (): React.ReactNode => {
    return (
      <HookForm control={control} errors={errors}>
        <PFPasswordInput
          label={t('CurrentPassword')}
          formProps={formProps.currentPassword}
          testID="current_password"
        />
        <PFPasswordInput
          label={t('Common:NewPassword')}
          showRequirements={true}
          onAllRequirementsMet={setRequirementsMet}
          formProps={formProps.newPassword}
          testID="new_password"
        />
      </HookForm>
    )
  }

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(action)}
      title={t('EditPasswordTitle')}
    >
      {getContent()}
    </GenericNonModalTemplate>
  )
}

export default EditPassword
