import React from 'react'
import Log from 'src/lib/loggingUtil'
import {getPfStore} from 'src/store'
import {NavigationContainerRef} from '@react-navigation/core'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

let idForBackButtonWorkAround = 1

export function rootStackNavContainerOnReady(
  rootNavigationRef: React.RefObject<NavigationContainerRef<MainStackParamList>>,
) {
  //To prevent a feedback loop from going backwards, we keep track of ignorePopCount
  let ignorePopCount = 0
  window.addEventListener(
    'popstate',
    function () {
      if (ignorePopCount > 0) {
        ignorePopCount--
      } else {
        rootNavigationRef.current?.goBack()
      }
    },
    false,
  )

  Log.log('READY!: ', rootNavigationRef.current?.getRootState())
  getPfStore().dispatch({type: 'NAVIGATOR_READY', ready: true})

  //We could use window.history.length, but it seems to be inaccurate. Which causes us to not
  //proper pop enough elements off the history when there is a POP_TO_TOP or RESET
  let currentHistory = window['historyOriginal']
  const cb = (event) => {
    switch (event.data?.action?.type) {
      case 'GO_BACK': {
        currentHistory--
        break
      }
      case 'POP_TO_TOP': {
        const history = window.history

        //We need to go back to a place in history depending on how many stacks we are pulling out
        const adjustBy = currentHistory - window['historyOriginal']
        if (adjustBy > 0) {
          currentHistory -= adjustBy
          ignorePopCount++
          history.go(adjustBy * -1)
        }
        break
      }
      case 'RESET': {
        const history = window.history

        // On resetting we have to considerate if others routes have been added to the stack
        const adjustedHistory = currentHistory - window['historyOriginal']
        const adjustBy = event.data.action.payload.routes.length - 1 - adjustedHistory
        currentHistory += adjustBy
        if (adjustBy < 0) {
          ignorePopCount++
          history.go(adjustBy)
        } else if (adjustBy > 0) {
          for (let i = 0; i < adjustBy; i++) {
            const id = idForBackButtonWorkAround++
            history?.pushState({id: id.toString()}, '')
          }
        }
        break
      }
      case 'NAVIGATE':
      case 'PUSH': {
        const history = window.history
        const id = idForBackButtonWorkAround++
        history?.pushState({id: id.toString()}, '')
        currentHistory++
        break
      }
    }
  }
  rootNavigationRef.current?.addListener('__unsafe_action__', cb)
}
