import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import AppEvents from 'src/lib/Analytics/app_events'
import {ModuleControlProps} from 'src/flows/types'
import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {EmailVerificationRESTContainer} from 'src/products/loans/EmailVerification/EmailVerificationRESTContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {
  trackEditEmail,
  trackResendCode as handleOnTrackResendCode,
  trackSubmitCode,
} from 'src/products/loans/EmailVerification/EmailVerification.utils'

type EmailVerificationMPOContainerProps = Required<
  Pick<ModuleControlProps, 'onModuleComplete'> & Pick<ModuleControlProps, 'moduleName'>
> &
  StackScreenProps<MainStackParamList, 'EmailVerification'>

export const EmailVerificationMPOContainer: FC<EmailVerificationMPOContainerProps> = ({
  onModuleComplete,
  navigation,
  moduleName,
}) => {
  useMPOAndroidToWeb(moduleName)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_email_verification_code_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
  })

  const handleOnEditEmail = (): void => {
    trackEditEmail()
    navigation.navigate('EditEmail')
  }

  const handleOnCodeSubmitted = async (): Promise<void> => {
    trackSubmitCode()
    await onModuleComplete()
  }

  const handleOnContinueAfterIsVerified = async (): Promise<void> => {
    await onModuleComplete()
  }

  return (
    <EmailVerificationRESTContainer
      onEditEmail={handleOnEditEmail}
      onResendCodeTracking={handleOnTrackResendCode}
      onCodeSubmitted={handleOnCodeSubmitted}
      onContinueAfterIsVerified={handleOnContinueAfterIsVerified}
    />
  )
}
