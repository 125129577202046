import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivationAutopayScheduleReduxContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleReduxContainer'

export const CardActivationAutopayScheduleMPOContainer: FC<
  StackScreenProps<MainStackParamList, 'CardActivationAutopaySchedule'> & ModuleControlProps
> = ({onStepComplete, navigation, route}) => {
  const isRoutedFromDashboard = route?.params?.isRoutedFromDashboard

  usePageViewedAnalytics({
    // Send the 'card_autopay_settings_page_viewed' event when this paged is accessed via 'Autopay Settings' on the dash
    eventName: isRoutedFromDashboard
      ? CardEvents.card_autopay_settings_page_viewed
      : CardEvents.card_autopay_schedule_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const handleOnComplete = async (): Promise<void> => {
    TrackAppEvent(CardEvents.enable_auto_pay_action, AppEvents.Category.Card, {
      source: 'ActivationFlow',
    })

    await onStepComplete?.()
  }

  return (
    <CardActivationAutopayScheduleReduxContainer
      onComplete={handleOnComplete}
      onPressSecondary={handleOnComplete}
      /* This is likely be removed in ENG-16749
       *
       * https://possible.atlassian.net/browse/ENG-16749
       */
      navigation={navigation}
      isRoutedFromDashboard={isRoutedFromDashboard}
    />
  )
}
