import {Variant} from '@amplitude/experiment-react-native-client'

const PrequalNoData = 'prequal-no-data'

export const ExperimentNames = [
  'android-skip-app-landing',
  'app-revamp-onboarding',
  'braze-content-cards',
  'card-credit-reporting-ui-updates',
  'card-onboarding-experiment-1-mock-bureau-uw',
  'loans-activation-workflow',
  'loans-repeat-loan-workflow',
  'loan-amount-selection-v2',
  'loan-application-handoff-to-web-on-android',
  'nevada-loans',
  'payments-phase-2',
  'plaid-layer-for-web',
  'plaid-layers',
  'plaid-ownership-validation',
  'prequal-no-data',
  'prequalification',
  'repeat-loan-workflow',
  'secure-message-center',
  'storybook',
  'use-plaid-refactor-and-oauth-redirect',
] as const

export type ExperimentNamesType = (typeof ExperimentNames)[number]

type ExperimentDefaultsType = {
  [Key in ExperimentNamesType]: ExperimentValue
}
export const ExperimentDefaults: ExperimentDefaultsType = {
  'android-skip-app-landing': 'control',
  'app-revamp-onboarding': 'control',
  'braze-content-cards': 'control',
  'card-credit-reporting-ui-updates': 'control',
  'card-onboarding-experiment-1-mock-bureau-uw': 'control',
  'loans-activation-workflow': 'control',
  'loans-repeat-loan-workflow': 'control',
  'loan-amount-selection-v2': 'control',
  'loan-application-handoff-to-web-on-android': 'off',
  'nevada-loans': 'control',
  'payments-phase-2': 'control',
  'plaid-layer-for-web': 'control',
  'plaid-layers': 'control',
  'plaid-ownership-validation': 'control',
  'prequal-no-data': 'control',
  'prequalification': 'control',
  'repeat-loan-workflow': 'control',
  'secure-message-center': 'control',
  'storybook': 'control',
  'use-plaid-refactor-and-oauth-redirect': 'off',
} as const

export const DefaultVariant: Variant = {
  value: 'control',
}

// All experiment variant value types
const ExperimentVariants = {
  prequalification: ['prequalificationEnabled', 'prequalificationDisabled'] as const,
  [PrequalNoData]: ['prequalNoData'] as const,
}

export const ExperimentValues = [
  'control',
  'treatment',
  'on',
  'off',
  ...ExperimentVariants['prequalification'],
  ...ExperimentVariants[PrequalNoData],
] as const
export type ExperimentValue = (typeof ExperimentValues)[number]
