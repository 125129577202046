import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {ModuleControlProps, OnboardingParamList} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import LoanProductIntroductionTemplate from 'src/products/loans/LoanProductIntroduction/LoanProductIntroductionTemplate'

type LoanProductIntroductionMPOContainerProps = StackScreenProps<
  OnboardingParamList,
  'LOAN_SELECTED'
> &
  ModuleControlProps

/**
 * Container to use LoanProductIntroduction within an MPO flow.
 */
const LoanProductIntroductionMPOContainer: React.FC<LoanProductIntroductionMPOContainerProps> = (
  props,
) => {
  const {onModuleComplete} = props
  const handleOnContinue = async (): Promise<void> => {
    await onModuleComplete?.()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_product_intro_page_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  return <LoanProductIntroductionTemplate onContinue={handleOnContinue} />
}

export {LoanProductIntroductionMPOContainer}
