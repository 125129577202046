import {possiblePrivacyURI, possibleTOSURI} from 'src/navigation/WebLinks'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {getLoan} from 'src/api/actions/loans/loanActions'

export const pageTitle = 'Accept agreements'
export const agreeAndContinueText = 'Agree and continue'

export const CREDIT_SERVICES_DISCLOSURE_STATEMENT = 'credit_services_disclosure_statement'
export const CREDIT_SERVICES_AGREEMENT = 'credit_services_agreement'
export const ARBITRATION_AGREEMENT = 'abritration_agreement'
export const LOAN_AGREEMENT = 'loan_agreement'
export const STATUTORY_NOTICE = 'statutory_notice'

export function onPossibleTOS(): void {
  TrackAppEvent(AppEvents.Name.accept_agreements_view_tos_selected, AppEvents.Category.Checkout)
  OpenWebPage({uri: possibleTOSURI()})
}

export function onPossiblePP(): void {
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_privacy_policy_selected,
    AppEvents.Category.Checkout,
  )
  OpenWebPage({uri: possiblePrivacyURI()})
}

export async function refreshEnv(params: {
  loanId: string
  setStateBusyTrue: () => void
  setStateBusyFalse: () => void
  userStateRefreshAction: () => Promise<boolean>
}): Promise<void> {
  const {loanId, setStateBusyTrue, setStateBusyFalse, userStateRefreshAction} = params
  try {
    setStateBusyTrue()

    await userStateRefreshAction()

    const response = await getLoan(loanId)
    if (response.getErrorStr()) {
      throw response
    }
  } catch (e) {
    Log.log(e)
  } finally {
    setStateBusyFalse()
  }
}
