import React, {useState} from 'react'

import {CollectDebitCardNumbersForLoanTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanTemplate'
import {DebitCardSubmitValues} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'
import {PaymentMethodsAlertModalErrorReason} from 'src/products/MCU/AccountManagementV2/PaymentMethodsAlertModals/PaymentMethodsAlertModals.types'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {formatDate, monthDate, yearMonth} from 'src/lib/utils/date'
import {
  CollectDebitCardNumbersForLoanConfirmCardDocument,
  CollectDebitCardNumbersForLoanDocument,
  CollectDebitCardNumbersForLoanSaveDisbursementMethodDocument,
  CollectDebitCardNumbersForLoanSubmitDocument,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoan.gqls'
import {
  AddPaymentMethodResultType,
  LoanPaymentMethod,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {usePromise} from 'src/lib/usePromise/usePromise'

export type CollectDebitCardNumbersForLoanGQLContainerProps = {
  associatedLinkedAccountId?: string
  onDebitCardSaved: () => void | Promise<void>
  onDebitCardSaveFailed?: (errorReason: PaymentMethodsAlertModalErrorReason) => void | Promise<void>
}
const CollectDebitCardNumbersForLoanGQLContainer: React.FC<
  CollectDebitCardNumbersForLoanGQLContainerProps
> = (props) => {
  const {associatedLinkedAccountId, onDebitCardSaved, onDebitCardSaveFailed} = props

  const [errorReasonForModal, setErrorReasonForModal] = useState<
    PaymentMethodsAlertModalErrorReason | undefined
  >()

  const {selectedData, error: queryError} = useCassandraQuery(
    CollectDebitCardNumbersForLoanDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (from) => {
      const primaryAccount = from.me.bankAccounts.all?.find(
        (account) => account.preferredFundingSource === true,
      )
      return {
        primaryAccount,
        loanId: from.me.loans.latestActionableLoan?.id,
      }
    },
  )
  const [submitAddDebitCard] = useCassandraMutation(CollectDebitCardNumbersForLoanSubmitDocument)

  const [setDisbursementMethod] = useCassandraMutation(
    CollectDebitCardNumbersForLoanSaveDisbursementMethodDocument,
  )

  const [submitConfirmDebitCard] = useCassandraMutation(
    CollectDebitCardNumbersForLoanConfirmCardDocument,
  )

  // if we were sent here to associate a debit card with a specific account we will use that,
  // otherwise we will associate this card w/their primary account
  const linkedAccountIdToAssociateWith =
    associatedLinkedAccountId ?? selectedData?.primaryAccount?.id

  const handleOnDismissErrorModal = (): void => {
    setErrorReasonForModal(undefined)
  }
  const [handleOnSubmit, {isLoading: isHandleOnSubmitExecuting}] = usePromise(
    async (data: DebitCardSubmitValues): Promise<void> => {
      const {number: maskedNumber, expiration, cvv} = data

      const unmaskedNumber: string | undefined = maskedNumber?.replace(/\s/g, '')
      const formattedExpiration = formatDate(expiration, yearMonth, monthDate)
      let hasError: boolean = false
      let errorType: AddPaymentMethodResultType | undefined

      const {data: submitAddDebitCardResponse, errors: submitAddDebitCardErrors} =
        await submitAddDebitCard({
          variables: {
            input: {
              cardExp: formattedExpiration,
              cardCvv: cvv,
              cardNumber: unmaskedNumber,
              linkedAccountId: linkedAccountIdToAssociateWith,
            },
          },
        })

      errorType = submitAddDebitCardResponse?.bankAddDebitPaymentMethod?.result.type
      hasError = !!(
        (submitAddDebitCardErrors || errorType) &&
        errorType !== AddPaymentMethodResultType.Successful
      )

      const loanId = selectedData?.loanId
      if (!hasError && loanId) {
        const paymentInstrumentId =
          submitAddDebitCardResponse?.bankAddDebitPaymentMethod?.paymentMethod?.id

        const {data: setDisbursementMethodResponse, errors: setDisbursementMethodErrors} =
          await setDisbursementMethod({
            variables: {
              input: {
                loanId,
                disbursementMethod: LoanPaymentMethod.DebitCard,
                paymentInstrumentId,
              },
            },
          })

        if (
          !setDisbursementMethodResponse?.loanSetDisbursementMethod.success ||
          setDisbursementMethodErrors
        ) {
          hasError = true
          errorType = undefined
        }
      }

      if (!hasError && loanId) {
        const submitConfirmDebitResponse = await submitConfirmDebitCard({
          variables: {
            loanId,
          },
        })

        if (
          submitConfirmDebitResponse?.errors ||
          !submitConfirmDebitResponse?.data?.loanActivationUpdateRequirements.success
        ) {
          hasError = true
          errorType = undefined
        }
      }

      if (hasError) {
        const errorReason = errorType ?? 'GENERIC_ERROR'
        setErrorReasonForModal(errorReason)

        await onDebitCardSaveFailed?.(errorReason)
      } else {
        await onDebitCardSaved()
      }
    },
  )
  return (
    <CollectDebitCardNumbersForLoanTemplate
      isSubmitting={isHandleOnSubmitExecuting}
      // if this is supposed to be associated w/their primary account and we failed to load
      // their primary we show an error
      showFailedToLoadError={!!(!associatedLinkedAccountId && queryError)}
      errorReason={errorReasonForModal}
      onDismissErrorModal={handleOnDismissErrorModal}
      onSubmit={async (data: DebitCardSubmitValues): Promise<void> => {
        await handleOnSubmit(data)
      }}
    />
  )
}

export {CollectDebitCardNumbersForLoanGQLContainer}
