import React, {FC} from 'react'
import {View} from 'react-native-animatable'
import {StyleSheet} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  californiaSingleAPR,
  californiaInstallmentAPR,
} from 'src/products/loans/LoanSelection/FeeCalc'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {primaryBrandColor} from 'src/designSystem/semanticColors'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const CaliforniaLoanInfo: FC<Props> = ({navigation}) => {
  const {t} = useTranslation(['LoanSelection', 'Common'])

  const onOk = () => {
    navigation?.pop()
  }
  const primary = {
    text: t('Common:Dismiss'),
    onPress: () => {
      onOk()
    },
  }
  return (
    <LightboxNotice title={t('MainTitle')} primary_action={primary}>
      <View>
        <PFText variant={'p'}>{t('CAMainDescription')}</PFText>
      </View>
      <View style={styles.box_view}>
        <View style={styles.feeDetailHeader}>
          <View style={styles.leftColumn} />
          <View style={styles.middleColumn}>
            <PFText variant={'p_sm_semibold'}>{t('TimeToRepay')}</PFText>
          </View>
          <View style={{flex: 1}}>
            <View style={styles.aprStyle}>
              <PFText variant={'p_sm_semibold'}>{t('APR')}</PFText>
            </View>
          </View>
        </View>
        <View style={styles.tableView}>
          <View style={styles.leftColumn}>
            <PFText variant={'p_sm'}>{t('SinglePaymentLoan')}</PFText>
          </View>
          <View style={styles.middleColumn}>
            <PFText variant={'p_sm'}>{t('Common:NumDays', {count: 14})}</PFText>
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.aprStyle}>
              <PFText variant={'p_sm'}>{californiaSingleAPR}%</PFText>
            </View>
          </View>
        </View>
        <View style={{flexDirection: 'row'}}>
          <View style={styles.leftColumn}>
            <PFText variant={'p_sm_semibold'}>{t('PossibleInstallment')}</PFText>
          </View>
          <View style={styles.middleColumn}>
            <PFText variant={'p_sm_semibold'}>{t('Common:NumWeeks', {count: 8})}</PFText>
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.aprStyle}>
              <PFText variant={'p_sm_semibold'}>{californiaInstallmentAPR}%</PFText>
            </View>
          </View>
        </View>
      </View>
    </LightboxNotice>
  )
}
export default CaliforniaLoanInfo

const styles = StyleSheet.create({
  aprStyle: {
    alignSelf: 'flex-end',
  },
  box_view: {
    display: 'flex',
    paddingVertical: smallGap,
    width: '100%',
  },
  feeDetailHeader: {
    borderBottomColor: primaryBrandColor,
    borderBottomWidth: 0.25,
    flexDirection: 'row',
    paddingBottom: smallGap / 2,
  },
  leftColumn: {
    flex: 3,
  },
  middleColumn: {
    flex: 2,
  },
  rightColumn: {
    flex: 1,
  },
  tableView: {
    flexDirection: 'row',
    paddingVertical: smallGap / 2,
  },
})
