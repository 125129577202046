import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {TilesToDisplayOnce} from 'src/products/card/LoanDash/utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const CardExpiredTile: FC<BaseCardProps> = ({loading, tileMarginHorizontal, tileRadius}) => {
  const {t} = useTranslation('CardDashboard')
  usePageViewedAnalytics({
    eventName: CardEvents.card_expired_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText="ExpiredTitle"
      content={t('ExpiredDescription')}
      loading={loading}
      displayOnceKey={TilesToDisplayOnce.CardExpiredTile}
    />
  )
}
