import {createInstance, Identify} from '@amplitude/analytics-react-native'
import {ReactNativeClient} from '@amplitude/analytics-types'

import {DefaultConfiguration} from 'src/lib/Analytics/ampli'

export function newAmplitude(key: string): ReactNativeClient {
  const amplitudeInstance = createInstance()
  amplitudeInstance.init(key, undefined, {
    ...DefaultConfiguration,
    trackingOptions: {country: false},
  })
  return amplitudeInstance
}

export function setIdentityObject(
  property: string,
  value: string,
  _amplitudeInstance: ReactNativeClient,
): void {
  const id = new Identify()
  id.set(property, value)
  _amplitudeInstance.identify(id)
}
