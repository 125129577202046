import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import PayOverTime from 'src/assets/illustrations/PayOverTime.svg'
import {darkGrayText} from 'src/designSystem/semanticColors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import AppNavActions from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type Props = StackScreenProps<MainStackParamList, 'PayOverTimeConfirmation'> & {
  amount: number
  date: string
}

const PayOverTimeConfirmation: React.FC<Props> = (props) => {
  const {navigation, amount, date} = props
  const {t} = useTranslation(['PayOverTimeConfirmation', 'Common'])
  usePageViewedAnalytics({
    eventName: CardEvents.card_pay_over_time_confirmation_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      title={t('PayOverTimeScheduled')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:OK'),
          onPress: (): void => {
            TrackAppEvent(
              CardEvents.card_pay_over_time_confirmation_page_confirm_cta,
              AppEvents.Category.Card,
            )
            AppNavActions.PopToName(navigation, 'CardDashboard')
          },
        },
      }}
    >
      <Box align="center">
        <Box marginTop="little" marginBottom="small">
          <PFSvgContain width={156} height={156} svg={PayOverTime} />
        </Box>
        <Box align={'start'} direction={'row'} marginVertical="small">
          <PFText variant={'d2'}>{convertToDollarAmt(amount)}</PFText>
        </Box>
        <Box marginBottom="little">
          <PFText variant={'p_lg'} color={darkGrayText}>
            {t('WillBeYourFirstPaymentOn')}
          </PFText>
        </Box>
        <PFText variant={'h3'}>{date}</PFText>
      </Box>
    </Page>
  )
}

export default withForwardedNavigationParams<Props>()(PayOverTimeConfirmation)
