import {PfReduxState} from 'src/reducers/types'

//Firebase Remote Config Selectors, this is not used anymore and should be deprecated

// LEAVING COMMENTED BECAUSE IT IS LIKELY THAT WE WILL NEED STATE BY STATE EXPERIMENTS IN THE NEAR FUTURE
type StateExperiment = {
  abv: string
  unary: (state: PfReduxState) => boolean
}

const currentStateExperiments: StateExperiment[] = []
const emptyStates: string[] = []

export const abFlaggedStatesEnabled = (reduxState: PfReduxState): string[] => {
  if (currentStateExperiments.length === 0) {
    return emptyStates
  } else {
    return currentStateExperiments.filter((exp) => exp.unary(reduxState)).map((exp) => exp.abv)
  }
}
