import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import CardsMultiple from 'src/assets/illustrations/Cards_multiple.svg'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const CardErrorTile: FC<BaseCardProps> = ({tileMarginHorizontal, tileRadius}) => {
  const {t} = useTranslation('CardDashboard')
  usePageViewedAnalytics({
    eventName: CardEvents.card_error_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText={t('Error')}
      content={t('CardError')}
      img={
        <PFSvg
          SvgUri={CardsMultiple}
          svgProps={{
            height: 79,
            width: 89,
          }}
        />
      }
      loading={false}
    />
  )
}
