import React, {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'
import moment from 'moment'

import {capitalizeFirstLetter} from 'src/lib/user/utils'
import {LoanAgreementPayment} from '@possible/cassandra/src/types/types.mobile.generated'
import {NamedColors} from 'src/designSystem/colors'
import {primaryBackgroundColor, separatorColor} from 'src/designSystem/semanticColors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import CollapsibleCard from 'src/designSystem/components/organisms/CollapsibleCard/CollapsibleCard'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import PFDescriptor from 'src/designSystem/components/atoms/PFDescriptor/PFDescriptor'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'

export type LoanApprovedPaymentType = Pick<LoanAgreementPayment, 'amount' | 'date'>

const getPaymentRows = (payments: LoanApprovedPaymentType[]): JSX.Element[] => {
  return payments?.map(
    (payment): JSX.Element => (
      <Box
        key={`payment-${Date.parse((payment.date ?? '').toString())}`}
        gap={'tiny'}
        direction={'row'}
      >
        <Box flex>
          <PFText variant={'p'}>{moment(payment.date).format('MMM DD')}</PFText>
        </Box>
        <PFText variant={'p_semibold'}>${payment.amount ?? '0'}</PFText>
      </Box>
    ),
  )
}

export type LoanPaymentReviewContentProps = {
  accountMask: string
  amountApproved: string
  apr: string
  payments: LoanApprovedPaymentType[]
  preferredAccountInstitutionName: string
  preferredAccountType: string
  totalCost: string
  totalOwed: string
}

const LoanPaymentReviewContent: FC<LoanPaymentReviewContentProps> = (props) => {
  const {
    accountMask,
    amountApproved,
    apr,
    payments,
    preferredAccountInstitutionName,
    preferredAccountType,
    totalCost,
    totalOwed,
  } = props

  const {t} = useTranslation(['LoanApproved', 'Common'])

  const [showSchedule, setShowSchedule] = useState(false)
  const [showAprModal, setShowAprModal] = useState(false)

  const paymentRows = getPaymentRows(payments)

  return (
    <>
      <Box gap={'medium'}>
        <Box align="center">
          <PFText variant={'p_sm_semibold'} color={NamedColors.SILVER}>
            {t('AmountApproved')}
          </PFText>
          <PFText variant={'d2'}>${amountApproved}</PFText>
        </Box>
        <BasicTile>
          <Box gap={'tiny'} padding={'small'}>
            <Box gap={'tiny'} direction={'row'} flex>
              <PFText variant={'p'}>{t('TotalFees')}</PFText>
              <PFText variant={'p'}>${totalCost}</PFText>
            </Box>
            <Box gap={'tiny'} direction={'row'} flex>
              <PFText variant={'p_semibold'}>{t('PaybackAmount')}</PFText>
              <PFText variant={'p_semibold'}>${totalOwed}</PFText>
            </Box>
            <Box gap={'tiny'}>
              <PFText variant={'p'}>
                {t('TotalCostsTranslatesTo')}{' '}
                <SvgLink
                  onPress={(): void => setShowAprModal(true)}
                  linkText={`${apr}% ${t('Apr')}`}
                  linkType={'inline'}
                />
                .
              </PFText>
            </Box>
          </Box>
        </BasicTile>
        <Box gap={'small'} direction={'row'} justify={'center'}>
          <Box align={'center'} flex paddingTop={6}>
            <SvgIcon name={'info'} colorVariant={'success'} size={'large'} />
          </Box>
          <Box boxStyle={styles.descriptorText}>
            <PFText variant={'p'}>
              <Trans i18nKey={'DescriptorText'} t={t}>
                This short term loan is intended to be paid off within a couple months. The
                <PFText variant={'p_semibold'}>APR will look higher</PFText>
                given it is calculating based on a full year calculation.
              </Trans>
            </PFText>
          </Box>
        </Box>
        <Box marginBottom={'small'}>
          <CollapsibleCard
            openTitle={t('ViewSchedule')}
            hideTitle={t('HideSchedule')}
            open={showSchedule}
            onChangeOpen={(): void => setShowSchedule(!showSchedule)}
            content={
              <Box gap={'medium'}>
                <Separator variant={'solid'} color={separatorColor} />
                {paymentRows}
              </Box>
            }
          >
            <Box padding={'small'}>
              <PFText variant={'label_md'}>
                {payments.length} {t('WeekTitle')}
              </PFText>
            </Box>
            <Box
              gap={'tiny'}
              paddingVertical={'medium'}
              paddingHorizontal={'small'}
              boxStyle={{backgroundColor: primaryBackgroundColor}}
            >
              <PFText variant={'p_lg_semibold'}>{preferredAccountInstitutionName}</PFText>
              <PFText variant={'p'}>
                {capitalizeFirstLetter(preferredAccountType)} {accountMask}
              </PFText>
            </Box>
          </CollapsibleCard>
        </Box>
      </Box>
      <Overlay
        visible={showAprModal}
        title={t('WhatIsApr')}
        text={''}
        content={
          <Box direction={'row'} gap={'small'}>
            <PFDescriptor name={'bank-fees'} size={'large'} />
            <Box flex>
              <PFText variant={'p_sm'}>{t('TheAnnualPercentageRate')}</PFText>
            </Box>
          </Box>
        }
        okayButtonText={t('Done')}
        okayButtonSize={'medium'}
        onOkay={(): void => setShowAprModal(false)}
        testID="PaymentReviewModal"
      />
    </>
  )
}

export {LoanPaymentReviewContent}

const styles = StyleSheet.create({
  descriptorText: {
    flex: 9,
  },
})
