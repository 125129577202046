import {useCallback, useState} from 'react'

import {useMutationFactory} from '@possible/cassandra/src/utils'
import {
  UserRegisterDocument,
  UserRegisterInput,
} from '@possible/cassandra/src/types/types.public.generated'

import {ShowException} from 'src/lib/errors'
import {verifyAuthentication} from 'src/api/MobileGatewayAPI/actions'
import {usePfDispatch} from 'src/store/utils'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'

export type UseUserRegisterMutationReturn = {
  userSignUp: (data: UserRegisterInput) => Promise<void>
  isSubmittingForm: boolean
  isSocialBusy: boolean
}

type UseUserRegisterMutationArgs = {
  userLogin: (data: UserRegisterInput) => Promise<void>
}

export const useUserRegisterMutation = (
  args: UseUserRegisterMutationArgs,
): UseUserRegisterMutationReturn => {
  const {userLogin} = args
  const [userRegister, {loading: isLoadingRegisterMutation}] = useMutationFactory(
    UserRegisterDocument,
  )({}, {isPublic: true})

  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const dispatch = usePfDispatch()

  const userSignUp = useCallback(
    async (data: UserRegisterInput): Promise<void> => {
      const {email, password, stateSelected} = data

      setIsAuthenticating(true)
      try {
        const {data: result, errors} = await userRegister({
          variables: {
            input: {
              stateSelected,
              email,
              password,
            },
          },
        })

        if (errors) {
          if (errors[0].extensions?.errorSubCode === 'register_email_exists') {
            sendAnalyticEvent(ampli.registrationExistingLogIn.bind(ampli))
            await userLogin(data)
            setIsAuthenticating(false)
            return
          }
          throw errors
        }

        if (result?.userRegister) {
          await verifyAuthentication({
            response: result.userRegister,
            dispatch: dispatch,
            analyticsData: 'signup',
            method: 'emailpwd',
          })
          sendAnalyticEvent(ampli.registrationGetStarted.bind(ampli))
        }
        setIsAuthenticating(false)
      } catch (e) {
        ShowException(e)
        setIsAuthenticating(false)
      }
    },
    [dispatch, userLogin, userRegister],
  )

  return {
    userSignUp,
    isSubmittingForm: isLoadingRegisterMutation || isAuthenticating,
    isSocialBusy: false,
  }
}
