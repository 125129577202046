import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ApplyInFewStepsTemplate} from 'src/products/general/ApplyInFewSteps/ApplyInFewStepsTemplate'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {isDeviceWeb} from 'src/lib/utils/platform'

type ApplyInFewStepsContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'ApplyInFewSteps'>

const ApplyInFewStepsContainer: React.FC<ApplyInFewStepsContainerProps> = (props): JSX.Element => {
  const {navigation, onPreReqFulfilled} = props
  const [hasPushPermissions] = usePushPermissions()

  navigation.setOptions({
    headerShown: false,
  })

  const onHandleStartThreeStepsApplication = async (): Promise<void> => {
    if (isDeviceWeb()) {
      await onPreReqFulfilled()
      return
    }

    if (!hasPushPermissions) {
      navigation.navigate('NotificationsPermissions')
      return
    }

    await onPreReqFulfilled()
  }

  return (
    <ApplyInFewStepsTemplate
      handleStartThreeStepsApplication={onHandleStartThreeStepsApplication}
    />
  )
}

export {ApplyInFewStepsContainer}
