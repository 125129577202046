import React, {ReactNode, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {StackHeaderProps, StackNavigationProp} from '@react-navigation/stack'
import {StyleSheet, View} from 'react-native'
import {RouteProp, useNavigationState} from '@react-navigation/native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {ParamListBase} from '@react-navigation/routers'

import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import {
  loanApplicationFlowStages,
  OnboardingStages,
  reapplyFlowStages,
} from 'src/navigation/NavPageState'
import HeaderCloseTextButton from 'src/nav/Header/HeaderCloseTextButton/HeaderCloseTextButton'
import HeaderLogoutButton from 'src/nav/Header/HeaderLogoutButton/HeaderLogoutButton'
import {HeaderMenuButton} from 'src/nav/Header/HeaderMenuButton/HeaderMenuButton'
import {UserLoginStates} from 'src/api/reducers/types'
import {
  headerIconHeight,
  headerIconLeftPadding,
  headerIconRightPadding,
  iconSize,
  LeftButtonStateTypes,
  pfHeaderHeight,
  pfHeaderHeightWeb,
  RightButtonStateTypes,
} from 'src/nav/Header/HeaderConstants'
import {getPfStore} from 'src/store'
import {pageMarginHorizontal} from 'src/designSystem/layout'
import {LoanFlowType} from 'src/navigation/LoanFlow'
import {BasicSizeVariants, Color} from 'src/designSystem/types'
import {PfReduxState} from 'src/reducers/types'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {noLeftButtonProp} from 'src/nav/ModalStackHeader'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type RouteType = RouteProp<MainStackParamList>

type NavigationType = StackNavigationProp<ParamListBase, string>

const leftButtonComp = (
  leftButton: LeftButtonStateTypes,
  navigation: NavigationType,
  headerButtonsColor?: Color,
): ReactNode => {
  switch (leftButton) {
    case LeftButtonStateTypes.BACK:
      return (
        <View style={styles.imageView}>
          <HeaderBackButton navigation={navigation} size={iconSize} color={headerButtonsColor} />
        </View>
      )
    case LeftButtonStateTypes.LOGOUT:
      return (
        <View style={styles.logoutViewLeft}>
          <HeaderLogoutButton color={headerButtonsColor} />
        </View>
      )
    case LeftButtonStateTypes.MENU:
      return (
        <View style={styles.imageView}>
          <HeaderMenuButton navigation={navigation} size={iconSize} />
        </View>
      )
    default:
      return undefined
  }
}

const rightButtonComp = (
  rightButton: RightButtonStateTypes,
  navigation: NavigationType,
  headerButtonsColor?: Color,
): ReactNode => {
  switch (rightButton) {
    case RightButtonStateTypes.CLOSE:
      return (
        <View style={styles.closeView}>
          <HeaderCloseTextButton navigation={navigation} color={headerButtonsColor} />
        </View>
      )
    case RightButtonStateTypes.LOGOUT:
      return (
        <View style={styles.logoutViewRight}>
          <HeaderLogoutButton />
        </View>
      )
    case RightButtonStateTypes.NONE:
      return undefined
  }
}

export const getLeftButtonType = (
  index: number,
  currentPageName: string,
  userIsLoggedIn: boolean,
): LeftButtonStateTypes => {
  if (index === 0) {
    if (OnboardingStages.includes(currentPageName)) {
      return LeftButtonStateTypes.LOGOUT
    } else if (userIsLoggedIn) {
      return LeftButtonStateTypes.MENU
    } else {
      return LeftButtonStateTypes.NONE
    }
  } else {
    return LeftButtonStateTypes.BACK
  }
}

const showCloseButton = (
  route: RouteType,
  currentPageName: string,
  reduxState: PfReduxState,
): boolean => {
  //we don't want to show the close button when we are on the email verification screen outside of the application flow
  const isEditEmail: boolean = !!route.params?.['editEmail']
  if (isEditEmail) {
    return false
  }

  return (
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    loanApplicationFlowStages(reduxState)?.includes(currentPageName as LoanFlowType) ||
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    reapplyFlowStages(reduxState)?.includes(currentPageName as LoanFlowType)
  )
}

export const getRightButtonType = (
  index: number,
  currentPageName: string,
  route: RouteType,
  reduxState: PfReduxState,
  closeButton?: boolean,
): RightButtonStateTypes => {
  if (index > 0) {
    if (showCloseButton(route, currentPageName, reduxState) || closeButton) {
      return RightButtonStateTypes.CLOSE
    } else if (currentPageName === 'Profile') {
      return RightButtonStateTypes.LOGOUT
    }
  }
  return RightButtonStateTypes.NONE
}

type Props = StackHeaderProps &
  PropsFromRedux & {
    headerButtonsColor?: Color
    closeButton?: boolean
  }
const MainStackHeader = (props: Props) => {
  const [leftButton, setLeftButton] = useState(LeftButtonStateTypes.NONE)
  const [rightButton, setRightButton] = useState(RightButtonStateTypes.NONE)
  const [logo, setLogo] = useState(false)
  const [logoSize, setLogoSize] = useState<BasicSizeVariants>('medium')
  const {route, navigation, userIsLoggedIn, headerButtonsColor, closeButton} = props
  const store = getPfStore()
  const reduxState = store.getState()
  const index = useNavigationState((state) => state.index)

  const processState = (currentPageName: string, pageParams?: object) => {
    if (currentPageName === 'Dashboard') {
      setLeftButton(LeftButtonStateTypes.MENU)
      setRightButton(RightButtonStateTypes.NONE)
      setLogo(true)
      return
    }

    if (currentPageName === 'Landing') {
      setLeftButton(LeftButtonStateTypes.NONE)
      setRightButton(RightButtonStateTypes.NONE)
      setLogo(true)
      setLogoSize('small')
      return
    }

    if (pageParams?.[noLeftButtonProp]) {
      setLeftButton(LeftButtonStateTypes.NONE)
      setRightButton(RightButtonStateTypes.NONE)
      return
    }

    setLeftButton(getLeftButtonType(index, currentPageName, userIsLoggedIn))
    setRightButton(
      getRightButtonType(index, currentPageName, route as RouteType, reduxState, closeButton),
    )
  }

  useEffect(() => processState(route.name, route.params))

  return (
    <SafeAreaView edges={['top']}>
      <View style={[styles.container, isDeviceWeb() && styles.containerWeb]}>
        <View style={styles.leftButton} testID="btn_previous">
          {leftButtonComp(leftButton, navigation, headerButtonsColor)}
        </View>
        <View style={styles.content}>{logo ? <NameLogo size={logoSize} /> : null}</View>
        <View style={styles.rightButton}>
          {rightButtonComp(rightButton, navigation, headerButtonsColor)}
        </View>
      </View>
    </SafeAreaView>
  )
}
const mapStateToProps = (state) => {
  return {
    userIsLoggedIn: state.api.user_logged_state === UserLoginStates.logged_in,
  }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MainStackHeader)

const styles = StyleSheet.create({
  closeView: {
    paddingRight: headerIconRightPadding,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: pfHeaderHeight,
    width: '100%',
  },
  containerWeb: {
    height: pfHeaderHeightWeb,
  },
  content: {
    flex: 1,
  },
  imageView: {
    height: headerIconHeight,
    marginLeft: headerIconLeftPadding,
    width: headerIconHeight,
  },
  leftButton: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  logoutViewLeft: {
    alignItems: 'flex-start',
    paddingLeft: pageMarginHorizontal,
  },
  logoutViewRight: {
    paddingRight: pageMarginHorizontal,
  },
  rightButton: {
    alignItems: 'flex-end',
    flex: 1,
  },
})
