import React from 'react'
import {get, orderBy} from 'lodash'

import {ReapplicationBankTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankTemplate'
import {useGetLinkedAccounts} from 'src/lib/bank/useGetLinkedAccounts'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'

type Props = {
  onContinue: () => void | Promise<void>
  bankLinkAnalyticEvent: () => void
}

export const ReapplicationBankGQLContainer: React.FC<Props> = (props) => {
  const {onContinue: handleOnContinue, bankLinkAnalyticEvent} = props

  const {linkedAccountsData, isLoadingLinkedAccounts, linkedAccountsError} = useGetLinkedAccounts()

  const {addOrRelinkAccountForPrimary} = useAccountManagementBankLinking()

  const isPreferredAccount = linkedAccountsData?.preferredAccount

  const isLatestAccount = orderBy(
    linkedAccountsData?.linkedAccounts,
    [(o): unknown => get(o, 'lastSyncedAt', 0)],
    ['desc'],
  )[0]

  const handleOnRelinkAccount = (): void => {
    if (!isPreferredAccount?.id) {
      logAddPaymentMethodErrorAndShowException(
        new Error(
          'ReapplicationBankGQLContainer handleOnRelinkAccount() no preferred account found',
        ),
      )
      return
    }
    addOrRelinkAccountForPrimary({
      accountToRelink: {
        id: isPreferredAccount?.id,
      },
      onChoosePrimaryAccountComplete: handleOnContinue,
    })
    bankLinkAnalyticEvent()
  }

  const handleShouldLinkNewAccount = (): void => {
    addOrRelinkAccountForPrimary({
      onChoosePrimaryAccountComplete: handleOnContinue,
    })
    bankLinkAnalyticEvent()
  }

  return (
    <BaseTemplate
      isLoading={isLoadingLinkedAccounts && !linkedAccountsData}
      isError={!!linkedAccountsError}
    >
      <ReapplicationBankTemplate
        {...props}
        isPreferredAccount={isPreferredAccount}
        isLatestAccount={isLatestAccount}
        onContinue={handleOnContinue}
        onLinkNewAccount={handleShouldLinkNewAccount}
        onRelinkAccount={handleOnRelinkAccount}
      />
    </BaseTemplate>
  )
}
