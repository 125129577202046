import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useState} from 'react'
import {isEmulatorSync} from 'react-native-device-info'

import PhoneVerificationTemplate from 'src/products/loans/PhoneConfirmation/PhoneVerificationTemplate'
import {ModuleControlProps} from 'src/flows/types'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

const isEmulator = isEmulatorSync()

type Props = StackScreenProps<MainStackParamList, 'PhoneVerification'> & ModuleControlProps

const PhoneVerificationMPOContainer: React.FC<Props> = (props) => {
  const {onModuleComplete} = props

  const [showNotificationsModal, setShowNotificationsModal] = useState(false)

  const [hasPushPermissions] = usePushPermissions()

  const handleVerifiedSuccessfully = async (): Promise<void> => {
    //the __DEV_ condition is to allow detox to bypass the push permission
    if (__DEV__ || isEmulator) {
      setShowNotificationsModal(true)
      await onModuleComplete?.()
      return
    }
    if (isDeviceWeb()) {
      await onModuleComplete?.()
    } else {
      if (!hasPushPermissions) {
        setShowNotificationsModal(true)
      } else {
        await onModuleComplete?.()
      }
    }
  }

  const handlePushPermissionsGranted = useCallback(async () => {
    await onModuleComplete?.()
  }, [onModuleComplete])

  const handlePushPermissionsModalDismissed = useCallback(async () => {
    setShowNotificationsModal(false)
    return Promise.resolve()
  }, [])

  return (
    <PhoneVerificationTemplate
      {...props}
      showNotificationsModal={showNotificationsModal}
      onVerifiedSuccessfully={handleVerifiedSuccessfully}
      onPushPermissionsGranted={handlePushPermissionsGranted}
      onPushPermissionsModalDismissed={handlePushPermissionsModalDismissed}
    />
  )
}

export default PhoneVerificationMPOContainer
