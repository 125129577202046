import moment from 'moment'
import {sortBy, reverse} from 'lodash'

import {statusList, fundingAuthDirection} from 'src/lib/loans/consts'
import {momentDateAfterTimeFormat} from 'src/lib/time_util'
import {PfReduxState} from 'src/reducers/types'
import {ILoanRedux} from 'src/lib/loans/reducers/types'
import {formatDate, humanReadableDateNumbers} from 'src/lib/utils/date'

type Loan = ILoanRedux | null | undefined

export function getLoanStatus(loan: Loan): statusList | undefined {
  return loan?.status?.status as statusList
}

export function getLoanSubStatus(loan: Loan): statusList | undefined {
  return loan?.status?.subStatus as statusList
}

export function getLoanStatusReason(loan: Loan): string | undefined {
  return loan?.status?.statusReason
}

const latestLoanIsNotActionable = (loan: ILoanRedux) => {
  return loan
    ? getLoanStatus(loan) === statusList.REJECTED ||
        getLoanStatus(loan) === statusList.CANCELLED ||
        getLoanStatus(loan) === statusList.EXPIRED
    : true
}

export function loanDefaultOrChargedOff(loan: ILoanRedux) {
  return (
    (getLoanStatus(loan) === statusList.ACTIVE &&
      getLoanSubStatus(loan) === statusList.ACTIVE_DEFAULT) ||
    getLoanStatus(loan) === statusList.CHARGED_OFF
  )
}

export function loanIsLate(loan: ILoanRedux) {
  return (
    getLoanStatus(loan) === statusList.ACTIVE && getLoanSubStatus(loan) === statusList.ACTIVE_LATE
  )
}

export function get_latest_loan(loans: ILoanRedux[] | undefined): ILoanRedux | null {
  if (!loans || loans.length === 0) {
    return null
  }
  loans = sortBy(loans, ['createdAtDatetime'])
  loans = reverse(loans)
  if (!loans) return null
  const latestLoan = loans[0]
  const previousLoan = loans.length > 1 ? loans[1] : undefined
  if (
    previousLoan &&
    (loanDefaultOrChargedOff(previousLoan) || loanIsLate(previousLoan)) &&
    latestLoanIsNotActionable(latestLoan)
  ) {
    return previousLoan
  }

  // If latest loan is cancelled, but there is a previous active loan,
  // return the active loan
  // This happens when users declines an installment loan
  const activeLoans = loans.filter((loan) => getLoanStatus(loan) === statusList.ACTIVE)
  if (getLoanStatus(latestLoan) === statusList.CANCELLED && activeLoans.length) {
    return activeLoans[0]
  }

  return latestLoan
}

export function get_loan_terms(store: PfReduxState, loan_term_id) {
  return store.lib?.loans?.loan_terms_by_id?.get(loan_term_id)
}

export function sort_loans_by_created_desc(loans) {
  if (!loans) {
    return []
  }
  return reverse(sortBy(loans, ['createdAtDatetime']))
}

export function get_payment_amount(loan) {
  return loan.agreement.totalOwed
}

export function get_payments_from_agreement(loan) {
  return loan?.agreement?.payments
}

export function getReapplyDate(loan, userStateAbv?: string) {
  let body: undefined | string[] = undefined

  const eligibleOn = moment(loan?.reapplyDatetime).utc()
  const now = moment().utc()
  const reapplyDate = eligibleOn
    ? moment(eligibleOn).local().format(momentDateAfterTimeFormat)
    : undefined
  const canReapply = eligibleOn?.isSameOrBefore(now)
  const status = getLoanStatus(loan)
  if (status === statusList.REJECTED) {
    return {reapplyDate, canReapply}
  }

  if (userStateAbv === 'FL' && status === statusList.PAIDOFF) {
    body = !canReapply
      ? [`Due to Florida regulations, you will be able to apply again on ${reapplyDate}`]
      : undefined
  }
  return {reapplyDate, canReapply, body}
}

export function isLoanLate(loanStatus, loanSubStatus) {
  return loanStatus === statusList.ACTIVE && loanSubStatus === statusList.ACTIVE_LATE
}

export function isLoanChargedOff(loanStatus) {
  return loanStatus === statusList.CHARGED_OFF
}

export function isDefaultOrChargedOff(loanStatus, loanSubStatus) {
  return (
    (loanStatus === statusList.ACTIVE && loanSubStatus === statusList.ACTIVE_DEFAULT) ||
    loanStatus === statusList.CHARGED_OFF
  )
}

export function isUpgradeAvailable(transfers, userStateAbv) {
  if (!transfers || userStateAbv !== 'CA') {
    return false
  }

  if (transfers.length === 1) {
    const transaction = transfers[0]
    const rescheduledDate = transaction.rescheduledDate ? moment(transaction.rescheduledDate) : null
    const hasBeenRescheduled =
      rescheduledDate && !rescheduledDate.isSame(moment(transaction.originalDate))
    return (
      transaction &&
      transaction.status !== statusList.FAILED &&
      transaction.status !== statusList.SUSPENDED &&
      transaction.status !== statusList.PROCESSING &&
      !hasBeenRescheduled
    )
  }
  return false
}

export function isLoanPending(loanStatus) {
  return loanStatus === statusList.PENDING
}

export function hasApplicationExpired(loanStatus, loanSubStatus) {
  return (
    loanStatus === statusList.EXPIRED &&
    (loanSubStatus === statusList.EXPIRED_PENDING || loanSubStatus === statusList.EXPIRED)
  )
}

export function hasLoanExpired(loanStatus, loanSubStatus) {
  return loanStatus === statusList.EXPIRED && loanSubStatus === statusList.EXPIRED_APPROVED
}

export function isLoanActive(loanStatus) {
  return loanStatus === statusList.ACTIVE
}

export function isLoanRejected(loanStatus) {
  return loanStatus === statusList.REJECTED
}

export function isLoanApproved(loanStatus) {
  return loanStatus === statusList.APPROVED
}

export function isLoanProcessing(loanStatus) {
  return loanStatus === statusList.PROCESSING
}

export const hasOpenLoanStatus = (loanStatus?: string): boolean => {
  return !!(
    loanStatus &&
    (isLoanActive(loanStatus) ||
      isLoanApproved(loanStatus) ||
      isLoanPending(loanStatus) ||
      userAcceptedLoan(loanStatus))
  )
}

export const getLatestLoanStatus = (state: PfReduxState | undefined): statusList | undefined => {
  if (!state) return
  const latestLoan = get_latest_loan(state.lib?.loans?.loans)
  return getLoanStatus(latestLoan)
}

export const hasOpenLoan = (state: PfReduxState | undefined) => {
  const loanStatus = getLatestLoanStatus(state)
  if (loanStatus === undefined) return false
  return hasOpenLoanStatus(loanStatus)
}

export const hasChargedOffLoan = (state: PfReduxState | undefined) => {
  const loanStatus = getLatestLoanStatus(state)
  if (loanStatus === undefined) return false
  return isLoanChargedOff(loanStatus)
}

export const hasCompletedLoan = (state: PfReduxState | undefined): boolean => {
  if (!state) {
    return false
  }

  const completedLoan = (state.lib?.loans?.loans ?? []).find((loan) => {
    const status = getLoanStatus(loan)
    return status && [statusList.COMPLETED, statusList.PAIDOFF].includes(status)
  })

  return !!completedLoan
}

export function isLoanReplacementPending(loanStatus, replacedLoanId) {
  return isLoanPending(loanStatus) && replacedLoanId
}
export function isPendingInstallmentLoan(loan) {
  return isLoanReplacementPending(getLoanStatus(loan), loan?.originalLoanId)
}

export function isLoanReplacementApproved(loanStatus, replacedLoanId) {
  return isLoanApproved(loanStatus) && replacedLoanId
}

export function noCurrentLoanCanReapply(loan) {
  /* checking if the loan was paid off more than 2 weeks ago */
  const currentPeriodWeeks = 2

  const now = moment().utc()
  const notCurrent = moment(loan?.paidOffDatetime).utc().add(currentPeriodWeeks, 'weeks')

  return notCurrent?.isBefore(now) || notCurrent?.isSame(now, 'day')
}

export function userAcceptedLoan(loan) {
  return getLoanStatusReason(loan) === statusList.USER_ACCEPTED_LOAN
}

export function showTitleandPayments(loan) {
  const loanStatus = getLoanStatus(loan)

  if (!loanStatus) {
    return false
  }

  switch (loanStatus) {
    case statusList.ACTIVE:
    case statusList.PAIDOFF:
    case statusList.CHARGED_OFF:
    case statusList.DEFAULT:
    case statusList.LATE:
      return true
    case statusList.REJECTED:
    case statusList.EXPIRED:
    case statusList.CANCELLED:
      return false
    // For Approved and Pending, if this loan is a replacement for an earlier loan, show payments
    case statusList.APPROVED:
    case statusList.PENDING:
      return isInstallmentLoan(loan)
    default:
      return false
  }
}

export function getPaymentInstrumentFromLoan(loan: ILoanRedux | null) {
  return loan?.paymentInstruments?.find((obj) => obj.direction === fundingAuthDirection.from)
}

/**
 * Loans expire 3 days after they are approved
 */
export const getLoanExpirationDate = (
  loan: ILoanRedux | null,
  dateFormat: string = humanReadableDateNumbers,
): string | undefined => {
  if (!loan) return
  const {approvedAtDatetime} = loan
  return getLoanExpirationDateStr(approvedAtDatetime, dateFormat)
}

export const getLoanExpirationDateStr = (
  approvedAtDatetime?: string,
  dateFormat: string = humanReadableDateNumbers,
): string | undefined => {
  if (!approvedAtDatetime || !moment(approvedAtDatetime).isValid()) return
  const expiresAt = moment(approvedAtDatetime).add(3, 'd')
  return formatDate(expiresAt, dateFormat)
}

/**
 * Installment loans should have an originalLoanId set
 */
export const isInstallmentLoan = (loan) => {
  return !!loan?.originalLoanId
}

export const paymentTypeMapper = (type) => {
  const paymentTypes = {
    ach: 'bank account',
    debit_card: 'debit card',
    default: 'account',
  }
  return paymentTypes[type] || paymentTypes['default']
}

export const rejectedReasonsMapper = (reason: string | undefined = 'default'): string => {
  const reasons: {[key: string]: string} = {
    'banking.checking.balancetoolow': 'the average account balance is currently too low',
    'banking.error.income_too_low': 'income to this account doesn’t meet eligibility requirements.',
    'banking.error.income_unpredictable':
      'we were not able to identify a regular pattern of income deposits for this account.',
    'banking.error.income_verify':
      'we were not able to identify a regular pattern of income deposits for this account.',
    'corona.income.risky_income_source':
      'we were not able to identify a regular pattern of income deposits for this account.',
    'banking.error.min_history': 'there was not enough bank history available in this account.',
    'banking.error.returned_checks_high':
      'this account showed a high amount of returned transactions',
    'loan.reject.cash_flow': 'the funds in this account are used up too soon after income deposit',
    'loan.reject.pf_performance': 'there were late payments on your previous loan with Possible',
    'loan.reject.stacking_behavior':
      'this account showed recent deposits and withdrawals from other lenders.',
    'user.legal.sdb_check_failed': 'there are loan restrictions from your state.',
    'underwriting.reject.limited_pf_history': 'you have limited credit history with us',
    'default': 'the average account balance is currently too low',
  }

  return reasons[reason] || reasons['default']
}

export const isAmountRequestedBiggerThanBorrowed = (amountBorrowed, amountRequested) => {
  if (!amountBorrowed || !amountRequested) return false
  return amountRequested > amountBorrowed
}

export const isCounterOffer = (amountBorrowed, amountRequested) => {
  return amountBorrowed !== amountRequested
}
