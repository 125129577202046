import React from 'react'
import {Keyboard} from 'react-native'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {FormProvider, useForm} from 'react-hook-form'

import {RegistrationTemplate} from 'src/products/MCU/RegistrationOrLogin/Registration/RegistrationTemplate'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {UserRegisterInput} from '@possible/cassandra/src/types/types.public.generated'

import {useUserLoginMutation} from 'src/products/MCU/RegistrationOrLogin/Login/useUserLoginMutation'
import {useUserRegisterMutation} from 'src/products/MCU/RegistrationOrLogin/Registration/useUserRegisterMutation'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

const Registration: React.FC = (): JSX.Element => {
  const formContext = useForm<UserRegisterInput>({
    mode: 'all',
  })

  const {isSocialBusy, handleSocialLogin, userLogin} = useUserLoginMutation(formContext)

  const {isSubmittingForm, userSignUp} = useUserRegisterMutation({userLogin})

  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'Registration'>>()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.registration_screen_viewed,
    eventCategory: AppEvents.Category.Login,
  })

  const handleTapLogin = (): void => {
    navigation.replace('Login')
  }

  const handleSignUp = (input: UserRegisterInput): void => {
    Keyboard.dismiss()
    void userSignUp(input)
  }

  return (
    <FormProvider {...formContext}>
      <RegistrationTemplate
        onHandleSignUp={handleSignUp}
        onHandleTapLogin={handleTapLogin}
        onHandleSocialLogin={handleSocialLogin}
        isSocialBusy={isSocialBusy}
        isSubmittingForm={isSubmittingForm}
      />
    </FormProvider>
  )
}

export {Registration}
