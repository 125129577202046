import moment, {Moment} from 'moment'
import {
  Address as CAddress,
  ApplicantPhysicalAddressComponentsInput,
} from '../types/types.mobile.generated'
import {Address as IAMAddress} from '../types/types.iam.generated'

// Dates
/**
 * Format string for date times. YYYY-MM-DDTHH:mm:ss[Z]
 */
export const DateTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'

/**
 * Format string for dates. YYYY-MM-DD
 */
export const DateFormat = 'YYYY-MM-DD'

/**
 * Format a date to a date time string using formatString.
 * @param date The date to format.
 * @param formatString The format string to use. Defaults to DateTimeFormat.
 * @returns A formatted string.
 */
export const DateToDateTimeFormat = (
  date: Date | Moment | string | undefined | null,
  formatString = DateTimeFormat,
) => moment(date).format(formatString)

/**
 * Format a date to a date string using formatString
 * @param date The date time to format.
 * @param formatString The format string to use. Defaults to DateFormat.
 * @returns A formatted string.
 */
export const DateToDateFormat = (
  date: Date | Moment | string | undefined | null,
  formatString = DateFormat,
) => moment(date).format(formatString)

/**
 * Format a date representing now to a date time string using formatString.
 * @param formatString The format string to use. Defaults to DateTimeFormat.
 * @returns A formatted string.
 */
export const NowInDateTimeFormat = (formatString = DateTimeFormat) => moment().format(formatString)

/**
 * Format a date representing now to a date string using formatString.
 * @param formatString The format string to use. Defaults to DateFormat.
 * @returns A formatted string.
 */
export const NowInDateFormat = (formatString = DateFormat) => moment().format(formatString)

export const nowM = moment().add(2, 'day')
export const nowStr = nowM.format('YYYY-MM-DD')
export const oneYearAgoStr = nowM.clone().add(-1, 'y').format('YYYY-MM-DD')

// Addresses
const textOrEmpty = (text: string | undefined | null): string => text ?? ''

/**
 * Format an address or address components to a single string.
 * @param address The address or address components to format.
 * @returns ${street1}
 *
 * ${street2}
 *
 * ${city}, ${state} ${postalCode}
 */
export const FormatAddressOrAddressComponents = (
  address: CAddress | IAMAddress | ApplicantPhysicalAddressComponentsInput,
): string => {
  const {street1, street2, city, state, postalCode} = address
  const streets = [street1, street2].filter((s) => !!s).join('\n')
  const cityState = [city, state].filter((s) => !!s).join(', ')
  const region = [cityState, postalCode].filter((s) => !!s).join(' ')
  return [streets, region].filter((s) => !!s).join('\n')
}
