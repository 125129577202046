import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {useFocusEffect} from '@react-navigation/native'
import React, {useCallback} from 'react'

import {ModuleControlProps} from 'src/flows/types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {CollectDebitCardNumbersForLoanDocument} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoan.gqls'

import {
  CollectDebitCardNumbersForLoanGQLContainer,
  CollectDebitCardNumbersForLoanGQLContainerProps,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanGQLContainer'
import {logOfferActivationError} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'

export type CollectDebitCardNumbersForLoanMPOContainerProps = ModuleControlProps

/**
 * Container for the CollectDebitCardNumbersForLoan screen to integrate it into
 * the MPO navigation system.
 */
const CollectDebitCardNumbersForLoanMPOContainer: React.FC<
  CollectDebitCardNumbersForLoanMPOContainerProps
> = (props) => {
  const {onModuleComplete, onStepComplete} = props

  const [fetchQuery] = useCassandraLazyQuery(
    CollectDebitCardNumbersForLoanDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (from) => {
      const hasActiveLoan =
        from.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ActiveLoanAggregateStatus'
      return {
        hasActiveLoan,
      }
    },
  )
  const fetchQueryToCheckForActiveLoan = useCallback(() => {
    void fetchQuery().then((res) => {
      // if we somehow end up here with an active loan we should leave this MPO module immediately
      if (res.selectedData?.hasActiveLoan) {
        logOfferActivationError(
          new Error(
            'CollectDebitCardNumbersForLoanMPOContainer encountered when user has an active loan',
          ),
        )
        void onModuleComplete?.()
      }
    })
  }, [fetchQuery, onModuleComplete])

  useFocusEffect(fetchQueryToCheckForActiveLoan)

  /**
   * After adding a new debit card they've completed this MPO step.
   */
  const handleOnDebitCardSaved = async (): Promise<void> => {
    TrackAppEvent(
      AppEvents.Name.disbursement_method_debit_link_success,
      AppEvents.Category.Checkout,
    )
    await onStepComplete?.()
  }

  const handleOnDebitCardSaveFailed: CollectDebitCardNumbersForLoanGQLContainerProps['onDebitCardSaveFailed'] =
    (errorReason) => {
      TrackAppEvent(
        AppEvents.Name.disbursement_method_debit_link_fail,
        AppEvents.Category.Checkout,
        {error: errorReason},
      )
    }
  return (
    <CollectDebitCardNumbersForLoanGQLContainer
      onDebitCardSaved={handleOnDebitCardSaved}
      onDebitCardSaveFailed={handleOnDebitCardSaveFailed}
    />
  )
}

export {CollectDebitCardNumbersForLoanMPOContainer}
