import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import WarningOrange from 'src/assets/icons/WarningOrange.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type ConfirmBankDetailsModalProps = {
  isVisible: boolean
  onOkay: () => void | Promise<void>
  accountMask?: string
}

const ConfirmBankDetailsModal: FC<ConfirmBankDetailsModalProps> = (props) => {
  const {t} = useTranslation('ConfirmDetailsAcceptLoan')

  const {isVisible, onOkay: handleOnOkay, accountMask} = props

  return (
    <Overlay
      visible={isVisible}
      title={
        <PFText variant={'h2'} textAlign={'center'}>
          {t('ConfirmDetailsAcceptLoanLongTitle')}
        </PFText>
      }
      image={
        <Box>
          <PFSvgContain svg={WarningOrange} width="33%" />
        </Box>
      }
      text={
        <PFText variant={'p'} textAlign={'center'}>
          {t('ConfirmDetailsAcceptLoanBodyStart')}
          <PFText variant={'p_semibold'} textAlign={'center'}>
            {t('ConfirmDetailsAcceptLoanBodyMiddle', {mask: accountMask})}
          </PFText>
          {t('ConfirmDetailsAcceptLoanBodyEnd')}
        </PFText>
      }
      okayButtonText={t('ConfirmDetailsAcceptLoanPrimaryAction')}
      onOkay={handleOnOkay}
      testID="ConfirmBankDetailsModal"
    />
  )
}

export {ConfirmBankDetailsModal}
