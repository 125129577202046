import {Consumer} from '@possible/cassandra'
import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'
import {transferMethodsType} from 'src/lib/loans/consts'

export const TransferMethodsToLoanPaymentMethod = (
  transferMethod: transferMethodsType,
): LoanPaymentMethod => {
  switch (transferMethod) {
    case 'ach':
      return Consumer.types.LoanPaymentMethod.Ach
    case 'check':
      return Consumer.types.LoanPaymentMethod.Check
    case 'interchange':
      return Consumer.types.LoanPaymentMethod.DebitCard
    case 'none':
      return Consumer.types.LoanPaymentMethod.None
  }
}
