import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AchPaymentMethod, AutopayModel} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {ShowException, logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {
  CardActivationAutopayScheduleMutationDocument,
  CardSetAutopayModelMutationDocument,
} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleMutation.gqls'
import {CardActivationAutopayScheduleDocument} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleQuery.gqls'
import {
  CardActivationAutopayScheduleTemplate,
  CardActivationAutopayScheduleTemplateProps,
} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleTemplate/CardActivationAutopayScheduleTemplate'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {PaymentAccount, PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {useBankAggregator} from 'src/products/card/PaymentMethods/useBankAggregator'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {
  getValidPaymentFrequency,
  getValidPaymentSchedule,
} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardPaymentScheduleTemplate.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type CardActivationAutopayScheduleGQLContainerProps = {
  defaultPaymentMethod: PaymentAccount
  doesPrimaryPaymentMethodHavePaymentInstrument: boolean
  navigation: StackScreenProps<MainStackParamList>['navigation']
  onComplete: () => void
  onPressSecondary: () => void
  Template?: React.ComponentType<CardActivationAutopayScheduleTemplateProps>
} & Pick<CardActivationAutopayScheduleTemplateProps, 'isRoutedFromDashboard'>

export const CardActivationAutopayScheduleGQLContainer: FC<
  CardActivationAutopayScheduleGQLContainerProps
> = ({
  defaultPaymentMethod,
  doesPrimaryPaymentMethodHavePaymentInstrument,
  navigation,
  onComplete,
  onPressSecondary,
  Template = CardActivationAutopayScheduleTemplate,
  isRoutedFromDashboard,
}) => {
  const {t} = useTranslation(['CardSetupAutopayments', 'Common'])
  const {
    data,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(CardActivationAutopayScheduleDocument, {fetchPolicy: 'cache-first'})
  const [isLoadingMutation, setIsLoadingMutation] = useState<boolean>(false)

  const [cardAccountEnableAutomaticPayments] = useCassandraMutation(
    CardActivationAutopayScheduleMutationDocument,
    {
      /* This is necessary because the mutation's schema does not return a copy of the mutated
       * fields. This is a workaround to ensure the client and server state stay in sync. */
      update: (cache) => {
        cache.evict({
          id: `CardAccount:${data?.me.cardAccounts.active?.id}`,
          fieldName: 'autopayEnabled',
        })
      },
    },
  )

  const linkPaymentMethod = useLinkPaymentMethod()
  const callBankAggregator = useBankAggregator(navigation)

  const enableAutopay = async (paymentAccountId?: string): Promise<void> => {
    try {
      setIsLoadingMutation(true)
      const linkedPaymentMethod = await linkPaymentMethod(paymentAccountId, false, true)
      const r = await cardAccountEnableAutomaticPayments({
        variables: {
          cardAccountId: data?.me.cardAccounts.active?.id ?? '',
          cardPaymentMethodId: linkedPaymentMethod?.id ?? '',
        },
      })
      if (r?.errors && r.errors.length > 0) {
        throw r.errors[0]
      }

      onComplete()
    } catch (e) {
      void logErrorAndShowException(e, t('ErrorText'))
    } finally {
      setIsLoadingMutation(false)
    }
  }

  const onCompleteVerifyBankDetails = async (
    selectedBankAccount: AchPaymentMethod | undefined,
  ): Promise<void> => {
    await enableAutopay(selectedBankAccount?.id)
  }

  const handleOnPressSubmit = async (): Promise<void> => {
    if (!data?.me.cardAccounts.active?.id) {
      ShowException('Common:UnableToLocateAccount')
    }

    if (!defaultPaymentMethod) {
      TrackAppEvent(CardEvents.card_autopay_schedule_page_continue_cta, AppEvents.Category.Card, {
        bankLinkStatus: 'MissingDefaultPaymentInstrument',
      })

      callBankAggregator((bankName: string) => {
        PushPage(navigation, 'CardVerifyBankDetails', {
          bankNameToFilter: bankName,
          flow: PaymentFlow.Onboarding,
          onComplete: onCompleteVerifyBankDetails,
        })
      })
    } else if (!doesPrimaryPaymentMethodHavePaymentInstrument) {
      TrackAppEvent(CardEvents.card_autopay_schedule_page_continue_cta, AppEvents.Category.Card, {
        bankLinkStatus: 'MissingPaymentMethod',
      })

      PushPage(navigation, 'CardVerifyBankDetails', {
        bankIdToFilter: getPaymentMethodAccount(defaultPaymentMethod)?.id,
        flow: PaymentFlow.Onboarding,
        onComplete: onCompleteVerifyBankDetails,
      })

      return
    } else {
      TrackAppEvent(CardEvents.card_autopay_schedule_page_continue_cta, AppEvents.Category.Card, {
        bankLinkStatus: 'LinkedPaymentMethod',
      })
      await enableAutopay(defaultPaymentMethod.id)
    }
  }

  const handleOnSelectManualPay = (): void => {
    void onPressSecondary?.()
  }

  const getPrimaryButtonText = (): string | undefined => {
    if (!defaultPaymentMethod) {
      return t('LinkYourBankAccount')
    } else if (!doesPrimaryPaymentMethodHavePaymentInstrument) {
      return t('VerifyBankDetails')
    } else {
      return undefined
    }
  }

  const paymentFrequency = getValidPaymentFrequency(
    data?.me.cardAccounts?.active?.payments?.potential?.frequency,
  )
  const paymentSchedule = getValidPaymentSchedule(
    data?.me.cardAccounts.active?.payments?.potential?.payments,
  )

  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const [cardAccountSetAutopayModel] = useCassandraMutation(CardSetAutopayModelMutationDocument)
  const setAutoPayModel = async (autopayModel: AutopayModel): Promise<void> => {
    await cardAccountSetAutopayModel({
      variables: {
        cardAccountId: data?.me.cardAccounts.active?.id ?? '',
        autopayModel,
      },
    })
  }
  return (
    <BaseTemplate isLoading={isLoadingQuery || !paymentSchedule} isError={!!queryError}>
      <Template
        isLoading={isLoadingMutation}
        onPressPrimary={handleOnPressSubmit}
        onSelectManualPay={handleOnSelectManualPay}
        paymentFrequency={paymentFrequency}
        paymentSchedule={paymentSchedule ?? []}
        primaryButtonText={getPrimaryButtonText()}
        minPayData={cardMinPayResponse}
        setMinPayAutopayModel={setAutoPayModel}
        isRoutedFromDashboard={isRoutedFromDashboard}
      />
    </BaseTemplate>
  )
}
