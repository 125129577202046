import React, {FC} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import StateLoanInfo from 'src/products/loans/LoanSelection/StateLoanInfo'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
  paymentInfo: PaymentInfoType
}

const TexasLoanInfo: FC<Props> = ({navigation, paymentInfo}) => {
  const {t} = useTranslation('LoanSelection')
  return (
    <StateLoanInfo
      navigation={navigation}
      description={t('TXMainDescription')}
      paymentInfo={paymentInfo || {}}
      feesTitle={t('CSOFees')}
    />
  )
}
export default TexasLoanInfo
