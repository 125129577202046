export const TOP_OF_UI_STACK = 'TOP_OF_UI_STACK'
export const SIDE_MENU = 'SIDE_MENU'

export function SideMenuState(open) {
  return {type: SIDE_MENU, open}
}

export function ComponentIsOnTop(component_id, component_name) {
  return {type: TOP_OF_UI_STACK, component_id, component_name}
}
