export enum WaitListVariant {
  LOAN,
  CARD_ENABLED_SUPPORTED_STATE,
  CARD_ENABLED_UNSUPPORTED_STATE,
}

export const getWaitListVariant = (
  isUnsupportedState: boolean,
  isCardsUser: boolean,
): WaitListVariant => {
  if (isCardsUser && !isUnsupportedState) {
    return WaitListVariant.CARD_ENABLED_SUPPORTED_STATE
  } else if (isCardsUser && isUnsupportedState) {
    return WaitListVariant.CARD_ENABLED_UNSUPPORTED_STATE
  }
  return WaitListVariant.LOAN
}
