import {ImageCapture} from 'src/products/general/UniversalCamera/UniversalCamera.types'

export const jpegContentType = 'image/jpeg'

export const openImageUriAsFile = async (
  dataUri: string,
  fileName = 'data.jpg',
  contentType = jpegContentType,
): Promise<File> => {
  // Convert the dataUrl to a blob
  const blob = await (await fetch(dataUri)).blob()

  return new File([blob], fileName, {
    type: contentType,
  })
}

export const getImageUri = (imageInfo: ImageCapture): string => {
  switch (imageInfo.source) {
    case 'mobile':
      return imageInfo.uri
    case 'web':
      return imageInfo.dataUri
  }
}

export const getImageFileName = (imageInfo: ImageCapture): string => {
  switch (imageInfo.source) {
    case 'mobile':
      return imageInfo.fileName
    case 'web':
      return imageInfo.file.name
  }
}
