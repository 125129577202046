import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'

type PhoneNumberAlreadyVerifiedTemplateProps = {
  onPhoneAlreadyVerified: () => Promise<void>
  isActionDisabled: boolean
}

const PhoneNumberAlreadyVerifiedTemplate: FC<PhoneNumberAlreadyVerifiedTemplateProps> = (
  props,
): JSX.Element => {
  const {onPhoneAlreadyVerified, isActionDisabled} = props
  const {t} = useTranslation('PhoneNumberEntry')

  const action = {
    onPress: onPhoneAlreadyVerified,
    text: t('Common:Continue'),
    disabled: isActionDisabled,
  }

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      testID={'PhoneConfirmationPage'}
      buttonProps={buttonLockupProperties(action)}
      title={t('YourPhoneNumberHasBeenVerified')}
    />
  )
}

export {PhoneNumberAlreadyVerifiedTemplate}
