import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {logOfferApplicationErrorAndShowException} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import PersonalInfoConfirmationGQLContainer from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmationGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

type PersonalInfoConfirmationWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to integrate the PersonalInfoConfirmation screen with Workflows.
 */
const PersonalInfoConfirmationWorkflowContainer: React.FC<
  PersonalInfoConfirmationWorkflowContainerProps
> = (props: PersonalInfoConfirmationWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_confirm_page_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  const onConfirm = async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.personal_details_confirm_page_completed,
        AppEvents.Category.InitialApplication,
      )
      await onPreReqFulfilled()
    } catch (e) {
      logOfferApplicationErrorAndShowException(
        e,
        'Error while reviewing application and confirming personal information in Workflows',
      )
    }
  }
  return <PersonalInfoConfirmationGQLContainer onConfirm={onConfirm} />
}

export default PersonalInfoConfirmationWorkflowContainer
