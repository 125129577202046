import React from 'react'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import LoanProductIntroductionTemplate from 'src/products/loans/LoanProductIntroduction/LoanProductIntroductionTemplate'

type LoanProductIntroductionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use LoanProductIntroduction within a Workflows flow.
 */
const LoanProductIntroductionWorkflowContainer: React.FC<
  LoanProductIntroductionWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props
  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_product_intro_page_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  return <LoanProductIntroductionTemplate onContinue={handleOnContinue} />
}

export {LoanProductIntroductionWorkflowContainer}
