import {StackScreenProps} from '@react-navigation/stack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {PhoneNumberInputVerificationTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationTemplate'
import * as Public from '@possible/cassandra/src/types/types.public.generated'
import {
  userForgotPasswordSendCode,
  userValidateVerificationCode,
} from '@possible/cassandra/src/user/authPublicMethods'
import {ShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ampli} from 'src/lib/Analytics/ampli'
import Log from 'src/lib/loggingUtil'
import {formatPhoneNumber} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.utils'
import {AccountDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'

type Props = StackScreenProps<MainStackParamList, 'PhoneConfirmation'>

const PhoneNumberInputVerificationForgotPasswordContainer: React.FC<Props> = (props) => {
  const {navigation, route} = props
  const {t} = useTranslation(['PhoneConfirmation', 'PhoneNumberEntry', 'Common'])
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false)
  const [shouldShowVerificationCode, setShouldShowVerificationCode] = useState<boolean>(false)

  const failedToSendCodeErrorMessage = 'Failed to send forgot password code'

  const handleSendForgotPasswordCode = async (phoneNumber: string): Promise<void> => {
    setIsSendingVerificationCode(true)

    try {
      const input: Public.UserForgotPasswordSendCodeInput = {
        usernameType: Public.AccountUsernameType.Phone,
        username: formatPhoneNumber(phoneNumber),
        deliveryMedium: Public.AccountDeliveryMedium.Sms,
      }
      const wasCodeSent: boolean | undefined = await userForgotPasswordSendCode(input)
      if (!wasCodeSent) {
        throw Error(failedToSendCodeErrorMessage)
      }

      setShouldShowVerificationCode(true)
    } catch (e) {
      ShowException(e)
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleSubmitVerificationCode = async (code: string, phoneNumber: string): Promise<void> => {
    try {
      TrackAppEvent(AppEvents.Name.enter_verification_code_submitted, AppEvents.Category.Admin)

      try {
        ampli.registrationVerificationCompleted()
      } catch (e) {
        Log.warn('Failed to send registration verification completed event', e)
      }

      setIsSendingVerificationCode(true)

      const input: Public.UserValidateVerificationCodeInput = {
        usernameType: Public.AccountUsernameType.Phone,
        username: formatPhoneNumber(phoneNumber),
        verificationCode: code,
      }
      const resp: Public.UserValidateVerificationCodeResponse | undefined =
        await userValidateVerificationCode(input)
      if (!resp?.obfuscatedEmail) {
        throw Error('Failed to validate verification code')
      }
      const obfuscatedEmail: string | undefined = resp.obfuscatedEmail

      PushPage(navigation, 'CreatePasswordLoggedOut', {
        code,
        username: formatPhoneNumber(phoneNumber),
        obfuscatedEmail: obfuscatedEmail,
      })
    } catch (e) {
      ShowException(e)
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleOnEditPhoneNumber = (): void => {
    setShouldShowVerificationCode(false)
  }

  const handleResendCode = async (
    method: AccountDeliveryMedium,
    phoneNumber: string,
  ): Promise<void> => {
    TrackAppEvent(
      method === AccountDeliveryMedium.Sms
        ? AppEvents.Name.enter_verification_code_resend_selected
        : AppEvents.Name.enter_verification_code_phone_call_selected,
      AppEvents.Category.Checkout,
    )
    try {
      setIsSendingVerificationCode(true)
      const input: Public.UserForgotPasswordSendCodeInput = {
        usernameType: Public.AccountUsernameType.Phone,
        username: formatPhoneNumber(phoneNumber),
        deliveryMedium:
          method === AccountDeliveryMedium.Sms
            ? AccountDeliveryMedium.Sms
            : AccountDeliveryMedium.Voice,
      }
      const wasSuccessful: boolean | undefined = await userForgotPasswordSendCode(input)
      if (!wasSuccessful) {
        throw Error(failedToSendCodeErrorMessage)
      }
    } catch (e) {
      ShowException(e)
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  return (
    <PhoneNumberInputVerificationTemplate
      testID={'Phone_Confirmation_Account_Recovery_Page'}
      onButtonPress={handleSendForgotPasswordCode}
      mainTitle={t('Common:ForgotYourPassword')}
      mainBody={t('CellPhoneWillBeUsedForAccountVerification')}
      navigation={navigation}
      route={route}
      isActionDisabled={isSendingVerificationCode}
      shouldShowVerificationCode={shouldShowVerificationCode}
      shouldShowNoPhoneAction={true}
      onSubmitVerificationCode={handleSubmitVerificationCode}
      onEditPhoneNumber={handleOnEditPhoneNumber}
      onResendMyCodeAction={handleResendCode}
    />
  )
}

export {PhoneNumberInputVerificationForgotPasswordContainer}
