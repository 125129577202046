import React, {FC, RefObject} from 'react'
import {View, ViewStyle} from 'react-native'
import {Controller, ValidationRules, FieldErrors, Control} from 'react-hook-form'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'

//___________________________steps to use form component_______________________________________
//1. create the form data type in your component
//    eg. type FormData = {
//           firstName: string
//           lastName: string
//        }
//2. set up the form hook in your component with the FormData type
//    eg. const {control, handleSubmit, errors} = useForm<FormData>({mode: 'all'})
//    NOTE: the default validation behavior is only onSubmit if you don't add the option argument as above
//3. wrap your input fields in the form component passing the control and errors props
//4. pass the formFieldProps to each input field as a formProps object
//4. wrap the submit button function in handleSubmit

//_____________________steps to add new input field type_______________________________________
//1. add to FIELD_VARIANT enum
//2. update switch in getInputField below to add necessary props to the rendered input
// ^^ at a minimum this needs to call the render prop onChange function with the form value when it changes
//3. add formFieldProps to prop types of the input field

export type formFieldProps = {
  displayErrors?: boolean
  field: FIELD_VARIANTS
  name: string
  rules?: ValidationRules
  viewStyle?: ViewStyle
  ref?: RefObject<any>
}

export enum FIELD_VARIANTS {
  DATEPICKER = 'datepicker',
  DROPDOWN = 'dropdown',
  TEXT_FIELD = 'textField',
}

export const DefaultFormGapSizeVariant = 'little'

type HookFormPropTypes = React.PropsWithChildren & {
  control: Control
  errors: FieldErrors
  box?: BoxProps
  noBox?: boolean
}

const getErrorMessage = (errors: FieldErrors, name: string): string => {
  return errors[name]?.message
}

const getDisplayError = (
  display: boolean,
  errors: FieldErrors,
  name: string,
): JSX.Element | undefined => {
  if (!display) return undefined
  return (
    <PFText color={'error'} variant={'p_sm'}>
      {getErrorMessage(errors, name)}
    </PFText>
  )
}

const HookForm: FC<HookFormPropTypes> = (props) => {
  const {control, errors, box, noBox, children} = props

  const getInputField = (renderProps, formProps, childProps, type) => {
    const {value, onChange} = renderProps
    const {field, name, ref} = formProps
    const elementProps = {
      ...childProps,
      value,
      ref,
    }
    const onChangeWithFilter = (text: string) => {
      const {changeFilter} = elementProps
      return changeFilter ? changeFilter(text) : text
    }

    const error = getErrorMessage(errors, name)

    switch (field) {
      case FIELD_VARIANTS.TEXT_FIELD:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
        elementProps.onChange = (e): void => onChange(onChangeWithFilter(e.nativeEvent.text))
        elementProps.error = error
        break
      case FIELD_VARIANTS.DROPDOWN:
        elementProps.onSelection = (val) => onChange(val)
        elementProps.error = error
        break
      case FIELD_VARIANTS.DATEPICKER:
        elementProps.onChange = (val) => onChange(val)
        elementProps.error = error
        break
      default:
        elementProps.onChange = (e) => onChange(e.nativeEvent.text)
    }

    return React.createElement(type, {...elementProps})
  }

  const wrapChildInController = (child) => {
    const {formProps, ...childProps} = child.props
    const {name, rules, displayErrors, viewStyle} = formProps
    return (
      <View key={name} style={[{width: '100%'}, viewStyle]}>
        <Controller
          control={control}
          render={(renderProps) => getInputField(renderProps, formProps, childProps, child.type)}
          name={name}
          rules={{...rules}}
          defaultValue={control.defaultValuesRef?.current?.[name] ?? ''}
        />
        {getDisplayError(displayErrors, errors, name)}
      </View>
    )
  }

  const wrapRecursive = (nodeChildren: React.ReactNode): React.ReactNode => {
    return React.Children.toArray(nodeChildren).map((child) => {
      if (React.isValidElement(child)) {
        if (child?.props?.formProps?.name) {
          return wrapChildInController(child)
        } else if (child?.props?.children) {
          return React.cloneElement(child, child.props, wrapRecursive(child.props.children))
        }
      }
      return child
    })
  }

  const formInputs = wrapRecursive(children)

  //for the box, we want to default to using a box with little gap (8px)
  //but if you explicitly pass box props we should override with those values
  //and if you specify noBox then we will not use the gap at all
  const boxProps: BoxProps = {
    gap: DefaultFormGapSizeVariant,
    ...box,
  }

  return noBox ? <View>{formInputs}</View> : <Box {...boxProps}>{formInputs}</Box>
}

export default HookForm
