import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {ModuleControlProps} from 'src/flows/types'
import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {userIdSelector} from 'src/lib/user/selector'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanAmountSelectionRESTContainer} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionRESTContainer'
import {usePfSelector} from 'src/store/utils'

export type LoanAmountSelectionMPOContainerProps = ModuleControlProps &
  StackScreenProps<MainStackParamList, 'LoanAmountSelection'>

/**
 * Container to use LoanAmountSelection within an MPO flow.
 */
const LoanAmountSelectionMPOContainer: React.FC<LoanAmountSelectionMPOContainerProps> = (
  props: LoanAmountSelectionMPOContainerProps,
) => {
  const {moduleName, onModuleComplete, onStepComplete, navigation} = props
  useMPOAndroidToWeb(moduleName)

  const marketingSurveySrcQuery = useUserProperty('mkt_chl_src')
  const wasLoanMarketingSurveyViewed: boolean = !!marketingSurveySrcQuery.value
  const userId: string | undefined = usePfSelector(userIdSelector)

  /**
   * When user finishes selecting their loan amount we send them to the
   * next MPO step or module accordingly.
   */
  const handleComplete = useCallback(async (): Promise<void> => {
    if (!onModuleComplete || !onStepComplete) {
      const err = new Error(
        `Unable to continue with loan amount selection, couldn't determine next module`,
      )
      Log.error(err)
      throw err
    }
    try {
      if (wasLoanMarketingSurveyViewed) {
        await onModuleComplete()
      } else {
        await onStepComplete({
          userId,
        })
      }
    } catch (e) {
      void logErrorAndShowException(
        e,
        'LoanAmountSelectionMPOContainer failed to send to next MPO module/step',
      )
      throw e
    }
  }, [onModuleComplete, onStepComplete, userId, wasLoanMarketingSurveyViewed])

  return (
    <LoanAmountSelectionRESTContainer
      navigation={navigation}
      onComplete={(): void => void handleComplete()}
    />
  )
}

export {LoanAmountSelectionMPOContainer}
