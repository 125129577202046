import {Alert} from 'react-native'

import {
  unsupportedInstitutionsOnlineBanksFallback,
  unsupportedInstitutionsPossibleFallback,
} from 'src/lib/RemoteConfig/properties'
import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'

const addPlaidLayersIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - [Plaid Layers]`)
  modifiedError.stack = e.stack
  return modifiedError
}

const formatError = (e: unknown): Error => {
  let finalError: Error
  if (e instanceof Error) {
    finalError = e
  } else {
    try {
      finalError = new Error(JSON.stringify(e))
    } catch (e) {
      finalError = new Error(String(e))
    }
  }
  return addPlaidLayersIdentifierToError(finalError)
}

/**
 * Log errors related to Plaid Layers. Includes standardized log prefix.
 */
export const logPlaidLayerError = (e: unknown, msg?: string): void => {
  const finalError = formatError(e)
  Log.error(finalError, msg)
}

/**
 * Log errors related to Plaid Layers and show an exception. Includes standardized log prefix.
 */
export const logPlaidLayersAndShowException = (e: unknown, msg?: string): void => {
  const finalError = formatError(e)
  void logErrorAndShowException(finalError, msg)
}

/**
 * Verify if the institution is supported and show an alert if it's not.
 * @param institutionId
 * @returns
 */
export const sendAlertIfUnsupportedInstitution = (institutionId: string | undefined): void => {
  const unsupportedBankOrInstitution =
    unsupportedInstitutionsOnlineBanksFallback.find(
      (institution) => institution.id === institutionId,
    ) ||
    unsupportedInstitutionsPossibleFallback.find((institution) => institution.id === institutionId)

  if (!unsupportedBankOrInstitution) {
    return
  }

  Alert.alert(
    `${unsupportedBankOrInstitution?.name} isn't supported yet`,
    `Try a different bank to continue.`,
  )
}
