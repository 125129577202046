/* eslint-disable @typescript-eslint/require-await */
import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback} from 'react'

import {
  AddressCollectionTemplate,
  AddressCompletionMethod,
} from 'src/products/MCU/Address/AddressCollectionTemplate'
import {
  stateMismatch,
  supportedState,
  useUpdateUserAddress,
} from 'src/products/MCU/Address/address.utils'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {getUserStateAbv} from 'src/lib/loans/selector'
import {UI_UPDATE} from 'src/lib/ui/actions'
import {userIdSelector} from 'src/lib/user/selector'
import {GetFullStateFromAbv} from 'src/lib/us_states'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanFlowAddress} from 'src/navigation/LoanFlow'
import {PushPage} from 'src/navigation/NavHelper'
import NavPageState from 'src/navigation/NavPageState'
import {usePfDispatch, usePfSelector} from 'src/store/utils'

type Props = StackScreenProps<MainStackParamList, 'AddressCollection'>

const AddressLegacyContainer: React.FC<Props> = (props) => {
  const {navigation} = props

  const [updateUserAddress] = useUpdateUserAddress(Consumer.types.AddressType.Home)

  const selectedState = usePfSelector(getUserStateAbv)
  const abEnabledStates = usePfSelector(abFlaggedStatesEnabled)
  const userId = usePfSelector(userIdSelector)

  const dispatch = usePfDispatch()

  const onProofComplete = useCallback(
    (address: Consumer.types.Address) => {
      dispatch({type: UI_UPDATE, state: {userInfoChanges: address}})
      navigation?.pop(2)
      NavPageState.NavToNextPage(LoanFlowAddress, navigation)
    },
    [navigation, dispatch],
  )

  const porNeededSubmit = useCallback(
    (porState: string) => {
      PushPage(navigation, 'ProofOfResidence', {
        state: porState,
        selectedState: selectedState,
        onProofComplete: (address: Consumer.types.Address) => onProofComplete(address),
      })
    },
    [onProofComplete, navigation, selectedState],
  )

  const unsupportedStateSubmit = useCallback(
    (userState) => {
      const stateFull = GetFullStateFromAbv(userState)
      PushPage(navigation, 'OutOfState', {
        state: stateFull,
        userId,
      })
    },
    [navigation, userId],
  )

  const handleComplete: AddressCompletionMethod = useCallback(
    async (address, userState) => {
      if (stateMismatch(userState, selectedState)) {
        if (!supportedState(userState, abEnabledStates)) {
          unsupportedStateSubmit(userState)
        } else {
          porNeededSubmit(userState)
        }
      } else {
        NavPageState.NavToNextPage(LoanFlowAddress, navigation)
      }
    },
    [abEnabledStates, porNeededSubmit, navigation, selectedState, unsupportedStateSubmit],
  )

  return <AddressCollectionTemplate updateAddress={updateUserAddress} onComplete={handleComplete} />
}

export {AddressLegacyContainer}
