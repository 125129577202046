import React, {FC, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useLinkDebitPaymentMethod} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForCardProduct/useLinkDebitPaymentMethod'
import {getErrorMessage} from 'src/products/card/PaymentMethods/BankNumbersForm'
import DebitCardAddedModal from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForCardProduct/DebitCardAddedModal'
import {DebitCardTemplate} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCardTemplate'
import {DebitCardSubmitValues} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/DebitCard.utils'

type Props = StackScreenProps<
  MainStackParamList,
  'CollectDebitCardNumbersForCardProduct' & 'DebitCard'
>

const CollectDebitCardNumbersForCardProduct: FC<Props> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_add_debit_card_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const [isDebitCardAddedModalVisible, setIsDebitCardAddedModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const linkDebitPaymentMethod = useLinkDebitPaymentMethod()

  const handleOnSubmit = async ({
    number,
    expiration,
    cvv,
  }: DebitCardSubmitValues): Promise<void> => {
    TrackAppEvent(CardEvents.card_add_debit_card_page_on_submit_cta, AppEvents.Category.Card)
    setIsLoading(true)

    try {
      await linkDebitPaymentMethod({
        cardNumber: number,
        cardExp: expiration,
        cardCvv: cvv,
      })

      setIsDebitCardAddedModalVisible(true)
    } catch (e) {
      snackbarErrorMessage(getErrorMessage(e))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <DebitCardTemplate
        {...props}
        hiddenFields={['name']}
        loading={isLoading}
        onSubmit={handleOnSubmit}
      />
      <DebitCardAddedModal
        visible={isDebitCardAddedModalVisible}
        onDismiss={(): void => {
          navigation.goBack()
          setIsDebitCardAddedModalVisible(false)
        }}
      />
    </>
  )
}

export {CollectDebitCardNumbersForCardProduct}
