import {ApolloQueryResult} from '@apollo/client'
import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useEffect, useState} from 'react'

import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ApplicationActivationWorkflowStackNav} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.consts'
import {UseApprovedApplicationWithOfferIdReadyLoanType} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.types'
import {ApplicationActivationWorkflowRouteNames} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflowDescriptor'
import {ApprovedApplicationsQuery} from 'src/products/general/ApplicationActivationWorkflow/ApprovedApplications.gqls'
import {
  getScreenComponentFromUXConfig,
  handleOnAllLoanActivationPreReqsMet,
} from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanActivationWorkflow.utils'
import {
  LoanActivationWorkflowUXConfig,
  getLoanActivationWorkflowUXConfig,
} from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanActivationWorkflowUXConfig'
import {WorkflowDebugPanel} from 'src/products/general/Workflows/WorkflowDebugPanel'
import {WorkflowLoading} from 'src/products/general/Workflows/WorkflowLoading'
import {useWorkflowsSort} from 'src/workflows/experimentation'
import {useMoveToNextPreReqRoute} from 'src/workflows/hooks/useMoveToNextPreReqRoute'
import {useWFLogging} from 'src/workflows/logging'
import {
  GetApplicationActivationWorkflowNavigationOptions,
  useSetupRouteHistory,
} from 'src/workflows/navigation'
import {
  PreReqType,
  WorkflowPreReqFulfillScreenProps,
  WorkflowsStackParams,
} from 'src/workflows/types'
import AppEvents from 'src/lib/Analytics/app_events'

/**
 * Selector to get the met and unmet prereqs for a loan from the ApprovedApplicationsQuery.
 */
const LoanApprovedAggregateStatusPreReqSelectorFactory =
  (loanId: string) =>
  (
    result: ApolloQueryResult<ApprovedApplicationsQuery>,
  ): {met: PreReqType[]; unmet: PreReqType[]} => {
    for (const app of result.data.me.products.applications.all) {
      const {product} = app
      if (product.__typename === 'Loan' && product.id === loanId) {
        const {aggregateStatus} = product
        if (aggregateStatus.__typename === 'ApprovedLoanAggregateStatus') {
          return {
            met: aggregateStatus.activationPreReqs.met,
            unmet: aggregateStatus.activationPreReqs.unmet,
          }
        }
      }
    }

    // the app is probably active now
    return {
      met: [],
      unmet: [],
    }
  }

/**
 * Nav stack for the loan activation workflow.
 */
export const LoanApplicationActivationWorkflow: React.FC<
  StackScreenProps<MainStackParamList, 'ApplicationActivationWorkflow'> &
    UseApprovedApplicationWithOfferIdReadyLoanType
> = (props) => {
  const {navigation, loan, refetch} = props
  const log = useWFLogging('LoanApplicationActivationWorkflow')

  const [workflowNavigation, setWorkflowNavigation] =
    useState<StackNavigationProp<WorkflowsStackParams>>()
  if (loan.aggregateStatus.__typename !== 'ApprovedLoanAggregateStatus') {
    const outError = new Error(
      'Arrived at LoanApplicationActivationWorkflow with a loan that is not approved',
    )
    // should be caught by our error boundary
    log.error(outError)
    throw outError
  }
  const stateCode = loan.state

  const uxConfig: LoanActivationWorkflowUXConfig = getLoanActivationWorkflowUXConfig({stateCode})

  const moveToNextPreReqRoute = useMoveToNextPreReqRoute({
    met: loan.aggregateStatus.activationPreReqs.met,
    unmet: loan.aggregateStatus.activationPreReqs.unmet,
  })

  useWorkflowsSort({
    selectedOffer: undefined,
    unmetPreReqs: loan.aggregateStatus.activationPreReqs.unmet,
    preReqSortSelector: () => uxConfig.preReqSortOrderFn,
    passthrough: undefined,
  })

  useEffect(() => {
    log.debug(`LoanApplicationActivationWorkflow using UX config for stateCode="${stateCode}"`)
  }, [stateCode, log])

  useSetupRouteHistory({
    navigation: workflowNavigation,
    unmetPreReqs: loan.aggregateStatus.activationPreReqs.unmet,
    metPreReqs: loan.aggregateStatus.activationPreReqs.met,
  })

  useEffect(() => {
    if (loan.aggregateStatus.__typename === 'ApprovedLoanAggregateStatus') {
      log.debug('Loan activation prereqs updated', {
        met: loan.aggregateStatus.activationPreReqs.met,
        unmet: loan.aggregateStatus.activationPreReqs.unmet,
      })
    }
  }, [
    loan.aggregateStatus.activationPreReqs.met,
    loan.aggregateStatus.activationPreReqs.unmet,
    loan.aggregateStatus.__typename,
    log,
  ])
  const handleOnPreReqFulfilled = useCallback(async () => {
    await moveToNextPreReqRoute({
      navigation,
      preReqSelector: LoanApprovedAggregateStatusPreReqSelectorFactory(loan.id),
      refetch,
      onAllPreReqsMet: async () => {
        log.log('All loan activation prereqs met, calling handleOnAllLoanActivationPreReqsMet()')
        try {
          await handleOnAllLoanActivationPreReqsMet({
            mainNavigation: navigation,
            workflowNavigation,
            logger: log,
          })
        } catch (e) {
          workflowNavigation?.navigate('PostApplicationActivationError', {
            error: e instanceof Error ? e : new Error(String(e)),
          })
        }
        return true
      },
    })
  }, [moveToNextPreReqRoute, navigation, loan.id, refetch, log, workflowNavigation])

  const handleOnNavigationLoaded = useCallback((nav: StackNavigationProp<WorkflowsStackParams>) => {
    setWorkflowNavigation(nav)
  }, [])

  return (
    <>
      <ApplicationActivationWorkflowStackNav.Navigator
        // start at Loading route, it will immediately nav once handleOnNavigationLoaded() is called
        initialRouteName="Loading"
        screenOptions={{
          gestureEnabled: true,
          headerTransparent: true,
          headerShadowVisible: false,
          headerBackgroundContainerStyle: {
            backgroundColor: 'white',
          },
        }}
      >
        <ApplicationActivationWorkflowStackNav.Screen
          name="Loading"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <WorkflowLoading {...screenProps} onNavigationLoaded={handleOnNavigationLoaded} />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        {/*
         *  If the user finished the workflow and attempted to activate their loan application but
         *  something went wrong we will send them here and let them retry navigating to the next screen.
         */}
        <ApplicationActivationWorkflowStackNav.Screen
          name="PostApplicationActivationError"
          options={GetApplicationActivationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <ErrorTemplate
              analyticName={AppEvents.Name.application_activation_workflow_post_error_viewed}
              error={screenProps.route.params?.error}
              onRetry={(): void => {
                void handleOnAllLoanActivationPreReqsMet({
                  mainNavigation: navigation,
                  workflowNavigation,
                  logger: log,
                })
              }}
            />
          )}
        </ApplicationActivationWorkflowStackNav.Screen>
        {
          /**
           * Generate each route and screen from the UX config. This is dynamically done because
           * the UX differs depending on the user's scenario.
           */
          ApplicationActivationWorkflowRouteNames.map((routeName) => {
            return (
              <ApplicationActivationWorkflowStackNav.Screen
                name={routeName}
                options={GetApplicationActivationWorkflowNavigationOptions}
                key={`${routeName}-${stateCode}`}
              >
                {(): React.ReactNode => {
                  if (loan.aggregateStatus.__typename !== 'ApprovedLoanAggregateStatus') {
                    throw new Error(
                      'Invalid active loan status for LoanApplicationActivationWorkflow when generating screen components',
                    )
                  }
                  // get the screen component for this route from the UX config
                  const RouteScreen: React.FC<WorkflowPreReqFulfillScreenProps> =
                    getScreenComponentFromUXConfig({
                      uxConfig,
                      routeName,
                      stateCode,
                      metPreReqs: loan.aggregateStatus.activationPreReqs.met,
                      unmetPreReqs: loan.aggregateStatus.activationPreReqs.unmet,
                      logger: log,
                    })
                  return <RouteScreen onPreReqFulfilled={handleOnPreReqFulfilled} />
                }}
              </ApplicationActivationWorkflowStackNav.Screen>
            )
          })
        }
      </ApplicationActivationWorkflowStackNav.Navigator>

      <WorkflowDebugPanel title="Loan Activation Workflow Debug" />
    </>
  )
}
