require('setimmediate')
import 'react-native-gesture-handler'
import React from 'react'
import ReactDOM from 'react-dom/client'

import {rootTagNameWeb} from 'src/lib/utils/platform'
import AppWrapper from 'src/AppWrapper'

const root = ReactDOM.createRoot(document.getElementById(rootTagNameWeb) as HTMLElement)

root.render(<AppWrapper />)
