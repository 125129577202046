import {
  LoanAcceptanceSteps,
  ModuleDefinitionMultiDirectedNode,
  OnboardingModuleDefinitions,
  OnboardingParamList,
} from 'src/flows/types'
import {
  disbursementMethodSelectedSelector,
  latestLoanSelector,
  latestLoanTypeSelector,
  repeatLoanSelector,
} from 'src/lib/loans/selector'
import {getLoanStatus, isLoanReplacementApproved} from 'src/lib/loans/utils'
import {getPfStore} from 'src/store'
import {HasAccountAndRouting} from 'src/lib/bank_account_utils'
import {ModuleMultiNodeDirector} from 'src/flows/modules/types'
import {preferredAccount, preferredAccountInterchangeSelector} from 'src/lib/user/accountsSelector'
import {regionCodes, transferMethods} from 'src/lib/loans/consts'

import {AcceptACH} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptACH/AcceptACH'
import {AcceptAgreementsWAInstallment} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/WAInstallment/AcceptAgreementsWAInstallment'
import {CollectDebitCardNumbersForLoanMPOContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanMPOContainer'
import {ConfirmBankAccountMPOContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountMPOContainer'
import {ConfirmDebitCardMPOContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardMPOContainer'
import {LoanFinalAcceptMPOContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptMPOContainer'
import {HowPaymentsWorkMPOContainer} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkMPOContainer'
import {LoanPaymentReviewMPOContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewMPOContainer'
import {LoanDisbursementMethodSelectionMPOContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionMPOContainer'
import {LoanTilaDisclosureMPOContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureMPOContainer'
import {ReasonSurveyMPOContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyMPOContainer'
import {UpgradeToInstallmentPlanMPOContainer} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlanMPOContainer'
import AcceptAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements'
import {PaymentReviewTilaAndLoanAgreementCAMPOContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCAMPOContainer'
import {StateDisclosureMPOContainer} from 'src/products/loans/StateDisclosure/StateDisclosureMPOContainer'

export type LoanAcceptanceStepDefinitions = ModuleDefinitionMultiDirectedNode<
  OnboardingParamList,
  keyof Pick<OnboardingParamList, Extract<keyof LoanAcceptanceSteps, string>>
>['steps']

type LoanAcceptanceStepDirector = ModuleMultiNodeDirector<
  OnboardingParamList,
  keyof Pick<OnboardingParamList, Extract<keyof LoanAcceptanceSteps, string>>
>

const regionsRequiringSeparateAch = new Set([regionCodes.Florida, regionCodes.Texas])
const regionsWithConvertableLoans = new Set([regionCodes.California])
const regionsWithStateDisclosure = new Set([regionCodes.Ohio, regionCodes.Louisiana])

function regionCheck(state, regions) {
  const loanType = latestLoanTypeSelector(state)
  return regions.has(loanType?.regionCode)
}

function regionRequiresSeparateAch(state) {
  return regionCheck(state, regionsRequiringSeparateAch)
}

function regionRequiresStateDisclosure(state) {
  return regionCheck(state, regionsWithStateDisclosure)
}

const loanAcceptanceDefaultNextSteps: Partial<
  Record<keyof LoanAcceptanceSteps, LoanAcceptanceStepDirector>
> = {
  LOAN_PAYMENT_REVIEW: () => {
    const state = getPfStore().getState()
    const loan = latestLoanSelector(state)
    const paymentMethods = loan?.methods ?? []
    if (
      paymentMethods.find((method) => {
        return method.direction === 'to' && method.network === transferMethods.interchange
      })
    ) {
      return 'LOAN_DISBURSEMENT_METHOD'
    }

    return 'LOAN_TILA_DISCLOSURE'
  },
  LOAN_DISBURSEMENT_METHOD: () => {
    const state = getPfStore().getState()
    const disbursementMethod = disbursementMethodSelectedSelector(state)
    if (disbursementMethod === transferMethods.interchange) {
      if (repeatLoanSelector(state) && preferredAccountInterchangeSelector(state)) {
        return 'LOAN_CONFIRM_DEBIT_CARD'
      }

      return {name: 'LOAN_COLLECT_DEBIT_CARD_NUMBERS', params: {}}
    }

    return 'LOAN_TILA_DISCLOSURE'
  },
  LOAN_CONFIRM_DEBIT_CARD: () => {
    return 'LOAN_TILA_DISCLOSURE'
  },
  LOAN_COLLECT_DEBIT_CARD_NUMBERS: () => {
    return 'LOAN_TILA_DISCLOSURE'
  },
  LOAN_TILA_DISCLOSURE: () => {
    return 'LOAN_HOW_PAYMENTS_WORK'
  },
  LOAN_HOW_PAYMENTS_WORK: () => {
    return 'LOAN_ACCEPT_AGREEMENTS'
  },
  LOAN_ACCEPT_AGREEMENTS: () => {
    const state = getPfStore().getState()
    if (regionRequiresSeparateAch(state)) {
      return 'LOAN_ACCEPT_ACH'
    }
    if (regionRequiresStateDisclosure(state)) {
      return 'LOAN_STATE_DISCLOSURE'
    }
    if (!HasAccountAndRouting(preferredAccount(state))) {
      return 'LOAN_CONFIRM_BANK'
    }

    return 'LOAN_REASON_SURVEY'
  },
  LOAN_STATE_DISCLOSURE: () => {
    const state = getPfStore().getState()
    if (!HasAccountAndRouting(preferredAccount(state))) {
      return 'LOAN_CONFIRM_BANK'
    }

    return 'LOAN_REASON_SURVEY'
  },
  LOAN_ACCEPT_ACH: () =>
    loanAcceptanceDefaultNextSteps['LOAN_STATE_DISCLOSURE']!('LOAN_ACCEPT_ACH'),
  LOAN_CONFIRM_BANK: () => 'LOAN_REASON_SURVEY',
  LOAN_REASON_SURVEY: () => 'LOAN_FINAL_ACCEPT',
}

const loanAcceptanceSingleToMultiPaymentNextSteps: Partial<
  Record<keyof LoanAcceptanceSteps, LoanAcceptanceStepDirector>
> = {
  LOAN_DISBURSEMENT_METHOD: () => {
    const state = getPfStore().getState()
    const getDisbursementMethod = disbursementMethodSelectedSelector(state)
    if (getDisbursementMethod === transferMethods.interchange) {
      if (repeatLoanSelector(state) && preferredAccountInterchangeSelector(state)) {
        return 'LOAN_CONFIRM_DEBIT_CARD'
      }

      return {name: 'LOAN_COLLECT_DEBIT_CARD_NUMBERS', params: {isInLoanApprovalFlow: true}}
    }

    return 'LOAN_HOW_PAYMENTS_WORK'
  },

  LOAN_CONFIRM_DEBIT_CARD: () => {
    const state = getPfStore().getState()
    if (!HasAccountAndRouting(preferredAccount(state))) {
      return 'LOAN_CONFIRM_BANK'
    }

    return 'LOAN_HOW_PAYMENTS_WORK'
  },

  LOAN_COLLECT_DEBIT_CARD_NUMBERS: () =>
    loanAcceptanceSingleToMultiPaymentNextSteps['LOAN_CONFIRM_DEBIT_CARD']?.(
      'LOAN_COLLECT_DEBIT_CARD_NUMBERS',
    ),
  LOAN_CONFIRM_BANK: () => 'LOAN_ACCEPT_ACH',

  LOAN_HOW_PAYMENTS_WORK: () => {
    const state = getPfStore().getState()
    if (!HasAccountAndRouting(preferredAccount(state))) {
      return 'LOAN_CONFIRM_BANK'
    }

    return 'LOAN_ACCEPT_ACH'
  },

  LOAN_ACCEPT_ACH: () => 'LOAN_REASON_SURVEY',
  LOAN_REASON_SURVEY: () => 'LOAN_TILA_AND_PAYMENT_REVIEW',
  LOAN_TILA_AND_PAYMENT_REVIEW: () => ({
    name: 'LOAN_UPGRADE_TO_INSTALLMENT',
    params: {firstOffer: true},
  }),
  LOAN_UPGRADE_TO_INSTALLMENT: () => undefined,
}

const loanAcceptanceWAExtendedInstallmentNextSteps: Partial<
  Record<keyof LoanAcceptanceSteps, LoanAcceptanceStepDirector>
> = {
  LOAN_PAYMENT_REVIEW: () => 'LOAN_TILA_DISCLOSURE',
  LOAN_TILA_DISCLOSURE: () => 'LOAN_ACCEPT_AGREEMENTS_WA_INSTALLMENT',
  LOAN_ACCEPT_AGREEMENTS_WA_INSTALLMENT: () => 'LOAN_FINAL_ACCEPT_WA_INSTALLMENT',
  LOAN_FINAL_ACCEPT_WA_INSTALLMENT: () => undefined,
}

const loanAcceptanceModuleSteps: Partial<LoanAcceptanceStepDefinitions> = {
  LOAN_PAYMENT_REVIEW: {
    screen: {
      path: 'loan/review',
    },
    Component: LoanPaymentReviewMPOContainer,
  },
  LOAN_DISBURSEMENT_METHOD: {
    screen: {
      path: 'loan/method',
    },
    Component: LoanDisbursementMethodSelectionMPOContainer,
  },
  LOAN_COLLECT_DEBIT_CARD_NUMBERS: {
    screen: {
      path: 'loan/card-info',
    },
    Component: CollectDebitCardNumbersForLoanMPOContainer,
  },
  LOAN_CONFIRM_DEBIT_CARD: {
    screen: {
      path: 'loan/confirm-card-info',
    },
    Component: ConfirmDebitCardMPOContainer,
  },
  LOAN_HOW_PAYMENTS_WORK: {
    screen: {
      path: 'loan/how-payments-work',
    },
    Component: HowPaymentsWorkMPOContainer,
    options: {
      headerBackgroundContainerStyle: {
        backgroundColor: 'transparent',
      },
    },
  },
  LOAN_TILA_DISCLOSURE: {
    screen: {
      path: 'loan/tila-disclosure',
    },
    Component: LoanTilaDisclosureMPOContainer,
  },
  LOAN_TILA_AND_PAYMENT_REVIEW: {
    screen: {
      path: 'loan/tila-and-payment-review',
    },
    Component: PaymentReviewTilaAndLoanAgreementCAMPOContainer,
  },
  LOAN_UPGRADE_TO_INSTALLMENT: {
    screen: {
      path: 'loan/upgrade-to-installment',
    },
    Component: UpgradeToInstallmentPlanMPOContainer,
    // they acceped the TILA disclosure and single payment loan terms on the previous screen.
    // that is irreverisble so we don't show the back button. they must move forward
    headerLeftButton: 'None',
  },
  LOAN_ACCEPT_ACH: {
    screen: {
      path: 'loan/accept-ach',
    },
    Component: AcceptACH,
  },
  LOAN_ACCEPT_AGREEMENTS: {
    screen: {
      path: 'loan/accept-agreements',
    },
    Component: AcceptAgreements,
  },
  LOAN_ACCEPT_AGREEMENTS_WA_INSTALLMENT: {
    screen: {
      path: 'loan/accept-wa-installment-agreements',
    },
    Component: AcceptAgreementsWAInstallment,
  },
  LOAN_STATE_DISCLOSURE: {
    screen: {
      path: 'loan/state-disclosure',
    },
    Component: StateDisclosureMPOContainer,
  },
  LOAN_CONFIRM_BANK: {
    screen: {
      path: 'loan/confirm-bank',
    },
    Component: ConfirmBankAccountMPOContainer,
  },
  LOAN_REASON_SURVEY: {
    screen: {
      path: 'loan/survey',
    },
    Component: ReasonSurveyMPOContainer,
  },
  LOAN_FINAL_ACCEPT: {
    screen: {
      path: 'loan/accept',
    },
    Component: LoanFinalAcceptMPOContainer,
  },
  LOAN_FINAL_ACCEPT_WA_INSTALLMENT: {
    screen: {
      path: 'loan/accept-wa',
    },
    Component: LoanFinalAcceptMPOContainer,
  },
}

export const LoanAcceptanceModuleDefinitions: Partial<OnboardingModuleDefinitions> = {
  LOAN_ACCEPTANCE: {
    type: 'MultiDirected',
    init: () => {
      const state = getPfStore().getState()
      const loanType = latestLoanTypeSelector(state) ?? undefined

      if (loanType?.regionCode && regionsWithConvertableLoans.has(loanType.regionCode)) {
        return 'LOAN_DISBURSEMENT_METHOD'
      }

      return 'LOAN_PAYMENT_REVIEW'
    },
    director: (from) => {
      const state = getPfStore().getState()
      if (!state) {
        return undefined
      }

      const loan = latestLoanSelector(state)
      const loanType = latestLoanTypeSelector(state) ?? undefined
      if (isLoanReplacementApproved(getLoanStatus(loan), loan?.originalLoanId)) {
        return loanAcceptanceWAExtendedInstallmentNextSteps[from]?.()
      } else if (loanType?.regionCode && regionsWithConvertableLoans.has(loanType.regionCode)) {
        return loanAcceptanceSingleToMultiPaymentNextSteps[from]?.()
      }
      return loanAcceptanceDefaultNextSteps[from]?.()
    },
    steps: loanAcceptanceModuleSteps,
  },
  LOAN_END: {
    type: 'End',
    navigateTo: 'Dashboard',
  },
}
