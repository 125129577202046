import React, {FC} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {useGetRejectionText} from 'src/products/card/LoanDash/useGetRejectionText'
import {CardReapply} from 'src/products/card/CardReapply'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {useIsUserEligibleToReapply} from 'src/products/card/Application/useIsUserEligibleToReapply'
import {CardTileBase} from 'src/products/card/LoanDash/CardTileBase'

export const CardApplicationRejected: FC = () => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_rejected_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {title, body, loading: isLoadingRejectionText} = useGetRejectionText()
  const {isLoading: isUserEligibleToReapplyLoading, isUserEligibleToReapply} =
    useIsUserEligibleToReapply()
  const isLoading = isLoadingRejectionText || isUserEligibleToReapplyLoading

  if (!isUserEligibleToReapplyLoading && isUserEligibleToReapply) {
    return <CardReapply tileMarginHorizontal={0} />
  }

  return (
    <BaseTemplate isLoading={isLoading} testID="CardApplicationRejectedBase">
      <CardTileBase
        titleText={title}
        content={body}
        loading={isLoading}
        tileMarginHorizontal={0}
        testID="CardApplicationRejectedBase"
      />
    </BaseTemplate>
  )
}
