import {CohortPhase, CohortUserTpe} from 'src/cassandra'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType'

const DefaultCohortPhase = CohortPhase.Pga

export const useGetCohortPhase = (): CohortPhase => {
  const isInOboardingExperimentA3FeatureFlag = useIsFeatureFlagEnabled(
    'card-onboarding-experiment-1-mock-bureau-uw',
  )
  const cohortUserType = useGetCohortUserType()

  return isInOboardingExperimentA3FeatureFlag && cohortUserType !== CohortUserTpe.LoanGrad
    ? CohortPhase.OnboardingExp1
    : DefaultCohortPhase
}
