import React, {FC, useState, useEffect, useCallback} from 'react'
import {Image, ImageStyle, StyleSheet} from 'react-native'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {maxBy} from 'lodash'

import {ContextualizedLogException, ShowException} from 'src/lib/errors'
import {userInfoUpdate} from 'src/api/actions/user/userActions'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import {UserAddressTypeV1} from 'src/lib/user/types'
import ActivityIndicator from 'src/products/general/components/atoms/ActivityIndicator/ActivityIndicator'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {userInfoChanges, formattedPI} from 'src/lib/user/selector'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import HookForm, {FIELD_VARIANTS} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {defaultActivityIndicatorSize} from 'src/designSystem/guide'
import {UpdatePIFields} from 'src/products/loans/PersonalInformation/PIUtils'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {userIdSelector} from 'src/api/selectors/selectors'
import {getUserDocumentColletion} from '@possible/cassandra/src/user/methods'
import {
  UserDocumentSubType,
  UserDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {UserDocQueryDocument} from 'src/cassandra/src/query_types/query_types'
import {StandardIDRatio} from 'src/products/general/components/organisms/CameraPage/CameraPage.consts'
import Log from 'src/lib/loggingUtil'

const PIFieldsToUpdate = ['first_name', 'last_name']

export type UserInfoChangesType = {
  first_name: string
  last_name: string
  id_number: string
  birth_date: string
  address: UserAddressTypeV1
}

type NameMatchesIDFormData = {
  first_name: string
  last_name: string
}

type Props = StackScreenProps<MainStackParamList, 'NameMatchesIDConfirm'> & {
  onComplete: () => void
  setBusy: (boolean) => void
}

const NameMatchesIDConfirm: FC<Props> = (props) => {
  const {setBusy: setParentBusy, onComplete, navigation} = props

  const userInfo = usePfSelector(formattedPI)
  const userInfoChange = usePfSelector(userInfoChanges) as UserInfoChangesType
  const userId = usePfSelector(userIdSelector)

  const dispatch = usePfDispatch()

  const [busy, setBusy] = useState(false)
  const [changed, setChanged] = useState(false)
  const [latestIdPhoto, setLatestIdPhoto] = useState<UserDocQueryDocument>()
  const {t} = useTranslation(['PersonalInformation', 'Common'])

  const initialInfo = UpdatePIFields(
    userInfo,
    userInfoChange,
    PIFieldsToUpdate,
  ) as UserInfoChangesType

  // we have no control over the typing of the `watch` method
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {control, handleSubmit, errors, watch} = useForm<NameMatchesIDFormData>({
    mode: 'all',
    defaultValues: initialInfo,
  })

  const refresh = useCallback(async () => {
    setBusy(true)
    try {
      if (!userId) {
        return
      }

      const documentType: UserDocumentType = UserDocumentType.Identity
      const docs = await getUserDocumentColletion(documentType)

      if (!docs) {
        throw new Error('Unable to get user document colection')
      }

      const userDocuments = docs?.all

      if (userDocuments) {
        const idPhotos = userDocuments.filter(
          (image) => image.document.subType === UserDocumentSubType.DriversLicenseFront,
        )
        const mostRecentIdPhoto = maxBy(idPhotos, (image) =>
          new Date(image.document.createdAt).valueOf(),
        )
        setLatestIdPhoto(mostRecentIdPhoto)
      }
    } catch (e) {
      if (e instanceof Error) {
        Log.log(`No ID images found: ${e.message}`)
      }
    } finally {
      setBusy(false)
    }
  }, [userId])

  useEffect(() => {
    refresh().catch(ContextualizedLogException('Failed to refresh ID photo'))
  }, [refresh])

  const onSubmit = async (data: NameMatchesIDFormData): Promise<void> => {
    setBusy(true)
    setParentBusy(true)
    try {
      const {first_name, last_name} = data

      const res = await dispatch(userInfoUpdate({firstName: first_name, lastName: last_name}))

      if (!res) {
        throw new Error('Failed to submit')
      }

      complete()
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
      setParentBusy(false)
    }
  }

  const complete = (): void => {
    navigation.pop()
    onComplete?.()
  }

  const primary_action = {
    text: t('ResubmitPersonalDetails'),
    onPress: handleSubmit(onSubmit),
    disabled: getHasErrorsOrMissingValues(errors, watch) || !changed || busy,
    testID: 'Resubmit-Personal-Details-Btn',
  }

  const formProps = {
    first_name: {
      name: 'first_name',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('Common:FirstName')}),
      },
    },
    last_name: {
      name: 'last_name',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t('Common:LabelIsRequired', {label: t('Common:LastName')}),
      },
    },
  }

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(primary_action)}
      title={t('ReconfirmYourDetails')}
      description={t('PleaseUpdateYourPersonalDetails')}
      subPage={true}
    >
      <Box fill={true} justify={'between'}>
        <Image source={{uri: latestIdPhoto?.presignedUrl.url}} style={styles.image} />
        <HookForm control={control} errors={errors}>
          <PFTextInput
            label={t('Common:FirstName')}
            onFocus={(): void => setChanged(true)}
            autoCorrect={false}
            errorOnEmpty={true}
            formProps={formProps.first_name}
            testID="First-Name-Input-URA"
          />
          <PFTextInput
            label={t('Common:LastName')}
            onFocus={(): void => setChanged(true)}
            autoCorrect={false}
            errorOnEmpty={true}
            formProps={formProps.last_name}
            testID="Last-Name-Input-URA"
          />
        </HookForm>
        <ActivityIndicator animating={busy} size="large" style={styles.loader} />
      </Box>
    </GenericNonModalTemplate>
  )
}

export default withForwardedNavigationParams<Props>()(NameMatchesIDConfirm)

const styles = StyleSheet.create({
  image: {
    resizeMode: 'contain',
    width: '100%',
    aspectRatio: StandardIDRatio,
  } as ImageStyle,
  loader: {
    margin: defaultActivityIndicatorSize,
  },
})
