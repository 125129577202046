import React, {FC} from 'react'

import {Consumer} from 'src/cassandra'
import Analytics from 'src/lib/Analytics'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {useGetCohortPhase} from 'src/products/card/Application/useGetCohortPhase'
import {CardAgreementViewerTemplate} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerTemplate/CardAgreementViewerTemplate'
import {useEnsureIsNewCardAccount} from 'src/products/card/Application/useEnsureIsNewCardAccount'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType'
import {useGetSelectedCardOfferId} from 'src/products/card/Application/useGetSelectedCardOfferId'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {useInitializeCards} from 'src/products/loans/Landing/useInitializeCards'

type CardAgreementViewerGQLContainerProps = {
  onComplete: (() => void | Promise<void>) | undefined
}
export const CardAgreementViewerGQLContainer: FC<CardAgreementViewerGQLContainerProps> = ({
  onComplete,
}) => {
  const [applyForCard, {loading: isLoading, called: wasCalled, error}] =
    Consumer.hooks.useCardAccountSubmitApplicationMutation({
      onError: (e) => {
        logOfferApplicationError(e, 'CardAgreementViewerGQLContainer query failed')
      },
    })

  const cohortPhase = useGetCohortPhase()
  const cardOfferId = useGetSelectedCardOfferId()
  const cohortUserType = useGetCohortUserType()
  const {initializeCards} = useInitializeCards()

  usePageViewedAnalytics({
    eventName: CardEvents.accept_cardholder_agreement_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {setPreviousAccount, clearPreviousAccount} = useEnsureIsNewCardAccount()

  const handleOnAgree = async (): Promise<void> => {
    try {
      TrackAppEvent(CardEvents.card_application_submitted, AppEvents.Category.Card, undefined, true)
      Analytics.CardApplicationSubmitted()

      // Submit the application
      const {errors} = await applyForCard({
        variables: {
          cardOfferId,
          miscData: {
            cohortPhase,
            cohortUserType,
          },
        },
      })

      if (errors?.length) {
        throw new Error(errors[0]?.message)
      }

      // Ensure user is updated with the correct userType
      await initializeCards()

      setPreviousAccount()
      await onComplete?.()
    } catch (e) {
      clearPreviousAccount()
      logOfferApplicationErrorAndShowException(e, 'card application submit error')
    }
  }

  return (
    <CardAgreementViewerTemplate
      onPress={handleOnAgree}
      isLoading={isLoading}
      called={wasCalled && !error}
    />
  )
}
