import {combineReducers} from 'redux'

import loans from 'src/lib/loans/reducers'
import {LoanState} from 'src/lib/loans/reducers/types'
import users from 'src/lib/user/reducers'
import {UserReduxTypesV1} from 'src/lib/user/types'
import ura, {URAState} from 'src/lib/ura/reducers/uraReducers'
import ui from 'src/lib/ui/reducers'
import card, {CardsState} from 'src/lib/card/slice'
import onboarding, {OnboardingState} from 'src/lib/onboarding/slice'

export interface LibReduxType {
  loans?: LoanState
  users: UserReduxTypesV1
  ura?: URAState
  ui?: any
  card: CardsState
  onboarding: OnboardingState
}

export default combineReducers<LibReduxType>({
  loans,
  users,
  ura,
  ui,
  card,
  onboarding,
})
