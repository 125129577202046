import React, {FC, ReactNode} from 'react'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {
  SummaryLineItem,
  SummaryLineItemProps,
} from 'src/designSystem/components/molecules/SummaryCard/SummaryLineItem'
import GenericList from 'src/designSystem/components/molecules/GenericList/GenericList'

type SummaryCardProps = {
  lineItems: SummaryLineItemProps[]
}

const SummaryCard: FC<SummaryCardProps> = (props) => {
  const {lineItems} = props

  const getSummaryLineItems = (): ReactNode[] => {
    return lineItems.map((item, index) => (
      <SummaryLineItem label={item.label} value={item.value} key={index} />
    ))
  }

  return (
    <BasicTile padding={'small'}>
      <GenericList items={getSummaryLineItems()} boxGap={'large'} />
    </BasicTile>
  )
}

export {SummaryCard}
