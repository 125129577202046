import React, {useEffect} from 'react'

import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {useMPOAndroidToWeb} from 'src/flows/redirection'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {LoanDisbursementMethodSelectionGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionGQLContainer'
import {usePfDispatch} from 'src/store/utils'

export type LoanDisbursementMethodSelectionMPOContainerProps = ModuleControlProps

/**
 * MPO container for the LoanDisbursementMethodSelection screen to integrate it into
 * an MPO flow.
 */
export const LoanDisbursementMethodSelectionMPOContainer: React.FC<
  LoanDisbursementMethodSelectionMPOContainerProps
> = (props) => {
  const {moduleName, onModuleComplete, onStepComplete} = props
  useMPOAndroidToWeb(moduleName)
  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Checkout,
    eventName: AppEvents.Name.loan_disbursement_method_selection_screen_viewed,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const dispatch = usePfDispatch()
  useEffect(() => {
    void dispatch(UserStateRefresh())
  }, [dispatch])

  const handleOnContinue = async (): Promise<void> => {
    try {
      await onStepComplete?.()
    } catch (e) {
      void logOfferActivationErrorAndShowException(
        e,
        'LoanDisbursementMethodSelectionMPOContainer handleOnContinue() failed',
      )
    }
  }

  const handleOnHasActiveLoan = async (): Promise<void> => {
    try {
      await onModuleComplete?.()
    } catch (e) {
      void logOfferActivationErrorAndShowException(
        e,
        'LoanDisbursementMethodSelectionMPOContainer handleOnHasActiveLoan() failed',
      )
    }
  }
  return (
    <LoanDisbursementMethodSelectionGQLContainer
      onContinue={handleOnContinue}
      onHasActiveLoan={handleOnHasActiveLoan}
    />
  )
}
