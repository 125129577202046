import moment, {Moment} from 'moment'
import React, {FC, RefObject, useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet, TextInput} from 'react-native'
import {useSelector} from 'react-redux'

import {ampli} from 'src/lib/Analytics/ampli'
import {userInfoUpdate} from 'src/api/actions/user/userActions'
import {gapSizeMap} from 'src/designSystem/layout'
import HookForm, {
  DefaultFormGapSizeVariant,
  FIELD_VARIANTS,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFDropdown from 'src/designSystem/components/atoms/PFDropdown/PFDropdown'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import PFDatePickerField from 'src/designSystem/components/molecules/PFDatePickerField'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  suffixOptions,
  UpdatePIFields,
  validateDate,
} from 'src/products/loans/PersonalInformation/PIUtils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {logAndShowErrorMessage} from 'src/lib/errors'
import {legalMaxAgeForLoan, legalMinAgeForLoan, validBeforeDate} from 'src/lib/time_util'
import {formattedPI, userInfoChanges} from 'src/lib/user/selector'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import {usePfDispatch} from 'src/store/utils'
import Log from 'src/lib/loggingUtil'
import Snackbar from 'src/lib/Snackbar'

export const RequiredPIFields = ['first_name', 'last_name', 'birth_date']

export type PersonalInformationFormData = {
  first_name: string
  middle_name?: string
  last_name: string
  name_suffix?: string
  birth_date: Moment
}

type Props = {
  onComplete: () => Promise<void>
}

const PersonalInformationTemplate: FC<Props> = (props) => {
  const {onComplete} = props

  const {t} = useTranslation(['PersonalInformation', 'Common'])

  const userInfo = useSelector(formattedPI)
  const userInfoChange = useSelector((state) => userInfoChanges(state))

  const initialInfo = UpdatePIFields(userInfo, userInfoChange)
  const dispatch = usePfDispatch()

  const [busy, setBusy] = useState<boolean>(false)

  const {control, handleSubmit, errors, watch} = useForm<PersonalInformationFormData>({
    mode: 'all',
    defaultValues: {
      ...initialInfo,
      birth_date: initialInfo.birth_date ? moment(initialInfo.birth_date) : '',
      id_expiration_date: initialInfo.id_expiration_date
        ? moment(initialInfo.id_expiration_date)
        : '',
    },
  })

  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_screen_viewed,
    eventCategory: AppEvents.Category.InitialApplication,
  })

  useEffect(() => {
    try {
      ampli.registrationSuccessfullyCompleted()
    } catch (e) {
      Log.warn('failed to send registrationSuccessfullyCompleted event to Ampli', e)
    }
  }, [])

  const onSubmit = async (data: PersonalInformationFormData): Promise<void> => {
    TrackAppEvent(AppEvents.Name.personal_details_completed, AppEvents.Category.InitialApplication)
    setBusy(true)

    try {
      const formatBirthDate = validateDate(data.birth_date, legalMaxAgeForLoan, legalMinAgeForLoan)

      if (!formatBirthDate) {
        Snackbar.error({
          title: t('ErrorInvalidBirthDate'),
          duration: Snackbar.LENGTH_LONG,
        })
        return
      }

      const piDataToConfirm = {
        firstName: data.first_name,
        middleName: data.middle_name,
        lastName: data.last_name,
        nameSuffix: data.name_suffix,
        birthDate: formatBirthDate,
      }

      const res = await dispatch(userInfoUpdate(piDataToConfirm))

      if (!res) {
        throw new Error('Failed to submit user data')
      }

      await onComplete()
    } catch (error) {
      logAndShowErrorMessage({
        error,
      })
    } finally {
      setBusy(false)
    }
  }

  const labelIsRequiredLocalization = 'Common:LabelIsRequired'
  const middleName: RefObject<TextInput> = useRef(null)
  const lastName: RefObject<TextInput> = useRef(null)

  const formProps = {
    first_name: {
      name: 'first_name',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t(labelIsRequiredLocalization, {replace: {label: t('Common:FirstName')}}),
      },
      viewStyle: styles.fullRow,
    },
    middle_name: {
      name: 'middle_name',
      field: FIELD_VARIANTS.TEXT_FIELD,
      viewStyle: styles.fullRow,
      ref: middleName,
    },
    last_name: {
      name: 'last_name',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t(labelIsRequiredLocalization, {replace: {label: t('Common:LastName')}}),
      },
      viewStyle: styles.fullRow,
      ref: lastName,
    },
    name_suffix: {
      name: 'name_suffix',
      field: FIELD_VARIANTS.DROPDOWN,
      viewStyle: styles.fullRow,
    },
    birth_date: {
      name: 'birth_date',
      field: FIELD_VARIANTS.DATEPICKER,
      rules: {
        required: t(labelIsRequiredLocalization, {replace: {label: t('Common:BirthDate')}}),
      },
      viewStyle: styles.fullRow,
    },
  }

  const getDisabledAction = (): boolean => {
    return getHasErrorsOrMissingValues(errors, watch, [...RequiredPIFields]) || busy
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Next'),
          onPress: handleSubmit(onSubmit),
          disabled: getDisabledAction(),
          loading: busy,
        },
      }}
      title={t('YourPersonalDetails')}
      variant={'generic'}
      smallTopGap={true}
    >
      <Box fill justify="between">
        <HookForm
          control={control}
          errors={errors}
          box={{gap: 'none', direction: 'row', wrap: 'wrap'}}
        >
          <PFTextInput
            label={t('Common:FirstName')}
            testID={'input_first_name'}
            formProps={formProps.first_name}
            returnKeyType={'next'}
            textContentType="givenName"
            onSubmitEditing={(): void => middleName.current?.focus()}
          />
          <PFTextInput
            label={t('MiddleNameOptional')}
            testID={'input_middle_name'}
            formProps={formProps.middle_name}
            returnKeyType={'next'}
            textContentType="middleName"
            onSubmitEditing={(): void => lastName.current?.focus()}
          />
          <PFTextInput
            label={t('Common:LastName')}
            testID={'input_last_name'}
            formProps={formProps.last_name}
            returnKeyType={'next'}
            textContentType="familyName"
          />
          <PFDropdown
            label={t('SuffixOptional')}
            placeholder={t('SuffixOptional')}
            options={suffixOptions}
            formProps={formProps.name_suffix}
          />
          <PFDatePickerField
            formProps={formProps.birth_date}
            label={t('Common:BirthDate')}
            minimumDate={validBeforeDate.toDate()}
            maximumDate={legalMinAgeForLoan.toDate()}
            editableStyle={true}
            editable={false}
          />
        </HookForm>

        <Box padding="little">
          <PFText variant="p_sm">{t('PersonalInfoDisclaimer')}</PFText>
        </Box>
      </Box>
    </Page>
  )
}

export default PersonalInformationTemplate

const styles = StyleSheet.create({
  fullRow: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
  },
})
