import React, {FC, ComponentProps, useMemo} from 'react'
import {Text, TextStyle, Animated} from 'react-native'
import {TransChild} from 'react-i18next/TransWithoutContext'

import {DefaultVariantsColor} from 'src/designSystem/colors'
import {TextVariants, VariantsStyle} from 'src/designSystem/typography'
import {Color} from 'src/designSystem/types'
import {testProps} from 'src/lib/utils/tests.utils'

export type PFTextProps = {
  /* The Trans component uses objects for replacement, but they aren't valid ReactNodes */
  children?: TransChild | TransChild[]
  textAlign?: TextStyle['textAlign']
  color?: Color
  onPress?: () => void
  variant?: TextVariants
  disabled?: boolean
  animated?: boolean
  animationStyles?: Animated.WithAnimatedValue<TextStyle>
  textProps?: ComponentProps<typeof Text>
  animatedTextProps?: ComponentProps<typeof Animated.Text>
  testID?: string
}

/**
 * Generic text component with branding rules applied.
 * Can display variants defined by designSystem/typography/TextVariants.
 * Can use colors defined by designSystem/colors/ColorVariants.
 * @example <PFText variant='p'>text</PFText>
 * @example <PFText variant='p_lg_semibold' textAlign='center'>text</PFText>
 * @example <PFText variant='h2' color='success'>text</PFText>
 */
const PFText: FC<PFTextProps> = ({
  textAlign,
  color,
  onPress,
  variant,
  disabled,
  children,
  animated,
  animationStyles,
  textProps,
  animatedTextProps,
  testID,
}) => {
  const textVariant = variant ?? 'h1'
  const textColor = disabled
    ? DefaultVariantsColor['textDisabled']
    : DefaultVariantsColor[color ?? 'textPrimary'] ?? color

  const variantAndColorStyle: TextStyle = useMemo(
    () => VariantsStyle[textVariant](textColor),
    [textColor, textVariant],
  )

  const alignStyle = textAlign ? {textAlign} : undefined
  const style = [variantAndColorStyle, alignStyle, textProps?.style]

  if (animated) {
    return (
      <Animated.Text
        {...textProps}
        {...animatedTextProps}
        style={[style, animationStyles]}
        onPress={onPress}
        {...testProps(testID)}
      >
        {/* @ts-expect-error See note on `children` */}
        {children}
      </Animated.Text>
    )
  }

  return (
    <Text {...textProps} style={style} onPress={onPress} {...testProps(testID)}>
      {/* @ts-expect-error See note on `children` */}
      {children}
    </Text>
  )
}

export default PFText
