import React, {useCallback, useMemo, useState} from 'react'
import {range} from 'lodash'
import {StyleSheet, View} from 'react-native'
import LightboxNotice from 'src/designSystem/components/organisms/Lightbox/LightboxNotice'
import moment from 'moment'

const daysInMonth = (year: number) => {
  return new Map([
    [0, 31],
    [1, moment([year]).isLeapYear() ? 29 : 28],
    [2, 31],
    [3, 30],
    [4, 31],
    [5, 30],
    [6, 31],
    [7, 31],
    [8, 30],
    [9, 31],
    [10, 30],
    [11, 31],
  ])
}

const monthList = [
  {key: 0, value: 'Jan'},
  {key: 1, value: 'Feb'},
  {key: 2, value: 'Mar'},
  {key: 3, value: 'Apr'},
  {key: 4, value: 'May'},
  {key: 5, value: 'Jun'},
  {key: 6, value: 'Jul'},
  {key: 7, value: 'Aug'},
  {key: 8, value: 'Sep'},
  {key: 9, value: 'Oct'},
  {key: 10, value: 'Nov'},
  {key: 11, value: 'Dec'},
]

const monthOptions = monthList.map((obj) => (
  <option key={obj.key} value={obj.key}>
    {obj.value}
  </option>
))

type Props = {
  dateValue: Date
  label: string
  onConfirm: (dateValue: Date) => void
  minimumDate?: Date
  maximumDate?: Date
}

const AndroidWebDatePicker = (props: Props) => {
  const {label, onConfirm, dateValue, minimumDate, maximumDate} = props

  const [day, setDay] = useState(dateValue?.getDate())
  const [month, setMonth] = useState(dateValue?.getMonth())
  const [year, setYear] = useState(dateValue?.getFullYear())

  const valueToOptions = useCallback(
    (value: number) => (
      <option key={value} value={value}>
        {value}
      </option>
    ),
    [],
  )

  const yearOptions = useMemo(() => {
    const minYear = minimumDate?.getFullYear() ?? 1901
    const maxYear = (maximumDate?.getFullYear() ?? new Date().getFullYear()) + 1
    return range(minYear, maxYear).map(valueToOptions)
  }, [valueToOptions, minimumDate, maximumDate])

  const dateOptions = useMemo(() => {
    const numberOfDaysInMonth = daysInMonth(year).get(month) ?? 31

    return range(1, numberOfDaysInMonth + 1).map(valueToOptions)
  }, [valueToOptions, year, month])

  // the input selects were not updating correctly when switching from
  // Feb 29 2024 to 2023. It should have shown Mar 1 2023, but instead it
  // was showing Feb 1 2023. However, submitting this result was updating
  // the underlying field to Mar 1 2023.
  const updateDate = (d: number, m: number, y: number) => {
    const mmt = moment().year(y).month(m).date(d)
    setDay(mmt.date())
    setMonth(mmt.month())
    setYear(mmt.year())
  }

  const onChangeYear = (event) => {
    updateDate(day, month, parseInt(event.target.value))
  }

  const onChangeMonth = (event) => {
    updateDate(day, parseInt(event.target.value), year)
  }

  const onChangeDay = (event) => {
    updateDate(parseInt(event.target.value), month, year)
  }

  const getDateAsObject = () => {
    const date = new Date()
    date.setFullYear(year)
    date.setMonth(month)
    date.setDate(day)
    return date
  }

  const body = (
    <View style={styles.datePickerView}>
      <View style={[styles.datePickerSelectView, styles.datePickerSelectViewPadded]}>
        <select onChange={onChangeMonth} value={month} style={selectStyles}>
          {monthOptions}
        </select>
      </View>
      <View style={[styles.datePickerSelectView, styles.datePickerSelectViewPadded]}>
        <select onChange={onChangeDay} value={day} style={selectStyles}>
          {dateOptions}
        </select>
      </View>
      <View style={styles.datePickerSelectView}>
        <select onChange={onChangeYear} value={year} style={selectStyles}>
          {yearOptions}
        </select>
      </View>
    </View>
  )

  const primary = {
    text: 'Confirm',
    onPress: () => onConfirm(getDateAsObject()),
    closeOnPress: true,
  }

  return <LightboxNotice title={label} body={body} primary_action={primary} />
}

export default AndroidWebDatePicker

const styles = StyleSheet.create({
  datePickerSelectView: {
    flex: 1,
  },
  datePickerSelectViewPadded: {
    paddingRight: 16,
  },
  datePickerView: {
    flexDirection: 'row',
    paddingBottom: 8,
    paddingTop: 8,
  },
})

const selectStyles = {
  height: 40,
}
