import {
  CardManualReviewAction,
  LoanDocumentsHistoryDocument,
  TransformLoanDocumentKeyForDownloadDocument,
  UnderwritingSimulatorGetTemplatesQueryDocument,
  User,
} from '../types/iam'
import {_applyMutation, _applyQuery} from '../utils'
import {
  CardAccountApplicationRejectQuery,
  CardAccountApplicationResumeQuery,
  CardAccountApplicationSnoozeQuery,
  CardAccountDeactivateQuery,
  CardAccountSuspendQuery,
  CardActivateQuery,
  CardNextManualReviewActionQuery,
  UserModifyAddressComponentMutation,
  UserQuery,
  UserUpdatePiiMutation,
} from '../schemas/iam'
import {ApplyMutation, ApplyQuery} from '../utils/operations'
import {LoanDocumentType} from '../types/types.mobile.generated'

type UserResponse = {
  user: User
}

type CardNextManualReviewActionResponse = {
  cardNextManualReviewAction: CardManualReviewAction
}

export const GetUser = async (userId: string): Promise<User> => {
  const resp = await _applyQuery<UserResponse>(UserQuery, {userId})
  return resp.user
}

export const GetCardNextManualReviewAction = async (
  userId: string,
): Promise<CardManualReviewAction> => {
  const resp = await _applyQuery<CardNextManualReviewActionResponse>(
    CardNextManualReviewActionQuery,
    {userId},
  )
  return resp.cardNextManualReviewAction
}

export const CardAccountApplicationResume = async (taskId: string) =>
  _applyMutation(CardAccountApplicationResumeQuery, {taskId})

export const CardAccountApplicationSnooze = async (taskId: string) =>
  _applyMutation(CardAccountApplicationSnoozeQuery, {taskId})

export const CardAccountApplicationReject = async (taskId: string, reviewReasonCode: number) =>
  _applyMutation(CardAccountApplicationRejectQuery, {taskId, reviewReasonCode})

export const CardActivate = async (cardAccountId: string, cardId: string) =>
  _applyMutation(CardActivateQuery, {cardAccountId, cardId})

export const cardAccountSuspend = async (cardAccountId: string, suspensionStatusReason: string) =>
  _applyMutation(CardAccountSuspendQuery, {cardAccountId, suspensionStatusReason})

export const cardAccountDeactivate = async (
  cardAccountId: string,
  deactivationStatusReason: string,
) => _applyMutation(CardAccountDeactivateQuery, {cardAccountId, deactivationStatusReason})

export const userUpdatePii = async (userId: string, userPiiInput: any) =>
  _applyMutation(UserUpdatePiiMutation, {userId, userPiiInput})

export const getUnderwritingSimulatorTemplates = async () => {
  return (await ApplyQuery(UnderwritingSimulatorGetTemplatesQueryDocument)).data
    .underwritingSimulatorGetTemplates.templates
}

export const getLoanDocumentsHistory = async (loanId: string) => {
  return (await ApplyQuery(LoanDocumentsHistoryDocument, {loanId})).data.loan.documents.history
}

export const userModifyAddressComponent = async (
  userId: string,
  addressType: any,
  physicalAddress: any,
) => ApplyMutation(UserModifyAddressComponentMutation, {userId, addressType, physicalAddress})

export const transformLoanDocumentKeyForDownload = async (
  documentKey: string,
  documentType: LoanDocumentType,
) => {
  return (
    await ApplyMutation(TransformLoanDocumentKeyForDownloadDocument, {
      documentKey,
      documentType,
    })
  )?.transformLoanDocumentKeyForDownload
}
