import React, {FC, ReactElement} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useSelector} from 'react-redux'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppNav from 'src/nav/AppNavActions'
import {latestLoanTypeSelector} from 'src/lib/loans/selector'
import {PfReduxState} from 'src/reducers/types'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {LAStateDisclosureTemplate} from 'src/products/loans/StateDisclosure/LAStateDisclosure/LAStateDisclosureTemplate'
import {OHStateDisclosureTemplate} from 'src/products/loans/StateDisclosure/OHStateDisclosure/OHStateDisclosureTemplate'
import {regionCodes} from 'src/lib/loans/consts'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {StateDisclosureGQLContainer} from 'src/products/loans/StateDisclosure/StateDisclosureGQLContainer'

const stepName = LoanApprovedFlow.StateDisclosure

type Props = StackScreenProps<MainStackParamList, 'StateDisclosure'> & ModuleControlProps

export const StateDisclosureNavPageStateContainer: FC<Props> = (props) => {
  const {navigation} = props
  const loanType = useSelector((state: PfReduxState) => latestLoanTypeSelector(state))

  usePageViewedAnalytics({
    eventName: AppEvents.Name.state_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnContinue = (): void => {
    TrackAppEvent(AppEvents.Name.state_disclosure_accepted, AppEvents.Category.Checkout, {
      navContainerType: 'NavPageState',
    })

    const nextPage = NavPageState.GetNextApprovalState(stepName)
    void NavPageState.GoToScreen(navigation, nextPage)
  }

  const handleOnExit = (): void => {
    TrackAppEvent(AppEvents.Name.state_disclosure_exit, AppEvents.Category.Checkout, {
      navContainerType: 'NavPageState',
    })
    AppNav.popToTop(navigation)
  }

  if (loanType?.regionCode === regionCodes.Louisiana) {
    return (
      <StateDisclosureGQLContainer
        onExit={handleOnExit}
        onContinue={handleOnContinue}
        render={(props): ReactElement => {
          return <LAStateDisclosureTemplate {...props} />
        }}
      ></StateDisclosureGQLContainer>
    )
  } else if (loanType?.regionCode === regionCodes.Ohio) {
    return (
      <StateDisclosureGQLContainer
        onExit={handleOnExit}
        onContinue={handleOnContinue}
        render={(props): ReactElement => {
          return <OHStateDisclosureTemplate {...props} />
        }}
      ></StateDisclosureGQLContainer>
    )
  } else {
    return (
      <ErrorTemplate
        error={new Error('StateDisclosureNavPageStateContainer: Unknown region code')}
      />
    )
  }
}
