import * as Types from '../../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type AcceptAgreementsUpdateLoanActivationAgreementsMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
  acceptAutoPayAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptLoanAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptCreditServiceAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptCreditServiceDisclosure?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptArbitrationAgreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  acceptStatutoryNotice?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type AcceptAgreementsUpdateLoanActivationAgreementsMutation = {
  __typename?: 'Mutation'
  loanActivationUpdateRequirements: {
    __typename?: 'LoanActivationUpdateRequirementsResponse'
    success: boolean
  }
}

export type AcceptAgreementsSetLoanDisbursementMethodMutationVariables = Types.Exact<{
  loanId: Types.Scalars['UUID']['input']
  disbursementMethod: Types.LoanPaymentMethod
}>

export type AcceptAgreementsSetLoanDisbursementMethodMutation = {
  __typename?: 'Mutation'
  loanSetDisbursementMethod: {__typename?: 'SetLoanDisbursementMethodResponse'; success: boolean}
}

export const AcceptAgreementsUpdateLoanActivationAgreementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsUpdateLoanActivationAgreements'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptAutoPayAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptCreditServiceAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'acceptCreditServiceDisclosure'},
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptArbitrationAgreement'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'acceptStatutoryNotice'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanActivationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptAutoPayAgreement'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptAutoPayAgreement'},
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptLoanAgreement'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'acceptLoanAgreement'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptCreditServiceAgreement'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptCreditServiceAgreement'},
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptCreditServiceDisclosure'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptCreditServiceDisclosure'},
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptArbitrationAgreement'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptArbitrationAgreement'},
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'acceptStatutoryNotice'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'acceptStatutoryNotice'},
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsUpdateLoanActivationAgreementsMutation,
  AcceptAgreementsUpdateLoanActivationAgreementsMutationVariables
>
export const AcceptAgreementsSetLoanDisbursementMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AcceptAgreementsSetLoanDisbursementMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'disbursementMethod'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanPaymentMethod'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanSetDisbursementMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanId'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'disbursementMethod'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'disbursementMethod'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptAgreementsSetLoanDisbursementMethodMutation,
  AcceptAgreementsSetLoanDisbursementMethodMutationVariables
>
