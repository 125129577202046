import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'

import {UserLoginStates} from 'src/api/reducers/types'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {HasAccountAndRouting, HasDebitAccountNumber} from 'src/lib/bank_account_utils'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {regionCodes, transferMethods} from 'src/lib/loans/consts'
import {
  disbursementMethodSelectedSelector,
  hasDisbursementOptions,
  inLoanReapplyingPhase,
  latestLoanSelector,
  latestLoanTypeSelector,
  userIsInAllowedUsStateSelector,
} from 'src/lib/loans/selector'
import {
  getLoanStatus,
  isAmountRequestedBiggerThanBorrowed,
  isLoanReplacementApproved,
} from 'src/lib/loans/utils'
import Log from 'src/lib/loggingUtil'
import {preferredAccount} from 'src/lib/user/accountsSelector'
import {
  isAddressComplete,
  isBankAccountSet,
  isDLScanned,
  isEmailVerifiedSelector,
  isLivenessCaptured,
  isPIComplete,
  isPhoneVerified,
  isSSNInfoComplete,
} from 'src/lib/user/selector'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {isDeviceWeb} from 'src/lib/utils/platform'
import AppNav from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {
  ApplicationDashboard,
  LoanFlowAddress,
  LoanFlowBank,
  LoanFlowBankLinkProps,
  LoanFlowEmailConfirmation,
  LoanFlowIDScan,
  LoanFlowLiveness,
  LoanFlowLoanSelection,
  LoanFlowLoanSubmission,
  LoanFlowPersonalInformation,
  LoanFlowPhoneConfirmation,
  LoanFlowSSNConfirm,
  LoanFlowType,
} from 'src/navigation/LoanFlow'
import {PushPage, SetRoot, SetRootStack} from 'src/navigation/NavHelper'
import {PfReduxState} from 'src/reducers/types'
import {getPfStore} from 'src/store'
import {PfReduxStore} from 'src/store/types'

//Order of pages in the Loan Reapplication flow
export const ReapplyLoanFlowOrder: LoanFlowType[] = [
  LoanFlowLoanSelection,
  LoanFlowBank,
  LoanFlowLoanSubmission,
]

//Onboarding flow
export const OnboardingStages = [LoanFlowPhoneConfirmation, LoanFlowBankLinkProps]

export const LoanApplicationFlowStages: LoanFlowType[] = [
  LoanFlowLoanSelection,
  LoanFlowPersonalInformation,
  LoanFlowAddress,
  LoanFlowSSNConfirm,
  LoanFlowLiveness,
  LoanFlowIDScan,
  LoanFlowLoanSubmission,
]

// Maps a page and the method used to check if the user already completed that step
// The check method is called by the method that build the stack
// @ts-expect-error typesript can't properly infer map type
const checkActionsFlowStages = new Map([
  [LoanFlowPhoneConfirmation, isPhoneVerified],
  [LoanFlowBank, isBankAccountSet],
  [LoanFlowLiveness, isLivenessCaptured],
  [LoanFlowIDScan, (state: PfReduxState): boolean => !!isDLScanned(state)],
  [LoanFlowPersonalInformation, IsPIInfoComplete],
  [LoanFlowAddress, isAddressInfoComplete],
  [LoanFlowSSNConfirm, (state: PfReduxState): boolean => !!isSSNInfoComplete(state)],
  [LoanFlowEmailConfirmation, isEmailVerifiedSelector],
  [LoanFlowLoanSubmission, undefined],
])

//Application Flows
enum APPLICATION_PHASES {
  LOGIN = 'Login',
  ONBOARDING = 'OnBoarding',
  FIRST_LOAN_APPLICATION = 'FirstLoanApplication',
  REAPPLYING = 'ReApplying',
  OTHER = 'Other',
}

//this method takes the full flow (eg reapply, onboarding, etc) and a sub flow (eg LoanFlowEmailConfirmation)
//and returns the full flow with the subflow added at the end right before the last step (submission)
export const addFlowBeforeSubmission = (
  fullFlow: LoanFlowType[],
  flowToAdd: LoanFlowType,
): LoanFlowType[] => {
  if (!fullFlow || fullFlow?.length < 2) {
    return fullFlow
  }
  const startIndex = fullFlow.length - 1
  const withNewFlowAdded = fullFlow.slice()
  withNewFlowAdded.splice(startIndex, 0, flowToAdd)
  return withNewFlowAdded
}

export function loanApplicationFlowStages(state: PfReduxState): LoanFlowType[] {
  let loanApplicationFlow = LoanApplicationFlowStages

  if (!isEmailVerifiedSelector(state)) {
    loanApplicationFlow = addFlowBeforeSubmission(loanApplicationFlow, LoanFlowEmailConfirmation)
  }

  return loanApplicationFlow
}

export const reapplyFlowStages = (state: PfReduxState): LoanFlowType[] => {
  let flowOrder = ReapplyLoanFlowOrder

  if (!isEmailVerifiedSelector(state)) {
    flowOrder = addFlowBeforeSubmission(flowOrder, LoanFlowEmailConfirmation)
  }
  return flowOrder
}

/**
 * @return {integer}
 */
function findLastStateIndexOfFlowOrder(flowOrder: string[], currentPage: LoanFlowType) {
  let lastStateIndex = flowOrder?.findIndex((s) => s === currentPage)
  //if the user just completed email verification the flowOrder will no longer contain that step
  if (currentPage === LoanFlowEmailConfirmation && lastStateIndex < 0) {
    //in loanApplicationFlowStages and reapplyFlowStages we set email verification as the last flow before submission
    lastStateIndex = flowOrder?.length - 1
  }
  return lastStateIndex
}

/**
 * @return {string}
 */
function GetNextReapplyLoanStep(currentPage: LoanFlowType, state: PfReduxState) {
  const flowOrder = reapplyFlowStages(state)
  const lastStateIndex = findLastStateIndexOfFlowOrder(flowOrder, currentPage)
  return lastStateIndex < flowOrder?.length - 1
    ? flowOrder?.[lastStateIndex + 1]
    : LoanFlowLoanSubmission
}

/**
 * @return {string}
 */
function GetNextInitialLoanStep(currentPage: LoanFlowType, state: PfReduxState) {
  if (
    currentPage === LoanFlowPhoneConfirmation &&
    isPhoneVerified(state) &&
    isSSNInfoComplete(state) &&
    isEmailVerifiedSelector(state)
  ) {
    /* if the user was assigned the phone_entry_first A/B flag while already
    in the application flow we don't want to send them back to the flow when
    they finish verifying their phone number.
     */
    return LoanFlowLoanSubmission
  }
  const flowstages = loanApplicationFlowStages(state)
  const lastStateIndex = findLastStateIndexOfFlowOrder(flowstages, currentPage)

  return lastStateIndex < flowstages.length - 1
    ? flowstages[lastStateIndex + 1]
    : LoanFlowLoanSubmission
}

function IsPIInfoComplete(state) {
  return isPIComplete(state)
}

function isAddressInfoComplete(state) {
  return isAddressComplete(state)
}

export const AddMainDashboardToStack = (stack: AppStack): AppStack => {
  stack.push({routeName: LoanApprovedFlow.Dashboard})
  return stack
}

function GetCurrentLoanApplicationStack(state, stack, reapplying) {
  const loanFlowStages = reapplying ? reapplyFlowStages(state) : loanApplicationFlowStages(state)
  Log.log('loanFlowStages: ', loanFlowStages)
  stack = AddMainDashboardToStack(stack)

  for (const stage of loanFlowStages) {
    stack.push({routeName: stage})
    const checker = checkActionsFlowStages.get(stage)
    if (!checker?.(state)) {
      return stack
    }
  }
}

const regionsRequiringSeparateAch = new Set([regionCodes.Florida, regionCodes.Texas])
const regionsWithConvertableLoans = new Set([regionCodes.California])
const regionsWithStateDisclosure = new Set([regionCodes.Ohio, regionCodes.Louisiana])

function regionCheck(state, regions) {
  const loanType = latestLoanTypeSelector(state)
  return regions.has(loanType?.regionCode)
}

function regionRequiresSeparateAch(state) {
  return regionCheck(state, regionsRequiringSeparateAch)
}

function regionRequiresStateDisclosure(state) {
  return regionCheck(state, regionsWithStateDisclosure)
}

type FlowSteps = {
  screen?: keyof MainStackParamList
  props?: any
  resetStack?: boolean
  component?: JSX.Element
  onTransition?: (
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>,
  ) => void
  overlay?: boolean
  undismissable?: boolean
}

const isCounterOffer = () => {
  const state = getPfStore().getState()
  const loan = latestLoanSelector(state)
  return isAmountRequestedBiggerThanBorrowed(loan?.amountBorrowed, loan?.amountRequested)
}

function defaultApprovalFlow(state, currentPage): FlowSteps | undefined {
  switch (currentPage) {
    case LoanApprovedFlow.Landing: {
      if (isCounterOffer()) {
        return {screen: LoanApprovedFlow.CounterOffer}
      }

      if (!isPhoneVerified(state)) {
        return {screen: LoanApprovedFlow.PhoneConfirmation}
      }
      return {screen: LoanApprovedFlow.LoanPaymentReview}
    }
    case LoanApprovedFlow.CounterOffer: {
      if (!isPhoneVerified(state)) {
        return {screen: LoanApprovedFlow.PhoneConfirmation}
      }
      return {screen: LoanApprovedFlow.LoanPaymentReview}
    }
    case LoanApprovedFlow.PhoneVerification: //exit point for PhoneConfirmation
      return {screen: LoanApprovedFlow.LoanPaymentReview}
    case LoanApprovedFlow.LoanPaymentReview: {
      if (hasDisbursementOptions(state)) {
        return {screen: LoanApprovedFlow.DisbursementMethodSelection}
      }
      return {screen: LoanApprovedFlow.LoanTilaDisclosure}
    }
    case LoanApprovedFlow.DisbursementMethodSelection: {
      const getDisbursementMethod = disbursementMethodSelectedSelector(state)
      if (getDisbursementMethod === transferMethods.interchange) {
        if (HasDebitAccountNumber(preferredAccount(state))) {
          return {screen: LoanApprovedFlow.ConfirmDebitCard}
        }
        return {
          screen: LoanApprovedFlow.CollectBankDebitCardNumbers,
          props: {isInLoanApprovalFlow: true},
        }
      }
      return {screen: LoanApprovedFlow.LoanTilaDisclosure}
    }
    case LoanApprovedFlow.ConfirmDebitCard: {
      return {screen: LoanApprovedFlow.LoanTilaDisclosure}
    }
    case LoanApprovedFlow.CollectBankDebitCardNumbers: {
      return {screen: LoanApprovedFlow.LoanTilaDisclosure}
    }
    case LoanApprovedFlow.LoanTilaDisclosure: {
      return {screen: LoanApprovedFlow.HowPaymentsWork}
    }
    case LoanApprovedFlow.HowPaymentsWork:
      return {screen: LoanApprovedFlow.AcceptAgreements}
    case LoanApprovedFlow.AcceptAgreements: {
      if (regionRequiresSeparateAch(state)) {
        return {screen: LoanApprovedFlow.AcceptACH}
      }
      if (regionRequiresStateDisclosure(state)) {
        return {screen: LoanApprovedFlow.StateDisclosure}
      }
      if (!HasAccountAndRouting(preferredAccount(state))) {
        return {screen: LoanApprovedFlow.ConfirmBankAccount}
      }
      return {screen: LoanApprovedFlow.ReasonSurvey}
    }
    case LoanApprovedFlow.StateDisclosure:
    case LoanApprovedFlow.AcceptACH: {
      if (!HasAccountAndRouting(preferredAccount(state))) {
        return {screen: LoanApprovedFlow.ConfirmBankAccount}
      }
      return {screen: LoanApprovedFlow.ReasonSurvey}
    }
    case LoanApprovedFlow.ConfirmBankAccount:
      return {screen: LoanApprovedFlow.ReasonSurvey}
    case LoanApprovedFlow.ReasonSurvey:
      return {screen: LoanApprovedFlow.LoanFinalAccept}
    case LoanApprovedFlow.LoanFinalAccept:
      return {
        screen: LoanApprovedFlow.Dashboard,
        resetStack: true,
      }
    case LoanApprovedFlow.LoanFinalAcceptWAExtendedInstallment:
      return {
        screen: LoanApprovedFlow.Dashboard,
        resetStack: true,
      }
  }
  return undefined
}

function singleToMultiPaymentApprovalFlow(reduxState, currentPage): FlowSteps | undefined {
  function landingToNonCounterOffer(): FlowSteps | undefined {
    if (!isPhoneVerified(reduxState)) {
      return {screen: LoanApprovedFlow.PhoneConfirmation}
    }
    return {screen: LoanApprovedFlow.DisbursementMethodSelection}
  }

  switch (currentPage) {
    case LoanApprovedFlow.Landing: {
      if (isCounterOffer()) {
        return {
          screen: LoanApprovedFlow.CounterOffer,
        }
      }

      return landingToNonCounterOffer()
    }
    case LoanApprovedFlow.CounterOffer:
      return landingToNonCounterOffer()
    case LoanApprovedFlow.PhoneVerification: //exit point for PhoneConfirmation
      return {screen: LoanApprovedFlow.DisbursementMethodSelection}
    case LoanApprovedFlow.DisbursementMethodSelection: {
      const getDisbursementMethod = disbursementMethodSelectedSelector(reduxState)
      if (getDisbursementMethod === transferMethods.interchange) {
        if (HasDebitAccountNumber(preferredAccount(reduxState))) {
          return {screen: LoanApprovedFlow.ConfirmDebitCard}
        }
        return {
          screen: LoanApprovedFlow.CollectBankDebitCardNumbers,
          props: {isInLoanApprovalFlow: true},
        }
      }
      return {screen: LoanApprovedFlow.HowPaymentsWork}
    }
    case LoanApprovedFlow.ConfirmDebitCard:
    case LoanApprovedFlow.CollectBankDebitCardNumbers:
      if (!HasAccountAndRouting(preferredAccount(reduxState))) {
        return {screen: LoanApprovedFlow.ConfirmBankAccount}
      }
      return {screen: LoanApprovedFlow.HowPaymentsWork}
    case LoanApprovedFlow.ConfirmBankAccount:
      return {screen: LoanApprovedFlow.AcceptACH}
    case LoanApprovedFlow.HowPaymentsWork:
      if (!HasAccountAndRouting(preferredAccount(reduxState))) {
        return {screen: LoanApprovedFlow.ConfirmBankAccount}
      }
      return {screen: LoanApprovedFlow.AcceptACH}
    case LoanApprovedFlow.AcceptACH:
      return {screen: LoanApprovedFlow.ReasonSurvey}
    case LoanApprovedFlow.ReasonSurvey:
      return {screen: LoanApprovedFlow.PaymentReviewTilaAndLoanAgreementCA}
    case LoanApprovedFlow.PaymentReviewTilaAndLoanAgreementCA:
      return {
        screen: LoanApprovedFlow.UpgradeToInstallment,
        resetStack: true,
        props: {isFromNavPageStateAcceptance: true},
      }
    case LoanApprovedFlow.UpgradeToInstallment:
      return {
        screen: LoanApprovedFlow.Dashboard,
        resetStack: true,
      }
  }
}

function waExtendedInstallmentApprovalFlow(currentPage): FlowSteps | undefined {
  switch (currentPage) {
    case LoanApprovedFlow.Dashboard: {
      if (isCounterOffer()) {
        return {
          screen: LoanApprovedFlow.CounterOffer,
        }
      }

      return {
        screen: LoanApprovedFlow.LoanPaymentReview,
      }
    }
    case LoanApprovedFlow.CounterOffer:
      return {
        screen: LoanApprovedFlow.LoanPaymentReview,
      }
    case LoanApprovedFlow.LoanPaymentReview:
      return {
        screen: LoanApprovedFlow.LoanTilaDisclosure,
      }
    case LoanApprovedFlow.LoanTilaDisclosure:
      return {
        screen: LoanApprovedFlow.AcceptAgreementsWAInstallment,
      }
    case LoanApprovedFlow.AcceptAgreements:
    case LoanApprovedFlow.AcceptAgreementsWAInstallment:
      return {
        screen: LoanApprovedFlow.LoanFinalAcceptWAExtendedInstallment,
      }
    case LoanApprovedFlow.LoanFinalAcceptWAExtendedInstallment:
      return {
        screen: LoanApprovedFlow.Dashboard,
        resetStack: true,
      }
  }
}

export function isUserOnboarding(state) {
  //If a user doesn't have a verified phone number they always start on onboarding
  //If the haven't selected a state they start on onboarding
  //Since the bank account can be deleted, for the bank we also check that they haven't selected a loan amount
  return (
    !isPhoneVerified(state) || !userIsInAllowedUsStateSelector(state) || !isBankAccountSet(state)
  )
}

type StackRoute = {
  routeName: keyof MainStackParamList
  params?: Record<string, string | boolean | number>
}
export type AppStack = StackRoute[]
class NavPageState {
  store: PfReduxStore | undefined = undefined
  initialAppPhase: APPLICATION_PHASES | undefined = undefined

  init(store) {
    if (this.store) {
      return
    }
    this.store = store
  }

  ApplicationPhase(state) {
    if (state?.api.user_logged_state === UserLoginStates.not_logged_in) {
      return APPLICATION_PHASES.LOGIN
    }
    if (inLoanReapplyingPhase(state)) {
      return APPLICATION_PHASES.REAPPLYING
    }
    if (state.lib.loans?.loans?.length > 0) {
      return APPLICATION_PHASES.OTHER
    }
    return APPLICATION_PHASES.FIRST_LOAN_APPLICATION
  }

  GetNextApprovalState(currentPage) {
    const reduxState = this.store?.getState()
    if (!reduxState) {
      return undefined
    }
    const loan = latestLoanSelector(reduxState)
    const loanType = reduxState ? latestLoanTypeSelector(reduxState) : undefined
    if (isLoanReplacementApproved(getLoanStatus(loan), loan?.originalLoanId)) {
      return waExtendedInstallmentApprovalFlow(currentPage)
    } else if (loanType?.regionCode && regionsWithConvertableLoans.has(loanType.regionCode)) {
      return singleToMultiPaymentApprovalFlow(reduxState, currentPage)
    } else {
      return defaultApprovalFlow(reduxState, currentPage)
    }
  }

  async GoToScreen(navigation, screenInfo): Promise<void> {
    if (screenInfo) {
      if (screenInfo.overlay) {
        ShowLightbox(screenInfo.component, screenInfo.undismissable ?? false)
      } else if (screenInfo.resetStack) {
        SetRoot(screenInfo.screen, screenInfo.props)
      } else {
        PushPage(navigation, screenInfo.screen, screenInfo.props)
      }
      if (screenInfo.onTransition) {
        screenInfo.onTransition()
      }
    }
  }

  // This needs to be moved to AppNavActions.push method
  navigateToPage(navigation, page) {
    Log.log('NavigateToPage: ', navigation, page)
    if (navigation) {
      AppNav.push(navigation, page)
    } else {
      AppNav.pushToMainStack(page)
    }
  }

  GetApplicationStack() {
    let stack: AppStack = []
    const state = this.store?.getState()
    if (!state) {
      Log.warn('GetApplicationStack state is not ready')
      return stack
    }
    stack = GetCurrentLoanApplicationStack(state, stack, inLoanReapplyingPhase(state))
    return stack
  }

  NavToNextLoanApplicationPage(currentPage, navigation, phase, state) {
    let page

    if (phase === APPLICATION_PHASES.REAPPLYING) {
      page = GetNextReapplyLoanStep(currentPage, state)
    } else {
      page = GetNextInitialLoanStep(currentPage, state)
    }

    this.navigateToPage(navigation, page)
  }

  surveyViewedNavigation(data) {
    const {userId, surveyViewed, navigation, onComplete} = data

    if (!surveyViewed) {
      AppNav.push(navigation, 'LoansMarketingSurvey', {
        userId,
        onComplete: () => onComplete?.(),
      })
    } else {
      onComplete?.()
    }
  }

  async NavToNextOnboardingPage(currentPage, navigation, state: PfReduxState) {
    Log.log('NavToNextOnBoardingPage: ', currentPage, navigation, state)
    const redirectToWeb = getIsFeatureFlagEnabled('loan-application-handoff-to-web-on-android')
    let page = this.GetNextOnboardingPage(currentPage)
    let checker = checkActionsFlowStages.get(page)

    while (await checker?.(state)) {
      page = this.GetNextOnboardingPage(page)
      checker = checkActionsFlowStages.get(page)
    }

    if (page === LoanApprovedFlow.Dashboard) {
      //Though the next step is coming back as Dashboard, in result of an experiment we directing users who are
      //Onboarding directly into the application flow

      if (redirectToWeb) {
        //For Android device once the onboarding flow is complete, pop out to the WebApp
        //to continue the experience. The native app should go to the Dashboard.
        EmitRedirectionEvent('application')
        SetRootStack([{routeName: page}])
        return
      }

      this.initialAppPhase = undefined
      Log.info('Navigating to Loan Application')
      SetRootStack(this.GetApplicationStack())
      return
    }

    this.navigateToPage(navigation, page)
  }

  NavToDashboardLoader() {
    SetRootStack([{routeName: 'DashboardLoader'}])
  }

  NavToDashboard() {
    const stack = AddMainDashboardToStack([])
    SetRootStack(stack)
  }

  NavToNextPage(currentPage, navigation) {
    const state = this.store?.getState()
    if (!state) {
      return undefined
    }
    const currentPhase = this.ApplicationPhase(state)

    switch (currentPage) {
      case LoanFlowPhoneConfirmation:
        {
          if (this.initialAppPhase === APPLICATION_PHASES.ONBOARDING) {
            this.NavToNextOnboardingPage(currentPage, navigation, state)
          } else {
            //This should be loan approved only used for users with a very old application
            const approval_step = this.GetNextApprovalState('PhoneVerification')
            this.GoToScreen(navigation, approval_step)
          }
        }
        break
      case LoanFlowBankLinkProps:
        {
          this.NavToNextOnboardingPage(currentPage, navigation, state)
        }
        break
      case ApplicationDashboard:
      case LoanFlowIDScan:
      case LoanFlowSSNConfirm:
      case LoanFlowLiveness:
      case LoanFlowPersonalInformation:
      case LoanFlowAddress:
      case LoanFlowLoanSelection:
      case LoanFlowEmailConfirmation:
      case LoanFlowBank:
        {
          this.NavToNextLoanApplicationPage(currentPage, navigation, currentPhase, state)
        }
        break
    }
  }

  GetNextOnboardingPage(currentPage) {
    const lastStateIndex = OnboardingStages.findIndex((s) => s === currentPage)

    return lastStateIndex < OnboardingStages.length - 1
      ? OnboardingStages[lastStateIndex + 1]
      : LoanApprovedFlow.Dashboard
  }

  GetOnboardingStack(state, stack): AppStack {
    for (const stage of OnboardingStages) {
      stack.push({routeName: stage})

      const checker = checkActionsFlowStages.get(stage)
      if (!checker?.(state)) {
        break
      }
    }

    return stack
  }

  GetInitialNavStack() {
    const stack: AppStack = []
    const state = this.store?.getState()

    if (state) {
      this.initialAppPhase = this.ApplicationPhase(state)
      if (state?.api.user_logged_state === UserLoginStates.not_logged_in) {
        let routeName: keyof MainStackParamList = 'Landing'
        if (isDeviceWeb()) {
          routeName = 'Registration'
        }
        stack.push({routeName})
        return stack
      }

      stack.push({routeName: 'DashboardLoader'})
    }
    return stack
  }

  pushCurrentPage(navigation) {
    const currentPage = this.GetCurrentPage()
    AppNav.push(navigation, currentPage.routeName, currentPage.params)
  }

  GetCurrentPage() {
    const stack = this.GetInitialNavStack()
    return stack[stack.length - 1]
  }

  /**
   * Retrieves the routing for getting to where we need to be in multi-product onboarding.
   * @returns An AppStack representing the user's state in multi-product onboarding.
   */
  GetMPONavStack(): AppStack {
    // The onboarding component will actually determine where it needs to go once it is mounted.
    return [{routeName: 'Onboarding'}]
  }

  NavToMPO() {
    SetRootStack(this.GetMPONavStack())
  }

  NavToCurrentStateRoot() {
    const currentPage = this.GetCurrentPage()
    SetRootStack([currentPage])
  }

  PushNavToReapply(navigation) {
    PushPage(navigation, LoanFlowLoanSelection)
  }
  //Loan States
  IsDLScanned() {
    const state = this.store?.getState()
    return state && isDLScanned(state)
  }

  IsPIInfoComplete() {
    const state = this.store?.getState()
    return IsPIInfoComplete(state)
  }

  IsAddressInfoComplete() {
    const state = this.store?.getState()
    return isAddressInfoComplete(state)
  }

  IsLivenessCaptured() {
    const state = this.store?.getState()
    return state && isLivenessCaptured(state)
  }
}

export default new NavPageState()
