import APIClient, {APIv2ResponseBase} from '@possible/generated/APIClient'
import {allowedStates} from 'src/lib/us_states'
import {abFlaggedStatesEnabled} from 'src/lib/experimentation/selectors'
import {PfDispatch, PfGetState} from 'src/store/types'
import {loans} from '@possible/generated/src/generated/protomonoTypes'

class APIClientLoan {
  dispatch?: PfDispatch
  getState?: PfGetState

  init(dispatch, getState) {
    this.dispatch = dispatch
    this.getState = getState
  }

  async GetLoanTypeById(id) {
    const params = {
      method: 'GetLoanTypeById',
      uri: '/api/v2/loans2/GetLoanTypeById',
      request: {id},
    }

    return APIClient.apiV2Put(params)
  }

  async getConversionOptions(id) {
    const params = {
      method: 'GetConversionOptions',
      uri: '/api/v2/loans2/GetConversionOptions',
      request: {
        include_offers: true,
        loan: {
          id,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async convertLoan(id, offer_id) {
    const params = {
      method: 'ConvertLoan',
      uri: '/api/v2/loans2/ConvertLoan',
      request: {
        loan: {
          id,
        },
        offer_id,
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoansByUserId(userId) {
    const params = {
      method: 'GetLoansByUserId',
      uri: '/api/v2/loans2/GetLoansByUserId',
      request: {
        loan: {
          userId,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoan(id) {
    const params = {
      method: 'GetLoan',
      uri: '/api/v2/loans2/GetLoan',
      request: {
        loan: {
          id,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoanPaymentMethods(id) {
    const params = {
      method: 'GetLoanPaymentMethods',
      uri: '/api/v2/loans2/GetLoanPaymentMethods',
      request: {
        loan: {
          id,
        },
      },
    }

    return APIClient.apiV2Put(params)
  }

  async GetLoanTerms(stateAbv) {
    const reduxState = this.getState?.()
    const abEnabledStates = reduxState ? abFlaggedStatesEnabled(reduxState) : undefined
    if (!allowedStates(abEnabledStates).includes(stateAbv)) {
      const error = {error: {errorCode: 'not_ok', errorMsg: 'Unsupported State'}}
      return Object.assign(new APIv2ResponseBase(), error)
    }

    const params = {
      method: 'GetPrimaryLoanTypeByRegion',
      uri: '/api/v2/loans2/GetPrimaryLoanTypeByRegion',
      request: {
        regionCode: `USA-${stateAbv}`,
      },
    }
    return APIClient.apiV2Put(params)
  }
}

export default new APIClientLoan()
