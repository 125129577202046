import APIClient, {APIv2Response} from '@possible/generated/APIClient'
import Log from 'src/lib/loggingUtil'
import {banking} from '@possible/generated/proto'

class APIClientBanking {
  async GetPaymentInstruments(
    userId,
    withOptionalMetadata,
  ): Promise<APIv2Response<banking.IGetPaymentInstrumentsResponse> | null> {
    const params = {
      method: 'GetPaymentInstruments',
      uri: '/api/v2/banking/GetPaymentInstruments',
      request: {userId, withOptionalMetadata},
    }
    try {
      const response = await APIClient.apiV2Put(params)
      response.throwIfError()

      return response
    } catch (e) {
      Log.log('GetPaymentInstruments: ', e)
      return null
    }
  }

  async GetLinkedAccounts(
    userId,
  ): Promise<APIv2Response<banking.IGetLinkedAccountsResponse> | null> {
    const params = {
      method: 'GetLinkedAccounts',
      uri: '/api/v2/banking/GetLinkedAccounts',
      request: {
        userId,
      },
    }

    try {
      const response = await APIClient.apiV2Put(params)
      response.throwIfError()

      return response
    } catch (e) {
      Log.log('GetLinkedAccounts: ', e)
      return null
    }
  }
}

export default new APIClientBanking()
