import {Consumer} from '@possible/cassandra'
import {CardAccountOfferInfo} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCallback} from 'react'

import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {getCardOfferInfo} from 'src/products/general/ProductHub/ProductHub.utils'
import {usePfDispatch} from 'src/store/utils'
import {wfError} from 'src/workflows/logging'
import {ClearWorkflowStateAction, SetWorkflowStateAction} from 'src/workflows/slice'
import {canAccessOffer} from 'src/workflows/utils'

type UseSelectCardOfferType = () => () => Promise<void>

export const useSelectCardOffer: UseSelectCardOfferType = () => {
  const {selectedData} = Consumer.hooks.useProductsQuery({fetchPolicy: 'cache-first'})
  const {canAccessCards} = useCanAccessCards()
  const dispatch = usePfDispatch()

  return useCallback(async () => {
    try {
      const cardOfferInfo: CardAccountOfferInfo | undefined = getCardOfferInfo(
        selectedData?.eligible?.all ?? [],
      )
      const cardOffer =
        cardOfferInfo?.offers && cardOfferInfo.offers.length > 0
          ? cardOfferInfo.offers[0]
          : undefined

      if (cardOffer && canAccessOffer(cardOffer, canAccessCards)) {
        await dispatch(ClearWorkflowStateAction())
        await dispatch(
          SetWorkflowStateAction({
            selectedOffer: {offerId: cardOffer.id, metFrontEndPreReqs: []},
          }),
        )
        return
      }
    } catch (e) {
      if (e instanceof Error) {
        wfError(e, 'Error setting default offer')
      }
    }
  }, [canAccessCards, dispatch, selectedData?.eligible?.all])
}
