import React, {FC} from 'react'

import {LoanTilaDisclosureGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureGQLContainer'
import {ModuleControlProps} from 'src/flows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

type LoanTilaDisclosureMPOContainerProps = ModuleControlProps

const LoanTilaDisclosureMPOContainer: FC<LoanTilaDisclosureMPOContainerProps> = (props) => {
  const {onStepComplete} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.tila_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'MPO',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    if (onStepComplete) {
      await onStepComplete()
    }
  }

  return <LoanTilaDisclosureGQLContainer onContinue={handleOnContinue} />
}

export {LoanTilaDisclosureMPOContainer}
