import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {LoanPaymentReviewGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewGQLContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import NavPageState from 'src/navigation/NavPageState'

type LoanPaymentReviewNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'LoanPaymentReview'>,
  'navigation'
>

const LoanPaymentReviewNavPageStateContainer: FC = () => {
  const navigation: LoanPaymentReviewNavPageStateContainerProps = useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.review_payment_schedule_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    const nextPage = NavPageState.GetNextApprovalState('LoanPaymentReview')
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  return <LoanPaymentReviewGQLContainer onContinue={handleOnContinue} />
}

export {LoanPaymentReviewNavPageStateContainer}
